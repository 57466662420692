import ActionTypes from '../actions/actionTypes';

export enum WebSocketStatus {
  Connected = 'CONNECTED',
  Connecting = 'CONNECTING',
  Disconnected = 'DISCONNECTED',
  NotConnected = 'NOT_CONNECTED',
  Error = 'ERROR'
}

export default function(
  state: WebSocketStatus = WebSocketStatus.NotConnected,
  action: any
): WebSocketStatus {
  switch (action.type) {
    case ActionTypes.SocketConnected:
      return WebSocketStatus.Connected;

    case ActionTypes.SocketConnecting:
      return WebSocketStatus.Connecting;

    case ActionTypes.SocketClosed:
      return WebSocketStatus.Disconnected;

    case ActionTypes.SocketError:
      return WebSocketStatus.Error;

    default:
      return state;
  }
}
