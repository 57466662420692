import { AccessLevel } from 'libs/accessLevel';
import { Membership, User } from 'models/user';
import { UserGroup } from 'models/user_group';
import { uniq } from 'lodash';

export class MemberShips {
  memberships: Membership[];
  userGroups: UserGroup[];

  constructor(memberships: Membership[], userGroups: UserGroup[]) {
    this.memberships = memberships;
    this.userGroups = userGroups;
  }

  // その権限を持ったユーザー数をカウント
  // ユーザーグループの影響で重複してる場合は重複を除いた数を返す
  memberCount(accessLevel: AccessLevel): number {
    return uniq(
      this.memberships
        .filter((membership) => {
          if (accessLevel === AccessLevel.Admin) {
            return membership.access_level >= accessLevel;
          }
          return membership.access_level === accessLevel;
        })
        .flatMap((membership): User[] => {
          if (membership.entity_type === 'u') {
            return [membership.user];
          }
          // ユーザーグループの場合、そのユーザーグループに所属しているユーザーの数を返す
          const userGroup = this.userGroups.find(
            (userGroup) => userGroup.id === membership.user_group.id
          );
          if (userGroup) {
            return userGroup.users || [];
          }
          return [];
        })
        .map((user) => user.id)
    ).length;
  }
}
