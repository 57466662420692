import {
  Chart,
  ChartConfigs,
  ChartType,
  Color,
  DualAxisColumnLineType,
  FusionChartType,
  getInitColumnLineOrder,
  getInitOrder,
  getInitTooltip,
  getUseFusionChartType,
  Heatmap,
  Option,
  OrderOptions,
  OrderSetting,
  setDefaultTableOption,
  Table,
  TableColumn,
  TableColumnsV2,
  TableColumnsV3,
  TableColumnsV4,
  TableOption,
  tableOptionColumns,
  TableOptionName,
  TableOptionOrder,
  tableOptionTemplate,
  TableOptionType,
  Tooltip,
  View,
  Xtimeformattype,
  YValue
} from 'models/chart';
import { Draft, produce } from 'immer';
import {
  CategoricalColorType,
  getColors,
  Palette
} from 'components/visualize/chart/color';
import { getValueLabel } from 'components/visualize/transformer';
import { filterAggregate } from 'components/visualize/editor/util';
import { countBy, intersection, pick, union } from 'lodash-es';
import {
  ColumnSelectRules,
  SelectTypes as ColumnSelectV2SelectTypes,
  SelectTypes
} from 'models/form/value';
import { Dtypes } from 'Utils/dataTypes';
import { generateNewRule } from 'components/form/columnSelectV2Field';
import isEqual from 'react-fast-compare';
import {
  getColumnLabelsByRules,
  TableSettings
} from 'components/visualize/table/tableSettings';

export enum ChartAction {
  aggregated = 'aggregated',
  type = 'type',
  portId = 'portId',
  chart = 'chart',
  chartX = 'chartX',
  chartY = 'chartY',
  chartLegend = 'chartLegend',
  addChartY = 'add_chart_y',
  deleteChartY = 'delete_chart_y',
  dualAxisChartY = 'dual_axis_chart_y',
  dualAxisChartLegend = 'dual_axis_chart_legend',
  addDualAxisChartY = 'add_dual_axis_chart_y',
  deleteDualAxisChartY = 'delete_dual_axis_chart_y',
  dualAxisOrder = 'dual_axis_order',
  setTableColumns = 'set_table_columns',
  setTableColumnSelectRules = 'set_table_column_select_rules',
  setTableColumnAggKeyRules = 'set_table_column_agg_key_select_rules',
  setTableOption = 'set_table_option',
  moveTableColumns = 'move_table_columns', // sort: [string]を作る
  changeTableColumn = 'change_table_column',
  changeTableColumnWidth = 'change_table_column_width',
  changName = 'change_name',
  changeXTimeFormatType = 'change_x_time_format_type',
  view = 'view',
  order = 'order',
  color = 'color',
  tooltip = 'tooltip',
  changePalette = 'change_palette',
  dataFetched = 'data_fetched',
  load = 'load',
  loaded = 'loaded',
  save = 'save',
  aggregate = 'aggregate',
  originColumns = 'origin_columns'
}

interface TypeActionPayload {
  type: ChartType;
  columns: Option[];
}

interface SetTypeAction {
  type: ChartAction.type;
  payload: TypeActionPayload;
}

interface SetPortIdAction {
  type: ChartAction.portId;
  payload: { portId: string };
}

interface SetChartAction {
  type: ChartAction.chart;
  payload: ChartConfigs;
}

interface SetChartXAction {
  type: ChartAction.chartX;
  payload: {
    x: Option;
  };
}

interface SetChartYAction {
  type: ChartAction.chartY;
  payload: {
    index: number;
    value: YValue;
  };
}

interface AddChartYAction {
  type: ChartAction.addChartY;
}

interface DeleteChartYAction {
  type: ChartAction.deleteChartY;
  payload: { index: number };
}

interface SetChartLegend {
  type: ChartAction.chartLegend;
  payload: {
    legend?: Option;
  };
}

interface SetDualAxisChartYAction {
  type: ChartAction.dualAxisChartY;
  payload: {
    type: DualAxisColumnLineType;
    index: number;
    value: YValue;
  };
}

interface AddDualAxisChartYAction {
  type: ChartAction.addDualAxisChartY;
  payload: {
    type: DualAxisColumnLineType;
  };
}

interface DeleteDualAxisChartYAction {
  type: ChartAction.deleteDualAxisChartY;
  payload: { type: DualAxisColumnLineType; index: number };
}

interface SetDualAxisChartLegend {
  type: ChartAction.dualAxisChartLegend;
  payload: {
    type: DualAxisColumnLineType;
    legend?: Option;
  };
}

interface SetTableColumnSelectRulesAction {
  type: ChartAction.setTableColumnSelectRules;
  payload: {
    columnSelectRules: ColumnSelectRules;
  };
}

interface SetTableColumnAggKeyRulesAction {
  type: ChartAction.setTableColumnAggKeyRules;
  payload: {
    columnAggKeyRules: ColumnSelectRules;
  };
}

interface SetTableOptionsAction {
  type: ChartAction.setTableOption;
  payload: {
    option: TableOption;
  };
  meta?: { requireSave?: boolean; requiredFetch?: boolean };
}

interface SetViewAction {
  type: ChartAction.view;
  payload: Partial<View>;
  meta?: { requireSave?: boolean; requiredFetch?: boolean };
}

interface SetColorAction {
  type: ChartAction.color;
  payload: Partial<Color>;
  meta?: { requireSave: boolean };
}

interface DataFetchedAction {
  type: ChartAction.dataFetched;
}

interface LoadChart {
  type: ChartAction.load;
  payload?: Chart;
}

interface SaveChartAction {
  type: ChartAction.save;
  payload: { uuid?: string };
}

interface ChangeNameAction {
  type: ChartAction.changName;
  payload: {
    name: string;
  };
}

interface SetAggregatedAction {
  type: ChartAction.aggregated;
  payload: { aggregated: boolean };
}

interface MoveTableColumnsAction {
  type: ChartAction.moveTableColumns;
  payload: {
    columns: string[];
  };
}

interface ChangeTableColColumnAction {
  type: ChartAction.changeTableColumn;
  payload: {
    column: TableColumn;
  };
}

interface ChangeTableColumnWidthAction {
  type: ChartAction.changeTableColumnWidth;
  payload: {
    name: string;
    width: number;
  };
}

interface ChangePaletteAction {
  type: ChartAction.changePalette;
  payload: {
    palette: Palette;
  };
}

interface ChangeXTimeFormatAction {
  type: ChartAction.changeXTimeFormatType;
  payload: {
    xtimeformattype: Xtimeformattype;
  };
}

interface SetTooltipAction {
  type: ChartAction.tooltip;
  payload: Tooltip;
}

interface SetOrderAction {
  type: ChartAction.order;
  payload: Partial<OrderSetting>;
}

interface SetDualAxisOrderAction {
  type: ChartAction.dualAxisOrder;
  payload: Partial<{
    x: OrderOptions;
    legend: { column: OrderOptions; line: OrderOptions };
  }>;
}

interface SetOriginColumnsAction {
  type: ChartAction.originColumns;
  payload: {
    originColumns: Option[];
  };
}

export type ChartActionType =
  | SetTypeAction
  | SetPortIdAction
  | SetChartAction
  | SetChartXAction
  | SetChartYAction
  | AddChartYAction
  | DeleteChartYAction
  | SetChartLegend
  | SetTableColumnSelectRulesAction
  | SetTableColumnAggKeyRulesAction
  | SetTableOptionsAction
  | SetViewAction
  | SetColorAction
  | LoadChart
  | DataFetchedAction
  | SaveChartAction
  | ChangeNameAction
  | SetAggregatedAction
  | MoveTableColumnsAction
  | ChangeTableColColumnAction
  | ChangePaletteAction
  | ChangeXTimeFormatAction
  | ChangeTableColumnWidthAction
  | SetTooltipAction
  | SetOrderAction
  | SetDualAxisChartYAction
  | AddDualAxisChartYAction
  | DeleteDualAxisChartYAction
  | SetDualAxisChartLegend
  | SetDualAxisOrderAction
  | SetOriginColumnsAction;

export type ChartDispatch = (action: ChartActionType) => void;

export type ChartState = Chart & {
  requiredFetch: boolean;
  requireSave: boolean;
  loaded: boolean;
  originColumns?: Option[];
  useOriginColumns?: boolean; // /chart_queryでテーブルの列設定を無視してportの列をそのまま取得する
};

function cleanViewAttributes(draft: Draft<ChartState>): Draft<ChartState> {
  if (draft.type !== ChartType.scatter) {
    delete draft.view.yNumberPrefix;
    delete draft.view.xNumberPrefix;
    delete draft.view.yNumberSuffix;
    delete draft.view.xNumberSuffix;
    delete draft.view.yShowPercentage;
    delete draft.view.xShowPercentage;
    delete draft.view.yAxisValueDecimals;
    delete draft.view.xAxisValueDecimals;
    delete draft.view.xFormatNumberScale;
  }
  switch (draft.type) {
    case ChartType.scatter:
      delete draft.view.numberPrefix;
      delete draft.view.numberSuffix;
      delete draft.view.showPercentage;
      delete draft.view.decimals;
      delete draft.view.stack100Percent;
      delete draft.view.xFormatNumberScale;
      break;
    case ChartType.column:
    case ChartType.bar:
    case ChartType.area:
    case ChartType.boxAndWhisker:
      break;
    default:
      delete draft.view.stack100Percent;
      break;
  }
  if ([ChartType.line, ChartType.scatter].includes(draft.type)) {
    delete draft.view.dataPoints;
  }
  return draft;
}

function cleanChartAttributes(
  draft: Draft<ChartState>,
  payload: TypeActionPayload
): Draft<ChartState> {
  switch (draft.type) {
    case ChartType.bar:
      draft.chart = {
        x:
          draft.chart.x &&
          ['string', 'number'].includes(draft.chart.x.dtype || '')
            ? draft.chart.x
            : undefined,
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        stacked: draft.chart.stacked,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.column:
      draft.chart = {
        x: draft.chart.x,
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        stacked: draft.chart.stacked,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.area:
      draft.chart = {
        x: draft.chart.x,
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        stacked: true,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.line:
      draft.chart = {
        x: draft.chart.x,
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        xtimeformattype: draft.chart.xtimeformattype || undefined,
        multiAxis: false
      };
      draft.view.showValues = '0';
      break;
    case ChartType.radar:
      draft.chart = {
        x: draft.chart.x,
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.boxAndWhisker:
      draft.chart = {
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        aggregated: true,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.scatter:
      draft.chart = {
        x:
          draft.chart.x && draft.chart.x.dtype === 'number'
            ? draft.chart.x
            : undefined,
        y: draft.chart.y || [{}],
        legend: draft.chart.legend,
        aggregated: true
      };
      break;
    case ChartType.pie:
      draft.chart = {
        legend: draft.chart.legend,
        value: draft.chart.value || { func: 'SUM' },
        aggregated: true
      };
      draft.view.numberSuffix = undefined;
      draft.view.showPercentage = undefined;
      draft.view.showValues = '1';
      break;
    case ChartType.table:
      draft.chart = {
        columns: _migrateChartColumns(
          draft.chart.columns || payload.columns || []
        ),
        aggregated: true
      };
      draft.view = migrateViewHeatmap(draft.view);
      break;
    case ChartType.dualAxisColumnLine:
      draft.chart = {
        x: draft.chart.x,
        dualAxisY: { column: [{ func: 'SUM' }], line: [{ func: 'SUM' }] },
        dualAxisOrder: getInitColumnLineOrder(),
        aggregated: true,
        xtimeformattype: draft.chart.xtimeformattype || undefined
      };
      draft.view.showValues = '0';
      break;
    case ChartType.histogram:
      draft.chart = {
        y: draft.chart.y || [{ func: 'SUM' }],
        legend: draft.chart.legend,
        numBuckets: 10,
        aggregated: true
      };
      break;
  }

  if (
    draft.type !== ChartType.table &&
    draft.type !== ChartType.dualAxisColumnLine
  ) {
    draft.chart.order = getInitOrder();
  }
  return draft;
}

function cleanOrder(_opt: OrderOptions, type: ChartType): OrderOptions {
  let opt = { ..._opt };
  if (
    opt.target === 'x' &&
    Object.prototype.hasOwnProperty.call(opt, 'summarise')
  ) {
    delete opt.summarise;
  }

  if (opt.target !== 'x' && opt.summarise == undefined) {
    opt.summarise = 'SUM';
  }

  if (opt.target !== 'specific') {
    delete opt.column;
  }

  if (opt.column != undefined) {
    const yAggregates = filterAggregate(
      type === ChartType.pie
        ? {
            type,
            value: opt.column
          }
        : {
            type: ChartType.column,
            y: opt.column
          }
    );

    const aggregateValidate =
      yAggregates.findIndex((agg) => agg.value === opt.column?.func) > 0;
    if (!aggregateValidate) {
      opt.column = { ...opt.column, func: yAggregates[0].value };
    }
  }

  return opt;
}

function migrateChartColumnsV1toV2(
  columns: Option[] | TableColumn[]
): TableColumnsV2 {
  const columnsV1: TableColumn[] = columns;
  const columnNames = columnsV1.map((col) => col.value);
  const columnSelectRules: ColumnSelectRules = [
    {
      type: ColumnSelectV2SelectTypes.column_names,
      exclude: false,
      value: columnNames
    }
  ];

  const columnV2: TableColumnsV2 = {
    version: 2,
    columns: {
      columnSelectRules,
      selectedColumns: columnsV1
    }
  };

  return columnV2;
}

function migrateChartColumnsV2toV3(columns: TableColumnsV2): TableColumnsV3 {
  let columnV3: TableColumnsV3 = {
    version: 3,
    columns: columns.columns,
    options: generateOptionsV3(columns.columns.selectedColumns)
  };

  return columnV3;
}

function migrateChartColumnsV3toV4(columns: TableColumnsV3): TableColumnsV4 {
  let columnV3: TableColumnsV4 = {
    version: 4,
    columns: {
      columnSelectRules: columns.columns.columnSelectRules,
      tableDisplayColumnsWithOption: columns.columns.selectedColumns || [],
      columnAggKeyRules: [
        {
          type: ColumnSelectV2SelectTypes.column_names,
          exclude: false,
          value: []
        }
      ],
      queryArgColumns: []
    },
    options: columns.options
  };
  const tableSettings = new TableSettings(
    { columns: columnV3, aggregated: true },
    columns.columns.selectedColumns || []
  );
  columnV3.columns.queryArgColumns = tableSettings.queryArgColumns;
  columnV3.columns.tableDisplayColumnsWithOption =
    tableSettings.tableDisplayColumnsWithOption;
  return columnV3;
}

function generateOptionsV3(
  tableColumns: TableColumn[]
): TableColumnsV3['options'] {
  let options: TableColumnsV3['options'] = tableOptionTemplate;

  tableColumns.forEach((col) => {
    options = updateOptionsV3(col, options);
  });

  return options;
}

function updateOptionsV3(
  tableColumn: TableColumn,
  optionsV3: TableColumnsV3['options'],
  targetName?: TableOptionName
): TableColumnsV3['options'] {
  const label = tableColumn.label;
  return produce(optionsV3, (draft) => {
    draft.forEach((row) => {
      const optionName = row.option.name;
      if (targetName && targetName !== optionName) {
        // targetNameを指定した場合はそのオプションのみ更新
        return;
      }
      const opColumns = tableOptionColumns[optionName];

      switch (row.type) {
        case TableOptionType.bool: {
          const isAdd = Boolean(tableColumn[opColumns[0]]);
          if (row.option.option.columnSelectRules.length === 0) {
            // なければ追加
            row.option.option = {
              columnSelectRules: [generateNewRule()]
            };
          }
          const rule = row.option.option.columnSelectRules[0];
          if (rule.type === SelectTypes.column_names && !rule.exclude) {
            if (isAdd) {
              // 追加
              rule.value = rule.value.includes(label)
                ? rule.value
                : [...rule.value, label];
            } else {
              // 削除
              rule.value = rule.value.filter((name) => name != label);
            }
          }
          break;
        }

        case TableOptionType.multiple: {
          if (
            optionName === TableOptionName.formatDate &&
            tableColumn.dtype !== Dtypes.DATE
          )
            return;
          if (
            optionName === TableOptionName.formatTimestamp &&
            tableColumn.dtype !== Dtypes.TIMESTAMP
          )
            return;
          if (
            optionName === TableOptionName.formatTime &&
            tableColumn.dtype !== Dtypes.TIME
          )
            return;

          const value = tableColumn[opColumns[0]];
          const isAdd = value != undefined && value !== '' && value !== false; // 0の時はisAdd = trueにしたいのでBooleanを使わない
          if (optionName === TableOptionName.dir && isAdd) {
            // 並び替えの場合は一つの列しか設定できないようにしたいので、毎回削除してから追加する
            row.option.option = [];
          }

          if (isAdd) {
            // 追加
            let isAdded = false;
            row.option.option.forEach((op) => {
              const rule = op.columnSelectRules[0];
              // rulesがcolumn_namesのみ
              if (!(rule.type === SelectTypes.column_names && !rule.exclude))
                return;

              if (!isAdded && isEqual(rule.value, [label])) {
                // その列名のみだったら変更する
                op.option = {
                  ...op.option,
                  ...pick(tableColumn, opColumns)
                };
                isAdded = true;
                return;
              }

              if (
                !isAdded &&
                isEqual(op.option, pick(tableColumn, opColumns)) &&
                !rule.value.includes(label)
              ) {
                // 同じ設定かつその列名が入ってないなら追加する
                rule.value = [...rule.value, label];
                isAdded = true;
                return;
              }
              if (
                !isEqual(op.option, pick(tableColumn, opColumns)) &&
                rule.value.includes(label)
              ) {
                // 別設定かつその列名が入っているなら削除する
                rule.value = rule.value.filter((name) => name != label);
                return;
              }
            });
            if (!isAdded) {
              // 追加されてないなら追加
              row.option.option.push({
                columnSelectRules: [generateNewRule([label])],
                option: pick(tableColumn, opColumns)
              });
            }
          } else {
            // 削除
            row.option.option.forEach((op) => {
              const rule = op.columnSelectRules[0];
              // rulesがcolumn_namesのみ
              if (!(rule.type === SelectTypes.column_names && !rule.exclude))
                return;

              if (rule.value.includes(label)) {
                rule.value = rule.value.filter((name) => name != label);
              }
            });
          }

          // rulesがcolumn_namesなのに列が一つも選択されてない行は削除
          row.option.option = row.option.option.filter((op) => {
            const rule = op.columnSelectRules[0];
            // rulesがcolumn_namesのみ
            if (!(rule.type === SelectTypes.column_names && !rule.exclude)) {
              return true;
            }

            return rule.value.length > 0;
          });

          break;
        }

        case TableOptionType.barColor: {
          const progressColorPalette = tableColumn.progressColorPalette ?? {
            type: CategoricalColorType.Set2
          };
          const color = tableColumn.color ?? getColors(progressColorPalette)[0];

          const isAdd = Boolean(tableColumn[opColumns[0]]);
          if (isAdd) {
            // 追加
            let isAdded = false;
            row.option.option.forEach((op) => {
              const rule = op.columnSelectRules[0];
              // rulesがcolumn_namesのみ
              if (!(rule.type === SelectTypes.column_names && !rule.exclude))
                return;

              if (
                !isAdded &&
                isEqual(op.progressColorPalette, progressColorPalette)
              ) {
                // 同じカラーパレットなら追加変更する
                rule.value = rule.value.includes(label)
                  ? rule.value
                  : [...rule.value, label];
                op.colors[label] = color;
                isAdded = true;
                return;
              }
              if (
                !isEqual(op.progressColorPalette, progressColorPalette) &&
                rule.value.includes(label)
              ) {
                // 別カラーパレットかつその列名が入っているなら削除する
                rule.value = rule.value.filter((name) => name != label);
                delete op.colors[label];
                return;
              }
            });
            if (!isAdded) {
              // 追加されてないなら追加
              row.option.option.push({
                columnSelectRules: [generateNewRule([label])],
                progressColorPalette: progressColorPalette,
                colors: { [label]: color }
              });
            }
          } else {
            // 削除
            row.option.option.forEach((op) => {
              const rule = op.columnSelectRules[0];
              // rulesがcolumn_namesのみ
              if (!(rule.type === SelectTypes.column_names && !rule.exclude))
                return;

              if (rule.value.includes(label)) {
                rule.value = rule.value.filter((name) => name != label);
                delete op.colors[label];
              }
            });
          }

          // rulesがcolumn_namesなのに列が一つも選択されてない行は削除
          row.option.option = row.option.option.filter((op) => {
            const rule = op.columnSelectRules[0];
            // rulesがcolumn_namesのみ
            if (!(rule.type === SelectTypes.column_names && !rule.exclude)) {
              return true;
            }

            return rule.value.length > 0;
          });

          break;
        }

        case TableOptionType.stringCellColor: {
          if (tableColumn.stringCellColor == undefined) return;

          const isAdd = Boolean(tableColumn.stringCellColor.show);
          if (row.option.option.columnSelectRules.length === 0) {
            // なければ追加
            row.option.option = {
              columnSelectRules: [generateNewRule([label])],
              stringCellColor: {}
            };
          }

          // rulesがcolumn_namesのみ
          const rule = row.option.option.columnSelectRules[0];
          if (!(rule.type === SelectTypes.column_names && !rule.exclude))
            return;

          if (isAdd) {
            // 追加
            rule.value = rule.value.includes(label)
              ? rule.value
              : [...rule.value, label];
            row.option.option.stringCellColor[label] =
              tableColumn.stringCellColor;
          } else {
            // 削除
            rule.value = rule.value.filter((name) => name != label);
            delete row.option.option.stringCellColor[label];
          }
          break;
        }

        case TableOptionType.width: {
          if (tableColumn.width == undefined) return;
          row.option.option.width[label] = tableColumn.width;

          break;
        }
      }
    });
  });
}

const checkboxOptions = {
  palette: 'showCellColor',
  textColorPalette: 'setTextColor',
  timeFormatType: 'toFormat'
};

type TableColumnVersions =
  | Option[]
  | TableColumn[]
  | TableColumnsV2
  | TableColumnsV3
  | TableColumnsV4;

const getTableColumnsVersion = (columns: TableColumnVersions): number => {
  if (Array.isArray(columns)) {
    return 1;
  }

  return columns['version'];
};

function _migrateChartColumns(columns: TableColumnVersions): TableColumnsV4 {
  const version = getTableColumnsVersion(columns);
  if (version === 4) {
    return columns as TableColumnsV4;
  }
  if (version === 3) {
    return migrateChartColumnsV3toV4(columns as TableColumnsV3);
  }
  if (version === 2) {
    const columnsV3 = migrateChartColumnsV2toV3(columns as TableColumnsV2);
    return migrateChartColumnsV3toV4(columnsV3);
  }
  const columnsV2 = migrateChartColumnsV1toV2(
    columns as Option[] | TableColumn[]
  );
  const columnsV2WithDefault = setDefaultTableOption(columnsV2);
  const columnsV3 = migrateChartColumnsV2toV3(columnsV2WithDefault);
  return migrateChartColumnsV3toV4(columnsV3);
}

export function migrateChartColumns(chart: Chart): Chart {
  if (chart.type !== ChartType.table) {
    return chart;
  }
  const columns = chart.chart.columns;
  if (!columns) {
    return chart;
  }
  const version = getTableColumnsVersion(columns);
  const columnsV4 = _migrateChartColumns(columns);
  return produce(chart, (draft) => {
    draft.chart.columns = columnsV4;
    if (version !== 4) {
      draft.chart.aggregated = true;
    }
    return draft;
  });
}

// テーブル設定を最新状態にする
// - 古いバージョンの場合はmigrateする
// - 列が更新されている場合があるので、列選択ルールに基づいて列の選択を行う
export function toLatestChartSettings(chart: Chart): Chart {
  return produce(chart, (draft) => {
    if (draft.type !== ChartType.table) {
      return draft;
    }
    const migrated = migrateChartColumns(draft);
    const tableSettings = new TableSettings(
      migrated.chart as Table,
      draft.originColumns || []
    );
    draft.chart = tableSettings.settings;
    return draft;
  });
}
// 選択列、ヒートマップ列、オプションが更新された時にこの関数でselectedColumnsを更新する
export function getOptionReflectedColumns(
  columnsV3: TableColumnsV4,
  targetColumns: Option[]
): TableColumn[] {
  let tableColumns: TableColumn[] = targetColumns;

  // 列名ごとの設定取得
  columnsV3.options.forEach((op) => {
    const optionName = op.option.name;
    const opColumns = tableOptionColumns[optionName];
    switch (op.type) {
      case TableOptionType.bool: {
        const columnLabels = getColumnLabelsByRules(
          targetColumns,
          op.option.option.columnSelectRules
        );
        tableColumns = tableColumns.map((col) => {
          if (columnLabels.includes(col.label)) {
            return { ...col, [opColumns[0]]: true };
          }
          return col;
        });
        break;
      }
      case TableOptionType.multiple: {
        op.option.option.map((option) => {
          const columnLabels = getColumnLabelsByRules(
            targetColumns,
            option.columnSelectRules
          );
          // 元々チェックボックスがついてたオプションの場合、まとめて変更の場合はチェックボックスがないため、列選択時に true にする必要がある
          let boolOption = {};
          const keys = intersection(
            Object.keys(option.option),
            Object.keys(checkboxOptions)
          );
          if (keys.length > 0) {
            boolOption = {
              [checkboxOptions[keys[0]]]: true
            };
          }

          tableColumns = tableColumns.map((col) => {
            if (columnLabels.includes(col.label)) {
              return { ...col, ...option.option, ...boolOption };
            }
            return col;
          });
        });
        break;
      }
      case TableOptionType.stringCellColor: {
        const columnNames = Object.keys(op.option.option.stringCellColor).map(
          (columnName) => columnName
        );
        tableColumns = tableColumns.map((col) => {
          if (columnNames.includes(col.label)) {
            return {
              ...col,
              stringCellColor: op.option.option.stringCellColor[col.label]
            };
          }
          return col;
        });
        break;
      }
      case TableOptionType.barColor: {
        op.option.option.map((option) => {
          const columnLabels = getColumnLabelsByRules(
            targetColumns.filter((col) => col.dtype === Dtypes.NUMBER),
            option.columnSelectRules
          );
          tableColumns = tableColumns.map((col) => {
            if (columnLabels.includes(col.label)) {
              return {
                ...col,
                showProgress: true,
                progressColorPalette: option.progressColorPalette,
                color: option.colors[col.label]
              };
            }
            return col;
          });
        });
        break;
      }
      case TableOptionType.width: {
        tableColumns = tableColumns.map((col) => {
          if (Object.keys(op.option.option.width).includes(col.label)) {
            return {
              ...col,
              width: op.option.option.width[col.label]
            };
          }
          return col;
        });
        break;
      }

      case TableOptionType.order: {
        if (op.option.option.order == undefined) return;
        if (
          op.option.option.order.length !==
          union(
            op.option.option.order,
            tableColumns.map((col) => col.label)
          ).length
        ) {
          // 列が変わっていたら並びをtableColumnsのままにしておく
          return;
        }
        tableColumns.sort(
          (a, b) =>
            op.option.option.order.indexOf(a.label) -
            op.option.option.order.indexOf(b.label)
        );
        break;
      }
    }
  });
  return tableColumns;
}

export function migrateViewHeatmap(view: View): View {
  return produce(view, (draft) => {
    if (!Object.keys(draft).includes('heatmap')) {
      const heatmap: Heatmap = {
        show: draft['isHeatmap'],
        columnSelectRules: [
          {
            type: ColumnSelectV2SelectTypes.column_names,
            exclude: false,
            value: draft['heatmapColNames'] ?? []
          }
        ],
        range: {
          max: draft['heatmapMax'],
          min: draft['heatmapMin']
        },
        palette: draft['heatmapPalette']
      };
      delete draft['isHeatmap'];
      delete draft['heatmapColNames'];
      delete draft['heatmapMin'];
      delete draft['heatmapMax'];
      delete draft['heatmapPalette'];
      draft.heatmap = heatmap;
      return draft;
    }
    return draft;
  });
}

export function chartReducer(
  state: ChartState,
  action: ChartActionType
): ChartState {
  switch (action.type) {
    case ChartAction.load: {
      if (action.payload == undefined) {
        return produce(state, (draft) => {
          draft.loaded = true;
          return draft;
        });
      }

      let payload: Chart = { ...action.payload };

      // tooltipの初期化
      if (payload.view.tooltip == undefined) {
        payload = {
          ...payload,
          view: { ...payload.view, tooltip: getInitTooltip() }
        };
      }

      // orderの初期化
      if (
        payload.type !== ChartType.table &&
        payload.type !== ChartType.dualAxisColumnLine &&
        payload.chart.order == undefined
      ) {
        payload = {
          ...payload,
          chart: { ...payload.chart, order: getInitOrder() }
        };
      }

      if (
        payload.type === ChartType.table &&
        payload.chart.columns !== undefined
      ) {
        const settings = migrateChartColumns(payload);
        const settingsView = migrateViewHeatmap(settings.view);
        return {
          ...state,
          ...{ ...settings, view: settingsView },
          requiredFetch: true,
          requireSave: false,
          loaded: true
        };
      }

      try {
        if (getUseFusionChartType(payload) === FusionChartType.TimeSeries) {
          // timeseriesの場合はツールチップの「自分で設定する」を強制off
          if (payload.view.tooltip) {
            payload.view.tooltip.useCustomTooltip = false;
          }
        }
      } catch {}

      return {
        ...state,
        ...payload,
        requiredFetch: true,
        requireSave: false,
        loaded: true
      };
    }

    case ChartAction.save:
      return produce(state, (draft) => {
        if (action.payload.uuid !== undefined) {
          draft.uuid = action.payload.uuid;
        }
        draft.requireSave = false;
        return draft;
      });
    case ChartAction.aggregated:
      return produce(state, (draft) => {
        draft.chart = { ...draft.chart, ...action.payload };
        if (
          (draft.type === ChartType.line ||
            draft.type === ChartType.column ||
            draft.type === ChartType.bar ||
            draft.type === ChartType.area ||
            draft.type === ChartType.radar) &&
          draft.chart.y != undefined &&
          draft.chart.y.length > 0
        ) {
          draft.view.yAxisName = getValueLabel(
            draft.chart.aggregated || false,
            draft.chart.y[0]
          );
        }

        if (
          draft.type !== ChartType.table &&
          draft.type !== ChartType.boxAndWhisker &&
          draft.type !== ChartType.dualAxisColumnLine
        ) {
          if (draft.chart.order == undefined) {
            draft.chart.order = getInitOrder();
          }

          if (draft.chart.aggregated) {
            if (draft.chart.order.x.column) {
              delete draft.chart.order.x.column.func;
            }
            if (draft.chart.order.legend.column) {
              delete draft.chart.order.legend.column.func;
            }
          } else {
            draft.chart.order.x.column = {
              ...draft.chart.order.x.column,
              func: 'SUM'
            };
            draft.chart.order.legend.column = {
              ...draft.chart.order.legend.column,
              func: 'SUM'
            };
          }
        }

        if (draft.type === ChartType.dualAxisColumnLine) {
          if (draft.chart.dualAxisY != undefined) {
            if (draft.chart.dualAxisY.column.length > 0) {
              draft.view.yAxisName = getValueLabel(
                draft.chart.aggregated || false,
                draft.chart.dualAxisY.column[0]
              );
            }
            if (draft.chart.dualAxisY.line.length > 0) {
              draft.view.y2AxisName = getValueLabel(
                draft.chart.aggregated || false,
                draft.chart.dualAxisY.line[0]
              );
            }
          }

          if (draft.chart.dualAxisOrder == undefined) {
            draft.chart.dualAxisOrder = getInitColumnLineOrder();
          }
          if (draft.chart.aggregated) {
            if (draft.chart.dualAxisOrder.x.column) {
              delete draft.chart.dualAxisOrder.x.column.func;
            }
            if (draft.chart.dualAxisOrder.legend.column.column) {
              delete draft.chart.dualAxisOrder.legend.column.column.func;
            }
            if (draft.chart.dualAxisOrder.legend.line.column) {
              delete draft.chart.dualAxisOrder.legend.line.column.func;
            }
          } else {
            draft.chart.dualAxisOrder.x.column = {
              ...draft.chart.dualAxisOrder.x.column,
              func: 'SUM'
            };
            draft.chart.dualAxisOrder.legend.column.column = {
              ...draft.chart.dualAxisOrder.legend.column.column,
              func: 'SUM'
            };
            draft.chart.dualAxisOrder.legend.line.column = {
              ...draft.chart.dualAxisOrder.legend.line.column,
              func: 'SUM'
            };
          }
        }

        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.type:
      return produce(state, (draft) => {
        draft.type = action.payload.type;
        draft = cleanViewAttributes(draft);
        draft = cleanChartAttributes(draft, action.payload);
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.portId:
      return produce(state, (draft) => {
        draft.port_id = action.payload.portId;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.chart:
      return produce(state, (draft) => {
        draft.chart = { ...draft.chart, ...action.payload };
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.chartX:
      return produce(state, (draft) => {
        draft.chart = { ...draft.chart, ...action.payload };
        if (draft.type !== ChartType.histogram) {
          draft.view.xAxisName = action.payload.x ? action.payload.x.value : '';
        }
        if (
          (draft.type === ChartType.line ||
            draft.type === ChartType.column ||
            draft.type === ChartType.bar ||
            draft.type === ChartType.area ||
            draft.type === ChartType.radar ||
            draft.type === ChartType.dualAxisColumnLine) &&
          action.payload.x &&
          action.payload.x.dtype != undefined
        ) {
          if (action.payload.x.dtype === 'date') {
            draft.chart.xtimeformattype = Xtimeformattype.day;
          } else if (action.payload.x.dtype === 'timestamp') {
            draft.chart.xtimeformattype = Xtimeformattype.second;
          } else if (action.payload.x.dtype === 'time') {
            draft.chart.xtimeformattype = Xtimeformattype.time_second;
          } else {
            draft.chart.xtimeformattype = undefined;
            draft.chart.xtimeformat = undefined;
          }
        }
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.chartY:
      return produce(state, (draft) => {
        switch (draft.type) {
          case ChartType.dualAxisColumnLine:
          case ChartType.table:
            return draft;
          case ChartType.pie: {
            draft.chart.value = action.payload.value;
            const yAggregates = filterAggregate({
              type: draft.type,
              value: action.payload.value
            });

            const aggregateValidate =
              yAggregates.findIndex(
                (agg) => agg.value === action.payload.value.func
              ) > 0;
            if (!aggregateValidate) {
              draft.chart.value.func = yAggregates[0].value;
            }

            draft.view.yAxisName = getValueLabel(
              draft.chart.aggregated || false,
              draft.chart.value
            );
            break;
          }
          default: {
            if (draft.chart.y == undefined) {
              draft.chart.y = [];
            }
            draft.chart.y[action.payload.index] = action.payload.value;
            if (draft.type !== ChartType.histogram) {
              if (draft.chart.y.length > 1) {
                draft.view.yAxisName = '';
              } else {
                draft.view.yAxisName = getValueLabel(
                  draft.type === ChartType.scatter
                    ? true
                    : draft.chart.aggregated || false,
                  action.payload.value
                );
              }
            }

            if (draft.type !== ChartType.scatter) {
              const yAggregates = filterAggregate({
                type: draft.type,
                y: action.payload.value
              });

              const aggregateValidate =
                yAggregates.findIndex(
                  (agg) => agg.value === action.payload.value.func
                ) > 0;
              if (!aggregateValidate) {
                draft.chart.y[action.payload.index].func = yAggregates[0].value;
              }
            }

            break;
          }
        }

        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.dualAxisChartY:
      return produce(state, (draft) => {
        const { type, index, value } = action.payload;
        if (draft.type !== ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.chart.dualAxisY == undefined) {
          draft.chart.dualAxisY = {
            column: [],
            line: []
          };
        }
        draft.chart.dualAxisY[type][index] = value;
        const y = draft.chart.dualAxisY[type];
        let yAxisName = '';
        if (y.length > 1) {
          yAxisName = '';
        } else {
          yAxisName = getValueLabel(draft.chart.aggregated || false, value);
        }
        if (type === 'column') {
          draft.view.yAxisName = yAxisName;
        } else {
          draft.view.y2AxisName = yAxisName;
        }

        const yAggregates = filterAggregate({
          type: draft.type,
          y: value
        });

        const aggregateValidate =
          yAggregates.findIndex((agg) => agg.value === value.func) > 0;
        if (!aggregateValidate) {
          draft.chart.dualAxisY[type][index].func = yAggregates[0].value;
        }
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.addChartY:
      return produce(state, (draft) => {
        if (draft.type === ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (
          draft.type !== ChartType.pie &&
          draft.type !== ChartType.table &&
          draft.chart.y
        ) {
          draft.chart.y.push({ func: 'SUM' });
          if (
            draft.chart.y.length > 1 &&
            draft.chart.order != undefined &&
            draft.chart.order.legend != undefined &&
            draft.chart.order.legend.target === 'specific'
          ) {
            draft.chart.order.legend.target = 'x';
            delete draft.chart.order.legend.column;
          }
          if (!draft.view.tooltip) {
            draft.view.tooltip = getInitTooltip();
            draft.view.tooltip.showLegend = true;
          } else {
            draft.view.tooltip.showLegend = true;
          }
          draft.requiredFetch = true;
          draft.requireSave = true;
        }
        return draft;
      });
    case ChartAction.addDualAxisChartY:
      return produce(state, (draft) => {
        if (draft.type !== ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.chart.dualAxisY == undefined) {
          draft.chart.dualAxisY = {
            column: [],
            line: []
          };
        }
        const { type } = action.payload;
        draft.chart.dualAxisY[type].push({ func: 'SUM' });
        if (
          draft.chart.dualAxisY[type].length > 1 &&
          draft.chart.dualAxisOrder != undefined &&
          draft.chart.dualAxisOrder.legend[type] != undefined &&
          draft.chart.dualAxisOrder.legend[type].target === 'specific'
        ) {
          // 縦軸が二つ以上あったら凡例を削除
          draft.chart.dualAxisOrder.legend[type].target = 'x';
          delete draft.chart.dualAxisOrder.legend[type].column;
        }
        if (!draft.view.tooltip) {
          draft.view.tooltip = getInitTooltip();
          draft.view.tooltip.showLegend = true;
        } else {
          draft.view.tooltip.showLegend = true;
        }
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.deleteChartY:
      return produce(state, (draft) => {
        if (draft.type === ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (
          draft.type !== ChartType.pie &&
          draft.type !== ChartType.table &&
          draft.chart.y
        ) {
          draft.chart.y = draft.chart.y.filter(
            (_, index) => index !== action.payload.index
          );
          draft.requiredFetch = true;
          draft.requireSave = true;
        }

        if (
          draft.type === ChartType.line &&
          draft.chart.y &&
          draft.chart.y.length < 2
        ) {
          // 縦軸の数が2未満の場合は2軸設定をoffにする
          draft.chart.multiAxis = false;
        }
        return draft;
      });
    case ChartAction.deleteDualAxisChartY:
      return produce(state, (draft) => {
        if (draft.type !== ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.chart.dualAxisY == undefined) {
          return;
        }
        draft.chart.dualAxisY[action.payload.type] = draft.chart.dualAxisY[
          action.payload.type
        ].filter((_, index) => index !== action.payload.index);
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.chartLegend: {
      return produce(state, (draft) => {
        if (draft.type === ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.type !== ChartType.table) {
          draft.chart.legend = action.payload.legend;
          if (draft.view.tooltip) {
            draft.view.tooltip.showLegend = draft.chart.legend ? true : false;
          }
        }

        if (draft.type !== ChartType.table) {
          if (draft.chart.order == undefined) {
            draft.chart.order = getInitOrder();
          }
          draft.chart.order.x.summarise = 'SUM';
          draft.chart.order.legend.summarise = 'SUM';
        }

        if (draft.type === ChartType.line && !action.payload.legend) {
          // 凡例を削除した場合はは2軸設定をoffにする
          draft.chart.multiAxis = false;
        }

        draft.color.colors = null;
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    }
    case ChartAction.dualAxisChartLegend: {
      return produce(state, (draft) => {
        const { type, legend } = action.payload;
        if (draft.type !== ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.chart.dualAxisLegend == undefined) {
          draft.chart.dualAxisLegend = {};
        }
        draft.chart.dualAxisLegend[type] = legend;
        if (draft.view.tooltip) {
          draft.view.tooltip.showLegend = draft.chart.dualAxisLegend[type]
            ? true
            : false;
        }
        if (draft.chart.dualAxisOrder == undefined) {
          draft.chart.dualAxisOrder = getInitColumnLineOrder();
        }
        draft.chart.dualAxisOrder.x.summarise = 'SUM';
        draft.chart.dualAxisOrder.legend[type].summarise = 'SUM';

        draft.color.colors = null;
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    }
    case ChartAction.setTableColumnSelectRules:
      return produce(state, (draft) => {
        if (draft.type !== ChartType.table) {
          return draft;
        }
        if (!draft.chart.columns) {
          return draft;
        }
        draft.chart.columns.columns.columnSelectRules =
          action.payload.columnSelectRules;
        const tableSettings = new TableSettings(
          draft.chart as Table,
          draft.originColumns || []
        );
        draft.chart = tableSettings.settings;

        draft.requireSave = true;
        draft.requiredFetch = true;
        return draft;
      });
    case ChartAction.setTableColumnAggKeyRules:
      return produce(state, (draft) => {
        if (draft.type !== ChartType.table) {
          return draft;
        }
        if (!draft.chart.columns) {
          return draft;
        }
        draft.chart.columns.columns.columnAggKeyRules =
          action.payload.columnAggKeyRules;
        const tableSettings = new TableSettings(
          draft.chart as Table,
          draft.originColumns || []
        );
        draft.chart = tableSettings.settings;

        draft.requireSave = true;
        draft.requiredFetch = true;
        return draft;
      });
    case ChartAction.setTableOption:
      const s = produce(state, (draft) => {
        if (draft.type !== ChartType.table) {
          return draft;
        }
        if (!draft.chart.columns) {
          return draft;
        }
        const { option } = action.payload;
        // 対象のオプションのみを更新
        let find = false;
        draft.chart.columns.options = draft.chart.columns.options.map((row) => {
          if (row.option.name === option.option.name) {
            find = true;
            return option;
          }
          return row;
        });
        if (!find) {
          // 存在しない場合、追加する
          draft.chart.columns.options = [
            ...draft.chart.columns.options,
            option
          ];
        }
        // オプションが更新されたら、selectedColumnsも更新する
        const tableSettings = new TableSettings(
          draft.chart as Table,
          draft.originColumns || []
        );
        draft.chart = tableSettings.settings;

        if (!action.meta || action.meta.requireSave) {
          draft.requireSave = true;
        }
        if (action.meta?.requiredFetch) {
          draft.requiredFetch = true;
        }
        return draft;
      });
      return s;
    case ChartAction.moveTableColumns:
      return produce(state, (draft) => {
        if (
          draft.type === ChartType.table &&
          draft.chart.columns != undefined
        ) {
          draft.chart.columns.columns.tableDisplayColumnsWithOption.sort(
            (a, b) => {
              return action.payload.columns.indexOf(a.value) >
                action.payload.columns.indexOf(b.value)
                ? 1
                : -1;
            }
          );
          // 他の列設定に合わせてlabelを使ってorderを作成する
          const option: TableOptionOrder = {
            name: TableOptionName.order,
            option: {
              order:
                draft.chart.columns.columns.tableDisplayColumnsWithOption.map(
                  (col) => col.label
                )
            }
          };
          draft.chart.columns.options = draft.chart.columns.options.map(
            (op) => {
              if (op.type === TableOptionType.order) {
                return {
                  ...op,
                  option
                };
              }
              return op;
            }
          );
          draft.requireSave = true;
        }
        return draft;
      });

    case ChartAction.changeTableColumnWidth:
      return produce(state, (draft) => {
        if (
          draft.type === ChartType.table &&
          draft.chart.columns != undefined
        ) {
          const colIndex =
            draft.chart.columns.columns.tableDisplayColumnsWithOption.findIndex(
              (col) => col.value === action.payload.name // action.payload.nameにはTableColumn.valueが入っている
            );
          if (colIndex < 0) {
            return draft;
          }
          draft.chart.columns.columns.tableDisplayColumnsWithOption[colIndex] =
            {
              ...draft.chart.columns.columns.tableDisplayColumnsWithOption[
                colIndex
              ],
              width: action.payload.width
            };
          draft.chart.columns.options = updateOptionsV3(
            draft.chart.columns.columns.tableDisplayColumnsWithOption[colIndex],
            draft.chart.columns.options,
            TableOptionName.width
          );
          draft.requireSave = true;
        }
        return draft;
      });

    case ChartAction.changeTableColumn:
      return produce(state, (draft) => {
        if (
          draft.type === ChartType.table &&
          draft.chart.columns != undefined
        ) {
          draft.chart.columns.options = updateOptionsV3(
            action.payload.column,
            draft.chart.columns.options
          );
          draft.chart.columns.columns.tableDisplayColumnsWithOption =
            draft.chart.columns.columns.tableDisplayColumnsWithOption.map(
              (col) =>
                col.value === action.payload.column.value
                  ? action.payload.column
                  : col
            );
          draft.requireSave = true;
        }
        return draft;
      });
    case ChartAction.changeXTimeFormatType:
      return produce(state, (draft) => {
        if (
          draft.type != ChartType.pie &&
          draft.type != ChartType.table &&
          draft.type != ChartType.scatter &&
          draft.type != ChartType.histogram
        ) {
          draft.chart.xtimeformattype = action.payload.xtimeformattype;
          if (action.payload.xtimeformattype === 'custom') {
            draft.chart.xtimeformat = '%Y-%m-%d';
          }
        }
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.view:
      return produce(state, (draft) => {
        draft.view = { ...draft.view, ...action.payload };
        if (!action.meta || action.meta.requireSave) {
          draft.requireSave = true;
        }
        if (!action.meta || action.meta.requiredFetch) {
          draft.requiredFetch = true;
        }
      });
    case ChartAction.color:
      if (!state.loaded) {
        return state;
      }
      return produce(state, (draft) => {
        const newColor = { ...draft.color, ...action.payload };
        draft.color = newColor;
        if (!action.meta || action.meta.requireSave) {
          draft.requireSave = true;
        }
        return draft;
      });
    case ChartAction.changePalette:
      return produce(state, (draft) => {
        draft.color.palette = action.payload.palette;
        const colors = getColors(action.payload.palette);
        if (draft.color.colors) {
          const keys = Object.keys(draft.color.colors);
          keys.sort((a, b) =>
            a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            })
          );
          const newColors: { [name: string]: string } = {};
          keys.forEach((key, i) => {
            newColors[key] = colors[i % colors.length];
          });
          draft.color.colors = newColors;
        }

        if (draft.color.color) {
          draft.color.color = colors[0];
        }
        draft.requireSave = true;
        return draft;
      });

    case ChartAction.tooltip: {
      return produce(state, (draft) => {
        draft.view.tooltip = { ...draft.view.tooltip, ...action.payload };
        draft.requireSave = true;
        return draft;
      });
    }

    case ChartAction.order: {
      return produce(state, (draft) => {
        if (draft.type === ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.type !== ChartType.table) {
          if (draft.chart.order == undefined) {
            draft.chart.order = getInitOrder();
          }
          draft.chart.order = { ...draft.chart.order, ...action.payload };
          draft.chart.order.x = cleanOrder(draft.chart.order.x, draft.type);
          draft.chart.order.legend = cleanOrder(
            draft.chart.order.legend,
            draft.type
          );
        }
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    }
    case ChartAction.dualAxisOrder: {
      return produce(state, (draft) => {
        if (draft.type !== ChartType.dualAxisColumnLine) {
          return draft;
        }
        if (draft.chart.dualAxisOrder == undefined) {
          draft.chart.dualAxisOrder = getInitColumnLineOrder();
        }
        draft.chart.dualAxisOrder = {
          ...draft.chart.dualAxisOrder,
          ...action.payload
        };

        draft.chart.dualAxisOrder.x = cleanOrder(
          draft.chart.dualAxisOrder.x,
          draft.type
        );
        Object.keys(draft.chart.dualAxisOrder.legend).forEach((type) => {
          if (draft.chart.dualAxisOrder == undefined) {
            return;
          }
          draft.chart.dualAxisOrder.legend[type] = cleanOrder(
            draft.chart.dualAxisOrder.legend[type],
            draft.type
          );
        });
        draft.requiredFetch = true;
        draft.requireSave = true;
        return draft;
      });
    }

    case ChartAction.changName:
      return produce(state, (draft) => {
        draft.name = action.payload.name;
        draft.requireSave = true;
        return draft;
      });
    case ChartAction.dataFetched:
      return produce(state, (draft) => {
        draft.requiredFetch = false;
        return draft;
      });

    case ChartAction.originColumns:
      return produce(state, (draft) => {
        draft.originColumns = action.payload.originColumns;
        return draft;
      });
  }
}

export function initState(
  projectId: string,
  nodeId: string,
  portId: string
): ChartState {
  return {
    project_uuid: projectId,
    node_id: nodeId,
    port_id: portId,
    name: 'グラフ名なし',
    type: ChartType.column,
    chart: {
      x: undefined,
      y: [{ func: 'SUM' }],
      aggregated: true,
      order: {
        x: { method: 'asc', target: 'x' },
        legend: { method: 'asc', target: 'x' }
      }
    },
    view: {
      showValues: '0',
      showLegend: '1',
      tooltip: getInitTooltip(),
      showNullData: '1',
      legendPosition: 'bottom'
    },
    color: {
      palette: { type: CategoricalColorType.Set2, reverse: false },
      color: getColors({ type: CategoricalColorType.Set2 })[0],
      colors: {}
    },
    requiredFetch: false,
    requireSave: false,
    loaded: false,
    originColumns: []
  };
}

// 値ごとに色設定があるので、テーブルデータの値を設定に反映させる
export function getReflectedStringColorOption(
  stringCellColorOption: TableOption,
  columnLabels: { [colname: string]: string[] }
): TableOption {
  return produce(stringCellColorOption, (draft) => {
    if (draft.type !== TableOptionType.stringCellColor) {
      return;
    }
    const option = draft.option.option.stringCellColor;
    Object.keys(option).forEach((colname) => {
      const targetValues = columnLabels[colname];
      if (!targetValues) {
        return;
      }
      const op = option[colname];

      let colorCount = countBy(Object.values(op.colors)); // 色ごとに使用回数をカウント
      const colors = getColors(op.palette);
      colorCount = Object.fromEntries(
        colors.map((color) => {
          return [color, colorCount[color] || 0];
        })
      );

      let newOpColors = {};
      targetValues.forEach((value) => {
        if (Object.keys(op.colors).includes(value)) {
          newOpColors[value] = op.colors[value];
          return;
        }
        // 使用回数の少ない色から使っていく。すべて使っていたらcolorsの最初から
        const valueColor = Object.keys(colorCount)
          .map((color, i) => {
            return {
              color,
              count: colorCount[color],
              sortNum: i
            };
          })
          .sort((a, b) => {
            if (a.count !== b.count) {
              return a.count < b.count ? -1 : 1;
            }
            return a.sortNum < b.sortNum ? -1 : 1;
          })[0].color;

        newOpColors[value] = valueColor;
        colorCount[valueColor] = colorCount[valueColor] + 1;
      });
      op.colors = newOpColors;
    });
  });
}
