import * as React from 'react';
import { MenuItem, Select } from '@material-ui/core';
import dayjs from 'dayjs';
import { InputBase } from 'ui/common/textfield';
import { ParameterType, RangeDateParameter } from 'models/report';
import { Dtypes } from 'Utils/dataTypes';
import { DateRangeValues } from 'models/report/parameter';
import { dateRangeInitialValues } from 'components/report/parameter/date/initialValue';
import { DateRangeParameter } from 'components/report/parameter/date/range';
import { DateRangeClass } from 'components/report/parameter/parameterClass/dateRange';
import { DialogProvider } from 'components/report/parameter/parameterDialog';

export const customDateList = {
  empty: '　',
  lastWeek: '直近1週間',
  lastTwoWeeks: '直近2週間',
  lastMonth: '直近1ヶ月',
  lastThreeMonths: '直近3ヶ月',
  lastSixMonths: '直近6ヶ月',
  lastYear: '直近1年',
  calendar: 'カレンダーから選択'
};

export const initialDateRange: DateRangeValues = dateRangeInitialValues;

export const getDateConditionValue = (
  condition: string,
  dateRange: DateRangeValues
) => {
  if (condition === 'calendar') {
    return getStringDate(dateRange);
  } else {
    return customDateList[condition];
  }
};

export const getQueryStringEndDate = (values: DateRangeValues): string => {
  const param = valuesToParam(values);
  const instance = new DateRangeClass(param);
  const { endDate } = instance.calculateInputValue();
  // DBの時間は9時間前
  return dayjs(endDate).format('YYYY-MM-DD 14:59:59');
};

export const getQueryStringStartDate = (values: DateRangeValues): string => {
  const param = valuesToParam(values);
  const instance = new DateRangeClass(param);
  const { startDate } = instance.calculateInputValue();
  // DBの時間は9時間前
  return dayjs(startDate).subtract(9, 'hour').format('YYYY-MM-DD HH:mm:ss');
};

export const getStringDate = (values: DateRangeValues) => {
  const param = valuesToParam(values);
  const instance = new DateRangeClass(param);
  return instance.toDisplayString();
};

export const isDateRange = (value): value is DateRangeValues => {
  return 'select' in value;
};

const valuesToParam = (values: DateRangeValues): RangeDateParameter => {
  return {
    type: ParameterType.Range,
    dtype: Dtypes.DATE,
    values: values,
    // 以下はreport parameterのみで使用する余計な値
    uuid: '',
    name: '',
    order_number: 0,
    target: {}
  };
};

export const Calendar: React.FC<{
  id: string;
  title: string;
  open: boolean;
  anchorEl: HTMLDivElement | null;
  ranges: DateRangeValues;
  onClose: () => void;
  onChange: (values: DateRangeValues) => void;
}> = ({ title, open, ranges, onClose, onChange }) => {
  const param: RangeDateParameter = React.useMemo(() => {
    return valuesToParam(ranges);
  }, [ranges]);

  return (
    <DialogProvider open={open} onClose={onClose}>
      <DateRangeParameter
        title={title}
        param={param}
        onSubmit={(param) => {
          onChange(param.values);
        }}
      />
    </DialogProvider>
  );
};

export const CustomDate: React.FC<{
  title: string;
  id: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ title, id, value, onChange }) => {
  return (
    <tr>
      <td>{title}</td>
      <td>
        <Select
          data-cy={title}
          id={id}
          value={value}
          onChange={onChange}
          input={<InputBase />}
          renderValue={(selected) => selected as string}
          fullWidth={true}
        >
          {Object.entries(customDateList).map(([key, label]) => (
            <MenuItem key={key} value={label}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </td>
    </tr>
  );
};
