import * as React from 'react';
import {
  accessLevelList,
  CheckboxForm,
  datasourceStatusList,
  SelectForm,
  TextForm
} from 'ui/listView/filter/form';
import {
  Calendar,
  CustomDate,
  getDateConditionValue
} from 'ui/listView/filter/customDate';
import {
  AutocompleteForm,
  AutocompleteItemIconType,
  toOptions,
  Option
} from 'ui/listView/filter/autocompleteForm';
import { FilterType } from 'ui/listView/filter/type';
import {
  handleChangeAccessLevels,
  handleChangeCustomDateCondition,
  handleChangeCustomDateRange,
  handleChangeDatasourceName,
  handleChangeDatasourceStatuses,
  handleChangeDatasourceTypes,
  handleChangeExcludeFolders,
  handleCloseCalendar
} from 'ui/listView/filter/handler';
import { FilterContext } from 'ui/listView/filter/context';
import { CreatorRow } from './row/creatorRow';

export const DatasourceFilter: React.FC = () => {
  const {
    state: filterState,
    dispatch: filterDispatch,
    isAdminPage,
    emails,
    datasourceTypes
  } = React.useContext(FilterContext);
  const calendarForCreateOpen = Boolean(
    filterState[FilterType.CalendarPositionForCreation]
  );
  const calendarForUpdateOpen = Boolean(
    filterState[FilterType.CalendarPositionForUpdate]
  );

  const refForCalendar = React.useRef<HTMLTableElement | null>(null);

  const typeOptions = React.useMemo(() => {
    return toOptions(datasourceTypes);
  }, [datasourceTypes]);

  const onChangeType = React.useCallback(
    (_: React.ChangeEvent<{}>, value: Option[]) => {
      handleChangeDatasourceTypes(filterState, filterDispatch)(
        _,
        value.map((v) => v.value)
      );
    },
    [filterState, filterDispatch]
  );

  return (
    <>
      <table ref={refForCalendar}>
        <tbody>
          <TextForm
            title="データソース名（含む）"
            value={filterState[FilterType.DatasourceName]}
            onChange={handleChangeDatasourceName(filterState, filterDispatch)}
          />
          <AutocompleteForm
            title="タイプ"
            options={typeOptions}
            value={filterState[FilterType.DatasourceTypes]}
            type={AutocompleteItemIconType.Datasource}
            onChange={onChangeType}
          />
          <SelectForm
            title="ステータス"
            labels={datasourceStatusList}
            values={filterState[FilterType.DatasourceStatuses]}
            onChange={handleChangeDatasourceStatuses(
              filterState,
              filterDispatch
            )}
          />
          <CustomDate
            title="作成日時"
            id="created-select"
            value={getDateConditionValue(
              filterState[FilterType.CreateCondition],
              filterState[FilterType.CreateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForCreation,
              FilterType.CreateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          <CustomDate
            title="更新日時"
            id="created-select"
            value={getDateConditionValue(
              filterState[FilterType.UpdateCondition],
              filterState[FilterType.UpdateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForUpdate,
              FilterType.UpdateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          {!isAdminPage && (
            <SelectForm
              title="自分の権限"
              labels={accessLevelList}
              values={filterState[FilterType.AccessLevels]}
              onChange={handleChangeAccessLevels(filterState, filterDispatch)}
            />
          )}
          <CreatorRow emails={emails} />
          <CheckboxForm
            title="フォルダを表示しない"
            value={filterState[FilterType.ExcludeFolders]}
            onChange={handleChangeExcludeFolders(filterState, filterDispatch)}
          />
        </tbody>
      </table>
      <Calendar
        id="create-calendar"
        title="作成日時"
        open={calendarForCreateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForCreation]}
        ranges={filterState[FilterType.CreateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.CreateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForCreation,
          filterState,
          filterDispatch
        )}
      />
      <Calendar
        id="update-calendar"
        title="更新日時"
        open={calendarForUpdateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForUpdate]}
        ranges={filterState[FilterType.UpdateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.UpdateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForUpdate,
          filterState,
          filterDispatch
        )}
      />
    </>
  );
};
