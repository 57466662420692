import React from 'react';
import { Variable } from 'models/project';
import VariableEditor from 'components/variableEditor';

export const VariableEditorDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  editIndex: number;
  addVariable: (variable: Variable) => void;
  editVariable: (variable: Variable, index: number) => void;
  saveVariables: (resourceId: string) => void;
  resourceId: string;
  localVariables: Variable[];
  importedVariables: Variable[];
}> = ({
  open,
  onClose,
  editIndex,
  addVariable,
  editVariable,
  saveVariables,
  resourceId,
  localVariables,
  importedVariables
}) => {
  const isAdd = editIndex === -1;
  if (!open) {
    return null;
  }
  return (
    <VariableEditor
      dialog={{ open, onClose }}
      initialVariable={isAdd ? undefined : localVariables[editIndex]}
      addVariable={addVariable}
      editVariable={(variable: Variable) => editVariable(variable, editIndex)}
      saveVariables={() => {
        saveVariables(resourceId);
        onClose();
      }}
      handleCancel={onClose}
      existingVariables={localVariables.concat(importedVariables)}
      importedVariables={importedVariables}
    />
  );
};
