import * as React from 'react';
import SelectField from 'components/form/selectField';
import { SwitchWeekdayList } from 'models/form/value';
import { SelectFieldSchema } from 'models/form/schema';
import { FieldTypes, SelectFieldValueElement } from 'models/form/schema';

export const SelectWeekday: React.FC<{
  value: SwitchWeekdayList | SwitchWeekdayList[] | null;
  onChange: (value: SwitchWeekdayList | SwitchWeekdayList[]) => void;
  multiple: boolean;
  index: number;
}> = ({ value, onChange, multiple, index }) => {
  const weekday_list = WeekdayList;
  const handleChange = React.useCallback(
    (_, r_val: SwitchWeekdayList | SwitchWeekdayList[]) => {
      if (Array.isArray(r_val)) {
        onChange(r_val as SwitchWeekdayList[]);
      } else {
        onChange(r_val as SwitchWeekdayList);
      }
    },
    [onChange]
  );

  const schema: SelectFieldSchema = {
    key: String(index),
    type: FieldTypes.select,
    items: weekday_list as SelectFieldValueElement[],
    multi: multiple,
    allDtypes: [],
    placeholder: '曜日を選択',
    fullWidth: true,
    variant: 'outlined'
  };

  return (
    <div>
      <SelectField
        clearable={false}
        value={value as any}
        //errors={errors}
        schema={schema}
        onChangeField={handleChange}
        detailedHelpTooltip={null}
      />
    </div>
  );
};

const WeekdayList = [
  { value: SwitchWeekdayList.monday, label: '月' },
  { value: SwitchWeekdayList.tuesday, label: '火' },
  { value: SwitchWeekdayList.wednesday, label: '水' },
  { value: SwitchWeekdayList.thursday, label: '木' },
  { value: SwitchWeekdayList.friday, label: '金' },
  { value: SwitchWeekdayList.saturday, label: '土' },
  { value: SwitchWeekdayList.sunday, label: '日' }
];
