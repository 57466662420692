import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  spacer: {
    flex: '1 1 auto'
  }
});
export const Spacer: React.VFC = () => {
  const classes = styles();
  return <div className={classes.spacer} />;
};
