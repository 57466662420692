import { AccessLevel } from 'libs/accessLevel';

import { Graph } from 'models/graph';
import { blue, green, grey, orange, red } from '@material-ui/core/colors';

export enum ProjectSaveStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

export enum ProjectStatus {
  Unknown = 0,
  SUCCESS,
  WARNING,
  ERROR,
  RUNNING
}

export function ProjectStatusText(s: ProjectStatus) {
  switch (s) {
    case ProjectStatus.SUCCESS:
      return '成功';
    case ProjectStatus.WARNING:
      return '警告';
    case ProjectStatus.ERROR:
      return 'エラー';
    case ProjectStatus.RUNNING:
      return '実行中';
    case ProjectStatus.Unknown:
      return '-';
    default:
      return '-';
  }
}

export function ProjectStatusColor(s: ProjectStatus): string {
  switch (s) {
    case ProjectStatus.RUNNING:
      return blue['A700'];
    case ProjectStatus.SUCCESS:
      return green['A700'];
    case ProjectStatus.WARNING:
      return orange['A700'];
    case ProjectStatus.ERROR:
      return red['A700'];
    case ProjectStatus.Unknown:
      return grey['A700'];
    default:
      return grey['A700'];
  }
}

export enum ExecuteStatus {
  IDLE,
  EXECUTING,
  INTERRUPTING
}

export enum WorkflowStatus {
  NONE,
  SUCCESS,
  WARNING,
  ERROR,
  EXECUTING
}

export interface Project {
  uuid: string;
  name: string;
  status: ProjectStatus;
  workflows: Workflow[];
  current_workflow_index: number;
  hasVisualizeNodes: { [nodeId: string]: boolean };
  hasReportVisualizeNodes: { [nodeId: string]: { [chartId: string]: boolean } };
  variables: Variable[];
  accessLevel: AccessLevel;
  public_flag: boolean;
  scheduling_locked: boolean;
}

export interface Workflow {
  id: number;
  name: string;
  graph: Graph;
  status: ExecuteStatus;
  runnerId: string;
  orderNumber: number;
  checkedIds: string[];
}

export interface Variable {
  name: string;
  value: string;
  dtype: string;
  desc: string;
  uuid?: string;
  dateType?: string;
  dateUnit?: string;
  dateOperator?: string;
  dateFormat?: string;
  timestampFormat?: string;
  timeFormat?: string;
  dateCustomFormat?: string;
  timestampCustomFormat?: string;
  timeCustomFormat?: string;
  disableEdit?: boolean;
}

export interface BuilderProject {
  uuid: string;
  name: string;
  version?: number;
  current_workflow_index: number;
  connection_type: string;
  connection_uuid: number;
  workflows: BuilderWorkflow[];
  access_level: number;
}

export interface BuilderWorkflow {
  uuid: string;
  name: string;
  graph: Graph;
  status: ExecuteStatus;
  orderNumber: number;
  checkedIds: string[];
}
