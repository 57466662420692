import * as React from 'react';
import clsx from 'clsx';
import { find } from 'lodash-es';
import Select, {
  components as RSComponents,
  MultiValueProps,
  OptionProps,
  SingleValueProps,
  StylesConfig
} from 'react-select';
import { Typography } from '@material-ui/core';
import { getOptionDTypeIcon } from 'components/form/selectField';
import { red } from '@material-ui/core/colors';
import { Label } from 'components/visualize/form/label';
import { Option, YValue } from '../../../models/chart';
import { filterColumn } from 'components/visualize/transformer';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { aggregateMethod } from 'components/visualize/editor/util';

const useStyles = makeStyles({
  multiValue: {
    backgroundColor: red[300]
  }
});

type SelectedOption = Option | undefined;

export interface Base {
  label: string;
  placeholder?: string;
  newline?: boolean;
}

interface ListSelectProps extends Base {
  onChange: (value: string) => void;
  defaultValue?: Option;
  value?: string;
  items: Option[];
  menuPortalTarget?: HTMLElement;
  isClearable?: boolean;
}

const selectStyles = {
  container: (props) => ({ ...props, width: '100%' }),
  menu: (props) => ({ ...props, zIndex: 10 }),
  valueContainer: (props) => ({ ...props, maxHeight: 500, overflowY: 'auto' }),
  multiValueLabel: (props) => ({
    ...props,
    whiteSpace: 'none',
    wordBreak: 'break-all'
  })
};

const SelectComponents = {
  Option: (props: OptionProps<any>) => {
    const { children, getStyles, innerRef, innerProps, data } = props;
    const color = data.isError ? red[400] : undefined;
    const { id, ...divProps } = innerProps;
    return (
      <div
        data-cy={`select-option-${data.value}`}
        // @ts-ignore
        style={{ ...getStyles('option', props), color }}
        ref={innerRef}
        {...divProps}
      >
        {data.icon ? (
          <>
            {data.icon}
            <span style={{ verticalAlign: 'middle' }}>{children}</span>
          </>
        ) : (
          children
        )}
      </div>
    );
  },
  SingleValue: (props: SingleValueProps<any>) => {
    const { children, data, getStyles } = props;
    const color = data.isError ? red[400] : undefined;
    return (
      <Typography
        // @ts-ignore
        style={{ ...getStyles('singleValue', props), color }}
        {...props.innerProps}
      >
        {data.icon ? (
          <>
            {data.icon}
            <span style={{ verticalAlign: 'middle' }}>{children}</span>
          </>
        ) : (
          children
        )}
      </Typography>
    );
  },
  MultiValue: (props: MultiValueProps<any>) => {
    const classes = useStyles();
    const { data } = props;
    const newProps = {
      ...props,
      className: clsx(data.isError && classes.multiValue)
    };

    return (
      <div data-tip={data.value} data-for={data.value}>
        <RSComponents.MultiValue {...newProps}>
          {data.value}
        </RSComponents.MultiValue>
        {data.isError && (
          <ReactTooltip id={data.value} place="right">
            <p>{`${data.value} が存在しません`}</p>
          </ReactTooltip>
        )}
      </div>
    );
  }
};

const ColumnComponents = {
  Option: (props: OptionProps<any>) => {
    const { children, getStyles, innerRef, innerProps, data } = props;
    const icon = getOptionDTypeIcon(data);
    const color = data.isError ? red[400] : undefined;
    return (
      <div
        data-cy={`column-select-option-${data.value}`}
        // @ts-ignore
        style={{ ...getStyles('option', props), color }}
        ref={innerRef}
        {...innerProps}
      >
        {icon} {children}
      </div>
    );
  },
  SingleValue: (props: SingleValueProps<any>) => {
    const { data, getStyles } = props;
    const color = data.isError ? red[400] : undefined;
    const icon = getOptionDTypeIcon(data);
    return (
      <Typography
        // @ts-ignore
        style={{ ...getStyles('singleValue', props), color }}
        {...props.innerProps}
      >
        {icon} {props.children}
      </Typography>
    );
  },
  MultiValue: (props: MultiValueProps<any>) => {
    const classes = useStyles();
    const { data } = props;
    const newProps = {
      ...props,
      className: clsx(data.isError && classes.multiValue)
    };

    return (
      <div data-tip={data.value} data-for={data.value}>
        <RSComponents.MultiValue {...newProps}>
          {data.value}
        </RSComponents.MultiValue>
        {data.isError && (
          <ReactTooltip id={data.value} place="right">
            <p>{`${data.value} が存在しません`}</p>
          </ReactTooltip>
        )}
      </div>
    );
  }
};

export interface SelectFieldProps extends Omit<ListSelectProps, 'label'> {
  label?: string;
}

export const SelectField: React.FC<SelectFieldProps> = ({
  label,
  placeholder,
  defaultValue,
  value,
  items,
  onChange,
  isClearable,
  menuPortalTarget
}) => {
  const selectedOption = React.useMemo(() => {
    return find(items, (item) => {
      return item.value === value;
    });
  }, [items, value]);
  const [selected, select] = React.useState<SelectedOption>(selectedOption);

  React.useEffect(() => {
    select(selectedOption);
  }, [selectedOption]);

  const onChangeSelect = React.useCallback(
    (ev) => {
      select(ev);
      onChange(ev ? ev.value : '');
    },
    [onChange]
  );

  const SelectComponemt = (
    <Select
      components={SelectComponents}
      menuPortalTarget={menuPortalTarget}
      placeholder={placeholder}
      value={selected}
      styles={selectStyles}
      defaultValue={defaultValue}
      isClearable={isClearable}
      isRtl={false}
      onChange={onChangeSelect}
      isSearchable={true}
      options={items}
      menuPosition={menuPortalTarget ? 'fixed' : 'absolute'}
    />
  );

  return (
    <>
      {label == undefined ? (
        SelectComponemt
      ) : (
        <Label text={label}>{SelectComponemt}</Label>
      )}
    </>
  );
};

interface ColumnSelectProps extends Base {
  'data-cy'?: string;
  items: Option[];
  deleteButton?: React.ReactNode;
  dtypes?: string[];
  value?: Option;
  loading?: boolean;
  onChange: (value: Option) => void;
  componentStyles?: StylesConfig;
}

export const ColumnSelect: React.FC<Omit<ColumnSelectProps, 'label'>> = ({
  value,
  items,
  dtypes,
  onChange,
  placeholder,
  loading,
  componentStyles
}) => {
  const [selected, select] = React.useState<SelectedOption>(value);
  const onChangeSelect = React.useCallback(
    (ev) => {
      select(ev);
      onChange(ev);
    },
    [select, onChange]
  );

  const isOptionDisabled = React.useCallback((opt: SelectedOption) => {
    return opt == undefined || (opt.isError != undefined && opt.isError);
  }, []);

  React.useEffect(() => {
    if (selected !== value) {
      select(value);
    }
  }, [value, selected]);

  return (
    <Select
      components={ColumnComponents}
      placeholder={placeholder}
      menuPortalTarget={document.body}
      value={selected || null}
      styles={componentStyles ? componentStyles : selectStyles}
      isOptionDisabled={isOptionDisabled}
      isClearable={true}
      isRtl={false}
      isLoading={loading}
      onChange={onChangeSelect}
      isSearchable={true}
      options={dtypes != undefined ? filterColumn(dtypes, items) : items}
    />
  );
};

export const ColumnSelectField: React.FC<ColumnSelectProps> = ({
  label,
  value,
  items,
  dtypes,
  onChange,
  placeholder,
  deleteButton,
  ...props
}) => {
  return (
    <Label text={label} data-cy={props['data-cy']}>
      <ColumnSelect
        placeholder={placeholder}
        value={value}
        items={items}
        dtypes={dtypes}
        onChange={onChange}
        deleteButton={deleteButton}
      />
      {deleteButton && deleteButton}
    </Label>
  );
};

export const YValueField: React.FC<{
  'data-cy'?: string;
  aggregates: Option[];
  label: string;
  placeholder: string;
  deleteButton?: React.ReactNode;
  func?: string;
  onChange: (value: YValue) => void;
  column?: Option;
  columns: Option[];
}> = ({
  aggregates,
  label,
  placeholder,
  deleteButton,
  func,
  column,
  columns,
  onChange,
  ...props
}) => {
  const onChangeAggregate = React.useCallback(
    (ev) => {
      onChange({ column, func: ev.value });
    },
    [column, onChange]
  );

  const onChangeColumn = React.useCallback(
    (ev) => {
      onChange({ column: ev, func });
    },
    [func, onChange]
  );

  React.useEffect(() => {
    if (func == undefined) {
      onChange({
        column,
        func: aggregates[0] != undefined ? aggregates[0].value : undefined
      });
    }
  }, [func, aggregates, onChange, column]);

  const funcLabel = React.useMemo(() => {
    const agg = aggregateMethod.find((agg) => agg.value === func);
    return agg != undefined ? agg.label : undefined;
  }, [func]);

  return (
    <Label text={label}>
      <div
        style={{
          display: 'flex',
          width: '100%'
        }}
      >
        <div
          style={{ width: 'calc(100% - 70px)' }}
          data-cy={`column-select-for-${props['data-cy']}`}
        >
          <Select
            placeholder={placeholder}
            onChange={onChangeColumn}
            value={column}
            menuPortalTarget={document.body}
            components={{
              ...ColumnComponents,
              IndicatorsContainer: () => null
            }}
            styles={{
              control: (props) => ({
                ...props,
                height: 48,
                borderRightWidth: 0,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
              })
            }}
            options={columns}
          />
        </div>
        <div
          style={{ width: 70 }}
          data-cy={`func-select-for-${props['data-cy']}`}
        >
          <Select
            placeholder="関数"
            onChange={onChangeAggregate}
            value={func ? { label: funcLabel, value: func } : undefined}
            styles={{
              control: (props) => ({
                ...props,
                height: 48,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0
              }),
              menu: (props) => ({ ...props, width: 160, zIndex: 10 })
            }}
            menuPortalTarget={document.body}
            isClearable={false}
            isRtl={false}
            isSearchable={false}
            components={{
              IndicatorsContainer: () => null
            }}
            options={aggregates}
          />
        </div>
        {deleteButton && deleteButton}
      </div>
    </Label>
  );
};

interface MultiColumnSelectProps extends Base {
  items: Option[];
  values: Option[];
  dtypes?: string[];
  onChange: (value?: Option[]) => void;
}

export const MultiColumnSelect: React.FC<
  Omit<MultiColumnSelectProps, 'label'>
> = ({ onChange, placeholder, dtypes, items, values = [] }) => {
  const [selected, select] = React.useState<Option[]>(values);
  const onChangeSelect = React.useCallback(
    (ev) => {
      select(ev);
      onChange(ev);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (values !== selected) {
      select(values);
    }
  }, [values, selected]);

  const isOptionDisabled = React.useCallback((opt: SelectedOption) => {
    return opt == undefined || (opt.isError != undefined && opt.isError);
  }, []);
  return (
    <Select
      components={ColumnComponents}
      menuPortalTarget={document.body}
      placeholder={placeholder}
      value={selected}
      isMulti={true}
      closeMenuOnSelect={false}
      styles={selectStyles}
      isOptionDisabled={isOptionDisabled}
      isClearable={true}
      isRtl={false}
      onChange={onChangeSelect}
      isSearchable={true}
      options={dtypes != undefined ? filterColumn(dtypes, items) : items}
    />
  );
};

export const MultiColumnSelectField: React.FC<MultiColumnSelectProps> = ({
  label,
  newline,
  onChange,
  placeholder,
  dtypes,
  items,
  values = []
}) => {
  return (
    <Label text={label} newline={newline}>
      <MultiColumnSelect
        placeholder={placeholder}
        values={values}
        items={items}
        dtypes={dtypes}
        onChange={onChange}
      />
    </Label>
  );
};
