import * as React from 'react';
import { Icon } from '@iconify/react';

export const enum DestinationType {
  slack = 'slack',
  email = 'email',
  teams = 'teams',
  chatwork = 'chatwork'
}

interface Destination {
  type: DestinationType;
  name: string;
  logo: string | JSX.Element;
}

export const destinations: Destination[] = [
  { type: DestinationType.slack, name: 'Slack', logo: '/images/iconSlack.svg' },
  {
    type: DestinationType.email,
    name: 'Email',
    logo: (
      <Icon
        icon="material-symbols:attach-email-outline"
        style={{ height: '100%', width: '100%' }}
      />
    )
  },
  { type: DestinationType.teams, name: 'Teams', logo: '/images/iconTeams.svg' },
  {
    type: DestinationType.chatwork,
    name: 'Chatwork',
    logo: '/images/iconChatwork.svg'
  }
];

export const destinationMap: {
  [k: string]: Destination | undefined;
} = Object.fromEntries(destinations.map((d) => [d.type, d]));

export const getDestName = (dstType: DestinationType) => {
  const find = destinationMap[dstType];
  if (find) {
    return find.name;
  }
};
