import { Nodes, Positions } from 'models/graph';
import { minBy, sortBy } from 'lodash-es';

export const NodeWidth = 170;

interface Coordinate {
  x: number;
  y: number;
}

export function getDistance(base: Coordinate, target: Coordinate): number {
  return Math.sqrt((base.x - target.x) ** 2 + (base.y - target.y) ** 2);
}

export class ControlCoords {
  static nodeSpan = 40;
  static nodeSize = {
    width: NodeWidth,
    height: 32
  };
  static initNodeCoord = {
    x: 104,
    y: 30
  };
  static initPosition = {
    offsetX: 0,
    offsetY: 0,
    zoomLevel: 100
  };

  static getCenter(view: Coordinate): Coordinate {
    const { x, y } = view;
    return {
      x: x + ControlCoords.nodeSize.width / 2,
      y: y + ControlCoords.nodeSize.height / 2
    };
  }

  static getDistance(base: Coordinate, target: Coordinate): number {
    return Math.sqrt((base.x - target.x) ** 2 + (base.y - target.y) ** 2);
  }

  private amountZoomLevel: number;

  constructor(private nodes: Nodes, positions: Positions) {
    this.amountZoomLevel = positions.zoomLevel / 100;
  }

  public generateNodeCoord(
    selectedNodeId?: string,
    requireInput?: boolean
  ): { nodeCoord: Coordinate } {
    if (
      selectedNodeId == undefined ||
      this.nodes[selectedNodeId] == undefined
    ) {
      return {
        nodeCoord: {
          x: ControlCoords.initNodeCoord.x,
          y: ControlCoords.initNodeCoord.y
        }
      };
    }

    // search contains node
    let tmpSelectedCoord: Coordinate = this.nodes[selectedNodeId];

    // データソースは一番上のNodeと同じyにだす。
    const minYNode = minBy(Object.values(this.nodes), 'y');
    if (this.nodes && !requireInput) {
      if (minYNode) {
        const tmpNode = this.nodes[minYNode.id];
        tmpSelectedCoord = {
          x: tmpNode.x,
          y: tmpNode.y
        };
      }
    }

    const selectedCoord = {
      ...tmpSelectedCoord,
      center: ControlCoords.getCenter(tmpSelectedCoord)
    };

    // 全Nodeの重心からの距離を計算
    const baseCoord = Object.values(this.nodes).map((node) => {
      const center = ControlCoords.getCenter(node);
      return {
        x: node.x,
        y: node.y,
        center,
        distance: ControlCoords.getDistance(center, selectedCoord.center)
      };
    });
    const sortedBaseCoord = sortBy(baseCoord, 'distance');

    // y方向の検索を始めるはじめの座標
    let initialVertical =
      selectedCoord.y +
      this.nodes[selectedNodeId].rows * 11 * 1.2 +
      8 +
      1 +
      23 +
      ControlCoords.nodeSize.height;
    const searchVertical = true;

    if (sortedBaseCoord.length === 0) {
      return {
        nodeCoord: {
          x: this.nodes[selectedNodeId].x,
          y:
            this.nodes[selectedNodeId].y +
            this.nodes[selectedNodeId].rows * 11 * 1.2 +
            8 +
            1 +
            23 +
            ControlCoords.nodeSpan
        }
      };
    }

    while (searchVertical) {
      initialVertical = initialVertical + ControlCoords.nodeSpan;
      let initialHorizon = selectedCoord.x;
      let searchHorizontal = true;
      // 1 move vertical and search horizontal
      while (searchHorizontal) {
        const targetCoord = {
          ...selectedCoord,
          x: initialHorizon,
          y: initialVertical
        };
        let foundCoord = sortedBaseCoord.every((base) => {
          return !this.containNodeCoord(base, targetCoord);
        });

        searchHorizontal = !foundCoord;
        if (foundCoord && !requireInput) {
          requireInput = true;
          searchHorizontal = true;
          foundCoord = false;
          initialVertical = minYNode ? minYNode.y : selectedCoord.y;
        }

        if (foundCoord) {
          return {
            nodeCoord: {
              x: initialHorizon,
              y: initialVertical
            }
          };
        }
        initialHorizon = initialHorizon + ControlCoords.nodeSpan + 40;
      }
    }

    return { nodeCoord: { x: 0, y: 0 } };
  }

  private containNodeCoord(base: Coordinate, target: Coordinate): boolean {
    const { leftTop: baseLeftTop, rightBottom: baseRightBottom } =
      this.calcSquareCoord(base);
    const { leftTop: targetLeftTop, rightBottom: targetRightBottom } =
      this.calcSquareCoord(target);

    // contains conditions is A ≧ A` and B ≦ B`
    return (
      baseLeftTop.x < targetRightBottom.x &&
      baseLeftTop.y < targetRightBottom.y &&
      baseRightBottom.x > targetLeftTop.x &&
      baseRightBottom.y > targetLeftTop.y
    );
  }

  private calcSquareCoord(view: Coordinate) {
    const { x: normalX, y: normalY } = view;
    const x = normalX / this.amountZoomLevel;
    const y = normalY / this.amountZoomLevel;
    const width = ControlCoords.nodeSize.width / this.amountZoomLevel;
    const height = ControlCoords.nodeSize.height / this.amountZoomLevel;
    const leftTop = {
      x,
      y
    };

    const rightBottom = {
      y: leftTop.y + height,
      x: leftTop.x + width
    };
    return {
      leftTop,
      rightBottom
    };
  }
}
