import * as React from 'react';

import {
  Avatar,
  Button,
  Chip,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import { Alert, Autocomplete } from '@material-ui/lab';
import { DtypeItems, Dtypes, toDtypeLabel } from 'Utils/dataTypes';
import { produce } from 'immer';
import { Add, Remove } from '@material-ui/icons';
import { diffSet, unionSet } from 'components/form/util';
import { getColumnIcon } from 'components/dataTable/headerCell';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { PortStatus } from 'models/graph';
import DataTable from 'components/dataTable/table';
import { useDebounce } from 'react-use';
import { DataSummary } from 'models/data';
import {
  ColumnSelectRule,
  ColumnSelectRules,
  ColumnSelectV2Value,
  SelectColumnNames,
  SelectTypes
} from 'models/form/value';
import { ColumnSelectV2FieldSchema } from 'models/form/schema';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';
import { Dialog as Dialog } from 'ui/common/dialog';

export interface Column {
  name: string;
  dtype: Dtypes;
}

interface ColumnError {
  [colName: string]: boolean;
}

export interface ColumnOptionsV2Names {
  [originName: string]: string; // valueには変更後の値が入る
}

export const generateNewRule = (value: string[] = []): SelectColumnNames => ({
  type: SelectTypes.column_names,
  exclude: false,
  value
});

export const initializeRules = (
  value: ColumnSelectRules | undefined
): ColumnSelectRules => {
  if (value == undefined || value.length === 0) {
    return [generateNewRule()];
  }
  return value;
};

interface ColumnSelectFieldProps {
  value: ColumnSelectV2Value | null;
  schema: ColumnSelectV2FieldSchema;
  columns: DataSummary['columns'];
  dtypes: DataSummary['dtypes'];
  onChangeField: ChangeFieldHandler;
  projectId: DataSummary['projectId'];
  portId?: string;
  dataStatus: PortStatus;
  detailedHelpTooltip: React.ReactNode;
  colnameMap?: { [k: string]: string };
}

const getDefaultValue = (type: SelectTypes) => {
  switch (type) {
    case SelectTypes.all_columns:
      return null;

    case SelectTypes.column_names:
    case SelectTypes.column_types:
      return [];

    case SelectTypes.regex:
    case SelectTypes.starts_with:
    case SelectTypes.ends_with:
    case SelectTypes.includes:
      return '';
  }
};

export const ColumnSelectV2Field: React.FC<ColumnSelectFieldProps> = ({
  value,
  columns,
  dtypes,
  schema,
  onChangeField,
  projectId,
  portId,
  dataStatus,
  detailedHelpTooltip,
  colnameMap
}) => {
  const [open, setOpen] = React.useState(false);
  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);
  const onChange = React.useCallback(
    (rules: ColumnSelectRules) => {
      onChangeField(schema.key, { version: 2, rules }, []);
    },
    [schema, onChangeField]
  );
  React.useEffect(() => {
    if (!value) {
      onChange([generateNewRule()]);
      if (schema.autoOpen) {
        setOpen(true);
      }
    }
  }, []);

  if (!value) {
    return null;
  }

  const [selected] = selectColumnsByRule(columns, dtypes, value.rules);
  const errors = checkDtypes(selected, schema.dtypes);

  return (
    <div>
      {Object.keys(errors).length > 0 && schema.dtypes && (
        <Typography color="secondary" variant="subtitle2">
          選択できない型の列が含まれます。選択可能な型は、
          {schema.dtypes.map((d) => toDtypeLabel(d)).join(', ')}です。
        </Typography>
      )}
      <div style={{ maxHeight: 139, overflow: 'auto' }}>
        <ListColumns columns={selected} errors={errors} hideNone={true} />
      </div>
      {dataStatus === PortStatus.Loading && <div>データをロード中です...</div>}
      {columns.length === 0 && dataStatus === PortStatus.Loaded && (
        <Typography color="error" variant="body2">
          選択可能な列がありません
        </Typography>
      )}
      {getNotExistColumns(columns, value.rules).length > 0 && (
        <Alert style={{ margin: '5px 0' }} severity="error">
          存在しない列が選択されています
        </Alert>
      )}
      <Button color="primary" variant="outlined" onClick={onOpen}>
        列選択
      </Button>
      <Typography
        variant="subtitle2"
        gutterBottom={true}
        display="inline"
        style={{ marginLeft: 8 }}
      >
        {selected.length}列選択済み
      </Typography>
      <ColumnSelectDialog
        open={open}
        value={value.rules}
        schema={schema}
        columns={columns}
        dtypes={dtypes}
        onChange={onChange}
        onClose={onClose}
        projectId={projectId}
        portId={portId}
        dataStatus={dataStatus}
        colnameMap={colnameMap}
      />
      {detailedHelpTooltip}
    </div>
  );
};

// 使用できないDtypeのインデックスを返す
const checkDtypes = (
  col: Column[],
  validDtypes: Dtypes[] | undefined
): ColumnError => {
  if (!validDtypes) {
    return {};
  }

  const errors: ColumnError = {};
  col.forEach((c) => {
    if (!validDtypes.includes(c.dtype)) {
      errors[c.name] = true;
    }
  });
  return errors;
};

const includeTypes = [
  { text: '次を選択', value: 'include' },
  { text: '次を削除', value: 'exclude' }
];
const IncludeTypeSelect: React.FC<{
  value: boolean;
  onChange: (value: boolean) => void;
}> = ({ value, onChange }) => {
  const selected = value ? 'exclude' : 'include';
  const onChangeSelected = React.useCallback(
    (ev: React.ChangeEvent<{ value: unknown }>) =>
      onChange((ev.target.value as string) === 'exclude'),
    [onChange]
  );
  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ marginRight: 8, width: 110 }}
      data-cy="include-type-select"
    >
      <SmallSelect value={selected} onChange={onChangeSelected}>
        {includeTypes.map((t) => (
          <MenuItem
            key={t.value}
            value={t.value}
            style={{ fontSize: 14 }}
            data-cy={`include-type-select-menu-${t.value}`}
          >
            {t.text}
          </MenuItem>
        ))}
      </SmallSelect>
    </FormControl>
  );
};

const selectTypes = [
  { text: 'すべての列', value: SelectTypes.all_columns },
  { text: '列名一致', value: SelectTypes.column_names },
  { text: '列型一致', value: SelectTypes.column_types },
  { text: '指定文字から始まる列名', value: SelectTypes.starts_with },
  { text: '指定文字で終わる列名', value: SelectTypes.ends_with },
  { text: '指定文字を含む列名', value: SelectTypes.includes },
  { text: '正規表現一致', value: SelectTypes.regex }
];
const SelectTypeSelect: React.FC<{
  value: SelectTypes;
  onChange: (value: SelectTypes) => void;
}> = ({ value, onChange }) => {
  const onChangeSelected = React.useCallback(
    (ev: React.ChangeEvent<{ value: unknown }>) =>
      onChange(ev.target.value as SelectTypes),
    [onChange]
  );
  return (
    <FormControl
      variant="outlined"
      size="small"
      style={{ marginRight: 16, width: 206 }}
      data-cy="select-type-select"
    >
      <SmallSelect value={value} onChange={onChangeSelected}>
        {selectTypes.map((t) => (
          <MenuItem
            key={t.value}
            value={t.value}
            style={{ fontSize: 14 }}
            data-cy={`select-type-select-menu-${t.value}`}
          >
            {t.text}
          </MenuItem>
        ))}
      </SmallSelect>
    </FormControl>
  );
};

const setterStyles = makeStyles({
  field: {
    backgroundColor: '#fff',
    fontSize: 14
  },
  option: {
    display: 'inline-flex',
    alignItems: 'center',
    '& svg': {
      marginRight: 4
    }
  }
});

export const ColumnSelectValueSetter: React.FC<{
  columns: string[];
  dtypes: Dtypes[];
  rule: ColumnSelectRule;
  onChange: (value: any) => void;
}> = ({ rule, columns, dtypes, onChange }) => {
  const width = 230;
  const classes = setterStyles();
  const [columnMapping, setColumnMapping] = React.useState<{
    [col: string]: Dtypes;
  }>(Object.fromEntries(columns.map((c, i) => [c, dtypes[i]])));
  React.useEffect(() => {
    setColumnMapping(Object.fromEntries(columns.map((c, i) => [c, dtypes[i]])));
  }, [columns, dtypes]);

  const onChangeColumnSelect = React.useCallback(
    (_, val: string[]) => onChange(val),
    [onChange]
  );
  const onChangeTypeSelect = React.useCallback(
    (_, val: Array<(typeof DtypeItems)[0]>) =>
      onChange(val.map((v) => v.value)),
    [onChange]
  );
  const renderTags = React.useCallback(() => {
    return null;
  }, [columnMapping]);

  const [inputText, setInputText] = React.useState(rule.value);
  const [, cancel] = useDebounce(
    () => {
      onChange(inputText);
    },
    1000,
    [inputText]
  );
  // ↑のonChangeまでの間にタイプ変更されると、不正なvalueがセットされるので
  // タイプが変わった場合onChangeをキャンセルする
  React.useEffect(() => {
    cancel();
  }, [rule.type]);

  switch (rule.type) {
    case SelectTypes.all_columns:
      return <div style={{ width }} />;

    case SelectTypes.column_names:
      return (
        <Autocomplete
          style={{ display: 'inline-flex', width }}
          classes={{
            inputRoot: classes.field
          }}
          disableCloseOnSelect={true}
          size="small"
          value={rule.value}
          multiple={true}
          options={columns}
          filterSelectedOptions={true}
          onChange={onChangeColumnSelect}
          renderTags={renderTags}
          renderOption={(option) => (
            <div
              className={classes.option}
              data-cy={`column-select-value-setter-column_names-option-${option}`}
            >
              {getColumnIcon(columnMapping[option])}
              {option}
            </div>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="列名の指定"
              variant="outlined"
              fullWidth={true}
              data-cy="column-select-value-setter-column_names"
            />
          )}
          openOnFocus={true}
        />
      );

    case SelectTypes.column_types: {
      const sel = DtypeItems.filter((dt) => rule.value.includes(dt.value));
      return (
        <Autocomplete
          style={{ display: 'inline-flex', width }}
          classes={{
            inputRoot: classes.field
          }}
          disableCloseOnSelect={true}
          size="small"
          value={sel}
          multiple={true}
          options={DtypeItems}
          getOptionLabel={(op: (typeof DtypeItems)[0]) => op.text}
          filterSelectedOptions={true}
          onChange={onChangeTypeSelect}
          renderTags={renderTags}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="データ型の指定"
              variant="outlined"
              fullWidth={true}
            />
          )}
          openOnFocus={true}
        />
      );
    }

    case SelectTypes.regex:
      return (
        <TextField
          placeholder="正規表現を入力"
          InputProps={{
            classes: { root: classes.field }
          }}
          style={{ width }}
          size="small"
          variant="outlined"
          value={inputText}
          onChange={({ currentTarget }) => {
            setInputText(currentTarget.value);
          }}
        />
      );

    case SelectTypes.starts_with:
    case SelectTypes.ends_with:
    case SelectTypes.includes:
      return (
        <TextField
          placeholder="文字を入力"
          InputProps={{
            classes: { root: classes.field }
          }}
          style={{ width }}
          size="small"
          variant="outlined"
          value={inputText}
          onChange={({ currentTarget }) => {
            setInputText(currentTarget.value);
          }}
        />
      );
  }
};

const itemStyle = makeStyles({
  container: {
    padding: 10
  },
  base: {
    margin: 4
  },
  error: {}
});

const SelectedItems: React.FC<{
  columns: string[];
  dtypes: Dtypes[];
  rule: ColumnSelectRule;
  onChange: (value: any) => void;
}> = ({ rule, columns, dtypes, onChange }) => {
  const classes = itemStyle();
  const [columnMapping, setColumnMapping] = React.useState<{
    [col: string]: Dtypes;
  }>(Object.fromEntries(columns.map((c, i) => [c, dtypes[i]])));
  React.useEffect(() => {
    setColumnMapping(Object.fromEntries(columns.map((c, i) => [c, dtypes[i]])));
  }, [columns, dtypes]);
  const onDelete = React.useCallback(
    (index) => () => {
      if (
        [SelectTypes.column_names, SelectTypes.column_types].includes(rule.type)
      ) {
        const newValue = [...(rule.value as string[] | Dtypes[])];
        newValue.splice(index, 1);
        onChange(newValue);
      }
    },
    [rule, onChange]
  );

  switch (rule.type) {
    case SelectTypes.column_names:
      return (
        <div className={classes.container}>
          {rule.value.map((option, index) => {
            let props = {};
            if (!columnMapping[option]) {
              props = {
                color: 'secondary'
              };
            }

            return (
              <Chip
                className={classes.base}
                avatar={<Avatar>{getColumnIcon(columnMapping[option])}</Avatar>}
                key={option}
                label={option}
                size="small"
                tabIndex={-1}
                onDelete={onDelete(index)}
                {...props}
              />
            );
          })}
        </div>
      );

    case SelectTypes.column_types:
      return (
        <div className={classes.container}>
          {rule.value.map((option, index) => {
            return (
              <Chip
                className={classes.base}
                avatar={<Avatar>{getColumnIcon(option)}</Avatar>}
                key={option}
                label={toDtypeLabel(option)}
                size="small"
                tabIndex={-1}
                onDelete={onDelete(index)}
              />
            );
          })}
        </div>
      );

    default:
      return null;
  }
};

const selectorStyle = makeStyles({
  container: {
    position: 'relative',
    border: '1px solid #4f9af8',
    borderRadius: 2,
    marginTop: 4,
    marginBottom: 4,
    '&:first-child': {
      '&:hover': {
        margin: '3px -1px -1px -1px'
      }
    },
    '&:last-child': {
      '&:hover': {
        margin: '-1px -1px 3px -1px'
      }
    },
    '&:hover': {
      borderWidth: 2,
      margin: '-1px',
      '&:after': {
        borderRightWidth: 2,
        borderBottomWidth: 2,
        bottom: -10
      }
    },
    '&:after': {
      position: 'absolute',
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      borderTop: '0px solid transparent',
      borderRight: '1px solid #4f9af8',
      borderBottom: '1px solid #4f9af8',
      borderLeft: '0px solid transparent',
      bottom: -9,
      left: '50%',
      marginTop: -8,
      marginLeft: -8,
      content: "''", // tslint:disable-line
      transform: 'rotate(45deg)',
      backgroundColor: '#edf5ff',
      zIndex: 1
    },
    '&.noRules': {
      '&:after': {
        backgroundColor: '#d7e5f6'
      }
    }
  },
  containerExclude: {
    border: '1px solid #df5d76',
    '&:after': {
      backgroundColor: '#fff4f6',
      borderRightColor: '#df5d76',
      borderBottomColor: '#df5d76'
    },
    '&:hover': {
      border: '2px solid #df5d76'
    },
    '&.noRules': {
      '&:after': {
        backgroundColor: '#fbe1e6'
      }
    }
  },
  ruleContainer: {
    backgroundColor: '#d7e5f6',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 10px'
  },
  ruleContainerExclude: {
    backgroundColor: '#fbe1e6'
  },
  itemsContainer: {
    backgroundColor: '#edf5ff'
  },
  itemsContainerExclude: {
    backgroundColor: '#fff4f6'
  }
});

const ColumnSelectorArray: React.FC<{
  value: ColumnSelectRules;
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: ColumnSelectRules) => void;
}> = ({ value, columns, dtypes, onChange }) => {
  const classes = selectorStyle();
  const onAdd = (index: number) => () => {
    // index + 1の場所にアイテムを追加する
    onChange([
      ...value.slice(0, index + 1),
      generateNewRule(),
      ...value.slice(index + 1)
    ]);
  };

  const onRemove = (index: number) => () => {
    onChange([...value.slice(0, index), ...value.slice(index + 1)]);
  };

  return (
    <>
      {value.map((v, index) => {
        const hasNoItems = !(
          [SelectTypes.column_types, SelectTypes.column_names] as SelectTypes[]
        ).includes(v.type);

        return (
          <div
            key={index}
            className={clsx(classes.container, {
              [classes.containerExclude]: v.exclude,
              noRules: hasNoItems
            })}
          >
            <div
              className={clsx(classes.ruleContainer, {
                [classes.ruleContainerExclude]: v.exclude
              })}
            >
              <IncludeTypeSelect
                value={v.exclude}
                onChange={(newVal) => {
                  const newState = produce(value, (draft) => {
                    draft[index].exclude = newVal;
                  });
                  onChange(newState);
                }}
              />
              <SelectTypeSelect
                value={v.type}
                onChange={(newVal) => {
                  const newState = produce(value, (draft) => {
                    if (draft[index].type !== newVal) {
                      draft[index].type = newVal;
                      draft[index].value = getDefaultValue(newVal);
                    }
                  });
                  onChange(newState);
                }}
              />
              <ColumnSelectValueSetter
                columns={columns}
                dtypes={dtypes}
                rule={v}
                onChange={(newVal) => {
                  const newState = produce(value, (draft) => {
                    draft[index].value = newVal;
                  });
                  onChange(newState);
                }}
              />
              <IconButton
                onClick={onAdd(index)}
                size="small"
                style={{ marginRight: 8, marginLeft: 8 }}
              >
                <Add fontSize="inherit" />
              </IconButton>
              <IconButton
                onClick={onRemove(index)}
                size="small"
                disabled={index === 0}
              >
                <Remove fontSize="inherit" />
              </IconButton>
            </div>
            <div
              className={clsx(classes.itemsContainer, {
                [classes.itemsContainerExclude]: v.exclude
              })}
            >
              <SelectedItems
                columns={columns}
                dtypes={dtypes}
                rule={v}
                onChange={(newVal) => {
                  const newState = produce(value, (draft) => {
                    draft[index].value = newVal;
                  });
                  onChange(newState);
                }}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

interface ColumnSelectDialogProps {
  open: boolean;
  value: ColumnSelectRules;
  schema: ColumnSelectV2FieldSchema;
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: ColumnSelectRules) => void;
  onClose: () => void;
  projectId?: string;
  portId?: string;
  dataStatus: PortStatus;
  colnameMap?: ColumnOptionsV2Names;
}

const dialogStyles = makeStyles({
  title: {
    backgroundColor: '#f0f0f0'
  },
  content: {
    backgroundColor: '#e0e0e0',
    padding: 0
  },
  columnListContainer: {
    padding: '24px 10px',
    width: 800,
    height: 300,
    overflow: 'hidden',
    paddingRight: 10
  },
  columnListTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#414141',
    paddingBottom: 5,
    borderBottom: '2px solid #b0b0b0'
  },
  rulesContainer: {
    width: 704,
    minHeight: 350,
    backgroundColor: '#fff',
    padding: '24px 32px',
    overflowY: 'auto'
  },
  dialogActions: {
    backgroundColor: '#f0f0f0'
  },
  actions: {
    width: 704,
    justifyContent: 'center'
  }
});

export const ColumnSelectDialog: React.FC<ColumnSelectDialogProps> = ({
  open,
  value,
  schema,
  columns,
  dtypes,
  onChange,
  onClose,
  projectId,
  portId,
  dataStatus,
  colnameMap
}) => {
  const classes = dialogStyles();
  const [rules, setRules] = React.useState<ColumnSelectRules>([]);
  const onOKClicked = React.useCallback(() => {
    onChange(rules);
    onClose();
  }, [rules, onChange, onClose]);
  React.useEffect(() => {
    // ダイアログを開いたら、rulesにvalueをコピーする
    if (open) {
      setRules(initializeRules(value));
    }
  }, [open]);
  const [selected, notSelected] = selectColumnsByRule(columns, dtypes, rules);
  const errors = checkDtypes(selected, schema.dtypes);
  const isError = Object.keys(errors).length > 0 ? true : false;

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={onClose}
      data-cy="column-select-dialog"
      title="選択ルールを作成してください"
      contentProps={{ className: classes.content }}
      OKButton={{
        onClick: onOKClicked,
        disabled: isError
      }}
    >
      <div style={{ display: 'flex', maxHeight: 600 }}>
        <div className={classes.rulesContainer}>
          {Object.keys(errors).length > 0 && schema.dtypes && (
            <Typography color="secondary" variant="subtitle2">
              選択できない型の列が含まれます。選択可能な型は、
              {schema.dtypes.map((d) => toDtypeLabel(d)).join(', ')}です。
            </Typography>
          )}
          <ColumnSelectorArray
            value={rules}
            columns={columns}
            dtypes={dtypes}
            onChange={setRules}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div className={classes.columnListContainer}>
            <Typography className={classes.columnListTitle}>
              未選択 ({notSelected.length} 列)
            </Typography>
            <DataTable
              dataStatus={dataStatus}
              portId={portId}
              projectId={projectId}
              displayColumns={notSelected.map((c) => c.name)}
              colnameMap={colnameMap}
            />
          </div>
          <div className={classes.columnListContainer}>
            <Typography className={classes.columnListTitle}>
              選択済み ({selected.length} 列)
            </Typography>
            <DataTable
              dataStatus={dataStatus}
              portId={portId}
              projectId={projectId}
              displayColumns={selected.map((c) => c.name)}
              colnameMap={colnameMap}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
};

const listStyles = makeStyles({
  item: {
    padding: 0,
    color: '#787878'
  },
  text: {
    fontWeight: 500
  }
});

const ListColumns: React.FC<{
  columns: Column[];
  errors?: ColumnError;
  hideNone?: boolean;
}> = ({ columns, errors, hideNone }) => {
  const classes = listStyles();
  const columnErrors = errors ? errors : {};
  if (hideNone && columns.length === 0) {
    return null;
  }
  return (
    <List dense={true} disablePadding={true}>
      {columns.length === 0 && (
        <ListItem className={classes.item}>
          <ListItemText>なし</ListItemText>
        </ListItem>
      )}
      {columns.map(({ name, dtype }) => {
        const errorProps = {};
        if (columnErrors[name]) {
          errorProps['color'] = 'secondary';
        }
        return (
          <ListItem key={name} className={classes.item}>
            <ListItemText
              primaryTypographyProps={errorProps}
              classes={{ primary: classes.text }}
            >
              {getColumnIcon(dtype)}&nbsp;
              {name}
            </ListItemText>
          </ListItem>
        );
      })}
    </List>
  );
};

const isEmpty = (val: Dtypes | string | string[]): boolean => {
  if (Array.isArray(val)) {
    return val.length === 0;
  }
  return val === '';
};

export const selectColumnsByRule = (
  columns: string[],
  dtypes: Dtypes[],
  rules: ColumnSelectRules
): [Column[], Column[]] => {
  // ルールを一つづつ適用していく
  let current = new Set<string>();

  // １つ目が除くの場合は、すべてのカラムから除去する
  const firstRule = rules[0];
  if (firstRule && firstRule.exclude) {
    if (
      firstRule.type !== SelectTypes.all_columns &&
      !isEmpty(firstRule.value)
    ) {
      current = new Set(columns);
    }
  }

  rules.forEach((r) => {
    let result = new Set(columns);
    // ルールが空の時はスキップする
    if (r.type !== SelectTypes.all_columns && isEmpty(r.value)) {
      return;
    }

    switch (r.type) {
      case SelectTypes.all_columns:
        break;

      case SelectTypes.column_names:
        result = new Set(r.value);
        break;

      case SelectTypes.column_types:
        columns.forEach((c, index) => {
          if (!r.value.includes(dtypes[index])) {
            result.delete(c);
          }
        });
        break;

      case SelectTypes.regex:
        try {
          const re = new RegExp(r.value);
          columns.forEach((c) => {
            if (!re.test(c)) {
              result.delete(c);
            }
          });
        } catch {
          result.clear();
        }
        break;

      case SelectTypes.starts_with:
        columns.forEach((c) => {
          if (!c.startsWith(r.value)) {
            result.delete(c);
          }
        });
        break;

      case SelectTypes.ends_with:
        columns.forEach((c) => {
          if (!c.endsWith(r.value)) {
            result.delete(c);
          }
        });
        break;

      case SelectTypes.includes:
        columns.forEach((c) => {
          if (!c.includes(r.value)) {
            result.delete(c);
          }
        });
        break;
    }

    current = r.exclude ? diffSet(current, result) : unionSet(current, result);
  });

  // 元の順序を維持しながらSetから取り出す
  const inc = [] as Column[];
  const exc = [] as Column[];
  columns.forEach((c, i) => {
    if (current.has(c)) {
      inc.push({ name: c, dtype: dtypes[i] });
    } else {
      exc.push({ name: c, dtype: dtypes[i] });
    }
  });

  return [inc, exc];
};

const SmallSelect = withStyles({
  root: {
    fontSize: 14,
    backgroundColor: '#fff',
    '&:focus': {
      backgroundColor: '#fff'
    }
  }
})(Select);

interface ColumnV1 {
  label: string;
  value: string;
  dtype: Dtypes;
}

export const migrateColumnSelectValue = (
  val: ColumnSelectV2Value | ColumnV1 | ColumnV1[] | null
): ColumnSelectV2Value | null => {
  if (val == undefined) {
    return null;
  }

  if (!Array.isArray(val) && val['version'] === 2) {
    return val as ColumnSelectV2Value;
  }

  // 旧multiのカラム選択
  if (Array.isArray(val)) {
    const rule = generateNewRule();
    rule.value = (val as ColumnV1[]).map((v) => v.value);
    return { version: 2, rules: [rule] };
  }

  if (val.hasOwnProperty('value')) {
    const rule = generateNewRule();
    rule.value = [(val as ColumnV1).value];
    return { version: 2, rules: [rule] };
  }

  return null;
};

const getNotExistColumns = (
  columns: string[],
  rules: ColumnSelectRules
): string[] => {
  if (columns.length === 0) {
    return [];
  }
  const notExistColumns = rules
    .map((r) => {
      if (r.type === SelectTypes.column_names) {
        // columnsに含まれていないvalueを取得
        return r.value.filter((c) => !columns.includes(c));
      } else {
        return [];
      }
    })
    .flat();
  return [...new Set(notExistColumns)];
};
