import * as React from 'react';
import MainLayout from 'components/layouts/main';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import ListView from 'components/ui/listView';
import {
  addUserGroup,
  deleteUserGroup,
  getAdminUserGroups,
  putUserGroup
} from 'libs/api';
import { projectTheme } from '../../../theme';
import { QueryParams } from 'ui/listViewBase';
import { UserGroupItem } from 'models/dependency';
import { Dialog } from 'ui/common/dialog';
import { UserGroup } from 'models/user_group';
import { useDisclosure } from '../../../hooks/useDisclosure';
import { TextField } from 'ui/common/textfield';

const AdminUserGroupListPage = () => {
  const loadFunc = React.useMemo(() => {
    return async (_, queryParams?: QueryParams) => {
      const { data } = await getAdminUserGroups(queryParams);
      return { data: { items: data, paths: [] } };
    };
  }, []);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedGroup, setSelectedGroup] = React.useState<UserGroup | null>(
    null
  );
  const [key, setKey] = React.useState(0);

  return (
    <MainLayout selectedMenu="admin/user_groups">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            key={key}
            type="user_groups"
            load={loadFunc}
            move={() => {
              setSelectedGroup(null);
              onOpen();
            }}
            onClickItemLink={(item: UserGroupItem) => {
              setSelectedGroup({
                id: item.uuid,
                name: item.name,
                ip_address: item.ip_address,
                all_users: item.all_users
              });
              onOpen();
            }}
            delete={deleteUserGroup}
            showDetailPane={false}
            supportFolder={false}
            isAdminPage={true}
          />
        </ThemeProvider>
      </Grid>
      <EditUserGroupDialog
        key={selectedGroup ? selectedGroup.id : 'new'}
        open={isOpen}
        onClose={(reload: boolean) => {
          onClose();
          if (reload) {
            setKey((key) => key + 1);
          }
        }}
        defaultGroup={selectedGroup}
      />
    </MainLayout>
  );
};

const EditUserGroupDialog = ({
  defaultGroup,
  open,
  onClose
}: {
  defaultGroup: UserGroup | null;
  open: boolean;
  onClose: (reload: boolean) => void;
}) => {
  const [g, setG] = React.useState<UserGroup>(() => {
    return {
      id: defaultGroup ? defaultGroup.id : '',
      name: defaultGroup ? defaultGroup.name : '',
      ip_address: defaultGroup ? defaultGroup.ip_address : '',
      all_users: defaultGroup ? defaultGroup.all_users : false
    };
  });
  return (
    <Dialog
      title={g.id ? 'グループの編集' : 'グループの追加'}
      open={open}
      fullWidth={true}
      maxWidth="sm"
      onClose={() => onClose(false)}
      contentProps={{
        style: { display: 'flex', flexDirection: 'column', gap: 16 }
      }}
      OKButton={{
        onClick: async () => {
          if (g.id) {
            await putUserGroup(g);
          } else {
            await addUserGroup(g);
          }
          onClose(true);
        }
      }}
    >
      <TextField
        label="グループ名"
        value={g.name}
        InputLabelProps={{ shrink: true }}
        disabled={g.all_users}
        onChange={(e) => setG({ ...g, name: e.target.value })}
      />
      <TextField
        label="ダッシュボード公開制限用IPアドレスを紐付ける"
        value={g.ip_address}
        InputLabelProps={{ shrink: true }}
        onChange={(e) => setG({ ...g, ip_address: e.target.value })}
      />
    </Dialog>
  );
};

export default AdminUserGroupListPage;
