import * as React from 'react';
import {
  CardType,
  ChartItem,
  Parameter,
  ParameterDType,
  ParameterGroup,
  ParameterType,
  ParameterValues,
  Report,
  ReportItem,
  ReportToken,
  TextItem
} from 'models/report';
import { Chart, Option, ChartType } from 'models/chart';
import { produce } from 'immer';
import { omit } from 'lodash-es';
import {
  DEFAULT_TEXT_CARD_SIZE,
  getPositionForNewItem
} from 'components/report/grid';
import { GenerateId } from 'components/diagram/utils';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { Dtypes } from 'Utils/dataTypes';
import { ItemTypes } from 'components/report/parameter/itemTypes';
import { migrateViewHeatmap, toLatestChartSettings } from './chartReducer';
import {
  dateRangeInitialValues,
  dateSingleInitialValues,
  datetimeRangeInitialValues,
  datetimeSingleInitialValues,
  timeRangeInitialValues,
  timeSingleInitialValues
} from 'components/report/parameter/date/initialValue';
import { setInitialValues } from 'components/report/parameter/initialValue';
import { DateParameterClassFactory } from 'components/report/parameter/parameterClass/factory';

export enum ReportAction {
  load = 'load',
  load_parameters = 'load_parameters',
  addText = 'add_text',
  addChart = 'add_chart',
  addImage = 'add_image',
  addParameter = 'add_parameter',
  itemAdded = 'item_added',
  changeTextContent = 'change_text_content',
  changeLayout = 'change_layout',
  deleteItem = 'delete_item',
  deleteParameter = 'delete_parameter',
  changeName = 'change_name',
  changeParameterName = 'change_parameter_name',
  changeParameterGroupName = 'change_parameter_group_name',
  changeParameterValue = 'change_parameter_value',
  changeParameterOrder = 'change_parameter_order',
  createParameterGroup = 'crete_parameter_group',
  addParameterInGroup = 'add_parameter_in_group',
  deleteParameterInGroup = 'delete_parameter_in_group',
  deleteParameterGroup = 'delete_parameter_group',
  setParameterColumn = 'set_parameter_column',
  setFetched = 'set_fetched',
  chartRendered = 'chart_rendered',
  enableSave = 'enable_save',
  disableSave = 'disable_save',
  changePortalFolderId = 'change_portal_folder_id',
  changePortalChecked = 'change_portal_checked',
  changeEnabledPublicTokenChecked = 'change_enabled_public_token_checked',
  changePublicRestrict = 'change_public_restrict',
  resetPublicToken = 'reset_public_token'
}

interface ChangeNameAction {
  type: ReportAction.changeName;
  payload: {
    name: string;
  };
}

interface LoadAction {
  type: ReportAction.load;
  payload: ReportState;
}

interface LoadParametersAction {
  type: ReportAction.load_parameters;
  payload: {
    parameters: Parameter[];
  };
}

interface AddTextAction {
  type: ReportAction.addText;
}

interface AddChartAction {
  type: ReportAction.addChart;
  payload: {
    ids: string[];
    charts: Chart[];
  };
}

interface ItemAddedAction {
  type: ReportAction.itemAdded;
}

interface AddParameterAction {
  type: ReportAction.addParameter;
  payload: {
    dtype: ParameterDType;
    type: ParameterType;
  };
}

interface DeleteParameterAction {
  type: ReportAction.deleteParameter;
  payload: {
    uuid: string;
  };
}

interface ChangeParameterNameAction {
  type: ReportAction.changeParameterName;
  payload: {
    uuid: string;
    name: string;
  };
}

interface ChangeParameterGroupNameAction {
  type: ReportAction.changeParameterGroupName;
  payload: {
    uuid: string;
    name: string;
  };
}

interface ChangeTextContentAction {
  type: ReportAction.changeTextContent;
  payload: { uuid: string; content: RawDraftContentState };
}

interface ChangeLayoutAction {
  type: ReportAction.changeLayout;
  payload: { items: ReportItem[] };
}

interface DeleteItemAction {
  type: ReportAction.deleteItem;
  payload: { id: string };
}

interface ChangeParameterValueAction {
  type: ReportAction.changeParameterValue;
  payload: { uuid: string; values: Partial<ParameterValues> };
}

interface SetParameterColumnAction {
  type: ReportAction.setParameterColumn;
  payload: {
    uuid: string;
    itemId: string;
    chartId: string;
    column?: Option;
  };
}

interface ChangeParameterOrderAction {
  type: ReportAction.changeParameterOrder;
  payload: {
    id: string;
    atIndex: number;
    isGroup: boolean;
  };
}

interface CreateParameterGroupAction {
  type: ReportAction.createParameterGroup;
}

interface AddParameterInGroup {
  type: ReportAction.addParameterInGroup;
  payload: {
    groupedParameterId: string;
    id: string;
    atIndex: number;
  };
}

interface DeleteParameterInGroup {
  type: ReportAction.deleteParameterInGroup;
  payload: {
    groupedParameterId: string;
    atIndex: number;
  };
}

interface DeleteParameterGroupAction {
  type: ReportAction.deleteParameterGroup;
  payload: { uuid: string };
}

interface SetFetchedAction {
  type: ReportAction.setFetched;
  payload: { [uuid: string]: boolean };
}

interface ChartRendered {
  type: ReportAction.chartRendered;
  payload: { uuid: string };
}

interface Changed {
  type: ReportAction.enableSave;
}

interface DisableSave {
  type: ReportAction.disableSave;
}

interface CangePortalFolderId {
  type: ReportAction.changePortalFolderId;
  payload: { portalFolderId: string };
}

interface ChangePortalChecked {
  type: ReportAction.changePortalChecked;
  payload: { checked: boolean };
}

interface ChangeEnabledPublicTokenChecked {
  type: ReportAction.changeEnabledPublicTokenChecked;
  payload: {
    enabled_public_goken: boolean;
    token?: string;
  };
}

interface ChangePublicRestrictAction {
  type: ReportAction.changePublicRestrict;
  payload: {
    public_token: Omit<ReportToken, 'token' | 'password_created'>;
  };
}

interface ChangePublicTokenAction {
  type: ReportAction.resetPublicToken;
  payload: {
    token: string;
  };
}

type ReportActionType =
  | ChangeNameAction
  | LoadAction
  | LoadParametersAction
  | AddChartAction
  | AddTextAction
  | ItemAddedAction
  | ChangeTextContentAction
  | ChangeLayoutAction
  | DeleteItemAction
  | AddParameterAction
  | DeleteParameterAction
  | ChangeParameterNameAction
  | ChangeParameterGroupNameAction
  | ChangeParameterValueAction
  | ChangeParameterOrderAction
  | AddParameterInGroup
  | DeleteParameterInGroup
  | DeleteParameterGroupAction
  | SetParameterColumnAction
  | SetFetchedAction
  | CreateParameterGroupAction
  | ChartRendered
  | Changed
  | DisableSave
  | CangePortalFolderId
  | ChangePortalChecked
  | ChangeEnabledPublicTokenChecked
  | ChangePublicRestrictAction
  | ChangePublicTokenAction;

function initParameter(payload: {
  dtype: ParameterDType;
  type: ParameterType;
}): Parameter | undefined {
  const { dtype, type } = payload;
  const uuid = `param-${GenerateId()}`;
  const name = 'untitled';
  let newParam = {
    uuid,
    name,
    dtype,
    type,
    target: {}
  } as Parameter;
  switch (newParam.dtype) {
    case Dtypes.STRING:
      newParam.name = '文字列フィルタ';
      if (newParam.type === ParameterType.Single) {
        newParam = {
          ...newParam,
          operator: '==',
          value: undefined
        };
        return newParam;
      }
      if (newParam.type === ParameterType.Multiple) {
        newParam = {
          ...newParam,
          operator: 'in',
          values: []
        };
        return newParam;
      }
      return undefined;
    case Dtypes.NUMBER:
      newParam.name = '数値フィルタ';
      if (newParam.type === ParameterType.Single) {
        newParam = {
          ...newParam,
          operator: '==',
          value: undefined
        };
        return newParam;
      }

      if (newParam.type === ParameterType.Range) {
        newParam = {
          ...newParam,
          min: undefined,
          max: undefined
        };
        return newParam;
      }
      return undefined;
    case Dtypes.TIME:
      newParam.name = '時間フィルタ';
      if (newParam.type === ParameterType.Single) {
        newParam = {
          ...newParam,
          values: timeSingleInitialValues
        };
        return newParam;
      }

      if (newParam.type === ParameterType.Range) {
        newParam = {
          ...newParam,
          values: timeRangeInitialValues
        };
        return newParam;
      }
      return undefined;
    case Dtypes.DATE:
      newParam.name = '日付フィルタ';
      if (newParam.type === ParameterType.Single) {
        newParam = {
          ...newParam,
          values: dateSingleInitialValues
        };
        return newParam;
      }

      if (newParam.type === ParameterType.Range) {
        newParam = {
          ...newParam,
          values: dateRangeInitialValues
        };
        return newParam;
      }

      return undefined;

    case Dtypes.TIMESTAMP: {
      newParam.name = '日付時間フィルタ';
      if (newParam.type === ParameterType.Single) {
        newParam = {
          ...newParam,
          values: datetimeSingleInitialValues
        };
        return newParam;
      }

      if (newParam.type === ParameterType.Range) {
        newParam = {
          ...newParam,
          values: datetimeRangeInitialValues
        };
        return newParam;
      }

      return undefined;
    }
  }
  return undefined;
}

function migrateReportItem(item: ReportItem): ReportItem {
  return produce(item, (draft) => {
    if (
      draft.type === CardType.Chart &&
      draft.chart != undefined &&
      draft.chart.type === ChartType.table &&
      draft.chart.chart.columns != undefined
    ) {
      draft.chart = toLatestChartSettings(draft.chart);
      draft.chart.view = migrateViewHeatmap(draft.chart.view);
      return draft;
    }
    return draft;
  });
}

export type ReportDispatch = (action: ReportActionType) => void;

export type ReportState = Omit<Report, 'status' | 'error_charts'> & {
  save_enabled: boolean;
};

export function reportReducer(
  state: ReportState,
  action: ReportActionType
): ReportState {
  switch (action.type) {
    case ReportAction.load:
      return produce(state, (draft) => {
        const { public_token, ...rest } = action.payload;
        if (!public_token) {
          // public_tokenが空の場合は初期値のままにしたいので
          draft = {
            ...draft,
            ...rest
          };
        } else {
          draft = action.payload;
        }
        draft.items = draft.items.map((item) => {
          item = migrateReportItem(item);
          return { ...item, fetched: false };
        });

        // temporary
        draft.parameters = draft.parameters.map((p, i) => {
          if (p.order_number == undefined) {
            p.order_number = i;
            return p;
          }

          return p;
        });

        // 時間型のフィルター & versionが2じゃない場合は既存のフィルターを無視して、v2で初期化する
        draft.parameters = draft.parameters.map((p) => {
          if (
            p.dtype === Dtypes.DATE ||
            p.dtype === Dtypes.TIME ||
            p.dtype === Dtypes.TIMESTAMP
          ) {
            if (p?.values?.version !== 2) {
              return setInitialValues(p);
            } else {
              const factory = new DateParameterClassFactory();
              const dateInstance = factory.createInstance(p);
              p.values.inputValue = dateInstance.calculateInputValue();
              return p;
            }
          }
          return p;
        });

        if (!draft.parameter_groups) {
          draft.parameter_groups = [];
        }
        return draft;
      });
    case ReportAction.load_parameters:
      return produce(state, (draft) => {
        draft.parameters = action.payload.parameters;
        // temporary
        draft.parameters = draft.parameters.map((p, i) => {
          if (p.order_number == undefined) {
            p.order_number = i;
            return p;
          }
          return p;
        });

        // 時間型のフィルター & versionが2じゃない場合は既存のフィルターを無視して、v2で初期化する
        draft.parameters = draft.parameters.map((p) => {
          if (
            p.dtype === Dtypes.DATE ||
            p.dtype === Dtypes.TIME ||
            p.dtype === Dtypes.TIMESTAMP
          ) {
            if (p?.values?.version !== 2) {
              return setInitialValues(p);
            } else {
              const factory = new DateParameterClassFactory();
              const dateInstance = factory.createInstance(p);
              p.values.inputValue = dateInstance.calculateInputValue();
              return p;
            }
          }
          return p;
        });

        return draft;
      });
    case ReportAction.addChart:
      return produce(state, (draft) => {
        action.payload.ids.forEach((id, i) => {
          const newChartItem: ChartItem = {
            type: CardType.Chart,
            uuid: `chart-${GenerateId()}`,
            chart: action.payload.charts.find((chart) => chart.uuid === id),
            justAdded: action.payload.ids.length === i + 1,
            fetched: false,
            ...getPositionForNewItem(draft.items)
          };
          draft.items.push(migrateReportItem(newChartItem));
        });
        return draft;
      });
    case ReportAction.addText: {
      const newTextItem: TextItem = {
        type: CardType.Text,
        uuid: `text-${GenerateId()}`,
        content: { entityMap: {}, blocks: [] },
        justAdded: true,
        ...getPositionForNewItem(
          state.items,
          DEFAULT_TEXT_CARD_SIZE.width,
          DEFAULT_TEXT_CARD_SIZE.height
        )
      };
      return produce(state, (draft) => {
        draft.items.push(newTextItem);
        return draft;
      });
    }
    case ReportAction.deleteItem:
      return produce(state, (draft) => {
        draft.items = draft.items.filter(
          (item) => item.uuid !== action.payload.id
        );

        draft.parameters.forEach((param, i) => {
          if (param.target[action.payload.id]) {
            delete draft.parameters[i].target[action.payload.id];
          }
        });
        return draft;
      });
    case ReportAction.itemAdded:
      return produce(state, (draft) => {
        draft.items = draft.items.map((item: ReportItem) =>
          omit<ReportItem>(item, 'justAdded')
        ) as ReportItem[];
        return draft;
      });
    case ReportAction.changeTextContent:
      return produce(state, (draft) => {
        draft.items = draft.items.map((item) =>
          item.uuid === action.payload.uuid
            ? { ...item, content: action.payload.content }
            : item
        );
        return draft;
      });
    case ReportAction.changeLayout:
      return produce(state, (draft) => {
        draft.items = action.payload.items;
        return draft;
      });
    case ReportAction.changeName:
      return produce(state, (draft) => {
        draft.name = action.payload.name;
        draft.save_enabled = true;
        return draft;
      });
    case ReportAction.addParameter: {
      const newParam = initParameter(action.payload);
      if (newParam === undefined) {
        return state;
      }

      return produce(state, (draft) => {
        const paramOrders = draft.parameters
          .filter((p) => p.group_uuid == undefined)
          .map((p) => p.order_number);

        const groupOrders = draft.parameter_groups.map((g) => g.order_number);

        newParam.order_number = Math.max(...paramOrders, ...groupOrders) + 1;
        draft.parameters.push(newParam);
        return draft;
      });
    }
    case ReportAction.deleteParameter:
      return produce(state, (draft) => {
        draft.parameters = draft.parameters.filter(
          (param) => param.uuid !== action.payload.uuid
        );
        return draft;
      });
    case ReportAction.changeParameterName:
      return produce(state, (draft) => {
        draft.parameters = draft.parameters.map((param) =>
          param.uuid === action.payload.uuid
            ? { ...param, name: action.payload.name }
            : param
        );
        return draft;
      });

    case ReportAction.changeParameterGroupName:
      return produce(state, (draft) => {
        draft.parameter_groups.forEach((g) => {
          if (g.uuid === action.payload.uuid) {
            g.name = action.payload.name;
          }
        });
        return draft;
      });
    case ReportAction.changeParameterValue:
      return produce(state, (draft) => {
        draft.parameters = draft.parameters.map((param) =>
          param.uuid === action.payload.uuid
            ? { ...param, ...action.payload.values }
            : param
        ) as Parameter[];
        return draft;
      });

    case ReportAction.changeParameterOrder:
      return produce(state, (draft) => {
        const { id, atIndex, isGroup } = action.payload;
        const parameter = draft.parameters.find((param) => param.uuid === id);

        // order in group
        if (parameter != undefined && !isGroup) {
          // sort in group
          if (parameter.group_uuid) {
            const groupedParameters = draft.parameters.filter(
              (p) => p.group_uuid === parameter.group_uuid
            );
            groupedParameters.sort((a, b) => a.order_number - b.order_number);
            const index = groupedParameters.findIndex(
              (p) => p.uuid === parameter.uuid
            );
            groupedParameters.splice(index, 1);
            groupedParameters.splice(atIndex, 0, parameter);

            groupedParameters.forEach((p, i) => {
              const index = draft.parameters.findIndex(
                (param) => param.uuid === p.uuid
              );
              draft.parameters[index].order_number = i;
            });
            return draft;
          }
        }

        let orderItems: {
          id: string;
          type: ItemTypes;
          order_number: number;
        }[] = [];

        draft.parameters.forEach((param) => {
          if (param.group_uuid == undefined) {
            orderItems.push({
              id: param.uuid,
              type: ItemTypes.PARAMETER,
              order_number: param.order_number
            });
          }
        });

        draft.parameter_groups.forEach((group) => {
          orderItems.push({
            id: group.uuid,
            type: ItemTypes.GROUP,
            order_number: group.order_number
          });
        });

        const group = draft.parameter_groups.find((group) => group.uuid === id);
        if (isGroup && group == undefined) {
          return draft;
        }

        const uuid = isGroup ? group?.uuid : id;

        const itemType = isGroup ? ItemTypes.GROUP : ItemTypes.PARAMETER;

        orderItems.sort((a, b) => {
          return a.order_number - b.order_number;
        });

        const index = orderItems.findIndex(
          (item) => item.id === uuid && item.type === itemType
        );
        if (index < 0) {
          return draft;
        }

        const item = orderItems[index];
        orderItems.splice(index, 1);
        orderItems.splice(atIndex, 0, item);
        // reset order_number
        orderItems = orderItems.map((item, i) => {
          item.order_number = i;
          return item;
        });

        draft.parameter_groups.forEach((group, i) => {
          const item = orderItems.find(
            (item) => group.uuid === item.id && item.type === ItemTypes.GROUP
          );
          if (item) {
            draft.parameter_groups[i].order_number = item.order_number;
          }
        });

        draft.parameters.forEach((param, i) => {
          const item = orderItems.find(
            (item) =>
              param.uuid === item.id && item.type === ItemTypes.PARAMETER
          );
          if (item) {
            draft.parameters[i].order_number = item.order_number;
          }
        });
        return draft;
      });
    case ReportAction.setParameterColumn:
      return produce(state, (draft) => {
        const { column } = action.payload;
        if (column == undefined) {
          draft.parameters = draft.parameters.map((param) => {
            if (param.uuid === action.payload.uuid) {
              delete param.target[action.payload.itemId];
            }
            return param;
          });
        } else {
          draft.parameters = draft.parameters.map((param) =>
            param.uuid === action.payload.uuid
              ? {
                  ...param,
                  target: {
                    ...param.target,
                    [action.payload.itemId]: {
                      chart_id: action.payload.chartId,
                      column
                    }
                  }
                }
              : param
          );
        }
        return draft;
      });
    case ReportAction.addParameterInGroup:
      return produce(state, (draft) => {
        const { groupedParameterId, id, atIndex } = action.payload;
        const groupedParameterIndex = draft.parameters.findIndex(
          (param) => param.uuid === groupedParameterId
        );

        const groupIndex = draft.parameter_groups.findIndex(
          (param) => param.uuid === id
        );
        if (groupedParameterIndex < 0 || groupIndex < 0) {
          return draft;
        }

        const group = draft.parameter_groups[groupIndex];

        draft.parameters[groupedParameterIndex].group_uuid = group.uuid;
        const parameterId = draft.parameters[groupedParameterIndex].uuid;

        const groupParameters: { [id: string]: number } = {};
        draft.parameters
          .filter((param) => param.group_uuid === group.uuid)
          .forEach((p) => {
            if (p.uuid === parameterId) {
              groupParameters[p.uuid] = atIndex;
            }

            if (p.order_number >= atIndex) {
              groupParameters[p.uuid] = p.order_number + 1;
            }
            groupParameters[p.uuid] = p.order_number;
          });

        draft.parameters.forEach((param, i) => {
          if (groupParameters[param.uuid] != undefined) {
            draft.parameters[i].order_number = groupParameters[param.uuid];
          }
        });

        return draft;
      });

    case ReportAction.deleteParameterInGroup:
      return produce(state, (draft) => {
        const { groupedParameterId, atIndex } = action.payload;
        const groupedParameterIndex = draft.parameters.findIndex(
          (param) => param.uuid === groupedParameterId
        );

        if (groupedParameterIndex < 0) {
          return draft;
        }

        const groupedParameter = draft.parameters[groupedParameterIndex];
        const groupId = groupedParameter.group_uuid;
        if (groupId) {
          draft.parameters[groupedParameterIndex].group_uuid = undefined;
          const groupedParameters = draft.parameters.filter(
            (p) => p.group_uuid === groupId
          );

          // delete group
          if (groupedParameters.length === 1) {
            draft.parameter_groups = draft.parameter_groups.filter(
              (g) => g.uuid !== groupId
            );
            groupedParameters.forEach((param) => {
              const index = draft.parameters.findIndex(
                (p) => p.uuid === param.uuid
              );
              if (index >= 0) {
                draft.parameters[index].group_uuid = undefined;
              }
            });
          }

          groupedParameters.forEach((gp, i) => {
            gp.order_number = i;
            const index = draft.parameters.findIndex((p) => p.uuid === gp.uuid);
            if (index > 0) {
              draft.parameters[index].order_number = i;
            }
          });
        }

        const orderItems: {
          id: string;
          type: ItemTypes;
          order_number: number;
        }[] = [];

        draft.parameter_groups.forEach((group) => {
          const newOrderIndex =
            group.order_number >= atIndex
              ? group.order_number + 1
              : group.order_number;

          orderItems[group.uuid] = {
            id: group.uuid,
            type: ItemTypes.GROUP,
            order_number: newOrderIndex
          };
        });

        draft.parameters
          .filter((p) => p.group_uuid == undefined)
          .forEach((param) => {
            const newOrderIndex =
              param.order_number >= atIndex
                ? param.order_number + 1
                : param.order_number;

            orderItems[param.uuid] = {
              id: param.uuid,
              type: ItemTypes.PARAMETER,
              order_number: newOrderIndex
            };
          });

        orderItems.sort((a, b) => a.order_number - b.order_number);

        orderItems.forEach((item) => {
          if (item.type === ItemTypes.GROUP) {
            const index = draft.parameter_groups.findIndex(
              (g) => g.uuid === item.id
            );
            if (index > 0) {
              draft.parameter_groups[index].order_number = item.order_number;
            }
          }

          if (item.type === ItemTypes.PARAMETER) {
            const index = draft.parameters.findIndex((p) => p.uuid === item.id);
            if (index > 0) {
              draft.parameters[index].order_number = item.order_number;
            }
          }
        });

        return draft;
      });

    case ReportAction.deleteParameterGroup:
      return produce(state, (draft) => {
        const group = draft.parameter_groups.find(
          (g) => g.uuid === action.payload.uuid
        );
        if (group == undefined) {
          return draft;
        }

        draft.parameter_groups = draft.parameter_groups.filter(
          (g) => g.uuid !== action.payload.uuid
        );

        draft.parameters.forEach((p) => {
          if (p.group_uuid === action.payload.uuid) {
            p.group_uuid = undefined;
            p.order_number = p.order_number + group.order_number;
          }
        });
        return draft;
      });

    case ReportAction.setFetched:
      return produce(state, (draft) => {
        draft.items = draft.items.map((item) =>
          action.payload[item.uuid] != undefined
            ? { ...item, fetched: action.payload[item.uuid] }
            : item
        );
        return draft;
      });
    case ReportAction.createParameterGroup:
      return produce(state, (draft) => {
        const paramOrderNumbers = draft.parameters
          .filter((p) => p.group_uuid == undefined)
          .map((p) => p.order_number);

        const groupOrderNumbers = draft.parameter_groups.map(
          (g) => g.order_number
        );

        const newOrderNumber =
          Math.max(...paramOrderNumbers, ...groupOrderNumbers) + 1;
        draft.parameter_groups.push({
          uuid: `parameter_group_${GenerateId()}`,
          name: 'グループ',
          order_number: newOrderNumber
        });
        return draft;
      });

    case ReportAction.chartRendered:
      return produce(state, (draft) => {
        draft.items = draft.items.map((item) => {
          if (item.uuid === action.payload.uuid) {
            item.isRendered = true;
          }
          return item;
        });
      });
    case ReportAction.enableSave:
      return produce(state, (draft) => {
        draft.save_enabled = true;
      });
    case ReportAction.disableSave:
      return produce(state, (draft) => {
        draft.save_enabled = false;
      });
    case ReportAction.changePortalChecked:
      return produce(state, (draft) => {
        if (!draft.portal_folder) {
          return draft;
        }
        draft.portal_folder.enabled = action.payload.checked;
        return draft;
      });
    case ReportAction.changePortalFolderId:
      return produce(state, (draft) => {
        const enabled = draft.portal_folder?.enabled || false;
        draft.portal_folder = {
          folder_id: action.payload.portalFolderId,
          enabled
        };
        return draft;
      });

    case ReportAction.changeEnabledPublicTokenChecked:
      return produce(state, (draft) => {
        const { enabled_public_goken, token } = action.payload;
        draft.enabled_public_token = enabled_public_goken;
        // tokenが設定してあれば更新する。
        if (token) {
          draft.public_token.token = token;
        }
        return draft;
      });

    case ReportAction.changePublicRestrict:
      return produce(state, (draft) => {
        draft.public_token = {
          ...draft.public_token,
          ...action.payload.public_token
        };
        return draft;
      });

    case ReportAction.resetPublicToken:
      return produce(state, (draft) => {
        draft.public_token.token = action.payload.token;
        return draft;
      });
  }
}

function initReportState(): ReportState {
  return {
    uuid: '',
    name: '',
    access_level: 0,
    items: [],
    parameters: [],
    parameter_groups: [],
    created_at: '',
    updated_at: '',
    scheduling_locked: false,
    save_enabled: false,
    enabled_public_token: false,
    public_token: {
      token: '', //
      ip_filter: '',
      user_groups: [],
      use_password: false,
      password_created: false
    }
  };
}

export function initState(): ReportState {
  return initReportState();
}

type ContextValue = {
  state: ReportState;
  dispatch: ReportDispatch;
};

export const ReportStore = React.createContext({} as ContextValue);

export function getDispatch(): ReportDispatch {
  const { dispatch } = React.useContext(ReportStore);
  return dispatch;
}

export function getState(): ReportState {
  const { state } = React.useContext(ReportStore);
  return state;
}

export const ReportProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reportReducer, initReportState());

  return (
    <ReportStore.Provider value={{ state, dispatch }}>
      {children}
    </ReportStore.Provider>
  );
};

export function cleanParameterGroup(state: ReportState): {
  items: ReportItem[];
  parameters: Parameter[];
  parameter_groups: ParameterGroup[];
} {
  const groupCount: { [id: string]: number } = {};
  const { parameters, parameter_groups } = state;
  parameters.forEach((p) => {
    if (p.group_uuid) {
      groupCount[p.group_uuid] = (groupCount[p.group_uuid] ?? 0) + 1;
    }
  });

  const newParameters = parameters.map((p) => {
    if (p.group_uuid && groupCount[p.group_uuid] === 1) {
      const newParameter = { ...p };
      newParameter.group_uuid = undefined;
      const group = parameter_groups.find((g) => g.uuid === p.group_uuid);
      if (group) {
        newParameter.order_number = group.order_number;
      }
      return newParameter;
    }
    return p;
  });

  const newParameterGroups = parameter_groups.filter((g) => {
    return !(groupCount[g.uuid] == undefined || groupCount[g.uuid] === 1);
  });

  return {
    items: state.items,
    parameters: newParameters,
    parameter_groups: newParameterGroups
  };
}
