import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  PropTypes
} from '@material-ui/core';
import clsx from 'clsx';
import { CustomTheme } from '../../../theme';

const buttonStyles = makeStyles((theme: CustomTheme) => ({
  nehan: {
    color: theme.palette?.nehan?.contrastText,
    backgroundColor: theme.palette?.nehan?.main,
    '&:hover': {
      backgroundColor: theme.palette?.nehan?.dark
    }
  },
  nehan_orange: {
    color: theme.palette?.nehan_orange?.contrastText,
    backgroundColor: theme.palette?.nehan_orange?.main,
    '&:hover': {
      backgroundColor: theme.palette?.nehan_orange?.dark
    }
  },
  common: {
    color: theme.palette?.common_color?.contrastText,
    backgroundColor: theme.palette?.common_color?.main,
    '&:hover': {
      backgroundColor: theme.palette?.common_color?.dark
    }
  },
  common_color: {
    color: theme.palette?.common_color?.contrastText,
    backgroundColor: theme.palette?.common_color?.main,
    '&:hover': {
      backgroundColor: theme.palette?.common_color?.dark
    }
  },
  cancel: {
    color: theme.palette?.cancel?.contrastText,
    backgroundColor: theme.palette?.cancel?.main,
    '&:hover': {
      backgroundColor: theme.palette?.cancel?.dark
    }
  }
}));

export type ButtonProps = Omit<MuiButtonProps, 'color'> & {
  color?:
    | PropTypes.Color
    | 'nehan'
    | 'common'
    | 'common_color'
    | 'cancel'
    | 'nehan_orange';
};

export const Button: React.FC<ButtonProps> = ({
  className,
  children,
  color,
  ...props
}) => {
  const classes = buttonStyles();
  const c =
    color && ['inherit', 'primary', 'secondary', 'default'].includes(color)
      ? (color as PropTypes.Color)
      : undefined;
  return (
    <MuiButton
      data-cy={
        typeof children === 'string' && props['data-cy'] == undefined
          ? children
          : props['data-cy']
      }
      color={c}
      variant="contained"
      {...props}
      className={clsx(className, {
        [classes.nehan]: color === 'nehan',
        [classes.common_color]: color === 'common_color' || color === 'common',
        [classes.cancel]: color === 'cancel',
        [classes.nehan_orange]: color === 'nehan_orange'
      })}
    >
      {children}
    </MuiButton>
  );
};
