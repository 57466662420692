import type {
  ArrayFieldValue,
  ColumnRenameRulesValue,
  ColumnSelectFieldValueElement,
  ColumnSelectV2Value,
  FieldValue,
  FormValue,
  mlPreprocessValue
} from 'models/form/value';
import {
  SelectTypes as ColumnSelectV2SelectTypes,
  SwitchConditions
} from 'models/form/value';
import { evalExpr } from './util';
import { compact, difference, get, intersection, pick, uniq } from 'lodash-es';
import { DataSummary } from 'models/data';
import { DataMap } from 'models/graph';
import { Dtypes, toDtypeLabel } from 'Utils/dataTypes';
import {
  ColumnSelectFieldSchema,
  ColumnSelectV2FieldSchema,
  FieldSchema,
  FieldTypes,
  FormSchema
} from 'models/form/schema';
import {
  FormValidationError,
  ValidationSchema,
  ValidationTypes
} from 'models/form/validate';

import { getSwitchError } from 'components/form/switchField';
import { getMlpreprosessError } from 'components/form/mlPreprocessField';

export interface ColumnData {
  columns: string[];
  dtypes: string[][];
}
const errorMessages = {
  required: '必須項目です。',
  integer: '整数で入力して下さい。',
  float: '実数で入力して下さい。',
  date: 'yyyy-mm-ddの形式で入力してください。'
};

const validationRegex = {
  integer: /^[-]?(0|[1-9]\d*)$/,
  float: /^[-]?(0|[1-9]\d*)+(\.\d*)?$/,
  date: /(\d{4}).?(\d{2}).?(\d{2}).*/
};

export const validate = (
  value: FieldValue,
  schema: ValidationSchema | undefined,
  columnData?: ColumnData
): string[] => {
  if (schema == undefined) {
    return [];
  }

  let errors: Array<string | undefined> = [];
  if (schema.required) {
    errors.push(required(value));
  }

  // 変数で開始していたら無視する
  if (!`${value}`.match(/^\$[a-z]+/)) {
    switch (schema.type) {
      case ValidationTypes.any:
        break;

      case ValidationTypes.integer: {
        errors.push(integer(value));
        if (schema.min != undefined) {
          errors.push(minNumber(value, schema.min));
        }
        if (schema.max != undefined) {
          errors.push(maxNumber(value, schema.max));
        }
        break;
      }

      case ValidationTypes.float: {
        errors.push(float(value));
        if (schema.min != undefined) {
          errors.push(minNumber(value, schema.min));
        }
        if (schema.max != undefined) {
          errors.push(maxNumber(value, schema.max));
        }
        break;
      }

      case ValidationTypes.date: {
        errors.push(date(value));
        break;
      }

      case ValidationTypes.parentType: {
        const vs = (
          Array.isArray(value) ? value : [value]
        ) as ColumnSelectFieldValueElement[];
        vs.forEach((v) => {
          errors.push(parentType(v, columnData));
        });
        break;
      }
    }
  }

  return [...new Set(errors)].filter(
    (v) => v != undefined && v != ''
  ) as string[];
};

export interface ValidationResult {
  valid: boolean;
  errors: FormValidationError;
}

const defaultValidateResult = [];

export const isValid = (result: FormValidationError): boolean => {
  const results: boolean[] = Object.values(result).map((res) => {
    if (Array.isArray(res)) {
      // string[] or FormValidationError[]
      // lengthが0なら、どちらにしろOK
      if (res.length === 0) {
        return true;
      }

      // 値があるときは中身を検証する
      if (typeof res[0] === 'object') {
        // FormValidationError[]になる
        return (res as FormValidationError[]).every((r) => isValid(r));
      } else {
        // string[]のパターンかつ、エラーが出ている
        return false;
      }
    } else {
      return isValid(res);
    }
  });

  return results.every((r) => r);
};

export const validateAll = (
  value: FormValue,
  formSchema: FormSchema,
  portSummaries: { [portId: string]: DataSummary },
  inputDataMap: DataMap
): ValidationResult => {
  const validateResult: ValidationResult = { valid: true, errors: {} };

  formSchema.forEach((schema) => {
    const key = schema.key;
    const evaluatedDisplayExpr = evalExpr(schema.displayExpr, value);

    if (!evaluatedDisplayExpr) {
      return defaultValidateResult;
    }

    validateResult.errors[schema.key] = validateBySchema(
      value[key],
      schema,
      portSummaries,
      inputDataMap
    );
  });

  validateResult.valid = isValid(validateResult.errors);

  return validateResult;
};

export const validateBySchema = (
  value: FieldValue,
  schema: FieldSchema,
  portSummaries: { [portId: string]: DataSummary },
  inputDataMap: DataMap
) => {
  switch (schema.type) {
    case FieldTypes.object: {
      const validateResult = {};
      if (schema.optional && value == undefined) {
        return validateResult;
      }
      schema.properties.forEach((s) => {
        if (evalExpr(s.displayExpr, value as FormValue)) {
          validateResult[s.key] = validateBySchema(
            get(value, s.key),
            s,
            portSummaries,
            inputDataMap
          );
        }
      });
      return validateResult;
    }

    case FieldTypes.array:
      if (value == undefined) {
        return [];
      }
      return (value as ArrayFieldValue).map((val) => {
        const validateResult = {};
        schema.item.forEach((s) => {
          if (evalExpr(s.displayExpr, val)) {
            validateResult[s.key] = validateBySchema(
              get(val, s.key),
              s,
              portSummaries,
              inputDataMap
            );
          }
        });
        return validateResult;
      });

    case FieldTypes.column_select: {
      let errors: string[] = [];
      if (value == undefined) {
        return errors;
      }
      const inputPortColumns = getInputPortColumns(
        schema,
        portSummaries,
        inputDataMap
      );
      if (Object.keys(inputPortColumns).length === 0) {
        return [];
      }

      const vs = (
        Array.isArray(value) ? value : [value]
      ) as ColumnSelectFieldValueElement[];
      const selectedColumns = vs.map((v) => {
        return v.value;
      });

      // inputPortColumnsに含まれておらず、選択した列にだけ含まれているものを取得
      const notExistColumns = difference(
        selectedColumns,
        Object.keys(inputPortColumns)
      );
      if (notExistColumns.length > 0) {
        errors.push('存在しない列が選択されています。');
      }

      // dtypesが指定したもののみか調べる
      if (schema.dtypes != undefined && schema.dtypes.length > 0) {
        const selectedDtypes = compact(
          uniq(vs.map((v) => inputPortColumns[v.value]))
        );
        const legalDtypes = intersection(selectedDtypes, schema.dtypes);
        if (legalDtypes.length !== selectedDtypes.length) {
          const errorDtypes = difference(selectedDtypes, legalDtypes);
          const errorMsg = errorDtypes.map((e) => toDtypeLabel(e)).join(', ');
          errors.push(`${errorMsg}型の列は指定できません。`);
        }
      }

      if (schema.validate?.type == ValidationTypes.parentType) {
        if (schema.inConfNames || !schema.inConfName) {
          // 一つのフォームに複数の入力ポートがある場合は、両方のポートの型を調べる
          // inConfNamesもinConfNameもない場合は入力ポートが一つ
          Object.keys(inputDataMap).forEach((k) => {
            const portId = inputDataMap[k]['portId'];
            const inputPort = portSummaries[portId];
            errors = errors.concat(
              validate(value, schema.validate, {
                columns: inputPort.columns,
                dtypes: [inputPort.dtypes]
              })
            );
          });
        } else if (schema.inConfName) {
          // inConfNameがある場合は、入力ポートが複数あるが、各フォームには一つのポートが紐付く
          // この場合、フォームに紐付くポートの型だけを見る
          const portId = inputDataMap[schema.inConfName]['portId'];
          const inputPort = portSummaries[portId];
          errors = errors.concat(
            validate(value, schema.validate, {
              columns: inputPort.columns,
              dtypes: [inputPort.dtypes]
            })
          );
        }
      }
      return [...new Set(errors)];
    }

    case FieldTypes.column_select_v2: {
      if (value == undefined || Array.isArray(value)) {
        return [];
      }
      const inputPortColumns = getInputPortColumns(
        schema,
        portSummaries,
        inputDataMap
      );
      if (Object.keys(inputPortColumns).length === 0) {
        return [];
      }
      const selectedColumns = (value as ColumnSelectV2Value).rules
        .map((r) => {
          if (r.type === ColumnSelectV2SelectTypes.column_names) {
            return r.value;
          } else {
            return [];
          }
        })
        .flat();
      // inputPortColumnsに含まれておらず、選択した列にだけ含まれているものを取得
      const notExistColumns = difference(
        selectedColumns,
        Object.keys(inputPortColumns)
      );
      return notExistColumns.length > 0
        ? ['存在しない列が選択されています。']
        : [];
    }
    case FieldTypes.rename: {
      value = value as ColumnRenameRulesValue;
      if (schema == undefined) {
        return [];
      }
      if (value == undefined || value.renames.length === 0) {
        return ['error'];
      }
      return [];
    }
    case FieldTypes.switch: {
      value = value as SwitchConditions;
      const inConfName = schema.inConfName ? schema.inConfName : 'Input';
      const portId = inputDataMap[inConfName]?.['portId'];
      if (portId == undefined) {
        return ['error'];
      }
      const inputPort = portSummaries[portId];
      const columns = inputPort?.columns;
      const dtypes = inputPort?.dtypes;
      const error = getSwitchError(value, columns, dtypes);
      if (error.length > 0) {
        return ['error'];
      }
      return [];
    }
    case FieldTypes.ml_preprocess: {
      value = value as mlPreprocessValue;
      const error = getMlpreprosessError(value);
      if (error != undefined) {
        return ['error'];
      }
      return [];
    }
    default:
      return validate(value, schema.validate);
  }
};

const required = (value) =>
  value == undefined || value === '' || value.length === 0
    ? errorMessages.required
    : undefined;

const integer = (value) =>
  value && !validationRegex.integer.test(value)
    ? errorMessages.integer
    : undefined;
const float = (value) =>
  value && !validationRegex.float.test(value) ? errorMessages.float : undefined;
const minNumber = (value, min) =>
  value && +value < min ? `${min}以上で入力してください。` : undefined;
const maxNumber = (value, max) =>
  value && +value > max ? `${max}以下で入力してください。` : undefined;
const date = (value) =>
  value && !validationRegex.date.test(value) ? errorMessages.date : undefined;
const parentType = (
  value: ColumnSelectFieldValueElement,
  columnData?: ColumnData
) => {
  if (!value || !columnData) {
    return undefined;
  }
  const { columns, dtypes } = columnData;
  if (columns == undefined || dtypes == undefined) {
    return undefined;
  }
  const idx = columns.indexOf(value.label);
  if (idx >= 0) {
    let error: string = '';
    dtypes.some((dts) => {
      const parentDtype = dts[idx];
      if (parentDtype != value.dtype) {
        error = `セットされた列の型が変わりました。列を選択し直して下さい`;
        return true;
      }
      return false;
    });
    return error;
  }
  return undefined;
};
const getInputPortColumns = (
  schema: ColumnSelectFieldSchema | ColumnSelectV2FieldSchema,
  portSummaries: { [portId: string]: DataSummary },
  inputDataMap: DataMap
): { [column: string]: Dtypes } => {
  if (schema.inConfNames != undefined) {
    // 共通のカラムを取り出す
    const columns: string[] = intersection(
      ...schema.inConfNames.map((n) => {
        const pm = inputDataMap[n];
        const d = pm ? portSummaries[pm.portId] : undefined;
        return d ? d.columns : [];
      })
    );
    const inConfName = schema.inConfNames[0];
    const portMap = inputDataMap[inConfName];
    const data = portMap ? portSummaries[portMap.portId] : undefined;
    if (data) {
      const columnMap = Object.fromEntries(
        data.columns.map((c, i) => [c, data.dtypes[i]])
      );
      return pick(columnMap, columns);
    }
    return {};
  } else {
    const inConfName = schema.inConfName ? schema.inConfName : 'Input';
    const portMap = inputDataMap[inConfName];
    const data = portMap ? portSummaries[portMap.portId] : undefined;
    if (data) {
      return Object.fromEntries(
        data.columns.map((c, i) => [c, data.dtypes[i]])
      );
    }
  }
  return {};
};
