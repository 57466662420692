import * as React from 'react';

export function IconNumber() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 17"
      style={{ minWidth: 16 }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#59B37B" />
        <text
          fill="#FFF"
          fontFamily="Noto Sans JP"
          fontSize="8"
          fontWeight="bold"
        >
          <tspan x="1.5" y="11.7">
            123
          </tspan>
        </text>
      </g>
    </svg>
  );
}
