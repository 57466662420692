import { ListItemType } from 'models/dependency';
import { DateRangeValues } from 'models/report/parameter';

export const FilterType = {
  AccessLevels: 'access_levels',
  BuilderName: 'builder_name',
  CalendarPositionForCreation: 'calendar_position_for_creation',
  CalendarPositionForExecutionEnd: 'calendar_position_for_execution_end',
  CalendarPositionForExecutionStart: 'calendar_position_for_execution_start',
  CalendarPositionForUpdate: 'calendar_position_for_update',
  Clear: 'clear',
  ConnectionName: 'connection_name',
  CreateCondition: 'create_condition',
  CreateRange: 'create_range',
  Creators: 'creators',
  DashboardName: 'dashboard_name',
  DatasourceName: 'datasource_name',
  DatasourceStatuses: 'datasource_statuses',
  DatasourceTypes: 'datasource_types',
  DataTypes: 'data_types',
  ExcludeFolders: 'exclude_folders',
  ExecutionEndCondition: 'execution_end_cond',
  ExecutionEndRange: 'execution_end_range',
  ExecutionStartCondition: 'execution_start_cond',
  ExecutionStartRange: 'execution_start_cond_range',
  ExportStatuses: 'export_statuses',
  LimitedAccess: 'limited_access',
  ProjectName: 'project_name',
  PublicURLExists: 'public_url_exists',
  ReportName: 'report_name',
  UpdateCondition: 'update_condition',
  UpdateRange: 'update_range',
  VariableName: 'variable_name',
  ScheduleName: 'schedule_name',
  IsScheduled: 'is_scheduled',
  NotificationName: 'notification_name',
  NotificationDstName: 'notification_dst_name',
  NotificationDstTypes: 'notification_dst_type',
  UserGroupName: 'user_group_name',
  HasIPAddress: 'has_ip_address',
  UserEmail: 'email',
  UserTypes: 'user_types',
  UserPasswordGenerated: 'user_password_generated',
  UserGroups: 'user_groups',
  OrganizationName: 'organization_name'
} as const;

export type FilterActionType = (typeof FilterType)[keyof typeof FilterType];

export type FilterAction = {
  type: FilterActionType;
  payload:
    | DateRangeValues
    | HTMLTableElement
    | ListItemType
    | boolean
    | null
    | string
    | string[];
};

export type FilterState =
  | BaseFilterState
  | BuilderFilterState
  | ConnectionFilterState
  | DatasourceFilterState
  | ExportFilterState
  | ProjectFilterState
  | ReportFilterState
  | VariableFilterState
  | ScheduleFilterState
  | NotificationFilterState
  | UserFilterState
  | UserGroupFilterState
  | OrganizationFilterState;

export type BaseFilterState = {
  filtering: boolean;
};

export type UserFilterState = BaseFilterState & {
  [FilterType.UserEmail]: string;
  [FilterType.UserTypes]: string[];
  [FilterType.UserPasswordGenerated]: boolean;
  [FilterType.UserGroups]: string[];
};

export type UserGroupFilterState = BaseFilterState & {
  [FilterType.UserGroupName]: string;
  [FilterType.HasIPAddress]: boolean;
};

export type ScheduleFilterState = BaseFilterState & {
  [FilterType.ScheduleName]: string;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.Creators]: string[];
  [FilterType.AccessLevels]: string[];
  [FilterType.IsScheduled]: boolean;
};

export type BuilderFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.ExcludeFolders]: boolean;
  [FilterType.BuilderName]: string;
  [FilterType.DatasourceTypes]: string[];
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type ConnectionFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.ConnectionName]: string;
  [FilterType.DatasourceTypes]: string[];
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type NotificationFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.NotificationName]: string;
  [FilterType.NotificationDstTypes]: string[];
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type NotificationDstFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.NotificationDstName]: string;
  [FilterType.NotificationDstTypes]: string[];
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type DatasourceFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.DatasourceName]: string;
  [FilterType.DatasourceStatuses]: string[];
  [FilterType.DatasourceTypes]: string[];
  [FilterType.ExcludeFolders]: boolean;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type ExportFilterState = BaseFilterState & {
  [FilterType.CalendarPositionForExecutionEnd]: HTMLTableElement | null;
  [FilterType.CalendarPositionForExecutionStart]: HTMLTableElement | null;
  [FilterType.DatasourceTypes]: string[];
  [FilterType.ExecutionEndCondition]: string;
  [FilterType.ExecutionEndRange]: DateRangeValues;
  [FilterType.ExecutionStartCondition]: string;
  [FilterType.ExecutionStartRange]: DateRangeValues;
  [FilterType.ExportStatuses]: string[];
  [FilterType.ProjectName]: string;
};

export type ProjectFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.ExcludeFolders]: boolean;
  [FilterType.ProjectName]: string;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type ReportFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.ExcludeFolders]: boolean;
  [FilterType.LimitedAccess]: boolean;
  [FilterType.ReportName]: string;
  [FilterType.PublicURLExists]: boolean;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type VariableFilterState = BaseFilterState & {
  [FilterType.AccessLevels]: string[];
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.Creators]: string[];
  [FilterType.DataTypes]: string[];
  [FilterType.ExcludeFolders]: boolean;
  [FilterType.VariableName]: string;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};

export type OrganizationFilterState = BaseFilterState & {
  [FilterType.CalendarPositionForCreation]: HTMLTableElement | null;
  [FilterType.CalendarPositionForUpdate]: HTMLTableElement | null;
  [FilterType.CreateCondition]: string;
  [FilterType.CreateRange]: DateRangeValues;
  [FilterType.OrganizationName]: string;
  [FilterType.UpdateCondition]: string;
  [FilterType.UpdateRange]: DateRangeValues;
};
