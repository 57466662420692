/* tslint:disable */
import {
  createTheme,
  darken,
  Theme,
  ThemeOptions
} from '@material-ui/core/styles';
import {
  Palette,
  PaletteOptions,
  SimplePaletteColorOptions
} from '@material-ui/core/styles/createPalette';
import { PropTypes } from '@material-ui/core';

interface NehanPaletteColorOptions extends SimplePaletteColorOptions {
  headerBorder?: string;
  headerBg?: string;
  subHeaderBorder?: string;
  subHeaderBg?: string;
}

interface CustomPalette extends Palette {
  nehan: NehanPaletteColorOptions;
  nehan_orange: SimplePaletteColorOptions;
  common_color: SimplePaletteColorOptions;
  cancel: SimplePaletteColorOptions;
}

export interface CustomTheme extends Theme {
  palette: CustomPalette;
}

interface CustomPaletteOptions extends PaletteOptions {
  nehan?: NehanPaletteColorOptions;
  common_color?: SimplePaletteColorOptions;
  cancel?: SimplePaletteColorOptions;
}

export interface CustomThemeOptions extends ThemeOptions {
  palette?: CustomPaletteOptions;
}

export const nehanOrangeColor = '#D57B3D';
export const nehanOrangeColorDark = darken(nehanOrangeColor, 0.2);
export const nehanColor = '#0088a7';
export const nehanColorDark = '#0089a7';
export const color = '#344955';
export const NehanProjectColor = '#D47C3E';
export const NehanDatasourceColor = '#b2b351';
export const NehanReportColor = '#C66C6C';
export const NehanScheduleColor = '#0089a7';

export type NehanColors = PropTypes.Color | 'nehan' | 'common_color';

export const fontFamily =
  'Roboto,"Noto Sans JP","Helvetica Neue",Arial,sans-serif';
const baseTheme = {
  typography: {
    fontFamily,
    h1: { fontFamily },
    h2: { fontFamily },
    h3: { fontFamily },
    h4: { fontFamily },
    h5: { fontFamily },
    h6: { fontFamily },
    subtitle1: { fontFamily },
    subtitle2: { fontFamily },
    body2: { fontFamily },
    body1: { fontFamily },
    caption: { fontFamily },
    button: { fontFamily }
  },
  palette: {
    common_color: {
      main: nehanColor,
      dark: nehanColorDark,
      contrastText: '#ffffff'
    },
    cancel: {
      main: '#fff',
      dark: '#fff',
      contrastText: '#000'
    },
    nehan_orange: {
      main: nehanOrangeColor,
      dark: nehanOrangeColorDark,
      contrastText: '#ffffff'
    }
  }
};

const theme = createTheme(baseTheme as ThemeOptions);
export default theme;

export const project: CustomThemeOptions = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    nehan: {
      main: '#e2762a',
      dark: '#9E521D',
      contrastText: '#ffffff'
    }
  }
};
export const projectTheme = createTheme(project);

const dashboard: CustomThemeOptions = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    nehan: {
      headerBorder: '#C66C6C',
      headerBg: '#eeeeee',
      subHeaderBorder: '#C66C6C',
      subHeaderBg: '#fcf7f7',
      main: '#0088a7',
      dark: '#005f74',
      contrastText: '#ffffff'
    }
  },
  typography: {
    ...baseTheme.typography,
    h1: { ...baseTheme.typography.h1, color },
    h2: { ...baseTheme.typography.h2, color },
    h3: { ...baseTheme.typography.h3, color },
    h4: { ...baseTheme.typography.h4, color },
    h5: { ...baseTheme.typography.h5, color },
    h6: { ...baseTheme.typography.h6, color },
    subtitle1: { ...baseTheme.typography.subtitle1, color },
    subtitle2: { ...baseTheme.typography.subtitle2, color },
    body2: { ...baseTheme.typography.body2, color },
    body1: { ...baseTheme.typography.body1, color },
    caption: { ...baseTheme.typography.caption, color },
    button: { ...baseTheme.typography.button, color }
  }
};
export const dashboardTheme = createTheme(dashboard);

const visualize: CustomThemeOptions = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    nehan: {
      main: '#51a8dd',
      dark: '#38759a',
      contrastText: '#ffffff'
    }
  },
  typography: {
    ...baseTheme.typography,
    h1: { ...baseTheme.typography.h1, color },
    h2: { ...baseTheme.typography.h2, color },
    h3: { ...baseTheme.typography.h3, color },
    h4: { ...baseTheme.typography.h4, color },
    h5: { ...baseTheme.typography.h5, color },
    h6: { ...baseTheme.typography.h6, color },
    subtitle1: { ...baseTheme.typography.subtitle1, color },
    subtitle2: { ...baseTheme.typography.subtitle2, color },
    body2: { ...baseTheme.typography.body2, color },
    body1: { ...baseTheme.typography.body1, color },
    caption: { ...baseTheme.typography.caption, color },
    button: { ...baseTheme.typography.button, color }
  }
};
export const visualizeTheme = createTheme(visualize);

const datasource: CustomThemeOptions = {
  ...baseTheme,
  palette: {
    ...baseTheme.palette,
    nehan: {
      main: '#0088a7',
      dark: '#005f74',
      contrastText: '#ffffff'
    }
  }
};
export const datasourceTheme = createTheme(datasource);
