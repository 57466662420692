import * as React from 'react';
import { IconButton, Typography } from '@material-ui/core';
import { Close, Info, NavigateBefore, TextFormat } from '@material-ui/icons';
import { createStyles } from '@material-ui/core/styles';
import { LinkForceRemount } from 'ui/linkForceRemount';
import IcRelationships from '../../icons/IcRelationships';
import LabeledButton from '../../ui/labeledButton';
import { IconSchedule } from '../../icons/IcSchedule';
import SvgIcDashboard from '../../icons/IcDashboard';
import { makeStyles } from '@material-ui/styles';
import { Spacer } from '../../ui/common/spacer';
import SvgIcSqlBuilder from '../../icons/IcSqlBuilder';
import { SvgIcDatasource } from '../../icons/IcDatasource';
import { getDatasourceExpression } from 'libs/datasources';
import { IconExport } from 'components/icons/IcExport';
import { IconConnection } from 'components/icons/IcConnection';

export const Logo: React.FC<{
  link: string;
  imageOrPath: React.ReactNode;
}> = ({ link, imageOrPath }) => {
  return (
    <LinkForceRemount
      to={link}
      title="TOPページに戻る"
      style={{ display: 'inline-flex' }}
      data-cy="top-link"
    >
      {typeof imageOrPath === 'string' ? (
        <img alt="nehan" src={imageOrPath} height="50" />
      ) : (
        imageOrPath
      )}
    </LinkForceRemount>
  );
};

export const RelationshipIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="系譜">
      <IcRelationships height={24} width={24} />
    </LabeledButton>
  );
};

export const DatasourceIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="データソース">
      <SvgIcDatasource />
    </LabeledButton>
  );
};

export const ScheduleIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="スケジューリング">
      <IconSchedule />
    </LabeledButton>
  );
};

export const ReportIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="ダッシュボード">
      <SvgIcDashboard />
    </LabeledButton>
  );
};

export const VariableIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="変数">
      <TextFormat />
    </LabeledButton>
  );
};

export const ExportIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="データエクスポート">
      <IconExport />
    </LabeledButton>
  );
};

const noticeStyles = makeStyles(() =>
  createStyles({
    infoRoot: {
      width: 'auto',
      height: '60px',
      marginRight: '50px',
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'none',
      color: '#e2762a',
      backgroundColor: 'transparent',
      '&:hover': {
        textDecoration: 'none'
      }
    },
    infoLabel: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  })
);

export const NoticeIcon: React.FC<{}> = () => {
  const classes = noticeStyles();
  return (
    <IconButton
      href="https://moonfish.nehan.io/release_note"
      target="_blank"
      classes={{ root: classes.infoRoot, label: classes.infoLabel }}
    >
      <Info />
      <p
        style={{
          color: '#959595',
          fontSize: '9px',
          margin: '0'
        }}
      >
        お知らせ
      </p>
    </IconButton>
  );
};

export const ConnectionSettings: React.FC<{
  onOpenDialog?: () => void;
  onCloseConfigDialog?: () => void;
}> = ({ onOpenDialog, onCloseConfigDialog }) => {
  return (
    <>
      <IconButton
        onClick={() => {
          onCloseConfigDialog?.();
          onOpenDialog?.();
        }}
      >
        <NavigateBefore />
      </IconButton>
      <Typography>接続設定</Typography>
      <Spacer />
      <IconButton onClick={onCloseConfigDialog}>
        <Close />
      </IconButton>
    </>
  );
};

export const BuilderIcon: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="SQLビルダー">
      <SvgIcSqlBuilder />
    </LabeledButton>
  );
};

export const ConnectionSettingIcon: React.FC<{
  onClick: () => void;
  type?: string;
}> = ({ onClick, type }) => {
  return (
    <LabeledButton color="inherit" onClick={onClick} label="接続設定">
      <ConnectionConfigButton type={type} />
    </LabeledButton>
  );
};

const ConnectionConfigButton: React.FC<{ type?: string }> = ({ type }) => {
  if (!type) {
    return <IconConnection />;
  }

  const expr = getDatasourceExpression(type);
  if (expr.logoPath) {
    return (
      <img
        alt={type}
        src={expr.logoPath}
        width="25"
        style={{ verticalAlign: 'bottom', marginRight: 5 }}
      />
    );
  } else {
    return <IconConnection />;
  }
};
