export enum ValidationTypes {
  any = 'any',
  integer = 'integer',
  float = 'float',
  date = 'date',
  parentType = 'parent_type'
}

interface ValidationSchemaBase {
  type: ValidationTypes;
  required?: boolean;
}

interface AnyValidationSchema extends ValidationSchemaBase {
  type: ValidationTypes.any;
}

interface DateValidationSchema extends ValidationSchemaBase {
  type: ValidationTypes.date;
}

interface IntegerValidationSchema extends ValidationSchemaBase {
  type: ValidationTypes.integer;
  min?: number;
  max?: number;
}

interface FloatValidationSchema extends ValidationSchemaBase {
  type: ValidationTypes.float;
  min?: number;
  max?: number;
}

interface ParentTypeValidationSchema extends ValidationSchemaBase {
  type: ValidationTypes.parentType;
}

export type ValidationSchema =
  | AnyValidationSchema
  | IntegerValidationSchema
  | FloatValidationSchema
  | DateValidationSchema
  | ParentTypeValidationSchema;

export type FieldValidationError = string[];

export interface FormValidationError {
  [k: string]:
    | FieldValidationError
    | ArrayFieldValidationError
    | FormValidationError;
}

export type ArrayFieldValidationError = FormValidationError[];
