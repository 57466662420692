import {
  _delete,
  _get,
  _getWithAccessTokenV2,
  _getWithFixedToken,
  _getWithSharedToken,
  _post,
  _postWithSharedToken,
  _put
} from 'libs/api/base';
import {
  Parameter,
  ParameterGroup,
  ParameterTarget,
  Report,
  ReportItem,
  ReportToken
} from '../../models/report';
import { QueryParams } from 'ui/listViewBase';
import { Chart, QueryData } from 'models/chart';
import { AxiosResponse } from 'axios';
import { ReportListResponse, TreeResponse } from 'models/dependency';

export function createReport(body: { name: string; folder_id?: string }) {
  return _post<{ id: string }>(`/reports`, body);
}

export function getReport(id: string, token?: string) {
  if (token) {
    return _getWithSharedToken(token, `/embed/reports/${id}`);
  }
  return _get(`/reports/${id}`);
}

export function loginReport(
  id: string,
  token: string | null,
  password?: string | null
) {
  return _post(
    `/reports/login`,
    { id, token, password },
    { withCredentials: true }
  );
}

export function getReportParameters(id: string) {
  return _get(`/reports/${id}/parameters`);
}

export function updateReport(id: string, body: any) {
  return _put(`/reports/${id}`, body);
}

export function getReports(folderId?: string, queryParams?: QueryParams) {
  return _get<ReportListResponse>(`/reports`, {
    params: { folderId, ...queryParams }
  });
}

export function getReportsByChartId(chart_id: string) {
  return _get<Report[]>('/reports', { params: { chart_id } });
}

export function getReportsByProjectId(project_id: string) {
  return _get<Report[]>('/reports', { params: { project_id } });
}

export function deleteReport(id: string) {
  return _delete(`/reports/${id}`);
}

export async function getReportMembers(reportId: string) {
  return _get(`/reports/${reportId}/members`);
}

export async function deleteReportMember(
  reportId: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/reports/${reportId}/members`, { id, is_group });
}

export function getReportCharts(reportId: string, chartIds: string[]) {
  return _post<Chart[]>(`/reports/${reportId}/charts`, { ids: chartIds });
}

export function updateReportItems(
  reportId: string,
  items: ReportItem[],
  parameters: Parameter[],
  parameter_groups: ParameterGroup[],
  name?: string
) {
  return _put(`/reports/${reportId}/items`, {
    items,
    parameters,
    parameter_groups,
    name
  });
}

export function getUniqueParameterValue(
  reportId: string,
  target: { [itemId: string]: ParameterTarget },
  groups: Parameter[],
  token?: string
): Promise<AxiosResponse<QueryData, any>> {
  if (token) {
    return _postWithSharedToken(token, '/embed/unique_values', {
      target,
      groups
    });
  }
  return _post(`/reports/${reportId}/unique_values`, {
    target,
    groups
  });
}

export function getReportChartColumns(reportUUID: string, chartUUID: string) {
  return _get(`/reports/${reportUUID}/columns`, {
    params: { chart_uuid: chartUUID }
  });
}

export function getReportToken(reportId: string) {
  return _get<ReportToken>(`/reports/${reportId}/token`);
}

export function resetReportToken(reportId: string) {
  return _post(`/reports/${reportId}/reset_token`, null);
}

export function updateReportEnabledPublishToken(
  reportId: string,
  enabled_public_token: boolean
) {
  return _post(`/reports/${reportId}/enabled_public_token`, {
    enabled_public_token
  });
}

export function updateReportPublicRestrict(
  reportId: string,
  public_restrict: {
    ip_filter: string;
    user_groups: string[];
    password: string;
    use_password: boolean;
  }
) {
  return _post(`/reports/${reportId}/public_restrict`, public_restrict);
}

export function deleteReportToken(reportId: string) {
  return _delete(`/reports/${reportId}/token`);
}

export function duplicateReport(reportId: string, folderId?: string) {
  return _post(
    `/reports/${reportId}/duplicate`,
    {},
    {
      params: { folderId }
    }
  );
}

export async function getAdminReports(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get('/admin/reports', {
    params: { folderId, ...queryParams }
  });
}

export async function getReportRelationships(id: string) {
  return _get(`/reports/${id}/relationships`);
}

export function getReportPreview(token: string, id: string) {
  return _getWithFixedToken(token, `/report_preview/${id}`);
}

export async function updateReportPreviewImage(id: string) {
  return _post(`/reports/${id}/update_preview`, {});
}

export function getReportPreviewImage(id: string) {
  return _getWithAccessTokenV2(`/reports/${id}/preview`, {
    responseType: 'blob'
  });
}

export function getReportPortalParentFolders(reportId: string) {
  return _get<TreeResponse>(`/report_portal/parent_folders`, {
    params: { reportId }
  });
}

export function getReportPortalItems(folder_id: string, token?: string) {
  if (token) {
    return _postWithSharedToken(token, `/embed/report_portal/items`, {
      folder_id
    });
  }
  return _post<TreeResponse>(`/report_portal/items`, {
    folder_id
  });
}

export function getReportPortalFolder(reportId: string) {
  return _get<any>(`/reports/${reportId}/portal_folder`);
}

export function putReportPortalFolderId(folder_id: string) {
  return _put<any>(`/report_portal_folder`, {
    folder_id
  });
}

export function updateReportPortalFolderEnabled(
  folder_id: string,
  enabled: boolean
) {
  return _put<any>(`/report_portal_folder/${folder_id}/enabled`, { enabled });
}
