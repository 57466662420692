import * as React from 'react';
import { Button, Dialog, DialogActions } from '@material-ui/core';
import IcHelpOn from 'components/icons/IcHelpOn';
import { getManualUrl, getManualUrlByKey } from 'components/help';

interface HelpDialogProps {
  open: boolean;
  onClose: () => void;
}

const HelpDialog: React.FC<HelpDialogProps> = ({ onClose, open, children }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      {children}
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default HelpDialog;

export interface HelpButtonProps {
  url?: string;
  manualUrlPath?: string;
  manualUrlKey?: string;
}

export const HelpButton: React.FC<HelpButtonProps> = ({
  url,
  manualUrlPath,
  manualUrlKey
}) => {
  let href = '';
  if (url) {
    href = url;
  } else if (manualUrlPath) {
    href = getManualUrl(manualUrlPath);
  } else if (manualUrlKey) {
    href = getManualUrlByKey(manualUrlKey);
  } else {
    href = getManualUrl();
  }
  return (
    <Button
      style={{ margin: '2px', minWidth: '0px' }}
      href={href}
      target="_blank"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          alignContent: 'flex-end'
        }}
      >
        <IcHelpOn style={{ height: '2em', width: '2em' }} />
        <span style={{ fontSize: '0.825em', width: '3em' }}>ヘルプ</span>
      </div>
    </Button>
  );
};
