import * as React from 'react';
import { Variable } from 'models/project';
import { SwitchSelectColumn, FormValue } from 'models/form/value';
import { FieldValidationError } from 'models/form/validate';
import { DataMap } from 'models/graph';
import { DataSummary } from 'models/data';
import { FieldTypes, CategorySelectFieldSchema } from 'models/form/schema';
import CategorySelectField from 'components/form/categorySelectField';
import { get } from 'lodash-es';

export const CategorySelect: React.FC<{
  variables: Variable[];
  value: string | null;
  selectColumn: SwitchSelectColumn | null;
  onChange: (val: string) => void;
  errors: FieldValidationError;
  placeholder: string;
  index: number;
  multiple: boolean;
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}> = ({
  variables,
  value,
  selectColumn,
  onChange,
  errors,
  placeholder,
  index,
  multiple,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const handleChange = React.useCallback(
    (_, val: string) => onChange(val),
    [onChange]
  );

  const schema: CategorySelectFieldSchema = {
    key: String(index),
    type: FieldTypes.category_select,
    multi: multiple,
    columnNameKey: selectColumn === null ? '' : selectColumn.label,
    inConfName: 'Input',
    placeholder: placeholder,
    fullWidth: true,
    variant: 'outlined'
  };
  const inConfName = schema.inConfName ? schema.inConfName : 'Input';

  let uniqueVals;
  if (schema.columnNameKey && contextData != undefined) {
    const colName = schema.columnNameKey;
    //const colName = evalExpr(schema.columnNameKey, contextData);
    const portMap = inputDataMap[inConfName];
    const data =
      portMap != undefined ? portSummaries[portMap.portId] : undefined;
    uniqueVals = get(data, ['summary', colName, 'unique_vals'], undefined);
  }
  const newVal = value === null ? '' : value;

  return (
    <CategorySelectField
      variables={variables}
      value={newVal}
      suggetions={uniqueVals}
      errors={errors}
      schema={schema}
      onChangeField={handleChange}
      detailedHelpTooltip={null}
    />
  );
};
