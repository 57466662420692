import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { makeStyles } from '@material-ui/core/styles';
import { color } from '../../../theme';

export const useStyles = makeStyles({
  paper: {
    color
  },
  rangePaper: {
    display: 'flex',
    flexDirection: 'column',
    width: 700,
    paddingBottom: 20
    // height: 500
  },
  title: {
    fontWeight: 700
  },
  color: {
    color
  },
  relativeCondition: {
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center'
  },
  columnText: {
    margin: '0 5px'
  },
  multiple: {
    width: '80%'
  },
  rangeTitle: {
    display: 'flex',
    margin: 10,
    justifyContent: 'space-between'
  },
  menu: {
    maxHeight: 400,
    height: 400,
    position: 'static!important' as any
  },
  menuList: {
    maxHeight: '400px!important',
    height: 400
  },
  relativeSwitch: {
    marginLeft: 20
  },
  valueContainer: {
    maxHeight: 200,
    overflowY: 'scroll!important' as any
  },
  singleDate: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  rdParameter: {
    display: 'flex',
    padding: 10,
    marginBottom: 10,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: 'rgb(210, 210, 210)',
    backgroundColor: 'rgb(250,250,250)',
    borderStyle: 'solid'
  },
  rdRoot: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rdInput: {
    flexDirection: 'column',
    alignItems: 'center'
  },
  initial: {
    '& .rdrDayNumber span': {
      color: '#1d2429!important'
    }
  },
  pickerArea: {
    display: 'flex',
    // alignItems: 'center',
    justifyContent: 'space-between'
  },
  pickerAreaDateSingle: {
    justifyContent: 'center'
  },
  pickerAreaDatetimeSingle: {
    justifyContent: 'space-evenly'
  },
  datePickerWrapper: {},
  datePickerBorder: {
    border: '1px solid rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    padding: '8px',
    width: '140px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  datePickerBorderError: {
    borderColor: '#d32f2f!important'
  },
  datePickerIcon: {
    fontSize: '15px'
  },
  datePicker: {
    margin: 0
  },
  datePickerInput: {
    padding: '0px',
    width: '85px',
    fontSize: '15px'
  },
  datePickerErrorMSg: {
    color: '#d32f2f'
  },
  timePickerIcon: {
    fontSize: '15px'
  },
  timePickerIconTimeRange: {
    marginLeft: '10px'
  },
  timePickerInput: {
    padding: '0px',
    width: '65px',
    fontSize: '15px'
  },
  dateRangeDivider: {
    fontSize: '20px',
    paddingTop: '5px'
  },
  dateTimeRangeDivider: {
    fontSize: '20px',
    paddingTop: '25px'
  },
  timeSingleBorder: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center'
  },
  timeSinglePicker: {
    width: '70px',
    margin: '0px 40px'
  },
  timeRangeBorder: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.20)',
    borderRadius: '5px',
    alignItems: 'center'
  },
  timeRangePicker: {
    display: 'block',
    width: '70px',
    margin: '0px 40px'
  },
  timeRangeText: {
    fontWeight: 500
  },
  condition: {
    display: 'flex',
    alignItems: 'center',
    width: '100%'
  },
  relativeList: {
    paddingTop: 0
  },
  relativeListItem: {
    padding: '4px 16px'
  },
  relativeListItemText: {
    margin: '0'
  },
  relativeListItemPrefix: {
    marginRight: '4px'
  },
  relativeListItemInputPast: {
    marginRight: '4px',
    marginTop: '4px'
  },
  relativeListItemInputPastInput: {
    padding: '4px 8px',
    textAlign: 'center'
  },
  relativeListItemInputPastUnitForm: {
    marginTop: '4px'
  },
  relativeListItemInputPastUnitSelect: {
    padding: '4px 8px',
    textAlign: 'center'
  },
  relativeListItemSuffix: {
    marginLeft: '4px'
  },
  abusoluteCalendarArea: {
    display: 'flex',
    marginTop: '16px'
  },
  abusoluteCalendarCalendar: {
    marginLeft: '-10px'
  },
  actionArea: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '8px',
    backgroundColor: 'rgb(0,0,0,0.05)'
  },
  actionAreaButton: {
    margin: '0 4px'
  },
  dialogPaper: {
    maxWidth: 'unset'
  },
  dialogActons: {
    padding: 0
  }
});
