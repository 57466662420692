import ActionTypes from 'actions/actionTypes';
import { getVariables } from 'libs/api';
import { VariableItem } from 'models/dependency';
import { Variable } from 'models/project';
import { ResourceType } from 'models/variable';

export const itemsToVariables = (items: VariableItem[]): Variable[] => {
  return items.map((v) => {
    return itemToVariable(v);
  });
};

export const itemToVariable = (v: VariableItem): Variable => {
  return {
    name: v.name,
    value: v.value,
    dtype: v.dtype,
    dateType: v.date_type,
    dateUnit: v.date_unit,
    dateOperator: v.date_operator,
    dateFormat: v.date_format,
    timestampFormat: v.timestamp_format,
    timeFormat: v.time_format,
    dateCustomFormat: v.date_custom_format,
    timestampCustomFormat: v.timestamp_custom_format,
    timeCustomFormat: v.time_custom_format,
    desc: v.desc,
    uuid: v.uuid
  };
};

// インポートした共通変数を読み込む
export function loadImportedVariables(
  resourceType: ResourceType,
  resourceId: string
) {
  return async (dispatch, _) => {
    const { data } = await getVariables(resourceType, resourceId);
    dispatch({
      type: ActionTypes.LoadVariables,
      payload: { data: itemsToVariables(data.items) }
    });
  };
}
