import * as React from 'react';
import { CheckboxForm, TextForm } from 'ui/listView/filter/form';
import {
  handleChangeUserGroupHasIP,
  handleChangeUserGroupName
} from 'ui/listView/filter/handler';
import { FilterType } from 'ui/listView/filter/type';
import { FilterContext } from 'ui/listView/filter/context';

export const UserGroupFilter: React.FC = () => {
  const { state: filterState, dispatch: filterDispatch } =
    React.useContext(FilterContext);

  return (
    <>
      <table>
        <tbody>
          <TextForm
            title="ユーザーグループ名（含む）"
            value={filterState[FilterType.UserGroupName]}
            onChange={handleChangeUserGroupName(filterState, filterDispatch)}
          />
          <CheckboxForm
            title="IPアドレスが登録されている"
            value={filterState[FilterType.HasIPAddress]}
            onChange={handleChangeUserGroupHasIP(filterState, filterDispatch)}
          />
        </tbody>
      </table>
    </>
  );
};
