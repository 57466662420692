import { produce } from 'immer';

interface ComparableByOrderNumber {
  orderNumber: number;
}

export function reorderByOrderNumber<T extends ComparableByOrderNumber>(
  arr: T[],
  index: number,
  targetIndex: number
): T[] {
  return produce(arr, (draft) => {
    draft[index].orderNumber = draft[targetIndex].orderNumber;

    let startIndex: number;
    let endIndex: number;
    let diff: number;

    if (index < targetIndex) {
      startIndex = index + 1;
      endIndex = targetIndex + 1;
      diff = -1;
    } else {
      startIndex = targetIndex;
      endIndex = index;
      diff = 1;
    }

    for (let i = startIndex; i < endIndex; i++) {
      draft[i].orderNumber += diff;
    }

    draft.sort((a, b) => a.orderNumber - b.orderNumber);
  });
}
