import * as React from 'react';
import { TextForm } from 'ui/listView/filter/form';
import {
  handleChangeCustomDateCondition,
  handleChangeCustomDateRange,
  handleChangeOrganizationName,
  handleCloseCalendar
} from 'ui/listView/filter/handler';
import {
  Calendar,
  CustomDate,
  getDateConditionValue
} from 'ui/listView/filter/customDate';
import { FilterType } from 'ui/listView/filter/type';
import { FilterContext } from 'ui/listView/filter/context';

export const OrganizationFilter: React.FC = () => {
  const { state: filterState, dispatch: filterDispatch } =
    React.useContext(FilterContext);
  const calendarForCreateOpen = Boolean(
    filterState[FilterType.CalendarPositionForCreation]
  );
  const calendarForUpdateOpen = Boolean(
    filterState[FilterType.CalendarPositionForUpdate]
  );

  const refForCalendar = React.useRef<HTMLTableElement | null>(null);

  return (
    <>
      <table ref={refForCalendar}>
        <tbody>
          <TextForm
            title={'組織名（含む）'}
            value={filterState[FilterType.OrganizationName]}
            onChange={handleChangeOrganizationName(filterState, filterDispatch)}
          />
          <CustomDate
            title={'作成日時'}
            id={'created-select'}
            value={getDateConditionValue(
              filterState[FilterType.CreateCondition],
              filterState[FilterType.CreateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForCreation,
              FilterType.CreateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          <CustomDate
            title={'更新日時'}
            id={'created-select'}
            value={getDateConditionValue(
              filterState[FilterType.UpdateCondition],
              filterState[FilterType.UpdateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForUpdate,
              FilterType.UpdateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
        </tbody>
      </table>
      <Calendar
        id={'create-calendar'}
        title="作成日時"
        open={calendarForCreateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForCreation]}
        ranges={filterState[FilterType.CreateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.CreateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForCreation,
          filterState,
          filterDispatch
        )}
      />
      <Calendar
        id={'update-calendar'}
        title="更新日時"
        open={calendarForUpdateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForUpdate]}
        ranges={filterState[FilterType.UpdateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.UpdateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForUpdate,
          filterState,
          filterDispatch
        )}
      />
    </>
  );
};
