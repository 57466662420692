import * as React from 'react';
import { TableCell, IconButton } from '@material-ui/core';
import { ListItem, OrganizationItem } from 'models/dependency';
import { OpenMenuType, TimestampCell } from 'ui/listViewTableCell';
import { MoreHoriz } from '@material-ui/icons';

export const OrganizationRow: React.FC<{
  item: OrganizationItem;
  onClickItem?: (item: ListItem) => void;
  onOpenMenu: OpenMenuType;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  disableCheck?: boolean;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({ item, onOpenMenu }) => {
  return (
    <>
      <TableCell>{item.id}</TableCell>
      <TableCell>{item.name}</TableCell>
      <TableCell>{item.namespace}</TableCell>
      <TimestampCell time={item.created_at} />
      <TimestampCell time={item.updated_at} />
      <TableCell padding="none">
        <IconButton
          disabled={false}
          data-cy="listview-row-menu"
          size="small"
          onClick={(event) => {
            onOpenMenu(event, String(item.id), item.name);
          }}
        >
          <MoreHoriz />
        </IconButton>
      </TableCell>
    </>
  );
};
