import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';

const sparkStyle = makeStyles({
  root: {
    color: 'white',
    borderRadius: '50%',
    width: 20,
    height: 20,
    background: 'rgba(77, 77, 77, 0.8)',
    flexShrink: 0,
    verticalAlign: 'text-bottom'
  },
  node: {
    width: 12,
    height: 12,
    position: 'absolute',
    zIndex: 100,
    left: 18,
    top: 18
  },
  datasource: {
    width: 15,
    height: 15
  }
});
export const SparkIcon: React.VFC<{
  style?: 'node' | 'datasource';
  color?: string;
}> = ({ style, color = 'currentColor' }) => {
  const classes = sparkStyle();

  return (
    <svg
      className={clsx(
        classes.root,
        style === 'node' && classes.node,
        style === 'datasource' && classes.datasource
      )}
      viewBox="0 0 24 24"
    >
      <path fill={color} d="M11 15H6L13 1V9H18L11 23V15Z" />
    </svg>
  );
};
