import { Chart, ChartResult, Option, Row, TableColumn } from './chart';
import { RawDraftContentState } from 'react-draft-wysiwyg';
import { Dtypes } from 'Utils/dataTypes';
import { NodeStatus } from 'models/graph';
import { ItemTypes } from 'components/report/parameter/itemTypes';
import { green, grey, orange, red } from '@material-ui/core/colors';
import {
  DateRangeValues,
  DateSingleValues,
  DatetimeRangeValues,
  DatetimeSingleValues,
  TimeRangeValues,
  TimeSingleValues
} from './report/parameter';

export enum CardType {
  Chart = 'chart',
  Text = 'text',
  Image = 'image'
}

export enum ReportStatus {
  Unknown = 0,
  Success,
  Warning,
  Error
}

export interface Report {
  uuid: string;
  name: string;
  access_level: number;
  items: ReportItem[];
  parameters: Parameter[];
  parameter_groups: ParameterGroup[];
  public_token: ReportToken;
  created_at: string;
  updated_at: string;
  status: ReportStatus;
  error_charts: number;
  scheduling_locked: boolean;
  portal_folder?: ReportPortalFolder;
  enabled_public_token: boolean;
}

export function ReportStatusText(s: ReportStatus) {
  switch (s) {
    case ReportStatus.Success:
      return '成功';
    case ReportStatus.Warning:
      return '警告';
    case ReportStatus.Error:
      return 'エラー';
    case ReportStatus.Unknown:
      return '-';
    default:
      return '-';
  }
}

export function ReportStatusColor(s: ReportStatus): string {
  switch (s) {
    case ReportStatus.Success:
      return green['A700'];
    case ReportStatus.Warning:
      return orange['A700'];
    case ReportStatus.Error:
      return red['A700'];
    case ReportStatus.Unknown:
      return grey['A700'];
    default:
      return grey['A700'];
  }
}

export interface ReportToken {
  token: string;
  ip_filter: string;
  user_groups: string[];
  password?: string; // APIで取得時は含まれないので
  use_password: boolean;
  password_created: boolean;
}

export type ReportTokenRestrict = Omit<ReportToken, 'password_created'>;

export type ReportTokenResponse = Omit<ReportToken, 'password'>;

export type ReportItem = ChartItem | TextItem;

export interface ChartItem extends Item {
  type: CardType.Chart;
  chart?: Chart & { node_status?: NodeStatus };
  fetched?: boolean;
}

export interface TextItem extends Item {
  type: CardType.Text;
  content: RawDraftContentState;
}

export interface Item extends ItemPosition {
  uuid: string;
  justAdded?: boolean;
  isRendered?: boolean;
}

export interface ItemPosition extends Position {
  w: number;
  h: number;
}

export interface Position {
  x: number; // col
  y: number; // row
}

export interface Size {
  width: number;
  height: number;
}

export enum ParameterType {
  Single = 'single',
  Multiple = 'multiple',
  Range = 'range'
}

export type Parameter =
  | SingleStringParameter
  | MultipleStringParameter
  | SingleNumberParameter
  | RangeNumberParameter
  | SingleDateParameter
  | RangeDateParameter
  | SingleTimeParameter
  | RangeTimeParameter
  | SingleDatetimeParameter
  | RangeDatetimeParameter;

export type TimeParameter =
  | SingleDateParameter
  | RangeDateParameter
  | SingleTimeParameter
  | RangeTimeParameter
  | SingleDatetimeParameter
  | RangeDatetimeParameter;

export interface ParameterGroup {
  uuid: string;
  name: string;
  order_number: number;
}

export type CardParameter = Omit<Parameter, 'target'> & {
  column: Option;
};

export interface CardRawResult {
  type: 'raw';
  result: {
    data: Row[];
    columns: TableColumn[];
    columnValues: {
      [colname: string]: string[];
    };
    isSampling?: boolean;
  };
}

export interface CardTransformedResult {
  type: 'transformed';
  result: ChartResult;
}

export interface CardError {
  type: 'error';
  result: { message: string };
}

export interface CardWarning {
  type: 'warning';
  result: { data: undefined; isUniq: true };
}

export type CardResult =
  | CardRawResult
  | CardTransformedResult
  | CardError
  | CardWarning;

export type ParameterDType =
  | Dtypes.STRING
  | Dtypes.NUMBER
  | Dtypes.DATE
  | Dtypes.TIME
  | Dtypes.TIMESTAMP;

export interface GenericsParameter<T, P> {
  uuid: string;
  dtype: T;
  type: P;
  name: string;
  order_number: number;
  group_uuid?: string;
  target: { [itemId: string]: ParameterTarget };
}

export interface ParameterTarget {
  chart_id: string;
  column: Option;
}

export type ParameterValues =
  | SingleStringValue
  | MultipleStringValue
  | SingleNumberValue
  | RangeNumberValue
  | SingleDateValue
  | RangeDateValue
  | SingleDatetimeValue
  | RangeDatetimeValue
  | SingleTimeValue
  | RangeTimeValue;

interface SingleStringValue {
  operator: StringOperator;
  value?: string;
}

export type StringOperator =
  | '=='
  | 'contains'
  | 'starts_with'
  | 'ends_with'
  | 'regexp';

export interface SingleNumberValue {
  operator: string;
  value?: string;
}

export interface MultipleStringValue {
  operator?: 'in' | 'not_in';
  values: string[];
}

export interface RangeNumberValue {
  min?: string;
  max?: string;
}

export interface SingleTimeValue {
  values: TimeSingleValues;
}

export interface RangeTimeValue {
  values: TimeRangeValues;
}

export interface SingleDateValue {
  values: DateSingleValues;
}

export interface RangeDateValue {
  values: DateRangeValues;
}

export interface SingleDatetimeValue {
  values: DatetimeSingleValues;
}

export interface RangeDatetimeValue {
  values: DatetimeRangeValues;
}

export type RelativeDateUnit = 'hour' | 'day' | 'week' | 'month' | 'year';
export type RelativeDateDirection = 'last' | 'next';

export type SingleStringParameter = SingleStringValue &
  GenericsParameter<Dtypes.STRING, ParameterType.Single>;

export type MultipleStringParameter = MultipleStringValue &
  GenericsParameter<Dtypes.STRING, ParameterType.Multiple>;

export type SingleNumberParameter = SingleNumberValue &
  GenericsParameter<Dtypes.NUMBER, ParameterType.Single>;

export type RangeNumberParameter = RangeNumberValue &
  GenericsParameter<Dtypes.NUMBER, ParameterType.Range>;

export type SingleTimeParameter = SingleTimeValue &
  GenericsParameter<Dtypes.TIME, ParameterType.Single>;

export type RangeTimeParameter = RangeTimeValue &
  GenericsParameter<Dtypes.TIME, ParameterType.Range>;

export type SingleDateParameter = SingleDateValue &
  GenericsParameter<Dtypes.DATE, ParameterType.Single>;

export type RangeDateParameter = RangeDateValue &
  GenericsParameter<Dtypes.DATE, ParameterType.Range>;

export type SingleDatetimeParameter = SingleDatetimeValue &
  GenericsParameter<Dtypes.TIMESTAMP, ParameterType.Single>;

export type RangeDatetimeParameter = RangeDatetimeValue &
  GenericsParameter<Dtypes.TIMESTAMP, ParameterType.Range>;

export interface ParameterGroupItem extends ParameterGroup {
  parameters: Parameter[];
  itemType: ItemTypes.GROUP;
}

export type ParameterItem = Parameter & {
  itemType: ItemTypes.PARAMETER;
};
export type ParameterItems = (ParameterGroupItem | ParameterItem)[];

export interface ReportPortalFolder {
  folder_id: string;
  enabled: boolean;
}
