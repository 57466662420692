import { initialDateRange } from 'ui/listView/filter/customDate';
import { ListItemType } from 'models/dependency';
import {
  BuilderFilterState,
  ConnectionFilterState,
  DatasourceFilterState,
  ExportFilterState,
  FilterState,
  FilterType,
  NotificationDstFilterState,
  NotificationFilterState,
  OrganizationFilterState,
  ProjectFilterState,
  ReportFilterState,
  ScheduleFilterState,
  UserFilterState,
  UserGroupFilterState,
  VariableFilterState
} from 'ui/listView/filter/type';

const initialAccessLevels = [];
const initialBuilderName = '';
const initialCalendarPositionForCreation = null;
const initialCalendarPositionForExecutionEnd = null;
const initialCalendarPositionForExecutionStart = null;
const initialCalendarPositionForUpdate = null;
const initialConnectionName = '';
const initialCreateCondition = 'empty';
const initialCreateRange = initialDateRange;
const initialCreators = [];
const initialDatasourceName = '';
const initialDatasourceStatuses = [];
const initialDatasourceTypes = [];
const initialDataTypes = [];
const initialExcludeFolders = false;
const initialExecutionEndCondition = 'empty';
const initialExecutionEndRange = initialDateRange;
const initialExecutionStartCondition = 'empty';
const initialExecutionStartRange = initialDateRange;
const initialExportStatuses = [];
const initialFiltering = false;
const initialLimitedAccess = false;
const initialProjectName = '';
const initialPublicUrlExists = false;
const initialReportName = '';
const initialUpdateCondition = 'empty';
const initialUpdateRange = initialDateRange;
const initialVariableName = '';
const initialScheduleName = '';
const initialIsScheduled = false;
const initialNotificationName = '';
const initialNotificationDstTypes = [];
const initialNotificationDstName = '';
const initialUserGroupName = '';
const initialHasIpAddress = false;
const initialOrganizationName = '';

const initialScheduleFilterState: ScheduleFilterState = {
  filtering: initialFiltering,
  [FilterType.ScheduleName]: initialScheduleName,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.Creators]: initialCreators,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.IsScheduled]: initialIsScheduled
};

const initialBuilderFilterState: BuilderFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.ExcludeFolders]: initialExcludeFolders,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.BuilderName]: initialBuilderName,
  [FilterType.DatasourceTypes]: initialDatasourceTypes,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialConnectionFilterState: ConnectionFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.ConnectionName]: initialConnectionName,
  [FilterType.DatasourceTypes]: initialDatasourceTypes,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialDatasourceFilterState: DatasourceFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.ExcludeFolders]: initialExcludeFolders,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.DatasourceName]: initialDatasourceName,
  [FilterType.DatasourceStatuses]: initialDatasourceStatuses,
  [FilterType.DatasourceTypes]: initialDatasourceTypes,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialExportFilterState: ExportFilterState = {
  filtering: initialFiltering,
  [FilterType.CalendarPositionForExecutionEnd]:
    initialCalendarPositionForExecutionEnd,
  [FilterType.CalendarPositionForExecutionStart]:
    initialCalendarPositionForExecutionStart,
  [FilterType.DatasourceTypes]: initialDatasourceTypes,
  [FilterType.ExecutionEndCondition]: initialExecutionEndCondition,
  [FilterType.ExecutionEndRange]: initialExecutionEndRange,
  [FilterType.ExecutionStartCondition]: initialExecutionStartCondition,
  [FilterType.ExecutionStartRange]: initialExecutionStartRange,
  [FilterType.ProjectName]: initialProjectName,
  [FilterType.ExportStatuses]: initialExportStatuses
};

const initialProjectFilterState: ProjectFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.ExcludeFolders]: initialExcludeFolders,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.ProjectName]: initialProjectName,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialReportFilterState: ReportFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.ExcludeFolders]: initialExcludeFolders,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.LimitedAccess]: initialLimitedAccess,
  [FilterType.PublicURLExists]: initialPublicUrlExists,
  [FilterType.ReportName]: initialReportName,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialVariableFilterState: VariableFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.ExcludeFolders]: initialExcludeFolders,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.DataTypes]: initialDataTypes,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange,
  [FilterType.VariableName]: initialVariableName
};

const initialNotificationFilterState: NotificationFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.NotificationName]: initialNotificationName,
  [FilterType.NotificationDstTypes]: initialNotificationDstTypes,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialNotificationDstFilterState: NotificationDstFilterState = {
  filtering: initialFiltering,
  [FilterType.AccessLevels]: initialAccessLevels,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.NotificationDstName]: initialNotificationDstName,
  [FilterType.NotificationDstTypes]: initialNotificationDstTypes,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.Creators]: initialCreators,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

const initialUserGroupFilterState: UserGroupFilterState = {
  filtering: initialFiltering,
  [FilterType.UserGroupName]: initialUserGroupName,
  [FilterType.HasIPAddress]: initialHasIpAddress
};

const initialUserFilterState: UserFilterState = {
  filtering: initialFiltering,
  [FilterType.UserEmail]: '',
  [FilterType.UserTypes]: [],
  [FilterType.UserPasswordGenerated]: false,
  [FilterType.UserGroups]: []
};

const initialOrganizationFilterState: OrganizationFilterState = {
  filtering: initialFiltering,
  [FilterType.CalendarPositionForCreation]: initialCalendarPositionForCreation,
  [FilterType.CalendarPositionForUpdate]: initialCalendarPositionForUpdate,
  [FilterType.CreateCondition]: initialCreateCondition,
  [FilterType.CreateRange]: initialCreateRange,
  [FilterType.OrganizationName]: initialOrganizationName,
  [FilterType.UpdateCondition]: initialUpdateCondition,
  [FilterType.UpdateRange]: initialUpdateRange
};

export const initFilterState = (pageType: ListItemType): FilterState => {
  switch (pageType) {
    case 'schedules':
      return initialScheduleFilterState;
    case 'builders':
      return initialBuilderFilterState;
    case 'connections':
      return initialConnectionFilterState;
    case 'datasources':
    case 'selector_datasource':
      return initialDatasourceFilterState;
    case 'exports':
    case 'selector_export':
      return initialExportFilterState;
    case 'projects':
    case 'visualize_selector':
    case 'selector_project':
      return initialProjectFilterState;
    case 'reports':
    case 'report_selector':
    case 'selector_report':
      return initialReportFilterState;
    case 'variables':
      return initialVariableFilterState;
    case 'notifications':
      return initialNotificationFilterState;
    case 'notification_dsts':
      return initialNotificationDstFilterState;
    case 'user_groups':
      return initialUserGroupFilterState;
    case 'users':
      return initialUserFilterState;
    case 'organizations':
      return initialOrganizationFilterState;
    default:
      return { filtering: false };
  }
};

const isInitialAccessLevels = (state: FilterState): boolean => {
  return (
    state[FilterType.AccessLevels] === undefined ||
    state[FilterType.AccessLevels].length === 0
  );
};

const isInitialBuilderName = (state: FilterState): boolean => {
  return (
    state[FilterType.BuilderName] === undefined ||
    state[FilterType.BuilderName] === initialBuilderName
  );
};

const isInitialConnectionName = (state: FilterState): boolean => {
  return (
    state[FilterType.ConnectionName] === undefined ||
    state[FilterType.ConnectionName] === initialConnectionName
  );
};

const isInitialCreateCondition = (state: FilterState): boolean => {
  return (
    state[FilterType.CreateCondition] === undefined ||
    state[FilterType.CreateCondition] === initialCreateCondition
  );
};

const isInitialCreators = (state: FilterState): boolean => {
  return (
    state[FilterType.Creators] === undefined ||
    state[FilterType.Creators].length === 0
  );
};

const isInitialDatasourceName = (state: FilterState): boolean => {
  return (
    state[FilterType.DatasourceName] === undefined ||
    state[FilterType.DatasourceName] === initialDatasourceName
  );
};

const isInitialDatasourceStatuses = (state: FilterState): boolean => {
  return (
    state[FilterType.DatasourceStatuses] === undefined ||
    state[FilterType.DatasourceStatuses].length === 0
  );
};

const isInitialDatasourceTypes = (state: FilterState): boolean => {
  return (
    state[FilterType.DatasourceTypes] === undefined ||
    state[FilterType.DatasourceTypes].length === 0
  );
};

const isInitialDataTypes = (state: FilterState): boolean => {
  return (
    state[FilterType.DataTypes] === undefined ||
    state[FilterType.DataTypes].length === 0
  );
};

const isInitialExcludeFolders = (state: FilterState): boolean => {
  return (
    state[FilterType.ExcludeFolders] === undefined ||
    state[FilterType.ExcludeFolders] === initialExcludeFolders
  );
};

const isInitialExecutionEndCondition = (state: FilterState): boolean => {
  return (
    state[FilterType.ExecutionEndCondition] === undefined ||
    state[FilterType.ExecutionEndCondition] === initialExecutionEndCondition
  );
};

const isInitialExecutionStartCondition = (state: FilterState): boolean => {
  return (
    state[FilterType.ExecutionStartCondition] === undefined ||
    state[FilterType.ExecutionStartCondition] === initialExecutionStartCondition
  );
};

const isInitialExportStatuses = (state: FilterState): boolean => {
  return (
    state[FilterType.ExportStatuses] === undefined ||
    state[FilterType.ExportStatuses].length === 0
  );
};

const isInitialScheduleName = (state: FilterState): boolean => {
  return (
    state[FilterType.ScheduleName] === undefined ||
    state[FilterType.ScheduleName] === initialScheduleName
  );
};

const isInitialUserGroupName = (state: FilterState): boolean => {
  return (
    state[FilterType.UserGroupName] === undefined ||
    state[FilterType.UserGroupName] === initialUserGroupName
  );
};

const isInitialHasIpAddress = (state: FilterState): boolean => {
  return (
    state[FilterType.HasIPAddress] === undefined ||
    state[FilterType.HasIPAddress] === initialHasIpAddress
  );
};

const isInitialUserEmail = (state: FilterState): boolean => {
  return (
    state[FilterType.UserEmail] === undefined ||
    state[FilterType.UserEmail] === ''
  );
};

const isInitialUserTypes = (state: FilterState): boolean => {
  return (
    state[FilterType.UserTypes] === undefined ||
    state[FilterType.UserTypes].length === 0
  );
};

const isInitialUserPasswordGenerated = (state: FilterState): boolean => {
  return (
    state[FilterType.UserPasswordGenerated] === undefined ||
    state[FilterType.UserPasswordGenerated] === false
  );
};

const isInitialUserGroups = (state: FilterState): boolean => {
  return (
    state[FilterType.UserGroups] === undefined ||
    state[FilterType.UserGroups].length === 0
  );
};

export const isInitialFilterState = (state: FilterState): boolean => {
  return (
    isInitialAccessLevels(state) &&
    isInitialBuilderName(state) &&
    isInitialConnectionName(state) &&
    isInitialCreateCondition(state) &&
    isInitialCreators(state) &&
    isInitialDatasourceName(state) &&
    isInitialDatasourceStatuses(state) &&
    isInitialDatasourceTypes(state) &&
    isInitialDataTypes(state) &&
    isInitialExcludeFolders(state) &&
    isInitialExecutionEndCondition(state) &&
    isInitialExecutionStartCondition(state) &&
    isInitialExportStatuses(state) &&
    isInitialLimitedAccess(state) &&
    isInitialProjectName(state) &&
    isInitialPublicUrlExists(state) &&
    isInitialReportName(state) &&
    isInitialUpdateCondition(state) &&
    isInitialVariableName(state) &&
    isInitialScheduleName(state) &&
    isInitialIsScheduled(state) &&
    isInitialNotificationName(state) &&
    isInitialNotificationDstName(state) &&
    isInitialNotificationDstTypes(state) &&
    isInitialUserGroupName(state) &&
    isInitialHasIpAddress(state) &&
    isInitialUserEmail(state) &&
    isInitialUserTypes(state) &&
    isInitialUserPasswordGenerated(state) &&
    isInitialUserGroups(state) &&
    isInitialOrganizationName(state)
  );
};

const isInitialLimitedAccess = (state: FilterState): boolean => {
  return (
    state[FilterType.LimitedAccess] === undefined ||
    state[FilterType.LimitedAccess] === initialLimitedAccess
  );
};

const isInitialIsScheduled = (state: FilterState): boolean => {
  return (
    state[FilterType.IsScheduled] === undefined ||
    state[FilterType.IsScheduled] === initialIsScheduled
  );
};

const isInitialProjectName = (state: FilterState): boolean => {
  return (
    state[FilterType.ProjectName] === undefined ||
    state[FilterType.ProjectName] === initialProjectName
  );
};

const isInitialPublicUrlExists = (state: FilterState): boolean => {
  return (
    state[FilterType.PublicURLExists] === undefined ||
    state[FilterType.PublicURLExists] === initialPublicUrlExists
  );
};

const isInitialReportName = (state: FilterState): boolean => {
  return (
    state[FilterType.ReportName] === undefined ||
    state[FilterType.ReportName] === initialReportName
  );
};

const isInitialUpdateCondition = (state: FilterState): boolean => {
  return (
    state[FilterType.UpdateCondition] === undefined ||
    state[FilterType.UpdateCondition] === initialUpdateCondition
  );
};

const isInitialVariableName = (state: FilterState): boolean => {
  return (
    state[FilterType.VariableName] === undefined ||
    state[FilterType.VariableName] === initialVariableName
  );
};

const isInitialNotificationName = (state: FilterState): boolean => {
  return (
    state[FilterType.NotificationName] === undefined ||
    state[FilterType.NotificationName] === initialNotificationName
  );
};

const isInitialNotificationDstName = (state: FilterState): boolean => {
  return (
    state[FilterType.NotificationDstName] === undefined ||
    state[FilterType.NotificationDstName] === initialNotificationDstName
  );
};

const isInitialNotificationDstTypes = (state: FilterState): boolean => {
  return (
    state[FilterType.NotificationDstTypes] === undefined ||
    state[FilterType.NotificationDstTypes].length === 0
  );
};

const isInitialOrganizationName = (state: FilterState): boolean => {
  return (
    state[FilterType.OrganizationName] === undefined ||
    state[FilterType.OrganizationName] === initialOrganizationName
  );
};
