import * as DataForge from 'data-forge';
import {
  Area,
  Bar,
  Chart,
  ChartTheme,
  ChartType,
  Column,
  DataPoints,
  DataPointType,
  defaultDataPointType,
  defaultDataPointTypeList,
  defaultLineType,
  FusionChartConfigure,
  FusionChartCrossTableConfigure,
  FusionChartType,
  GenericsChartSettings,
  getFusionChartType,
  getInitOrder,
  getInitTooltip,
  getUseFusionChartType,
  GroupedSeries,
  Label,
  Line,
  LineType,
  LineTypes,
  NullableFusionChartConfigure,
  Option,
  OrderOptions,
  OrderSetting,
  Point,
  QueryData,
  Radar,
  Row,
  TableColumn,
  TimeseriesData,
  TimeseriesPlottype,
  Tooltip,
  TransformedData,
  TransformedDualResult,
  TransformedResult,
  View,
  Xtimeformattype,
  Y2SeriesNames,
  YValue
} from '../../models/chart';
import { getColors, radarAlpha } from 'components/visualize/chart/color';
import { sum as lodashSum, uniq } from 'lodash-es';
import { produce } from 'immer';
import { color as themeColor, fontFamily } from '../../theme';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  scatterTransformMultipleWithMultiY,
  scatterTransformMultipleWithLegend,
  scatterTransformSingle,
  addRegressionEquation,
  addRegressionEquations
} from './transformer/scatter';
import {
  aggregateByLegend,
  generateCategories,
  getDisplayValue,
  getXAxisDisplayValue,
  getValueName,
  getYOrderIndexies,
  nullValueByDType,
  pivotXValueDF,
  seriesSum,
  sortLegend,
  sortX,
  summariseSeries
} from './transformer/common';
import { orderValueName, nullValueLabel } from './transformer/constant';
import { histogramXAxisFormatter } from './transformer/histgram';
import { sortLegendOnTimeseriesMultiAxis } from './transformer/timeseries';

dayjs.extend(customParseFormat);

function aggregateName(name?: string): string {
  switch (name) {
    case 'SUM':
      return '(合計)';
    case 'AVG':
      return '(平均)';
    case 'MAX':
      return '(最大)';
    case 'MIN':
      return '(最小)';
    case 'COUNT':
      return '(カウント)';
    case 'UNIQ_CNT':
      return '(ユニークカウント)';
    default:
      return '';
  }
}

export function getHashedData(queryData?: QueryData | null): Row[] {
  if (queryData == undefined) {
    return [];
  }
  const { rows, columns } = queryData;
  return rows.map((row) => {
    const r: Row = {};
    columns.forEach((col, i) => {
      r[col] = row[i];
    });
    return r;
  });
}

export function getColumns(queryData?: QueryData): TableColumn[] {
  if (queryData == undefined) {
    return [];
  }
  return queryData.columns.map((colname, i) => {
    return {
      value: colname,
      label: colname,
      dtype: queryData.dtypes[i]
    };
  });
}

export function getValueLabel(aggregated: boolean, value?: YValue): string {
  if (value == undefined || value.column == undefined) {
    return '';
  }

  if (aggregated) {
    return value.column!.value;
  }

  const name = aggregateName(value.func);
  return value.column.value + name;
}

export function filterColumn(dtypes: string[], columns: Option[]): Option[] {
  if (dtypes.length === 0) {
    return columns;
  }
  return columns.filter((column) => {
    return dtypes.includes(column.dtype || '');
  });
}

function checkUniqueDataframe(
  chart: Chart,
  dataFrame?: DataForge.DataFrame
): boolean {
  if (dataFrame == undefined) {
    return true;
  }

  const rowLength = dataFrame.count();
  const columns: string[] = [];

  switch (chart.type) {
    case ChartType.scatter:
    case ChartType.table:
    case ChartType.boxAndWhisker:
      return true;
    case ChartType.bar:
    case ChartType.column:
    case ChartType.line:
    case ChartType.area:
    case ChartType.radar: {
      const { x, legend } = chart.chart;
      if (x) {
        columns.push(x.value);
      }
      if (legend) {
        columns.push(legend.value);
      }

      const uniqLength = dataFrame
        .pivot(columns, columns[0], DataForge.Series.count)
        .count();
      return uniqLength === rowLength;
    }
    case ChartType.pie: {
      if (chart.chart.legend == undefined) {
        return true;
      }

      const legendLen = dataFrame
        .getSeries(chart.chart.legend.value)
        .distinct()
        .count();
      return legendLen === rowLength;
    }
    default:
      return true;
  }
}

/*
凡例を指定した場合のデータ変形
- fusionchartの形に変換
- 凡例の並び替え
- x軸の並び替え
*/
function transformMultipleWithLegend(
  config: FusionChartCrossTableConfigure,
  settings: Chart,
  dataFrame?: DataForge.IDataFrame
): TransformedData | undefined {
  if (config.type === FusionChartType.Scatter) {
    return scatterTransformMultipleWithLegend(dataFrame, config);
  }

  if (dataFrame == undefined) {
    return;
  }

  const {
    chart: { x, legend, order, y, aggregated }
  } = config;
  if (legend == undefined) {
    return;
  }
  const xColname = x.value; // spread horizontal
  const legendColname = legend.value; // group column,
  const yColname = getValueName(aggregated, y[0]); // value column
  const yDType = y[0].column?.dtype;

  const xValues = dataFrame
    .distinct((row) => row[xColname])
    .orderBy((value) => {
      return value ?? nullValueByDType(yDType);
    })
    .toArray()
    .map((row) => {
      return row[xColname];
    });

  // 凡例の並べ替え
  // x軸の値を列名に横展開する
  const wide = pivotXValueDF(
    dataFrame,
    xColname,
    yColname,
    legendColname,
    order,
    xValues,
    y[0],
    aggregated
  );

  const useType = getUseFusionChartType(settings);
  if (useType === FusionChartType.TimeSeries) {
    let sortedLegends = wide.toArray().map((row) => {
      const seriesname =
        row[legendColname] == undefined ? nullValueLabel : row[legendColname];
      return String(seriesname);
    });
    if (
      getFusionChartType(settings) === FusionChartType.MultiAxisLine &&
      settings.view.y2SeriesNames
    ) {
      const { y2SeriesNames } = settings.view;
      // 2軸の場合は、1軸目、2軸目の順に凡例を並べないといけないので、sortedLegendsを並び替える
      sortedLegends = sortLegendOnTimeseriesMultiAxis(
        sortedLegends,
        y2SeriesNames
      );
    }

    // 列：日付, 凡例1, 凡例2, 凡例3,...に変換
    const pivot = dataFrame
      .groupBy((row) => row[xColname])
      .select((group) => {
        let column = {
          [xColname]: group.first()[xColname]
        };
        sortedLegends.forEach((legendValue) => {
          const arr = group
            .filter((grow) => String(grow[legendColname]) === legendValue)
            .toArray();
          column[legendValue] = arr.length > 0 ? arr[0][yColname] : null;
        });
        return column;
      })
      .inflate();
    return {
      type: useType,
      pivotData: pivot.toArray(),
      sortedLegends,
      plottype: getTimeseriesPlotType(settings)
    };
  }

  let sortedXs = xValues;

  // 横軸を並び替え
  if (order.x.target !== 'x') {
    const categoryCount = dataFrame.getSeries(legendColname).distinct().count();
    if (order.x.target === 'y') {
      const summary = dataFrame
        .pivot([legendColname, xColname], yColname, seriesSum)
        .groupBy((row) => row[xColname])
        .select((group) => ({
          [xColname]: group.first()[xColname],
          [yColname]: summariseSeries(
            group.deflate((row) => row[yColname]),
            categoryCount,
            order.x.summarise
          )
        }));
      if (order.x.method === 'asc') {
        sortedXs = summary
          .orderBy((row) => row[yColname] ?? nullValueByDType(yDType))
          .toArray()
          .map((row) => {
            return row[xColname];
          });
      } else {
        sortedXs = summary
          .orderByDescending((row) => row[yColname] ?? nullValueByDType(yDType))
          .toArray()
          .map((row) => {
            return row[xColname];
          });
      }
    }

    if (order.x.target === 'specific') {
      const specificValueName = getValueName(aggregated, order.x.column);
      const specificValueDType = order.x.column?.column?.dtype;
      const summary = dataFrame
        .pivot([legendColname, xColname], specificValueName, seriesSum)
        .groupBy((row) => row[xColname])
        .select((group) => ({
          [xColname]: group.first()[xColname],
          [specificValueName]: summariseSeries(
            group.deflate((row) => row[specificValueName]),
            categoryCount,
            order.x.summarise
          )
        }));

      if (order.x.method === 'asc') {
        sortedXs = summary
          .orderBy(
            (row) =>
              row[specificValueName] ?? nullValueByDType(specificValueDType)
          )
          .toArray()
          .map((row) => {
            return row[xColname];
          });
      } else {
        sortedXs = summary
          .orderByDescending(
            (row) =>
              row[specificValueName] ?? nullValueByDType(specificValueDType)
          )
          .toArray()
          .map((row) => {
            return row[xColname];
          });
      }
    }

    sortedXs = sortedXs.map((v) => String(v));
  } else {
    if (sortedXs.every((x) => typeof x === 'number')) {
      sortedXs.sort((a, b) => a - b);
    } else {
      sortedXs.sort((a, b) =>
        String(a).localeCompare(String(b), undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
    }
    if (order.x.method === 'desc') {
      sortedXs = sortedXs.reverse();
    }
  }

  const dataset = wide.toArray().map((row) => {
    const seriesname =
      row[legendColname] == undefined ? nullValueLabel : row[legendColname];
    return {
      seriesname: String(seriesname),
      data: sortedXs.map((x) => {
        return { value: row[x] };
      })
    };
  });

  const categories = [
    {
      category: sortedXs.map((x) => {
        return { label: getXAxisDisplayValue(x, settings) };
      })
    }
  ];
  return {
    type: config.type,
    categories,
    dataset
  };
}

export function isFusionChartConfigure(
  settings: NullableFusionChartConfigure | FusionChartConfigure
): settings is FusionChartConfigure {
  switch (settings.type) {
    case FusionChartType.Bar:
    case FusionChartType.Column:
    case FusionChartType.Line:
    case FusionChartType.Scatter:
    case FusionChartType.Area:
    case FusionChartType.Radar:
      return (
        settings.chart.x != undefined &&
        settings.chart.y != undefined &&
        settings.chart.y.length !== 0 &&
        settings.chart.y[0].column != undefined
      );
    case FusionChartType.Histogram:
      return (
        settings.chart.y != undefined &&
        settings.chart.y.length !== 0 &&
        settings.chart.y[0].column != undefined
      );
    case FusionChartType.MultipleSeriesBar:
    case FusionChartType.MultipleSeriesColumn:
    case FusionChartType.MultipleSeriesLine:
    case FusionChartType.StackedBar:
    case FusionChartType.StackedColumn:
    case FusionChartType.MultipleSeriesArea:
    case FusionChartType.StackedArea:
    case FusionChartType.MultiAxisLine: {
      // 横軸、縦軸どちらか欠けてたらfalse
      if (
        settings.chart?.x == undefined ||
        settings.chart.y == undefined ||
        settings.chart.y.length == 0 ||
        settings.chart.y[0].column == undefined
      ) {
        return false;
      }

      const valueNum = settings.chart.y.length;
      if (valueNum === 1 && settings.chart.legend != undefined) {
        return true;
      }

      return valueNum > 1;
    }
    case FusionChartType.MultipleHistogram: {
      if (
        settings.chart.y == undefined ||
        settings.chart.y.length == 0 ||
        settings.chart.y[0].column == undefined
      ) {
        return false;
      }

      const valueNum = settings.chart.y.length;
      if (valueNum === 1 && settings.chart.legend != undefined) {
        return true;
      }

      return valueNum > 1;
    }
    case FusionChartType.DualAxisColumnLine:
    case FusionChartType.DualAxisColumnLineStacked: {
      // 横軸、縦軸のどれかが欠けてたらfalse
      if (
        settings.chart.x == undefined ||
        settings.chart.dualAxisY == undefined ||
        settings.chart.dualAxisY.column.length == 0 ||
        settings.chart.dualAxisY.column[0].column == undefined ||
        settings.chart.dualAxisY.line.length == 0 ||
        settings.chart.dualAxisY.line[0].column == undefined
      ) {
        return false;
      }
      return true;
    }
    case FusionChartType.BoxAndWhisker: {
      // ・横軸はなし
      // ・凡例はなくても良い
      //  = 縦軸さえあれば良い
      return (
        settings.chart.y != undefined &&
        settings.chart.y.length != 0 &&
        settings.chart.y[0].column != undefined
      );
    }
    case FusionChartType.Pie:
      return (
        settings.chart.legend != undefined && settings.chart.value != undefined
      );
  }
}

export function extractColumns(settings: Chart): Option[] {
  const options: Option[] = [];
  switch (settings.type) {
    case ChartType.bar:
    case ChartType.column:
    case ChartType.line:
    case ChartType.scatter:
    case ChartType.area:
    case ChartType.radar: {
      const {
        chart: { x, y, legend }
      } = settings;
      if (x) {
        options.push(x);
      }
      if (y) {
        y.forEach((d) => {
          if (d.column) {
            options.push(d.column);
          }
        });
      }

      if (legend) {
        options.push(legend);
      }

      if (settings.type !== ChartType.scatter) {
        if (
          settings.chart.order &&
          settings.chart.order.x &&
          settings.chart.order.x.target === 'specific' &&
          settings.chart.order.x.column &&
          settings.chart.order.x.column.column
        ) {
          options.push(settings.chart.order.x.column.column);
        }

        if (
          settings.chart.order &&
          settings.chart.order.legend &&
          settings.chart.order.legend.target === 'specific' &&
          settings.chart.order.legend.column &&
          settings.chart.order.legend.column.column
        ) {
          options.push(settings.chart.order.legend.column.column);
        }
      }
      break;
    }
    case ChartType.dualAxisColumnLine: {
      const {
        chart: { x, dualAxisY, dualAxisLegend, dualAxisOrder }
      } = settings;
      if (x) {
        options.push(x);
      }
      if (dualAxisY) {
        Object.keys(dualAxisY).forEach((type) => {
          dualAxisY[type].forEach((d) => {
            if (d.column) {
              options.push(d.column);
            }
          });
        });
      }

      if (dualAxisLegend) {
        Object.keys(dualAxisLegend).forEach((type) => {
          if (dualAxisLegend[type] != undefined) {
            options.push(dualAxisLegend[type]);
          }
        });
      }

      if (
        dualAxisOrder?.x.target === 'specific' &&
        dualAxisOrder?.x.column?.column
      ) {
        options.push(dualAxisOrder.x.column.column);
      }

      if (dualAxisOrder?.legend) {
        Object.keys(dualAxisOrder.legend).forEach((type) => {
          if (
            dualAxisOrder.legend[type].target === 'specific' &&
            dualAxisOrder.legend[type]?.column?.column
          ) {
            options.push(dualAxisOrder.legend[type].column.column);
          }
        });
      }
      break;
    }
    case ChartType.pie: {
      const {
        chart: { value, legend }
      } = settings;
      if (value && value.column) {
        options.push(value.column);
      }
      if (legend) {
        options.push(legend);
      }

      if (
        settings.chart.order &&
        settings.chart.order.legend &&
        settings.chart.order.legend.target === 'specific' &&
        settings.chart.order.legend.column &&
        settings.chart.order.legend.column.column
      ) {
        options.push(settings.chart.order.legend.column.column);
      }

      break;
    }
    case ChartType.table: {
      const {
        chart: { columns }
      } = settings;
      if (columns) {
        columns.columns?.queryArgColumns.forEach((col) => {
          const { label, dtype, value, isError, errorType } = col;
          options.push({ label, dtype, value, isError, errorType });
        });
      }
      break;
    }
    case ChartType.boxAndWhisker: {
      const {
        chart: { y, legend }
      } = settings;
      if (y) {
        y.forEach((d) => {
          if (d.column) {
            options.push(d.column);
          }
        });
      }

      if (legend) {
        options.push(legend);
      }
      if (
        settings.chart.order &&
        settings.chart.order.legend &&
        settings.chart.order.legend.target === 'specific' &&
        settings.chart.order.legend.column &&
        settings.chart.order.legend.column.column
      ) {
        options.push(settings.chart.order.legend.column.column);
      }
      break;
    }
  }
  return options;
}

// これ使ってるとこ
export function validateColumns(settings: Chart): boolean {
  return extractColumns(settings).every((opt) => {
    return !opt.isError;
  });
}

export interface ChartData {
  type: FusionChartType;
  dataSource: {
    chart: any;
    data?: any;
    dataset?: any;
    annotations?: any;
    series?: string;
    xAxis?: any;
    yAxis?: any;
    tooltip?: any;
    categories?: any;
    navigator?: any;
    subcaption?: { text?: string; position?: string; style?: any };
    legend?: any;
    color?: string | null;
    colors?: { [seriesname: string]: string } | null;
    extensions?: any;
    dataPoints?: DataPoints;
    lineTypes?: LineTypes;
    y2SeriesNames?: Y2SeriesNames;
    axis?: Axis[];
    axisColor?: string;
    table?: {
      [colname: string]: string[];
    };
    timeseries?: {
      data: any[][];
      schema: {
        name: string;
        type: string;
        format?: string;
      }[];
    };
    // ビジュアライズではfusionchartでタイトルをつけないので使用しないこと
    caption?: {
      text: string;
      position?: string;
    };
  };
}

interface Axis {
  title?: string;
  maxValue?: string;
  minValue?: string;
  numberPrefix?: string;
  numberSuffix?: string;
  decimals?: number;
  formatNumberScale?: string;
  axisOnLeft?: number;
  dataset?: AxisDataset[];
  axisLineThickness?: number;
  linethickness?: number;
}

interface AxisDataset {
  seriesname?: string;
  data?: {
    value?: string | number;
  }[];
  alpha?: any;
  color?: any;
}

function convertToPercentage(d: ChartData, view: View): ChartData {
  const { type, dataSource } = { ...d };
  switch (type) {
    case FusionChartType.Pie:
    case FusionChartType.Bar:
    case FusionChartType.Column:
    case FusionChartType.Line:
    case FusionChartType.Area:
      if (dataSource.data != undefined) {
        dataSource.data = dataSource.data.map((d) =>
          d.value != undefined ? { ...d, value: d.value * 100 } : d
        );
      }
      break;
    case FusionChartType.MultipleSeriesBar:
    case FusionChartType.MultipleSeriesColumn:
    case FusionChartType.MultipleSeriesLine:
    case FusionChartType.StackedBar:
    case FusionChartType.StackedColumn:
    case FusionChartType.MultipleSeriesArea:
    case FusionChartType.StackedArea:
    case FusionChartType.BoxAndWhisker:
    case FusionChartType.Radar:
      if (dataSource.dataset != undefined) {
        dataSource.dataset = dataSource.dataset.map((s) => {
          s.data = s.data.map((d) =>
            d.value != undefined ? { ...d, value: d.value * 100 } : d
          );
          return s;
        });
      }
      break;
    case FusionChartType.DualAxisColumnLine:
    case FusionChartType.DualAxisColumnLineStacked: {
      if (dataSource.dataset != undefined) {
        dataSource.dataset = dataSource.dataset.map((s) => {
          let enablePercentage: boolean;
          if (view.y2SeriesNames && view.y2SeriesNames[s.seriesname]) {
            enablePercentage = Boolean(view.y2ShowPercentage);
          } else {
            enablePercentage = Boolean(view.showPercentage);
          }
          if (!enablePercentage) {
            return s;
          }
          s.data = s.data.map((d) =>
            d.value != undefined ? { ...d, value: d.value * 100 } : d
          );
          return s;
        });
      }
      break;
    }
    case FusionChartType.MultiAxisLine:
      if (dataSource.axis != undefined) {
        let targetIndexes: number[] = [];
        view.showPercentage && targetIndexes.push(0);
        view.y2ShowPercentage && targetIndexes.push(1);
        dataSource.axis = dataSource.axis.map((a, i) => {
          if (a.dataset == undefined) {
            return a;
          }
          if (!targetIndexes.includes(i)) {
            return a;
          }
          return {
            ...a,
            dataset: a.dataset.map((s) => {
              if (s.data == undefined) {
                return s;
              }
              s.data = s.data.map((d) =>
                d.value != undefined
                  ? { ...d, value: Number(d.value) * 100 }
                  : d
              );
              return s;
            })
          };
        });
      }
      break;
    case FusionChartType.Scatter: {
      const { yShowPercentage, xShowPercentage } = view;
      if (dataSource.dataset != undefined) {
        dataSource.dataset = dataSource.dataset.map((s) => {
          s.data = s.data.map((d) => {
            return d
              ? {
                  ...d,
                  x: xShowPercentage && d.x != null ? d.x * 100 : d.x,
                  y: yShowPercentage && d.y != null ? d.y * 100 : d.y
                }
              : d;
          });
          return s;
        });
      }
      break;
    }
    default:
      return {
        type,
        dataSource
      };
  }
  return {
    type,
    dataSource
  };
}

export function getInitChartData(): ChartData {
  return {
    type: FusionChartType.Column,
    dataSource: {
      chart: { theme: ChartTheme.Fusion }
    }
  };
}

function generateTooltipText(view: View, data: TransformedData): string {
  let tooltip = view.tooltip;
  if (tooltip == undefined) {
    tooltip = getInitTooltip();
  }
  if (tooltip.useCustomTooltip) {
    return tooltip.customFormat;
  }

  let yAxisValueName = '$dataValue';
  let xAxisValueName = '$label';

  if (data.type === FusionChartType.Scatter) {
    yAxisValueName = '$yValue';
    xAxisValueName = '$xValue';
  }

  if (view.stack100Percent === '1') {
    yAxisValueName = '$percentValue';
  }

  let tooltipText = '';
  if (tooltip.showLegend) {
    tooltipText += `凡例: ${
      data.type === FusionChartType.Pie ||
      (data.type === FusionChartType.BoxAndWhisker && data.dataset.length === 1)
        ? '$label'
        : '$seriesName'
    }`;
  }

  if (tooltip.showXAxis) {
    if (tooltipText !== '') {
      tooltipText += '<br/>';
    }
    tooltipText += `$xAxisName: ${xAxisValueName}`;
  }

  if (tooltip.showYAxis) {
    if (tooltipText !== '') {
      tooltipText += '<br/>';
    }
    if (data.type === FusionChartType.BoxAndWhisker) {
      tooltipText += `最大: $maxDataValue<br>Q3: $Q3<br>中央: $median <br>Q1: $Q1<br>最小: $minDataValue</b>`;
    } else {
      tooltipText += `$yAxisName: ${yAxisValueName}`;
    }
  }

  return tooltipText;
}

function generateDualAxisTooltipText(view: View): string | undefined {
  let tooltip = view.tooltip;
  if (tooltip == undefined) {
    tooltip = getInitTooltip();
  }
  if (tooltip.useCustomTooltip) {
    return tooltip.customFormat;
  }

  const textList: string[] = [];
  if (tooltip.showLegend) {
    textList.push('$seriesName');
  }

  if (tooltip.showXAxis) {
    textList.push('$label');
  }

  if (tooltip.showYAxis) {
    textList.push('$dataValue');
  }

  return textList.join(', ');
}

export function attachViewStyle(data: ChartData, configs?: Chart): ChartData {
  if (configs === undefined) {
    return data;
  }
  const { view } = configs;
  let d = { ...data };
  if (
    view.showPercentage ||
    view.yShowPercentage ||
    view.xShowPercentage ||
    view.y2ShowPercentage
  ) {
    d = convertToPercentage(d, view);
  }

  const { type, dataSource } = d;
  dataSource.chart = {
    ...dataSource.chart,
    ...view,
    formatNumberScale:
      view.formatNumberScale == undefined ? '0' : view.formatNumberScale,
    forceDecimals: view.decimals != undefined ? 1 : undefined,
    plotHighlightEffect: 'fadeout',
    exportEnabled: '1',
    exportFormats: 'PNG|SVG|CSV|XLSX',
    exportFileName: 'dashboard_export',
    forceYAxisValueDecimals:
      view.yAxisValueDecimals != undefined ? 1 : undefined,
    forceXAxisValueDecimals:
      view.xAxisValueDecimals != undefined ? 1 : undefined,
    thousandSeparator: ',',
    baseFont: fontFamily,
    baseFontColor: themeColor,
    outCnvBaseFont: fontFamily,
    outCnvBaseFontColor: themeColor,
    captionFont: fontFamily,
    captionFontColor: themeColor,
    subCaptionFont: fontFamily,
    subCaptionFontColor: themeColor,
    xAxisNameFont: fontFamily,
    xAxisNameFontColor: themeColor,
    yAxisNameFont: fontFamily,
    yAxisNameFontColor: themeColor,
    yAxisValueFont: fontFamily,
    yAxisValueFontColor: themeColor,
    pYAxisNameFont: fontFamily,
    pYAxisNameFontColor: themeColor,
    sYAxisNameFont: fontFamily,
    sYAxisNameFontColor: themeColor,
    legendItemFont: fontFamily,
    legendItemFontColor: themeColor,
    valueFont: fontFamily,
    valueFontColor: themeColor,
    labelFont: fontFamily,
    labelFontColor: themeColor,
    caption: null
  };

  return {
    type,
    dataSource
  };
}

function dataPointTypeToFusionChartProps(
  type: DataPointType,
  isScatter?: boolean
): { [propName: string]: string } {
  const defaultProps = { drawAnchors: '1', anchorRadius: '4' };
  switch (type) {
    case 'circle':
      if (isScatter) {
        // scatterだけ円の表現ができないので20角形で代用
        return {
          ...defaultProps,
          anchorSides: '20'
        };
      }
      return defaultProps;
    case 'triangle':
      return {
        ...defaultProps,
        anchorSides: '3'
      };
    case 'quadrangle':
      return {
        ...defaultProps,
        anchorSides: '4'
      };
    case 'pentagon':
      return {
        ...defaultProps,
        anchorSides: '5'
      };
    case 'none':
      return { drawAnchors: '0' };
  }
}

function lineTypeToFusionChartProps(
  type: LineType,
  multiple?: boolean
): { [propName: string]: string } {
  switch (type) {
    case 'normal':
      return {};
    case 'dashed':
      const propName = multiple ? 'dashed' : 'lineDashed';
      return {
        [propName]: '1'
      };
    case 'thick':
      return {
        linethickness: '5'
      };
  }
}

// 単軸凡例なしの場合はseriesnameがないのでsettingsから取得する
function getDataPointLabel(settings: Chart): string | undefined {
  const config = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure | NullableFusionChartConfigure;
  if (!isFusionChartConfigure(config)) {
    return;
  }

  if (
    config.type === FusionChartType.Line ||
    config.type === FusionChartType.Scatter
  ) {
    return config.chart.y[0].column?.label;
  }
}

function getDataPoints(
  fusionChartType: FusionChartType,
  dataPoints?: DataPoints,
  label?: string | string[]
): DataPoints | undefined {
  if (
    ![
      FusionChartType.Line,
      FusionChartType.MultipleSeriesLine,
      FusionChartType.MultiAxisLine,
      FusionChartType.Scatter
    ].includes(fusionChartType)
  ) {
    return;
  }
  if (!label) {
    return;
  }

  const labels = Array.isArray(label) ? label : [label];
  const newDataPoints = {};
  labels.forEach((l, i) => {
    const defaultType =
      fusionChartType === FusionChartType.Scatter
        ? defaultDataPointTypeList[i % defaultDataPointTypeList.length] // 散布図の場合はローテーションさせる
        : defaultDataPointType;
    newDataPoints[l] =
      dataPoints && dataPoints[l] ? dataPoints[l] : defaultType;
  });
  return newDataPoints;
}

function getLineTypes(
  fusionChartType: FusionChartType,
  lineTypes?: LineTypes,
  label?: string | string[]
): LineTypes | undefined {
  if (
    ![
      FusionChartType.Line,
      FusionChartType.MultipleSeriesLine,
      FusionChartType.MultiAxisLine
    ].includes(fusionChartType)
  ) {
    return;
  }
  if (!label) {
    return;
  }

  const labels = Array.isArray(label) ? label : [label];
  const newLineTypes: LineTypes = {};
  labels.forEach((l) => {
    newLineTypes[l] =
      lineTypes && lineTypes[l] ? lineTypes[l] : defaultLineType;
  });
  return newLineTypes;
}

function generateColors(
  seriesnames: string[],
  colors: {
    [seriesname: string]: string;
  } | null,
  defaultColors: string[]
): {
  [seriesname: string]: string;
} {
  // 存在する凡例のみ割り当てる
  let newColors = {};
  Object.keys(colors || {}).map((key) => {
    if (seriesnames.includes(key) && colors != null && key in colors) {
      newColors[key] = colors[key];
    }
  });
  // 未使用の色を割り当てる
  const unusedColors = defaultColors.filter(
    (c) => !(colors && Object.values(colors).includes(c))
  );
  const unassignedSeriesnames = seriesnames.filter(
    (n) => !(colors && Object.keys(colors).includes(n))
  );
  // 色が割り当てられていない凡例のみだけ割り当てる
  unassignedSeriesnames.forEach((name, i) => {
    newColors[name] = unusedColors[i % unusedColors.length];
  });
  return newColors;
}

function getAlpha(
  type: FusionChartType,
  settingsAplha?: string
): string | undefined {
  if (type !== FusionChartType.Radar) {
    return;
  }
  return settingsAplha !== undefined ? settingsAplha : radarAlpha;
}

// 2軸目かどうか
function generateY2SeriesNames(
  y2SeriesNames: Y2SeriesNames | undefined,
  seriesnames: string[]
): Y2SeriesNames {
  const newY2SeriesNames: Y2SeriesNames = {};
  seriesnames.forEach((name) => {
    newY2SeriesNames[name] =
      y2SeriesNames && y2SeriesNames[name] ? y2SeriesNames[name] : false;
  });
  return newY2SeriesNames;
}

function generateDataset(
  dataset: GroupedSeries[],
  colors: { [seriesname: string]: string },
  dataPoints: DataPoints | undefined,
  lineTypes: LineTypes | undefined,
  alpha?: string | undefined
) {
  return dataset.map((d) => {
    const newDataPointsChartProps =
      dataPoints && dataPoints[d.seriesname]
        ? dataPointTypeToFusionChartProps(dataPoints[d.seriesname])
        : {};
    const newLineTypesChartProps =
      lineTypes && lineTypes[d.seriesname]
        ? lineTypeToFusionChartProps(lineTypes[d.seriesname], true)
        : {};
    return {
      ...d,
      color: colors ? colors[d.seriesname] : undefined,
      ...newDataPointsChartProps,
      ...newLineTypesChartProps,
      alpha
    };
  });
}

// 単一データをラベルの順に並び替える
function sortSingleData(d: Point[], labels: string[]): Point[] {
  return d
    .map((row) => {
      // indexを付与
      return {
        index: labels.findIndex((l) => l === row.label),
        row: row
      };
    })
    .sort((a, b) => {
      // indexの順に並び替える
      if (a.index < b.index) {
        return -1;
      }
      if (a.index > b.index) {
        return 1;
      }
      return 0;
    })
    .map((d) => d.row);
}

// Multipleのdatasetをラベルの順に並び替える
function sortMultipleDataset(
  dataset: GroupedSeries[],
  category: Label[],
  labels: string[]
): GroupedSeries[] {
  return dataset.map((ds) => {
    const sortedData = ds.data
      .map((v, i) => {
        // indexを付与
        const label = category[i].label;
        return {
          index: labels.findIndex((l) => l === label),
          row: v
        };
      })
      .sort((a, b) => {
        // indexの順に並び替える
        if (a.index < b.index) {
          return -1;
        }
        if (a.index > b.index) {
          return 1;
        }
        return 0;
      })
      .map((d) => d.row);
    return {
      ...ds,
      data: sortedData
    };
  });
}

function getSortedLabels(data: (TransformedData | undefined)[]): string[] {
  let sortedLabels: string[] = [];
  for (const d of data) {
    if (d == undefined) {
      continue;
    }
    switch (d.type) {
      case FusionChartType.Column: {
        sortedLabels = d.data.map((data) => data.label);
        break;
      }
      case FusionChartType.MultipleSeriesColumn:
      case FusionChartType.StackedColumn: {
        if (d.categories.length > 0) {
          sortedLabels = d.categories[0].category.map((c) => c.label);
        }
        break;
      }
    }
    if (sortedLabels.length > 0) {
      break;
    }
  }
  return sortedLabels;
}

function generateDualDataset(
  data: (TransformedData | undefined)[],
  settings: Chart,
  sortedLabels: string[]
): {
  seriesname: string;
  data: {
    value?: string | number;
  }[];
  renderAs?: string;
  parentYAxis?: string;
}[] {
  let dataset: {
    seriesname: string;
    data: {
      value?: string | number;
    }[];
    renderAs?: string;
    parentYAxis?: string;
  }[] = [];
  data.forEach((d) => {
    if (d == undefined) {
      return;
    }

    switch (d.type) {
      case FusionChartType.Column:
      case FusionChartType.Line: {
        const seriesname =
          settings.type === ChartType.dualAxisColumnLine &&
          settings.chart.dualAxisY
            ? settings.chart.dualAxisY[
                d.type === FusionChartType.Column
                  ? ChartType.column
                  : ChartType.line
              ][0].column?.label
            : undefined;
        const sortedData = sortSingleData(d.data, sortedLabels);
        dataset.push({
          seriesname: seriesname ?? '',
          data: sortedData.map((data) => {
            return { value: data.value };
          }),
          renderAs: d.type === FusionChartType.Line ? 'line' : undefined,
          parentYAxis: d.type === FusionChartType.Line ? 's' : undefined
        });
        break;
      }
      case FusionChartType.MultipleSeriesColumn:
      case FusionChartType.MultipleSeriesLine:
      case FusionChartType.StackedColumn: {
        if (d.categories.length === 0) {
          return;
        }
        const sortedDataset = sortMultipleDataset(
          d.dataset,
          d.categories[0].category,
          sortedLabels
        );
        sortedDataset.forEach((ds) => {
          dataset.push({
            seriesname: ds.seriesname,
            data: ds.data,
            renderAs:
              d.type === FusionChartType.MultipleSeriesLine
                ? 'line'
                : undefined,
            parentYAxis:
              d.type === FusionChartType.MultipleSeriesLine ? 's' : undefined
          });
        });
        break;
      }
    }
  });
  return dataset;
}

function generateDualFusionChartData(
  data: (TransformedData | undefined)[],
  settings: Chart
): ChartData {
  const {
    color: { colors },
    view: { dataPoints, lineTypes }
  } = settings;

  if (data.length < 2) {
    return defaultChartData;
  }

  // dataごとで並びが異なる場合があるので揃える
  const sortedLabels = getSortedLabels(data);
  if (sortedLabels.length === 0) {
    return defaultChartData;
  }
  const category = sortedLabels.map((l) => {
    return { label: l };
  });
  const dataset = generateDualDataset(data, settings, sortedLabels);

  // seriesnameによって変わる属性を更新する
  const seriesnames = dataset
    .map((d) => {
      return d?.seriesname;
    })
    .filter((seriesname): seriesname is string => seriesname != undefined);

  const defaultColors = getColors(settings.color.palette);
  const newColors = generateColors(seriesnames, colors, defaultColors);
  // 折れ線グラフの凡例
  const lineSeriesnames = dataset
    .filter((d) => d.renderAs && d.renderAs === 'line')
    .map((d) => {
      return d?.seriesname;
    })
    .filter((seriesname): seriesname is string => seriesname != undefined);
  const newDataPoints = getDataPoints(
    FusionChartType.Line,
    dataPoints,
    lineSeriesnames
  );
  const newLineTypes = getLineTypes(
    FusionChartType.Line,
    lineTypes,
    lineSeriesnames
  );
  const newY2SeriesNames: Y2SeriesNames = {};
  lineSeriesnames.forEach((s) => {
    newY2SeriesNames[s] = true;
  });

  const newMultipleDataset = generateDataset(
    dataset,
    newColors,
    newDataPoints,
    newLineTypes
  );

  const {
    yAxisName,
    yAxisMaxValue,
    yAxisMinValue,
    numberPrefix,
    numberSuffix,
    decimals,
    formatNumberScale,
    y2AxisName,
    y2AxisMaxValue,
    y2AxisMinValue,
    y2NumberPrefix,
    y2NumberSuffix,
    y2Decimals,
    y2FormatNumberScale
  } = settings.view;

  return {
    type: getFusionChartType(settings),
    dataSource: {
      chart: {
        theme: ChartTheme.Fusion,
        showToolTip: getShowToolTip(settings.view.tooltip),
        plotToolText: generateDualAxisTooltipText(settings.view),
        drawCustomLegendIcon: '0',
        connectNullData: getConnectNullData(settings.view.showNullData),
        axisColor: '#999999',
        pYAxisName: yAxisName,
        pYAxisMaxValue: yAxisMaxValue,
        pYAxisMinValue: yAxisMinValue,
        pNumberPrefix: numberPrefix,
        pNumberSuffix: numberSuffix,
        pDecimals: decimals,
        pFormatNumberScale: formatNumberScale,
        sYAxisName: y2AxisName,
        sYAxisMaxValue: y2AxisMaxValue,
        sYAxisMinValue: y2AxisMinValue,
        sNumberPrefix: y2NumberPrefix,
        sNumberSuffix: y2NumberSuffix,
        sDecimals: y2Decimals,
        sFormatNumberScale: y2FormatNumberScale
      },
      categories: [{ category }],
      colors: newColors,
      color: null,
      dataPoints: newDataPoints,
      lineTypes: newLineTypes,
      dataset: newMultipleDataset,
      y2SeriesNames: newY2SeriesNames
    }
  };
}

function getShowToolTip(tooltip?: Tooltip): boolean {
  const tip = tooltip ?? getInitTooltip();
  return (
    tip.showLegend || tip.showXAxis || tip.showYAxis || tip.useCustomTooltip
  );
}

function getConnectNullData(showNullData?: string): string {
  if (showNullData == null || showNullData === '0') {
    return '0';
  }
  return '1';
}

const defaultChartData = {
  type: FusionChartType.Column,
  dataSource: {
    chart: { theme: ChartTheme.Fusion },
    color: null,
    colors: null
  }
};

function getTimeseriesPlotType(settings: Chart): TimeseriesPlottype {
  switch (settings.type) {
    case ChartType.column:
      return TimeseriesPlottype.column;
    case ChartType.line:
      return TimeseriesPlottype.line;
    case ChartType.area:
      return TimeseriesPlottype.area;
    default:
      return TimeseriesPlottype.column;
  }
}

function getTimeseriesFormat(settings: Chart): string | undefined {
  return 'xtimeformattype' in settings.chart
    ? _getTimeseriesFormat(
        settings.chart.xtimeformattype,
        settings.chart.xtimeformat
      )
    : undefined;
}

function _getTimeseriesFormat(
  formatType?: Xtimeformattype,
  _format?: string
): string | undefined {
  if (formatType == null) {
    return;
  }
  const format = _format ? _format.replace('%i', '%M') : undefined;
  switch (formatType) {
    case Xtimeformattype.year:
      if (format === 'jp') {
        return '%Y年';
      }
      return '%Y';
    case Xtimeformattype.month:
      if (format === 'jp') {
        return '%Y年%m月';
      }
      if (format === 'slash') {
        return '%Y/%m';
      }
      return '%Y-%m';
    case Xtimeformattype.day:
      if (format === 'jp') {
        return '%Y年%m月%d日';
      }
      if (format === 'slash') {
        return '%Y/%m/%d';
      }
      return '%Y-%m-%d';
    case Xtimeformattype.hour:
      if (format === 'jp') {
        return '%Y年%m月%d日 %H時';
      }
      if (format === 'slash') {
        return '%Y/%m/%d %H';
      }
      return '%Y-%m-%d %H';
    case Xtimeformattype.minute:
      if (format === 'jp') {
        return '%Y年%m月%d日 %H時%M分';
      }
      if (format === 'slash') {
        return '%Y/%m/%d %H:%M';
      }
      return '%Y-%m-%d %H:%M';
    case Xtimeformattype.second:
      if (format === 'jp') {
        return '%Y年%m月%d日 %H時%M分%S秒';
      }
      if (format === 'slash') {
        return '%Y/%m/%d %H:%M:%S';
      }
      return '%Y-%m-%d %H:%M:%S';
    case Xtimeformattype.millisecond:
      if (format === 'jp') {
        return '%Y年%m月%d日 %H時%M分%S秒%L';
      }
      if (format === 'slash') {
        return '%Y/%m/%d %H:%M:%S.%L';
      }
      return '%Y-%m-%d %H:%M:%S.%L';
    case Xtimeformattype.time_hour:
      if (format === 'jp') {
        return '%H時';
      }
      return '%H';
    case Xtimeformattype.time_minute:
      if (format === 'jp') {
        return '%H時%M分';
      }
      return '%H:%M';
    case Xtimeformattype.time_second:
      if (format === 'jp') {
        return '%H時%M分%S秒';
      }
      return '%H:%M:%S';
    case Xtimeformattype.time_millisecond:
    case Xtimeformattype.time_microsecond:
      if (format === 'jp') {
        return '%H時%M分%S秒%L';
      }
      return '%H:%M:%S.%L';
    case Xtimeformattype.auto:
    case Xtimeformattype.custom:
      return format;
    default: {
      const ex: never = formatType;
      return ex;
    }
  }
}

const outputTimeFormatTypes = [
  Xtimeformattype.year,
  Xtimeformattype.month,
  Xtimeformattype.day,
  Xtimeformattype.hour,
  Xtimeformattype.minute,
  Xtimeformattype.second,
  Xtimeformattype.millisecond
];

function getOutputTimeFormat(settings: Chart): { [unit: string]: string } {
  const formatType =
    'xtimeformattype' in settings.chart ? settings.chart.xtimeformattype : '';
  if (formatType == null) {
    return {};
  }
  const format =
    'xtimeformat' in settings.chart ? settings.chart.xtimeformat : '';
  // outputTimeFormatTypesの全てのformatを埋める
  let outputTimeFormat = {};

  if (formatType.startsWith('time_')) {
    // time_~ はday以上は空にする
    outputTimeFormatTypes.map((type) => {
      outputTimeFormat[type] = ' ';
    });
    [
      Xtimeformattype.time_hour,
      Xtimeformattype.time_minute,
      Xtimeformattype.time_second,
      Xtimeformattype.time_millisecond,
      Xtimeformattype.time_microsecond
    ].forEach((fType) => {
      switch (fType) {
        case 'time_hour': {
          outputTimeFormat['hour'] = _getTimeseriesFormat(fType, format);
          break;
        }
        case 'time_minute': {
          outputTimeFormat['minute'] = _getTimeseriesFormat(fType, format);
          break;
        }
        case 'time_second': {
          outputTimeFormat['second'] = _getTimeseriesFormat(fType, format);
          break;
        }
        case 'time_millisecond':
        case 'time_microsecond': {
          outputTimeFormat['millisecond'] = _getTimeseriesFormat(fType, format);
          break;
        }
      }
    });
  } else if (formatType === 'custom') {
    // カスタムは全て同じフォーマットにする
    outputTimeFormatTypes.map((type) => {
      outputTimeFormat[type] = _getTimeseriesFormat(formatType, format);
    });
  } else {
    outputTimeFormatTypes.map((type) => {
      outputTimeFormat[type] = _getTimeseriesFormat(type, format);
    });
  }

  return outputTimeFormat;
}

const customFormats: { format: string; type: Xtimeformattype }[] = [
  { format: '%f', type: Xtimeformattype.millisecond },
  { format: '%S', type: Xtimeformattype.second },
  { format: '%i', type: Xtimeformattype.minute },
  { format: '%H', type: Xtimeformattype.hour },
  { format: '%d', type: Xtimeformattype.day },
  { format: '%m', type: Xtimeformattype.month },
  { format: '%Y', type: Xtimeformattype.year }
];

function getXaxisBinning(settings: Chart) {
  // binの表示間隔
  // 例えばday: [1,3] の場合、navigatorで範囲を狭めていったときに3日ごとに一つのbinが表示され、
  // さらに狭めると1日ごとに一つのbinが表示される
  let formatType =
    'xtimeformattype' in settings.chart
      ? settings.chart.xtimeformattype
      : undefined;
  if (formatType == null) {
    return {};
  }

  if (formatType === 'custom') {
    // 最小単位のformatTypeにする
    const format =
      'xtimeformat' in settings.chart ? settings.chart.xtimeformat : undefined;
    if (format !== undefined) {
      formatType = customFormats.find((row) =>
        format.includes(row.format)
      )?.type;
    }
  }

  let bins = {};
  switch (formatType) {
    case 'year': {
      bins = { year: [1] };
      break;
    }
    case 'month': {
      bins = { month: [1] };
      break;
    }
    case 'day': {
      bins = { day: [1] };
      break;
    }
    case 'hour': {
      bins = { hour: [1] };
      // bins = { day: [1], hour: [1] };
      break;
    }
    case 'minute': {
      bins = { minute: [1] };
      // bins = { hour: [1], minute: [1] };
      break;
    }
    case 'second': {
      bins = { second: [1] };
      // bins = { hour: [1], minute: [1], second: [1] };
      break;
    }
    case 'millisecond': {
      // bins = { millisecond: [1] };
      bins = {
        minute: [1],
        second: [1],
        millisecond: [10, 20, 50, 100, 200, 250, 500]
      };
      break;
    }
    case 'time_hour': {
      bins = { hour: [1] };
      break;
    }
    case 'time_minute': {
      bins = { minute: [1] };
      // bins = { hour: [1], minute: [1] };
      break;
    }
    case 'time_second': {
      bins = { second: [1] };
      // bins = { hour: [1], minute: [1], second: [1] };
      break;
    }
    case 'time_millisecond':
    case 'time_microsecond': {
      bins = { millisecond: [1] };
      // bins = { hour: [1], minute: [1], second: [1], millisecond: [1] };
      break;
    }
    default: {
      bins = {};
      break;
    }
  }

  let binning = {};
  outputTimeFormatTypes.forEach((type) => {
    binning[type] = [];
  });
  return { ...binning, ...bins };
}

const timeseriesLegendPositions = [
  { from: 'right', to: { position: 'right', alignment: 'middle' } },
  { from: 'left', to: { position: 'left', alignment: 'middle' } },
  { from: 'bottom', to: { position: 'bottom', alignment: 'middle' } },
  { from: 'top', to: { position: 'top', alignment: 'middle' } },
  { from: 'top-left', to: { position: 'left', alignment: 'start' } },
  { from: 'top-right', to: { position: 'right', alignment: 'start' } },
  { from: 'bottom-left', to: { position: 'left', alignment: 'end' } },
  { from: 'bottom-right', to: { position: 'right', alignment: 'end' } },
  { from: 'left-top', to: { position: 'top', alignment: 'start' } },
  { from: 'left-bottom', to: { position: 'bottom', alignment: 'start' } },
  { from: 'right-top', to: { position: 'top', alignment: 'end' } },
  { from: 'right-bottom', to: { position: 'bottom', alignment: 'end' } }
];

function convertTimeseriesLegendPosition(from?: string): {
  position?: string;
  alignment?: string;
} {
  const find = timeseriesLegendPositions.find((row) => row.from === from);
  if (find) {
    return find.to;
  }
  return { alignment: 'middle' };
}

function convertTimeseriesLineStyle(type?: LineType): {
  [type: string]: any;
} {
  switch (type) {
    case 'normal': {
      return {
        plot: {
          'stroke-width': 2
        },
        'plot.null': {
          'stroke-width': 2,
          'stroke-dasharray': 'none'
        }
      };
    }
    case 'thick': {
      return {
        plot: {
          'stroke-width': 4
        },
        'plot.null': {
          'stroke-width': 4,
          'stroke-dasharray': 'none'
        }
      };
    }
    case 'dashed': {
      return {
        plot: {
          'stroke-width': 2,
          'stroke-dasharray': '4 4'
        },
        'plot.null': {
          'stroke-width': 2,
          'stroke-dasharray': '4 4'
        }
      };
    }
    default: {
      return {
        plot: {
          'stroke-width': 2
        },
        'plot.null': {
          'stroke-width': 2,
          'stroke-dasharray': 'none'
        }
      };
    }
  }
}

function setTimeseriesViewChartData(
  chartData: ChartData,
  settings: Chart,
  sortedLegends: string[],
  saveParts?: Partial<ChartData['dataSource']>
): ChartData {
  const {
    color: { color, colors },
    view: { lineTypes }
  } = settings;
  const fcSettings = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure;
  const { view } = fcSettings;
  const defaultColors = getColors(settings.color.palette);
  const showToolTip = getShowToolTip(settings.view.tooltip);
  const outputTimeFormat = getOutputTimeFormat(settings);
  const xColname = 'x' in fcSettings.chart ? fcSettings.chart.x.value : '';

  // パーセント変換
  const { dataSource } = chartData;
  const { timeseries } = dataSource;
  const y2SeriesNames = saveParts?.y2SeriesNames || {};
  if (timeseries != undefined) {
    let targetIndexes: number[] = [];
    if (Object.keys(y2SeriesNames).length > 0) {
      // 複数軸
      if (view.showPercentage) {
        targetIndexes = timeseries.schema
          .map((row, i) => {
            if (!y2SeriesNames[row.name]) {
              return i;
            }
            return -1;
          })
          .filter((index) => index !== -1);
      }
      if (view.y2ShowPercentage) {
        targetIndexes = [
          ...targetIndexes,
          ...timeseries.schema
            .map((row, i) => {
              if (y2SeriesNames[row.name]) {
                return i;
              }
              return -1;
            })
            .filter((index) => index !== -1)
        ];
      }
    } else {
      // 単軸
      if (view.showPercentage) {
        targetIndexes = [...Array(timeseries.schema.length - 1)].map(
          (_, i) => i + 1
        );
      }
    }
    timeseries.data = timeseries.data.map((row) => {
      return row.map((value, i) => {
        if (i === 0) {
          // 1列目は日付列なのでスキップ
          return value;
        }
        if (targetIndexes.includes(i)) {
          return value * 100;
        }
        return value;
      });
    });
    chartData.dataSource.timeseries = timeseries;
  }

  // サブタイトル
  chartData.dataSource = {
    ...chartData.dataSource,
    subcaption: {
      text: view.subCaption,
      position: 'center'
    }
  };

  // 色
  let newColor: string | null = null;
  let newColors = {};
  let palettecolors: string[] = [];
  if (
    [
      FusionChartType.Column,
      FusionChartType.Line,
      FusionChartType.Area
    ].includes(fcSettings.type)
  ) {
    newColor = color ?? defaultColors[0];
    palettecolors = [newColor];
  } else {
    newColors = generateColors(sortedLegends, colors, defaultColors);
    palettecolors = sortedLegends.map((legend) => newColors[legend]);
  }
  chartData.dataSource = {
    ...chartData.dataSource,
    chart: {
      ...chartData.dataSource.chart,
      palettecolors
    }
  };

  const newLineTypes = getLineTypes(fcSettings.type, lineTypes, sortedLegends);
  chartData.dataSource.yAxis[0] = {
    ...chartData.dataSource.yAxis[0],
    // 軸
    title: view.yAxisName,
    max: view.stack100Percent ? '101' : view.yAxisMaxValue, // 101にしたのは、100だとデータが範囲内に収まってないとみなされ120まで軸が表示されてしまうため
    min: view.yAxisMinValue,
    format: {
      prefix: `${view.numberPrefix || ''}          `,
      suffix: view.stack100Percent ? '%' : view.numberSuffix,
      round: view.decimals,
      defaultFormat: view.formatNumberScale === '1'
    },
    // プロットの形
    plot: chartData.dataSource.yAxis[0].plot.map((plot) => {
      const lineType = newLineTypes && newLineTypes[plot.value];
      const linePlot = convertTimeseriesLineStyle(lineType);
      return {
        ...plot,
        connectnulldata: view.showNullData,
        style: linePlot
      };
    })
  };
  chartData.dataSource.xAxis = {
    outputTimeFormat
  };

  if (
    [
      FusionChartType.DualAxisColumnLine,
      FusionChartType.DualAxisColumnLineStacked,
      FusionChartType.MultiAxisLine
    ].includes(fcSettings.type)
  ) {
    chartData.dataSource.yAxis[1] = {
      ...chartData.dataSource.yAxis[1],
      // 軸
      title: view.y2AxisName,
      max: view.y2AxisMaxValue,
      min: view.y2AxisMinValue,
      format: {
        prefix: view.y2NumberPrefix,
        suffix: `${
          view.stack100Percent ? '%' : view.y2NumberSuffix || ''
        }          `,
        round: view.y2Decimals,
        defaultFormat: view.y2FormatNumberScale === '1'
      },
      // プロットの形
      plot: chartData.dataSource.yAxis[1].plot.map((plot) => {
        const lineType = newLineTypes && newLineTypes[plot.value];
        const linePlot = convertTimeseriesLineStyle(lineType);
        return {
          ...plot,
          connectnulldata: view.showNullData,
          style: linePlot
        };
      })
    };
  }

  // 凡例
  const oneLegend = [
    FusionChartType.Line,
    FusionChartType.Column,
    FusionChartType.Area
  ].includes(fcSettings.type);
  chartData.dataSource.legend = {
    ...chartData.dataSource.legend,
    enabled: oneLegend ? 0 : view.showLegend,
    ...convertTimeseriesLegendPosition(view.legendPosition)
  };

  // ツールチップ
  chartData.dataSource.tooltip = {
    enabled: showToolTip,
    outputTimeFormat
  };
  if (
    ![FusionChartType.StackedColumn, FusionChartType.StackedArea].includes(
      fcSettings.type
    )
  ) {
    chartData.dataSource.tooltip = {
      ...chartData.dataSource.tooltip,
      toolText: `
      <div style="display: block;font-size: 14px;color: ${themeColor};">
        ${sortedLegends.map((legend, i) => {
          const customs: string[] = [];
          if (view.tooltip?.showLegend) {
            customs.push(`凡例: ${legend}`);
          }
          if (view.tooltip?.showXAxis) {
            customs.push(`${xColname}: $binStart`);
          }
          if (view.tooltip?.showYAxis) {
            const label = y2SeriesNames[legend]
              ? view.y2AxisName
              : view.yAxisName;
            customs.push(`${label}: $series.${i}.dataValue`);
          }
          return `
            <div style="display: flex;">
              <div style="display:inline-block;margin-right:5px;height:14px;width:14px;background-color:${
                newColors[legend]
              };">
              </div>
              <div style="display:inline-block">
                ${customs.join('<br>')}
              </div>
            </div>`;
        })}
      </div>`
    };
  }

  // スライダー
  chartData = {
    ...chartData,
    dataSource: {
      ...chartData.dataSource,
      navigator: {
        enabled: view.showSlider ? 1 : 0
      }
    }
  };

  chartData = {
    ...chartData,
    dataSource: {
      ...chartData.dataSource,
      xAxis: {
        ...chartData.dataSource.xAxis,
        binning: getXaxisBinning(settings)
      }
    }
  };

  // テーブル保存用
  chartData = {
    ...chartData,
    dataSource: {
      ...chartData.dataSource,
      ...saveParts,
      color: newColor,
      colors: newColors,
      lineTypes: newLineTypes
    }
  };

  // 固定
  chartData = {
    ...chartData,
    dataSource: {
      ...chartData.dataSource,
      extensions: {
        customRangeSelector: {
          enabled: '0'
        },
        standardRangeSelector: {
          enabled: '0'
        }
      }
    }
  };
  // 固定 font, color
  const style = {
    fill: themeColor,
    'font-family': fontFamily
  };
  chartData.dataSource.subcaption = {
    ...chartData.dataSource.subcaption,
    style: { text: style }
  };
  chartData.dataSource.xAxis = {
    ...chartData.dataSource.xAxis,
    style: {
      label: style,
      'label-major': style,
      'label-minor': style,
      'label-context': style
    }
  };
  chartData.dataSource.yAxis = chartData.dataSource.yAxis.map((row) => {
    return {
      ...row,
      style: {
        title: style,
        label: style
      }
    };
  });
  chartData.dataSource.tooltip = {
    ...chartData.dataSource.tooltip,
    style: {
      container: style,
      text: style,
      header: style,
      body: style
    }
  };
  return chartData;
}

function generateTimeseriesChartData(
  settings: Chart,
  data: TimeseriesData
): ChartData {
  const {
    view: { y2SeriesNames, stack100Percent }
  } = settings;
  const useType = getUseFusionChartType(settings);

  const fcSettings = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure;
  switch (fcSettings.type) {
    case FusionChartType.Column:
    case FusionChartType.Line:
    case FusionChartType.Area:
    case FusionChartType.MultipleSeriesColumn:
    case FusionChartType.MultipleSeriesLine:
    case FusionChartType.MultipleSeriesArea: {
      const sortedLegends = data.sortedLegends;
      const xColname = fcSettings.chart.x.value;
      let chartData = {
        type: FusionChartType.TimeSeries,
        dataSource: {
          chart: {},
          yAxis: [
            {
              plot: sortedLegends.map((legend) => {
                return {
                  value: legend,
                  type: data.plottype
                };
              })
            }
          ],
          legend: {
            item: sortedLegends.map((legend) => {
              return {
                value: legend
              };
            })
          },
          timeseries: {
            schema: [
              {
                name: xColname,
                type: 'date',
                format: getTimeseriesFormat(settings) // dataの日付列をマッピングするために必要なもの
              },
              ...sortedLegends.map((legendValue) => {
                return {
                  name: legendValue,
                  type: 'number'
                };
              })
            ],
            data: data.pivotData.map((row) => {
              return [
                row[xColname],
                ...sortedLegends.map((legendValue) => row[legendValue])
              ];
            })
          }
        }
      } as ChartData;

      const newY2SeriesNames = generateY2SeriesNames(
        y2SeriesNames,
        sortedLegends
      );
      chartData = setTimeseriesViewChartData(
        chartData,
        settings,
        sortedLegends,
        { y2SeriesNames: newY2SeriesNames }
      );
      return chartData;
    }

    case FusionChartType.StackedArea:
    case FusionChartType.StackedColumn: {
      const sortedLegends = data.sortedLegends;
      const xColname = fcSettings.chart.x.value;
      const valueColname = 'value';
      const legendColname = 'variable';

      let pivotData = data.pivotData;
      if (stack100Percent) {
        // 100%積み上げ
        pivotData = pivotData.map((row) => {
          const numColnames = Object.keys(row).filter(
            (key) => key !== xColname
          );
          const total = lodashSum(numColnames.map((colname) => row[colname]));
          let newRow = { [xColname]: row[xColname] };
          numColnames.forEach((colname) => {
            newRow[colname] = (row[colname] / total) * 100;
          });
          return newRow;
        });
      }
      let pivot = new DataForge.DataFrame(pivotData);

      const colnames = pivot
        .getColumns()
        .toArray()
        .map((col) => col.name);
      const melt = pivot
        .melt(
          [xColname],
          colnames.filter((name) => name !== xColname)
        )
        .orderBy((row: { [colname: string]: any }) => {
          const legendValue = row[legendColname];
          const index = sortedLegends.findIndex(
            (legend) => legend === legendValue
          );
          return index;
        });
      let chartData = {
        type: useType,
        dataSource: {
          chart: {},
          series: legendColname,
          yAxis: [
            {
              plot: [
                {
                  value: valueColname,
                  type: data.plottype
                }
              ]
            }
          ],
          timeseries: {
            data: melt.toArray().map((row: { [colname: string]: any }) => {
              return [row[xColname], row[legendColname], row[valueColname]];
            }),
            schema: [
              {
                name: xColname,
                type: 'date',
                format: getTimeseriesFormat(settings)
              },
              {
                name: legendColname,
                type: 'string'
              },
              {
                name: valueColname,
                type: 'number'
              }
            ]
          }
        }
      } as ChartData;

      chartData = setTimeseriesViewChartData(
        chartData,
        settings,
        sortedLegends
      );
      return chartData;
    }

    case FusionChartType.MultiAxisLine: {
      const sortedLegends = data.sortedLegends;
      const xColname = fcSettings.chart.x.value;
      const newY2SeriesNames = generateY2SeriesNames(
        y2SeriesNames,
        sortedLegends
      );
      let chartData = {
        type: FusionChartType.TimeSeries,
        dataSource: {
          chart: { multicanvas: false },
          yAxis: [
            {
              plot: sortedLegends
                .filter((legend) => !newY2SeriesNames[legend])
                .map((legend) => {
                  return {
                    value: legend,
                    type: data.plottype
                  };
                })
            },
            {
              plot: sortedLegends
                .filter((legend) => newY2SeriesNames[legend])
                .map((legend) => {
                  return {
                    value: legend,
                    type: data.plottype
                  };
                })
            }
          ],
          timeseries: {
            schema: [
              {
                name: xColname,
                type: 'date',
                format: getTimeseriesFormat(settings)
              },
              ...sortedLegends.map((legendValue) => {
                return {
                  name: legendValue,
                  type: 'number'
                };
              })
            ],
            data: data.pivotData.map((row) => {
              return [
                row[xColname],
                ...sortedLegends.map((legendValue) => row[legendValue])
              ];
            })
          }
        }
      } as ChartData;

      chartData = setTimeseriesViewChartData(
        chartData,
        settings,
        sortedLegends,
        { y2SeriesNames: newY2SeriesNames }
      );
      return chartData;
    }
  }
  return defaultChartData;
}

function generateTimeseriesDualChartData(
  settings: Chart,
  _data: (TransformedData | undefined)[]
): ChartData {
  // dごとでデータの数が違うとjoinできないので横軸をuniqueにする
  const fcSettings = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure;
  const xColname = 'x' in fcSettings.chart ? fcSettings.chart.x.value : '';
  let alignedDFs: DataForge.IDataFrame[] = [];
  // 棒、折れ線の順に並び替え
  const data = _data.sort((a, b) => {
    if (a == null || b == null) {
      return 0;
    }
    if (a.type !== FusionChartType.TimeSeries) {
      return 0;
    }
    return a.plottype === TimeseriesPlottype.column ? -1 : 1;
  });
  data.forEach((d) => {
    if (d == null) {
      return;
    }
    if (d.type === FusionChartType.TimeSeries) {
      const pivot = new DataForge.DataFrame(d.pivotData);
      const aligned = pivot
        .groupBy((row) => row[xColname])
        .select((group) => {
          let row = group.first();
          // 棒と折れ線で同じ列名の可能性があるのでprefixをつける
          let col = {};
          Object.keys(row).forEach((key) => {
            const prefix =
              d.plottype === TimeseriesPlottype.column ? '縦棒' : '折れ線';
            if (key !== xColname) {
              col[`${prefix}_${key}`] = row[key];
              return;
            }
            col[key] = row[key];
          });
          return col;
        })
        .inflate();
      alignedDFs.push(aligned);
    }
  });
  const joind = alignedDFs[0]
    .join(
      alignedDFs[1],
      (left) => left[xColname],
      (right) => right[xColname],
      (left, right) => {
        return { ...left, ...right };
      }
    )
    .toArray();

  const sortedLegendPlottypes = data.flatMap((d) => {
    if (d == null) {
      return [];
    }
    if (d.type === FusionChartType.TimeSeries) {
      const sortedLegends = d.sortedLegends == null ? [] : d.sortedLegends;
      // 棒と折れ線で同じ列名の可能性があるのでprefixをつける
      const prefix =
        d.plottype === TimeseriesPlottype.column ? '縦棒' : '折れ線';
      return sortedLegends.map((legend) => {
        return {
          legend: `${prefix}_${legend}`,
          plottype: d.plottype
        };
      });
    }
    return [];
  });
  const sortedLegends = sortedLegendPlottypes.map((row) => row.legend);
  const lineLegends = sortedLegendPlottypes
    .filter((row) => row.plottype === TimeseriesPlottype.line)
    .map((row) => row.legend);
  const newY2SeriesNames: Y2SeriesNames = {};
  lineLegends.forEach((s) => {
    newY2SeriesNames[s] = true;
  });

  let chartData = {
    type: FusionChartType.TimeSeries,
    dataSource: {
      chart: { multicanvas: false },
      yAxis: [
        {
          plot: sortedLegendPlottypes
            .filter((row) => row.plottype === TimeseriesPlottype.column)
            .map((row) => {
              return {
                value: row.legend,
                type: row.plottype
              };
            })
        },
        {
          plot: sortedLegendPlottypes
            .filter((row) => row.plottype === TimeseriesPlottype.line)
            .map((row) => {
              return {
                value: row.legend,
                type: row.plottype
              };
            })
        }
      ],
      timeseries: {
        schema: [
          {
            name: xColname,
            type: 'date',
            format: getTimeseriesFormat(settings)
          },
          ...sortedLegends.map((legendValue) => {
            return {
              name: legendValue,
              type: 'number'
            };
          })
        ],
        data: joind.map((row) => {
          return [
            row[xColname],
            ...sortedLegends.map((legendValue) => row[legendValue])
          ];
        })
      }
    }
  } as ChartData;

  chartData = setTimeseriesViewChartData(chartData, settings, sortedLegends, {
    y2SeriesNames: newY2SeriesNames
  });
  return chartData;
}

export function generateFusionChartData(
  settings: Chart,
  data?: TransformedData | (TransformedData | undefined)[]
): ChartData {
  const {
    color: { color, colors },
    view: { dataPoints, lineTypes, y2SeriesNames }
  } = settings;
  if (data == undefined) {
    return defaultChartData;
  }

  // dual~のように複数のデータを持つ場合
  if (Array.isArray(data)) {
    if (data.length === 2 && data[0]?.type === FusionChartType.TimeSeries) {
      return generateTimeseriesDualChartData(settings, data);
    } else {
      return generateDualFusionChartData(data, settings);
    }
  }

  const showToolTip = getShowToolTip(settings.view.tooltip);
  const plotToolText = generateTooltipText(settings.view, data);
  const defaultColors = getColors(settings.color.palette);
  const connectNullData = getConnectNullData(settings.view.showNullData);

  const useType = getUseFusionChartType(settings);
  if (data.type === FusionChartType.TimeSeries) {
    return generateTimeseriesChartData(settings, data);
  }

  switch (data.type) {
    case FusionChartType.Bar:
    case FusionChartType.Column:
    case FusionChartType.Line:
    case FusionChartType.Area:
    case FusionChartType.Histogram: {
      const newColor = color ? color : defaultColors[0];
      const dataPointLabel = getDataPointLabel(settings);
      const newDataPoints = getDataPoints(useType, dataPoints, dataPointLabel);
      const newDataPointsChartProps = newDataPoints
        ? dataPointTypeToFusionChartProps(Object.values(newDataPoints)[0])
        : {};
      const newLineTypes = getLineTypes(useType, lineTypes, dataPointLabel);
      const newLineTypesChartProps = newLineTypes
        ? lineTypeToFusionChartProps(Object.values(newLineTypes)[0])
        : {};
      return {
        type: useType,
        dataSource: {
          data: data.data,
          chart: {
            theme: ChartTheme.Fusion,
            paletteColors: color ? color : defaultColors[0],
            showToolTip,
            plotToolText,
            ...newDataPointsChartProps,
            ...newLineTypesChartProps,
            drawCustomLegendIcon: '0',
            connectNullData,
            plotSpacePercent:
              data.type === FusionChartType.Histogram ? 0 : undefined
          },
          color: newColor,
          colors: null,
          dataPoints: newDataPoints,
          lineTypes: newLineTypes
        }
      };
    }
    case FusionChartType.MultipleSeriesBar:
    case FusionChartType.MultipleSeriesColumn:
    case FusionChartType.MultipleSeriesLine:
    case FusionChartType.StackedBar:
    case FusionChartType.StackedColumn:
    case FusionChartType.MultipleSeriesArea:
    case FusionChartType.StackedArea:
    case FusionChartType.Radar:
    case FusionChartType.MultipleHistogram: {
      const seriesnames = data.dataset.map((d) => {
        return d.seriesname;
      });
      // seriesnameによって変わる属性を更新する
      const newColors = generateColors(seriesnames, colors, defaultColors);
      const newDataPoints = getDataPoints(useType, dataPoints, seriesnames);
      const newLineTypes = getLineTypes(useType, lineTypes, seriesnames);
      const newY2SeriesNames = generateY2SeriesNames(
        y2SeriesNames,
        seriesnames
      );

      const newAlpha = getAlpha(useType, settings.color.alpha);

      const newMultipleDataset = generateDataset(
        data.dataset,
        newColors,
        newDataPoints,
        newLineTypes,
        newAlpha
      );

      return {
        type: useType,
        dataSource: {
          chart: {
            theme: ChartTheme.Fusion,
            showToolTip,
            plotToolText,
            drawCustomLegendIcon: '0',
            connectNullData
          },
          categories: data.categories,
          dataset: newMultipleDataset,
          colors: newColors,
          color: null,
          dataPoints: newDataPoints,
          lineTypes: newLineTypes,
          y2SeriesNames: newY2SeriesNames
        }
      };
    }
    case FusionChartType.BoxAndWhisker: {
      if (data.dataset.length === 1) {
        // single
        // dataごとに色を指定
        const seriesnames = data.categories[0].category.map((c) => c.label);
        const newColors = generateColors(seriesnames, colors, defaultColors);
        const newSingleData = data.dataset[0].data.map((d, i) => {
          const seriesname = data.categories[0].category[i].label;
          const newColor = newColors[seriesname];
          return {
            ...d,
            lowerboxcolor: newColor,
            upperboxcolor: newColor
          };
        });
        return {
          type: useType,
          dataSource: {
            chart: {
              theme: ChartTheme.Fusion,
              showToolTip,
              plotToolText
            },
            categories: data.categories,
            dataset: [{ data: newSingleData }],
            colors: newColors,
            color: null
          }
        };
      } else {
        // multiple
        // datasetごとに色を指定
        const seriesnames = data.dataset.map((d) => d.seriesname);
        const newColors = generateColors(seriesnames, colors, defaultColors);
        const newMultipleDataset = data.dataset.map((d) => {
          const newColor = newColors[d.seriesname];
          return {
            ...d,
            lowerboxcolor: newColor,
            upperboxcolor: newColor
          };
        });
        return {
          type: useType,
          dataSource: {
            chart: {
              theme: ChartTheme.Fusion,
              showToolTip,
              plotToolText
            },
            categories: data.categories,
            dataset: newMultipleDataset,
            colors: newColors,
            color: null
          }
        };
      }
    }
    case FusionChartType.Scatter: {
      if (data.dataset.length === 1) {
        const newScatterColor = color ? color : defaultColors[0];
        const dataPointLabel = getDataPointLabel(settings);
        const newDataPoints = getDataPoints(
          useType,
          dataPoints,
          dataPointLabel
        );
        const newDataPointsChartProps = newDataPoints
          ? dataPointTypeToFusionChartProps(
              Object.values(newDataPoints)[0],
              true
            )
          : {};
        return {
          type: useType,
          dataSource: {
            chart: {
              theme: ChartTheme.Fusion,
              showToolTip,
              plotToolText,
              drawLines: settings.view.showConnectingLines
            },
            dataset: data.dataset.map((d) => {
              return {
                ...d,
                anchorbgcolor: newScatterColor,
                anchorBorderColor: newScatterColor,
                lineColor: newScatterColor,
                ...newDataPointsChartProps,
                showRegressionLine: settings.view.showRegressionLine ? '1' : '0'
              };
            }),
            color: newScatterColor,
            colors: null,
            dataPoints: newDataPoints,
            ...addRegressionEquation(
              settings.view.showRegressionLine,
              data.dataset[0],
              newScatterColor
            )
          }
        };
      }
      const seriesnames = data.dataset
        .map((d) => d?.seriesname)
        .filter((seriesname): seriesname is string => seriesname != undefined);
      const newColors = generateColors(seriesnames, colors, defaultColors);
      const newDataPoints = getDataPoints(useType, dataPoints, seriesnames);
      const newDataset = data.dataset.map((d) => {
        const newColor =
          d.seriesname == undefined ? undefined : newColors[d.seriesname];
        const newDataPointsChartProps =
          newDataPoints && d.seriesname && newDataPoints[d.seriesname]
            ? dataPointTypeToFusionChartProps(newDataPoints[d.seriesname], true)
            : {};
        return {
          ...d,
          anchorbgcolor: newColor,
          anchorBorderColor: newColor,
          lineColor: newColor,
          ...newDataPointsChartProps,
          showRegressionLine: settings.view.showRegressionLine ? '1' : '0'
        };
      });
      return {
        type: useType,
        dataSource: {
          chart: {
            theme: ChartTheme.Fusion,
            showToolTip,
            plotToolText,
            drawLines: settings.view.showConnectingLines
          },
          dataset: newDataset,
          colors: newColors,
          color: null,
          dataPoints: newDataPoints,
          ...addRegressionEquations(
            settings.view.showRegressionLine,
            newDataset,
            newColors
          )
        }
      };
    }
    case FusionChartType.Pie: {
      const seriesnames = data.data.map((d) => d.label);
      const newColors = generateColors(seriesnames, colors, defaultColors);
      const newPieData = data.data.map((d) => {
        return { ...d, color: newColors[d.label] };
      });
      return {
        type: useType,
        dataSource: {
          chart: {
            theme: ChartTheme.Fusion,
            showToolTip,
            plotToolText,
            showLegend: 1,
            startingAngle: 90,
            yAxisName:
              settings.type === ChartType.pie
                ? getValueLabel(
                    settings.chart.aggregated || false,
                    settings.chart.value
                  )
                : null
          },
          data: newPieData,
          colors: newColors,
          color: null
        }
      };
    }
    case FusionChartType.MultiAxisLine: {
      const seriesnames = data.dataset.map((d) => {
        return d.seriesname;
      });
      // seriesnameによって変わる属性を更新する
      const newColors = generateColors(seriesnames, colors, defaultColors);
      const newDataPoints = getDataPoints(useType, dataPoints, seriesnames);
      const newLineTypes = getLineTypes(useType, lineTypes, seriesnames);
      const newY2SeriesNames = generateY2SeriesNames(
        y2SeriesNames,
        seriesnames
      );

      const newAlpha = getAlpha(useType, settings.color.alpha);

      // 軸ごとにdataset作る
      const newMultipleDataset = generateDataset(
        data.dataset.filter((d) => !newY2SeriesNames[d.seriesname]),
        newColors,
        newDataPoints,
        newLineTypes,
        newAlpha
      );

      const newMultipleDatasetY2 = generateDataset(
        data.dataset.filter((d) => newY2SeriesNames[d.seriesname]),
        newColors,
        newDataPoints,
        newLineTypes,
        newAlpha
      );

      const {
        yAxisName,
        yAxisMaxValue,
        yAxisMinValue,
        numberPrefix,
        numberSuffix,
        decimals,
        formatNumberScale,
        y2AxisName,
        y2AxisMaxValue,
        y2AxisMinValue,
        y2NumberPrefix,
        y2NumberSuffix,
        y2Decimals,
        y2FormatNumberScale
      } = settings.view;
      return {
        type: useType,
        dataSource: {
          chart: {
            theme: ChartTheme.Fusion,
            plotToolText,
            drawCustomLegendIcon: '0',
            axisColor: '#999999',
            connectNullData
          },
          categories: data.categories,
          colors: newColors,
          color: null,
          dataPoints: newDataPoints,
          lineTypes: newLineTypes,
          y2SeriesNames: newY2SeriesNames,
          axis: [
            // 縦軸
            {
              dataset: newMultipleDataset,
              title: yAxisName,
              maxValue: yAxisMaxValue,
              minValue: yAxisMinValue,
              numberPrefix: numberPrefix,
              numberSuffix: numberSuffix,
              decimals: decimals,
              formatNumberScale: formatNumberScale
            },
            // 縦軸2
            newMultipleDatasetY2.length > 0
              ? {
                  axisOnLeft: 0,
                  dataset: newMultipleDatasetY2,
                  title: y2AxisName,
                  maxValue: y2AxisMaxValue !== undefined ? y2AxisMaxValue : '',
                  minValue: y2AxisMinValue !== undefined ? y2AxisMinValue : '',
                  numberPrefix:
                    y2NumberPrefix !== undefined ? y2NumberPrefix : '',
                  numberSuffix: !y2NumberSuffix ? ' ' : y2NumberSuffix, // 空文字だともう片方の縦軸のnumberSuffixが適用されてしまうので
                  decimals: y2Decimals,
                  formatNumberScale:
                    y2FormatNumberScale !== undefined ? y2FormatNumberScale : ''
                }
              : { axisOnLeft: 0 }
          ]
        }
      };
    }
  }
}

function getBoxOrderedLegends(
  dataFrame: DataForge.IDataFrame,
  legend: Option,
  order: OrderSetting
): string[] {
  if (order.legend.target === 'specific') {
    // 「他の列」を選択
    // 凡例と順番のカラム（orderValueName）のdfを作成
    const legendOrderDf = dataFrame
      .groupBy((row) => row[legend.value])
      .select((group) =>
        aggregateByLegend(
          group,
          getValueName(true, order.legend.column),
          legend.value,
          order.legend.column?.func
        )
      )
      .inflate();
    // 凡例の並び替え
    return order.legend.method === 'asc'
      ? legendOrderDf
          .orderBy((row) => row[orderValueName] ?? -Infinity)
          .getSeries(legend.value)
          .toArray()
      : legendOrderDf
          .orderByDescending((row) => row[orderValueName] ?? -Infinity)
          .getSeries(legend.value)
          .toArray();
  } else {
    // 「凡例に設定した列」を選択
    let legends = dataFrame
      .distinct((row) => row[legend.value])
      .toArray()
      .map((row) => {
        return String(row[legend.value]);
      })
      // 凡例の並び替え
      .sort((a, b) =>
        a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
    if (order.legend.method === 'desc') {
      legends = legends.reverse();
    }
    return legends;
  }
}

function transformMultipleWithMultiY(
  configure: FusionChartCrossTableConfigure,
  settings: Chart,
  dataFrame: DataForge.IDataFrame
): TransformedData | undefined {
  const { chart: grouped } = configure;
  if (configure.type === FusionChartType.Scatter) {
    return scatterTransformMultipleWithMultiY(dataFrame, configure);
  }

  // valueNames: yの列名 -> ここでは判例の値を意味する
  const valueNames = configure.chart.y.map((value) =>
    getValueName(grouped.aggregated, value)
  );

  // 横軸orderColumnNameを設定した基準で並び替える
  const sortedDF = sortX(configure, dataFrame, valueNames);

  const categories = generateCategories(sortedDF, configure, settings);

  const orders = sortLegend(configure, dataFrame, valueNames);

  // 凡例（y軸に設定した列たち）の並び
  const yOrderIndexies = getYOrderIndexies(orders, valueNames);
  const useType = getUseFusionChartType(settings);
  if (useType === FusionChartType.TimeSeries) {
    const legends = yOrderIndexies.map((index) =>
      getValueName(grouped.aggregated, grouped.y[index])
    );
    const sortedLegends = sortLegendOnTimeseriesMultiAxis(
      legends,
      settings.view.y2SeriesNames
    );

    return {
      type: useType,
      pivotData: sortedDF.toArray(),
      sortedLegends,
      plottype: getTimeseriesPlotType(settings)
    };
  }

  const dataset = yOrderIndexies.map((index) => {
    const valueName = getValueName(grouped.aggregated, grouped.y[index]);
    return {
      seriesname: getValueLabel(grouped.aggregated, grouped.y[index]),
      data: sortedDF.toArray().map((row) => {
        return { value: row[valueName] };
      })
    };
  });

  return {
    type: configure.type,
    categories,
    dataset
  };
}

// multiple seriesのデータ変形
function transformMultiple(
  configure: FusionChartCrossTableConfigure,
  settings: Chart,
  dataFrame: DataForge.IDataFrame
): TransformedData | undefined {
  // single value
  if (configure.chart.y.length === 1) {
    // 凡例を設定した場合
    return transformMultipleWithLegend(configure, settings, dataFrame);
  } else {
    // yを複数指定した場合
    return transformMultipleWithMultiY(configure, settings, dataFrame);
  }
}

function transformSingleOrderdDF(
  configure:
    | GenericsChartSettings<FusionChartType.Bar, Bar>
    | GenericsChartSettings<FusionChartType.Column, Column>
    | GenericsChartSettings<FusionChartType.Line, Line>
    | GenericsChartSettings<FusionChartType.Area, Area>
    | GenericsChartSettings<FusionChartType.Radar, Radar>,
  dataFrame: DataForge.IDataFrame
): DataForge.IDataFrame {
  const { chart: simple } = configure;

  const orderName = getOrderColumnName(configure, simple.order.x);
  const orderMethod = simple.order.x.method;
  const orderColumnType =
    simple.order.x.target === 'y'
      ? 'number'
      : simple.order.x.column?.column?.dtype;
  const orderedDF =
    orderMethod === 'asc'
      ? dataFrame.orderBy(
          (row) => row[orderName] ?? nullValueByDType(orderColumnType)
        )
      : dataFrame.orderByDescending(
          (row) => row[orderName] ?? nullValueByDType(orderColumnType)
        );
  return orderedDF;
}

// fusionchartsのデータ形式に変換
function transformData(
  settings: Chart,
  dataFrame?: DataForge.DataFrame,
  isY2Axis?: boolean // 2軸目
): TransformedData | undefined {
  if (dataFrame == undefined) {
    return;
  }

  if (settings.type === ChartType.table) {
    return;
  }

  const updatedSettings = produce(settings, (draft) => {
    if (
      draft.type !== ChartType.dualAxisColumnLine &&
      draft.chart.order == undefined
    ) {
      draft.chart.order = getInitOrder();
    }

    if (draft.type === ChartType.scatter) {
      draft.chart.aggregated = true; // true固定
    }
    return draft;
  });

  const configure = {
    ...updatedSettings,
    type: getFusionChartType(updatedSettings) as FusionChartType
  } as FusionChartConfigure | NullableFusionChartConfigure;
  if (!isFusionChartConfigure(configure)) {
    return;
  }

  const dfToNullOutOfAxisRange = toNullOutOfAxisRange(
    configure,
    dataFrame,
    isY2Axis
  );
  const df = filterOverBinLimit(settings, dfToNullOutOfAxisRange);

  switch (configure.type) {
    case FusionChartType.Bar:
    case FusionChartType.Column:
    case FusionChartType.Line:
    case FusionChartType.Area: {
      const { chart: simple } = configure;

      const orderedDF = transformSingleOrderdDF(configure, df);

      const xName = simple.x.value;
      const yName = getValueName(
        configure.chart.aggregated,
        configure.chart.y[0]
      );

      const useType = getUseFusionChartType(settings);
      if (useType === FusionChartType.TimeSeries) {
        return {
          type: useType,
          pivotData: orderedDF.toArray(),
          sortedLegends: [yName],
          plottype: getTimeseriesPlotType(settings)
        };
      }
      return {
        type: configure.type,
        data: orderedDF.toArray().map((row) => {
          return {
            label: getXAxisDisplayValue(row[xName], settings),
            value: row[yName]
          };
        })
      };
    }
    case FusionChartType.MultipleSeriesBar:
    case FusionChartType.MultipleSeriesColumn:
    case FusionChartType.MultipleSeriesLine:
    case FusionChartType.StackedColumn:
    case FusionChartType.StackedBar:
    case FusionChartType.MultipleSeriesArea:
    case FusionChartType.StackedArea:
    case FusionChartType.MultiAxisLine: {
      return transformMultiple(configure, settings, df);
    }
    case FusionChartType.Radar: {
      // レーダーの場合はSeriesが一つでも複数でもFusionChartType.Radarのタイプを使用する
      const { chart } = configure;
      // 複数
      if (chart.y.length > 1 || chart.legend !== undefined) {
        return transformMultiple(configure, settings, df);
      }

      // 一つ
      const orderedDF = transformSingleOrderdDF(configure, df);
      const xName = chart.x.value;
      const yName = getValueName(chart.aggregated, chart.y[0]);
      return {
        type: configure.type,
        categories: [
          {
            category: orderedDF.toArray().map((row) => {
              return { label: String(row[xName]) };
            })
          }
        ],
        dataset: [
          {
            seriesname: '',
            data: orderedDF.toArray().map((row) => {
              return { value: row[yName] };
            })
          }
        ]
      };
    }

    case FusionChartType.Scatter: {
      if (
        configure.chart.y.length === 1 &&
        configure.chart.legend == undefined
      ) {
        return scatterTransformSingle(df, configure);
      }

      return transformMultiple(configure, settings, df);
    }

    case FusionChartType.Pie: {
      const { chart: pie } = configure;

      const valueName = getValueName(
        configure.chart.aggregated,
        configure.chart.value
      );

      const orderName = getOrderColumnName(configure, pie.order.legend);
      const orderDType = pie.order.legend.column?.column?.dtype;
      const orderMethod = pie.order.legend.method;
      const labelName = pie.legend.value;

      const orderedDF =
        orderMethod === 'asc'
          ? df.orderBy((row) => row[orderName] ?? nullValueByDType(orderDType))
          : df.orderByDescending(
              (row) => row[orderName] ?? nullValueByDType(orderDType)
            );
      const pieData = orderedDF
        .select((row) => ({
          [valueName]: row[valueName],
          [labelName]: row[labelName]
        }))
        .toArray()
        .map((row) => {
          return {
            value: row[valueName],
            label: getDisplayValue(row[labelName])
          };
        });
      return {
        type: configure.type,
        data: pieData
      };
    }
    case FusionChartType.BoxAndWhisker: {
      const {
        chart: { legend, order, y }
      } = configure;
      if (!legend) {
        // single
        // x軸：縦軸の列名1  縦軸の列名2  縦軸の列名3

        // 縦軸の列名取得
        const category = y.map((y) => {
          return { label: y.column!.value };
        });

        // data作成
        const data = y.map((y) => {
          const value = df.getSeries(y.column!.value).toArray().join(',');
          return { value };
        });

        const transformedData = {
          type: configure.type,
          categories: [{ category }],
          dataset: [{ data, seriesname: '' }]
        };
        return transformedData;
      }

      if (y.length === 1 && legend) {
        // single
        // x軸：凡例の値1  凡例の値2  凡例の値3

        // 凡例の値を取得
        const legends = getBoxOrderedLegends(df, legend, order);
        const category = legends.map((v) => {
          return { label: v };
        });

        // 凡例の値ごとのデータ配列を取得
        let valueByLegend = {};
        for (const group of df.groupBy((row) => row[legend.value])) {
          const legendValue = group.first()[legend.value];
          valueByLegend[legendValue] = group
            .getSeries(y[0].column!.value)
            .toArray()
            .join(',');
        }
        // legendsの並びごとのdata作成
        const data = legends.map((legend) => {
          return { value: valueByLegend[legend] };
        });

        const transformedData = {
          type: configure.type,
          categories: [{ category }],
          dataset: [{ data, seriesname: legend.value }]
        };
        return transformedData;
      }

      if (y.length > 1 && legend) {
        // multiple series
        // x軸：縦軸の列名1  縦軸の列名2  縦軸の列名3

        // 凡例の値を取得
        const legends = getBoxOrderedLegends(df, legend, order);

        // 凡例の値、縦軸の列名ごとのデータ配列を取得
        let valueByLegendY = {};
        for (const group of df.groupBy((row) => row[legend.value])) {
          const legendValue = group.first()[legend.value];
          valueByLegendY[legendValue] = [];
          y.map((yvalue) => {
            valueByLegendY[legendValue].push(
              group.getSeries(yvalue.column!.value).toArray().join(',')
            );
          });
        }

        // legendsの並びごとのdataset作成
        const dataset = legends.map((legend) => {
          return {
            seriesname: legend,
            data: valueByLegendY[legend].map((valueByY) => {
              return { value: valueByY };
            })
          };
        });

        // 縦軸の列名取得
        const category = y.map((y) => {
          return { label: y.column!.value };
        });

        const transformedData = {
          type: configure.type,
          categories: [{ category }],
          dataset
        };
        return transformedData;
      }
      return undefined;
    }
    case FusionChartType.Histogram: {
      const {
        chart: { y, numBuckets },
        view: { isRatio }
      } = configure;
      if (y[0].column == undefined) {
        return;
      }
      const beforeYcolname = y[0].column.value;
      let formattedDF = df.renameSeries({
        [`count_${beforeYcolname}`]: HISTO_Y_COLNAME,
        [`max_${beforeYcolname}`]: HISTO_Y_MAX_COLNAME,
        [`min_${beforeYcolname}`]: HISTO_Y_MIN_COLNAME
      });
      formattedDF = formattedDF.filter((row) => row.bucket !== null);
      if (isRatio) {
        formattedDF = convertRatio(formattedDF, HISTO_Y_COLNAME);
      }
      const bucketXmap = getBucketXlabelMap(
        formattedDF,
        HISTO_Y_MAX_COLNAME,
        HISTO_Y_MIN_COLNAME,
        numBuckets,
        settings
      );
      const transformed = transformHistogram(formattedDF, bucketXmap);
      return transformed;
    }
    case FusionChartType.MultipleHistogram: {
      const {
        chart: { legend, order, y, numBuckets },
        view: { isRatio }
      } = configure;

      if (y[0].column == undefined) {
        return;
      }
      let beforeYcolname = 'value';
      let beforeLegendColname = 'label';
      if (y.length === 1) {
        beforeYcolname = y[0].column.value;
        beforeLegendColname = legend.value;
      }
      let formattedDF = df.renameSeries({
        [`count_${beforeYcolname}`]: HISTO_Y_COLNAME,
        [`max_${beforeYcolname}`]: HISTO_Y_MAX_COLNAME,
        [`min_${beforeYcolname}`]: HISTO_Y_MIN_COLNAME,
        [beforeLegendColname]: HISTO_LEGEND_COLNAME
      });
      formattedDF = formattedDF.filter((row) => row.bucket !== null);
      if (isRatio) {
        formattedDF = convertRatio(
          formattedDF,
          HISTO_Y_COLNAME,
          HISTO_LEGEND_COLNAME
        );
      }
      const sortedLegends = sortHistogramLegends(
        formattedDF,
        order,
        HISTO_LEGEND_COLNAME,
        HISTO_Y_COLNAME
      );
      const bucketXmap = getBucketXlabelMap(
        formattedDF,
        HISTO_Y_MAX_COLNAME,
        HISTO_Y_MIN_COLNAME,
        numBuckets,
        settings
      );
      const transformed = transformMultipleHistogram(
        formattedDF,
        sortedLegends,
        bucketXmap
      );
      return transformed;
    }
  }
  return undefined;
}

function getOrderColumnName(
  configs: FusionChartConfigure,
  option: OrderOptions
): string {
  if (
    configs.type === FusionChartType.DualAxisColumnLine ||
    configs.type === FusionChartType.DualAxisColumnLineStacked ||
    configs.type === FusionChartType.Histogram ||
    configs.type === FusionChartType.MultipleHistogram
  ) {
    // 通らない
    return '';
  }
  if (configs.type === FusionChartType.Scatter) {
    if (option.target === 'specific') {
      return getValueName(true, option.column);
    }

    return option.target === 'x'
      ? configs.chart.x.value
      : getValueName(true, configs.chart.y[0]);
  }

  if (option.target == 'specific') {
    return getValueName(configs.chart.aggregated, option.column);
  }

  if (configs.type === FusionChartType.Pie) {
    return option.target === 'x'
      ? configs.chart.legend.value
      : getValueName(configs.chart.aggregated, configs.chart.value);
  }

  if (configs.type === FusionChartType.BoxAndWhisker) {
    if (option.target === 'specific') {
      // 他の列
      return getValueName(true, option.column);
    } else {
      // 凡例
      return configs.chart.legend!.value;
    }
  }

  return option.target === 'x'
    ? configs.chart.x.value
    : getValueName(configs.chart.aggregated, configs.chart.y[0]);
}

export function validateConfigure(settings: Chart): boolean {
  if (!validateColumns(settings)) {
    return false;
  }
  if (settings.type === ChartType.table) {
    const {
      chart: { columns }
    } = settings;
    return (
      columns != undefined &&
      columns.columns?.tableDisplayColumnsWithOption.length > 0
    );
  }
  const configure = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure | NullableFusionChartConfigure;
  return isFusionChartConfigure(configure);
}

function getYMinMaxValue(
  row: any,
  yName: string,
  configure: FusionChartConfigure,
  isMax: boolean,
  isY2Axis?: boolean
) {
  function _getYMinMaxValue(
    row: any,
    yName: string,
    configure: FusionChartConfigure,
    isMax: boolean,
    isY2Axis?: boolean
  ) {
    const yMinMax = isMax
      ? configure.view.yAxisMaxValue
      : configure.view.yAxisMinValue;
    const y2MinMax = isMax
      ? configure.view.y2AxisMaxValue
      : configure.view.y2AxisMinValue;

    if (isY2Axis) {
      return y2MinMax;
    }

    const y2Names = configure.view.y2SeriesNames || {};
    if (
      configure.type === FusionChartType.MultiAxisLine &&
      configure.chart.y.length === 1 &&
      'legend' in configure.chart &&
      configure.chart.legend
    ) {
      // 折れ線2軸かつ凡例ありの場合
      const legendName = configure.chart.legend.value;
      // 凡例の値がy2Namesに含まれている場合は2軸目
      if (y2Names[row[legendName]]) {
        return y2MinMax;
      } else {
        return yMinMax;
      }
    }

    return y2Names[yName] ? y2MinMax : yMinMax;
  }

  const minMaxValue = _getYMinMaxValue(row, yName, configure, isMax, isY2Axis);
  const infinity = isMax ? Infinity : -Infinity;
  return Number(
    minMaxValue != null && minMaxValue !== '' ? minMaxValue : infinity
  );
}

function getYMaxValue(
  row: any,
  yName: string,
  configure: FusionChartConfigure,
  isY2Axis?: boolean
) {
  return getYMinMaxValue(row, yName, configure, true, isY2Axis);
}

function getYMinValue(
  row: any,
  yName: string,
  configure: FusionChartConfigure,
  isY2Axis?: boolean
) {
  return getYMinMaxValue(row, yName, configure, false, isY2Axis);
}

function getXMaxValue(configure: FusionChartConfigure) {
  const maxValue = configure.view.xAxisMaxValue;
  return Number(maxValue != null && maxValue !== '' ? maxValue : Infinity);
}

function getXMinValue(configure: FusionChartConfigure) {
  const minValue = configure.view.xAxisMinValue;
  return Number(minValue != null && minValue !== '' ? minValue : -Infinity);
}

function getYValue(
  row: any,
  yName: string,
  configure: FusionChartConfigure,
  isY2Axis?: boolean
) {
  const { yShowPercentage, showPercentage, y2ShowPercentage, y2SeriesNames } =
    configure.view;

  function getShowPercentage(): boolean | undefined {
    if (isY2Axis) {
      return y2ShowPercentage;
    }

    if (configure.type === FusionChartType.Scatter) {
      return yShowPercentage;
    }

    const y2Names = y2SeriesNames || {};
    if (
      configure.type === FusionChartType.MultiAxisLine &&
      configure.chart.y.length === 1 &&
      'legend' in configure.chart &&
      configure.chart.legend
    ) {
      // 折れ線2軸かつ凡例ありの場合
      const legendName = configure.chart.legend.value;
      // 凡例の値がy2Namesに含まれている場合は2軸目
      if (y2Names[row[legendName]]) {
        return y2ShowPercentage;
      } else {
        return showPercentage;
      }
    }

    return y2Names[yName] ? y2ShowPercentage : showPercentage;
  }

  return row[yName] != null
    ? Number(row[yName]) * (getShowPercentage() ? 100 : 1)
    : row[yName];
}

function getXValue(value: any, configure: FusionChartConfigure) {
  function getShowPercentage(): boolean | undefined {
    const { xShowPercentage } = configure.view;

    if (configure.type === FusionChartType.Scatter) {
      return xShowPercentage;
    }

    return false;
  }

  return value != null
    ? Number(value) * (getShowPercentage() ? 100 : 1)
    : value;
}

// 軸の範囲外のデータをnullにする
function toNullOutOfAxisRange(
  configure: FusionChartConfigure,
  dataFrame: DataForge.IDataFrame,
  isY2Axis?: boolean // 2軸目
): DataForge.IDataFrame {
  if (!('y' in configure.chart) || !Array.isArray(configure.chart.y)) {
    return dataFrame;
  }

  let df = dataFrame;
  const yNames = configure.chart.y.map((y) => {
    const yName = getValueName(configure.chart.aggregated, y);
    return yName;
  });
  const xName =
    configure.type === FusionChartType.Scatter ? configure.chart.x.value : null;

  df = df.select((row) => {
    let newRow = { ...row };
    yNames.forEach((yName) => {
      if (configure.view.stack100Percent && !isY2Axis) {
        // 100%表示の時は無視する
        newRow[yName] = row[yName];
        return;
      }
      const yMax = getYMaxValue(row, yName, configure, isY2Axis);
      const yMin = getYMinValue(row, yName, configure, isY2Axis);
      const value = getYValue(row, yName, configure, isY2Axis);
      if (value < yMin || yMax < value) {
        newRow[yName] = null;
      } else {
        newRow[yName] = row[yName];
      }
    });
    if (xName != null) {
      const xMax = getXMaxValue(configure);
      const xMin = getXMinValue(configure);
      const value = getXValue(row[xName], configure);
      if (value < xMin || xMax < value) {
        newRow[xName] = null;
      } else {
        newRow[xName] = row[xName];
      }
    }
    return newRow;
  });
  return df;
}

function checkHasOutOfAxisRange(
  settings: Chart,
  dataFrame?: DataForge.DataFrame,
  isY2Axis?: boolean // 2軸目
): boolean {
  if (settings.type === ChartType.table) {
    return false;
  }
  if (dataFrame == undefined) {
    return false;
  }

  const configure = {
    ...settings,
    type: getFusionChartType(settings) as FusionChartType
  } as FusionChartConfigure | NullableFusionChartConfigure;
  if (!isFusionChartConfigure(configure)) {
    return false;
  }
  if (!('y' in configure.chart) || !Array.isArray(configure.chart.y)) {
    return false;
  }

  let hasOutOfAxisRange = false;
  const yNames = configure.chart.y.map((y) => {
    const yName = getValueName(configure.chart.aggregated, y);
    return yName;
  });
  const xName =
    configure.type === FusionChartType.Scatter ? configure.chart.x.value : null;

  dataFrame.toArray().forEach((row) => {
    if (hasOutOfAxisRange) return;
    yNames.forEach((yName) => {
      if (row[yName] == null) {
        // そもそも表示されないので対象外
        return;
      }
      if (configure.view.stack100Percent && !isY2Axis) {
        // 100%表示の時は無視する
        return;
      }
      const yMax = getYMaxValue(row, yName, configure, isY2Axis);
      const yMin = getYMinValue(row, yName, configure, isY2Axis);
      const value = getYValue(row, yName, configure, isY2Axis);
      if (value < yMin || yMax < value) {
        hasOutOfAxisRange = true;
        return;
      }
    });
    if (xName != null) {
      if (row[xName] == null || (yNames.length > 0 && row[yNames[0]] == null)) {
        // そもそも表示されないので対象外
        return;
      }
      const xMax = getXMaxValue(configure);
      const xMin = getXMinValue(configure);
      const value = getXValue(row[xName], configure);
      if (value < xMin || xMax < value) {
        hasOutOfAxisRange = true;
        return;
      }
    }
  });
  return hasOutOfAxisRange;
}

const timeseriesToDayjsFormatMap = {
  '%Y': 'YYYY',
  '%m': 'MM',
  '%d': 'DD',
  '%H': 'HH',
  '%M': 'mm',
  '%S': 'ss',
  '%L': 'SSS'
};

// timeseries型の場合の、時間軸（横軸）のbinの上限数
const X_BIN_LIMIT = 50000;

function toDayjs(
  value: any,
  dtype: string,
  formattype: Xtimeformattype | undefined,
  format: string | undefined
): Dayjs {
  const timeseriesFormat = _getTimeseriesFormat(formattype, format);
  let dayjsFormat = timeseriesFormat;
  // dayjsでparseするためにフォーマットを変換する
  Object.keys(timeseriesToDayjsFormatMap).forEach((from) => {
    dayjsFormat = String(dayjsFormat).replace(
      from,
      timeseriesToDayjsFormatMap[from]
    );
  });
  return ['date', 'timestamp'].includes(dtype)
    ? dayjs(value, dayjsFormat)
    : dayjs(`2021-01-01 ${value}`, `YYYY-MM-DD ${dayjsFormat}`);
}

function toDayjsUnit(
  formattype: Xtimeformattype,
  format: string | undefined
): ManipulateType {
  switch (formattype) {
    case Xtimeformattype.custom: {
      // カスタムの場合、最小単位のformatTypeにする
      if (format !== undefined) {
        const customFormattype = customFormats.find((row) =>
          format.includes(row.format)
        )?.type as ManipulateType;
        if (customFormattype != null) {
          return customFormattype;
        }
      }
      return 'millisecond';
    }
    case Xtimeformattype.year:
    case Xtimeformattype.month:
    case Xtimeformattype.day:
    case Xtimeformattype.hour:
    case Xtimeformattype.minute:
    case Xtimeformattype.second:
    case Xtimeformattype.millisecond:
      return formattype;
    case Xtimeformattype.time_hour:
      return 'hour';
    case Xtimeformattype.time_minute:
      return 'minute';
    case Xtimeformattype.time_second:
      return 'second';
    case Xtimeformattype.time_millisecond:
    case Xtimeformattype.time_microsecond:
      return 'millisecond';
    case Xtimeformattype.auto:
      return 'millisecond';
    default: {
      const ex: never = formattype;
      return ex;
    }
  }
}

function filterOverBinLimit(settings: Chart, df: DataForge.IDataFrame) {
  if (settings.type === ChartType.table) {
    return df;
  }
  const useType = getUseFusionChartType(settings);
  if (useType !== FusionChartType.TimeSeries) {
    return df;
  }
  if (!('x' in settings.chart)) {
    return df;
  }
  if (settings.chart.x?.value == null || settings.chart.x?.dtype == null) {
    return df;
  }
  if (
    !('xtimeformattype' in settings.chart) ||
    settings.chart.xtimeformattype == null
  ) {
    return df;
  }

  const xName = settings.chart.x?.value;
  const xValues = df.getSeries(xName).distinct().toArray();
  // 降順にソート
  xValues.sort((a, b) => {
    return a < b ? 1 : -1;
  });
  const dtype = settings.chart.x.dtype;
  const formattype = settings.chart?.xtimeformattype;
  const format = settings.chart?.xtimeformat;
  const xMax = toDayjs(xValues[0], dtype, formattype, format);
  const xMin = xMax.add(-X_BIN_LIMIT, toDayjsUnit(formattype, format));

  const filterdDf = df.filter(
    (row) => toDayjs(row[xName], dtype, formattype, format) >= xMin
  );
  return filterdDf;
}

function checkIsOverBinLimit(
  settings: Chart,
  df?: DataForge.DataFrame
): boolean {
  if (settings.type === ChartType.table) {
    return false;
  }
  if (df == undefined) {
    return false;
  }
  const useType = getUseFusionChartType(settings);
  if (useType !== FusionChartType.TimeSeries) {
    return false;
  }
  if (!('x' in settings.chart)) {
    return false;
  }
  if (settings.chart.x?.value == null || settings.chart.x?.dtype == null) {
    return false;
  }
  if (
    !('xtimeformattype' in settings.chart) ||
    settings.chart.xtimeformattype == null
  ) {
    return false;
  }

  const xName = settings.chart.x?.value;
  const xValues = df.getSeries(xName).distinct().toArray();
  // 降順にソート
  xValues.sort((a, b) => {
    return a < b ? 1 : -1;
  });
  const dtype = settings.chart.x.dtype;
  const formattype = settings.chart?.xtimeformattype;
  const format = settings.chart?.xtimeformat;
  const xMax = toDayjs(xValues[0], dtype, formattype, format);
  const xMin = xMax.add(-X_BIN_LIMIT, toDayjsUnit(formattype, format));

  const filterdDf = df.filter(
    (row) => toDayjs(row[xName], dtype, formattype, format) >= xMin
  );
  return filterdDf.count() < df.count();
}

export function transformQueryData(
  settings: Chart,
  data?: QueryData,
  isY2Axis?: boolean // 2軸目
): TransformedResult {
  if (
    data == undefined ||
    data.rows.length === 0 ||
    data.columns.length === 0
  ) {
    return {
      data: undefined,
      chartFeature: {
        isUniq: true,
        isSampling: false,
        hasOutOfAxisRange: false,
        isOverPeriodLimit: false
      }
    };
  }

  const df = new DataForge.DataFrame({
    columnNames: data.columns,
    rows: data.rows
  });

  // settings.chart.aggregated = trueなら isUniq は false
  let isUniq = !('aggregated' in settings.chart &&
  settings.chart.aggregated != undefined
    ? settings.chart.aggregated
    : false);
  if (!isUniq) {
    isUniq = checkUniqueDataframe(settings, df);
  }

  const hasOutOfAxisRange = checkHasOutOfAxisRange(settings, df, isY2Axis);
  const isOverPeriodLimit = checkIsOverBinLimit(settings, df);

  const transformed = transformData(settings, df, isY2Axis);
  return {
    data: transformed,
    chartFeature: {
      isUniq,
      isSampling: data.is_down_sampling,
      hasOutOfAxisRange,
      isOverPeriodLimit
    }
  };
}

export function toTransformedDualResult(
  dataList: (TransformedResult | undefined)[]
): TransformedDualResult {
  return {
    data: dataList.map((d) => d?.data),
    chartFeature: {
      isUniq: !dataList.some((d) => (d ? !d.chartFeature.isUniq : false)), // どれか一つでもfalseがあったらfalse
      isSampling: dataList.some((d) => (d ? d.chartFeature.isSampling : false)), // どれか一つでもtrueがあったらtrue
      hasOutOfAxisRange: dataList.some((d) =>
        d ? d.chartFeature.hasOutOfAxisRange : false
      ),
      isOverPeriodLimit: dataList.some((d) =>
        d ? d.chartFeature.isOverPeriodLimit : false
      )
    }
  };
}

export function getColumnValues(data: QueryData): {
  [colname: string]: string[];
} {
  let values = {};
  data.rows.forEach((row) => {
    row.forEach((value, i) => {
      const colname = data.columns[i];
      if (colname in values) {
        values[colname] = uniq([...values[colname], value]);
      } else {
        values[colname] = [value];
      }
    });
  });
  return values;
}

const HISTO_Y_COLNAME = 'y';
const HISTO_Y_MAX_COLNAME = 'max_y';
const HISTO_Y_MIN_COLNAME = 'min_y';
const HISTO_LEGEND_COLNAME = 'legend';

// const HISTO_NUM_BUCKETS_COLNAME = 'bucket';

function sortHistogramLegends(
  dataFrame: DataForge.IDataFrame,
  order: OrderSetting,
  legendColname: string,
  yColname: string
): string[] {
  let sortedLegends: string[] = [];
  switch (order.legend.target) {
    case 'x': {
      // 凡例のラベル名順
      sortedLegends = dataFrame
        .distinct((row) => row[legendColname])
        .toArray()
        .map((row) => {
          return row[legendColname];
        });
      sortedLegends.sort((a, b) =>
        String(a).localeCompare(String(b), undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );

      break;
    }
    case 'y': {
      // 縦軸の値順
      sortedLegends = dataFrame
        .groupBy((row) => row[legendColname])
        .select((group) =>
          aggregateByLegend(
            group,
            yColname,
            legendColname,
            order.legend.summarise
          )
        )
        .inflate()
        .orderBy((row) => row[orderValueName] ?? -Infinity)
        .toArray()
        .map((row) => row[legendColname]);

      break;
    }
    case 'specific': {
      // 他の列の値順
      sortedLegends = dataFrame
        .groupBy((row) => row[legendColname])
        .select((group) =>
          aggregateByLegend(
            group,
            getValueName(true, order.legend.column),
            legendColname,
            order.legend.summarise
          )
        )
        .inflate()
        .orderBy((row) => row[orderValueName] ?? -Infinity)
        .toArray()
        .map((row) => row[legendColname]);
      break;
    }
  }
  if (order.legend.method === 'desc') {
    sortedLegends.reverse();
  }
  return sortedLegends;
}

interface HistogramRow {
  bucket: any;
  y: any;
}

type MultipleHistogramRow = HistogramRow & {
  legend: any;
};

function convertRatio(
  dataFrame: DataForge.IDataFrame,
  yColname: string,
  legendColname?: string
): DataForge.IDataFrame {
  if (legendColname) {
    const totalDF = dataFrame
      .groupBy((row) => row[legendColname])
      .select((group) => {
        return {
          [legendColname]: group.first()[legendColname],
          total: group.getSeries(yColname).sum()
        };
      })
      .inflate();

    const names = dataFrame.getColumnNames();
    return dataFrame.join(
      totalDF,
      (left) => left[legendColname],
      (right) => right[legendColname],
      (left, right) => {
        const columns = {};
        names.forEach((name) => {
          columns[name] = left[name];
        });
        columns[yColname] = left[yColname] / right['total'];
        return columns;
      }
    );
  }
  const total = dataFrame.getSeries(yColname).sum();
  return dataFrame.select((row) => {
    return {
      ...row,
      [yColname]: row[yColname] / total
    };
  });
}

function transformHistogram(
  aggDF: DataForge.IDataFrame,
  bucketXmap: { [k: number]: string }
): TransformedData {
  const aggDFarr = aggDF.toArray();
  const data = Object.keys(bucketXmap).map((bucket) => {
    const find: HistogramRow | undefined = aggDFarr.find(
      (row: HistogramRow) => row.bucket == bucket
    );
    return {
      label: bucketXmap[bucket],
      value: find ? find.y : 0
    };
  });

  const transformed: TransformedData = {
    type: FusionChartType.Histogram,
    data
  };
  return transformed;
}

function transformMultipleHistogram(
  aggDF: DataForge.IDataFrame,
  sortedLegends: string[],
  bucketXmap: { [k: number]: string }
): TransformedData {
  const sortedBuckets = aggDF
    .distinct((row: MultipleHistogramRow) => row.bucket)
    .toArray()
    .map((row: MultipleHistogramRow) => row.bucket)
    .sort((a, b) => a - b);
  const category = sortedBuckets.map((bucket) => {
    return { label: bucketXmap[bucket] };
  });
  const dataset = sortedLegends.map((legend) => {
    const filtered = aggDF
      .where((row: MultipleHistogramRow) => row.legend === legend)
      .toArray();
    const data = sortedBuckets.map((bucket) => {
      const find: MultipleHistogramRow | undefined = filtered.find(
        (row: MultipleHistogramRow) => row.bucket === bucket
      );
      return {
        value: find ? find.y : undefined
      };
    });

    return {
      seriesname: legend,
      data
    };
  });
  const transformed: TransformedData = {
    type: FusionChartType.MultipleHistogram,
    categories: [{ category }],
    dataset
  };
  return transformed;
}

function getBucketXlabelMap(
  dataFrame: DataForge.IDataFrame,
  yMaxColname: string,
  yMinColname: string,
  numBuckets: number,
  settings: Chart
): { [k: number]: string } {
  const max = dataFrame.getSeries(yMaxColname).max();
  const min = dataFrame.getSeries(yMinColname).min();
  const buckets = Array.from({ length: numBuckets }, (_, i) => i + 1);
  let bucketXmap = {};
  const width = (max - min) / numBuckets;
  buckets.forEach((bucket) => {
    const from = histogramXAxisFormatter(min + (bucket - 1) * width, settings);
    const to = histogramXAxisFormatter(min + bucket * width, settings);
    if (bucket === 0) {
      bucketXmap[bucket] = ` ~ ${to}`;
      return;
    }
    if (bucket === numBuckets + 1) {
      bucketXmap[bucket] = `${from} ~`;
      return;
    }
    bucketXmap[bucket] = `${from} ~ ${to}`;
  });
  return bucketXmap;
}
