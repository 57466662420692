import { MultipleStringParameter } from 'models/report';
import { ParameterClass } from './base';
import { StringOptions } from '../useStringOptions';
import { difference } from 'lodash-es';

export class StringMultipleClass implements ParameterClass {
  constructor(
    public param: MultipleStringParameter,
    public stringOptions?: StringOptions
  ) {}
  isValid = () => {
    return this.param.values.length > 0;
  };
  toDisplayString = () => {
    if (this.param.operator === 'not_in') {
      // not inの場合、stringOption.length - values.length
      if (
        !this.stringOptions ||
        this.stringOptions.loading ||
        !this.stringOptions.value
      ) {
        return 'Loading...';
      }

      const length = this.stringOptions.value.length - this.param.values.length;
      if (length === 1) {
        return `${difference(this.stringOptions.value, this.param.values)[0]}`;
      }
      return `${length} 選択中`;
    }

    if (this.param.values.length === 1) {
      return `${this.param.values[0]}`;
    }
    return `${this.param.values.length} 選択中`;
  };

  toMinimizedDisplayString = () => {
    let text = '';
    const displayLength = Math.min(this.param.values.length, 3);
    text += this.param.values.slice(0, displayLength).join(', ');
    if (this.param.values.length > 3) {
      text += `...(計${this.param.values.length}個)`;
    }
    return text;
  };
}
