import { Chart } from 'models/chart';
import { formatXAxisNumber } from './format';

export const histogramXAxisFormatter = (
  num: number,
  settings: Chart
): string => {
  const { view } = settings;
  if (
    view.xShowPercentage ||
    view.xFormatNumberScale === '1' ||
    view.xAxisValueDecimals != undefined ||
    view.xNumberPrefix ||
    view.xNumberSuffix
  ) {
    return formatXAxisNumber(num, view);
  }
  // x軸表示の設定がない場合デフォルト
  return num.toPrecision(3);
};
