import { Dtypes } from 'Utils/dataTypes';
import {
  Table,
  Option,
  TableColumn,
  TableOptionType,
  TableOptionName,
  TableOptionMultiple,
  QueryArgColumn,
  AggregateFunc
} from '../../../models/chart';
import { ColumnSelectRules } from 'models/form/value';
import {
  getOptionReflectedColumns,
  getReflectedStringColorOption
} from '../../../hooks/chartReducer';
import { selectColumnsByRule } from 'components/form/columnSelectV2Field';
import { aggregateMethod } from '../editor/util';
import { produce } from 'immer';

export const getMatchColumnsByRules = (
  columns: Option[],
  rules: ColumnSelectRules
): Option[] => {
  const [matchColumns] = selectColumnsByRule(
    Object.values(columns).map((c) => c.label),
    Object.values(columns).map((c) => c.dtype as Dtypes),
    rules
  );
  const matchValues = matchColumns.map((c) => c.name);
  return columns.filter((c) => matchValues.includes(c.label));
};

export const getColumnLabelsByRules = (
  allColumns: Option[],
  columnSelectRules: ColumnSelectRules
): string[] => {
  const matchColumns = getMatchColumnsByRules(allColumns, columnSelectRules);
  return matchColumns.map((c) => c.label);
};

export const getAllowedDtypes = (
  aggFunc?: AggregateFunc
): Dtypes[] | undefined => {
  if (!aggFunc) {
    // 全ての型ok
    return undefined;
  }

  switch (aggFunc) {
    case AggregateFunc.avg:
    case AggregateFunc.max:
    case AggregateFunc.min:
    case AggregateFunc.sum:
      return [Dtypes.NUMBER];
    default:
      return undefined;
  }
};

const getAllowedDtypesError = (
  dtype: string | undefined,
  allowedDtypes: Dtypes[] | undefined
): [boolean, string] => {
  if (!dtype || !allowedDtypes) {
    return [false, ''];
  }
  if (allowedDtypes.includes(dtype as Dtypes)) {
    return [false, ''];
  }

  return [true, 'dtype'];
};

const validate = (settings: Table, columns: Option[]): Option[] => {
  // 集計設定で関数を選択したとき
  // 集計設定で列を選択したとき
  const aggOption = settings.columns.options.find(
    (o) =>
      o.type === TableOptionType.multiple &&
      o.option.name === TableOptionName.aggregate
  )?.option?.option as TableOptionMultiple['option'] | undefined;
  if (!aggOption) {
    return columns;
  }
  return produce(columns, (draft) => {
    aggOption.forEach((o) => {
      const selected = getMatchColumnsByRules(columns, o.columnSelectRules);
      const allowedDtypes = getAllowedDtypes(o.option.aggFunc);
      selected.forEach((c) => {
        // その関数を選択できない型の場合はエラーにしておく
        const [isError, errorType] = getAllowedDtypesError(
          c.dtype,
          allowedDtypes
        );
        if (isError) {
          const index = draft.findIndex((d) => d.value === c.value);
          draft[index] = {
            ...draft[index],
            isError: true,
            errorType: errorType
          };
        }
      });
    });
  });
};

export class TableSettings {
  constructor(
    private _settings: Table,
    private _originColumns: Option[]
  ) {}

  get settings() {
    return produce(this._settings, (draft) => {
      draft.columns.columns.queryArgColumns = this.queryArgColumns;
      draft.columns.columns.tableDisplayColumnsWithOption =
        this.tableDisplayColumnsWithOption;
    });
  }

  set settings(value) {
    this._settings = value;
  }

  get originColumns() {
    return validate(this._settings, this._originColumns);
  }

  // 「集計する」にチェックが入っていない場合の列選択肢
  get normalSelectableColumns(): Option[] {
    return this.originColumns;
  }

  // 「集計する」にチェックが入っていない場合の選択された列
  get normalSelectedColumns(): Option[] {
    return getMatchColumnsByRules(
      this.aggKeySelectableColumns,
      this._settings.columns.columns.columnSelectRules
    );
  }

  // 「集計する」にチェックが入っている場合の「キー列」選択肢
  get aggKeySelectableColumns(): Option[] {
    return this.originColumns;
  }

  // 「集計する」にチェックが入っている場合の選択されたキー列
  get aggKeySelectedColumns(): Option[] {
    return getMatchColumnsByRules(
      this.aggKeySelectableColumns,
      this._settings.columns.columns.columnAggKeyRules
    );
  }

  // 「集計する」にチェックが入っている場合の「集計設定」の列選択肢
  get aggTargetSelectableColumns(): Option[] {
    return this.originColumns;
  }

  // 「集計する」にチェックが入っている場合の「集計設定」の選択された列
  get aggTargetSelectedColumns(): (Option & {
    aggFunc?: AggregateFunc;
  })[] {
    const aggOption = this._settings.columns.options.find(
      (o) =>
        o.type === TableOptionType.multiple &&
        o.option.name === TableOptionName.aggregate
    )?.option as TableOptionMultiple | undefined;
    if (!aggOption) {
      return [];
    }
    const aggTargetColumns = aggOption.option.flatMap((o) => {
      const columns = getMatchColumnsByRules(
        this.aggTargetSelectableColumns,
        o.columnSelectRules
      );
      return columns.map((c) => {
        return {
          ...c,
          aggFunc: o.option.aggFunc
        };
      });
    });
    return aggTargetColumns;
  }

  // 「集計設定」で選択された列の、集計実行後の列
  get afterAggColumns(): Option[] {
    const aggOption = this._settings.columns.options.find(
      (o) =>
        o.type === TableOptionType.multiple &&
        o.option.name === TableOptionName.aggregate
    )?.option as TableOptionMultiple | undefined;
    if (!aggOption) {
      return [];
    }
    const afterAggColumns: Option[] = aggOption.option.flatMap((o) => {
      const columns = getMatchColumnsByRules(
        this.aggTargetSelectableColumns,
        o.columnSelectRules
      );
      return columns.map((c) => {
        // 新たな列として、labelとvalueを設定する
        const funcLabel = aggregateMethod.find(
          (m) => m.value === o.option.aggFunc
        )?.label;
        return {
          value: `${(o.option.aggFunc || '').toLowerCase()}_${c.value}`, // prestoの列名に合わせる
          label: `${c.value}_${funcLabel}`,
          dtype: Dtypes.NUMBER
        };
      });
    });
    return afterAggColumns;
  }

  // 以下で表示する
  // - DataTableに表示する列
  // -「グラフ形」タブ以外の列選択肢
  get tableDisplayColumns(): Option[] {
    if (this._settings.aggregated) {
      // 「集計する」にチェックが入っていない場合、そのまま
      return this.normalSelectedColumns;
    }

    // 「集計する」にチェックが入っている場合
    // キー列
    const aggKeyColumns = this.aggKeySelectedColumns;
    // 「集計設定」で選択された列の、集計実行後の名称の列
    const afterAggColumns = this.afterAggColumns;
    return [...aggKeyColumns, ...afterAggColumns];
  }

  // DataTableに渡す際に、必要な設定をくっつけた列
  get tableDisplayColumnsWithOption(): TableColumn[] {
    return getOptionReflectedColumns(
      this._settings.columns,
      this.tableDisplayColumns
    );
  }

  // クエリを実行するのに必要な列情報
  get queryArgColumns(): QueryArgColumn[] {
    if (this._settings.aggregated) {
      return this.normalSelectedColumns.map((c) => {
        return {
          ...c,
          isSelected: true,
          isAggKey: false
        };
      });
    }

    return [
      ...this.aggKeySelectedColumns.map((c) => {
        return {
          ...c,
          isSelected: false,
          isAggKey: true,
          aggFunc: undefined
        };
      }),
      ...this.aggTargetSelectedColumns.map((c) => {
        return {
          ...c,
          isSelected: false,
          isAggKey: false,
          aggFunc: c.aggFunc
        };
      })
    ];
  }

  // 「ヒートマップにする」の列選択肢
  get heatmapSelectableColumns(): Option[] {
    return this.tableDisplayColumns.filter((c) => c.dtype === Dtypes.NUMBER);
  }

  // 列の値をsettingsに反映させる
  updateSettingsByStringCellColor(columnValues: {
    [colname: string]: string[];
  }) {
    this.settings = produce(this._settings, (draft) => {
      draft.columns.options = this._settings.columns.options.map((option) => {
        if (option.type === TableOptionType.stringCellColor) {
          // テーブルデータの値を反映させたオプション
          return getReflectedStringColorOption(option, columnValues);
        }
        return option;
      });
    });
  }
}
