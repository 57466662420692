import makeStyles from '@material-ui/core/styles/makeStyles';

export const FieldConditionRuleStyles = makeStyles({
  betweenOperatorSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '15%'
    //width: '160px'
  },
  selectBox: {
    width: '30%',
    marginTop: '11px',
    paddingLeft: '10px'
  },
  betweenSelectBox: {
    width: '20%',
    marginTop: '11px',
    paddingLeft: '10px'
  },
  selectBoxWithoutUseCol: {
    width: '30%',
    marginTop: '52px',
    paddingLeft: '10px'
  },
  betweenSelectBoxWithoutUseCol: {
    width: '20%',
    marginTop: '52px',
    paddingLeft: '10px'
  }
});

export const OneOfArrayRuleStyles = makeStyles({
  columnSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '30%'
    //width: '230px'
  },
  betweenColumnSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '20%'
    //width: '230px'
  },
  selectBoxWithoutUseCol: {
    width: '30%',
    marginTop: '53px',
    paddingLeft: '10px'
  },
  elementSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '15%'
  },
  operatorSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '30%'
    //width: '230px'
  },
  dateOperatorSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '15%'
    //width: '230px'
  },
  betweenOperatorSelect: {
    padding: '3px 10px',
    marginTop: '50px',
    marginBottom: '10px',
    width: '20%'
    //width: '230px'
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 10px',
    //overflowY: 'auto',
    backgroundColor: '#ededed',
    border: '2px #c3c3c3 solid',
    borderRadius: '3px',
    height: '100%'
  },
  conditionContainer: {
    //overflowY: 'auto',
    backgroundColor: '#ffffff',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: '3px',
    position: 'relative',
    '&:not(:last-child)': {
      '&:after': {
        position: 'absolute',
        content: "'and'",
        borderRadius: '50%',
        width: 32,
        height: 32,
        bottom: -24,
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        background: '#9f9f9f',
        fontSize: 12,
        fontWeight: 500,
        boxShadow: '0 2px 5px 0 rgb(0 0 0 / 20%)',
        zIndex: 10
      }
    }
  },
  elseContainer: {
    width: 990,
    minHeight: 350,
    backgroundColor: '#e0e0e0',
    padding: '24px 32px',
    //overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex'
  }
});
