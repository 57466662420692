import { FieldValidationError, ValidationSchema } from 'models/form/validate';
import { Dtypes } from 'Utils/dataTypes';
import { TypographyProps } from '@material-ui/core/Typography';

export enum FieldTypes {
  object = 'object',
  array = 'array',
  text = 'text',
  select = 'select',
  column_select = 'column_select',
  column_select_v2 = 'column_select_v2',
  dtype_select = 'dtype_select',
  category_select = 'category_select',
  multiple_input = 'multiple_input',
  checkbox = 'checkbox',
  radio = 'radio',
  label = 'label',
  date = 'date',
  code_editor = 'code_editor',
  rename = 'rename',
  switch = 'switch',
  ml_preprocess = 'ml_preprocess',
  model_v2_id_select = 'model_v2_id_select',
  manual_ml = 'manual_ml',
  order = 'order',
}

export interface FieldSchemaBase {
  key: string;
  title?: string;
  inline?: boolean;
  defaultValue?: any;
  optional?: boolean;
  displayExpr?: string;
  hideDivider?: boolean;
  validate?: ValidationSchema;
  detailHelpName?: string;
}

export interface ObjectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.object;
  properties: FormSchema;
}

export interface ArrayFieldSchema extends FieldSchemaBase {
  type: FieldTypes.array;
  item: FieldSchema[];
}

export interface TextFieldSchema extends FieldSchemaBase {
  type: FieldTypes.text;
  readonly?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  variant?: 'outlined';
}

export interface SelectFieldValueElement {
  label: string;
  value: string;
  dtype?: string;
  changed?: boolean; // FIXME dtypeSelectFieldで使ってる
  disableColumn?: boolean;
}

export interface SelectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.select;
  items: Array<SelectFieldValueElement | string>;
  multi: boolean;
  allDtypes: Dtypes[][];
  placeholder?: string;
  errors?: FieldValidationError;
  fullWidth?: boolean;
  variant?: string;
  backgroundColor?: string;
}

export interface ColumnSelectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.column_select;
  multi: boolean;
  inConfName?: string;
  inConfNames?: string[];
  placeholder?: string;
  dtypes?: string[];
  excludeValueKeys?: string[];
  fullWidth?: boolean;
  variant?: string;
  backgroundColor?: string;
}

export interface ColumnSelectV2FieldSchema extends FieldSchemaBase {
  type: FieldTypes.column_select_v2;
  inConfName?: string;
  inConfNames?: string[];
  dtypes?: Dtypes[];
  autoOpen?: boolean;
}

export interface DtypeSelectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.dtype_select;
  columnKey: string;
  inConfName?: string;
  placeholder?: string;
}

export interface CategorySelectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.category_select;
  multi: boolean;
  columnNameKey: string;
  inConfName: string;
  placeholder?: string;
  fullWidth?: boolean;
  variant?: 'outlined';
  backgroundColor?: string;
}

export interface RadioFieldSchema extends FieldSchemaBase {
  type: FieldTypes.radio;
  items: Array<{ label: string; value: string }>;
}

export interface CheckboxFieldSchema extends FieldSchemaBase {
  type: FieldTypes.checkbox;
}

export interface LabelFieldSchema extends FieldSchemaBase {
  type: FieldTypes.label;
  text: string;
  variant?: 'fieldhead' | TypographyProps['variant'];
}

export interface DateFieldSchema extends FieldSchemaBase {
  type: FieldTypes.date;
}

export interface CodeEditorFieldSchema extends FieldSchemaBase {
  type: FieldTypes.code_editor;
}

export interface MultipleInputFieldSchema extends FieldSchemaBase {
  type: FieldTypes.multiple_input;
  key: string;
  label?: string;
  placeholder?: string;
  fullWidth?: boolean;
}

export interface RenameColumnsFieldSchema extends FieldSchemaBase {
  type: FieldTypes.rename;
  inConfName?: string;
  inConfNames?: string[];
  dtypes?: Dtypes[];
  autoOpen?: boolean;
}

export interface ColumnsOrderFieldSchema extends FieldSchemaBase {
  type: FieldTypes.order;
  inConfName?: string;
  inConfNames?: string[];
  dtypes?: Dtypes[];
  autoOpen?: boolean;
}

export interface SwitchFieldSchema extends FieldSchemaBase {
  type: FieldTypes.switch;
  inConfName?: string;
  inConfNames?: string[];
  dtypes?: Dtypes[];
  autoOpen?: boolean;
  readonly?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
}

export interface mlPreprocessFieldSchema extends FieldSchemaBase {
  type: FieldTypes.ml_preprocess;
  inConfName?: string;
  inConfNames?: string[];
  dtypes?: Dtypes[];
  autoOpen?: boolean;
  dialogDetailHelps: Array<{ key: string; help: string }>;
  excludeValueKeys?: string[];
  includeValueKyes?: string[];
}

export interface ModelV2IdSelectFieldSchema extends FieldSchemaBase {
  type: FieldTypes.model_v2_id_select;
  multi: boolean;
  columnNameKey: string;
  inConfName: string;
  placeholder?: string;
  fullWidth?: boolean;
  variant?: 'outlined';
  backgroundColor?: string;
}

export interface ManualMLFieldSchema extends FieldSchemaBase {
  type: FieldTypes.manual_ml;
  inConfName?: string;
  inConfNames?: string[];
  autoOpen?: boolean;
  modelTypeKey?: string;
  selectedAlgorithmes?: string;
  dialogDetailHelps: Array<{ key: string; help: string }>;
}

export type FieldSchema =
  | ObjectFieldSchema
  | ArrayFieldSchema
  | TextFieldSchema
  | SelectFieldSchema
  | ColumnSelectFieldSchema
  | ColumnSelectV2FieldSchema
  | DtypeSelectFieldSchema
  | CategorySelectFieldSchema
  | RadioFieldSchema
  | CheckboxFieldSchema
  | LabelFieldSchema
  | DateFieldSchema
  | CodeEditorFieldSchema
  | MultipleInputFieldSchema
  | RenameColumnsFieldSchema
  | SwitchFieldSchema
  | mlPreprocessFieldSchema
  | ModelV2IdSelectFieldSchema
  | ManualMLFieldSchema
  | ColumnsOrderFieldSchema;

export type FormSchema = FieldSchema[];
