import ActionTypes from './actionTypes';

export function setHelpStatus(status: boolean) {
  localStorage.setItem('help', String(status));

  return {
    type: ActionTypes.UserConfigHelpStatus,
    payload: {
      help: status
    }
  };
}

export function setNotificationStatus(status: boolean) {
  localStorage.setItem('notification', String(status));
  if (status && Notification.permission !== 'granted') {
    Notification.requestPermission();
  }
  return {
    type: ActionTypes.UserConfigNotificationStatus,
    payload: {
      notification: status
    }
  };
}


export function setDescribeStatus(status: boolean) {
  localStorage.setItem('describe', String(status));

  return {
    type: ActionTypes.UserConfigDescribeStatus,
    payload: {
      describe: status
    }
  };
}
