import dayjs from 'dayjs';
import { ParameterType, StringOperator, TimeParameter } from 'models/report';
import {
  InputPastValue,
  TimeValues,
  datetimeSingleUnits,
  datetimeRangeUnits
} from 'models/report/parameter';

export function operatorToString(operator: string): string {
  switch (operator) {
    case '==':
      return '=';
    default:
      return operator;
  }
}

export function stringOperatorToString(operator: StringOperator): string {
  switch (operator) {
    case '==':
      return '';
    case 'contains':
      return '含む';
    case 'starts_with':
      return 'から始まる';
    case 'ends_with':
      return 'で終わる';
    case 'regexp':
      return '正規表現';
  }
}

export function dateToString(d?: Date): string {
  if (!d) {
    return '';
  }
  return dayjs(d).format('YYYY年MM月DD日');
}

export function inputPastToString(
  inputPastValue: InputPastValue,
  type: ParameterType
): string | null {
  const suffixString = type === ParameterType.Single ? '前' : '間';
  const datetimeUnits =
    type === ParameterType.Single ? datetimeSingleUnits : datetimeRangeUnits;
  const unitString = datetimeUnits.find(
    (unit) => unit.value === inputPastValue.unit
  )?.label;
  if (!unitString) {
    return null;
  }
  return `過去${inputPastValue.value}${unitString}${suffixString}`;
}

export function relativeSelectToString(
  select: TimeValues['select'],
  relativeItems: {
    label: string;
    value: TimeValues['select'];
  }[]
): string | null {
  const label = relativeItems.find((item) => item.value === select)?.label;
  if (!label) {
    return null;
  }
  return label;
}

export function relativeToString(
  param: TimeParameter,
  relativeItems: {
    label: string;
    value: TimeValues['select'];
  }[]
): string | null {
  if (param.values.select === 'input_past') {
    // 過去N時間前など
    return inputPastToString(param.values.inputPastValue, param.type);
  }
  // 相対の文字列
  return relativeSelectToString(param.values.select, relativeItems);
}
