import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const LinkForceRemount = React.forwardRef<any, LinkProps>(
  (props, ref) => {
    const [routeUniqueKey, setRouteUniqueKey] = React.useState(
      `${Math.random()}`
    );
    const to = {
      pathname: typeof props.to === 'string' ? props.to : '',
      state: { routeUniqueKey }
    };
    return (
      <Link
        ref={ref}
        {...props}
        onClick={() => setRouteUniqueKey(`${Math.random()}`)}
        to={to}
      >
        {props.children}
      </Link>
    );
  }
);
