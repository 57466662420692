export enum Dtypes {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  TIMESTAMP = 'timestamp',
  TIMEDELTA = 'timedelta',
  TIME = 'time',
  PARTITION = 'partition',
  ARRAY = 'array', // for builder
  JSON = 'json', // for builder
  RECORD = 'record', // for builder
  UNDEFINED = ''
}

export const DtypeItems: Array<{ value: Dtypes; text: string }> = [
  { value: Dtypes.STRING, text: toDtypeLabel(Dtypes.STRING) },
  { value: Dtypes.NUMBER, text: toDtypeLabel(Dtypes.NUMBER) },
  { value: Dtypes.DATE, text: toDtypeLabel(Dtypes.DATE) },
  { value: Dtypes.TIMESTAMP, text: toDtypeLabel(Dtypes.TIMESTAMP) },
  { value: Dtypes.TIME, text: toDtypeLabel(Dtypes.TIME) }
];

export function LabelToDtype(dtypeLabel: string): Dtypes {
  switch (dtypeLabel) {
    case 'string':
      return Dtypes.STRING;
    case 'number':
      return Dtypes.NUMBER;
    case 'boolean':
      return Dtypes.BOOLEAN;
    case 'date':
      return Dtypes.DATE;
    case 'timestamp':
      return Dtypes.TIMESTAMP;
    case 'timedelta':
      return Dtypes.TIMEDELTA;
    case 'time':
      return Dtypes.TIME;
    case 'partition':
      return Dtypes.PARTITION;
    case 'array':
      return Dtypes.PARTITION;
    case 'json':
      return Dtypes.JSON;
    case 'record':
      return Dtypes.RECORD;
    default:
      return Dtypes.UNDEFINED
  }
}

export function toDtypeLabel(dtype: Dtypes | string): string {
  switch (dtype) {
    case Dtypes.STRING:
      return '文字列';
    case Dtypes.NUMBER:
      return '数値';
    case Dtypes.BOOLEAN:
      return '論理値';
    case Dtypes.DATE:
      return '日付';
    case Dtypes.TIMESTAMP:
      return '日付時間';
    case Dtypes.TIMEDELTA:
      return 'timedelta';
    case Dtypes.TIME:
      return '時間';
    case Dtypes.ARRAY:
      return '配列';
    case Dtypes.RECORD:
      return 'レコード';
    default:
      return '';
  }
}

export enum TIME_FORMAT {
  DATE = '%Y-%m-%d',
  TIME = '%H:%M:%S',
  TIMESTAMP = '%Y-%m-%d %H:%M:%S'
}

export interface NumberFormatOptions {
  localeMatcher?: 'best fit' | 'lookup';
  style?: 'decimal' | 'currency' | 'percent';
  currency?: string;
  currencyDisplay?: 'symbol' | 'code' | 'name';
  useGrouping?: boolean;
  minimumIntegerDigits?: number;
  minimumFractionDigits?: number;
  maximumFractionDigits?: number;
  minimumSignificantDigits?: number;
  maximumSignificantDigits?: number;
}
