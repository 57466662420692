import { intersection, set as _set } from 'lodash-es';
import { enablePatches, produce, produceWithPatches } from 'immer';
import ActionTypes from 'actions/actionTypes';
import { ProcessItem } from 'reducers/processList';
import {
  Edge,
  Edges,
  EdgeStatus,
  Graph,
  Node,
  Nodes,
  NodeStatus,
  Port,
  Ports,
  PortStatus,
  Positions,
  UpdateCoordinate
} from 'models/graph';
import { ColumnSelectFieldValue, FormValue } from 'models/form/value';
import { getFormValuesByPath } from 'components/form/util';
import {
  ConnectPort,
  generateEdgeId,
  GenerateId,
  generateNodeId,
  GeneratePortsInfo,
  searchNodeFromPort,
  topologicalSort
} from 'components/diagram/utils';
import { ControlCoords } from 'components/diagram/controlCoords';
import { DataSummary } from 'models/data';
import { FormValidationError } from 'models/form/validate';
import { NodeBackend } from 'models/websocket';
import {
  cutArray,
  redo,
  undo,
  UndoCreateEdge,
  UndoCreateNodeEdge,
  UndoRemoveEdge,
  UndoType
} from 'reducers/undo';

enablePatches();

export const INITIAL_POSITIONS = {
  offsetX: 0,
  offsetY: 0,
  zoomLevel: 100
};

export type GraphState = Graph;

export const graphInitState: GraphState = {
  nodes: {},
  edges: {},
  ports: {},
  positions: INITIAL_POSITIONS,
  selectedNodeId: undefined,
  previousNodeId: undefined,
  undoList: [],
  redoList: []
};

function createNode(
  state: GraphState,
  event: {
    selectedProcess: ProcessItem;
  }
): GraphState {
  const nodeId = generateNodeId();
  const { selectedNodeId } = state;
  const { outConf, inConf, name, type } = event.selectedProcess;
  const controlCoords = new ControlCoords(state.nodes, state.positions);
  const selectedNode =
    selectedNodeId !== undefined ? state.nodes[selectedNodeId] : undefined;
  const { nodeCoord } = controlCoords.generateNodeCoord(
    selectedNodeId,
    inConf != undefined
  );

  // 新しいportの作成
  const inPorts: Ports = GeneratePortsInfo(inConf || []);
  const outPorts: Ports = GeneratePortsInfo(outConf || []);

  // 新しいnodeの作成
  const newNode: Node = {
    id: nodeId,
    name,
    inPorts: Object.keys(inPorts),
    outPorts: Object.keys(outPorts),
    processType: type || '',
    status: NodeStatus.Temporary,
    backend: NodeBackend.Python,
    x: nodeCoord.x,
    y: nodeCoord.y,
    comment: '[自動コメント]',
    autoComment: '',
    rows: 3
  };

  let tmpEdge: Edge | undefined;
  if (selectedNode !== undefined && inConf !== undefined) {
    // edgeの設定
    const selectedOutPorts: Ports = {};
    const edgeId = generateEdgeId();
    selectedNode.outPorts.forEach((id) => {
      selectedOutPorts[id] = state.ports[id];
    });
    const { fromPortId, toPortId, connected } = ConnectPort(
      inPorts,
      selectedOutPorts
    );
    if (connected) {
      // 新しいedgeの作成
      tmpEdge = {
        id: edgeId,
        from: { nodeId: selectedNode.id, portId: fromPortId },
        to: { nodeId: newNode.id, portId: toPortId },
        status: EdgeStatus.Temporary
      };
    }
  }

  return produce(state, (draft) => {
    draft.ports = { ...draft.ports, ...inPorts, ...outPorts };
    draft.nodes[newNode.id] = newNode;

    if (tmpEdge != undefined) {
      draft.edges[tmpEdge.id] = tmpEdge;
    }
    draft.selectedNodeId = nodeId;
    draft.previousNodeId = state.selectedNodeId;
    const undoData: UndoCreateNodeEdge = {
      type: UndoType.CREATE_NODE_EDGE,
      params: {
        nodes: [newNode.id]
      }
    };
    if (tmpEdge) {
      undoData.params['edges'] = [tmpEdge.id];
    }
    draft.undoList.push(undoData);
    draft.undoList = cutArray(draft.undoList);
  });
}

function selectNode(
  state,
  nodeId: string
): Pick<GraphState, 'selectedNodeId' | 'previousNodeId'> {
  const selectedNodeId = state.selectedNodeId;
  let previousNodeId = state.previousNodeId;
  if (selectedNodeId !== nodeId) {
    previousNodeId = selectedNodeId;
  }
  return { selectedNodeId: nodeId, previousNodeId };
}

export function removeNode(
  ids: string[],
  graph: {
    nodes: Nodes;
    ports: Ports;
    edges: Edges;
  }
): readonly [
  {
    nodes: Nodes;
    ports: Ports;
    edges: Edges;
  },
  {
    nodes: Node[];
    ports: Port[];
    edges: Edge[];
  }
] {
  const deletedNodes: Node[] = [];
  const deletedEdges: Edge[] = [];
  const deletedPorts: Port[] = [];

  const newState = produce(graph, (draft) => {
    ids.forEach((nodeId) => {
      if (draft.nodes[nodeId] == undefined) {
        return;
      }
      const { inPorts, outPorts } = draft.nodes[nodeId];
      const deletePorts = inPorts.concat(outPorts);

      // portの削除
      deletePorts.forEach((portId) => {
        deletedPorts.push(graph.ports[portId]);
        delete draft.ports[portId];
      });

      const deleteEdges: string[] = [];
      Object.keys(draft.edges).forEach((key) => {
        if (
          draft.edges[key].from.nodeId === nodeId ||
          draft.edges[key].to.nodeId === nodeId
        ) {
          deleteEdges.push(key);
        }
      });

      // edgeの削除
      deleteEdges.forEach((edgeId) => {
        deletedEdges.push(graph.edges[edgeId]);
        delete draft.edges[edgeId];
      });

      // nodeの削除
      deletedNodes.push(graph.nodes[nodeId]);
      delete draft.nodes[nodeId];
    });
  });

  return [
    newState,
    { nodes: deletedNodes, edges: deletedEdges, ports: deletedPorts }
  ];
}

function deleteNode(
  state: GraphState,
  payload: { nodeIds: string[] }
): Partial<GraphState> {
  const { nodeIds } = payload;
  const [{ nodes, ports, edges }, deleted] = removeNode(nodeIds, state);
  let newSelectedNodeId = state.selectedNodeId;
  let newPreviousNodeId = state.previousNodeId;

  const isSelectedDelete =
    newSelectedNodeId != undefined
      ? !Object.keys(nodes).includes(newSelectedNodeId)
      : false;

  const isPrevDelete =
    newPreviousNodeId != undefined
      ? !Object.keys(nodes).includes(newPreviousNodeId)
      : false;

  if (isPrevDelete) {
    newPreviousNodeId = undefined;
  }

  if (isSelectedDelete) {
    newSelectedNodeId = newPreviousNodeId;
  }

  return {
    nodes,
    edges,
    ports,
    selectedNodeId: newSelectedNodeId,
    previousNodeId: newPreviousNodeId,
    undoList: cutArray([
      ...state.undoList,
      { type: UndoType.REMOVE_NODE as const, params: { deleted } }
    ]),
    redoList: []
  };
}

export function updateNode(
  state: GraphState,
  event: {
    formValues: FormValue;
    followExecute: boolean;
  }
) {
  const nodeId = state.selectedNodeId;
  if (nodeId === undefined) {
    return state;
  }

  return produce(state, (draft) => {
    draft.nodes[nodeId].status = NodeStatus.Executing;
    draft.nodes[nodeId].formValues = event.formValues;
    draft.nodes[nodeId].inPorts.forEach((pid) => {
      draft.ports[pid].status = PortStatus.Created;
    });
    draft.nodes[nodeId].outPorts.forEach((pid) => {
      draft.ports[pid].status = PortStatus.Created;
    });
    Object.keys(draft.edges).forEach((eid) => {
      if (draft.edges[eid].to.nodeId === nodeId) {
        draft.edges[eid].status = EdgeStatus.Created;
      }
    });
  });
}

function createLink(
  state: GraphState,
  payload: Pick<Edge, 'from' | 'to'>
): GraphState {
  const edgeId = GenerateId();
  if (Object.keys(state.edges).includes(edgeId)) {
    console.warn('CANNOT CREATE EDGE ID'); // tslint:disable-line
    return state;
  }

  const deletedEdges: Edge[] = [];
  let newState = produce(state, (draft) => {
    const deleteEdgeIds = Object.values(draft.edges)
      .filter((e) => e.to.portId === payload.to.portId)
      .map((e) => e.id);
    deleteEdgeIds.forEach((id) => {
      deletedEdges.push(state.edges[id]);
      delete draft.edges[id];
    });

    const newEdgeStatus =
      draft.nodes[payload.to.nodeId].status === NodeStatus.Temporary ||
      draft.nodes[payload.from.nodeId].status === NodeStatus.Temporary
        ? EdgeStatus.Temporary
        : EdgeStatus.Created;

    // テンポラリでない場合のみ、再実行してくださいにする
    if (draft.nodes[payload.to.nodeId].status !== NodeStatus.Temporary) {
      draft.nodes[payload.to.nodeId].status = NodeStatus.Warning;
      draft.nodes[payload.to.nodeId].errorMessage = '再実行してください';
    }
    draft.edges[edgeId] = {
      id: edgeId,
      ...payload,
      status: newEdgeStatus
    };
    if (draft.selectedNodeId !== payload.to.nodeId) {
      draft.previousNodeId = draft.selectedNodeId;
      draft.selectedNodeId = payload.to.nodeId;
    }
  });

  try {
    topologicalSort(newState.nodes, newState.edges);
  } catch (e) {
    alert('ループが発生するため、エッジの作成はできません');
    console.warn('circular dependent!!', e);
    return state;
  }

  const undoItem: UndoCreateEdge = {
    type: UndoType.CREATE_EDGE,
    params: {
      edge: newState.edges[edgeId],
      deletedEdges
    }
  };

  return {
    ...newState,
    undoList: cutArray([...newState.undoList, undoItem]),
    redoList: []
  };
}

export function removeLink(
  state: GraphState,
  payload: Pick<Edge, 'from' | 'to'>
) {
  const produced = produce(state, (draft) => {
    const deleteEdgeId = Object.values(draft.edges).find(
      (e) =>
        e.from.portId === payload.from.portId &&
        e.to.portId === payload.to.portId
    )?.id;
    if (deleteEdgeId === undefined) {
      return;
    }
    const undoItem: UndoRemoveEdge = {
      type: UndoType.REMOVE_EDGE,
      params: {
        edge: state.edges[deleteEdgeId]
      }
    };
    delete draft.edges[deleteEdgeId];

    if (draft.nodes[payload.to.nodeId].status !== NodeStatus.Temporary) {
      draft.nodes[payload.to.nodeId].status = NodeStatus.Warning;
      draft.nodes[payload.to.nodeId].errorMessage =
        'インプットが設定されていません';
    }
    draft.undoList.push(undoItem);
    draft.undoList = cutArray(draft.undoList);
    draft.redoList = [];
  });

  return produced;
}

export function updateNodeCoordinates(
  state: GraphState,
  payload: UpdateCoordinate[]
) {
  const [newState, redo, undo] = produceWithPatches(state, (draft) => {
    payload.forEach((coord) => {
      const { id, x, y } = coord;
      if (draft.nodes[id]) {
        draft.nodes[id].x = x;
        draft.nodes[id].y = y;
      }
    });
  });
  const newUndoList =
    undo.length === 0
      ? state.undoList
      : cutArray([
          ...state.undoList,
          {
            type: UndoType.NODE_POSITION as const,
            params: { undoPatch: undo, redoPatch: redo }
          }
        ]);
  return {
    ...newState,
    undoList: newUndoList,
    redoList: []
  };
}

export function updateNodeComment(
  state: GraphState,
  payload: { rows?: number; comment?: string; nodeId: string }
) {
  const { nodeId, comment, rows } = payload;
  return produce(state, (draft) => {
    if (!draft.nodes[nodeId]) {
      return;
    }
    if (comment !== undefined) {
      draft.nodes[nodeId].comment = comment;
    }

    if (rows !== undefined) {
      draft.nodes[nodeId].rows = rows;
    }
  });
}

export function setTemporaryFormValue(
  state: GraphState,
  payload: {
    formValues: FormValue;
    nodeId: string;
    formErrors: FormValidationError;
  }
) {
  const { nodeId, formValues, formErrors } = payload;
  return produce(state, (draft) => {
    draft.nodes[nodeId].formValues = formValues;
    draft.nodes[nodeId].formErrors = formErrors;
  });
}

export function clearTemporaryItems(
  state: GraphState
): Pick<GraphState, 'nodes' | 'edges' | 'ports' | 'selectedNodeId'> {
  const newNodes = { ...state.nodes };
  const newEdges = { ...state.edges };
  const newPorts = { ...state.ports };
  let { selectedNodeId, previousNodeId } = state;
  const tempNodes: string[] = [];

  Object.keys(state.nodes).forEach((key) => {
    if (newNodes[key].status === NodeStatus.Temporary) {
      tempNodes.push(key);
      newNodes[key].inPorts.forEach((portId) => {
        delete newPorts[portId];
      });
      newNodes[key].outPorts.forEach((portId) => {
        delete newPorts[portId];
      });
      delete newNodes[key];
    }
  });
  Object.values(state.edges).forEach((edge) => {
    if (edge.status === EdgeStatus.Temporary) {
      delete newEdges[edge.id];
    }
  });

  if (selectedNodeId && tempNodes.includes(selectedNodeId)) {
    selectedNodeId = previousNodeId;
  }

  return {
    nodes: newNodes,
    edges: newEdges,
    ports: newPorts,
    selectedNodeId
  };
}

function receiveError(
  state: GraphState,
  payload: { nodeId?: string; portId?: string; message: string }
) {
  const { nodeId, portId, message } = payload;
  const newState: Pick<GraphState, 'nodes' | 'ports'> = {
    nodes: { ...state.nodes },
    ports: { ...state.ports }
  };
  if (nodeId != undefined) {
    newState.nodes = {
      ...newState.nodes,
      [nodeId]: {
        ...newState.nodes[nodeId],
        status: NodeStatus.Error,
        errorMessage: message
      }
    };
  }

  if (portId) {
    const pNodeId = searchNodeFromPort(state.nodes, portId);
    newState.ports = {
      ...newState.ports,
      [portId]: { ...state.ports[portId], status: PortStatus.Error }
    };

    if (pNodeId != undefined) {
      newState['nodes'] = {
        ...newState.nodes,
        [pNodeId]: {
          ...newState.nodes[pNodeId],
          status: NodeStatus.Error,
          errorMessage: message
        }
      };
    }
  }
  return newState;
}

function validateColumnSelect(
  columns: string[],
  targetValues?: ColumnSelectFieldValue
): { isValid: boolean; newValue?: ColumnSelectFieldValue } {
  if (targetValues == undefined || columns == undefined) {
    return {
      isValid: true
    };
  }

  // 何も選択されていない場合
  if (Array.isArray(targetValues) && targetValues.length === 0) {
    return {
      isValid: true
    };
  }

  const paramTargetValues = Array.isArray(targetValues)
    ? targetValues
    : [targetValues];

  let valid = true;
  const selectedValues = paramTargetValues.map((value) => {
    // カラムが存在するかどうか
    if (!columns.includes(value.label)) {
      // 存在しないので、エラー扱いにする
      valid = false;
      return { ...value, isError: true };
    }

    // columnの順番が変わってるかもしれないので、更新してあげる
    const newValue = { ...value };
    delete newValue.isError;

    return newValue;
  });

  return {
    isValid: valid,
    newValue: Array.isArray(targetValues) ? selectedValues : selectedValues[0]
  };
}

function validateColumnInFormValue(
  state: GraphState,
  payload: {
    [nodeId: string]: {
      processPaths: string[][];
      columnInfo: { [name: string]: DataSummary };
      nameMap?: { [formKey: string]: string | string[] };
    };
  }
) {
  if (payload == undefined || Object.keys(payload).length === 0) {
    return state;
  }

  const nodes = produce(state.nodes, (draft) => {
    Object.keys(payload).forEach((nodeId) => {
      const { processPaths, columnInfo, nameMap } = payload[nodeId];
      const node = draft[nodeId];

      const columnSelectFormValues = getFormValuesByPath(
        processPaths,
        draft[nodeId].formValues
      );

      const validates = columnSelectFormValues.map((fv) => {
        const lastKeyName = fv.path[fv.path.length - 1];
        const inputName =
          nameMap && nameMap[lastKeyName] ? nameMap[lastKeyName] : 'Input';

        // inputNameが複数の場合は、共通カラムを選択なので、intersectionを取る
        const columns: string[] = Array.isArray(inputName)
          ? intersection(
              ...inputName.map((n) =>
                columnInfo[n] ? columnInfo[n].columns : []
              )
            )
          : columnInfo[inputName]
            ? columnInfo[inputName].columns
            : [];

        const { isValid, newValue } = validateColumnSelect(
          columns,
          fv.value as ColumnSelectFieldValue
        );
        if (newValue != undefined) {
          _set(draft[nodeId].formValues, fv.path, newValue);
        }
        return isValid;
      });

      const isValidNode = validates.every((v) => v);
      if (!isValidNode) {
        node.status = NodeStatus.Error;
        node.errorMessage = 'エラー: 存在しない列が選択されています';
      }
    });
  });

  return { nodes };
}

function updateGraphView(
  state: Graph,
  payload: {
    nodes: { [id: string]: { x: number; y: number } };
    positions: Positions;
  }
) {
  const [newState, redo, undo] = produceWithPatches(state, (draft) => {
    Object.keys(payload.nodes).forEach((nodeId) => {
      if (draft.nodes[nodeId]) {
        draft.nodes[nodeId].x = payload.nodes[nodeId].x;
        draft.nodes[nodeId].y = payload.nodes[nodeId].y;
      }
    });
  });
  const newUndoList =
    undo.length === 0
      ? state.undoList
      : [
          ...state.undoList,
          {
            type: UndoType.NODE_POSITION as const,
            params: { undoPatch: undo, redoPatch: redo }
          }
        ];
  return {
    ...newState,
    positions: payload.positions,
    undoList: cutArray(newUndoList),
    redoList: []
  };
}

function updateOutPortsObjectType(
  state: GraphState,
  payload: { nodeId: string; objectType: string }
) {
  const { nodeId, objectType } = payload;
  return produce(state, (draft) => {
    draft.nodes[nodeId].outPorts.forEach((portId) => {
      draft.ports[portId].objectType = objectType;
    });
  });
}

export default function nodeReducer(
  state: GraphState = graphInitState,
  action: any
): GraphState {
  switch (action.type) {
    case ActionTypes.CreateNode:
      return { ...state, ...createNode(state, action.payload) };

    case ActionTypes.SelectNode:
      if (state.selectedNodeId === action.payload) {
        return state;
      }
      return { ...state, ...selectNode(state, action.payload) };

    case ActionTypes.DeleteNode:
      return { ...state, ...deleteNode(state, action.payload) };

    case ActionTypes.UpdateNode:
      return { ...state, ...updateNode(state, action.payload) };

    case ActionTypes.CreateLink:
      return { ...state, ...createLink(state, action.payload) };

    case ActionTypes.RemoveLink:
      return { ...state, ...removeLink(state, action.payload) };

    case ActionTypes.UpdateNodeComment:
      return { ...state, ...updateNodeComment(state, action.payload) };

    case ActionTypes.UpdateNodeCoordinates:
      return { ...state, ...updateNodeCoordinates(state, action.payload) };

    case ActionTypes.ClearTemporaryItems:
      return { ...state, ...clearTemporaryItems(state) };

    case ActionTypes.SetTemporaryFormValue:
      return { ...state, ...setTemporaryFormValue(state, action.payload) };

    case ActionTypes.ReceiveError:
      return { ...state, ...receiveError(state, action.payload) };

    case ActionTypes.ValidateColumnInFormValues:
      return { ...state, ...validateColumnInFormValue(state, action.payload) };

    case ActionTypes.UpdateGraphView:
      return updateGraphView(state, action.payload);

    case ActionTypes.UpdateOutPortsObjectType:
      return { ...state, ...updateOutPortsObjectType(state, action.payload) };

    case ActionTypes.UndoGraph:
      return undo(state);

    case ActionTypes.RedoGraph:
      return redo(state);

    default:
      return state;
  }
}
