import axios from 'axios';

import Cookies from 'universal-cookie';
import { Chart, QueryData } from 'models/chart';
import { CardParameter } from 'models/report';
import { DataResponse } from 'models/data';
import { _postWithFixedToken, APIUrl } from 'libs/api/base';

export const clientIdName = 'nehan_client_id';
// cookieも一緒に送るための設定
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
const cookies = new Cookies();

function getBaseHeader(): Record<string, string> {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Nehan-Client-ID': cookies.get(clientIdName)
  };
}

const createApiUrl = (path: string): string => {
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }
  return `${APIUrl}${path}`;
};

/* tslint:disable:variable-name */

const _getWithAccessToken = <T = any>(
  token: string,
  path: string,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get<T>(`${createApiUrl(path)}?access_token=${token}`, {
    headers: { ...getBaseHeader(), ...options }
  });
};

const _getWithoutToken = <T = any>(
  path: string,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get<T>(`${createApiUrl(path)}`, {
    headers: { ...getBaseHeader(), ...options }
  });
};

const _postWithoutToken = <T = any>(
  path: string,
  body: any,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.post<T>(`${createApiUrl(path)}`, JSON.stringify(body), {
    headers: { ...getBaseHeader(), ...options }
  });
};

const _getWithSharedToken = <T = any>(
  token: string,
  path: string,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get<T>(`${createApiUrl(path)}?shared_token=${token}`, {
    headers: { ...getBaseHeader(), ...options }
  });
};

const _post = <T = any>(token: string, path: string, body: any) => {
  return axios.post<T>(createApiUrl(path), JSON.stringify(body), {
    headers: {
      ...getBaseHeader(),
      Authorization: `Bearer ${token}`
    }
  });
};

const _postWithSharedToken = (token: string, path: string, body: any) => {
  return axios.post(
    `${createApiUrl(path)}?shared_token=${token}`,
    JSON.stringify(body),
    {
      headers: getBaseHeader(),
      withCredentials: true
    }
  );
};

const _put = (token: string, path: string, body: any) => {
  return axios.put(createApiUrl(path), JSON.stringify(body), {
    headers: {
      ...getBaseHeader(),
      Authorization: `Bearer ${token}`
    }
  });
};

/* tslint:enable */

// projects
export async function getPortData(
  token: string,
  projectId: string,
  portId: string,
  lastModified?: string
) {
  return _getWithAccessToken<DataResponse>(
    token,
    `/projects/${projectId}/port/${portId}`,
    {
      'If-Modified-Since': lastModified
    }
  );
}

export async function getPortDataWithoutToken(
  projectId: string,
  portId: string,
  lastModified?: string
) {
  return _getWithoutToken<DataResponse>(
    `/projects/${projectId}/port/${portId}`,
    {
      'If-Modified-Since': lastModified
    }
  );
}

export async function getHtmlData(
  token: string,
  projectId: string,
  portId: string
) {
  return _getWithAccessToken(token, `projects/${projectId}/htmls/${portId}`);
}

export async function getImageData(
  token: string,
  projectId: string,
  portId: string
) {
  return _getWithAccessToken(token, `projects/${projectId}/images/${portId}`);
}

export const getPortDataWithSharedToken: typeof getPortData = (
  token,
  projectId,
  portId,
  lastModified
) => {
  return _getWithSharedToken<DataResponse>(
    token,
    `/embed/projects/${projectId}/port/${portId}`,
    { 'If-Modified-Since': lastModified }
  );
};

export const getImageDataWithSharedToken: typeof getImageData = (
  token,
  projectId,
  portId
) => {
  return _getWithSharedToken(
    token,
    `/embed/projects/${projectId}/images/${portId}`
  );
};

export const getHtmlDataWithSharedToken: typeof getHtmlData = (
  token,
  projectId,
  portId
) => {
  return _getWithSharedToken(
    token,
    `/embed/projects/${projectId}/htmls/${portId}`
  );
};

export function uploadCSV(
  token: string,
  file: File,
  onProgress: XMLHttpRequestEventTarget['onprogress'],
  onLoad: XMLHttpRequestEventTarget['onload'],
  onError?: XMLHttpRequestEventTarget['onerror']
) {
  const xhr = new XMLHttpRequest();
  xhr.upload.onprogress = onProgress;
  xhr.onload = onLoad;
  if (onError != undefined) {
    xhr.onerror = onError;
    xhr.onabort = onError;
    xhr.ontimeout = onError;
  }

  xhr.open('POST', `${APIUrl}/datasources/csv`);
  xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  const formData = new FormData();
  formData.append('file', file);
  xhr.send(formData);
}

export interface DatasourceParams {
  name: string;
  type: string;
  params: any;
  connectionId?: number;
  connection?: any;
}

interface NehanDatasourceParams extends DatasourceParams {
  type: 'nehan_storage';
  nodeId: string;
  portId: string;
  projectId: string;
}

export function addDatasource(
  token: string,
  data: DatasourceParams | NehanDatasourceParams
) {
  return _post(token, '/datasources', data);
}

export function updateDatasource(
  token: string,
  id: number,
  data: DatasourceParams | NehanDatasourceParams
) {
  return _put(token, `/datasources/${id}`, data);
}

export function executeChartQuery(
  projectUUID: string,
  config: Chart,
  token?: string,
  datasourceId?: number
) {
  const { chart, type, port_id, view } = config;
  const body = {
    chart,
    type,
    params: [],
    project_id: projectUUID,
    port_id,
    datasource_id: datasourceId,
    view
  };
  if (token) {
    return _post<QueryData>(token, `/chart_query`, body);
  }
  return _postWithoutToken<QueryData>(`/chart_query`, body);
}

export function executeReportChartQuery(
  reportUUID: string,
  config: Chart,
  reportType?: 'share' | 'preview',
  params?: CardParameter[],
  token?: string
) {
  const { chart, type, port_id, view } = config;
  const body = {
    chart,
    type,
    params,
    report_id: reportUUID,
    project_id: config.project_uuid,
    port_id,
    view
  };
  if (reportType === 'preview' && token) {
    return _postWithFixedToken(token, `/chart_preview_query`, body);
  }
  if (reportType === 'share' && token) {
    return _postWithSharedToken(token, `/embed/chart_query`, body);
  } else if (token) {
    return _post(token, `/reports/chart_query`, body);
  } else {
    return _postWithoutToken(`/reports/chart_query`, body);
  }
}
