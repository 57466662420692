import { Dtypes } from 'Utils/dataTypes';
import { TimeParameter, Parameter, ParameterType } from 'models/report';
import { ParameterClass, TimeParameterClass } from './base';
import { DateSingleClass } from './dateSingle';
import { DateRangeClass } from './dateRange';
import { DatetimeRangeClass } from './datetimeRange';
import { DatetimeSingleClass } from './datetimeSingle';
import { NumberRangeClass } from './numberRange';
import { NumberSingleClass } from './numberSingle';
import { StringMultipleClass } from './stringMultiple';
import { StringSingleClass } from './stringSingle';
import { TimeRangeClass } from './timeRange';
import { TimeSingleClass } from './timeSingle';
import { StringOptions } from '../useStringOptions';

export class ParameterClassFactory {
  createInstance(
    param: Parameter,
    stringOptions?: StringOptions
  ): ParameterClass {
    switch (param.dtype) {
      case Dtypes.STRING: {
        if (param.type === ParameterType.Single) {
          return new StringSingleClass(param, stringOptions);
        }
        return new StringMultipleClass(param, stringOptions);
      }
      case Dtypes.NUMBER: {
        if (param.type === ParameterType.Single) {
          return new NumberSingleClass(param);
        }
        return new NumberRangeClass(param);
      }
      case Dtypes.DATE:
      case Dtypes.TIMESTAMP:
      case Dtypes.TIME: {
        const factory = new DateParameterClassFactory();
        const dateInstance = factory.createInstance(param);
        return dateInstance;
      }
      default:
        const ex: never = param;
        return ex;
    }
  }
}

export class DateParameterClassFactory {
  createInstance(param: TimeParameter): TimeParameterClass {
    switch (param.dtype) {
      case Dtypes.DATE: {
        if (param.type === ParameterType.Single) {
          return new DateSingleClass(param);
        }
        return new DateRangeClass(param);
      }
      case Dtypes.TIMESTAMP: {
        if (param.type === ParameterType.Single) {
          return new DatetimeSingleClass(param);
        }
        return new DatetimeRangeClass(param);
      }
      case Dtypes.TIME: {
        if (param.type === ParameterType.Single) {
          return new TimeSingleClass(param);
        }
        return new TimeRangeClass(param);
      }
      default:
        const ex: never = param;
        return ex;
    }
  }
}
