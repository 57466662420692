import { produce } from 'immer';
import {
  ConfigStatus,
  Schedule,
  ScheduleHistoryStatus,
  ScheduleItemStatus,
  ScheduleRelationship,
  ScheduleStepDatasource,
  ScheduleStepProject,
  ScheduleStepWorkflow
} from 'models/schedule';

export function sortSteps(
  a: ScheduleStepDatasource | ScheduleStepProject | ScheduleStepWorkflow,
  b: ScheduleStepDatasource | ScheduleStepProject | ScheduleStepWorkflow
): number {
  if (a.level < b.level) return -1;
  if (a.level > b.level) return 1;
  if (a.step_index < b.step_index) return -1;
  if (a.step_index > b.step_index) return 1;
  return a.name.localeCompare(b.name);
}

type RowType =
  | 'relation'
  | 'datasource'
  | 'project'
  | 'workflow'
  | 'report'
  | 'nehan_internal'
  | 'export';

export interface Row {
  id: string;
  name: string;
  type: RowType;
  item_type: string;
  item_id: string;
  item_status: ScheduleItemStatus;
  item_error: string;
  relationship: ScheduleRelationship;
  step?: ScheduleStepDatasource | ScheduleStepProject | ScheduleStepWorkflow;
  columnIndex: number;
  columnWidth: number;
  data?: Row[];
  groupContext?: 'top' | 'bottom' | 'middle';
  groupColumnIndex?: number;
  groupWidth?: number;
  status?: ScheduleHistoryStatus;
  started_at?: string;
  ended_at?: string;
  isOutput?: boolean;
  export_type?: string;
  project_id?: string;
  node_id?: string;
  configStatus: ConfigStatus;
  error: string;
  relIndex: number;
  relationshipLength: number;
  stepIndex: number;
  workflowIndex: number;
  relationshipId: string;
  relationshipType: string;
  relationshipProjectId: string; // ワークフロースタートの場合に使う
  relationshipWorkflowId: string; // ワークフロースタートの場合に使う
}

export type ScheduleAction =
  | {
      type: 'load';
      schedule: Schedule;
    }
  | {
      type: 'update';
      schedule: Schedule;
    }
  | {
      type: 'delete';
      row: Row;
    }
  | {
      type: 'delete_multi';
      rows: Row[];
    }
  | {
      type: 'change_name';
      name: string;
    }
  | {
      type: 'change_ignore_error';
      checked: boolean;
    }
  | {
      type: 'change_relationship_ignore';
      indices: number[];
    }
  | {
      type: 'change_relationship_name';
      name: string;
      relationship_index: number;
    }
  | {
      type: 'move';
      step: ScheduleStepDatasource | ScheduleStepProject | ScheduleStepWorkflow;
      relationship: ScheduleRelationship;
      direction: 'left' | 'right';
    }
  | {
      type: 'add_relationship';
      relationship: ScheduleRelationship;
    }
  | {
      type: 'update_relationship';
      relationship: ScheduleRelationship;
      index: number;
    }
  | {
      type: 'move_relationship';
      index: number;
      direction: 'up' | 'down';
    };

export type ScheduleState = Schedule & {
  changed: boolean;
};

interface LevelIndexCache {
  level: number;
  count: number;
  maxIndex: number;
}

export function maxStepIndexPerLevel(
  s: Schedule
): [
  Record<number, Record<number, LevelIndexCache>>,
  Record<string, Record<number, LevelIndexCache>>
] {
  const ret: Record<number, Record<number, LevelIndexCache>> = {};
  const projectStepIndexPerLevel: Record<
    string,
    Record<number, LevelIndexCache>
  > = {};
  s.relationships.forEach((rel, i) => {
    const relret: Record<number, LevelIndexCache> = {};
    [...rel.steps].sort(sortSteps).forEach((step) => {
      if (relret[step.level]) {
        if (relret[step.level].maxIndex < step.step_index) {
          relret[step.level].maxIndex = step.step_index;
        } else if (relret[step.level].maxIndex == step.step_index) {
          relret[step.level].count++;
        }
      } else {
        relret[step.level] = {
          level: step.level,
          count: 1,
          maxIndex: step.step_index
        };
      }
      if (step.item_type === 'project') {
        projectStepIndexPerLevel[step.item_id] =
          maxWorkflowStepIndexPerLevel(step);
      }
    });
    ret[i] = relret;
  });
  return [ret, projectStepIndexPerLevel];
}

function maxWorkflowStepIndexPerLevel(
  s: ScheduleStepProject
): Record<number, LevelIndexCache> {
  const ret: Record<number, LevelIndexCache> = {};
  [...s.workflows].sort(sortSteps).forEach((step) => {
    if (ret[step.level]) {
      if (ret[step.level].maxIndex < step.step_index) {
        ret[step.level].maxIndex = step.step_index;
      } else if (ret[step.level].maxIndex == step.step_index) {
        ret[step.level].count++;
      }
    } else {
      ret[step.level] = {
        level: step.level,
        count: 1,
        maxIndex: step.step_index
      };
    }
  });
  return ret;
}

export function isMovableLeft(
  _cache: LevelIndexCache | undefined,
  row: Row
): boolean {
  return row.step?.step_index !== 0;
}

export function isMovableRight(
  cache: LevelIndexCache | undefined,
  row: Row
): boolean {
  if (row.type === 'relation') {
    return false;
  }
  if (cache == undefined) {
    return false;
  }
  if (row.step == undefined) {
    return false;
  }
  if (row.step.step_index < cache.maxIndex) {
    // 右にまだ要素があるなら、右に移動できる
    return true;
  }
  if (row.step.step_index === cache.maxIndex) {
    // 最大のところに２つ並んでる場合は、右に移動できる
    return cache.count > 1;
  }

  // なんかおかしいパターン
  console.log('なんかおかしいパターン', cache, row.stepIndex);
  return false;
}

function deleteMulti(state: ScheduleState, rows: Row[]): ScheduleState {
  return produce(state, (draft) => {
    rows.forEach((row) => {
      // indexが変わるので、deleteで削除する
      switch (row.type) {
        case 'relation':
          delete draft.relationships[row.relIndex];
          break;
        case 'datasource':
        case 'project': {
          const idx = draft.relationships[row.relIndex].steps.findIndex((s) => {
            if (s == undefined) {
              return false;
            }
            return s.item_id === row.item_id;
          });
          if (idx !== -1) {
            delete draft.relationships[row.relIndex].steps[idx];
          }
          break;
        }
        case 'workflow':
          {
            const step = draft.relationships[row.relIndex]?.steps.find(
              (s) => s && s.item_id === row.project_id
            );
            if (step == undefined) {
              // 親が先に削除されると、stepがundefinedになる
              break;
            }
            if (step.item_type === 'project') {
              const idx = step.workflows.findIndex(
                (w) => w && w.workflow_id === row.item_id
              );
              if (idx !== -1) {
                delete step.workflows[idx];
              }
            }
          }
          break;
      }
    });

    // deleteでundefinedになった値を詰める
    for (let i = 0; i < draft.relationships.length; i++) {
      if (draft.relationships[i]) {
        for (let j = 0; j < draft.relationships[i].steps.length; j++) {
          if (
            draft.relationships[i].steps[j] &&
            draft.relationships[i].steps[j].item_type === 'project'
          ) {
            // @ts-ignore
            draft.relationships[i].steps[j].workflows =
              // @ts-ignore
              draft.relationships[i].steps[j].workflows.filter(Boolean);
          }
        }
        draft.relationships[i].steps =
          draft.relationships[i].steps.filter(Boolean);
      }
    }
    draft.relationships = draft.relationships.filter(Boolean);
    draft.changed = true;
  });
}

export function scheduleReducer(
  state: ScheduleState,
  action: ScheduleAction
): ScheduleState {
  switch (action.type) {
    case 'load': {
      return { ...action.schedule, changed: false };
    }
    case 'update':
      return { ...action.schedule, changed: true };

    case 'change_name':
      return { ...state, name: action.name, changed: true };

    case 'change_relationship_name':
      return produce(state, (draft) => {
        draft.relationships[action.relationship_index].name = action.name;
        draft.changed = true;
      });

    case 'change_ignore_error':
      return { ...state, ignore_error_enabled: action.checked, changed: true };

    case 'change_relationship_ignore':
      return produce(state, (draft) => {
        for (let i = 0; i < draft.relationships.length; i++) {
          draft.relationships[i].ignore_error = action.indices.includes(i);
        }
        draft.changed = true;
      });

    case 'delete':
      return deleteMulti(state, [action.row]);

    case 'delete_multi':
      return deleteMulti(state, action.rows);

    case 'add_relationship': {
      return produce(state, (draft) => {
        draft.relationships.push(action.relationship);
        draft.changed = true;
      });
    }

    case 'update_relationship': {
      return produce(state, (draft) => {
        draft.relationships[action.index] = action.relationship;
        draft.changed = true;
        return;
      });
    }

    case 'move_relationship': {
      if (state.relationships.length === 1) {
        return state;
      }
      if (action.direction === 'up' && action.index === 0) {
        return state;
      }
      if (
        action.direction === 'down' &&
        action.index === state.relationships.length - 1
      ) {
        return state;
      }
      return produce(state, (draft) => {
        const target = draft.relationships[action.index];
        if (action.direction === 'up') {
          draft.relationships[action.index] =
            draft.relationships[action.index - 1];
          draft.relationships[action.index - 1] = target;
        } else {
          draft.relationships[action.index] =
            draft.relationships[action.index + 1];
          draft.relationships[action.index + 1] = target;
        }
        draft.changed = true;
      });
    }

    case 'move': {
      return produce(state, (draft) => {
        for (let wi = 0; wi < draft.relationships.length; wi++) {
          const rel = draft.relationships[wi];

          // リレーションを探す
          if (
            action.relationship.start_id !== rel.start_id ||
            action.relationship.start_type !== rel.start_type
          ) {
            continue;
          }

          // リレーションが見つかったので、stepを探す
          // プロジェクト全体と、データソースの移動
          if (
            action.step.item_type === 'project' ||
            action.step.item_type === 'datasource'
          ) {
            for (let i = 0; i < rel.steps.length; i++) {
              const step = rel.steps[i];

              if (step.item_id === action.step.item_id) {
                if (action.direction === 'left') {
                  step.step_index--;
                } else if (action.direction === 'right') {
                  step.step_index++;
                }
                break;
              }
            }
            // stepのノーマライズ
            normalizeStepIndex(rel.steps);
            draft.changed = true;
            return;
          }

          // ワークフローの移動
          if (action.step.item_type === 'workflow') {
            for (let i = 0; i < rel.steps.length; i++) {
              const step = rel.steps[i];

              if (
                step.item_type === 'project' &&
                step.item_id === action.step.project_id
              ) {
                for (let j = 0; j < step.workflows.length; j++) {
                  const w = step.workflows[j];
                  if (action.step.item_id === w.item_id) {
                    if (action.direction === 'left') {
                      w.step_index--;
                    } else if (action.direction === 'right') {
                      w.step_index++;
                    }
                    break;
                  }
                }
                // stepのノーマライズ
                normalizeStepIndex(step.workflows);
                draft.changed = true;
                return;
              }
            }
          }
        }
      });
    }
  }

  return state;
}

export function normalizeStepIndex(
  steps:
    | Array<ScheduleStepProject | ScheduleStepDatasource>
    | ScheduleStepWorkflow[]
) {
  // stepのノーマライズ
  steps.sort(sortSteps);
  let lastIndex = 0;
  let lastLevel = 0;
  for (let i = 0; i < steps.length; i++) {
    if (lastLevel !== steps[i].level) {
      lastIndex = 0;
    }
    steps[i].step_index = lastIndex;
    if (
      steps[i + 1] !== undefined &&
      steps[i].step_index !== steps[i + 1].step_index
    ) {
      lastIndex++;
    }
    lastLevel = steps[i].level;
  }
}
