import { Dtypes } from 'Utils/dataTypes';
import { DataResponseBodyV2 } from './data';
import { Palette } from 'components/visualize/chart/color';
import { ReflectType } from 'components/ui/dataTable';
import { ColumnSelectRules } from 'models/form/value';
import { ChartData } from 'components/visualize/transformer';

export type Chart =
  | GenericsChartSettings<ChartType.bar, Partial<Bar>>
  | GenericsChartSettings<ChartType.column, Partial<Column>>
  | GenericsChartSettings<ChartType.line, Partial<Line>>
  | GenericsChartSettings<ChartType.pie, Partial<Pie>>
  | GenericsChartSettings<ChartType.scatter, Partial<Scatter>>
  | GenericsChartSettings<ChartType.table, Partial<Table>>
  | GenericsChartSettings<ChartType.area, Partial<Area>>
  | GenericsChartSettings<ChartType.boxAndWhisker, Partial<BoxAndWhisker>>
  | GenericsChartSettings<ChartType.radar, Partial<Radar>>
  | GenericsChartSettings<
      ChartType.dualAxisColumnLine,
      Partial<DualAxisColumnLine>
    >
  | GenericsChartSettings<ChartType.histogram, Partial<Histogram>>;

export type NullableFusionChartConfigure =
  | GenericsChartSettings<FusionChartType.Bar, Partial<Bar>>
  | GenericsChartSettings<
      FusionChartType.MultipleSeriesBar,
      Partial<GroupedBar>
    >
  | GenericsChartSettings<FusionChartType.StackedBar, Partial<GroupedBar>>
  | GenericsChartSettings<FusionChartType.Column, Partial<Column>>
  | GenericsChartSettings<
      FusionChartType.MultipleSeriesColumn,
      Partial<GroupedColumn>
    >
  | GenericsChartSettings<FusionChartType.StackedColumn, Partial<GroupedBar>>
  | GenericsChartSettings<FusionChartType.Line, Partial<Line>>
  | GenericsChartSettings<
      FusionChartType.MultipleSeriesLine,
      Partial<GroupedLine>
    >
  | GenericsChartSettings<FusionChartType.Pie, Partial<Pie>>
  | GenericsChartSettings<FusionChartType.Scatter, Partial<Scatter>>
  | GenericsChartSettings<FusionChartType.Area, Partial<Area>>
  | GenericsChartSettings<
      FusionChartType.MultipleSeriesArea,
      Partial<GroupedArea>
    >
  | GenericsChartSettings<FusionChartType.StackedArea, Partial<GroupedArea>>
  | GenericsChartSettings<FusionChartType.BoxAndWhisker, Partial<BoxAndWhisker>>
  | GenericsChartSettings<FusionChartType.Radar, Partial<Radar>>
  | GenericsChartSettings<FusionChartType.MultiAxisLine, Partial<GroupedLine>>
  | GenericsChartSettings<
      FusionChartType.DualAxisColumnLine,
      Partial<DualAxisColumnLine>
    >
  | GenericsChartSettings<
      FusionChartType.DualAxisColumnLineStacked,
      Partial<DualAxisColumnLine>
    >
  | GenericsChartSettings<FusionChartType.Histogram, Partial<Histogram>>
  | GenericsChartSettings<
      FusionChartType.MultipleHistogram,
      Partial<GroupedHistogram>
    >;

export type FusionChartConfigure =
  | GenericsChartSettings<FusionChartType.Bar, Bar>
  | GenericsChartSettings<FusionChartType.MultipleSeriesBar, GroupedBar>
  | GenericsChartSettings<FusionChartType.Column, Column>
  | GenericsChartSettings<FusionChartType.MultipleSeriesColumn, GroupedColumn>
  | GenericsChartSettings<FusionChartType.StackedColumn, GroupedColumn>
  | GenericsChartSettings<FusionChartType.Line, Line>
  | GenericsChartSettings<FusionChartType.MultipleSeriesLine, GroupedLine>
  | GenericsChartSettings<FusionChartType.StackedBar, GroupedLine>
  | GenericsChartSettings<FusionChartType.Pie, Pie>
  | GenericsChartSettings<FusionChartType.Scatter, Scatter>
  | GenericsChartSettings<FusionChartType.Area, Area>
  | GenericsChartSettings<FusionChartType.MultipleSeriesArea, GroupedArea>
  | GenericsChartSettings<FusionChartType.StackedArea, GroupedArea>
  | GenericsChartSettings<FusionChartType.BoxAndWhisker, BoxAndWhisker>
  | GenericsChartSettings<FusionChartType.Radar, Radar>
  | GenericsChartSettings<FusionChartType.MultiAxisLine, GroupedLine>
  | GenericsChartSettings<
      FusionChartType.DualAxisColumnLine,
      DualAxisColumnLine
    >
  | GenericsChartSettings<
      FusionChartType.DualAxisColumnLineStacked,
      DualAxisColumnLine
    >
  | GenericsChartSettings<FusionChartType.Histogram, Histogram>
  | GenericsChartSettings<FusionChartType.MultipleHistogram, GroupedHistogram>;

export type FusionChartCrossTableConfigure =
  | GenericsChartSettings<FusionChartType.MultipleSeriesBar, GroupedBar>
  | GenericsChartSettings<FusionChartType.MultipleSeriesColumn, GroupedColumn>
  | GenericsChartSettings<FusionChartType.StackedBar, GroupedLine>
  | GenericsChartSettings<FusionChartType.StackedColumn, GroupedColumn>
  | GenericsChartSettings<FusionChartType.MultipleSeriesLine, GroupedLine>
  | GenericsChartSettings<FusionChartType.MultipleSeriesArea, GroupedArea>
  | GenericsChartSettings<FusionChartType.StackedArea, GroupedArea>
  | GenericsChartSettings<FusionChartType.Radar, Radar>
  | GenericsChartSettings<FusionChartType.MultiAxisLine, GroupedLine>
  | GenericsChartSettings<FusionChartType.Scatter, Scatter>;

export type ChartSettingType =
  | GenericsChartType<ChartType.bar, Partial<Bar>>
  | GenericsChartType<ChartType.column, Partial<Column>>
  | GenericsChartType<ChartType.line, Partial<Line>>
  | GenericsChartType<ChartType.pie, Partial<Pie>>
  | GenericsChartType<ChartType.scatter, Partial<Scatter>>
  | GenericsChartType<ChartType.table, Partial<Table>>
  | GenericsChartType<ChartType.area, Partial<Area>>
  | GenericsChartType<ChartType.boxAndWhisker, Partial<BoxAndWhisker>>
  | GenericsChartType<ChartType.radar, Partial<Radar>>
  | GenericsChartType<ChartType.dualAxisColumnLine, Partial<DualAxisColumnLine>>
  | GenericsChartType<ChartType.histogram, Partial<Histogram>>;

export type ChartConfigs =
  | Partial<Bar>
  | Partial<Column>
  | Partial<Line>
  | Partial<Pie>
  | Partial<Scatter>
  | Partial<Table>
  | Partial<Area>
  | Partial<BoxAndWhisker>
  | Partial<Radar>
  | Partial<DualAxisColumnLine>
  | Partial<Histogram>;

interface CommonChartSettings {
  uuid?: string;
  project_uuid: string;
  node_id: string;
  port_id: string;
  name: string;
  view: View;
  color: Color;
  originColumns?: Option[];
}

export type GenericsChartSettings<T, S> = CommonChartSettings &
  GenericsChartType<T, S>;

export interface GenericsChartType<T, S> {
  type: T;
  chart: S;
}

export interface YValue {
  column?: Option;
  func?: string;
}

export interface Option {
  value: string;
  label: string;
  name?: string;
  dtype?: string;
  isError?: boolean;
  errorType?: string;
  icon?: JSX.Element;
}

export enum ChartType {
  bar = 'bar',
  column = 'column',
  line = 'line',
  pie = 'pie',
  scatter = 'scatter',
  table = 'table',
  area = 'area',
  boxAndWhisker = 'boxAndWhisker',
  radar = 'radar',
  dualAxisColumnLine = 'dualAxisColumnLine',
  histogram = 'histogram'
}

function isGrouped(settings: ChartSettingType) {
  switch (settings.type) {
    case ChartType.bar:
    case ChartType.line:
    case ChartType.column:
    case ChartType.area:
    case ChartType.boxAndWhisker:
    case ChartType.radar:
    case ChartType.histogram:
      return settings.chart.legend != undefined;
    default:
      return false;
  }
}

export enum FusionChartType {
  Bar = 'bar2d',
  MultipleSeriesBar = 'msbar2d',
  StackedBar = 'stackedbar2d',
  Column = 'column2d',
  MultipleSeriesColumn = 'mscolumn2d',
  StackedColumn = 'stackedcolumn2d',
  Line = 'line',
  MultipleSeriesLine = 'msline',
  Pie = 'pie2d',
  Scatter = 'scatter',
  Area = 'area2d',
  MultipleSeriesArea = 'MSArea',
  StackedArea = 'stackedarea2d',
  BoxAndWhisker = 'boxandwhisker2d',
  Radar = 'radar',
  DualAxisColumnLine = 'mscombidy2d',
  DualAxisColumnLineStacked = 'stackedcolumn2dlinedy',
  MultiAxisLine = 'MultiAxisLine',
  Histogram = 'Histogram',
  MultipleHistogram = 'MultipleHistogram',
  TimeSeries = 'timeseries' // x軸が日付、日付時間、時間型の場合
}

export function getFusionChartType(
  settings: ChartSettingType
): FusionChartType {
  const grouped = isGrouped(settings);
  switch (settings.type) {
    case ChartType.bar:
      if (grouped) {
        return settings.chart.stacked
          ? FusionChartType.StackedBar
          : FusionChartType.MultipleSeriesBar;
      }

      // 値が複数ある場合
      if (settings.chart.y && settings.chart.y.length > 1) {
        return FusionChartType.MultipleSeriesBar;
      }
      return FusionChartType.Bar;
    case ChartType.column:
      if (grouped) {
        return settings.chart.stacked
          ? FusionChartType.StackedColumn
          : FusionChartType.MultipleSeriesColumn;
      }

      if (settings.chart.y && settings.chart.y.length > 1) {
        return FusionChartType.MultipleSeriesColumn;
      }
      return FusionChartType.Column;
    case ChartType.line:
      if (settings.chart.multiAxis) {
        return FusionChartType.MultiAxisLine;
      }
      if (grouped) {
        return FusionChartType.MultipleSeriesLine;
      }

      if (settings.chart.y && settings.chart.y.length > 1) {
        return FusionChartType.MultipleSeriesLine;
      }
      return FusionChartType.Line;
    case ChartType.pie:
      return FusionChartType.Pie;
    case ChartType.scatter:
      return FusionChartType.Scatter;
    case ChartType.area:
      if (grouped) {
        return settings.chart.stacked
          ? FusionChartType.StackedArea
          : FusionChartType.MultipleSeriesArea;
      }

      // 値が複数ある場合
      if (settings.chart.y && settings.chart.y.length > 1) {
        return FusionChartType.MultipleSeriesArea;
      }
      return FusionChartType.Area;
    case ChartType.boxAndWhisker:
      return FusionChartType.BoxAndWhisker;
    case ChartType.radar:
      return FusionChartType.Radar;
    case ChartType.dualAxisColumnLine:
      return settings.chart.stacked
        ? FusionChartType.DualAxisColumnLineStacked
        : FusionChartType.DualAxisColumnLine;
    case ChartType.histogram:
      if (
        (settings.chart.y && settings.chart.y.length > 1) ||
        settings.chart.legend
      ) {
        return FusionChartType.MultipleHistogram;
      }
      return FusionChartType.Histogram;
    default:
      throw new Error(`type: ${settings.type} is not supported`);
  }
}

// 実際に使うfusionchartsの型に変換(ヒストグラムのtypeはfusionchartsに存在しない)
export function getUseFusionChartType(settings: Chart): FusionChartType {
  const { type, view, chart } = settings;
  switch (type) {
    case ChartType.column:
    case ChartType.line:
    case ChartType.dualAxisColumnLine:
    case ChartType.area:
      if (
        'x' in chart &&
        chart.x?.dtype != null &&
        [Dtypes.DATE, Dtypes.TIME, Dtypes.TIMESTAMP].includes(
          chart.x?.dtype as Dtypes
        )
      ) {
        return FusionChartType.TimeSeries;
      }
  }

  const fusionChartType = getFusionChartType(settings);
  switch (fusionChartType) {
    case FusionChartType.Histogram:
      if (view.isLineChart) {
        return FusionChartType.Line;
      }
      return FusionChartType.Column;
    case FusionChartType.MultipleHistogram:
      if (view.isLineChart) {
        return FusionChartType.MultipleSeriesLine;
      }
      return FusionChartType.MultipleSeriesColumn;
    default:
      return fusionChartType;
  }
}

export type Orientation = 'horizontal' | 'vertical';

interface CommonChart {
  x: Option;
  y: YValue[];
  legend?: Option;
}

export enum Xtimeformattype {
  year = 'year',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
  millisecond = 'millisecond',
  time_hour = 'time_hour',
  time_minute = 'time_minute',
  time_second = 'time_second',
  time_millisecond = 'time_millisecond',
  time_microsecond = 'time_microsecond',
  custom = 'custom',
  auto = 'auto' // 今はない。過去はあった？
}

interface XTimeFormat {
  xtimeformattype?: Xtimeformattype;
  xtimeformat?: string;
}

interface Aggregate {
  aggregated: boolean;
}
interface Stack {
  stacked: boolean;
}

interface MultiAxis {
  multiAxis: boolean;
}

// legendの列で集計するもの
interface GroupedChart {
  x: Option;
  y: YValue[];
  legend: Option;
}

export type DualAxisColumnLineType = 'column' | 'line';

export type Bar = CommonChart & Aggregate & Stack & XTimeFormat & Order;
export type Column = CommonChart & Aggregate & Stack & XTimeFormat & Order;
export type Line = CommonChart & Aggregate & XTimeFormat & Order & MultiAxis;
export type Scatter = CommonChart & Order & Aggregate;
export type Pie = {
  legend: Option;
  value: YValue;
} & Aggregate &
  Order;
export type Area = CommonChart & Aggregate & Stack & XTimeFormat & Order;
export type BoxAndWhisker = {
  y: YValue[];
  legend?: Option;
} & Aggregate &
  XTimeFormat &
  Order;
export type Radar = CommonChart & Aggregate & XTimeFormat & Order;
export type DualAxisColumnLine = {
  x: Option;
  dualAxisY: { column: YValue[]; line: YValue[] };
  dualAxisLegend: { column?: Option; line?: Option };
  dualAxisOrder: {
    x: OrderOptions;
    legend: { column: OrderOptions; line: OrderOptions };
  };
} & Aggregate &
  Stack &
  XTimeFormat;
export type Histogram = {
  y: YValue[];
  legend?: Option;
  numBuckets: number;
} & Order &
  Aggregate;
type GroupedHistogram = {
  y: YValue[];
  legend: Option;
  numBuckets: number;
} & Order &
  Aggregate;

export enum AggregateFunc {
  sum = 'SUM',
  avg = 'AVG',
  max = 'MAX',
  min = 'MIN',
  count = 'COUNT',
  countAll = 'COUNT_ALL',
  uniqCnt = 'UNIQ_CNT'
}

export interface TableColumn extends Option {
  aggFunc?: AggregateFunc;
  width?: number;
  align?: 'center' | 'left' | 'right';
  setTextColor?: boolean;
  textColorPalette?: Palette;
  textColor?: string;
  dir?: 'desc' | 'asc';
  showProgress?: boolean; // ミニバーの表示
  progressColorPalette?: Palette; // ミニバーのカラーパレット
  color?: string; // ミニバーの色
  showCellColor?: boolean; // 条件付き書式
  palette?: Palette; // 条件付き書式のカラーパレット
  toLocale?: boolean;
  toFixed?: number;
  toPercentage?: boolean;
  toFormat?: boolean;
  timeFormatType?: string;
  timeFormat?: string;
  reflectType?: ReflectType;
  dtypeLabel?: Dtypes; // アイコン表示だけ変えたいとき用（データソースの列型変更時など）
  stringCellColor?: StringCellColorOption;
  showBottomAggregate?: boolean;
  bottomAggregateFunc?: AggregateFunc;
}

export interface QueryArgColumn extends Option {
  /*
  「アヤメの種類」が「集計設定」で選択され、関数が「カウント」の場合、

  label: アヤメの種類
  value: アヤメの種類
  type: string
  isAggKey: false
  aggFunc: COUNT
  isAfterAgg: false

  に加えて

  label: アヤメの種類_カウント
  value: count_アヤメの種類 (prestoのレスポンスの列名に合わせる)
  type: number
  isAggKey: false
  aggFunc: undefined
  isAfterAgg: true

  の列が追加される
  */
  isAggKey: boolean; // 「キー列を選択」の選択列かどうか。isAggの項目がない場合はfalse
  isSelected: boolean; // 「表示する列」の選択列かどうか。isSelectの項目がない場合はtrue
  aggFunc?: AggregateFunc;
}

export type Table = {
  columns: TableColumnsV4;
} & Aggregate;

export interface TableColumnsV2 {
  version: 2;
  columns: {
    columnSelectRules: ColumnSelectRules;
    selectedColumns: TableColumn[];
  };
}

export interface TableColumnsV3 {
  version: 3;
  columns: {
    columnSelectRules: ColumnSelectRules;
    selectedColumns: TableColumn[];
  };
  options: TableOption[];
}

export interface TableColumnsV4 {
  version: 4;
  columns: {
    columnSelectRules: ColumnSelectRules; // 表示する列
    columnAggKeyRules: ColumnSelectRules; // キー列を選択
    tableDisplayColumnsWithOption: TableColumn[]; // optionsが反映された列情報
    queryArgColumns: QueryArgColumn[]; // クエリを実行するのに必要な列情報
  };
  options: TableOption[];
}

export enum TableTabName {
  common = 'common',
  number = 'number',
  string = 'string',
  date = 'date'
}

export enum TableOptionName {
  align = 'align',
  dir = 'dir',
  textColor = 'textColor',
  width = 'width',
  order = 'order', // 列名の横の並び
  locale = 'locale',
  percentage = 'percentage',
  fixed = 'fixed',
  cellColor = 'cellColor',
  barColor = 'barColor',
  stringCellColor = 'stringCellColor',
  formatDate = 'formatDate',
  formatTimestamp = 'formatTimestamp',
  formatTime = 'formatTime',
  aggregate = 'aggregate',
  bottomAggregate = 'bottomAggregate'
}

// どのタブにどのオプションか
export const tableTabOptions = {
  [TableTabName.common]: [TableOptionName.align, TableOptionName.textColor],
  [TableTabName.number]: [
    TableOptionName.locale,
    TableOptionName.percentage,
    TableOptionName.fixed,
    TableOptionName.cellColor,
    TableOptionName.barColor,
    TableOptionName.bottomAggregate
  ],
  [TableTabName.string]: [TableOptionName.stringCellColor],
  [TableTabName.date]: [
    TableOptionName.formatDate,
    TableOptionName.formatTimestamp,
    TableOptionName.formatTime
  ]
};

// オプションに対応するTableColumnの値
export const tableOptionColumns: {
  [k in TableOptionName]: (keyof TableColumn)[];
} = {
  [TableOptionName.align]: ['align'],
  [TableOptionName.dir]: ['dir'],
  [TableOptionName.textColor]: [
    'setTextColor',
    'textColorPalette',
    'textColor'
  ],
  [TableOptionName.width]: ['width'],
  [TableOptionName.locale]: ['toLocale'],
  [TableOptionName.percentage]: ['toPercentage'],
  [TableOptionName.fixed]: ['toFixed'],
  [TableOptionName.cellColor]: ['showCellColor', 'palette'],
  [TableOptionName.barColor]: ['showProgress', 'progressColorPalette', 'color'],
  [TableOptionName.stringCellColor]: ['stringCellColor'], // v3から新規
  [TableOptionName.formatDate]: ['toFormat', 'timeFormatType', 'timeFormat'],
  [TableOptionName.formatTimestamp]: [
    'toFormat',
    'timeFormatType',
    'timeFormat'
  ],
  [TableOptionName.formatTime]: ['toFormat', 'timeFormatType', 'timeFormat'],
  [TableOptionName.aggregate]: ['aggFunc'],
  [TableOptionName.bottomAggregate]: [
    'showBottomAggregate',
    'bottomAggregateFunc'
  ],
  [TableOptionName.order]: []
};

export enum TableOptionType {
  bool = 'bool',
  multiple = 'multiple',
  barColor = 'barColor',
  stringCellColor = 'stringCellColor',
  width = 'width',
  order = 'order'
}

export interface GenericsTableOption<T, S> {
  type: T;
  option: S;
}

export type TableOption =
  | GenericsTableOption<TableOptionType.bool, TableOptionBool>
  | GenericsTableOption<TableOptionType.multiple, TableOptionMultiple>
  | GenericsTableOption<TableOptionType.barColor, TableOptionBarColor>
  | GenericsTableOption<TableOptionType.width, TableOptionWidth>
  | GenericsTableOption<TableOptionType.order, TableOptionOrder>
  | GenericsTableOption<
      TableOptionType.stringCellColor,
      TableOptionStringCellColor
    >;

interface TableOptionBase {
  name: TableOptionName;
}

export interface TableOptionBool extends TableOptionBase {
  option: { columnSelectRules: ColumnSelectRules };
}

export interface TableOptionMultiple extends TableOptionBase {
  option: {
    columnSelectRules: ColumnSelectRules;
    option: Omit<TableColumn, keyof Option>;
  }[];
}

// ミニバー専用
export interface TableOptionBarColor extends TableOptionBase {
  option: {
    columnSelectRules: ColumnSelectRules;
    progressColorPalette: Palette;
    colors: { [columnName: string]: string };
  }[];
}

// 文字列型の条件付き書式設定専用
export interface TableOptionStringCellColor extends TableOptionBase {
  option: {
    columnSelectRules: ColumnSelectRules;
    stringCellColor: {
      [columnName: string]: StringCellColorOption;
    };
  };
}

export interface StringCellColorOption {
  show: boolean;
  palette: Palette;
  colors: { [value: string]: string }; // 値ごとに色を設定
}

// まとめて設定で設定できないもの
export interface TableOptionWidth extends TableOptionBase {
  option: { width: { [columnName: string]: number } };
}

export interface TableOptionOrder extends TableOptionBase {
  option: { order: string[] };
}

export const tableOptionTemplate: TableColumnsV3['options'] = [
  {
    type: TableOptionType.bool,
    option: { name: TableOptionName.locale, option: { columnSelectRules: [] } }
  },
  {
    type: TableOptionType.bool,
    option: {
      name: TableOptionName.percentage,
      option: { columnSelectRules: [] }
    }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.align, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.dir, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.fixed, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.textColor, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.cellColor, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.formatDate, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.formatTimestamp, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.formatTime, option: [] }
  },
  {
    type: TableOptionType.barColor,
    option: { name: TableOptionName.barColor, option: [] }
  },
  {
    type: TableOptionType.width,
    option: { name: TableOptionName.width, option: { width: {} } }
  },
  {
    type: TableOptionType.order,
    option: { name: TableOptionName.order, option: { order: [] } }
  },
  {
    type: TableOptionType.stringCellColor,
    option: {
      name: TableOptionName.stringCellColor,
      option: {
        columnSelectRules: [],
        stringCellColor: {}
      }
    }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.aggregate, option: [] }
  },
  {
    type: TableOptionType.multiple,
    option: { name: TableOptionName.bottomAggregate, option: [] }
  }
];

type GroupedBar = GroupedChart & Aggregate & Order;
type GroupedColumn = GroupedChart & Aggregate & Order;
type GroupedLine = GroupedChart & Aggregate & Order;
type GroupedArea = GroupedChart & Aggregate & Order;

export interface Label {
  label: string;
}
interface Value {
  value?: number | string;
}

export type Point = Label &
  Value & {
    color?: string;
  };

interface Data {
  data: Point[];
}

export interface GroupedSeries {
  seriesname: string;
  data: Value[];
  color?: string;
}

interface Category {
  category: Label[];
}

export interface GroupedData {
  categories: Category[];
  dataset: GroupedSeries[];
}

export interface ScatterPoint {
  x?: number;
  y?: number;
  displayValue?: string;
}

export interface GroupedScatterSeries {
  seriesname?: string;
  anchorbgcolor?: string;
  anchorBorderColor?: string;
  data: ScatterPoint[];
}

interface SimpleChartData extends Data {
  type:
    | FusionChartType.Bar
    | FusionChartType.Column
    | FusionChartType.Line
    | FusionChartType.Area
    | FusionChartType.Histogram;
}

interface GroupedChartData extends GroupedData {
  type:
    | FusionChartType.MultipleSeriesBar
    | FusionChartType.MultipleSeriesColumn
    | FusionChartType.MultipleSeriesLine
    | FusionChartType.StackedBar
    | FusionChartType.StackedColumn
    | FusionChartType.MultipleSeriesArea
    | FusionChartType.StackedArea
    | FusionChartType.BoxAndWhisker
    | FusionChartType.Radar
    | FusionChartType.MultiAxisLine
    | FusionChartType.MultipleHistogram;
}
export interface ScatterData {
  type: FusionChartType.Scatter;
  dataset: GroupedScatterSeries[];
}

interface PieData extends Data {
  type: FusionChartType.Pie;
}

export enum TimeseriesPlottype {
  column = 'column',
  line = 'line',
  area = 'area'
}

export interface TimeseriesData {
  type: FusionChartType.TimeSeries;
  pivotData: { [colname: string]: any }[]; // 日付, 凡例1, 凡例2, 凡例3,...
  sortedLegends: string[];
  plottype: TimeseriesPlottype; // dualの場合に必要
}

export type TransformedData =
  | SimpleChartData
  | GroupedChartData
  | ScatterData
  | PieData
  | TimeseriesData;

export type ChartFeature = {
  isUniq: boolean;
  isSampling: boolean;
  hasOutOfAxisRange: boolean;
  isOverPeriodLimit: boolean;
};

export type ChartResult = {
  data: ChartData;
  chartFeature: ChartFeature;
};

export type TransformedResult = {
  data: TransformedData | undefined;
  chartFeature: ChartFeature;
};

export type TransformedDualResult = {
  data: (TransformedData | undefined)[];
  chartFeature: ChartFeature;
};

export interface View {
  caption?: string;
  subCaption?: string;
  xAxisName?: string;
  yAxisName?: string;
  stack100Percent?: string;
  xAxisMinValue?: string;
  xAxisMaxValue?: string;
  yAxisMinValue?: string;
  yAxisMaxValue?: string;
  showNavigator?: boolean;
  showValues?: string;
  displayValueColumn?: string; // 散布図のポイント上に表示する値の列名
  showRegressionLine?: boolean;
  showPercentage?: boolean;
  yShowPercentage?: boolean;
  xShowPercentage?: boolean;
  showLegend?: string;
  decimals?: number;
  yAxisValueDecimals?: number;
  xAxisValueDecimals?: number;
  legendPosition?: string;
  formatNumberScale?: string;
  numberPrefix?: string;
  yNumberPrefix?: string;
  xNumberPrefix?: string;
  numberSuffix?: string;
  yNumberSuffix?: string;
  xNumberSuffix?: string;
  showNullData?: string;
  showConnectingLines?: string;
  // tooltip
  tooltip?: Tooltip;
  dataPoints?: DataPoints;
  lineTypes?: LineTypes;
  // 2軸目
  y2SeriesNames?: Y2SeriesNames;
  y2AxisName?: string;
  y2AxisMinValue?: string;
  y2AxisMaxValue?: string;
  y2NumberPrefix?: string;
  y2NumberSuffix?: string;
  y2Decimals?: number;
  y2FormatNumberScale?: string;
  y2ShowPercentage?: boolean;
  // 表
  heatmap?: Heatmap;
  // ヒストグラム
  isRatio?: boolean;
  isLineChart?: boolean;
  // Timeseries
  showSlider?: boolean;
  // x軸を数値にした時の設定
  xFormatNumberScale?: string;
}

export interface Heatmap {
  show: boolean;
  columnSelectRules: ColumnSelectRules;
  range: {
    max?: number;
    min?: number;
  };
  palette?: Palette;
}

export interface DataPoints {
  [seriesname: string]: DataPointType;
}
export type DataPointType =
  | 'circle'
  | 'triangle'
  | 'quadrangle'
  | 'pentagon'
  | 'none';
export const defaultDataPointType: DataPointType = 'circle';
export const defaultDataPointTypeList: DataPointType[] = [
  'triangle',
  'quadrangle',
  'pentagon',
  'circle'
];

export interface LineTypes {
  [seriesname: string]: LineType;
}
export type LineType = 'normal' | 'thick' | 'dashed';
export const defaultLineType: LineType = 'normal';

export interface Tooltip {
  useCustomTooltip: boolean;
  showYAxis: boolean;
  showXAxis: boolean;
  showLegend: boolean;
  customFormat: string;
}

// 2軸目へ振り分ける凡例の候補
export interface Y2SeriesNames {
  [seriesname: string]: boolean; // 2軸目かどうか
}

export interface Order {
  order: OrderSetting;
}

export interface OrderSetting {
  x: OrderOptions;
  legend: OrderOptions;
  data?: { method: string }; // 散布図専用
}

export interface OrderOptions {
  method: string;
  target: string;
  column?: YValue;
  summarise?: string;
}

export enum ChartTheme {
  Fusion = 'fusion',
  Gammel = 'gammel',
  Candy = 'candy',
  Umber = 'umber',
  Carbon = 'carbon',
  Fint = 'fint',
  Ocean = 'ocean',
  Zune = 'zune'
}

export interface Color {
  palette: Palette;
  color: string | null;
  colors: { [seriesname: string]: string } | null;
  alpha?: string;
}

export interface Schema {
  columns: string[];
  dtypes: Dtypes[];
}

export interface QueryResult {
  data: Omit<DataResponseBodyV2, 'summary' | 'encoding'>;
  columns: { value: string; dtype: Dtypes }[];
  query: string;
}

export interface Row {
  [name: string]: number | string | boolean | undefined;
}

export interface QueryData {
  rows: any[][];
  dtypes: string[];
  columns: string[];
  num_rows: number;
  num_cols: number;
  is_down_sampling: boolean;
  display_table: boolean;
}

export type NullableQueryResult =
  | {
      type: ChartType;
      data: QueryData | undefined;
    }[]
  | QueryData
  | undefined
  | null;

export type NullableTransformedResult =
  | TransformedResult
  | TransformedDualResult
  | undefined
  | null;

export type NullableChartResult = ChartData | undefined | null;

export function getInitTooltip(): Tooltip {
  return {
    useCustomTooltip: false,
    showYAxis: true,
    showXAxis: false,
    showLegend: false,
    customFormat: ''
  };
}

export function getInitOrder(): OrderSetting {
  return {
    x: defaultOrder,
    legend: defaultOrder,
    data: { method: 'none' }
  };
}

export const defaultOrder: OrderOptions = { method: 'asc', target: 'x' };
export function getInitColumnLineOrder() {
  return {
    x: defaultOrder,
    legend: { column: defaultOrder, line: defaultOrder }
  };
}

interface TimeFormatOptions extends Option {
  format?: string;
}

export const timeFormatTypes: { [dtype: string]: TimeFormatOptions[] } = {
  [Dtypes.DATE]: [
    { value: 'date_kanji', label: 'YYYY年MM月DD日', format: 'YYYY年MM月DD日' },
    { value: 'date', label: 'YYYY/MM/DD', format: 'YYYY/MM/DD' },
    { value: 'custom', label: '自分で設定', format: 'YYYY-MM-DD' }
  ],
  [Dtypes.TIMESTAMP]: [
    {
      value: 'timestamp_kanji',
      label: 'YYYY年MM月DD日 HH時mm分ss秒',
      format: 'YYYY年MM月DD日 HH時mm分ss秒'
    },
    {
      value: 'timestamp',
      label: 'YYYY/MM/DD HH:mm:ss',
      format: 'YYYY/MM/DD HH:mm:ss'
    },
    { value: 'custom', label: '自分で設定', format: 'YYYY-MM-DD HH:mm:ss' }
  ],
  [Dtypes.TIME]: [
    { value: 'time_kanji', label: 'HH時mm分ss秒', format: 'HH時mm分ss秒' },
    { value: 'custom', label: '自分で設定', format: 'HH:mm:ss' }
  ]
};

export function setDefaultTableOption(columns: TableColumnsV2): TableColumnsV2 {
  return {
    ...columns,
    columns: {
      ...columns.columns,
      selectedColumns: columns.columns.selectedColumns.map((col) => {
        if (col.dtype == undefined) {
          return col;
        }
        let defaultCol = { ...col, setTextColor: !!col.textColor };
        if (Object.keys(timeFormatTypes).includes(col.dtype)) {
          defaultCol = {
            ...defaultCol,
            timeFormatType:
              col.toFormat && col.timeFormatType != undefined
                ? col.timeFormatType
                : 'custom', // 古いオプション設定の場合はtimeFormatTypeが存在しないのでcustomに強制的にセットする
            timeFormat:
              col.timeFormat ||
              timeFormatTypes[col.dtype].find((row) => row.value === 'custom')
                ?.format
          };
        }
        return defaultCol;
      })
    }
  };
}

export interface ComponentPropsDataTable {
  type: 'dataTable';
  props: {
    data: Row[];
    columns: TableColumn[];
    heatmap?: Heatmap;
  };
}

export interface ComponentPropsChart {
  type: 'chart';
  props: ChartData;
}

export type ComponentProps = ComponentPropsDataTable | ComponentPropsChart;
