import ActionTypes from 'actions/actionTypes';
import { Variable } from 'models/project';

const initialState: VariableState = {
  variables: []
};

interface VariableState {
  variables: Variable[];
}

// 共通変数
export default function variable(
  state: VariableState = initialState,
  action
): VariableState {
  switch (action.type) {
    case ActionTypes.LoadVariables: {
      const { data } = action.payload;

      return { ...state, variables: data };
    }
    default:
      return state;
  }
}
