import * as React from 'react';
import { Button, PropTypes } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';

interface LabeledButtonProps {
  color: PropTypes.Color;
  onClick: ButtonProps['onClick'];
  label: string;
  disabled?: boolean;
}

const styles = (theme) =>
  createStyles({
    root: {
      height: 64,
      border: 0,
      margin: 0,
      cursor: 'pointer',
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none',
      textTransform: 'none',
      backgroundColor: 'transparent'
    },
    label: {
      display: 'inline-flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: theme.typography.pxToRem(12)
    }
  });

const LabeledButton: React.FC<
  LabeledButtonProps & WithStyles<typeof styles>
> = ({ classes, color, onClick, label, disabled, children }) => {
  return (
    <Button
      color={color}
      onClick={onClick}
      classes={{ root: classes.root, label: classes.label }}
      disabled={disabled}
    >
      {children}
      {label}
    </Button>
  );
};

export default withStyles(styles)(LabeledButton);
