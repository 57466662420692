import { createStyles, makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { PriorityHigh } from '@material-ui/icons';
import { Button, Typography } from '@material-ui/core';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const useStyles = makeStyles(
  createStyles({
    label: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      margin: '8px 10px',
      justifyContent: 'space-between',
      fontFamily: 'NotoSansCJKjp',
      fontSize: '1rem',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '2.14',
      letterSpacing: 'normal'
    },
    labelText: {
      display: 'flex',
      color: '#787878',
      width: '40%'
    }
  })
);

export const Label: React.FC<{
  datacy?: string;
  text: string;
  helpText?: string;
  newline?: boolean;
  labelStyle?: React.CSSProperties;
  componentStyle?: React.CSSProperties;
}> = ({
  text,
  helpText,
  newline,
  labelStyle,
  componentStyle,
  children,
  ...props
}) => {
  const classes = useStyles();
  const splitSentence = React.useMemo(() => {
    if (helpText == undefined) {
      return null;
    }

    return helpText
      .split('\n')
      .map((sentence, i) => <p key={`${i}-${sentence}`}>{sentence}</p>);
  }, [helpText]);

  const renderHelp = () => {
    if (helpText == undefined || helpText.length == 0) {
      return null;
    }

    return (
      <div data-tip="" data-for="help-text-render">
        <PriorityHigh fontSize="small" />
        <ReactTooltip id="help-text-render" place="right">
          {splitSentence}
        </ReactTooltip>
      </div>
    );
  };
  return (
    <div
      className={classes.label}
      style={{ display: newline ? 'block' : 'flex' }}
    >
      <Typography
        component="div"
        className={classes.labelText}
        style={{ width: newline ? '100%' : '40%', ...labelStyle }}
      >
        {text}
        {renderHelp()}
      </Typography>
      <div
        style={{
          width: newline ? '100%' : '60%',
          display: 'flex',
          justifyContent: 'flex-end',
          ...componentStyle
        }}
        data-cy={props['data-cy'] ?? text}
      >
        {children}
      </div>
    </div>
  );
};

export const AddYValueButton: React.FC<{
  datacy?: string;
  onClick: () => void;
}> = ({ datacy, onClick }) => {
  return (
    <Label text="">
      <Button
        data-cy={datacy ? datacy : 'add_yvalue_button'}
        style={{ backgroundColor: '#FFFFFF' }}
        variant="contained"
        color="inherit"
        onClick={onClick}
      >
        +追加
      </Button>
    </Label>
  );
};
