import * as React from 'react';
import { Button, Paper } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    height: '100%'
  },
  content: {
    width: '100%',
    height: '100%',
    padding: 10,
    backgroundColor: 'rgb(255, 255, 255)',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  alert: { width: '70%' }
});

const severityNames: { [name: string]: string } = {
  warning: '警告',
  error: 'エラー'
};

export const AlertMessage: React.FC<{
  severity?: 'warning' | 'error';
  message?: string;
  detailMessage?: string;
}> = ({ severity, message, detailMessage }) => {
  const classes = useStyles();
  const [showDetail, setShowDetail] = React.useState<boolean>(false);
  const handleShowDetail = React.useCallback(() => {
    setShowDetail((prevState) => !prevState);
  }, []);

  if (message == undefined) {
    return null;
  }

  return (
    <div
      className={classes.root}
      data-cy="alert-container"
      data-severity={severity}
    >
      <Paper className={classes.content} elevation={0}>
        <Alert
          className={classes.alert}
          severity={severity}
          action={
            detailMessage && (
              <Button color="inherit" size="small" onClick={handleShowDetail}>
                詳細
              </Button>
            )
          }
        >
          {severity && <AlertTitle>{severityNames[severity]}</AlertTitle>}
          {message}
          <br />
          {showDetail && detailMessage}
        </Alert>
      </Paper>
    </div>
  );
};
