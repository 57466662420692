import ActionTypes from '../actions/actionTypes';

export type PortPosition = 'input' | 'output';

interface PreviewDialogReducer {
  open: boolean;
  nodeId?: string;
  portPosition?: PortPosition;
}

export default function previewReducer(
  state: PreviewDialogReducer = {
    open: false,
    nodeId: undefined,
    portPosition: 'output'
  },
  action: any
) {
  switch (action.type) {
    case ActionTypes.OpenPreviewDialog:
      return {
        open: true,
        nodeId: action.payload.nodeId,
        portPosition: action.payload.portPosition
      };
    case ActionTypes.ClosePreviewDialog:
      return {
        open: false,
        nodeId: undefined,
        portPosition: undefined
      };
    default:
      return state;
  }
}
