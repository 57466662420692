import { Dtypes } from 'Utils/dataTypes';
import { ParameterType, TimeParameter } from 'models/report';
import {
  dateRangeInitialValues,
  dateSingleInitialValues,
  datetimeRangeInitialValues,
  datetimeSingleInitialValues,
  timeRangeInitialValues,
  timeSingleInitialValues
} from './date/initialValue';
import { produce } from 'immer';

export const setInitialValues = (parameter: TimeParameter): TimeParameter => {
  return produce(parameter, (draft) => {
    switch (draft.dtype) {
      case Dtypes.TIME: {
        switch (draft.type) {
          case ParameterType.Single:
            draft.values = timeSingleInitialValues;
            return draft;
          case ParameterType.Range:
            draft.values = timeRangeInitialValues;
            return draft;
        }
      }
      case Dtypes.DATE: {
        switch (draft.type) {
          case ParameterType.Single:
            draft.values = dateSingleInitialValues;
            return draft;
          case ParameterType.Range:
            draft.values = dateRangeInitialValues;
            return draft;
        }
      }
      case Dtypes.TIMESTAMP: {
        switch (draft.type) {
          case ParameterType.Single:
            draft.values = datetimeSingleInitialValues;
            return draft;
          case ParameterType.Range:
            draft.values = datetimeRangeInitialValues;
            return draft;
        }
      }
    }
  });
};
