import { RangeDatetimeParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { DatetimeRangeValues } from 'models/report/parameter';

export class DatetimeRangeClass implements TimeParameterClass {
  constructor(public param: RangeDatetimeParameter) {}
  getRelativeItems = (): {
    label: string;
    value: DatetimeRangeValues['select'];
  }[] => {
    return [
      { label: '今週', value: 'this_week' },
      { label: '先週', value: 'last_week' },
      { label: '今月', value: 'this_month' },
      { label: '先月', value: 'last_month' },
      { label: '今年', value: 'this_year' },
      { label: '去年', value: 'last_year' },
      { label: '過去1時間', value: 'past_1_hour' },
      { label: '過去3時間', value: 'past_3_hour' },
      { label: '過去6時間', value: 'past_6_hour' },
      { label: '過去12時間', value: 'past_12_hour' }
    ];
  };
  isValidStartDate = () => {
    return dayjs(this.param.values.inputValue.startDate).isValid();
  };
  isValidStartTime = () => {
    return dayjs(
      `2000-01-01 ${this.param.values.inputValue.startTime}`
    ).isValid();
  };
  isValidEndDate = () => {
    return dayjs(this.param.values.inputValue.endDate).isValid();
  };
  isValidEndTime = () => {
    return dayjs(
      `2000-01-01 ${this.param.values.inputValue.endTime}`
    ).isValid();
  };
  isValid = () => {
    return (
      this.isValidStartDate() &&
      this.isValidStartTime() &&
      this.isValidEndDate() &&
      this.isValidEndTime()
    );
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      const { startDate, startTime, endDate, endTime } =
        this.calculateInputValue();
      // 具体的な日付
      return `${dayjs(`${startDate} ${startTime}`).format(
        'YYYY年MM月DD日 HH:mm:ss'
      )} ~ ${dayjs(`${endDate} ${endTime}`).format('YYYY年MM月DD日 HH:mm:ss')}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  formatDayjsToInputValue = (
    start: dayjs.Dayjs,
    end: dayjs.Dayjs
  ): DatetimeRangeValues['inputValue'] => {
    return {
      startDate: start.format('YYYY-MM-DD'),
      startTime: start.format('HH:mm:ss'),
      endDate: end.format('YYYY-MM-DD'),
      endTime: end.format('HH:mm:ss')
    };
  };
  calculateInputValue = (): DatetimeRangeValues['inputValue'] => {
    const { select, inputValue, inputPastValue, includeToday } =
      this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        const start = dayjs().subtract(
          Number(inputPastValue.value || 0),
          inputPastValue.unit
        );
        const end = includeToday ? dayjs() : dayjs().subtract(1, 'day');
        if (!start.isValid()) {
          // startが不正だとstartTimeが計算できないので個別にする
          return {
            startDate: start.format('YYYY-MM-DD'),
            startTime: inputValue.endTime,
            endDate: end.format('YYYY-MM-DD'),
            endTime: end.format('HH:mm:ss')
          };
        }
        return this.formatDayjsToInputValue(start, end);
      }
      case 'this_week':
        return this.formatDayjsToInputValue(
          dayjs().startOf('week').add(1, 'day'),
          dayjs().endOf('week').add(1, 'day')
        );
      case 'last_week':
        return this.formatDayjsToInputValue(
          dayjs().subtract(1, 'week').startOf('week').add(1, 'day'),
          dayjs().subtract(1, 'week').endOf('week').add(1, 'day')
        );
      case 'this_month':
        return this.formatDayjsToInputValue(
          dayjs().startOf('month'),
          dayjs().endOf('month')
        );
      case 'last_month':
        return this.formatDayjsToInputValue(
          dayjs().subtract(1, 'month').startOf('month'),
          dayjs().subtract(1, 'month').endOf('month')
        );
      case 'this_year':
        return this.formatDayjsToInputValue(
          dayjs().startOf('year'),
          dayjs().endOf('year')
        );
      case 'last_year':
        return this.formatDayjsToInputValue(
          dayjs().subtract(1, 'year').startOf('year'),
          dayjs().subtract(1, 'year').endOf('year')
        );
      case 'past_1_hour':
        return this.formatDayjsToInputValue(
          dayjs().subtract(1, 'hour'),
          dayjs()
        );
      case 'past_3_hour':
        return this.formatDayjsToInputValue(
          dayjs().subtract(3, 'hour'),
          dayjs()
        );
      case 'past_6_hour':
        return this.formatDayjsToInputValue(
          dayjs().subtract(6, 'hour'),
          dayjs()
        );
      case 'past_12_hour':
        return this.formatDayjsToInputValue(
          dayjs().subtract(12, 'hour'),
          dayjs()
        );
      default:
        const ex: never = select;
        return ex;
    }
  };
}
