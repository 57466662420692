import { _post } from 'libs/api/base';
import { DependencyItems, ListItemType } from '../../models/dependency';

export async function getDependency(body: {
  resourceID: string;
  type: ListItemType;
  isFolder: boolean;
}) {
  return _post<DependencyItems>('/dependency', body);
}
