import ActionTypes from '../actions/actionTypes';

export interface UserConfig {
  help: boolean;
  notification: boolean;
  describe: boolean;
}

const initialConfig = Object.freeze({
  help:
    localStorage.getItem('help') != undefined
      ? localStorage.getItem('help') === 'true'
      : true,
  notification:
    localStorage.getItem('notification') != undefined
      ? localStorage.getItem('notification') === 'true'
      : true,
  describe:
    localStorage.getItem('describe') != undefined
      ? localStorage.getItem('describe') === 'true'
      : true
});

export default function userConfig(
  state: UserConfig = initialConfig,
  action
): UserConfig {
  switch (action.type) {
    case ActionTypes.UserConfigHelpStatus:
      return { ...state, help: action.payload.help };

    case ActionTypes.UserConfigNotificationStatus:
      return { ...state, notification: action.payload.notification };

    case ActionTypes.UserConfigDescribeStatus:
      return { ...state, describe: action.payload.describe };

    default:
      return state;
  }
}
