function getManualPath(key: string): string | undefined {
  interface ManualPaths {
    [k: string]: string;
  }

  const manualPaths: ManualPaths = {
    projects: 'toppage/layput/#分析プロジェクト',
    project_main: 'data_analytics/layout',
    builders: 'toppage/layput/#sql-ビルダー',
    builder_main: 'data_import/sqlビルダー/layout',
    connections: 'toppage/layput/#接続情報',
    variable: 'variable/local-operation/#変数管理画面構成',
    variables: 'toppage/layput/#共通変数',
    datasources: 'toppage/layput/#データソース',
    exports: 'toppage/layput/#データエクスポート',
    schedules: 'toppage/layput/#スケジューリング',
    reports: 'toppage/layput/#ダッシュボード',
    reports_main: 'dashboard/layout/',
    visualize: 'visualize/layout/',
    visualize_selector: 'dashboard/機能操作/arrange/#配置',
    share_about: 'toppage/機能操作/share',
    notifications: 'toppage/layput/#通知',
    notification_dsts: 'toppage/layput/#通知先',
    users: 'organization/management/#ユーザー管理',
    user_groups: 'organization/management/#ユーザーグループ管理',

    dataexport_csv: 'data_export/データタイプごとの操作/download',
    dataexport_gbq: 'data_export/データタイプごとの操作/gbq',
    dataexport_gss: 'data_export/データタイプごとの操作/gss',
    dataexport_gcs: 'data_export/データタイプごとの操作/gcs',
    // dataexport_redshift: '2383', ※機能一時停止
    dataexport_s3: 'data_export/データタイプごとの操作/s3',
    dataexport_td: 'data_export/データタイプごとの操作/td/',
    dataexport_mysql: 'data_export/データタイプごとの操作/mysql',
    dataexport_msss: 'data_export/データタイプごとの操作/tsql',
    dataexport_nehan_storage: 'against_blackbox/connect_workflow/',
    dataexport_nehan_internal: 'against_blackbox/chain_datasource',
    dataexport_salesforce: 'data_export/データタイプごとの操作/salesforce',
    dataexport_onedrive: 'data_export/データタイプごとの操作/onedrive',

    datasource_csv_v2:
      'data_import/データソース/データタイプごとの操作/ファイル系/file_upload',
    datasource_csv_v3:
      'data_import/データソース/データタイプごとの操作/ファイル系/file_upload',
    datasource_s3:
      'data_import/データソース/データタイプごとの操作/ファイル系/s3',
    datasource_s3_v3:
      'data_import/データソース/データタイプごとの操作/ファイル系/s3',
    datasource_gss:
      'data_import/データソース/データタイプごとの操作/ファイル系/gss',
    datasource_gss_v3:
      'data_import/データソース/データタイプごとの操作/ファイル系/gss',
    datasource_gbq:
      'data_import/データソース/データタイプごとの操作/データベース系/gbq',
    datasource_gbq_v2:
      'data_import/データソース/データタイプごとの操作/データベース系/gbq',
    datasource_psql:
      'data_import/データソース/データタイプごとの操作/データベース系/psql',
    datasource_athena:
      'data_import/データソース/データタイプごとの操作/データベース系/athena',
    datasource_mysql:
      'data_import/データソース/データタイプごとの操作/データベース系/mysql',
    datasource_redshift:
      'data_import/データソース/データタイプごとの操作/データベース系/redshift',
    datasource_td:
      'data_import/データソース/データタイプごとの操作/データベース系/td',
    datasource_msss:
      'data_import/データソース/データタイプごとの操作/データベース系/sqlserver',
    datasource_sftp:
      'data_import/データソース/データタイプごとの操作/ファイル系/sftp',
    datasource_sftp_v3:
      'data_import/データソース/データタイプごとの操作/ファイル系/sftp',
    datasource_snowflake:
      'data_import/データソース/データタイプごとの操作/データベース系/snowflake',
    datasource_gdrive:
      'data_import/データソース/データタイプごとの操作/ファイル系/gdrive',
    datasource_onedrive:
      'data_import/データソース/データタイプごとの操作/ファイル系/onedrive',
    datasource_azureblob:
      'data_import/データソース/データタイプごとの操作/ファイル系/abs',
    datasource_gcs:
      'data_import/データソース/データタイプごとの操作/ファイル系/gcs',
    datasource_salesforce:
      'data_import/データソース/データタイプごとの操作/その他サービス/salesforce',
    datasource_gan4:
      'data_import/データソース/データタイプごとの操作/その他サービス/ga4',
    datasource_kintone:
      'data_import/データソース/データタイプごとの操作/その他サービス/kintone',
    datasource_hubspot:
      'data_import/データソース/データタイプごとの操作/その他サービス/hubspot',
    datasource_zoho_crm:
      'data_import/データソース/データタイプごとの操作/その他サービス/zoho_crm',

    connection_s3: 'data_import/接続情報/データタイプごとの操作/ファイル系/s3',
    connection_gcs:
      'data_import/接続情報/データタイプごとの操作/ファイル系/gcs',
    connection_gss:
      'data_import/接続情報/データタイプごとの操作/ファイル系/gss',
    connection_gdrive:
      'data_import/接続情報/データタイプごとの操作/ファイル系/gdrive',
    connection_onedrive:
      'data_import/接続情報/データタイプごとの操作/ファイル系/onedrive',
    connection_gan:
      'data_import/接続情報/データタイプごとの操作/その他サービス/ga4',
    connection_gbq_v2:
      'data_import/接続情報/データタイプごとの操作/データベース系/gbq',
    connection_psql:
      'data_import/接続情報/データタイプごとの操作/データベース系/psql',
    connection_athena:
      'data_import/接続情報/データタイプごとの操作/データベース系/athena',
    connection_mysql:
      'data_import/接続情報/データタイプごとの操作/データベース系/mysql',
    connection_redshift:
      'data_import/接続情報/データタイプごとの操作/データベース系/redshift',
    connection_td:
      'data_import/接続情報/データタイプごとの操作/データベース系/td',
    connection_msss: '接続情報/データタイプごとの操作/データベース系/sqlserver',
    connection_sftp:
      'data_import/接続情報/データタイプごとの操作/ファイル系/sftp',
    connection_salesforce:
      'data_import/接続情報/データタイプごとの操作/その他サービス/salesforce',
    connection_snowflake:
      'data_import/接続情報/データタイプごとの操作/データベース系/snowflake',
    connection_kintone:
      'data_import/接続情報/データタイプごとの操作/その他サービス/kintone',
    connection_hubspot:
      'data_import/接続情報/データタイプごとの操作/その他サービス/hubspot',
    connection_zoho_crm:
      'data_import/接続情報/データタイプごとの操作/その他サービス/zoho_crm',

    ml_bonito_1: 'ml/教師あり学習機能/automl/#精度検証結果',
    ml_bonito_2: 'ml/教師あり学習機能/automl/#機械が重視したデータ',
    ml_bonito_3: 'ml/教師あり学習機能/automl/#学習プロセス',
    ml_bonito_4: 'ml/教師あり学習機能/automl/#精度改善の余地',
    ml_bonito_5: 'ml/教師あり学習機能/automl/#モデル詳細情報',

    notification_insert_variable:
      'schedule_notification/notification_page_operation/#通知内容に変数を挿入',
    notification_insert_dashboard:
      'schedule_notification/notification_page_operation/#通知本文にダッシュボードリンクを挿入'
  };
  return manualPaths[key];
}

const manualUrl = 'https://octopus.nehan.io';

export function getManualUrl(path?: string): string {
  return path ? `${manualUrl}/${path}` : manualUrl;
}

export function getManualUrlByKey(key?: string): string {
  if (!key) {
    return manualUrl;
  }
  const path = getManualPath(key);
  return getManualUrl(path);
}

export function includeManualKey(key: string): boolean {
  return !!getManualPath(key);
}
