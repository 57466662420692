import * as React from 'react';
import * as Sentry from '@sentry/browser';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps
} from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import { UserRole } from 'libs/accessLevel';
import Forbidden from 'components/pages/errors/403';
import NotFound from 'components/pages/errors/404';
import { User } from '../models/user';
import { firebaseApp } from '../firebase';
import { getUser } from 'libs/api';

const Loading = (
  <LoadingSpinner text="あなたのログインをお待ちしておりました！" />
);
const initialUser: User = {
  id: '',
  email: '',
  first_name: '',
  last_name: '',
  role: UserRole.Disabled,
  groups: [],
  isTrial: true,
  organization: {
    name: '',
    namespace: '',
    role: UserRole.Disabled,
    feature_batch: false,
    feature_dashboard: false,
    feature_code_export: false,
    feature_spark: false,
    private_aws: false
  }
};

export const AuthContext = React.createContext<{ user: User }>({
  user: initialUser
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState<User>(initialUser);
  const [pending, setPending] = React.useState(true);

  React.useEffect(() => {
    let isMounted = true;
    const unsubscribe = onAuthStateChanged(getAuth(firebaseApp), async (u) => {
      if (!u) {
        setPending(false);
        setUser(initialUser);
      } else {
        try {
          const { data } = await getUser();
          if (isMounted) {
            setUser(data);
            setPending(false);
            Sentry.setUser({ id: data.id, email: data.email });
          }
        } catch {
          signOut(getAuth(firebaseApp));
        }
      }
    });

    return () => {
      isMounted = false;
      unsubscribe();
    };
  }, []);

  if (pending) {
    return Loading;
  }

  return (
    <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
  );
};

const hasAdminAccess = (user: User): boolean => {
  return user.organization.role >= UserRole.Admin;
};

const hasNehanAdminAccess = (user: User): boolean => {
  return user.organization.role >= UserRole.NehanAdmin;
};

interface NehanRouterBaseProps {
  authNotRequired?: (props: RouteComponentProps) => boolean;
  refreshOnSamePage?: boolean;
}

export const NotAuthedRoute: React.FC<RouteProps> = ({
  component: Component,
  ...rest
}) => {
  const { user } = React.useContext(AuthContext);

  if (!Component) {
    return <NotFound />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.id === '') {
          return <Component {...props} />;
        }

        return <Redirect to="/" />;
      }}
    />
  );
};

// リストページの検索結果をリンククリック時にリフレッシュしたい
let ROUTE_KEY = '';
/** ルーティングProps生成関数 */
const makeRouteComponentProps = (props) => {
  ROUTE_KEY = props.location?.state?.routeUniqueKey || ROUTE_KEY;
  return {
    key: ROUTE_KEY,
    ...props
  };
};

export const PrivateRouteBase: React.FunctionComponent<
  RouteProps & NehanRouterBaseProps & { user: User }
> = ({
  user,
  refreshOnSamePage,
  component: Component,
  authNotRequired,
  ...rest
}) => {
  if (!Component) {
    return <NotFound />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        const newProps = refreshOnSamePage
          ? makeRouteComponentProps(props)
          : props;

        // 認証が必要ないとき
        if (authNotRequired && authNotRequired(props)) {
          return <Component {...newProps} />;
        }

        if (user.id === '') {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        return <Component {...newProps} />;
      }}
    />
  );
};

export const PrivateRoute: React.FC<RouteProps & NehanRouterBaseProps> = (
  props
) => {
  const { user } = React.useContext(AuthContext);

  return <PrivateRouteBase user={user} {...props} />;
};

export const ProtectedRoute: React.FunctionComponent<
  RouteProps & NehanRouterBaseProps
> = ({ component: Component, authNotRequired, ...rest }) => {
  const { user } = React.useContext(AuthContext);

  if (!Component) {
    return <NotFound />;
  }
  return (
    <Route
      {...rest}
      render={(props) => {
        // 認証が必要ないとき
        if (authNotRequired && authNotRequired(props)) {
          return <Component {...props} />;
        }

        // 認証失敗（非ログイン）
        if (user.id === '') {
          return <Component {...props} />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export const AdminRoute: React.FunctionComponent<
  RouteProps & NehanRouterBaseProps
> = ({ component: Component, ...rest }) => {
  const { user } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.id === '') {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        if (hasAdminAccess(user)) {
          if (Component) {
            return <Component {...props} />;
          }
          return <NotFound />;
        }

        return <Forbidden />;
      }}
    />
  );
};

export const NehanAdminRoute: React.FunctionComponent<
  RouteProps & NehanRouterBaseProps
> = ({ component: Component, ...rest }) => {
  const { user } = React.useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.id === '') {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }

        if (hasNehanAdminAccess(user)) {
          if (Component) {
            return <Component {...props} />;
          }
          return <NotFound />;
        }

        return <Forbidden />;
      }}
    />
  );
};
