import { AggregateFunc, ChartType, Option, YValue } from 'models/chart';

interface PieValue {
  type: ChartType.pie;
  value?: YValue;
}

interface ChartValue {
  type:
    | ChartType.bar
    | ChartType.line
    | ChartType.column
    | ChartType.area
    | ChartType.boxAndWhisker
    | ChartType.radar
    | ChartType.dualAxisColumnLine
    | ChartType.scatter
    | ChartType.histogram;
  y?: YValue;
}

type Value = ChartValue | PieValue;

export const aggregateMethod: Option[] = [
  { value: AggregateFunc.sum, label: '合計' },
  { value: AggregateFunc.avg, label: '平均' },
  { value: AggregateFunc.max, label: '最大' },
  { value: AggregateFunc.min, label: '最小' },
  { value: AggregateFunc.countAll, label: 'カウント' },
  { value: AggregateFunc.uniqCnt, label: 'ユニークカウント' }
];

export const bottomAggregateMethod: Option[] = [
  { value: AggregateFunc.sum, label: '合計' },
  { value: AggregateFunc.avg, label: '平均' },
  { value: AggregateFunc.max, label: '最大' },
  { value: AggregateFunc.min, label: '最小' }
];

export function filterAggregate(value: Value): Option[] {
  switch (value.type) {
    case ChartType.bar:
    case ChartType.line:
    case ChartType.column:
    case ChartType.area:
    case ChartType.boxAndWhisker:
    case ChartType.radar:
    case ChartType.dualAxisColumnLine:
    case ChartType.histogram:
      if (value.y == undefined || value.y.column == undefined) {
        return aggregateMethod;
      }

      if (value.y.column.dtype === 'number') {
        return aggregateMethod;
      }

      return [
        { value: 'COUNT', label: 'カウント' },
        { value: 'UNIQ_CNT', label: 'ユニークカウント' }
      ];
    case ChartType.pie:
      if (value.value == undefined || value.value.column == undefined) {
        return aggregateMethod;
      }

      if (value.value.column.dtype === 'number') {
        return aggregateMethod;
      }

      return [
        { value: 'COUNT', label: 'カウント' },
        { value: 'UNIQ_CNT', label: 'ユニークカウント' }
      ];
    default:
      return [];
  }
}

const datetimeSeriesFormat: Option[] = [
  { value: 'year', label: '1年ごと' },
  { value: 'month', label: '1ヶ月ごと' },
  { value: 'day', label: '1日ごと' },
  { value: 'hour', label: '1時間ごと' },
  { value: 'minute', label: '1分ごと' },
  { value: 'second', label: '1秒ごと' },
  { value: 'millisecond', label: '1ミリ秒ごと' },
  { value: 'custom', label: 'カスタム' }
];

const dateSeriesFormat: Option[] = [
  { value: 'year', label: '年ごと' },
  { value: 'month', label: '月ごと' },
  { value: 'day', label: '日ごと' },
  { value: 'custom', label: 'カスタム' }
];

const timeSeriesFormat: Option[] = [
  { value: 'time_hour', label: '1時間ごと' },
  { value: 'time_minute', label: '1分ごと' },
  { value: 'time_second', label: '1秒ごと' },
  { value: 'time_millisecond', label: '1ミリ秒ごと' },
  { value: 'custom', label: 'カスタム' }
];

export function filterTimeSeries(dtype: string): Option[] {
  if (dtype === 'date') {
    return dateSeriesFormat;
  }
  if (dtype === 'time') {
    return timeSeriesFormat;
  }
  return datetimeSeriesFormat;
}
