import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function SvgIcMove(props) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <g stroke="currentColor" strokeWidth="1">
        <g transform="translate(-1470.000000, -836.000000)">
          <g
            transform="translate(1470.000000, 836.000000)"
            stroke="#344955"
            fill="none"
            strokeLinecap="round"
            strokeWidth="2"
          >
            <g transform="translate(4.000000, 7.000000)">
              <path d="M5,8 L4,8 C1.8,8 0,6.2 0,4 C0,1.8 1.8,-3.55271368e-15 4,-3.55271368e-15 L18,-3.55271368e-15 C20.2,-3.55271368e-15 22,1.8 22,4 C22,6.2 20.2,8 18,8 L17,8"></path>
              <path d="M6.5,17.8569 C8.985,17.8569 11,15.8419 11,13.3569 L11,8.9999"></path>
              <polyline
                strokeLinejoin="round"
                points="7.3789 11.3789 10.9999 7.7569 14.6209 11.3789"
              ></polyline>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
}

export default SvgIcMove;
