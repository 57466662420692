import { aggregateMethod } from 'components/visualize/editor/util';
import { TableColumn } from 'models/chart';
import React, { useEffect } from 'react';

export const BottomAggregateTooltip: React.FC<{
  tableRef: React.MutableRefObject<null>;
  columns: TableColumn[];
  setTableCellTooltipAnchorEl: (cell) => void;
  setCellValue: (value: string) => void;
}> = ({ tableRef, columns, setTableCellTooltipAnchorEl, setCellValue }) => {
  if (!tableRef.current) {
    return <></>;
  }

  const table = (tableRef.current! as { table: any }).table;
  if (!table) {
    return <></>;
  }

  useEffect(() => {
    const footerEl = table.footerManager.element;
    if (!footerEl) {
      return;
    }
    const mouseoverHandler = (event) => {
      if (!event.target) {
        return;
      }
      const columnValue = (event.target as Element).getAttribute(
        'tabulator-field'
      );
      const col = columns.find((c) => c.value === columnValue);
      if (!col) {
        return;
      }
      if (!col.showBottomAggregate || !col.bottomAggregateFunc) {
        return;
      }
      const funcLabel = aggregateMethod.find(
        (m) => m.value === col.bottomAggregateFunc
      )?.label;
      if (!funcLabel) {
        return;
      }
      setTableCellTooltipAnchorEl(event.target);
      setCellValue(funcLabel);
    };

    const mouseoutHandler = () => {
      setTableCellTooltipAnchorEl(null);
    };

    footerEl.addEventListener('mouseover', mouseoverHandler);
    footerEl.addEventListener('mouseout', mouseoutHandler);

    return () => {
      footerEl.removeEventListener('mouseover', mouseoverHandler);
      footerEl.removeEventListener('mouseout', mouseoutHandler);
    };
  }, [table, columns]);

  return <></>;
};
