import { ListItemType } from './dependency';

export function getFolderType(type: ListItemType): string {
  switch (type) {
    case 'projects':
      return 'project';
    case 'reports':
      return 'report';
    case 'builders':
      return 'builder';
    case 'datasources':
      return 'datasource';
    case 'variables':
      return 'variable';
    case 'schedules':
      return 'schedule';
    case 'notification_dsts':
      return 'notification_dst';
    case 'notifications':
      return 'notification';
    default:
      return '';
  }
}

export interface Path {
  name: string;
  uuid: string;
}
