import * as React from 'react';
import {
  Dtypes //, LabelToDtype
} from 'Utils/dataTypes';
import SelectField from 'components/form/selectField';
import { SwitchDatetimeElement } from 'models/form/value';
import { FieldTypes, SelectFieldValueElement } from 'models/form/schema';
import { SelectFieldSchema } from 'models/form/schema';

export const SelectElement: React.FC<{
  value: SwitchDatetimeElement | null;
  dtype: Dtypes | null;
  onChange: (
    value: SwitchDatetimeElement,
    element: Array<{
      disableUseColumn?: boolean;
      value: SwitchDatetimeElement;
      label: string;
      disableBetweenUseColumn?: boolean;
    }>
  ) => void;
  index: number;
}> = ({ value, dtype, onChange, index }) => {
  const element = determine_element(dtype);

  const handleChange = React.useCallback(
    (_, val: any) => onChange(val as SwitchDatetimeElement, element),
    [onChange]
  );

  //const val: SelectFieldValueElement = element.filter((v) => {
  //  v.value === value;
  //})[0];

  const schema: SelectFieldSchema = {
    key: String(index),
    type: FieldTypes.select,
    items: element as SelectFieldValueElement[],
    multi: false,
    allDtypes: [],
    fullWidth: true,
    variant: 'outlined',
    placeholder: '日付要素を選択'
  };
  return (
    <div>
      <SelectField
        clearable={false}
        value={value as any}
        //errors={errors}
        schema={schema}
        onChangeField={handleChange}
        detailedHelpTooltip={null}
      />
    </div>
  );
};

export const determine_element = (
  dtype: Dtypes | null
): Array<{
  disableUseColumn?: boolean;
  value: SwitchDatetimeElement;
  label: string;
  disableBetweenUseColumn?: boolean;
}> => {
  switch (dtype) {
    case Dtypes.DATE:
      return DateElement;
    case Dtypes.TIMESTAMP:
      return DatetimeElement;
    case Dtypes.TIME:
      return TimeElement;
    default:
      return DateElement;
  }
};

const DateElement = [
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.year,
    label: '年',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.month,
    label: '月',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.day,
    label: '日',
    disableBetweenUseColumn: true
  },
  { value: SwitchDatetimeElement.ymd, label: '年-月-日' },
  { value: SwitchDatetimeElement.ym, label: '年-月' },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.weekday,
    label: '曜日',
    disableBetweenUseColumn: true
  }
];

const DatetimeElement = [
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.year,
    label: '年',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.month,
    label: '月',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.day,
    label: '日',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.hour,
    label: '時',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.minute,
    label: '分',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.second,
    label: '秒',
    disableBetweenUseColumn: true
  },
  { value: SwitchDatetimeElement.ymd, label: '年-月-日' },
  { value: SwitchDatetimeElement.ym, label: '年-月' },
  { value: SwitchDatetimeElement.ymdhms, label: '年-月-日 時:分:秒' },
  { value: SwitchDatetimeElement.hms, label: '時:分:秒' },
  {
    disableUseColumn: true,
    value: SwitchDatetimeElement.weekday,
    label: '曜日',
    disableBetweenUseColumn: true
  }
];

const TimeElement = [
  { value: SwitchDatetimeElement.hms, label: '時:分:秒' },
  { value: SwitchDatetimeElement.hour, label: '時' },
  { value: SwitchDatetimeElement.minute, label: '分' },
  { value: SwitchDatetimeElement.second, label: '秒' }
];
