import { SingleDatetimeParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { DatetimeSingleValues } from 'models/report/parameter';

export class DatetimeSingleClass implements TimeParameterClass {
  constructor(public param: SingleDatetimeParameter) {}
  getRelativeItems = (): {
    label: string;
    value: DatetimeSingleValues['select'];
  }[] => {
    return [
      { label: '今日', value: 'today' },
      { label: '昨日', value: 'yesterday' },
      { label: '1週間前', value: '1_week_ago' },
      { label: '1ヶ月前', value: '1_month_ago' },
      { label: '1年前', value: '1_year_ago' },
      { label: '1時間前', value: '1_hour_ago' },
      { label: '6時間前', value: '6_hour_ago' },
      { label: '12時間前', value: '12_hour_ago' }
    ];
  };
  isValidDate = () => {
    return dayjs(this.param.values.inputValue.date).isValid();
  };
  isValidTime = () => {
    return dayjs(`2000-01-01 ${this.param.values.inputValue.time}`).isValid();
  };
  isValid = () => {
    return this.isValidDate() && this.isValidTime();
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      // 具体的な日付
      const { date, time } = this.calculateInputValue();
      return `= ${dayjs(`${date} ${time}`).format('YYYY年MM月DD日 HH:mm:ss')}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  formatDayjsToInputValue = (
    datetime: dayjs.Dayjs
  ): DatetimeSingleValues['inputValue'] => {
    return {
      date: datetime.format('YYYY-MM-DD'),
      time: datetime.format('HH:mm:ss')
    };
  };
  calculateInputValue = (): DatetimeSingleValues['inputValue'] => {
    const { select, inputValue, inputPastValue } = this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        const datetime = dayjs().subtract(
          Number(inputPastValue.value || 0),
          inputPastValue.unit
        );
        if (!datetime.isValid()) {
          // datetimeが不正だとtimeが計算できないので個別にする
          return {
            date: datetime.format('YYYY-MM-DD'),
            time: inputValue.time
          };
        }
        return this.formatDayjsToInputValue(datetime);
      }
      case 'today':
        return this.formatDayjsToInputValue(dayjs());
      case 'yesterday':
        return this.formatDayjsToInputValue(dayjs().subtract(1, 'day'));
      case '1_week_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(1, 'week'));
      case '1_month_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(1, 'month'));
      case '1_year_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(1, 'year'));
      case '1_hour_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(1, 'hour'));
      case '6_hour_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(6, 'hour'));
      case '12_hour_ago':
        return this.formatDayjsToInputValue(dayjs().subtract(12, 'hour'));
      default:
        const ex: never = select;
        return ex;
    }
  };
}
