import * as React from 'react';

export function IconTime() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      style={{ minWidth: 16 }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#AD72BF" />
        <g transform="translate(3 3)">
          <circle cx="5.5" cy="5.5" r="5" stroke="#FFF" />
          <path fill="#FFF" d="M5 5H8V6H5z" />
          <path
            fill="#FFF"
            d="M3.5 3.5H7.5V4.5H3.5z"
            transform="rotate(90 5.5 4)"
          />
        </g>
      </g>
    </svg>
  );
}
