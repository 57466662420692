import * as React from 'react';
import * as Sentry from '@sentry/browser';
import MainLogo from 'components/icons/MainLogo';

export default class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean }
> {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    window.onbeforeunload = null;

    if (import.meta.env.VITE_ENV === 'production') {
      Sentry.withScope((scope) => {
        Object.keys(errorInfo).forEach((key) => {
          scope.setExtra(key, errorInfo[key]);
        });
        scope.setLevel('fatal');
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div
          style={{
            width: '100vw',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center'
          }}
        >
          <MainLogo width={'40%'} height={'20%'} />
          <h1>予期せぬエラー</h1>
          <p>
            予期せぬエラーが発生しました。
            <br />
            ご迷惑をおかけし、申し訳ございません。
            <br />
            何度もこの場面がでる場合は、
            <br />
            <a href="mailto:support@nehan.io">support@nehan.io</a>
            <br />
            までご連絡ください。
          </p>
          <p>
            <a href="/">トップページへ</a>
          </p>
        </div>
      );
    }

    return this.props.children;
  }
}
