import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Typography, ButtonBase } from '@material-ui/core';
import { Link } from 'react-router-dom';

const style = (theme) =>
  createStyles({
    button: (props: { available: boolean }) => ({
      ...theme.typography.button,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      textDecoration: 'none',
      height: 80,
      width: '100%',
      color: theme.palette.text.primary,
      opacity: props.available ? 1 : 0.4,
      transition: theme.transitions.create(
        ['background-color', 'box-shadow', 'border'],
        {
          duration: theme.transitions.duration.short
        }
      ),
      '&:hover': {
        textDecoration: 'none',
        backgroundColor: fade(
          theme.palette.text.primary,
          theme.palette.action.hoverOpacity
        ),
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          backgroundColor: 'transparent'
        }
      }
    }),
    name: {
      textTransform: 'none',
      marginLeft: '33px',
      height: '20px',
      fontSize: '14px',
      fontWeight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      textAlign: 'center',
      color: '#525252'
    }
  });

interface LinkButtonProps {
  name: string | JSX.Element;
  logo?: string | JSX.Element;
  link?: string;
  onClick?: () => void;
  available?: boolean;
}

const LinkButton: React.FC<LinkButtonProps & WithStyles<typeof style>> = ({
  classes,
  name,
  logo,
  link,
  onClick,
  available = true,
  ...props
}) => {
  const component =
    link != undefined && available
      ? React.forwardRef((props, ref) => (
          <Link
            data-cy={`link-${props['data-cy']}`}
            style={{ display: 'flex' }}
            to={link}
            ref={ref}
            {...(props as any)}
          />
        ))
      : 'button';
  return (
    <ButtonBase
      data-cy={`button-${props['data-cy']}`}
      component={component}
      onClick={onClick}
      focusRipple={true}
      classes={{ root: classes.button }}
    >
      {typeof logo === 'string' && <img src={logo} width="60" />}
      {React.isValidElement(logo) && (
        <div style={{ width: '60px' }}>{logo}</div>
      )}
      <Typography className={classes.name}>{name}</Typography>
    </ButtonBase>
  );
};

export default withStyles(style)(LinkButton);
