import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Badge, IconButton, InputBase } from '@material-ui/core';
import { ArrowDropDown, Search } from '@material-ui/icons';
import { FilterContext } from 'ui/listView/filter/context';
import { ListViewFilter } from 'ui/listView/filter/listViewFilter';
import {
  handleChangeBuilderName,
  handleChangeConnectionName,
  handleChangeDatasourceName,
  handleChangeNotificationDstName,
  handleChangeNotificationName,
  handleChangeOrganizationName,
  handleChangeProjectName,
  handleChangeReportName,
  handleChangeScheduleName,
  handleChangeUserEmail,
  handleChangeUserGroupName,
  handleChangeVariableName
} from 'ui/listView/filter/handler';
import { FilterType } from 'ui/listView/filter/type';
import { CustomTheme } from '../../../../theme';

const useStyles = makeStyles((theme: CustomTheme) => ({
  search: {
    color: '#838383'
  },
  searchForm: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    height: 40,
    width: 234,
    borderRadius: 6,
    backgroundColor: '#f4f4f4',
    color: '#838383',
    boxShadow: 'none'
  },
  searchInput: {
    width: '100%',
    fontSize: 13
  },
  badge: {
    color: '#ffffff',
    backgroundColor: theme.palette?.nehan?.main
  }
}));

export const SearchForm: React.FC<{ filterApplied: boolean }> = ({
  filterApplied
}) => {
  const classes = useStyles();
  const {
    state: filterState,
    dispatch: filterDispatch,
    type,
    load
  } = React.useContext(FilterContext);
  const textFieldRef = React.useRef<HTMLDivElement | null>(null);

  const [filterAnchorEl, setFilterAnchorEl] =
    React.useState<HTMLDivElement | null>(null);

  const searchFieldHandler = () => {
    switch (type) {
      case 'builders':
        return handleChangeBuilderName(filterState, filterDispatch);
      case 'connections':
        return handleChangeConnectionName(filterState, filterDispatch);
      case 'datasources':
      case 'selector_datasource':
        return handleChangeDatasourceName(filterState, filterDispatch);
      case 'exports':
      case 'projects':
      case 'visualize_selector':
      case 'selector_export':
      case 'selector_project':
        return handleChangeProjectName(filterState, filterDispatch);
      case 'reports':
      case 'report_selector':
      case 'selector_report':
        return handleChangeReportName(filterState, filterDispatch);
      case 'variables':
        return handleChangeVariableName(filterState, filterDispatch);
      case 'schedules':
        return handleChangeScheduleName(filterState, filterDispatch);
      case 'notifications':
        return handleChangeNotificationName(filterState, filterDispatch);
      case 'notification_dsts':
        return handleChangeNotificationDstName(filterState, filterDispatch);
      case 'user_groups':
        return handleChangeUserGroupName(filterState, filterDispatch);
      case 'users':
        return handleChangeUserEmail(filterState, filterDispatch);
      case 'organizations':
        return handleChangeOrganizationName(filterState, filterDispatch);
    }
  };

  const handleClickFilterOpenButton = React.useCallback(() => {
    setFilterAnchorEl(textFieldRef.current);
  }, []);

  const handleClose = React.useCallback(() => {
    setFilterAnchorEl(null);
  }, []);

  const searchFieldValue = (): string => {
    switch (type) {
      case 'builders':
        return filterState[FilterType.BuilderName];
      case 'connections':
        return filterState[FilterType.ConnectionName];
      case 'datasources':
      case 'selector_datasource':
        return filterState[FilterType.DatasourceName];
      case 'exports':
      case 'projects':
      case 'visualize_selector':
      case 'selector_project':
      case 'selector_export':
        return filterState[FilterType.ProjectName];
      case 'reports':
      case 'report_selector':
      case 'selector_report':
        return filterState[FilterType.ReportName];
      case 'variables':
        return filterState[FilterType.VariableName];
      case 'schedules':
        return filterState[FilterType.ScheduleName];
      case 'notifications':
        return filterState[FilterType.NotificationName];
      case 'notification_dsts':
        return filterState[FilterType.NotificationDstName];
      case 'user_groups':
        return filterState[FilterType.UserGroupName];
      case 'users':
        return filterState[FilterType.UserEmail];
      case 'organizations':
        return filterState[FilterType.OrganizationName];
      default:
        return '';
    }
  };

  return (
    <div className={classes.searchForm}>
      <Search className={classes.search} />
      <InputBase
        className={classes.searchInput}
        inputProps={{ 'data-cy': 'list-search-input' }}
        value={searchFieldValue()}
        ref={textFieldRef}
        placeholder="検索"
        onChange={searchFieldHandler()}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            load(true);
          }
        }}
      />
      <IconButton onClick={handleClickFilterOpenButton}>
        <Badge
          badgeContent="ON"
          color="secondary"
          invisible={!filterApplied}
          classes={{ badge: classes.badge }}
        >
          <ArrowDropDown className={classes.search} />
        </Badge>
      </IconButton>
      <ListViewFilter anchorEl={filterAnchorEl} onClose={handleClose} />
    </div>
  );
};
