import { SingleDateParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { DateSingleValues } from 'models/report/parameter';

export class DateSingleClass implements TimeParameterClass {
  constructor(public param: SingleDateParameter) {}
  getRelativeItems = (): {
    label: string;
    value: DateSingleValues['select'];
  }[] => {
    return [
      { label: '今日', value: 'today' },
      { label: '昨日', value: 'yesterday' },
      { label: '1週間前', value: '1_week_ago' },
      { label: '1ヶ月前', value: '1_month_ago' },
      { label: '1年前', value: '1_year_ago' }
    ];
  };
  isValidDate = () => {
    return dayjs(this.param.values.inputValue.date).isValid();
  };
  isValid = () => {
    return this.isValidDate();
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      // 具体的な日付
      const { date } = this.calculateInputValue();
      return `= ${dayjs(date).format('YYYY年MM月DD日')}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  calculateInputValue = (): DateSingleValues['inputValue'] => {
    const { select, inputValue, inputPastValue } = this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        return {
          date: dayjs()
            .subtract(Number(inputPastValue.value || 0), inputPastValue.unit)
            .format('YYYY-MM-DD')
        };
      }
      case 'today':
        return { date: dayjs().format('YYYY-MM-DD') };
      case 'yesterday':
        return { date: dayjs().subtract(1, 'day').format('YYYY-MM-DD') };
      case '1_week_ago':
        return { date: dayjs().subtract(1, 'week').format('YYYY-MM-DD') };
      case '1_month_ago':
        return { date: dayjs().subtract(1, 'month').format('YYYY-MM-DD') };
      case '1_year_ago':
        return { date: dayjs().subtract(1, 'year').format('YYYY-MM-DD') };
      default:
        const ex: never = select;
        return ex;
    }
  };
}
