import { SingleTimeParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { TimeSingleValues } from 'models/report/parameter';

export class TimeSingleClass implements TimeParameterClass {
  constructor(public param: SingleTimeParameter) {}
  getRelativeItems = (): {
    label: string;
    value: TimeSingleValues['select'];
  }[] => {
    return [
      { label: '1時間前', value: '1_hour_ago' },
      { label: '6時間前', value: '6_hour_ago' },
      { label: '12時間前', value: '12_hour_ago' }
    ];
  };
  isValidTime = () => {
    return dayjs(`2000-01-01 ${this.param.values.inputValue.time}`).isValid();
  };
  isValid = () => {
    return this.isValidTime();
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      // 具体的な日付
      return `= ${this.calculateInputValue().time}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  calculateInputValue = (): TimeSingleValues['inputValue'] => {
    const { select, inputValue, inputPastValue } = this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        return {
          time: dayjs()
            .subtract(Number(inputPastValue.value || 0), inputPastValue.unit)
            .format('HH:mm:ss')
        };
      }
      case '1_hour_ago':
        return {
          time: dayjs().subtract(1, 'hour').format('HH:mm:ss')
        };
      case '6_hour_ago':
        return {
          time: dayjs().subtract(6, 'hour').format('HH:mm:ss')
        };
      case '12_hour_ago':
        return {
          time: dayjs().subtract(12, 'hour').format('HH:mm:ss')
        };
      default:
        const ex: never = select;
        return ex;
    }
  };
}
