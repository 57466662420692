import { Y2SeriesNames } from '../../../models/chart';

// timeseriesでは、2軸の場合は、1軸目、2軸目の順に凡例を並べないといけない
export const sortLegendOnTimeseriesMultiAxis = (
  legends: string[],
  y2SeriesNames: Y2SeriesNames | undefined
) => {
  const primaryLegends = legends.filter((legend) =>
    isPrimaryLegend(legend, y2SeriesNames)
  );
  const secondaryLegends = legends.filter(
    (legend) => !isPrimaryLegend(legend, y2SeriesNames)
  );
  const sortedLegends = [...primaryLegends, ...secondaryLegends];
  return sortedLegends;
};

const isPrimaryLegend = (
  legend: string,
  y2SeriesNames: Y2SeriesNames | undefined
) => {
  if (!y2SeriesNames) {
    return true;
  }
  return !y2SeriesNames[legend];
};
