import ActionTypes from '../actions/actionTypes';
import { produce } from 'immer';
import { FormSchema } from 'models/form/schema';

export interface ProcessItemConfig {
  name: string;
  portLabel: string;
  display: boolean;
  objectType: string;
}

export interface SupplementConfig {
  name: string;
  title: string;
  displayExpr?: string;
}

export interface ProcessItem {
  name: string; // Component name in React
  text: string; // Node Name in Graph View
  type?: string; // ProcessName in nehan-api
  spark?: boolean;
  supplement?: boolean;
  supplementConf?: SupplementConfig[];
  parentName?: ProcessItem['name']; // 親がいるばあい、親のProcessItem.Name
  iconName?: string;
  keywords: string[];
  children?: ProcessItem[];
  inConf?: ProcessItemConfig[];
  outConf?: ProcessItemConfig[];
  form?: FormSchema;
  Default: boolean;
  help?: Help;
}

interface Help {
  markdown: boolean;
  text: string;
}

export interface ProcessListState {
  modules: ProcessItem[];
  formPath: { [moduleType: string]: string[][] };
  inConfNameMap: {
    [processType: string]: { [formKey: string]: string | string[] };
  };
  loaded: boolean;
}

const initialState: ProcessListState = {
  modules: [],
  formPath: {},
  inConfNameMap: {},
  loaded: false
};

export function processList(
  state: ProcessListState = initialState,
  action: any
) {
  switch (action.type) {
    case ActionTypes.ReceiveModuleData: {
      return { ...action.payload, loaded: true };
    }

    case ActionTypes.ClearModuleData: {
      return produce(state, (draft) => {
        draft = { ...initialState };
        return draft;
      });
    }

    default:
      return state;
  }
}
