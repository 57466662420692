export function secondsToString(seconds: number): string {
  if (seconds === 0) {
    return '0秒';
  }
  const numhours = Math.floor(seconds / 3600);
  const numminutes = Math.floor((seconds % 3600) / 60);
  const numseconds = (seconds % 3600) % 60;
  let ret = '';
  if (numhours > 0) {
    ret += `${numhours}時間`;
  }
  if (numminutes > 0) {
    ret += `${numminutes}分`;
  }
  if (numseconds > 0) {
    ret += `${numseconds}秒`;
  }
  if (ret === '') {
    return '-';
  }
  return ret;
}
