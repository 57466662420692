import { combineReducers } from 'redux';

import { processList } from './processList';
import project from './project';
import webSocketStatus from './webSocketStatus';
import datasource from './datasource';
import previewDialog from './previewDialog';
import userConfig from './userConfig';
import builderProject from './builderProject';
import variable from './variable';

export const reducers = {
  processList,
  project,
  webSocketStatus,
  datasource,
  previewDialog,
  userConfig,
  builder: builderProject,
  variable
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
