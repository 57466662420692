import * as React from 'react';
import { HTMLAttributeAnchorTarget } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useAsync } from 'react-use';
import { BaseInfo, CreatorInfo, ShareInfo } from 'ui/detailPane';
import { NodeItem } from 'components/relationships/relationships';
import { getRelationshipItemDetail } from 'libs/api/relationship';
import { RelationshipItemDetail } from 'models/relationship';
import { createLink } from 'reducers/datasource';
import { AuthContext } from 'components/route';
import { getDatasourceExpression } from 'libs/datasources';
import {
  ArrowRightAlt,
  CalendarToday,
  CheckCircle,
  Close,
  RadioButtonUnchecked
} from '@material-ui/icons';
import dayjs from 'dayjs';
import {
  Button,
  Checkbox as MUICheckbox,
  CheckboxProps,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import {
  DependencyItemStatus,
  dependencyStatusToText
} from 'models/dependency';
import clsx from 'clsx';
import { HealthAndSafety } from 'components/icons/HealthAndSafety';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { TabButton } from 'ui/tabButton';
import IcWorkflowRoute from 'components/icons/IcWorkflowRelationships';
import { orange } from '@material-ui/core/colors';
import { NodeColor } from 'components/graph/colors';
import { NodeStatus } from 'models/graph';
import { AccessLevel } from 'libs/accessLevel';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '30%',
    boxShadow: '10px 0 3px -3px rgba(0, 0, 0, 0.1) inset'
  },
  logo: {
    display: 'flex',
    marginLeft: 10
  },
  closeButton: {
    color: theme.palette.grey[500]
  },
  name: {
    height: 27,
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 'normal',
    color: '#000000'
  },
  content: {
    marginLeft: 21,
    marginRight: 21,
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto'
  },
  infoArea: {
    marginTop: 11,
    borderRadius: 6,
    backgroundColor: '#eeeeee'
  },
  shareInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 60
  }
}));

const Logo: React.VFC<{ type?: string }> = ({ type }) => {
  const { user } = React.useContext(AuthContext);
  let src = '';
  switch (type) {
    case 'project':
    case 'workflow':
    case 'export':
      src = user.organization.feature_spark
        ? '/images/project_hyper_text.png'
        : '/images/project_text.png';
      break;
    case 'datasource':
      src = '/images/datasource_logo.png';
      break;
    case 'report':
      src = '/images/dashboard_logo.png';
      break;
    case 'builder':
      src = '/images/builder_logo.png';
      break;
  }
  if (src === '') {
    return null;
  }
  return (
    <img
      alt="logo"
      src={src}
      style={{
        maxHeight: 50,
        objectFit: 'contain',
        alignSelf: 'start',
        marginTop: 4
      }}
    />
  );
};

const StatusInfo: React.VFC<{
  status: DependencyItemStatus;
  error_message: string;
  href: string;
  target?: HTMLAttributeAnchorTarget;
  onClickStatusIcon?: () => void;
}> = ({ status, error_message, href, target, onClickStatusIcon }) => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <div className={clsx(classes.infoArea, classes.shareInfo)}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          marginLeft: 8
        }}
      >
        <HealthAndSafety />
        <strong>ステータス&nbsp;</strong>
        <span>{dependencyStatusToText(status)}</span>
        <div style={{ flex: 1 }} />
        {status === DependencyItemStatus.Error && (
          <Tooltip title={error_message}>
            <IconButton
              onClick={() => {
                if (target === '_blank') {
                  window.open(href, target);
                } else {
                  history.push(href);
                }
                onClickStatusIcon && onClickStatusIcon();
              }}
            >
              <ArrowRightAlt />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

export const DetailPane: React.FC<{
  type?: string;
  item: NodeItem | null;
  onClose: () => void;
  showWorkflowSelector?: boolean;
  checkedWorkflows?: string[];
  onWorkflowSelect?: (id: string, checked: boolean) => void;
  onClickWorkflowMode?: () => void;
  onClickStatusIcon?: () => void;
}> = ({
  type,
  item,
  showWorkflowSelector,
  checkedWorkflows,
  onWorkflowSelect,
  onClickWorkflowMode,
  onClose,
  onClickStatusIcon
}) => {
  const classes = useStyles();
  const [detail, setDetail] = React.useState<RelationshipItemDetail | null>(
    null
  );
  const [error, setError] = React.useState<string>('');
  const [tab, setTab] = React.useState<0 | 1>(
    showWorkflowSelector && type === 'project' ? 0 : 1
  );

  React.useEffect(() => {
    if (type === 'project' && showWorkflowSelector) {
      setTab(0);
    } else {
      setTab(1);
    }
  }, [type]);

  useAsync(async () => {
    if (!item || !type) {
      return;
    }
    if (type === 'expander') {
      return;
    }
    try {
      const { data } = await getRelationshipItemDetail({
        item_type: type,
        id: item.data.id,
        project_id: item.data.project_id
      });
      setDetail(data);
      setError('');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response && e.response.status === 403) {
          setError('選択したオブジェクトの権限がありません');
        } else {
          setError('詳細の取得に失敗しました');
        }
      }
    }
  }, [type, item]);

  if (!item || !type) {
    return null;
  }

  if (type === 'expander') {
    return null;
  }

  if (type === 'export') {
    const ds = getDatasourceExpression(item.data?.form_value?.type);
    if (ds.key === 'nehan_storage') {
      return null;
    }
  }

  if (error) {
    return (
      <div className={classes.root}>
        <div className={classes.logo}>
          <IconButton className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
          <Logo type={type} />
        </div>
        <div className={classes.content}>
          <Typography color="error">{error}</Typography>
        </div>
      </div>
    );
  }

  if (!detail) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <IconButton className={classes.closeButton} onClick={onClose}>
          <Close />
        </IconButton>
        <Logo type={type} />
      </div>
      <div className={classes.content}>
        <div className={classes.name}>{detail.name}</div>
        {showWorkflowSelector && type === 'project' && (
          <div style={{ display: 'flex', gap: 10, marginBottom: 8 }}>
            <TabButton active={tab === 0} onClick={() => setTab(0)}>
              ワークフロー
            </TabButton>
            <TabButton active={tab === 1} onClick={() => setTab(1)}>
              詳細
            </TabButton>
          </div>
        )}
        {tab === 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: '1 1 auto'
            }}
          >
            <div style={{ flex: '1 1 auto', overflowY: 'auto' }}>
              {detail.workflows?.map((w) => (
                <WorkflowItem
                  key={w.id}
                  name={w.name}
                  status={w.status}
                  checked={
                    checkedWorkflows ? checkedWorkflows.includes(w.id) : false
                  }
                  disableCheck={detail.access_level < AccessLevel.Developer}
                  onCheck={(c: boolean) => {
                    if (onWorkflowSelect) {
                      onWorkflowSelect(w.id, c);
                    }
                  }}
                />
              ))}
            </div>
            <Button
              onClick={onClickWorkflowMode}
              disabled={detail.access_level < AccessLevel.Developer}
            >
              <IcWorkflowRoute style={{ height: 32, width: 32 }} />
              系譜から選択する
            </Button>
          </div>
        )}
        {tab === 1 && (
          <>
            {['project', 'datasource', 'report', 'workflow', 'export'].includes(
              type
            ) && (
              <StatusInfo
                status={detail.status}
                error_message={detail.error_message}
                href={
                  detail.error_link || getItemHref(type, item.data.list_item)
                }
                target={type === 'workflow' ? undefined : '_blank'}
                onClickStatusIcon={onClickStatusIcon}
              />
            )}
            {['project', 'builder', 'datasource', 'report'].includes(type) && (
              <>
                <ShareInfo
                  people={detail.people}
                  accessLevel={detail.access_level}
                />
                {detail.creator && <CreatorInfo creator={detail.creator} />}
                <BaseInfo
                  icon={<CalendarToday />}
                  title="更新日時"
                  text={dayjs(detail.updated_at).format(
                    'YYYY年MM月DD日 HH時mm分'
                  )}
                />
                <BaseInfo
                  icon={<CalendarToday />}
                  title="作成日時"
                  text={dayjs(detail.created_at).format(
                    'YYYY年MM月DD日 HH時mm分'
                  )}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export function getItemHref(
  type: string,
  item: NodeItem['data']['list_item']
): string {
  switch (type) {
    case 'project':
      return `/projects/${item.uuid}`;
    case 'builder':
      return `/builders/${item.uuid}`;
    case 'datasource':
      // @ts-ignore
      return createLink(item.type, item.id);
    case 'export':
      // @ts-ignore
      return `/projects/${item.project_uuid}/nodes/${item.uuid}`;
    case 'report':
      return `/reports/${item.uuid}`;
    default:
      return '';
  }
}

const Checkbox = withStyles({
  root: {
    alignSelf: 'start',
    color: '#b8b8b8',
    '&$checked': {
      color: '#344955'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MUICheckbox color="default" {...props} />);

const itemStyle = makeStyles({
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    marginBottom: 8
  },
  itemWrapper: {
    width: '100%',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 8
  },
  item: {
    paddingLeft: 3,
    paddingRight: 3,
    height: 32,
    borderRadius: 16,
    display: 'flex',
    boxSizing: 'border-box',
    position: 'relative',
    flex: '1 1 auto',
    color: '#344955',
    border: 'solid 1px #344955',
    backgroundColor: '#f1f1f1',
    zIndex: 1
  },
  label: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 6
  },
  text: {
    display: 'inline-block',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  statusExecuting: {
    animation: '1s fadeAnimation',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    animationDuration: '2s'
  },
  '@keyframes fadeAnimation': {
    '@keyframes fadeAnimation': {
      '0%': { backgroundColor: orange[700] },
      '50%': { backgroundColor: orange[300] },
      '100%': { backgroundColor: orange[700] }
    }
  },
  statusSuccess: {
    color: NodeColor[NodeStatus.Executed].light,
    backgroundColor: NodeColor[NodeStatus.Executed].dark
  },
  statusWarning: {
    color: NodeColor[NodeStatus.Warning].light,
    backgroundColor: NodeColor[NodeStatus.Warning].dark
  },
  statusError: {
    color: NodeColor[NodeStatus.Error].light,
    backgroundColor: NodeColor[NodeStatus.Error].dark
  }
});
const WorkflowItem: React.FC<{
  name: string;
  status: DependencyItemStatus;
  checked: boolean;
  disableCheck?: boolean;
  onCheck: (checked: boolean) => void;
}> = ({ name, status, checked, disableCheck, onCheck }) => {
  const classes = itemStyle();
  return (
    <div className={clsx(classes.root)}>
      <Checkbox
        icon={<RadioButtonUnchecked />}
        checkedIcon={<CheckCircle />}
        checked={checked}
        disabled={disableCheck}
        onChange={(_, checked) => onCheck(checked)}
      />
      <div className={classes.itemWrapper}>
        <div
          className={clsx(
            classes.item,
            status === DependencyItemStatus.Running && classes.statusExecuting,
            status === DependencyItemStatus.Success && classes.statusSuccess,
            status === DependencyItemStatus.Warning && classes.statusWarning,
            status === DependencyItemStatus.Error && classes.statusError
          )}
        >
          <div className={clsx(classes.label)}>
            <Typography className={classes.text}>{name}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};
