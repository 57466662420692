// データソース、データエクスポートの表記
import * as React from 'react';
import { Datasource } from 'reducers/datasource';
import { humanFileSize } from 'libs/filesize';
import { get } from 'lodash-es';
import { removeDsKeyVersion } from 'components/connection/newConnectionList';

export const Category = {
  file: 0,
  database: 1,
  etc: 2
} as const;

export type Category = (typeof Category)[keyof typeof Category];

export const categories = [
  {
    category: Category.file,
    title: 'ファイル系',
    order: 0
  },
  {
    category: Category.database,
    title: 'データベース系',
    order: 1
  },
  {
    category: Category.etc,
    title: 'その他サービス',
    order: 2
  }
];

const cdataTypes = ['salesforce', 'zoho_crm', 'kintone', 'hubspot'];

export interface UsableDatasource {
  id: number;
  key: string;
  logo?: string;
  name: string;
  enabledInTrial: boolean;
  v2: boolean;
  category: Category;
  order: number;
  available: boolean;
  spark: boolean;
}

interface DatasourceExpression {
  key: string;
  name: string;
  logoPath?: string;
}

export const getExactDatasourceTypeName = (d: Datasource): string => {
  if (d.type === 'nehan_storage') {
    if (d.port != null && d.port.objectType === 'model') {
      return '機械学習モデル';
    }
  }
  return getDatasourceExpression(d.type).name;
};

export function getDatasourceExpression(type: string): DatasourceExpression {
  const typeWithoutVersion = removeDsKeyVersion(type);
  switch (typeWithoutVersion) {
    case 'csv':
    case 'spark_csv':
      return {
        key: 'csv',
        name: 'ファイル',
        logoPath: '/images/iconCsv.svg'
      };
    case 's3':
    case 'spark_s3':
      return {
        key: 's3',
        name: 'Amazon S3',
        logoPath: '/images/iconAmazonS3.svg'
      };
    case 'psql':
      return {
        key: 'psql',
        name: 'PostgreSQL',
        logoPath: '/images/iconPostgresSQL.svg'
      };
    case 'bq':
    case 'gbq':
    case 'bigquery':
      return {
        key: 'bigquery',
        name: 'Google BigQuery',
        logoPath: '/images/iconGoogleBigQuery.svg'
      };
    case 'gcs':
      return {
        key: 'gcs',
        name: 'Google Cloud Storage',
        logoPath: '/images/iconGoogleCloudStorage.svg'
      };
    case 'gdrive':
      return {
        key: 'gdrive',
        name: 'Google Drive',
        logoPath: '/images/iconGoogleDrive.svg'
      };
    case 'gss':
      return {
        key: 'gss',
        name: 'スプレッドシート',
        logoPath: '/images/iconGoogleSpreadSheet.svg'
      };
    case 'td':
    case 'treasure_data':
      return {
        key: 'treasure_data',
        name: 'Treasure Data',
        logoPath: '/images/iconTreasureData.png'
      };
    case 'redshift':
      return {
        key: 'redshift',
        name: 'Amazon Redshift',
        logoPath: '/images/iconAmazonRedshift.svg'
      };
    case 'athena':
      return {
        key: 'athena',
        name: 'Amazon Athena',
        logoPath: '/images/iconAmazonAthena.svg'
      };
    case 'mysql':
      return {
        key: 'mysql',
        name: 'MySQL',
        logoPath: '/images/iconMySQL.svg'
      };
    case 'nehan_storage':
      return {
        key: 'nehan_storage',
        name: '中間データ'
      };
    case 'local':
      return {
        key: 'local',
        name: 'このパソコン'
      };
    case 'builder_waypoint':
      return {
        key: 'builder_waypoint',
        name: '中間データ'
      };
    case 'mseo':
      return {
        key: 'mseo',
        name: 'Microsoft Excel Online',
        logoPath: '/images/iconExcelOnline.svg'
      };
    case 'azdl':
      return {
        key: 'azdl',
        name: 'Azure Data Lakes',
        logoPath: '/images/iconAzureDataLake.svg'
      };
    case 'azureblob':
      return {
        key: 'azureblob',
        name: 'Azure Blob Storage',
        logoPath: '/images/iconAzureBlob.svg'
      };
    case 'json':
      return {
        key: 'json',
        name: 'JSON',
        logoPath: '/images/iconJSON.svg'
      };
    case 'msss':
      return {
        key: 'msss',
        name: 'Microsoft SQL server/Azure T-SQL',
        logoPath: '/images/iconSQL.svg'
      };
    case 'aa':
      return {
        key: 'aa',
        name: 'Adobe Analytics',
        logoPath: '/images/iconAdobeAnalytics.svg'
      };
    case 'azts':
      return {
        key: 'azts',
        name: 'Azure Table Storage',
        logoPath: '/images/iconAzureTables.svg'
      };
    case 'msa':
      return {
        key: 'msa',
        name: 'Microsoft Access',
        logoPath: '/images/iconAccess.svg'
      };
    case 'ord':
      return {
        key: 'ord',
        name: 'Oracle Database',
        logoPath: '/images/iconOracleDB.svg'
      };
    case 'mongo':
      return {
        key: 'mongo',
        name: 'MongoDB',
        logoPath: '/images/iconMongoDB.svg'
      };
    case 'maria':
      return {
        key: 'maria',
        name: 'MariaDB',
        logoPath: '/images/iconMariaDB.svg'
      };
    case 'apss':
      return {
        key: 'apss',
        name: 'Apache Spark SQL',
        logoPath: '/images/iconSparkSQL.svg'
      };
    case 'salesforce':
      return {
        key: 'salesforce',
        name: 'Salesforce',
        logoPath: '/images/iconSalesforce.svg'
      };
    case 'sfmc':
      return {
        key: 'sfmc',
        name: 'Salesforce Marketing Cloud',
        logoPath: '/images/iconSFMarketingCloud.svg'
      };
    case 'sfp':
      return {
        key: 'sfp',
        name: 'Salesforce Pardot',
        logoPath: '/images/iconsalesforcepardot.svg'
      };
    case 'sfc':
      return {
        key: 'sfc',
        name: 'Salesforce Chatter',
        logoPath: '/images/iconSalesforceChatter.svg'
      };
    case 'fb':
      return {
        key: 'fb',
        name: 'Facebook',
        logoPath: '/images/iconFacebook.svg'
      };
    case 'github':
      return {
        key: 'github',
        name: 'GitHub',
        logoPath: '/images/iconGitHub.svg'
      };
    case 'gads':
      return {
        key: 'gads',
        name: 'Google Ads',
        logoPath: '/images/iconGoogleAds.svg'
      };
    case 'gan':
      return {
        key: 'gan',
        name: 'Google Analytics',
        logoPath: '/images/iconGoogleAnalytics.svg'
      };
    case 'gan4':
      return {
        key: 'gan4',
        name: 'Google Analytics 4',
        logoPath: '/images/iconGoogleAnalytics.svg'
      };
    case 'sansan':
      return {
        key: 'sansan',
        name: 'Sansan',
        logoPath: '/images/iconsansan.png'
      };
    case 'snowflake':
      return {
        key: 'snowflake',
        name: 'Snowflake',
        logoPath: '/images/iconSnowflake.svg'
      };
    case 'twitter':
      return {
        key: 'twitter',
        name: 'Twitter',
        logoPath: '/images/iconTwitter.svg'
      };
    case 'youtube':
      return {
        key: 'youtube',
        name: 'YouTube',
        logoPath: '/images/iconYouTube.svg'
      };
    case 'yta':
      return {
        key: 'yta',
        name: 'YouTube Analytics',
        logoPath: '/images/iconYoutubeAnalytics.svg'
      };
    case 'kintone':
      return {
        key: 'kintone',
        name: 'Kintone',
        logoPath: '/images/iconKintone.svg'
      };
    case 'hubspot':
      return {
        key: 'hubspot',
        name: 'HubSpot',
        logoPath: '/images/iconHubSpot.svg'
      };
    case 'en':
      return {
        key: 'en',
        name: 'Evernote',
        logoPath: '/images/iconEvernote.svg'
      };
    case 'insta':
      return {
        key: 'insta',
        name: 'Instagram',
        logoPath: '/images/iconInstagram.svg'
      };
    case 'ad':
      return {
        key: 'ad',
        name: 'Active Directory',
        logoPath: '/images/iconActiveDirectory.svg'
      };
    case 'gd':
      return {
        key: 'gd',
        name: 'Google Drive',
        logoPath: '/images/iconGoogleDrive.svg'
      };
    case 'db':
      return {
        key: 'db',
        name: 'Dropbox',
        logoPath: '/images/iconDropbox.svg'
      };
    case 'slack':
      return {
        key: 'slack',
        name: 'Slack',
        logoPath: '/images/iconSlack.svg'
      };
    case 'sftp':
      return {
        key: 'sftp',
        name: 'SFTP',
        logoPath: '/images/iconSFTP.svg'
      };
    case 'zoho_crm':
      return {
        key: 'zoho_crm',
        name: 'Zoho CRM',
        logoPath: '/images/iconZohoCRM.svg'
      };
    case 'onedrive':
      return {
        key: 'onedrive',
        name: 'OneDrive',
        logoPath: '/images/iconOneDrive.svg'
      };
    case 'nehan_internal':
      return {
        key: 'nehan_internal',
        name: 'チェイン',
        logoPath: '/images/iconNehanInternal.svg'
      };
    default:
      return {
        key: 'default',
        name: '未設定'
      };
  }
}

const detailItems: {
  [k: string]: Array<{
    name: string;
    value: string | ((ds: Datasource) => React.ReactNode);
  }>;
} = {
  csv: [
    { name: '元のファイル名', value: 'params.filename' },
    {
      name: 'ファイルサイズ',
      value: (ds) => {
        const size = get(ds.params, 'filesize', 0);
        return humanFileSize(size);
      }
    }
  ],
  psql: [
    { name: '接続先', value: 'connection.name' },
    {
      name: 'データベース',
      value: 'connection.detail.database'
    },
    { name: 'クエリ', value: 'params.query' }
  ],
  csv_v2: [
    {
      name: 'ファイル名とサイズ',
      value: (ds) => {
        return (
          <ul style={{ padding: 0, margin: 0, listStyle: 'none' }}>
            {ds.params.files.files.map((f) => (
              <li key={f.filename}>
                {f.filename}, {humanFileSize(f.size)}
              </li>
            ))}
          </ul>
        );
      }
    }
  ]
};

export function getDatasourceDetails(
  datasource?: Datasource
): Array<[string, string | React.ReactNode]> {
  if (!datasource) {
    return [];
  }

  const def = detailItems[datasource.type];
  if (!def) {
    return [];
  }

  return def.map(({ name, value }) => {
    if (typeof value === 'function') {
      return [name, value(datasource)];
    } else {
      return [name, get(datasource, value, 'none')];
    }
  });
}

export function canForceReload(d: Datasource | undefined): boolean {
  if (!d) {
    return false;
  }
  return (
    d.type !== 'csv' &&
    d.type !== 'csv_v2' &&
    d.type !== 'csv_v3' &&
    d.type !== 'nehan_storage' &&
    d.type !== 'nehan_internal'
  );
}

export const isCdataTypes = (type: string): boolean => {
  return cdataTypes.includes(type);
};
