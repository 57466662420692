import { _delete, _get, _post, _put } from 'libs/api/base';
import {
  NotificationDstCreateRequest,
  NotificationDstCreateRequestOnOauth,
  NotificationDstCreateResponse,
  NotificationDstGetResponse,
  NotificationDstUpdateRequest
} from 'models/notificationDst/api/base';
import { ChatworkRoomsResponse } from 'models/notificationDst/api/chatwork';
import { SlackChannelsResponse } from 'models/notificationDst/api/slack';
import { TeamsChannelsResponse } from 'models/notificationDst/api/teams';
import { DestinationType } from 'models/notificationDst/base';
import { QueryParams } from 'ui/listViewBase';

// oauthのコールバック時に生成する時の
export function createNotificationDstOnOauth(
  body: NotificationDstCreateRequestOnOauth
) {
  return _post<NotificationDstCreateResponse>('/notification_dsts', body);
}

export function createNotificationDst(body: NotificationDstCreateRequest) {
  return _post<NotificationDstCreateResponse>('/notification_dsts', body);
}

export function updateNotificationDst(
  uuid: string,
  body: NotificationDstUpdateRequest
) {
  return _put<NotificationDstCreateResponse>(
    `/notification_dsts/${uuid}`,
    body
  );
}

export function getNotificationDst(uuid: string) {
  return _get<NotificationDstGetResponse>(`/notification_dsts/${uuid}`);
}

export async function getNotificationDsts(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get(`/notification_dsts`, { params: { folderId, ...queryParams } });
}

export async function getAdminNotificationDsts(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get(`/admin/notification_dsts`, {
    params: { folderId, ...queryParams }
  });
}

export function deleteNotificationDst(uuid: string) {
  return _delete(`/notification_dsts/${uuid}`);
}

export function getNotificationDstSlackChannels(uuid: string) {
  return _get<SlackChannelsResponse>(
    `/notification_dsts/${uuid}/slack/channels`
  );
}

export function getNotificationDstTeamsChannels(uuid: string) {
  return _get<TeamsChannelsResponse>(
    `/notification_dsts/${uuid}/teams/channels`
  );
}

export function getNotificationDstChatworkRooms(uuid: string) {
  return _get<ChatworkRoomsResponse>(
    `/notification_dsts/${uuid}/chatwork/rooms`
  );
}

export function deleteNotificationDstMember(
  ndid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/notification_dsts/${ndid}/members`, { id, is_group });
}

export function getNotificationDstUserDstTypes() {
  return _get<DestinationType[]>(`/notification_dst_user_dst_types`);
}
