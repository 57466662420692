import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppBar, Toolbar } from '@material-ui/core';
import { createStyles, WithStyles, withStyles } from '@material-ui/core/styles';
import type { MainAppBarContainerProps } from 'containers/mainAppBar';

const styles = (theme) =>
  createStyles({
    appBar: {
      color: '#fff',
      backgroundColor: '#eee',
      zIndex: theme.zIndex.drawer + 1,
      borderTop: '4px solid #e2762a'
    },
    content: {
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center'
    }
  });

class MainAppBar extends React.PureComponent<
  MainAppBarContainerProps & WithStyles<typeof styles> & RouteComponentProps<{}>
> {
  render() {
    const { classes, children, appBarProps } = this.props;

    return (
      <>
        <AppBar
          elevation={0}
          color="default"
          className={classes.appBar}
          {...appBarProps}
        >
          <Toolbar>
            <div className={classes.content}>{children ? children : null}</div>
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

export default withStyles(styles)(withRouter(MainAppBar));
