import * as React from 'react';
import { Grid } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import MainLayout from 'components/layouts/main';
import { getExportNodes } from 'libs/api';
import ListView from 'components/ui/listView';
import { projectTheme } from '../../theme';

const ExportListPage: React.FC = () => {
  return (
    <MainLayout selectedMenu="exports">
      <Grid
        container={true}
        justifyContent="center"
        style={{ height: 'calc(100vh - 68px)' }}
      >
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="exports"
            showProject={true}
            load={getExportNodes}
            supportFolder={false}
          />
        </ThemeProvider>
      </Grid>
    </MainLayout>
  );
};

export default ExportListPage;
