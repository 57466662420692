import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import MainAppBar from 'containers/mainAppBar';
import MainDrawer, { MainDrawerProps } from 'components/mainDrawer';
import { Logo, NoticeIcon } from 'components/ui/mainAppBar/icons';
import { Spacer } from 'components/ui/common/spacer';
import ProfileIcon from 'components/ui/mainAppBar/profileIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    minWidth: 0
  }
}));

export const MainLayout: React.FC<MainDrawerProps & { hideInfo?: boolean }> = ({
  selectedMenu,
  children,
  hideInfo
}) => {
  const classes = useStyles();

  const logoImage = React.useMemo(() => {
    return (
      '/images/' +
      (import.meta.env.VITE_ENV === 'development'
        ? 'logoDev.png'
        : import.meta.env.VITE_ENV === 'staging'
        ? 'logoStg.png'
        : 'logo.png')
    );
  }, []);

  return (
    <div className={classes.root}>
      <MainAppBar>
        <>
          <Logo link="/projects" imageOrPath={logoImage} />
          <Spacer />
          {!hideInfo && <NoticeIcon />}
          <ProfileIcon />
        </>
      </MainAppBar>
      <MainDrawer selectedMenu={selectedMenu} />
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {children}
      </main>
    </div>
  );
};

export default MainLayout;
