import { _delete, _get, _post, _put } from 'libs/api/base';
import { Variable } from 'models/project';
import { VariableItem } from 'models/dependency';
import { Path } from 'models/folder';
import { ResourceType } from 'models/variable';
import { QueryParams } from 'ui/listViewBase';

export function createVariable(variable: Variable, folder_id?: string | null) {
  return _post<{ uuid: string }>(`/variables`, { variable, folder_id });
}

export function getVariable(id: string) {
  return _get(`/variables/${id}`);
}

export function updateVariable(id: string, variable: Variable) {
  return _put(`/variables/${id}`, { variable });
}

export function getVariableList(folderId?: string, queryParams?: QueryParams) {
  return _get<{ items: VariableItem[]; paths: Path[]; emails: string[] }>(
    `/variable_list`,
    {
      params: { folderId, ...queryParams }
    }
  );
}

export function getVariables(
  resourceType?: ResourceType,
  resourceUuid?: string
) {
  return _get<{ items: VariableItem[] }>(`/variables`, {
    params: { resourceType, resourceUuid }
  });
}

export function deleteVariable(id: string) {
  return _delete(`/variables/${id}`);
}

export function importVariables(
  resourceType: ResourceType,
  resourceUuid: string,
  variableUuids: string[]
) {
  return _post(`/variables/import`, {
    resourceType,
    resourceUuid,
    variableUuids
  });
}

export function unimportVariables(
  resourceType: ResourceType,
  resourceUuid: string,
  variableUuids: string[]
) {
  return _post(`/variables/unimport`, {
    resourceType,
    resourceUuid,
    variableUuids
  });
}

export function deleteVariableMember(
  vid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/variables/${vid}/members`, { id, is_group });
}

export async function getAdminVariables(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get('/admin/variables', {
    params: { folderId, ...queryParams }
  });
}
