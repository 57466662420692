import { AccessLevel } from 'libs/accessLevel';
import { NodeStatus } from 'models/graph';
import { DatasourceStatus } from 'models/datasource';
import { NodeBackend } from 'models/websocket';
import { Path } from 'models/folder';
import { ProjectStatus } from 'models/project';
import { ReportStatus } from 'models/report';
import {
  ConfigStatus,
  ScheduleHistoryStatus,
  SchedulingConfig
} from 'models/schedule';

export enum DependencyItemStatus {
  Unknown = 0,
  Success,
  Warning,
  Error,
  Running,
  NotExecuted
}

export function dependencyStatusToText(status: DependencyItemStatus) {
  switch (status) {
    case DependencyItemStatus.Success:
      return '成功';
    case DependencyItemStatus.Warning:
      return '警告';
    case DependencyItemStatus.Error:
      return 'エラー';
    case DependencyItemStatus.Running:
      return '実行中';
    case DependencyItemStatus.NotExecuted:
      return '未実行';
    default:
      return '';
  }
}

export interface DependencyItems {
  status: DependencyItemStatus;
  people: number;
  projects?: BaseItem[];
  connections?: ConnectionItem[];
  exports?: ExportItem[];
  builders?: BuilderItem[];
  datasources?: DatasourceItem[];
  reports?: ReportItem[];
  creator?: Creator;
  paths: Path[];
}

export type ListItemType =
  | 'projects'
  | 'schedules'
  | 'reports'
  | 'builders'
  | 'connections'
  | 'datasources'
  | 'exports'
  | 'visualize_selector'
  | 'report_selector'
  | 'folder_items'
  | 'creator'
  | 'variables'
  | 'schedules'
  | 'selector_project'
  | 'selector_datasource'
  | 'selector_report'
  | 'selector_export'
  | 'notification_dsts'
  | 'notifications'
  | 'users'
  | 'user_groups'
  | 'organizations';

export type ListItem =
  | BaseItem
  | ProjectItem
  | BuilderItem
  | ConnectionItem
  | DatasourceItem
  | ExportItem
  | ReportItem
  | FolderItem
  | NotificationItem
  | NotificationDstItem
  | UserGroupItem
  | OrganizationItem;

export interface BaseItem {
  uuid: string;
  name: string;
  created_at: string;
  updated_at: string;
  access_level: AccessLevel;
  organization_name?: string;
  is_folder: boolean;
}

export interface ProjectItem extends BaseItem {
  email: string;
  status: ProjectStatus;
  status_node_id: string;
  status_nodes: number;
}

export interface BuilderItem extends BaseItem {
  connection_type: string;
  connection_name: string;
  email: string;
}

export interface ConnectionItem extends BaseItem {
  type: string;
  email: string;
}

export interface NotificationDstItem extends BaseItem {
  type: string;
  email: string;
}

export interface NotificationItem extends BaseItem {
  type: string;
  email: string;
}

export interface DatasourceItem extends BaseItem {
  id: number;
  status: DatasourceStatus;
  backend: NodeBackend;
  type: string;
  email: string;
  error: string;
}

export interface ReportItem extends BaseItem {
  enabled_public_token: boolean;
  public_token?: string;
  ip_filter?: string;
  restrict_user_groups: boolean;
  use_password: boolean;
  email: string;
  status: ReportStatus;
  error_charts: number;
}

export interface VariableItem extends BaseItem {
  name: string;
  value: string;
  dtype: string;
  date_type: string;
  date_unit: string;
  date_operator: string;
  date_format: string;
  timestamp_format: string;
  time_format: string;
  date_custom_format: string;
  timestamp_custom_format: string;
  time_custom_format: string;
  desc: string;
  parent_uuid: string;
  checked?: boolean;
  email: string;
}

export interface ScheduleItem extends BaseItem {
  status: ScheduleHistoryStatus;
  next_run_time: string;
  scheduling_config: SchedulingConfig;
  config_status: ConfigStatus;
  error: string;
  error_node_id: string;
  error_project_id: string;
  error_datasource_id: string;
}

export interface UserGroupItem {
  uuid: string;
  name: string;
  member_count: number;
  ip_address: string;
  all_users: boolean;
  is_folder: null;
}

export interface OrganizationItem extends BaseItem {
  id: number;
  namespace: string;
}

export interface FolderItem {
  uuid: string;
  name: string;
  type: string;
  updated_at: string;
  datasource_type: string;
  is_folder: boolean;
}

export interface ExportItem {
  uuid: string;
  type: string;
  status: NodeStatus;
  started_at?: string;
  ended_at?: string;
  project_name: string;
  project_uuid: string;
  workflow_name: string;
  destination: string;
  is_folder?: boolean;
  error?: string;
}

export interface Creator {
  email: string;
  last_name: string;
  first_name: string;
  is_deleted: boolean;
  is_folder?: boolean;
}

export const isBaseItem = (value): value is BaseItem => {
  return value.name !== undefined;
};

export interface ReportListResponse {
  items: ReportItem[];
  paths: Path[];
  emails?: string[];
  types?: string[];
}

export interface TreeReportItem {
  item: ReportItem & { folder_uuid: string; parent_folder_uuid: string };
  children: TreeReportItem[];
}

export interface TreeResponse {
  tree: TreeReportItem;
  items: ReportItem[];
}
