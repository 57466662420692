export enum AccessLevel {
  Disabled = 0,
  Viewer = 10,
  Developer = 20,
  Admin = 30
}

export function AccessLevelString(a: AccessLevel): string {
  switch (a) {
    case AccessLevel.Disabled:
      return '無効';
    case AccessLevel.Viewer:
      return '参照者';
    case AccessLevel.Developer:
      return '編集者';
    case AccessLevel.Admin:
      return '管理者';
    default:
      const ex: never = a;
      return ex;
  }
}

export enum UserRole {
  Disabled = 0,
  DashboardViewer = 10,
  Developer = 20,
  Admin = 30,
  NehanAdmin = 100
}

export function UserRoleString(r: UserRole): string {
  switch (r) {
    case UserRole.DashboardViewer:
      return 'ダッシュボードユーザー';
    case UserRole.Admin:
      return 'nehanマネージャー';
    case UserRole.Developer:
      return 'nehanユーザー';
    case UserRole.Disabled:
      return 'アカウント停止';
    case UserRole.NehanAdmin:
      return 'nehan管理者';
    default:
      const ex: never = r;
      return ex;
  }
}
