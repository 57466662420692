import * as React from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {
  flatten,
  get,
  map,
  max,
  maxBy,
  min,
  minBy,
  pick,
  values
} from 'lodash-es';
import Tabulator from 'tabulator-tables';
import { ReactTabulator } from 'react-tabulator';

import chroma from 'chroma-js';
import { Dtypes } from 'Utils/dataTypes';
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Popper,
  Tooltip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Heatmap, Row, StringCellColorOption, TableColumn } from 'models/chart';
import {
  ArrowDownward,
  ArrowUpward,
  Check,
  FileCopy,
  Menu as MenuIcon,
  Settings
} from '@material-ui/icons';
import SpinnerScreen from 'components/LoadingSpinner';
import { AlertMessage } from 'components/visualize/alertMessage';
import {
  getColors,
  Palette,
  SequentialColorType
} from 'components/visualize/chart/color';
import { NodeStatus, PortStatus } from 'models/graph';
import {
  MenuItemDtypeChange,
  MenuItemNameChange
} from 'components/ui/dataTableMenuItem';
import { NodeColor } from 'components/graph/colors';
import {
  defaultMinBarColor,
  RowAlign,
  RowDir,
  RowBottomAggregateFunc,
  RowSetTextColor,
  RowShowCellColor,
  RowShowProgress,
  RowStringCellColor,
  RowToFixed,
  RowToFormat,
  RowToLocale,
  RowToPercentage
} from 'components/visualize/tab/tableColumnSetting';
import { selectColumnsByRule } from 'components/form/columnSelectV2Field';
import { BottomAggregateTooltip } from './dataTable/bottomAggregateTooltip';
import { Dialog } from './common/dialog';

const MAX_CELL_WIDTH = 200;
const SPACER_COL_NAME = '__nehan_end_spacer';
// @ts-ignore: 2339
window.moment = dayjs;

// データソースの列名・列型変換の反映ステータス
export enum ReflectType {
  None, // 変更なし
  NotReflected, // 未反映
  Reflected // 反映済み
}

Tabulator.prototype.extendModule('layout', 'modes', {
  fitData: function (columns) {
    // reset all cell width
    columns.forEach((col) => {
      col.element.style.width = '';
      const cells = col.cells;
      if (cells.length === 0) {
        return;
      }
      for (let i = 0; i < cells.length; i++) {
        cells[i].element.style.width = '';
      }
    });

    // get cell width per column
    const colSizes: number[] = [];
    columns.forEach((col) => {
      // カラム幅が固定されている場合
      if (col.widthFixed) {
        colSizes.push(col.width);
        return;
      }

      let maxCellWidth = col.element.offsetWidth;
      // カラムヘッダの幅
      if (maxCellWidth >= MAX_CELL_WIDTH) {
        maxCellWidth = MAX_CELL_WIDTH;
        colSizes.push(maxCellWidth);
        return;
      }

      // セルの幅
      const cells = col.cells;
      for (let i = 0; i < cells.length; i++) {
        const cellWidth = cells[i].element.offsetWidth;
        if (maxCellWidth < cellWidth) {
          maxCellWidth = cellWidth;
        }
        if (maxCellWidth >= MAX_CELL_WIDTH) {
          maxCellWidth = MAX_CELL_WIDTH;
          break;
        }
      }
      colSizes.push(Math.min(maxCellWidth + 10, MAX_CELL_WIDTH));
    });

    // set cell width
    columns.forEach((col, i) => {
      if (col.visible) {
        col.setWidthActual(colSizes[i]);
      }
    });
  }
});

dayjs.extend(utc);

const _typeof =
  typeof Symbol === 'function' && typeof Symbol.iterator === 'symbol'
    ? (obj) => {
        return typeof obj;
      }
    : (obj) => {
        return obj &&
          typeof Symbol === 'function' &&
          obj.constructor === Symbol &&
          obj !== Symbol.prototype
          ? 'symbol'
          : typeof obj;
      };

function getContrastColor(hexcolor: string) {
  const hex = hexcolor.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq < 150 ? 'white' : 'black';
}

function renderColorCell(
  element: HTMLElement,
  _: (callback: () => void) => void,
  params: {
    value: string;
    bgColor: string;
    align: string;
    textColor?: string;
  }
) {
  const { value, bgColor, align, textColor } = params;
  element.style.backgroundColor = bgColor;
  element.style.alignItems = 'center';
  element.style.justifyContent = align;
  if (textColor) {
    element.style.color = textColor;
  } else {
    // コントラストを考慮したtextColorにする
    const contrastColor = getContrastColor(bgColor || '#fff');
    if (contrastColor === 'white') {
      element.style.color = contrastColor;
    }
  }
  // element.style.height = '100%';
  return value;
}

function renderProgressBar(
  element: HTMLElement,
  onRendered: (callback: () => void) => void,
  params: {
    percentValue: number;
    min: number;
    max: number;
    color: string;
    legend: string;
    legendColor: string;
    legendAlign: string;
  }
) {
  const { percentValue, min, max, color, legend, legendColor, legendAlign } =
    params;
  element.style.minWidth = '30px';
  element.style.position = 'relative';

  element.setAttribute('aria-label', `${percentValue}`);

  const totalBarEl = document.createElement('div');
  totalBarEl.style.backgroundColor = chroma(color).alpha(0.2).hex('rgba');
  totalBarEl.style.height = '80%';
  totalBarEl.style.width = '100%';
  totalBarEl.style.display = 'inline-flex';
  totalBarEl.style.borderRadius = '5px';

  const barEl = document.createElement('div');
  barEl.style.display = 'inline-block';
  barEl.style.position = 'relative';
  barEl.style.width = percentValue + '%';
  barEl.style.backgroundColor = color;
  barEl.style.height = '100%';
  barEl.style.borderRadius = '5px';

  barEl.setAttribute('data-max', `${max}`);
  barEl.setAttribute('data-min', `${min}`);

  totalBarEl.appendChild(barEl);
  onRendered(() => {
    element.appendChild(totalBarEl);

    // value
    if (legend) {
      const legendEl = document.createElement('div');
      legendEl.style.position = 'absolute';
      legendEl.style.top = '0';
      legendEl.style.right = '0';
      legendEl.style.textAlign = legendAlign;
      legendEl.style.width = '100%';
      legendEl.style.color = legendColor;
      legendEl.innerHTML = legend;
      element.appendChild(legendEl);
    }
  });
}

const getColumnIcon = (dataType: string | undefined) => {
  switch (dataType) {
    case Dtypes.NUMBER:
    case Dtypes.TIMEDELTA:
      return `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" style="min-width: 16px">
    <g fill="none" fill-rule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#59B37B"/>
        <text fill="#FFF" font-family="Noto Sans JP" font-size="8" font-weight="bold">
            <tspan x="1.5" y="11.7">123</tspan>
        </text>
    </g>
</svg>

      `;
    case Dtypes.STRING:
      return `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" style="min-width: 16px">
    <g fill="none" fill-rule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#67B8DA"/>
        <text fill="#FFF" font-family="'Noto Sans JP'" font-size="10" font-weight="500">
            <tspan x="3.6" y="12">文</tspan>
        </text>
    </g>
</svg>

      `;
    case Dtypes.DATE:
      return `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" style="min-width: 16px">
  <g fill="none" fill-rule="evenodd">
      <circle cx="8.5" cy="8.5" r="8.5" fill="#787FB7"/>
      <g transform="translate(3.5 2.5)">
          <rect width="9" height="9" x=".5" y="1.5" stroke="#FFF" rx="1"/>
          <rect width="2" height="3" x="2" fill="#FFF" rx="1"/>
          <rect width="2" height="3" x="6" fill="#FFF" rx="1"/>
          <rect width="2" height="2" x="2" y="4" fill="#FFF" rx="1"/>
          <rect width="2" height="2" x="2" y="7" fill="#FFF" rx="1"/>
          <rect width="2" height="2" x="6" y="4" fill="#FFF" rx="1"/>
          <rect width="2" height="2" x="6" y="7" fill="#FFF" rx="1"/>
      </g>
  </g>
</svg>`;
    case Dtypes.TIME:
      return `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" style="min-width: 16px">
    <g fill="none" fill-rule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#AD72BF"/>
        <g transform="translate(3 3)">
            <circle cx="5.5" cy="5.5" r="5" stroke="#FFF"/>
            <path fill="#FFF" d="M5 5H8V6H5z"/>
            <path fill="#FFF" d="M3.5 3.5H7.5V4.5H3.5z" transform="rotate(90 5.5 4)"/>
        </g>
    </g>
</svg>
      `;
    case Dtypes.TIMESTAMP:
      return `
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17">
            <defs><style>.cls-2{fill:#fff}</style></defs>
            <circle cx="8.5" cy="8.5" r="8.5" style="fill:#75a5b1"/>
            <path class="cls-2" d="M5.5 6.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM5.5 9.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z"/>
            <path class="cls-2" d="M9.5 2.5a1 1 0 0 0-1 1h-2a1 1 0 0 0-2 0h-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h5.38a4 4 0 1 0 3.62-6.86V4.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1-1Zm0 3a1 1 0 0 0 1-1h1v2a4.07 4.07 0 0 1 .62.06 4.07 4.07 0 0 0-.62-.06 4.1 4.1 0 0 0-1.36.25 1 1 0 0 0-.64-.25 1 1 0 0 0-1 1 .94.94 0 0 0 .06.3 3.95 3.95 0 0 0-.51 4.7H3.5v-8h1a1 1 0 0 0 2 0h2a1 1 0 0 0 1 1Zm2 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm-3.06-.45Z"/>
            <path class="cls-2" d="M12 8h-1v3h3v-1h-2V8z"/>
        </svg>
      `;

    default:
      return `
<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 17 17" style="min-width: 16px">
    <g fill="none" fill-rule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="gray"/>
        <path fill="#FFF" fill-rule="nonzero" d="M9.298 10.636v-.21c0-.355.054-.644.161-.868.107-.224.282-.471.525-.742l.56-.63c.336-.392.567-.756.693-1.092.126-.336.189-.7.189-1.092 0-.821-.245-1.477-.735-1.967S9.461 3.3 8.472 3.3c-.457 0-.861.072-1.211.217-.35.145-.64.343-.868.595-.229.252-.401.555-.518.91-.117.355-.175.742-.175 1.162v.322h1.848v-.35c0-.457.077-.828.231-1.113.154-.285.408-.427.763-.427.317 0 .555.11.714.329.159.22.238.506.238.861 0 .28-.04.544-.119.791-.08.247-.217.478-.413.693l-.532.602c-.317.355-.541.693-.672 1.015-.13.322-.196.716-.196 1.183v.546h1.736zm.098 2.772V11.56H7.464v1.848h1.932z"/>
    </g></svg>`;
  }
};

function columnFormatter(dtype: string | undefined, editable: boolean) {
  return function customFormatter(cell: any) {
    return `<div style="display: flex; align-items: center; padding: 0 4px">
                ${editable ? getColumnIcon(dtype) : ''}
                <span style="margin-left: 4px; margin-top: 2px; text-overflow: ellipsis; overflow: hidden">${cell.getValue()}</span>
                <div style="flex-grow: 1"></div>
                <div class="tabulator-arrow"></div>
            </div>`;
  };
}

function numberFormatter(
  cell: any,
  formatterParams: {
    toLocale: boolean;
    toFixed: number;
    toPercentage: number;
    textAlign: string;
    showProgress: boolean;
    showCellColor: boolean;
    colors: string[];
    naText: string;
    isHeatmapCol: boolean;
  },
  onRendered: (callback: () => void) => void
) {
  const params =
    formatterParams != undefined
      ? formatterParams
      : {
          toLocale: false,
          toFixed: -1,
          toPercentage: false,
          showProgress: false,
          showCellColor: false,
          colors: [],
          naText: '<NA>',
          isHeatmapCol: false
        };
  // for value
  if (cell.getValue() == undefined) {
    return params.naText;
  }
  const value = cell.getValue() || '0';
  const element = cell.getElement();
  const max = get(params, 'max', 100);
  const min = get(params, 'min', 0);
  const textAlign = get(params, 'textAlign', 'left');
  //make sure value is in range
  let percentValue = parseFloat(value) <= max ? parseFloat(value) : max;
  percentValue =
    parseFloat(percentValue) >= min ? parseFloat(percentValue) : min;

  //workout percentage
  const percent = (max - min) / 100;
  percentValue = Math.round((percentValue - min) / percent);

  //set bar color
  let color = get(params, 'color', '#6495ed');
  switch (_typeof(color)) {
    case 'string':
      break;
    case 'function':
      color = color(value);
      break;
    case 'object':
      if (Array.isArray(color)) {
        const unitValue = 100 / color.length;
        let colorIndex = Math.floor(percentValue / unitValue);

        colorIndex = Math.min(colorIndex, color.length - 1);
        colorIndex = Math.max(colorIndex, 0);
        color = color[colorIndex];
        break;
      }
      break;
  }

  //generate legend
  let legend = get(params, 'legend', params.showProgress);
  switch (_typeof(legend)) {
    case 'string':
      break;
    case 'function':
      legend = legend(value);
      break;
    case 'boolean':
      legend = value;
      break;
  }

  //set legend color
  let legendColor = get(params, 'legendColor', null);
  switch (_typeof(legendColor)) {
    case 'string':
      break;
    case 'function':
      legendColor = legendColor(value);
      break;
    case 'object':
      if (Array.isArray(legendColor)) {
        const unitValue = 100 / legendColor.length;
        let colorIndex = Math.floor(percentValue / unitValue);

        colorIndex = Math.min(colorIndex, legendColor.length - 1);
        colorIndex = Math.max(colorIndex, 0);
        legendColor = legendColor[colorIndex];
      }
      break;
    default:
      legendColor = null;
  }

  let numberValue = parseFloat(value);
  let renderValue = value;
  if (params.toPercentage) {
    numberValue = parseFloat((numberValue * 100.0).toString());
    renderValue = numberValue != undefined ? numberValue.toString() : '';
  }

  if (Number(params.toFixed) >= 0) {
    const fixed = Math.min(100, Number(params.toFixed));
    renderValue = numberValue.toFixed(fixed);
    numberValue = parseFloat(renderValue);
  } else if (numberValue != undefined) {
    numberValue = roundDisplayNumber(numberValue);
    renderValue = String(numberValue);
  }

  if (params.toLocale) {
    renderValue = numberValue.toLocaleString();
  }

  if (params.toPercentage) {
    renderValue += '%';
  }

  if (params.showProgress) {
    renderProgressBar(element, onRendered, {
      percentValue,
      min,
      max,
      color,
      legend: renderValue,
      legendColor,
      legendAlign: textAlign
    });
    return '';
  }

  if (params.showCellColor || params.isHeatmapCol) {
    return renderColorCell(element, onRendered, {
      value: renderValue,
      bgColor: color,
      align: textAlign,
      textColor: legendColor
    });
  }

  element.style.color = legendColor;

  return renderValue;
}

function stringFormatter(
  cell: any,
  formatterParams: {
    legendColor?: string;
    timeFormat?: string;
    dtype?: string;
    naText: string;
    stringCellColor?: StringCellColorOption;
    textAlign: string;
    editable: boolean;
  },
  onRendered: (callback: () => void) => void
) {
  const { legendColor, dtype, timeFormat, stringCellColor, textAlign } =
    formatterParams;
  let value = cell.getValue();
  const element = cell.getElement();
  if (legendColor) {
    element.style.color = legendColor;
  }

  if (value == undefined) {
    value = formatterParams.naText;
  } else if (dtype != undefined && ['date', 'timestamp'].includes(dtype)) {
    if (timeFormat != undefined) {
      value = dayjs(value).format(timeFormat);
    } else {
      value = value.toString().replace(/(\.\d*[1-9])0+$|\.0+$/, '$1');
    }
  } else if (dtype === 'time') {
    if (timeFormat != undefined) {
      value = dayjs(`2021-01-01 ${value}`).format(timeFormat);
    } else {
      value = value.toString().replace(/(\.\d*[1-9])0+$|\.0+$/, '$1');
    }
  } else if (dtype == Dtypes.STRING) {
    value = value.toString().replace(/\n/g, '↵');
  }

  if (stringCellColor?.show) {
    return renderColorCell(element, onRendered, {
      value,
      bgColor: stringCellColor.colors[value],
      align: textAlign,
      textColor: legendColor
    });
  }

  return value;
}

export function convertSummaryColumnToTableColumn(
  summary: { columns: string[]; dtypes: Dtypes[] } | undefined
): TableColumn[] {
  const cols: TableColumn[] = [];

  if (summary === undefined) {
    return cols;
  }

  summary.columns.forEach((col, index) => {
    cols.push({
      value: col,
      label: col,
      dtype: summary.dtypes[index]
    });
  });
  return cols;
}

export function convertDatabaseRowToChartRow(
  rows: { data: Array<string | number | null> }[] | undefined,
  summary: { columns: string[] } | undefined
): Row[] {
  const newRows: Row[] = [];

  if (rows === undefined || summary === undefined) {
    return newRows;
  }

  rows.forEach((row) => {
    const newRow: Row = {};
    row.data.forEach((v, index) => {
      const col = summary.columns[index];
      const newV = v === null ? undefined : v;
      newRow[col] = newV;
    });
    newRows.push(newRow);
  });
  return newRows;
}

function isHeatmapColumn(
  column: TableColumn,
  isHeatmap?: boolean,
  heatmapColValues?: string[]
): boolean {
  return Boolean(
    isHeatmap && heatmapColValues && heatmapColValues.includes(column.value)
  );
}

function getColor(
  col: TableColumn,
  isHeatmap?: boolean,
  heatmapColValues?: string[],
  heatmapPalette?: Palette
): string | string[] | undefined {
  if (isHeatmapColumn(col, isHeatmap, heatmapColValues)) {
    return getColors(
      heatmapPalette || {
        type: SequentialColorType.Orange,
        size: 5
      }
    );
  }
  if (col.showProgress) {
    return col.color || defaultMinBarColor;
  }
  if (col.showCellColor) {
    return getColors(
      col.palette || { type: SequentialColorType.Orange, size: 5 }
    );
  }
  return undefined;
}

// ヒートマップの色をつける値の範囲の最大値
function getColorRangeMax(
  data: Row[],
  col: TableColumn,
  isHeatmapColumn: boolean,
  heatmapColValues?: string[],
  heatmapMax?: number
): number {
  if (isHeatmapColumn) {
    // 「ヒートマップの対象範囲」が設定されていたらそれを使う
    if (heatmapMax != undefined) {
      return heatmapMax;
    }
    // 「ヒートマップの対象範囲」を設定していない場合は、「ヒートマップにする列」で選択したカラム全体のmaxを使う
    if (heatmapColValues) {
      const vs = flatten(
        map(data, (row) => values(pick(row, heatmapColValues)))
      );
      return vs.length > 0 ? roundDisplayNumber(max(vs)) : 100;
    }
  }
  return get(maxBy(data, col.value), col.value, 100);
}

// ヒートマップの色をつける値の範囲の最小値
function getColorRangeMin(
  data: Row[],
  col: TableColumn,
  isHeatmapColumn: boolean,
  heatmapColValues?: string[],
  heatmapMin?: number
): number {
  if (isHeatmapColumn) {
    // 「ヒートマップの対象範囲」が設定されていたらそれを使う
    if (heatmapMin != undefined) {
      return heatmapMin;
    }
    // 「ヒートマップの対象範囲」を設定していない場合は、「ヒートマップにする列」で選択したカラム全体のminを使う
    if (heatmapColValues) {
      const vs = flatten(
        map(data, (row) => values(pick(row, heatmapColValues)))
      );
      return vs.length > 0 ? roundDisplayNumber(min(vs)) : 0;
    }
  }
  return get(minBy(data, col.value), col.value, 0);
}

// 数値を表示する桁に変換
function roundDisplayNumber(num: number): number {
  // return Math.round((num + Number.EPSILON) * 1000000) / 1000000;
  return num;
}

const getReflectCssClass = (reflectType?: ReflectType): string | undefined => {
  return reflectType === ReflectType.Reflected
    ? 'reflected'
    : reflectType === ReflectType.NotReflected
    ? 'not-reflected'
    : undefined;
};

const getSortFormat = (
  dtype: string | undefined,
  toFormat: boolean | undefined,
  format: string | undefined
): string | undefined => {
  if (toFormat) {
    return format || 'YYYY-MM-DD';
  }
  // デフォルト
  switch (dtype) {
    case 'date':
      return 'YYYY-MM-DD';
    case 'timestamp':
      return 'YYYY-MM-DD hh:mm:ss.SSS';
    case 'time':
      return 'hh:mm:ss.SSS';
  }
};

interface Column {
  title: string;
  dtype?: string;
  field: string;
  width?: number;
  align?: string;
  formatter?: any;
  visible?: boolean;
  titleFormatter?: any;
  headerClick?: any;
}

export interface DataTableProps {
  options?: { [key: string]: any };
  data: Row[];
  height: number | string;
  columns: TableColumn[];
  layout: string;
  showDownload?: boolean;
  loading: boolean;
  isSampling?: boolean;
  error?: string;
  onMoveColumn?: (columns: string[]) => void;
  onChangeColumn?: (column: TableColumn) => void;
  onResizeColumn?: (arg: { name: string; width: number }) => void;
  displayColumns?: string[];
  dataStatus?: PortStatus;
  isDataPreview?: boolean;
  naText?: string;
  heatmap?: Heatmap;
  columnValues?: { [colname: string]: string[] };
  // データソースでファイル読み込み時
  onChangeColumnName?: (colname: string, afterColname: string) => void;
  onChangeColumnDtype?: (colname: string, afterDtype: Dtypes) => void;
  editable?: boolean;
  isBonito?: boolean;
  hideSpaceColumn?: boolean;
  beforeExport?: () => void;
}

const useStyles = makeStyles({
  root: {
    fontFamily: ({
      editable
    }: {
      height: number | string;
      editable: boolean;
    }) =>
      `${
        editable ? 'NehanCustom,' : ''
      }Roboto,"Noto Sans JP","Helvetica Neue",Arial,sans-serif`,
    height: ({ height }: { height: number | string; editable: boolean }) =>
      height,
    '&.tabulator': {
      fontSize: '14px',
      marginBottom: 0,
      lineHeight: '2.14'
    },
    '& .tabulator-header': {
      borderBottom: 'none'
    },
    '& .tabulator-col[role=columnheader]': {
      fontWeight: 500,
      height: 40,
      backgroundColor: '#fff',
      '&:hover': {
        color: '#62b0e0', // ヘッダをホバーした時の文字の色
        backgroundColor: '#eef7fc' // ヘッダをホバーした時の背景色
      },
      overflow: 'visible'
    },
    '& .tabulator-row': {
      height: 34,
      display: 'flex',
      alignItems: 'center',
      borderBottom: 'none',
      '&.tabulator-row-even': {
        backgroundColor: '#f2f2f2' // 偶数行の背景色
      },
      '&:hover': {
        backgroundColor: '#e6e6e6' // 行をホバーした時の背景色
      }
    },
    '& .tabulator-col[role=columnheader]:last-child': {
      pointerEvents: 'none'
    },
    '& .tabulator-col[role=columnheader] > .tabulator-col-content': {
      border: 'solid 1px #ccc', // ヘッダの枠の色
      padding: 0,
      margin: '3px',
      cursor: 'grab',
      '&:hover': {
        borderColor: '#62b0e0' // ヘッダをホバーしたときの枠の色
      }
    },
    '& .tabulator-col[role=columnheader]:last-child > .tabulator-col-content': {
      border: 'none'
    },
    '& .tabulator-col[aria-sort=none][role=columnheader] .tabulator-col-content .tabulator-arrow':
      {
        display: 'none'
      },
    '& .tabulator-col[aria-sort=desc][role=columnheader] .tabulator-col-content .tabulator-arrow':
      {
        position: 'static',
        marginTop: '8px',
        display: 'inline-block',
        width: '9px',
        minWidth: 9,
        height: '9px',
        borderBottom: 'solid 2px #aaa', // ソートの向きを表す矢印の色
        borderRight: 'solid 2px #aaa', // ソートの向きを表す矢印の色
        borderLeft: 'none',
        borderTop: 'none',
        transform: 'rotate(45deg)'
      },
    '& .tabulator-col[aria-sort=asc][role=columnheader] .tabulator-col-content .tabulator-arrow':
      {
        position: 'static',
        marginTop: '17px',
        display: 'inline-block',
        minWidth: 9,
        width: '9px',
        height: '9px',
        borderBottom: 'solid 2px #aaa', // ソートの向きを表す矢印の色
        borderRight: 'solid 2px #aaa', // ソートの向きを表す矢印の色
        borderLeft: 'none',
        borderTop: 'none',
        transform: 'rotate(-135deg)'
      },
    '& .tabulator-col.reflected[role=columnheader] .tabulator-col-content': {
      border: `solid 2px ${NodeColor[NodeStatus.Executed].dark}`,
      backgroundColor: NodeColor[NodeStatus.Executed].light
    },
    '& .tabulator-col.not-reflected[role=columnheader] .tabulator-col-content':
      {
        border: `solid 2px ${NodeColor[NodeStatus.Warning].dark}`,
        backgroundColor: NodeColor[NodeStatus.Warning].light
      },
    '& .tabulator-cell': {
      paddingTop: '3px!important',
      paddingBottom: '3px!important',
      height: 32,
      verticalAlign: 'middle',
      cursor: 'default',
      whiteSpace: 'pre'
    },
    '& .tabulator-row .tabulator-cell:last-child .tabulator-col-resize-handle':
      {
        pointerEvents: 'none'
      },
    '& .tabulator-row .tabulator-cell:last-of-type': {
      fontFamily: 'auto'
    },
    '& .tabulator-row .tabulator-cell[tabulator-field="__nehan_end_spacer"]:last-of-type':
      {
        height: '34px !important',
        backgroundColor: '#fff'
      },
    '& .tabulator-col-resize-handle': {
      width: 3,
      right: -3
    },
    '& .tabulator-col-resize-handle.prev': {
      left: -3,
      right: 'auto'
    },
    '& .tabulator-col-resize-handle:hover': {
      backgroundColor: '#51a8dd', // カラムの間をホバーしたときの色
      width: 6,
      zIndex: 10
    },
    '& .tabulator-footer .tabulator-row': {
      display: 'block'
    }
  },
  exportIcon: {
    position: 'absolute',
    right: 10,
    zIndex: 2,
    fontSize: '1.2rem',
    border: '1px solid rgb(192, 192, 192)',
    borderRadius: '3px',
    padding: '2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  exportIconBonito: {
    position: 'absolute',
    left: 2,
    zIndex: 2,
    fontSize: '1.2rem',
    border: '1px solid rgb(192, 192, 192)',
    borderRadius: '3px',
    padding: '2px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  inChart: {
    top: -35,
    right: 10
  },
  inCard: {
    top: -25,
    right: 15
  },
  paper: {
    border: '1px solid',
    paddingRight: '2px',
    paddingLeft: '2px',
    backgroundColor: '#fff'
  }
});

const useSpinnerStyles = makeStyles({
  content: (props: { height: number | string }) => ({
    height: props.height,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  })
});

const isVisibleColumn = (
  col: string,
  displayColumns: string[] | undefined
): boolean => {
  return displayColumns === undefined ? true : displayColumns.includes(col);
};

function getSorter(dtype: Dtypes | string | undefined) {
  if (!dtype) {
    return 'string';
  }
  switch (dtype) {
    case Dtypes.NUMBER:
    case Dtypes.TIMEDELTA:
      return 'number';
    case Dtypes.BOOLEAN:
      return 'boolean';
    case Dtypes.DATE:
      return 'date';
    case Dtypes.TIMESTAMP:
      return timestampSorter;
    case Dtypes.TIME:
      return 'time';
    case Dtypes.STRING:
    case Dtypes.PARTITION:
    case Dtypes.ARRAY:
    case Dtypes.JSON:
    case Dtypes.RECORD:
      return 'string';
  }
  return 'string';
}

function timestampSorter(a: string, b: string): number {
  const da = dayjs(a);
  const db = dayjs(b);
  return da.valueOf() - db.valueOf();
}

export const DataTable: React.FC<DataTableProps> = ({
  options,
  height,
  data,
  columns,
  layout,
  showDownload,
  loading,
  error,
  displayColumns,
  dataStatus,
  onMoveColumn,
  onChangeColumn,
  onResizeColumn,
  isDataPreview,
  naText = '<NA>',
  heatmap,
  columnValues,
  onChangeColumnName,
  onChangeColumnDtype,
  editable: _editable, // ダッシュボードを編集可能かどうか
  isBonito,
  hideSpaceColumn,
  beforeExport
}) => {
  const ref = React.useRef(null);
  const editable = _editable == null ? true : _editable;
  const [column, setColumn] = React.useState<TableColumn>();
  const [focusScrollColumn, setFocusScrollColumn] = React.useState<
    string | null
  >(null);
  const SortDirection = {
    Asc: 'asc',
    Desc: 'desc'
  } as const;
  type SortDirection = (typeof SortDirection)[keyof typeof SortDirection];
  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const [selectedColumn, setSelectedColumn] = React.useState<string | null>(
    null
  );
  const [sortedColumn, setSortedColumn] = React.useState<string | null>(null);
  const [sortDirection, setSortDirection] =
    React.useState<SortDirection | null>(null);
  const [selectedTableColumn, setSelectedTableColumn] =
    React.useState<TableColumn | null>(null);
  const [cellValue, setCellValue] = React.useState<string>();
  const [tableCellTooltipAnchorEl, setTableCellTooltipAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const [scrollLeft, setScrollLeft] = React.useState(0);
  const onOpenTableCellTooltip = React.useCallback((event, cell) => {
    const element = cell.getElement();
    if (element.role === 'columnheader') {
      const span = element.querySelector('span');
      if (span && span.clientWidth < span.scrollWidth) {
        setTableCellTooltipAnchorEl(span);
        setCellValue(span.innerText);
      }
    } else {
      const handleElement = element.querySelector(
        '.tabulator-col-resize-handle:hover'
      );
      // カラムとカラムの間にマウスポインタがある場合、2px分広がったと考える
      // 恐らく、tabulator-col-resize-handleのCSSで「right: '-2px'」を指定していることが原因
      const clientWidth = element.clientWidth + (handleElement ? 2 : 0);
      if (clientWidth < element.scrollWidth) {
        setTableCellTooltipAnchorEl(event.currentTarget);
        setCellValue(element.innerText);
      }
    }
  }, []);
  const onCloseTableCellTooltip = React.useCallback(() => {
    setTableCellTooltipAnchorEl(null);
  }, []);
  const onCloseHeaderMenu = () => setMenuAnchorEl(null);
  const onClickHeader = React.useCallback(
    (
      event: React.MouseEvent<HTMLButtonElement>,
      column,
      tableColumn: TableColumn | undefined
    ) => {
      const selectedColumnLabel =
        columns.find((c) => c.value === column.getField())?.label ?? null; // 列のラベルを入れる。そうしないと列名変更で未変更の値が入ってしまう。
      setSelectedColumn(selectedColumnLabel);
      setMenuAnchorEl(event.currentTarget);
      if (tableColumn) {
        setSelectedTableColumn(tableColumn);
      }

      if (ref.current != null) {
        const table = (ref.current! as { table: any }).table;
        if (table != undefined) {
          setScrollLeft(table.columnManager.element.scrollLeft);
        }
      }
    },
    [columns, ref]
  );
  const onClickHeaderMenu = React.useCallback(
    (dir: SortDirection) => {
      if (selectedColumn !== null) {
        const table = (ref.current! as { table: any }).table!;
        if (selectedColumn === sortedColumn && sortDirection === dir) {
          setSortedColumn(null);
          setSortDirection(null);
          table.setSort([]);
          table.rowManager.sorterRefresh(true);
        } else {
          setSortedColumn(selectedColumn);
          setSortDirection(dir);
          table.setSort(selectedColumn, dir);
        }
      }
      onCloseHeaderMenu();
    },
    [selectedColumn, sortDirection, sortedColumn]
  );
  const onClickCopyColumnName = React.useCallback(() => {
    if (selectedColumn == null) {
      return;
    }
    navigator.clipboard.writeText(selectedColumn);
    onCloseHeaderMenu();
  }, [selectedColumn]);

  const heatmapColValues: string[] = React.useMemo(() => {
    if (heatmap == undefined) {
      return [];
    }
    const numberCols = columns.filter((col) => col.dtype === Dtypes.NUMBER);
    const [matchColumns] = selectColumnsByRule(
      Object.values(numberCols).map((c) => c.label),
      Object.values(numberCols).map((c) => c.dtype as Dtypes),
      heatmap.columnSelectRules
    );
    const matchColumnLabels = matchColumns.map((col) => col.name);
    return columns
      .filter((c) => matchColumnLabels.includes(c.label))
      .map((c) => c.value);
  }, [heatmap?.columnSelectRules, columns]);

  const addColumnIcon = React.useMemo(() => {
    const addedColumns: Column[] = columns.map((col) => {
      const iconFormatter = columnFormatter(
        col.dtypeLabel ?? col.dtype,
        editable
      );
      const reflectCssClass = getReflectCssClass(col.reflectType);
      if (col.dtype === Dtypes.NUMBER) {
        const isHeatmapCol = isHeatmapColumn(
          col,
          heatmap?.show,
          heatmapColValues
        );
        const minV = getColorRangeMin(
          data,
          col,
          isHeatmapCol,
          heatmapColValues,
          heatmap?.range.min
        );
        const maxV = getColorRangeMax(
          data,
          col,
          isHeatmapCol,
          heatmapColValues,
          heatmap?.range.max
        );
        const formatterParams = {
          showProgress: col.showProgress,
          showCellColor: col.showCellColor,
          min: minV,
          max: maxV,
          legendColor: col.textColor,
          toLocale: col.toLocale,
          toFixed: col.toFixed,
          toPercentage: col.toPercentage,
          textAlign: col.align || 'right',
          color: getColor(
            col,
            heatmap?.show,
            heatmapColValues,
            heatmap?.palette
          ),
          naText,
          isHeatmapCol: isHeatmapCol
        };
        return {
          title: col.label,
          field: col.value,
          titleFormatter: iconFormatter,
          hozAlign: col.align || 'right',
          width: col.width,
          formatter: numberFormatter,
          formatterParams,
          sorter: getSorter(col.dtype),
          sorterParams: {
            format: getSortFormat(col.dtype, col.toFormat, col.timeFormat)
          },
          visible: isVisibleColumn(col.value, displayColumns),
          headerClick: (event: React.MouseEvent<HTMLButtonElement>, column) =>
            onClickHeader(event, column, onChangeColumn ? col : undefined),
          cellMouseOver: (event, cell) => onOpenTableCellTooltip(event, cell),
          cellMouseOut: () => onCloseTableCellTooltip(),
          cssClass: reflectCssClass,
          bottomCalc:
            col.showBottomAggregate && col.bottomAggregateFunc
              ? col.bottomAggregateFunc.toLowerCase()
              : undefined,
          bottomCalcFormatter: numberFormatter,
          bottomCalcFormatterParams: {
            ...formatterParams,
            showProgress: false,
            showCellColor: false,
            isHeatmapCol: false
          }
        };
      }
      return {
        title: col.label,
        field: col.value,
        hozAlign: col.align,
        width: col.width,
        formatter: stringFormatter,
        formatterParams: {
          legendColor: col.textColor,
          stringCellColor: col.stringCellColor,
          textAlign: col.align || 'right',
          timeFormat: col.toFormat ? col.timeFormat || 'YYYY-MM-DD' : undefined,
          dtype: col.dtype,
          naText
        },
        titleFormatter: iconFormatter,
        sorter: getSorter(col.dtype),
        sorterParams: {
          format: getSortFormat(col.dtype, col.toFormat, col.timeFormat)
        },
        visible: isVisibleColumn(col.value, displayColumns),
        headerClick: (event: React.MouseEvent<HTMLButtonElement>, column) =>
          onClickHeader(event, column, onChangeColumn ? col : undefined),
        cellMouseOver: (event, cell) => onOpenTableCellTooltip(event, cell),
        cellMouseOut: () => onCloseTableCellTooltip(),
        cssClass: reflectCssClass
      };
    });
    if (columns.length > 0) {
      addedColumns.push({
        title: '',
        field: SPACER_COL_NAME,
        width: hideSpaceColumn ? 0 : 100,
        visible: displayColumns == undefined || displayColumns.length > 0
      });
    }
    return addedColumns;
  }, [
    data,
    columns,
    onChangeColumn,
    onClickHeader,
    heatmap,
    heatmapColValues,
    hideSpaceColumn
  ]);

  React.useEffect(() => {
    if (ref.current == null) {
      return;
    }
    const table = (ref.current! as { table: any }).table;
    if (table == undefined) {
      return;
    }

    if (menuAnchorEl == null) {
      // 列名型を変更すると横スクロールがリセットされるので、メニューを閉じたら列の位置までスクロール
      table.columnManager.element.scrollLeft = scrollLeft;
      table.rowManager.scrollLeft = scrollLeft;
    }
  }, [ref, menuAnchorEl]);

  React.useEffect(() => {
    if (ref.current == null) {
      return;
    }
    if (displayColumns == undefined) {
      return;
    }
    const table = (ref.current! as { table: any }).table;
    if (table == undefined) {
      return;
    }
    const columns = table.getColumns();
    columns.forEach((col) => {
      if (col.getField() === SPACER_COL_NAME) {
        if (displayColumns.length > 0) {
          col.show();
        } else {
          col.hide();
        }
        return;
      }
      if (displayColumns.includes(col.getField())) {
        col.show();
      } else {
        col.hide();
      }
    });
  }, [displayColumns]);

  const onResizeColumnWrapper = React.useCallback(
    (column: any) => {
      const name = column.getField();
      const width = column.getElement().offsetWidth;
      if (onResizeColumn != undefined) {
        onResizeColumn({ name, width });
        setFocusScrollColumn(name);
      }
    },
    [onResizeColumn, setFocusScrollColumn]
  );

  const onColumnMoved = React.useCallback(
    (column: any, columns: any[]) => {
      if (typeof onMoveColumn === 'function') {
        onMoveColumn(
          columns.map((col) => {
            return col.getField();
          })
        );
        setFocusScrollColumn(column.getField());
      }
    },
    [onMoveColumn, setFocusScrollColumn]
  );

  const onChange = React.useCallback(() => {
    if (onChangeColumn == undefined || column == undefined) {
      return;
    }

    onChangeColumn(column);
    setColumn(undefined);
    const name = column.value;
    setFocusScrollColumn(name);
  }, [column, setFocusScrollColumn, onChangeColumn]);

  React.useEffect(() => {
    if (ref == undefined || ref.current == undefined) {
      return;
    }
    const table = (ref.current! as { table: any }).table!;
    if (table == undefined) {
      return;
    }
    table.setSort(
      columns
        .slice()
        .reverse()
        .filter((col) => col.dir != undefined)
        .map((col) => ({
          column: col.value,
          dir: col.dir
        }))
    );
    table.rowManager.sorterRefresh(true);
  }, [columns, ref, addColumnIcon]);

  const downloadCSV = React.useCallback(async () => {
    if (ref == undefined || ref.current == undefined) {
      return;
    }

    // @ts-ignore
    if (!window.XLSX) {
      // @ts-ignore
      window.XLSX = await import('xlsx');
    }

    beforeExport?.();

    (ref.current! as { table: any }).table!.download(
      'xlsx',
      'dashboard_export.xlsx',
      { sheetName: 'data' }
    );
  }, [ref, beforeExport]);

  React.useEffect(() => {
    if (ref == undefined || ref.current == undefined) {
      return;
    }
    const table = (ref.current! as { table: any }).table;
    if (table == undefined) {
      return;
    }
    table.setHeight(height);
    table.redraw();
  }, [ref, height]);

  React.useEffect(() => {
    if (focusScrollColumn) {
      if (ref != undefined && ref.current != undefined) {
        const table = (ref.current! as { table: any }).table;
        if (table == undefined) {
          return;
        }

        table.scrollToColumn(focusScrollColumn, 'center', true);
        setFocusScrollColumn(null);
      }
    }
  }, [focusScrollColumn, ref]);

  const tabulatorOptions = React.useMemo(
    () => ({
      ...options,
      nestedFieldSeparator: '',
      invalidOptionWarnings: false,
      height: height,
      movableColumns: isDataPreview ? false : true,
      columnMoved: onColumnMoved,
      downloadReady: (_, blob) => blob,
      columnResized: onResizeColumnWrapper,
      initialSort: columns
        .slice()
        .reverse()
        .filter((col) => col.dir != undefined)
        .map((col) => ({
          column: col.value,
          dir: col.dir
        })),
      headerSort: false,
      layout: layout,
      resizableColumns: 'header',
      virtualDomBuffer: 30 // バッファを小さくすると頻繁に描画するようになる
    }),
    [
      options,
      height,
      isDataPreview,
      onColumnMoved,
      onResizeColumnWrapper,
      columns,
      layout
    ]
  );

  const classes = useStyles({ height, editable });
  const spinnerClasses = useSpinnerStyles({ height: '90%' });

  if (error) {
    return (
      <AlertMessage
        severity="error"
        message={'データの読み込みに失敗しました'}
        detailMessage={error}
      />
    );
  }

  if (!columns.every((col) => !col.isError)) {
    return (
      <AlertMessage
        severity="error"
        message={'存在しない列が選択されています'}
      />
    );
  }

  if (loading || dataStatus === PortStatus.Loading) {
    return <SpinnerScreen classes={spinnerClasses} text="データをロード中" />;
  }

  if (dataStatus && dataStatus !== PortStatus.Loaded) {
    return <></>;
  }

  return (
    <>
      <div
        style={{
          position: 'relative',
          height: '100%',
          backgroundColor: '#fff'
        }}
        data-cy={'datatable'}
      >
        {showDownload && (
          <Tooltip title="Export As XLSX" placement="bottom-end">
            <MenuIcon
              onClick={downloadCSV}
              className={clsx(
                isBonito ? classes.exportIconBonito : classes.exportIcon,
                onChangeColumn ? classes.inChart : classes.inCard
              )}
              fontSize="small"
              htmlColor="rgb(154, 154, 154)"
            />
          </Tooltip>
        )}
        <ReactTabulator
          className={classes.root}
          ref={ref}
          data={data}
          options={tabulatorOptions}
          columns={addColumnIcon}
        />
        <BottomAggregateTooltip
          tableRef={ref}
          columns={columns}
          setTableCellTooltipAnchorEl={setTableCellTooltipAnchorEl}
          setCellValue={setCellValue}
        />
      </div>
      {column != undefined && (
        <Dialog
          open={column != undefined}
          onClose={() => setColumn(undefined)}
          title={`${column.label}の設定`}
          contentProps={{ style: { minWidth: 400, padding: 12 } }}
          OKButton={{
            label: '決定',
            onClick: onChange
          }}
        >
          <>
            <div>
              <RowAlign align={column.align} setColumn={setColumn} />
              <RowDir dir={column.dir} setColumn={setColumn} />
              <RowSetTextColor
                setTextColor={column.setTextColor}
                textColorPalette={column.textColorPalette}
                textColor={column.textColor}
                setColumn={setColumn}
              />
              {column.dtype != undefined &&
                ['date', 'timestamp', 'time'].includes(column.dtype) && (
                  <RowToFormat
                    toFormat={column.toFormat}
                    timeFormatType={column.timeFormatType}
                    timeFormat={column.timeFormat}
                    dtype={column.dtype}
                    setColumn={setColumn}
                  />
                )}
              {column.dtype === 'number' && (
                <>
                  <RowToLocale
                    toLocale={column.toLocale}
                    setColumn={setColumn}
                  />
                  <RowToFixed toFixed={column.toFixed} setColumn={setColumn} />
                  <RowToPercentage
                    toPercentage={column.toPercentage}
                    setColumn={setColumn}
                  />
                  {!isHeatmapColumn(
                    column,
                    heatmap?.show,
                    heatmapColValues
                  ) && (
                    <>
                      <RowShowProgress
                        showProgress={column.showProgress}
                        progressColorPalette={column.progressColorPalette}
                        color={column.color}
                        setColumn={setColumn}
                      />
                      <RowShowCellColor
                        showCellColor={column.showCellColor}
                        palette={column.palette}
                        setColumn={setColumn}
                      />
                    </>
                  )}
                  <RowBottomAggregateFunc
                    showBottomAggregate={column.showBottomAggregate}
                    bottomAggregateFunc={column.bottomAggregateFunc}
                    setColumn={setColumn}
                  />
                </>
              )}
              {column.dtype === Dtypes.STRING &&
                columnValues &&
                columnValues[column.value] && (
                  <RowStringCellColor
                    option={column.stringCellColor}
                    columnValues={columnValues[column.value]}
                    setColumn={setColumn}
                  />
                )}
            </div>
          </>
        </Dialog>
      )}
      <Menu
        id="simple-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={onCloseHeaderMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 0, horizontal: 'center' }}
        getContentAnchorEl={null}
      >
        {!onChangeColumn && [
          <MenuItem
            key="asc"
            onClick={() => onClickHeaderMenu(SortDirection.Asc)}
          >
            <ListItemIcon>
              <ArrowUpward fontSize="small" />
            </ListItemIcon>
            昇順
            {selectedColumn === sortedColumn &&
              sortDirection === SortDirection.Asc && <Check />}
          </MenuItem>,
          <MenuItem
            key="desc"
            onClick={() => onClickHeaderMenu(SortDirection.Desc)}
          >
            <ListItemIcon>
              <ArrowDownward fontSize="small" />
            </ListItemIcon>
            降順
            {selectedColumn === sortedColumn &&
              sortDirection === SortDirection.Desc && <Check />}
          </MenuItem>
        ]}
        {selectedColumn && onChangeColumnName && (
          <MenuItemNameChange
            value={selectedColumn}
            onChange={(name: string) => {
              setMenuAnchorEl(null);
              onChangeColumnName(selectedColumn, name);
            }}
          >
            <ListItemIcon />
            列名変更をセット
          </MenuItemNameChange>
        )}
        {selectedColumn && onChangeColumnDtype && (
          <MenuItemDtypeChange
            onChange={(dtype: Dtypes) => {
              setMenuAnchorEl(null);
              onChangeColumnDtype(selectedColumn, dtype);
            }}
          >
            <ListItemIcon />
            型変更をセット
          </MenuItemDtypeChange>
        )}
        {onChangeColumn && selectedTableColumn && (
          <MenuItem
            onClick={() => {
              onCloseHeaderMenu();
              setColumn(selectedTableColumn);
            }}
          >
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            設定
          </MenuItem>
        )}
        <MenuItem onClick={onClickCopyColumnName}>
          <ListItemIcon>
            <FileCopy fontSize="small" />
          </ListItemIcon>
          列名をコピーする
        </MenuItem>
      </Menu>
      <Popper
        id={'cell-tooltip'}
        open={Boolean(tableCellTooltipAnchorEl)}
        anchorEl={tableCellTooltipAnchorEl}
        keepMounted={true}
        style={{
          zIndex: 1300,
          maxWidth: '80%',
          whiteSpace: 'pre-wrap'
        }} // 適切な値は判らないが、Menuのz-indexが1300なので同じ値にしておく
      >
        <div className={classes.paper}>{cellValue}</div>
      </Popper>
    </>
  );
};
