import * as React from 'react';
import SelectField from 'components/form/selectField';
import { SelectFieldSchema } from 'models/form/schema';
import { FieldTypes, SelectFieldValueElement } from 'models/form/schema';
import { SwitchBetweenOperators } from 'models/form/value';

export const SelectBetweenOperator: React.FC<{
  value: SwitchBetweenOperators | null;
  onChange: (value: SwitchBetweenOperators) => void;
  index: number;
}> = ({ value, onChange, index }) => {
  const between_operator = BetweenOperator;
  const handleChange = React.useCallback(
    (_, val: any) => onChange(val as SwitchBetweenOperators),
    [onChange]
  );

  //const val: SelectFieldValueElement = between_operator.filter((v) => {
  //  v.value === value;
  //})[0];

  const schema: SelectFieldSchema = {
    key: String(index),
    type: FieldTypes.select,
    items: between_operator as SelectFieldValueElement[],
    multi: false,
    allDtypes: [],
    fullWidth: true,
    variant: 'outlined',
    placeholder: '不等号選択'
  };
  return (
    <div>
      <SelectField
        clearable={false}
        value={value as any}
        //errors={errors}
        schema={schema}
        onChangeField={handleChange}
        detailedHelpTooltip={null}
      />
    </div>
  );
};

export const BetweenOperator = [
  {
    value: SwitchBetweenOperators.left_shift,
    label: '始 < 値 < 終'
  },
  {
    value: SwitchBetweenOperators.right_shift_assign,
    label: '始 ≦ 値 < 終'
  },
  {
    value: SwitchBetweenOperators.left_shift_assign,
    label: '始 < 値 ≦ 終'
  },
  {
    value: SwitchBetweenOperators.both_shift_assign,
    label: '始 ≦ 値 ≦ 終'
  }
];
