import { initialDateRange, isDateRange } from 'ui/listView/filter/customDate';
import { FilterAction, FilterState, FilterType } from 'ui/listView/filter/type';
import {
  initFilterState,
  isInitialFilterState
} from 'ui/listView/filter/initialValue';
import { ListItemType } from 'models/dependency';
import { DateRangeValues } from 'models/report/parameter';

export type FilterDispatch = (FilterState, FilterAction) => void;

const convertBoolean = (value): boolean => {
  return typeof value === 'boolean' ? value : false;
};

const convertDateRange = (value): DateRangeValues => {
  return isDateRange(value) ? value : initialDateRange;
};

const convertString = (value): string => {
  return typeof value === 'string' ? value : '';
};

const convertStrings = (value): string[] => {
  return isStringArray(value) ? value : [];
};
const convertTableElement = (value): HTMLTableElement | null => {
  return isHTMLTableElement(value) ? value : null;
};

const calendarPositionMap = {
  [FilterType.CreateRange]: FilterType.CalendarPositionForCreation,
  [FilterType.UpdateRange]: FilterType.CalendarPositionForUpdate,
  [FilterType.ExecutionStartRange]:
    FilterType.CalendarPositionForExecutionStart,
  [FilterType.ExecutionEndRange]: FilterType.CalendarPositionForExecutionEnd
};

export const filterReducer = (
  state: FilterState,
  action: FilterAction
): FilterState => {
  switch (action.type) {
    // String Array Types
    case FilterType.AccessLevels:
    case FilterType.Creators:
    case FilterType.DatasourceStatuses:
    case FilterType.DatasourceTypes:
    case FilterType.DataTypes:
    case FilterType.ExportStatuses:
    case FilterType.UserTypes:
    case FilterType.UserGroups:
    case FilterType.NotificationDstTypes: {
      const newState: FilterState = {
        ...state,
        [action.type]: convertStrings(action.payload)
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    // String Types
    case FilterType.UserEmail:
    case FilterType.UserGroupName:
    case FilterType.ScheduleName:
    case FilterType.BuilderName:
    case FilterType.ConnectionName:
    case FilterType.NotificationName:
    case FilterType.NotificationDstName:
    case FilterType.CreateCondition:
    case FilterType.DatasourceName:
    case FilterType.ExecutionStartCondition:
    case FilterType.ExecutionEndCondition:
    case FilterType.ProjectName:
    case FilterType.ReportName:
    case FilterType.VariableName:
    case FilterType.OrganizationName:
    case FilterType.UpdateCondition: {
      const newState: FilterState = {
        ...state,
        [action.type]: convertString(action.payload)
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    // Bool Types
    case FilterType.UserPasswordGenerated:
    case FilterType.HasIPAddress:
    case FilterType.IsScheduled:
    case FilterType.ExcludeFolders:
    case FilterType.LimitedAccess:
    case FilterType.PublicURLExists: {
      const newState = {
        ...state,
        [action.type]: convertBoolean(action.payload)
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    // Calendar
    case FilterType.CalendarPositionForCreation: {
      const newState = {
        ...state,
        [FilterType.CreateCondition]: 'calendar',
        [FilterType.CalendarPositionForCreation]: convertTableElement(
          action.payload
        )
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    case FilterType.CalendarPositionForExecutionEnd: {
      const newState = {
        ...state,
        [FilterType.ExecutionEndCondition]: 'calendar',
        [FilterType.CalendarPositionForExecutionEnd]: convertTableElement(
          action.payload
        )
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    case FilterType.CalendarPositionForExecutionStart: {
      const newState = {
        ...state,
        [FilterType.ExecutionStartCondition]: 'calendar',
        [FilterType.CalendarPositionForExecutionStart]: convertTableElement(
          action.payload
        )
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    case FilterType.CalendarPositionForUpdate: {
      const newState = {
        ...state,
        [FilterType.UpdateCondition]: 'calendar',
        [FilterType.CalendarPositionForUpdate]: convertTableElement(
          action.payload
        )
      };
      return { ...newState, filtering: !isInitialFilterState(newState) };
    }
    case FilterType.ExecutionEndRange:
    case FilterType.ExecutionStartRange:
    case FilterType.CreateRange:
    case FilterType.UpdateRange:
      // カレンダーダイアログの「OK」を押した時のアクション
      // - stateの更新
      // - ダイアログを閉じるためにnullをセットする
      return {
        ...state,
        [action.type]: convertDateRange(action.payload),
        [calendarPositionMap[action.type]]: null
      };
    case FilterType.Clear:
      return initFilterState(action.payload as ListItemType);
    default:
      return { ...state };
  }
};

// 判定がかなり適当なので、問題がある場合は条件を追加する
const isHTMLTableElement = (value): value is HTMLTableElement => {
  return (
    value !== null &&
    typeof value === 'object' &&
    typeof value.align === 'string'
  );
};

const isStringArray = (value): value is string[] => {
  if (!Array.isArray(value)) {
    return false;
  }

  if (value.some((v) => typeof v !== 'string')) {
    return false;
  }

  return true;
};
