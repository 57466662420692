import { OrganizationPayment, OrganizationUser, User } from '../../models/user';
import { AccessLevel, UserRole } from 'libs/accessLevel';
import { _delete, _get, _post, _put } from 'libs/api/base';
import { UserGroup } from 'models/user_group';
import { QueryParams } from 'ui/listViewBase';

export function getUser(login?: boolean) {
  return _get<User>('/user', { params: { login } });
}

export function getOrganizationUsers() {
  return _get<OrganizationUser[]>(`/organization_users`);
}

export function putOrganizationUser(
  organization: string,
  email: string,
  role: UserRole,
  generate_password: boolean
) {
  return _put(`/organizations/${organization}/users`, {
    email,
    role,
    generate_password
  });
}

export function resetOrganizationUserPassword(
  organization: string,
  email: string
) {
  return _post(`/organizations/${organization}/users/reset_password`, {
    email
  });
}

export function deleteOrganizationUserPassword(
  organization: string,
  email: string
) {
  return _delete(`/organizations/${organization}/users/password`, { email });
}

export function deleteOrganizationUser(organization: string, email: string) {
  return _delete(`/organizations/${organization}/users`, { email });
}

export function updateUser(params: { last_name: string; first_name: string }) {
  return _put('/user', params);
}

export async function addMembers(
  resourceType: string,
  resourceId: string,
  id: string,
  is_group: boolean,
  accessLevel: AccessLevel
) {
  return _post(`/members`, {
    resource_type: resourceType,
    resource_uid: String(resourceId),
    id,
    is_group,
    access_level: accessLevel
  });
}

export async function updateMembers(
  resourceType: string,
  resourceId: string,
  id: string,
  is_group: boolean,
  accessLevel: AccessLevel
) {
  return _put(`/members`, {
    resource_type: resourceType,
    resource_uid: String(resourceId),
    id,
    is_group,
    access_level: accessLevel
  });
}

export async function getSSOConfig(namespace: string) {
  return _get<{
    tenant_id: string;
    provider_name: 'saml' | 'oidc';
    provider_id: string;
  }>('/login/sso', { params: { namespace } });
}

export async function resetPasswordByUser(email: string) {
  return _post('/users/reset_password', { email });
}

export function getAdminOrganizationUsers(queryParams?: QueryParams) {
  return _get<OrganizationUser[]>(`/admin/users`, { params: queryParams });
}

export async function getUserGroups() {
  return _get<UserGroup[]>('/user_groups');
}

export async function getAdminUserGroups(queryParams?: QueryParams) {
  return _get(`/admin/user_groups`, { params: { ...queryParams } });
}

export async function addUserToUserGroup(
  org: string,
  userId: string,
  groupIds: string[]
) {
  return _post(`/organizations/${org}/users/${userId}/user_group`, {
    user_id: userId,
    group_ids: groupIds
  });
}

export async function addUserGroup(group: UserGroup) {
  return _post(`/user_groups`, group);
}

export async function putUserGroup(group: UserGroup) {
  return _put(`/user_groups/${group.id}`, group);
}

export async function deleteUserGroup(groupId: string) {
  return _delete(`/user_groups/${groupId}`);
}

export async function getOrganizationPayment() {
  return _get<OrganizationPayment>(`/admin/payment`);
}

export async function putOrganizationPayment(body: OrganizationPayment) {
  return _put<OrganizationPayment>(`/admin/payment`, body);
}
