import * as React from 'react';

import {
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core';
import { produce } from 'immer';
import {
  FormValue,
  SwitchAndCondition,
  SwitchAndConditions,
  SwitchBetweenOperators,
  SwitchCondition,
  SwitchConditions,
  SwitchDatetimeElement,
  SwitchOperators,
  SwitchSelectColumn,
  SwitchWeekdayList
} from 'models/form/value';
import { Dtypes, LabelToDtype } from 'Utils/dataTypes';
import { AddCircle, MoreHoriz } from '@material-ui/icons';
import { Variable } from 'models/project';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DataSummary } from 'models/data';
import TextField from 'components/ui/common/variableTextfield';
import {
  FieldTypes,
  SwitchFieldSchema,
  TextFieldSchema
} from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';
import DataTable from 'components/dataTable/table';
import { DataMap, PortStatus } from 'models/graph';
import { ColumnSelectValueSetter } from 'components/form/switchFieldComponent/columnSelectValueSetter';
import { UseColumnCondition } from 'components/form/switchFieldComponent/useColumnCondition';
import { NumberField } from 'components/form/switchFieldComponent/numberField';
import { StringField } from 'components/form/switchFieldComponent/stringField';
import { DateField } from 'components/form/switchFieldComponent/dateField';
import { DatetimeField } from 'components/form/switchFieldComponent/datetimeField';
import { TimeField } from 'components/form/switchFieldComponent/timeField';
import { validate_columns } from 'components/form/switchFieldComponent/utils';
import { TimeOperator } from 'components/form/switchFieldComponent/selectOperator';
import { Dialog } from 'components/ui/common/dialog';

interface SwitchFieldProps {
  value: SwitchConditions | null;
  schema: SwitchFieldSchema;
  columns: DataSummary['columns'];
  dtypes: DataSummary['dtypes'];
  variables: Variable[];
  errors: FieldValidationError;
  handleOnChange: ChangeFieldHandler;
  projectId?: string;
  portId?: string;
  dataStatus: PortStatus;
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}

export interface ColumnOptionsV2Names {
  [originName: string]: string; // valueには変更後の値が入る
}

interface SwitchFieldDialogProps {
  open: boolean;
  value: SwitchConditions;
  schema: SwitchFieldSchema;
  columns: string[];
  dtypes: Dtypes[];
  onClose: () => void;
  variables: Variable[];
  errors: FieldValidationError;
  onChange: (val: SwitchConditions) => void;
  projectId?: string;
  portId?: string;
  dataStatus: PortStatus;
  colnameMap?: ColumnOptionsV2Names;
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}

const fieldStyles = makeStyles(() => ({
  div: {
    overflowY: 'auto',
    paddingTop: '10px'
  },
  text: {
    whiteSpace: 'pre'
  }
}));

export const SwitchField: React.FC<SwitchFieldProps> = ({
  columns,
  dtypes,
  variables,
  schema,
  value,
  errors,
  handleOnChange,
  projectId,
  portId,
  dataStatus,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = fieldStyles();
  const [open, setOpen] = React.useState(false);
  const onOpen = React.useCallback(() => {
    setOpen(true);
  }, []);
  const onClose = React.useCallback(() => {
    setOpen(false);
  }, []);

  const onChange = React.useCallback(
    (rules: SwitchConditions) => {
      handleOnChange(schema.key, rules, []);
    },
    [schema, handleOnChange]
  );

  React.useEffect(() => {
    if (!value) {
      onChange(initializeConditions(undefined));
    }
  }, []);

  if (!value) {
    return null;
  }

  const get_errors = getSwitchError(value, columns, dtypes);

  return (
    <div>
      <Button color="primary" variant="outlined" onClick={onOpen}>
        設定
      </Button>
      <SwitchDialog
        open={open}
        value={value}
        schema={schema}
        columns={columns}
        dtypes={dtypes}
        onClose={onClose}
        variables={variables}
        errors={errors}
        onChange={onChange}
        projectId={projectId}
        portId={portId}
        dataStatus={dataStatus}
        contextData={contextData}
        inputDataMap={inputDataMap}
        portSummaries={portSummaries}
      />
      {get_errors.length > 0 && (
        <div className={classes.div}>
          <Typography
            className={classes.text}
            variant="inherit"
            color="secondary"
          >
            {get_errors[0]}
          </Typography>
        </div>
      )}
      {get_errors.length === 0 && (
        <div className={classes.div}>
          <GetSwitchRuleStr value={value} />
        </div>
      )}
    </div>
  );
};

const dialogStyles = makeStyles({
  title: {
    backgroundColor: '#f0f0f0'
  },
  content: {
    backgroundColor: '#fff',
    padding: 0
  },
  columnListContainer: {
    padding: '24px 10px',
    //width: 990,
    height: 300,
    overflow: 'hidden',
    paddingRight: 10
  },
  columnListTitle: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#414141',
    paddingBottom: 5,
    borderBottom: '2px solid #b0b0b0'
  },
  rulesContainer: {
    //width: 990,
    minHeight: 350,
    backgroundColor: '#e0e0e0',
    padding: '24px 32px',
    overflowY: 'auto',
    justifyContent: 'center'
  },
  dialogActions: {
    backgroundColor: '#f0f0f0',
    justifyContent: 'center'
  },
  actions: {
    //width: 704,
    justifyContent: 'center'
  },
  button: {
    margin: '0 4px'
  }
});

const SwitchDialog: React.FC<SwitchFieldDialogProps> = ({
  open,
  value,
  columns,
  dtypes,
  onClose,
  schema,
  variables,
  errors,
  onChange,
  projectId,
  portId,
  dataStatus,
  colnameMap,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = dialogStyles();
  const [rules, setRules] = React.useState<SwitchConditions>(
    initializeConditions(undefined)
  );

  React.useEffect(() => {
    // ダイアログを開いたら、rulesにvalueをコピーする
    if (open) {
      setRules(initializeConditions(value));
    }
  }, [open]);

  const onOKClicked = React.useCallback(() => {
    onChange(rules);
    onClose();
  }, [rules, onChange, onClose]);

  const selected = selectedColumns(columns, dtypes, rules);

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      onClose={onClose}
      title="条件と挿入値の設定"
      contentProps={{ className: classes.content }}
      OKButton={{ onClick: onOKClicked }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', maxHeight: 900 }}>
        <div style={{ overflowY: 'auto', maxHeight: 500, minHeight: 320 }}>
          <SwitchRulesArray
            value={rules}
            columns={columns}
            dtypes={dtypes}
            onChange={setRules}
            schema={schema}
            variables={variables}
            errors={errors}
            contextData={contextData}
            inputDataMap={inputDataMap}
            portSummaries={portSummaries}
          />
        </div>
        <div className={classes.columnListContainer}>
          <Typography className={classes.columnListTitle}>
            設定内の参照列 ({selected.length} 列)
          </Typography>
          <DataTable
            dataStatus={dataStatus}
            portId={portId}
            projectId={projectId}
            displayColumns={selected.map((c) => c.name)}
            colnameMap={colnameMap}
          />
        </div>
      </div>
    </Dialog>
  );
};

export interface Column {
  name: string;
  dtype: Dtypes;
}

export const selectedColumns = (
  columns: string[],
  dtypes: Dtypes[],
  rules: SwitchConditions
): Column[] => {
  // ルールを一つづつ適用していく
  let result = new Set<string>();
  if (rules.elseColname != null) {
    result.add(rules.elseColname.label);
  }
  const conditions = rules.conditions;

  conditions.forEach((r: SwitchCondition) => {
    if (r.returnColname != null) {
      result.add(r.returnColname.label);
    }
    r.andConditions.forEach((a: SwitchAndCondition) => {
      if (a.searchedColnames != null) {
        result.add(a.searchedColnames.label);
      }
      if (a.filterColnames != null) {
        result.add(a.filterColnames.label);
      }
      if (a.startFilterColnames != null) {
        result.add(a.startFilterColnames.label);
      }
      if (a.endFilterColnames != null) {
        result.add(a.endFilterColnames.label);
      }
    });
  });
  // 元の順序を維持しながらSetから取り出す
  const inc = [] as Column[];
  columns.forEach((c, i) => {
    if (result.has(c)) {
      inc.push({ name: c, dtype: dtypes[i] });
    }
  });

  return inc;
};

const ArrayRuleStyles = makeStyles({
  rulesContainer: {
    //width: 990,
    //overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: '#e4e4e4',
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: '3px'
  },
  rulesLeft: {
    width: 1158,
    marginRight: 8,
    position: 'relative',
    boxShadow: '5px 0 10px 0 rgba(0, 0, 0, 0.06)',
    '&:after': {
      position: 'absolute',
      boxSizing: 'border-box',
      width: 16,
      height: 16,
      borderTop: '0px solid transparent',
      borderRight: '2px solid #c3c3c3',
      borderBottom: '2px solid #c3c3c3',
      borderLeft: '0px solid transparent',
      right: 0,
      top: '50%',
      marginTop: -8,
      marginRight: -8,
      content: "''", // tslint:disable-line
      transform: 'rotate(-45deg)',
      backgroundColor: '#ededed',
      zIndex: 1
    }
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 10px',
    //overflowY: 'auto',
    backgroundColor: '#ededed',
    border: '2px #c3c3c3 solid',
    borderRadius: '3px',
    height: '100%'
  },
  otherwiseConditionContainer: {
    display: 'flex',
    backgroundColor: '#ffffff',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: '3px',
    alignItems: 'center',
    flex: '1 1 auto'
  },
  otherwiseContainer: {
    //width: 990,
    //overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: '#e4e4e4',
    marginTop: 5,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
    borderRadius: '3px',
    height: '100px'
  }
});
const SwitchRulesArray: React.FC<{
  value: SwitchConditions;
  columns: string[];
  dtypes: Dtypes[];
  onChange: (val: SwitchConditions) => void;
  schema: SwitchFieldSchema;
  variables: Variable[];
  errors: FieldValidationError;
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}> = ({
  value,
  columns,
  dtypes,
  onChange,
  schema,
  variables,
  errors,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = ArrayRuleStyles();
  const conditions = value.conditions;
  const elseUseColumn = value.elseUseColumn;
  const elseValue = value.elseValue;
  const elseColname = value.elseColname;
  return (
    <>
      {conditions.map((v, index) => {
        return (
          <div key={index} className={classes.rulesContainer}>
            <div className={classes.rulesLeft}>
              <SwitchRulesOneOfArray
                index={index}
                value={v}
                columns={columns}
                dtypes={dtypes}
                conditionOnChange={(newVal) => {
                  const newState = produce(conditions, (draft) => {
                    draft[index] = newVal;
                  });
                  onChange({
                    conditions: newState,
                    elseUseColumn: elseUseColumn,
                    elseValue: elseValue,
                    elseColname: elseColname
                  });
                }}
                schema={schema}
                variables={variables}
                errors={errors}
                contextData={contextData}
                inputDataMap={inputDataMap}
                portSummaries={portSummaries}
              />
            </div>
            <div
              style={{
                width: 442,
                paddingLeft: '16px',
                paddingRight: '8px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                position: 'relative'
              }}
            >
              <ConditionReturnValue
                value={v}
                variables={variables}
                schema={schema}
                conditionOnChange={(newVal) => {
                  const newState = produce(conditions, (draft) => {
                    draft[index] = newVal;
                  });
                  onChange({
                    conditions: newState,
                    elseUseColumn: elseUseColumn,
                    elseValue: elseValue,
                    elseColname: elseColname
                  });
                }}
                errors={errors}
                columns={columns}
                dtypes={dtypes}
                index={index}
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  padding: '3px 3px',
                  position: 'absolute',
                  right: 0,
                  bottom: 0
                }}
              >
                <AddRuleOrCondition
                  conditions={conditions}
                  index={index}
                  elseUseColumn={elseUseColumn}
                  elseValue={elseValue}
                  elseColname={elseColname}
                  conditionOnChange={onChange}
                />
                <MoveRuleOrCondition
                  conditions={conditions}
                  index={index}
                  elseUseColumn={elseUseColumn}
                  elseValue={elseValue}
                  elseColname={elseColname}
                  conditionOnChange={onChange}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className={classes.otherwiseContainer}>
        <div className={classes.rulesLeft}>
          <div className={classes.ruleContainer}>
            <div className={classes.otherwiseConditionContainer}>
              <Typography
                style={{
                  color: '#6e6e6e',
                  paddingLeft: '43px'
                }}
              >
                すべての条件に一致しない場合
              </Typography>
            </div>
          </div>
        </div>
        <div style={{ width: 442, paddingLeft: '16px', paddingRight: '8px' }}>
          <ElseReturnValue
            variables={variables}
            conditions={conditions}
            schema={schema}
            OnChange={onChange}
            errors={errors}
            elseUseColumn={elseUseColumn}
            elseValue={elseValue}
            elseColname={elseColname}
            columns={columns}
            dtypes={dtypes}
            index={0}
          />
        </div>
      </div>
    </>
  );
};

const OneOfArrayRuleStyles = makeStyles({
  columnSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '14%'
    //width: '230px'
  },
  elementSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '18%'
  },
  operatorSelect: {
    padding: '3px 10px',
    marginTop: '45px',
    width: '18%'
    //width: '230px'
  },
  ruleContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 10px',
    //overflowY: 'auto',
    backgroundColor: '#ededed',
    border: '2px #c3c3c3 solid',
    borderRadius: '3px',
    height: '100%'
  },
  conditionContainer: {
    //overflowY: 'auto',
    backgroundColor: '#ffffff',
    marginTop: 5,
    marginBottom: 5,
    borderRadius: '3px',
    position: 'relative',
    '&:not(:last-child)': {
      '&:after': {
        position: 'absolute',
        content: "'and'",
        borderRadius: '50%',
        width: 32,
        height: 32,
        bottom: -24,
        left: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#fff',
        background: '#9f9f9f',
        fontSize: 12,
        fontWeight: 500,
        boxShadow: '0 2px 5px 0 rgb(0 0 0 / 20%)',
        zIndex: 10
      }
    }
  },
  elseContainer: {
    width: 990,
    minHeight: 350,
    backgroundColor: '#e0e0e0',
    padding: '24px 32px',
    //overflowY: 'auto',
    justifyContent: 'center',
    display: 'flex'
  }
});

const SwitchRulesOneOfArray: React.FC<{
  index: number;
  value: SwitchCondition;
  columns: string[];
  dtypes: Dtypes[];
  conditionOnChange: (val: SwitchCondition) => void;
  schema: SwitchFieldSchema;
  variables: Variable[];
  errors: FieldValidationError;
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}> = ({
  index,
  value,
  columns,
  dtypes,
  conditionOnChange,
  schema,
  variables,
  errors,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = OneOfArrayRuleStyles();
  const andConditions: SwitchAndConditions = value.andConditions;
  const returnUseColumn = value.returnUseColumn;
  const returnValue = value.returnValue;
  const returnColname = value.returnColname;

  return (
    <>
      <div key={String(index) + '-root'} className={classes.ruleContainer}>
        {andConditions.map((v, index) => {
          const col = andConditions[index].searchedColnames;
          const dtype = col == null ? null : LabelToDtype(col.dtype);
          return (
            <div key={index} className={classes.conditionContainer}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'start'
                }}
              >
                {(dtype == null || col == null || col.label == null) && (
                  <NumberField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    errors={errors}
                    schema={schema}
                    variables={variables}
                  />
                )}
                {dtype === 'number' && (
                  <NumberField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    errors={errors}
                    schema={schema}
                    variables={variables}
                  />
                )}
                {dtype === 'string' && (
                  <StringField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    //errors={errors}
                    schema={schema}
                    variables={variables}
                    contextData={contextData}
                    inputDataMap={inputDataMap}
                    portSummaries={portSummaries}
                  />
                )}
                {dtype === 'date' && (
                  <DateField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    errors={errors}
                    schema={schema}
                    variables={variables}
                  />
                )}
                {dtype === 'timestamp' && (
                  <DatetimeField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    errors={errors}
                    schema={schema}
                    variables={variables}
                  />
                )}
                {dtype === 'time' && (
                  <TimeField
                    index={index}
                    columns={columns}
                    dtypes={dtypes}
                    dtype={dtype}
                    values={v}
                    andConditions={andConditions}
                    conditionOnChange={conditionOnChange}
                    returnUseColumn={returnUseColumn}
                    returnValue={returnValue}
                    returnColname={returnColname}
                    errors={errors}
                    schema={schema}
                    variables={variables}
                  />
                )}
              </div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <AddRuleAndCondition
                  conditions={andConditions}
                  index={index}
                  returnUseColumn={returnUseColumn}
                  returnValue={returnValue}
                  returnColname={returnColname}
                  conditionOnChange={conditionOnChange}
                />
                <MoveRuleAndCondition
                  conditions={andConditions}
                  index={index}
                  returnUseColumn={returnUseColumn}
                  returnValue={returnValue}
                  returnColname={returnColname}
                  conditionOnChange={conditionOnChange}
                />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

const ElseReturnValue: React.FC<{
  variables: Variable[];
  conditions: SwitchCondition[];
  schema: SwitchFieldSchema;
  OnChange: (val: SwitchConditions) => void;
  errors: FieldValidationError;
  elseUseColumn: boolean | null;
  elseValue: string | null;
  elseColname: SwitchSelectColumn | null;
  columns: string[];
  dtypes: Dtypes[];
  index: number;
}> = ({
  variables,
  conditions,
  schema,
  OnChange,
  //errors,
  elseUseColumn,
  elseValue,
  elseColname,
  columns,
  dtypes,
  index
}) => {
  const textSchema: TextFieldSchema = {
    type: FieldTypes.text,
    key: schema.key,
    readonly: schema.readonly,
    fullWidth: schema.fullWidth,
    placeholder: schema.placeholder
  };
  return (
    <div>
      <UseColumnCondition
        operator={SwitchOperators.eq}
        check={elseUseColumn}
        onChange={(newVal) => {
          OnChange({
            conditions: conditions,
            elseUseColumn: newVal,
            elseValue: elseValue,
            elseColname: elseColname
          });
        }}
        isReturnValue={true}
        label="既存列を挿入する"
      />
      {elseUseColumn === true && (
        <ColumnSelectValueSetter
          columns={columns}
          dtypes={dtypes}
          value={elseColname}
          onChange={(newVal) => {
            const newState =
              newVal == null
                ? null
                : {
                    label: newVal,
                    value: newVal,
                    dtype: dtypes[columns.indexOf(newVal)]
                  };
            OnChange({
              conditions: conditions,
              elseUseColumn: elseUseColumn,
              elseValue: elseValue,
              elseColname: newState
            });
          }}
          placeholder="列を選択"
          index={index}
          //errors={errors}
        />
      )}
      {elseUseColumn !== true && (
        <TextField
          variables={variables}
          value={elseValue == null? '' : elseValue}
          schema={textSchema}
          onChangeField={(newVal) => {
            OnChange({
              conditions: conditions,
              elseUseColumn: elseUseColumn,
              elseValue: newVal,
              elseColname: elseColname
            });
          }}
          errors={[]}
          placeholder="挿入する値"
        />
      )}
    </div>
  );
};

const ConditionReturnValue: React.FC<{
  value: SwitchCondition;
  variables: Variable[];
  schema: SwitchFieldSchema;
  conditionOnChange: (val: SwitchCondition) => void;
  errors: FieldValidationError;
  columns: string[];
  dtypes: Dtypes[];
  index: number;
}> = ({
  value,
  variables,
  schema,
  conditionOnChange,
  //errors,
  columns,
  dtypes,
  index
}) => {
  const andConditions: SwitchAndConditions = value.andConditions;
  const returnUseColumn = value.returnUseColumn;
  const returnValue = value.returnValue;
  const returnColname = value.returnColname;
  const textSchema: TextFieldSchema = {
    type: FieldTypes.text,
    key: schema.key,
    readonly: schema.readonly,
    fullWidth: schema.fullWidth,
    placeholder: schema.placeholder
  };
  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <UseColumnCondition
          operator={SwitchOperators.eq}
          check={returnUseColumn}
          onChange={(newVal) => {
            conditionOnChange({
              andConditions: andConditions,
              returnUseColumn: newVal,
              returnValue: returnValue,
              returnColname: returnColname
            });
          }}
          isReturnValue={true}
          label="既存列を挿入する"
        />
      </div>
      {returnUseColumn === true && (
        <ColumnSelectValueSetter
          columns={columns}
          dtypes={dtypes}
          value={returnColname}
          onChange={(newVal) => {
            const newState =
              newVal == null
                ? null
                : {
                    label: newVal,
                    value: newVal,
                    dtype: dtypes[columns.indexOf(newVal)]
                  };
            conditionOnChange({
              andConditions: andConditions,
              returnUseColumn: returnUseColumn,
              returnValue: returnValue,
              returnColname: newState
            });
          }}
          placeholder="列を選択"
          index={index}
          //errors={errors}
        />
      )}
      {returnUseColumn !== true && (
        <TextField
          variables={variables}
          value={returnValue == null ? '' : returnValue}
          schema={textSchema}
          onChangeField={(newVal) => {
            conditionOnChange({
              andConditions: andConditions,
              returnUseColumn: returnUseColumn,
              returnValue: newVal,
              returnColname: returnColname
            });
          }}
          errors={[]}
          placeholder="挿入する値"
        />
      )}
    </div>
  );
};

export const AddRuleAndCondition: React.FC<{
  conditions: SwitchAndConditions;
  index: number;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  conditionOnChange: (val: SwitchCondition) => void;
}> = ({
  conditions,
  index,
  returnValue,
  returnUseColumn,
  returnColname,
  conditionOnChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAdd = (index: number) => () => {
    const newAndConditions = [
      ...conditions.slice(0, index + 1),
      generateAndCondition(),
      ...conditions.slice(index + 1)
    ];
    // index + 1の場所にアイテムを追加する
    conditionOnChange({
      andConditions: newAndConditions,
      returnUseColumn: returnUseColumn,
      returnValue: returnValue,
      returnColname: returnColname
    });
    setAnchorEl(null);
  };
  const onCopy = (index: number) => () => {
    const newAndConditions = [
      ...conditions.slice(0, index + 1),
      ...conditions.slice(index, index + 1),
      ...conditions.slice(index + 1)
    ];
    // index + 1の場所にアイテムを追加する
    conditionOnChange({
      andConditions: newAndConditions,
      returnUseColumn: returnUseColumn,
      returnValue: returnValue,
      returnColname: returnColname
    });
    setAnchorEl(null);
  };
  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        style={{ marginRight: 10, marginLeft: 10, color: '#344955' }}
      >
        <AddCircle fontSize="medium" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '25ch'
          }
        }}
      >
        <MenuItem onClick={onAdd(index)}>and条件を新規追加</MenuItem>
        <Divider />
        <MenuItem onClick={onCopy(index)}>and条件として複製</MenuItem>
      </Menu>
    </div>
  );
};

export const MoveRuleAndCondition: React.FC<{
  conditions: SwitchAndConditions;
  index: number;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  conditionOnChange: (val: SwitchCondition) => void;
}> = ({
  conditions,
  index,
  returnValue,
  returnUseColumn,
  returnColname,
  conditionOnChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReorder = (index: number, newIndex: number) => () => {
    const newAndConditions = [...conditions];
    newAndConditions.splice(newIndex, 0, newAndConditions.splice(index, 1)[0]);
    // アイテムを移動する
    conditionOnChange({
      andConditions: newAndConditions,
      returnUseColumn: returnUseColumn,
      returnValue: returnValue,
      returnColname: returnColname
    });
    setAnchorEl(null);
  };

  const onDelete = (index: number) => () => {
    const newAndConditions = [...conditions];
    newAndConditions.splice(index, 1);
    // indexのアイテムを削除する
    conditionOnChange({
      andConditions: newAndConditions,
      returnUseColumn: returnUseColumn,
      returnValue: returnValue,
      returnColname: returnColname
    });
    setAnchorEl(null);
  };
  const condition_length = conditions.length;
  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        style={{ marginRight: 10, marginLeft: 10 }}
      >
        <MoreHoriz fontSize="medium" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '25ch'
          }
        }}
      >
        <MenuItem disabled={index === 0} onClick={onReorder(index, index - 1)}>
          <Typography>上へ移動</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={index === condition_length - 1}
          onClick={onReorder(index, index + 1)}
        >
          <Typography>下へ移動</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled={condition_length === 1} onClick={onDelete(index)}>
          <Typography variant="inherit" color="secondary">
            削除
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export const AddRuleOrCondition: React.FC<{
  conditions: SwitchCondition[];
  index: number;
  elseUseColumn: boolean | null;
  elseValue: string | null;
  elseColname: SwitchSelectColumn | null;
  conditionOnChange: (val: SwitchConditions) => void;
}> = ({
  conditions,
  index,
  elseUseColumn,
  elseValue,
  elseColname,
  conditionOnChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onAdd = (index: number) => () => {
    const newConditions = [
      ...conditions.slice(0, index + 1),
      generateCondition(),
      ...conditions.slice(index + 1)
    ];
    // index + 1の場所にアイテムを追加する
    conditionOnChange({
      conditions: newConditions,
      elseUseColumn: elseUseColumn,
      elseValue: elseValue,
      elseColname: elseColname
    });
    setAnchorEl(null);
  };
  const onCopy = (index: number) => () => {
    const newConditions = [
      ...conditions.slice(0, index + 1),
      ...conditions.slice(index, index + 1),
      ...conditions.slice(index + 1)
    ];
    // index + 1の場所にアイテムを追加する
    conditionOnChange({
      conditions: newConditions,
      elseUseColumn: elseUseColumn,
      elseValue: elseValue,
      elseColname: elseColname
    });
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        style={{ marginRight: 10, marginLeft: 10, color: '#344955' }}
      >
        <AddCircle fontSize="medium" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '25ch'
          }
        }}
      >
        <MenuItem onClick={onAdd(index)}>条件を新規追加</MenuItem>
        <Divider />
        <MenuItem onClick={onCopy(index)}>条件を複製</MenuItem>
      </Menu>
    </div>
  );
};

export const MoveRuleOrCondition: React.FC<{
  conditions: SwitchCondition[];
  index: number;
  elseUseColumn: boolean | null;
  elseValue: string | null;
  elseColname: SwitchSelectColumn | null;
  conditionOnChange: (val: SwitchConditions) => void;
}> = ({
  conditions,
  index,
  elseUseColumn,
  elseValue,
  elseColname,
  conditionOnChange
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onReorder = (index: number, newIndex: number) => () => {
    const newConditions = [...conditions];
    newConditions.splice(newIndex, 0, newConditions.splice(index, 1)[0]);
    // アイテムを移動する
    conditionOnChange({
      conditions: newConditions,
      elseUseColumn: elseUseColumn,
      elseValue: elseValue,
      elseColname: elseColname
    });
    setAnchorEl(null);
  };
  const onDelete = (index: number) => () => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    // indexのアイテムを削除する
    conditionOnChange({
      conditions: newConditions,
      elseUseColumn: elseUseColumn,
      elseValue: elseValue,
      elseColname: elseColname
    });
    setAnchorEl(null);
  };
  const condition_length = conditions.length;

  return (
    <div>
      <IconButton
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        size="small"
        style={{ marginRight: 10, marginLeft: 10 }}
      >
        <MoreHoriz fontSize="medium" />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 200,
            width: '25ch'
          }
        }}
      >
        <MenuItem disabled={index === 0} onClick={onReorder(index, index - 1)}>
          <Typography>上へ移動</Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          disabled={index === condition_length - 1}
          onClick={onReorder(index, index + 1)}
        >
          <Typography>下へ移動</Typography>
        </MenuItem>
        <Divider />
        <MenuItem disabled={condition_length === 1} onClick={onDelete(index)}>
          <Typography variant="inherit" color="secondary">
            削除
          </Typography>
        </MenuItem>
      </Menu>
    </div>
  );
};

export const initializeConditions = (
  value: SwitchConditions | undefined
): SwitchConditions => {
  if (value == undefined) {
    return generateNewRule();
  }
  return value;
};

const generateNewRule = (): SwitchConditions => ({
  conditions: [generateCondition()],
  elseUseColumn: null,
  elseValue: null,
  elseColname: null
});

const generateCondition = (): SwitchCondition => ({
  andConditions: [generateAndCondition()],
  returnUseColumn: null,
  returnValue: null,
  returnColname: null
});

const generateAndCondition = (): SwitchAndCondition => ({
  searchedColnames: null,
  operator: null,
  betweenOperator: null,
  datetimeType: null,
  weekdayType: null,
  valueUseColumn: null,
  filterColnames: null,
  valueStart: null,
  valueStartUseColumn: null,
  startFilterColnames: null,
  valueEnd: null,
  valueEndUseColumn: null,
  endFilterColnames: null,
  value: null
});

const checkAllNullValue = (value: Object): boolean => {
  const _res = Object.values(value).map((x) => {
    if (x == null) {
      return true;
    } else if (Array.isArray(x)) {
      const tmp = x.map((y) => {
        return checkAllNullValue(y);
      });
      return tmp.every((g) => g === true);
    } else {
      return false;
    }
  });
  const res = _res.every((n) => n === true);
  return res;
};

export const getSwitchError = (
  value: SwitchConditions | null,
  columns?: string[],
  dtypes?: Dtypes[]
): string[] => {
  if (columns == undefined || dtypes == undefined) {
    return ['未設定'];
  }
  if (value == undefined || value.conditions.length === 0) {
    return ['未設定'];
  }
  const check = checkAllNullValue(value);
  if (check) {
    return ['未設定'];
  }
  let errors: string[] = [];
  value.conditions.forEach((s: SwitchCondition) => {
    s.andConditions.forEach((a: SwitchAndCondition) => {
      if (a.searchedColnames == null) {
        errors.push('対象の列が選択されていません');
        return;
      }
      const dtype = a.searchedColnames.dtype;
      const label = a.searchedColnames.label;
      const validate = validate_columns(label, dtype, columns, dtypes);
      if (validate != null) {
        errors.push(validate);
        return;
      }
      switch (dtype) {
        case 'number': {
          const res = checkNumberError(a, columns, dtypes);
          if (res != undefined) {
            errors.push(res);
          }
          return;
        }
        case 'string': {
          const res = checkStringError(a, columns, dtypes);
          if (res != undefined) {
            errors.push(res);
          }
          return;
        }
        case 'date': {
          const res = checkDateError(a, columns, dtypes);
          if (res != undefined) {
            errors.push(res);
          }
          return;
        }
        case 'timestamp': {
          const res = checkDatetimeError(a, columns, dtypes);
          if (res != undefined) {
            errors.push(res);
          }
          return;
        }
        case 'time': {
          const res = checkTimeError(a, columns, dtypes);
          if (res != undefined) {
            errors.push(res);
          }
          return;
        }
        default:
          return;
      }
    });
    const return_use_column = s.returnUseColumn;
    const return_col_name = s.returnColname;
    if (return_use_column === true && return_col_name != null) {
      const validate = validate_columns(
        return_col_name.label,
        return_col_name.dtype,
        columns,
        dtypes
      );
      if (validate != null) {
        errors.push(validate);
        return;
      }
    }
  });
  const else_use_column = value.elseUseColumn;
  const else_col_name = value.elseColname;
  if (else_use_column === true && else_col_name != null) {
    const validate = validate_columns(
      else_col_name.label,
      else_col_name.dtype,
      columns,
      dtypes
    );
    if (validate != null) {
      errors.push(validate);
    }
  }
  return errors;
};

export const checkNumberError = (
  andCondition: SwitchAndCondition,
  columns: string[],
  dtypes: string[]
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return '比較タイプが選択されていません';
  }
  switch (opr) {
    case SwitchOperators.drop_na:
    case SwitchOperators.extract_na: {
      return;
    }
    case SwitchOperators.between: {
      const between_operator = andCondition.betweenOperator;
      if (between_operator == null) {
        return '範囲指定の不等号が選択されていません';
      }
      const valueStartUseColumn = andCondition.valueStartUseColumn;
      const startFilterColnames = andCondition.startFilterColnames;
      const valueStart = andCondition.valueStart;
      if (valueStartUseColumn === true && startFilterColnames == null) {
        return '始点が入力されていません';
      }
      if (valueStartUseColumn === true && startFilterColnames != null) {
        const vc = validate_columns(
          startFilterColnames.label,
          startFilterColnames.dtype,
          columns,
          dtypes,
          ['number']
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (
        valueStartUseColumn !== true &&
        (valueStart == null || valueStart === '')
      ) {
        return '始点が入力されていません';
      }

      const valueEndUseColumn = andCondition.valueEndUseColumn;
      const endFilterColnames = andCondition.endFilterColnames;
      const valueEnd = andCondition.valueEnd;
      if (valueEndUseColumn === true && endFilterColnames == null) {
        return '終点が入力されていません';
      }
      if (valueEndUseColumn === true && endFilterColnames != null) {
        const vc = validate_columns(
          endFilterColnames.label,
          endFilterColnames.dtype,
          columns,
          dtypes,
          ['number']
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (valueEndUseColumn !== true && (valueEnd == null || valueEnd === '')) {
        return '終点が入力されていません';
      }
      return;
    }
    case SwitchOperators.in:
    case SwitchOperators.not_in: {
      const value = andCondition.value;
      if (value == null || value === '') {
        return '条件値が入力されていません';
      }
      return;
    }
    case SwitchOperators.eq:
    case SwitchOperators.notEq:
    case SwitchOperators.lt:
    case SwitchOperators.le:
    case SwitchOperators.gt:
    case SwitchOperators.ge: {
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      if (valueUseColumn === true && filterColnames == null) {
        return '条件値が入力されていません';
      }
      if (valueUseColumn === true && filterColnames != null) {
        const vc = validate_columns(
          filterColnames.label,
          filterColnames.dtype,
          columns,
          dtypes,
          ['number']
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (valueUseColumn !== true && (value == null || value === '')) {
        return '条件値が入力されていません';
      }
      return;
    }
    default:
      return '比較タイプが選択されていません';
  }
};

export const checkStringError = (
  andCondition: SwitchAndCondition,
  columns: string[],
  dtypes: string[]
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return '比較タイプが選択されていません';
  }
  switch (opr) {
    case SwitchOperators.drop_na:
    case SwitchOperators.extract_na: {
      return;
    }
    case SwitchOperators.in:
    case SwitchOperators.not_in: {
      const value = andCondition.value;
      if (value == null || value === '') {
        return '条件が設定されていません';
      }
      return;
    }
    case SwitchOperators.eq:
    case SwitchOperators.notEq: {
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      if (valueUseColumn === true && filterColnames == null) {
        return '条件値が入力されていません';
      }
      if (valueUseColumn === true && filterColnames != null) {
        const vc = validate_columns(
          filterColnames.label,
          filterColnames.dtype,
          columns,
          dtypes,
          ['string']
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (valueUseColumn !== true && (value == null || value === '')) {
        return '条件値が入力されていません';
      }
      return;
    }
    case SwitchOperators.grep:
    case SwitchOperators.ungrep:
    case SwitchOperators.starts_with:
    case SwitchOperators.ends_with:
    case SwitchOperators.regexp:
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      if (valueUseColumn === true && filterColnames == null) {
        return '条件値が入力されていません';
      }
      if (valueUseColumn === true && filterColnames != null) {
        const vc = validate_columns(
          filterColnames.label,
          filterColnames.dtype,
          columns,
          dtypes,
          ['string']
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (valueUseColumn !== true && (value == null || value === '')) {
        return '条件値が入力されていません';
      }
      return;
    default:
      return '比較タイプが選択されていません';
  }
};

export const checkDateError = (
  andCondition: SwitchAndCondition,
  columns: string[],
  dtypes: string[]
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return '比較タイプが選択されていません';
  }
  const datetimeType = andCondition.datetimeType;
  if (datetimeType == null) {
    return '日付要素が選択されていません';
  }
  if (datetimeType === SwitchDatetimeElement.weekday) {
    switch (opr) {
      case SwitchOperators.eq:
      case SwitchOperators.notEq:
      case SwitchOperators.in:
      case SwitchOperators.not_in: {
        const weekdayType = andCondition.weekdayType;
        if (weekdayType == null) {
          return '曜日が選択されていません';
        }
        return;
      }
      default:
    }
  }
  switch (datetimeType) {
    case SwitchDatetimeElement.ymd:
    case SwitchDatetimeElement.ym:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          if (valueStartUseColumn === true && startFilterColnames == null) {
            return '始点が入力されていません';
          }
          if (valueStartUseColumn === true && startFilterColnames != null) {
            const vc = validate_columns(
              startFilterColnames.label,
              startFilterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueStartUseColumn !== true &&
            (valueStart == null || valueStart === '')
          ) {
            return '始点が入力されていません';
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          if (valueEndUseColumn === true && endFilterColnames == null) {
            return '終点が入力されていません';
          }
          if (valueEndUseColumn === true && endFilterColnames != null) {
            const vc = validate_columns(
              endFilterColnames.label,
              endFilterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueEndUseColumn !== true &&
            (valueEnd == null || valueEnd === '')
          ) {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          if (valueUseColumn === true && filterColnames == null) {
            return '条件値が入力されていません';
          }
          if (valueUseColumn === true && filterColnames != null) {
            const vc = validate_columns(
              filterColnames.label,
              filterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (valueUseColumn !== true && (value == null || value === '')) {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
    case SwitchDatetimeElement.year:
    case SwitchDatetimeElement.month:
    case SwitchDatetimeElement.day:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStart = andCondition.valueStart;
          if (valueStart == null || valueStart === '') {
            return '始点が入力されていません';
          }

          const valueEnd = andCondition.valueEnd;
          if (valueEnd == null || valueEnd === '') {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
  }
};

export const checkDatetimeError = (
  andCondition: SwitchAndCondition,
  columns: string[],
  dtypes: string[]
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return '比較タイプが選択されていません';
  }
  const datetimeType = andCondition.datetimeType;
  if (datetimeType == null) {
    return '日付要素が選択されていません';
  }
  if (datetimeType === SwitchDatetimeElement.weekday) {
    switch (opr) {
      case SwitchOperators.eq:
      case SwitchOperators.notEq:
      case SwitchOperators.in:
      case SwitchOperators.not_in: {
        const weekdayType = andCondition.weekdayType;
        if (weekdayType == null) {
          return '曜日が選択されていません';
        }
        return;
      }
      default:
    }
  }
  switch (datetimeType) {
    case SwitchDatetimeElement.ymd:
    case SwitchDatetimeElement.ym:
    case SwitchDatetimeElement.hms:
    case SwitchDatetimeElement.ymdhms:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          if (valueStartUseColumn === true && startFilterColnames == null) {
            return '始点が入力されていません';
          }
          if (valueStartUseColumn === true && startFilterColnames != null) {
            const vc = validate_columns(
              startFilterColnames.label,
              startFilterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueStartUseColumn !== true &&
            (valueStart == null || valueStart === '')
          ) {
            return '始点が入力されていません';
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          if (valueEndUseColumn === true && endFilterColnames == null) {
            return '終点が入力されていません';
          }
          if (valueEndUseColumn === true && endFilterColnames != null) {
            const vc = validate_columns(
              endFilterColnames.label,
              endFilterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueEndUseColumn !== true &&
            (valueEnd == null || valueEnd === '')
          ) {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          if (valueUseColumn === true && filterColnames == null) {
            return '条件値が入力されていません';
          }
          if (valueUseColumn === true && filterColnames != null) {
            const vc = validate_columns(
              filterColnames.label,
              filterColnames.dtype,
              columns,
              dtypes,
              ['date', 'timestamp']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (valueUseColumn !== true && (value == null || value === '')) {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
    case SwitchDatetimeElement.year:
    case SwitchDatetimeElement.month:
    case SwitchDatetimeElement.day:
    case SwitchDatetimeElement.hour:
    case SwitchDatetimeElement.minute:
    case SwitchDatetimeElement.second:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStart = andCondition.valueStart;
          if (valueStart == null || valueStart === '') {
            return '始点が入力されていません';
          }

          const valueEnd = andCondition.valueEnd;
          if (valueEnd == null || valueEnd === '') {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
  }
};

export const checkTimeError = (
  andCondition: SwitchAndCondition,
  columns: string[],
  dtypes: string[]
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return '比較タイプが選択されていません';
  }
  const datetimeType = andCondition.datetimeType;
  if (datetimeType == null) {
    return '日付要素が選択されていません';
  }
  switch (datetimeType) {
    case SwitchDatetimeElement.hour:
    case SwitchDatetimeElement.minute:
    case SwitchDatetimeElement.second:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          if (valueStartUseColumn === true && startFilterColnames == null) {
            return '始点が入力されていません';
          }
          if (valueStartUseColumn === true && startFilterColnames != null) {
            const vc = validate_columns(
              startFilterColnames.label,
              startFilterColnames.dtype,
              columns,
              dtypes,
              ['number']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueStartUseColumn !== true &&
            (valueStart == null || valueStart === '')
          ) {
            return '始点が入力されていません';
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          if (valueEndUseColumn === true && endFilterColnames == null) {
            return '終点が入力されていません';
          }
          if (valueEndUseColumn === true && endFilterColnames != null) {
            const vc = validate_columns(
              endFilterColnames.label,
              endFilterColnames.dtype,
              columns,
              dtypes,
              ['number']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueEndUseColumn !== true &&
            (valueEnd == null || valueEnd === '')
          ) {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          if (valueUseColumn === true && filterColnames == null) {
            return '条件値が入力されていません';
          }
          if (valueUseColumn === true && filterColnames != null) {
            const vc = validate_columns(
              filterColnames.label,
              filterColnames.dtype,
              columns,
              dtypes,
              ['number']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (valueUseColumn !== true && (value == null || value === '')) {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
    case SwitchDatetimeElement.hms:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return '範囲指定の不等号が選択されていません';
          }
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          if (valueStartUseColumn === true && startFilterColnames == null) {
            return '始点が入力されていません';
          }
          if (valueStartUseColumn === true && startFilterColnames != null) {
            const vc = validate_columns(
              startFilterColnames.label,
              startFilterColnames.dtype,
              columns,
              dtypes,
              ['time']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueStartUseColumn !== true &&
            (valueStart == null || valueStart === '')
          ) {
            return '始点が入力されていません';
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          if (valueEndUseColumn === true && endFilterColnames == null) {
            return '終点が入力されていません';
          }
          if (valueEndUseColumn === true && endFilterColnames != null) {
            const vc = validate_columns(
              endFilterColnames.label,
              endFilterColnames.dtype,
              columns,
              dtypes,
              ['time']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (
            valueEndUseColumn !== true &&
            (valueEnd == null || valueEnd === '')
          ) {
            return '終点が入力されていません';
          }
          return;
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return '条件が設定されていません';
          }
          return;
        }
        case SwitchOperators.eq:
        case SwitchOperators.notEq:
        case SwitchOperators.lt:
        case SwitchOperators.le:
        case SwitchOperators.gt:
        case SwitchOperators.ge: {
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          if (valueUseColumn === true && filterColnames == null) {
            return '条件値が入力されていません';
          }
          if (valueUseColumn === true && filterColnames != null) {
            const vc = validate_columns(
              filterColnames.label,
              filterColnames.dtype,
              columns,
              dtypes,
              ['time']
            );
            if (vc != undefined) {
              return vc;
            }
          }
          if (valueUseColumn !== true && (value == null || value === '')) {
            return '条件値が入力されていません';
          }
          return;
        }
        default:
          return '比較タイプが選択されていません';
      }
    default:
      const match_operator = TimeOperator.filter((o) => {
        o.value === opr;
      });
      if (match_operator.length === 0) {
        return '比較タイプが選択されていません';
      }
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      if (valueUseColumn === true && filterColnames == null) {
        return '条件値が入力されていません';
      }
      if (valueUseColumn === true && filterColnames != null) {
        const vc = validate_columns(
          filterColnames.label,
          filterColnames.dtype,
          columns,
          dtypes
        );
        if (vc != undefined) {
          return vc;
        }
      }
      if (valueUseColumn !== true && (value == null || value === '')) {
        return '条件値が入力されていません';
      }
      return;
  }
};

export const GetSwitchRuleStr: React.FC<{
  value: SwitchConditions | null;
}> = ({ value }) => {
  if (value == undefined || value.conditions.length === 0) {
    return <Typography></Typography>;
  }
  const check = checkAllNullValue(value);
  if (check) {
    return <Typography></Typography>;
  }

  const condition_comments = value.conditions.map(
    (s: SwitchCondition, index: number) => {
      const and_conditions_comment = s.andConditions.map(
        (a: SwitchAndCondition, and_index: number) => {
          if (a.searchedColnames == null) {
            return <Typography></Typography>;
          }
          const dtype = a.searchedColnames.dtype;
          const target_col = a.searchedColnames.label;
          const comment = getComment(dtype, target_col, a);
          return (
            <>
              {and_index > 0 && <Typography>AND</Typography>}
              <Typography>{comment}</Typography>
            </>
          );
        }
      );
      const returnColname = s.returnColname;
      const returnUseColumn = s.returnUseColumn;
      const returnValue = s.returnValue;

      let return_comment: string = '""';

      if (returnUseColumn === true && returnColname != null) {
        return_comment = '列: ' + returnColname.label;
      }
      if (returnUseColumn !== true) {
        return_comment = returnValue == null ? '""' : returnValue;
      }

      return (
        <>
          {index > 0 && <br></br>}
          <Typography style={{ fontWeight: 'bold' }}>
            {'条件: ' + String(index + 1)}
          </Typography>
          {and_conditions_comment}
          <Typography>{'-> ' + return_comment}</Typography>
        </>
      );
    }
  );
  const elseColname = value.elseColname;
  const elseUseColumn = value.elseUseColumn;
  const elseValue = value.elseValue;

  let else_comment: string = '""';

  if (elseUseColumn === true && elseColname != null) {
    else_comment = '列: ' + elseColname.label;
  }
  if (elseUseColumn !== true) {
    else_comment = elseValue == null ? '""' : elseValue;
  }
  return (
    <>
      {condition_comments}
      <br></br>
      <Typography style={{ fontWeight: 'bold' }}>全条件不一致</Typography>
      <Typography>{'-> ' + else_comment}</Typography>
    </>
  );
};

export const getNumberComment = (
  andCondition: SwitchAndCondition,
  target_col: string
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return;
  }
  const opr_text = CommentOperator.filter((o) => o.value === opr)[0].label;
  switch (opr) {
    case SwitchOperators.drop_na:
    case SwitchOperators.extract_na: {
      return '・列: ' + target_col + ' ' + opr_text;
    }
    case SwitchOperators.between: {
      const between_operator = andCondition.betweenOperator;
      if (between_operator == null) {
        return;
      }
      const between_text = CommentBetweenOperator.filter(
        (o) => o.value === between_operator
      )[0].label;
      const valueStartUseColumn = andCondition.valueStartUseColumn;
      const startFilterColnames = andCondition.startFilterColnames;
      const valueStart = andCondition.valueStart;
      let start_value: string = '';
      if (valueStartUseColumn === true && startFilterColnames != null) {
        start_value = '列: ' + startFilterColnames.label;
      }
      if (
        valueStartUseColumn !== true &&
        valueStart != null &&
        valueStart !== ''
      ) {
        start_value = valueStart;
      }

      const valueEndUseColumn = andCondition.valueEndUseColumn;
      const endFilterColnames = andCondition.endFilterColnames;
      const valueEnd = andCondition.valueEnd;
      let end_value: string = '';
      if (valueEndUseColumn === true && endFilterColnames != null) {
        end_value = '列: ' + endFilterColnames.label;
      }
      if (valueStartUseColumn !== true && valueEnd != null && valueEnd !== '') {
        end_value = valueEnd;
      }
      return (
        '・列: ' +
        target_col +
        ' ' +
        start_value +
        ' ' +
        between_text +
        ' ' +
        end_value
      );
    }
    case SwitchOperators.in:
    case SwitchOperators.not_in: {
      const value = andCondition.value;
      let value_comment: string = '';
      if (value != null && value !== '') {
        value_comment = value;
      }
      return '・列: ' + target_col + ' ' + opr_text + ' ' + value_comment;
    }
    default:
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      let value_comment: string = '';
      if (valueUseColumn === true && filterColnames != null) {
        value_comment = '列: ' + filterColnames.label;
      }
      if (valueUseColumn !== true && value != null && value !== '') {
        value_comment = value;
      }
      return '・列: ' + target_col + ' ' + opr_text + ' ' + value_comment;
  }
};

export const getStringComment = (
  andCondition: SwitchAndCondition,
  target_col: string
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return;
  }
  const opr_text = CommentOperator.filter((o) => o.value === opr)[0].label;

  switch (opr) {
    case SwitchOperators.drop_na:
    case SwitchOperators.extract_na: {
      return '・列: ' + target_col + ' ' + opr_text;
    }
    case SwitchOperators.in:
    case SwitchOperators.not_in: {
      const value = andCondition.value;
      let value_comment: string = '';
      if (value != null && value !== '') {
        value_comment = value;
      }
      return '・列: ' + target_col + ' ' + opr_text + ' ' + value_comment;
    }
    case SwitchOperators.eq:
    case SwitchOperators.notEq: {
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      let value_comment: string = '';
      if (valueUseColumn === true && filterColnames != null) {
        value_comment = '列: ' + filterColnames.label;
      }
      if (valueUseColumn !== true && value != null && value !== '') {
        value_comment = value;
      }
      return '・列: ' + target_col + ' ' + opr_text + ' ' + value_comment;
    }
    default:
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      let value_comment: string = '';
      if (valueUseColumn === true && filterColnames != null) {
        value_comment = '列: ' + filterColnames.label;
      }
      if (valueUseColumn !== true && value != null && value !== '') {
        value_comment = value;
      }
      return '・列: ' + target_col + ' ' + opr_text + ' ' + value_comment;
  }
};
const get_week_day_text = (
  value: SwitchWeekdayList | SwitchWeekdayList[]
): string => {
  let text: string;
  if (Array.isArray(value)) {
    const res = value.map((v) => {
      return CommentWeekDay.filter((o) => o.value === v)[0].label;
    });
    text = res.join(',');
  } else {
    text = CommentWeekDay.filter((o) => o.value === value)[0].label;
  }
  return text;
};
export const getDateTimeComment = (
  andCondition: SwitchAndCondition,
  target_col: string
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return;
  }
  const datetimeType = andCondition.datetimeType;
  if (datetimeType == null) {
    return '日付要素が選択されていません';
  }

  const opr_text = CommentOperator.filter((o) => o.value === opr)[0].label;
  const datetime_type_text = CommentDatetimeElement.filter(
    (o) => o.value === datetimeType
  )[0].label;
  const weekdayType = andCondition.weekdayType;
  const week_day_text =
    weekdayType == null ? '' : get_week_day_text(weekdayType);
  if (datetimeType === SwitchDatetimeElement.weekday) {
    switch (opr) {
      case SwitchOperators.eq:
      case SwitchOperators.notEq:
      case SwitchOperators.in:
      case SwitchOperators.not_in: {
        if (weekdayType == null) {
          return;
        }
        return (
          '・列: ' +
          target_col +
          ' ' +
          datetime_type_text +
          ' ' +
          opr_text +
          ' ' +
          week_day_text
        );
      }
      default:
        return;
    }
  }

  switch (datetimeType) {
    case SwitchDatetimeElement.ymd:
    case SwitchDatetimeElement.ym:
    case SwitchDatetimeElement.hms:
    case SwitchDatetimeElement.ymdhms:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return;
          }
          const between_text = CommentBetweenOperator.filter(
            (o) => o.value === between_operator
          )[0].label;
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          let start_value: string = '';
          if (valueStartUseColumn === true && startFilterColnames != null) {
            start_value = '列: ' + startFilterColnames.label;
          }
          if (
            valueStartUseColumn !== true &&
            valueStart != null &&
            valueStart !== ''
          ) {
            start_value = valueStart;
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          let end_value: string = '';
          if (valueEndUseColumn === true && endFilterColnames != null) {
            end_value = '列: ' + endFilterColnames.label;
          }
          if (
            valueStartUseColumn !== true &&
            valueEnd != null &&
            valueEnd !== ''
          ) {
            end_value = valueEnd;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            start_value +
            ' ' +
            between_text +
            ' ' +
            end_value
          );
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return (
            '・列: ' + target_col + ' ' + datetime_type_text + ' ' + opr_text
          );
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          let value_comment: string = '';
          if (value != null && value !== '') {
            value_comment = value;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value_comment
          );
        }
        default:
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          let value_comment: string = '';
          if (valueUseColumn === true && filterColnames != null) {
            value_comment = '列: ' + filterColnames.label;
          }
          if (valueUseColumn !== true && value != null && value !== '') {
            value_comment = value;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value_comment
          );
      }
    case SwitchDatetimeElement.year:
    case SwitchDatetimeElement.month:
    case SwitchDatetimeElement.day:
    case SwitchDatetimeElement.hour:
    case SwitchDatetimeElement.minute:
    case SwitchDatetimeElement.second:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return;
          }
          const between_text = CommentBetweenOperator.filter(
            (o) => o.value === between_operator
          )[0].label;
          const valueStart = andCondition.valueStart;
          if (valueStart == null || valueStart === '') {
            return;
          }

          const valueEnd = andCondition.valueEnd;
          if (valueEnd == null || valueEnd === '') {
            return;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            valueStart +
            ' ' +
            between_text +
            ' ' +
            valueEnd
          );
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return (
            '・列: ' + target_col + ' ' + datetime_type_text + ' ' + opr_text
          );
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          if (value == null || value === '') {
            return;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value
          );
        }
        default:
          const value = andCondition.value;
          if (value == null || value === '') {
            return;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value
          );
      }
  }
};

export const getTimeComment = (
  andCondition: SwitchAndCondition,
  target_col: string
): string | undefined => {
  const opr = andCondition.operator;
  if (opr == null) {
    return;
  }
  const datetimeType = andCondition.datetimeType;
  if (datetimeType == null) {
    return;
  }
  const opr_text = CommentOperator.filter((o) => o.value === opr)[0].label;
  const datetime_type_text = CommentDatetimeElement.filter(
    (o) => o.value === datetimeType
  )[0].label;

  switch (datetimeType) {
    case SwitchDatetimeElement.hour:
    case SwitchDatetimeElement.minute:
    case SwitchDatetimeElement.second:
    case SwitchDatetimeElement.hms:
      switch (opr) {
        case SwitchOperators.between: {
          const between_operator = andCondition.betweenOperator;
          if (between_operator == null) {
            return;
          }
          const between_text = CommentBetweenOperator.filter(
            (o) => o.value === between_operator
          )[0].label;
          const valueStartUseColumn = andCondition.valueStartUseColumn;
          const startFilterColnames = andCondition.startFilterColnames;
          const valueStart = andCondition.valueStart;
          let start_value: string = '';
          if (valueStartUseColumn === true && startFilterColnames != null) {
            start_value = '列: ' + startFilterColnames.label;
          }
          if (
            valueStartUseColumn !== true &&
            valueStart != null &&
            valueStart !== ''
          ) {
            start_value = valueStart;
          }

          const valueEndUseColumn = andCondition.valueEndUseColumn;
          const endFilterColnames = andCondition.endFilterColnames;
          const valueEnd = andCondition.valueEnd;
          let end_value: string = '';
          if (valueEndUseColumn === true && endFilterColnames != null) {
            end_value = '列: ' + endFilterColnames.label;
          }
          if (
            valueStartUseColumn !== true &&
            valueEnd != null &&
            valueEnd !== ''
          ) {
            end_value = valueEnd;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            start_value +
            ' ' +
            between_text +
            ' ' +
            end_value
          );
        }
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return (
            '・列: ' + target_col + ' ' + datetime_type_text + ' ' + opr_text
          );
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          const value = andCondition.value;
          let value_comment: string = '';
          if (value != null && value !== '') {
            value_comment = value;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value_comment
          );
        }
        default:
          const valueUseColumn = andCondition.valueUseColumn;
          const filterColnames = andCondition.filterColnames;
          const value = andCondition.value;
          let value_comment: string = '';
          if (valueUseColumn === true && filterColnames != null) {
            value_comment = '列: ' + filterColnames.label;
          }
          if (valueUseColumn !== true && value != null && value !== '') {
            value_comment = value;
          }
          return (
            '・列: ' +
            target_col +
            ' ' +
            datetime_type_text +
            ' ' +
            opr_text +
            value_comment
          );
      }
    default:
      const valueUseColumn = andCondition.valueUseColumn;
      const filterColnames = andCondition.filterColnames;
      const value = andCondition.value;
      let value_comment: string = '';
      if (valueUseColumn === true && filterColnames != null) {
        value_comment = '列: ' + filterColnames.label;
      }
      if (valueUseColumn !== true && value != null && value !== '') {
        value_comment = value;
      }
      return (
        '・列: ' +
        target_col +
        ' ' +
        datetime_type_text +
        ' ' +
        opr_text +
        value_comment
      );
  }
};

const getComment = (
  dtype: string,
  target_col: string,
  a: SwitchAndCondition
): string => {
  switch (dtype) {
    case 'number': {
      const res = getNumberComment(a, target_col);
      if (res != undefined) {
        return res;
      }
      return '';
    }
    case 'string': {
      const res = getStringComment(a, target_col);
      if (res != undefined) {
        return res;
      }
      return '';
    }
    case 'date':
    case 'timestamp': {
      const res = getDateTimeComment(a, target_col);
      if (res != undefined) {
        return res;
      }
      return '';
    }
    case 'time': {
      const res = getTimeComment(a, target_col);
      if (res != undefined) {
        return res;
      }
      return '';
    }
    default:
      return '';
  }
};

const CommentWeekDay = [
  { value: SwitchWeekdayList.monday, label: '月曜' },
  { value: SwitchWeekdayList.tuesday, label: '火曜' },
  { value: SwitchWeekdayList.wednesday, label: '水曜' },
  { value: SwitchWeekdayList.thursday, label: '木曜' },
  { value: SwitchWeekdayList.friday, label: '金曜' },
  { value: SwitchWeekdayList.saturday, label: '土曜' },
  { value: SwitchWeekdayList.sunday, label: '日曜' }
];

const CommentOperator = [
  { value: SwitchOperators.eq, label: '=' },
  { value: SwitchOperators.notEq, label: '≠' },
  { value: SwitchOperators.lt, label: '<' },
  { value: SwitchOperators.le, label: '<=' },
  { value: SwitchOperators.gt, label: '>' },
  { value: SwitchOperators.ge, label: '>=' },
  {
    value: SwitchOperators.in,
    label: '(いずれかに等しい)'
  },
  {
    value: SwitchOperators.not_in,
    label: '(いずれとも等しくない)'
  },
  { value: SwitchOperators.between, label: '' },
  {
    value: SwitchOperators.drop_na,
    label: '(欠損値を含まない)'
  },
  {
    value: SwitchOperators.extract_na,
    label: '(欠損値を含む)'
  },
  {
    value: SwitchOperators.grep,
    label: '(含む)'
  },
  {
    value: SwitchOperators.ungrep,
    label: '(含まない)'
  },
  {
    value: SwitchOperators.starts_with,
    label: '(から始まる)'
  },
  {
    value: SwitchOperators.ends_with,
    label: '(で終わる)'
  },
  {
    value: SwitchOperators.regexp,
    label: '(正規表現にマッチ)'
  }
];

const CommentDatetimeElement = [
  { value: SwitchDatetimeElement.year, label: '(年)' },
  { value: SwitchDatetimeElement.month, label: '(月)' },
  { value: SwitchDatetimeElement.day, label: '(日)' },
  { value: SwitchDatetimeElement.hour, label: '(時)' },
  { value: SwitchDatetimeElement.minute, label: '(分)' },
  { value: SwitchDatetimeElement.second, label: '(秒)' },
  { value: SwitchDatetimeElement.ymd, label: '(年-月-日)' },
  { value: SwitchDatetimeElement.ym, label: '(年-月)' },
  { value: SwitchDatetimeElement.ymdhms, label: '(年-月-日 時:分:秒)' },
  { value: SwitchDatetimeElement.hms, label: '(時:分:秒)' },
  { value: SwitchDatetimeElement.weekday, label: '(曜日)' }
];

export const CommentBetweenOperator = [
  {
    value: SwitchBetweenOperators.left_shift,
    label: '< 値 <'
  },
  {
    value: SwitchBetweenOperators.right_shift_assign,
    label: '≦ 値 <'
  },
  {
    value: SwitchBetweenOperators.left_shift_assign,
    label: '< 値 ≦'
  },
  {
    value: SwitchBetweenOperators.both_shift_assign,
    label: '≦ 値 ≦'
  }
];
