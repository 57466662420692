import * as React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Checkbox as MuiCheckbox, CheckboxProps } from '@material-ui/core';

export const Checkbox = withStyles({
  root: {
    color: '#D47C3E',
    '&$checked': {
      color: '#D47C3E'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);
