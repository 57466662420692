import * as DataForge from 'data-forge';
import {
  Chart,
  FusionChartCrossTableConfigure,
  OrderSetting,
  YValue
} from 'models/chart';
import { nullValueLabel, orderValueName } from './constant';
import { formatXAxisNumber } from './format';

export const sortX = (
  configure: FusionChartCrossTableConfigure,
  dataFrame: DataForge.IDataFrame,
  valueNames: string[]
): DataForge.IDataFrame => {
  const { chart: config } = configure;

  // 横軸orderColumnNameを設定した基準で並び替える
  let orderColumnName = config.x.value;
  let sortedDF: DataForge.IDataFrame;
  switch (config.order.x.target) {
    // y軸に設定した列
    case 'y': {
      const addOrderValue = dataFrame.select((row) =>
        horizontalAggregate(row, valueNames, config.order.x.summarise)
      );
      const sortFunc = (row) => row[orderValueName] ?? -Infinity;
      sortedDF =
        config.order.x.method === 'asc'
          ? addOrderValue.orderBy(sortFunc)
          : addOrderValue.orderByDescending(sortFunc);
      break;
    }
    // 他の列
    case 'specific': {
      orderColumnName = getValueName(config.aggregated, config.order.x.column);

      const sortFunc = (row) =>
        row[orderColumnName] ??
        nullValueByDType(config.order.x.column?.column?.dtype);
      sortedDF =
        config.order.x.method === 'asc'
          ? dataFrame.orderBy(sortFunc)
          : dataFrame.orderByDescending(sortFunc);
      break;
    }
    default: {
      const sortFunc = (row) =>
        row[orderColumnName] ?? nullValueByDType(config.x.dtype);
      sortedDF =
        config.order.x.method === 'asc'
          ? dataFrame.orderBy(sortFunc)
          : dataFrame.orderByDescending(sortFunc);
      break;
    }
  }
  return sortedDF;
};

export const generateCategories = (
  dataFrame: DataForge.IDataFrame,
  configure: FusionChartCrossTableConfigure,
  settings: Chart
) => {
  const { chart: config } = configure;
  return [
    {
      category: dataFrame.toArray().map((row) => {
        return { label: getXAxisDisplayValue(row[config.x.value], settings) };
      })
    }
  ];
};

export const sortLegend = (
  configure: FusionChartCrossTableConfigure,
  dataFrame: DataForge.IDataFrame,
  valueNames: string[]
): string[] => {
  const { chart: config } = configure;

  // 凡例の並べ替え
  let orders = [...valueNames];
  switch (config.order.legend.target) {
    // 縦軸に設定した列
    case 'x': {
      orders.sort((a, b) =>
        a.localeCompare(b, undefined, {
          numeric: true,
          sensitivity: 'base'
        })
      );
      if (config.order.legend.method === 'desc') {
        orders = orders.reverse();
      }
      break;
    }
    default: {
      const summarise = generateSummariseFunc(
        valueNames,
        config.order.legend.summarise
      );

      const selectColumns = (row) => {
        const selected = {};
        valueNames.forEach((name) => {
          selected[name] = row[name];
        });
        return selected;
      };

      const summary = dataFrame
        .select((row) => selectColumns(row))
        .summarize(summarise);
      let summaryArray = Object.keys(summary)
        .map((key) => [key, summary[key]])
        .sort((a, b) => {
          if (a[1] < b[1]) return -1;
          if (a[1] > b[1]) return 1;
          return 0;
        });

      if (config.order.legend.method === 'desc') {
        summaryArray = summaryArray.reverse();
      }
      orders = summaryArray.map((s) => s[0]);
    }
  }
  return orders;
};

// 凡例（y軸に設定した列たち）の並び
export const getYOrderIndexies = (
  orders: string[],
  valueNames: string[]
): number[] => {
  return orders.map((order) => {
    return valueNames.findIndex((v) => v === order);
  });
};

function avg(values: number[]) {
  return sum(values) / values.length;
}

function sum(values: number[]) {
  return values.reduce((sum, current) => {
    if (sum == undefined) {
      return current;
    }

    if (current == undefined) {
      return sum;
    }

    return sum + current;
  });
}

export function horizontalAggregate(
  row: any,
  valueNames: string[],
  func?: string
) {
  const values: number[] = valueNames.map((name) => row[name]);
  switch (func) {
    case 'MIN':
      row[orderValueName] = Math.min(
        ...values.filter((value) => value != undefined)
      );
      return row;
    case 'MAX':
      row[orderValueName] = Math.max(
        ...values.filter((value) => value != undefined)
      );
      return row;
    case 'AVG':
      row[orderValueName] = avg(values);
      return row;
    default:
      row[orderValueName] = sum(valueNames.map((name) => row[name]));
      return row;
  }
}

export function getValueName(aggregated: boolean, value?: YValue): string {
  if (value == undefined || value.column == undefined) {
    return '';
  }

  if (aggregated) {
    return value.column!.value;
  }

  return (value.func || '').toLowerCase() + '_' + value.column.value;
}

export function nullValueByDType(dtype?: string): string | number | null {
  return dtype === 'number' ? -Infinity : null;
}

export function getDisplayValue<T>(v: T): string {
  if (v == undefined) {
    return nullValueLabel;
  }
  return String(v);
}

export function getXAxisDisplayValue<T>(v: T, settings: Chart): string {
  if (v == undefined) {
    return nullValueLabel;
  }
  if ('x' in settings.chart && settings.chart.x?.dtype === 'number') {
    // x軸が数値の場合の表示
    return formatXAxisNumber(v, settings.view);
  }
  return getDisplayValue(v);
}

export function generateSummariseFunc(names: string[], func?: string) {
  const summarise = {};
  names.forEach((name) => {
    switch (func) {
      case 'AVG':
        summarise[name] = seriesAvg;
        break;
      case 'MIN':
        summarise[name] = seriesMin;
        break;
      case 'MAX':
        summarise[name] = seriesMax;
        break;
      default:
        summarise[name] = seriesSum;
        break;
    }
  });
  return summarise;
}

export function seriesSum(series: DataForge.ISeries) {
  return series.aggregate(0, (accumulator, amount) => {
    return accumulator + (amount ?? 0);
  });
}

export function seriesAvg(series: DataForge.ISeries, length?: number) {
  return seriesSum(series) / (length ?? 1);
}

export function seriesMax(series: DataForge.ISeries) {
  return series.aggregate(
    undefined,
    (accumulator: number | undefined, amount: number | undefined) => {
      if (accumulator == undefined) {
        return amount;
      }

      if (amount == undefined) {
        return accumulator;
      }

      return accumulator > amount ? accumulator : amount;
    }
  );
}

export function seriesMin(series: DataForge.ISeries) {
  return series.aggregate(
    undefined,
    (accumulator: number | undefined, amount: number | undefined) => {
      if (accumulator == undefined) {
        return amount;
      }

      if (amount == undefined) {
        return accumulator;
      }

      return accumulator > amount ? amount : accumulator;
    }
  );
}

export function summariseSeries(
  series: DataForge.ISeries,
  categoryLength: number,
  func?: string
) {
  switch (func) {
    case 'MAX':
      return seriesMax(series);
    case 'MIN':
      return seriesMin(series);
    case 'AVG':
      return seriesAvg(series, categoryLength);
    default:
      return seriesSum(series);
  }
}

// x軸の値を列名にして横展開する
export function pivotXValueDF(
  dataFrame: DataForge.IDataFrame,
  xColname: string,
  yColname: string,
  legendColname: string,
  order: OrderSetting,
  xValues: any[],
  y: YValue,
  aggregated: boolean
): DataForge.IDataFrame {
  let wide = dataFrame
    .groupBy((row) => row[legendColname])
    .select((group) =>
      wideTransform(group, legendColname, yColname, xColname, xValues)
    )
    .inflate();
  if (order.legend.target !== 'x') {
    /*
    sortedLegendsDF
    アヤメの花の種類  __order_value__
    setosa         2.3
    versicolor     2
    virginica      2.2
    */
    const sortedLegendsDF = dataFrame
      .groupBy((row) => row[legendColname])
      .select((group) =>
        aggregateByLegend(
          group,
          getValueName(
            aggregated,
            order.legend.target === 'y' ? y : order.legend.column
          ),
          legendColname,
          order.legend.summarise
        )
      )
      .inflate();

    // wideに順番のカラムを結合する
    const names = wide.getColumnNames();
    const mergedColumns = (left, right) => {
      const columns = {};
      names.forEach((name) => {
        columns[name] = left[name];
      });

      columns[orderValueName] = right[orderValueName];
      return columns;
    };
    wide = wide.join(
      sortedLegendsDF,
      (left) => left[legendColname],
      (right) => right[legendColname],
      mergedColumns
    );

    if (order.legend.method === 'asc') {
      // order column is only dtype number
      wide = wide.orderBy((row) => row[orderValueName] ?? -Infinity);
    } else {
      wide = wide.orderByDescending((row) => row[orderValueName] ?? -Infinity);
    }
    wide.dropSeries(orderValueName);
  } else {
    if (order.legend.method === 'asc') {
      wide = wide.orderBy((row) => row[legendColname] ?? -Infinity);
    } else {
      wide = wide.orderByDescending((row) => row[legendColname] ?? -Infinity);
    }
  }
  return wide;
}

function wideTransform(
  group: DataForge.IDataFrame,
  category: string,
  value: string,
  column: string,
  values: string[]
) {
  const wide = {};
  wide[category] = group.first()[category];
  values.forEach((v) => {
    const isNone = group.none((value) => value[column] === v);
    if (isNone) {
      wide[v] = undefined;
    } else {
      const deflate = group
        .where((value) => value[column] === v)
        .deflate((row) => row[value]);
      if (deflate.toArray().length === 0) {
        // 値がすべてnullの場合
        wide[v] = undefined;
      } else {
        wide[v] = deflate.toArray();
      }
    }
  });
  return wide;
}

export function aggregateByLegend(
  group: DataForge.IDataFrame,
  name: string,
  category: string,
  func?: string
) {
  const columns = {};
  columns[category] = group.first()[category];
  switch (func) {
    case 'AVG':
      columns[orderValueName] = group.getSeries(name).average();
      break;
    case 'MIN':
      columns[orderValueName] = group.getSeries(name).min();
      break;
    case 'MAX':
      columns[orderValueName] = group.getSeries(name).max();
      break;
    default:
      columns[orderValueName] = group.getSeries(name).sum();
  }
  return columns;
}
