import { compact, flatten, get } from 'lodash-es';
import { ProcessItem } from 'reducers/processList';
import { produce } from 'immer';
import {
  ObjectFieldValue,
  ArrayFieldValue,
  FormValue,
  SelectFieldValue,
  ColumnSelectV2Value,
  FieldValue,
  SelectTypes
} from 'models/form/value';
import { FieldSchema, FieldTypes, FormSchema } from 'models/form/schema';

let currentFormData: FormValue = {};

export function setCurrentFormData(val: FormValue) {
  currentFormData = val;
}

export function evalExpr(
  expr: string | undefined | null,
  formData: FormValue
): boolean {
  if (expr == undefined) {
    return true;
  }

  const evalTarget = { ...formData, $: currentFormData };

  try {
    // tslint:disable-next-line
    return Function(
      ...Object.keys(evalTarget),
      `return ${expr}`
    )(...Object.values(evalTarget));
  } catch (e) {
    return false;
  }
}

export function getExcludeValues(
  keys: string[] | undefined,
  formData: FormValue | undefined
): string[] {
  if (keys == undefined || formData == undefined) {
    return [];
  }
  const evalTarget = { ...formData, $: currentFormData };
  const values: Array<string | string[]> = compact(
    keys.map((k) => {
      const data = get(evalTarget, k, []) as SelectFieldValue;
      if (Array.isArray(data)) {
        return compact(
          data.map((d) => {
            if (d && d.hasOwnProperty('value')) {
              return d.value.toString();
            }
          })
        );
      }
      if (data && data.hasOwnProperty('value')) {
        return data.value.toString();
      }
    })
  );

  return flatten(values);
}

export function getIncludeValues(
  keys: string[] | undefined,
  formData: FormValue | undefined
): ColumnSelectV2Value {
  if (keys == undefined || formData == undefined) {
    return {
      version: 2,
      rules: [
        {
          type: SelectTypes.column_names,
          exclude: false,
          value: []
        }
      ]
    };
  }
  const evalTarget = { ...formData, $: currentFormData };
  const values: Array<ColumnSelectV2Value> = compact(
    keys.map((k) => {
      const data = get(evalTarget, k, {
        version: 2,
        rules: [
          {
            type: SelectTypes.column_names,
            exclude: false,
            value: []
          }
        ]
      }) as ColumnSelectV2Value;
      return data;
    })
  );
  if (values.length === 0) {
    return {
      version: 2,
      rules: [
        {
          type: SelectTypes.column_names,
          exclude: false,
          value: []
        }
      ]
    };
  }
  return values[0];
}

export function setDefaultProperties(
  properties: FormSchema,
  formData: FormValue
): FormValue {
  if (properties == undefined) {
    return {};
  }

  return produce(formData, (draft) => {
    // このメソッドで、displayExprがfalseと評価されたキーは削除しているが
    // 同じキーのフィールドが複数あった場合、片方がfalseと判定されてしまうと
    // 値が消されるため、キーごとに評価結果をキャッシュする
    const evaluatedDisplayExprCache: { [k: string]: boolean } = {};

    properties.forEach((fieldSchema) => {
      const { key } = fieldSchema;
      const evaluatedDisplayExpr = evalExpr(fieldSchema.displayExpr, draft);
      if (
        evaluatedDisplayExprCache[key] === undefined ||
        !evaluatedDisplayExprCache[key]
      ) {
        evaluatedDisplayExprCache[key] = evaluatedDisplayExpr;
      }

      if (evaluatedDisplayExpr) {
        let value: FieldValue;
        switch (fieldSchema.type) {
          case FieldTypes.object:
            if (draft[key] != undefined) {
              value = setDefaultProperties(
                fieldSchema.properties,
                draft[key] as ObjectFieldValue
              );
            } else {
              value =
                fieldSchema.optional != undefined && fieldSchema.optional
                  ? null
                  : setDefaultProperties(fieldSchema.properties, {});
            }
            break;

          case FieldTypes.array: {
            const values = draft[key] as ArrayFieldValue | null | undefined;
            if (values == undefined) {
              value = [
                setDefaultProperties(fieldSchema.item as FieldSchema[], {})
              ];
            } else {
              value = values.map((v) =>
                setDefaultProperties(fieldSchema.item, v)
              );
            }

            break;
          }

          case FieldTypes.label:
            return;

          default:
            if (draft[key] != undefined) {
              // セレクトボックスの場合選択肢がdisplayExpr次第で変わる場合があるので、選択肢になかった場合はデフォルト値をセットする
              if (
                fieldSchema.type === FieldTypes.select &&
                !fieldSchema.multi &&
                typeof draft[key] === 'string'
              ) {
                if (
                  fieldSchema.items.some((item) => {
                    if (typeof item === 'object') {
                      return item.value === draft[key];
                    } else {
                      return item === draft[key];
                    }
                  })
                ) {
                  return;
                }
              } else {
                return;
              }
            }
            value = getDefaultValue(fieldSchema);
            if (
              fieldSchema.optional != undefined &&
              fieldSchema.defaultValue == undefined
            ) {
              value = null;
            }
        }
        draft[key] = value;
      }
    });

    Object.keys(evaluatedDisplayExprCache).forEach((key) => {
      if (!evaluatedDisplayExprCache[key]) {
        delete draft[key];
      }
    });
  });
}

export function getDefaultValue(schema: FieldSchema) {
  if (schema.defaultValue != undefined) {
    return schema.defaultValue;
  }

  switch (schema.type) {
    case FieldTypes.text:
    case FieldTypes.date:
    case FieldTypes.code_editor:
    case FieldTypes.category_select:
      return '';

    case FieldTypes.select:
    case FieldTypes.column_select:
    case FieldTypes.column_select_v2:
    case FieldTypes.radio:
      return null;

    case FieldTypes.checkbox:
      return false;

    case FieldTypes.object:
      return setDefaultProperties(schema.properties, {});

    case FieldTypes.array:
      return setDefaultProperties(schema.item as FieldSchema[], {});

    default:
      return null;
  }
}

export function traverseModules(items: ProcessItem[]): ProcessItem[] {
  let ret: ProcessItem[] = [];

  items.forEach((m) => {
    if (m.children != undefined) {
      ret = ret.concat(traverseModules(m.children));
    }

    if (m.form) {
      return ret.push(m);
    }
  });

  return ret;
}

// formValueから、lodashのgetっぽい構文で値を取り出す
export function getFormValuesByPath(
  paths: string[][],
  formValues?: FormValue
): PickedFormValue[] {
  if (formValues == undefined) {
    return [];
  }

  const ret: PickedFormValue[] = [];
  paths.forEach((path) => {
    const values = pickValueFromFieldValue(formValues, path);
    ret.push(...values);
  });

  return ret;
}

interface PickedFormValue {
  path: string[];
  value: FieldValue;
}

export function pickValueFromFieldValue(
  value: FieldValue[] | FieldValue | undefined,
  path: string[],
  currentPath: string[] = []
): PickedFormValue[] {
  // path をコピーしておく
  const pc = [...path];

  if (value == undefined) {
    return [];
  }
  const p1 = pc.shift();
  if (p1 == undefined) {
    // undefinedのときは最後まで取りきっているのでそのまま返す
    return [{ path: currentPath, value: value as FieldValue }];
  }

  const values: PickedFormValue[] = [];
  if (p1 === '$') {
    // p1が$のとき、valueは必ず配列
    if (!Array.isArray(value)) {
      return [];
    }
    (value as ArrayFieldValue).forEach((v, index) => {
      const ret = pickValueFromFieldValue(
        v,
        pc,
        currentPath.concat([index.toString()])
      );
      return values.push(...ret);
    });
    return values;
  } else {
    return pickValueFromFieldValue(value[p1], pc, currentPath.concat([p1]));
  }
}

export function diffSet<T>(a: Set<T>, b: Set<T>): Set<T> {
  const difference = new Set(a);
  for (const elem of b) {
    difference.delete(elem);
  }
  return difference;
}

export function unionSet<T>(a: Set<T>, b: Set<T>): Set<T> {
  const union = new Set(a);
  for (const elem of b) {
    union.add(elem);
  }
  return union;
}
