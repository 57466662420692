import * as React from 'react';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import { CheckCircle, RadioButtonUnchecked } from '@material-ui/icons';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';

import { projectTheme } from '../../theme';
import { Button } from 'components/ui/common/button';
import {
  getExportNodes,
  getProjects,
  getReports,
  getUserDatasources
} from 'libs/api';
import ListView from 'components/ui/listView';
import {
  NodeItem,
  loadRelationshipFunc,
  Relationships
} from 'components/relationships/relationships';
import { DetailPane } from 'components/relationships/pane';
import { AccessLevel } from 'libs/accessLevel';
import { uniq } from 'lodash-es';
import { Dialog } from 'components/ui/common/dialog';

const UnSelectableTypes = ['builder', 'report', 'export'];
const UnSelectableDatasourceTypes = [
  'csv',
  'csv_v2',
  'csv_v3',
  'nehan_storage'
];
const UnCheckableDatasourceTypes = [
  ...UnSelectableDatasourceTypes,
  'nehan_internal'
];

const SelectMap = {
  project: { type: 'selector_project', func: getProjects },
  datasource: { type: 'selector_datasource', func: getUserDatasources },
  report: { type: 'selector_report', func: getReports },
  export: { type: 'selector_export', func: getExportNodes },
  manual: {
    type: 'selector_manual',
    func: () => {}
  },
  manual_project: { type: 'selector_project', func: getProjects },
  manual_datasource: {
    type: 'selector_datasource',
    func: getUserDatasources
  }
};

const SelectList: React.FC<{
  type: keyof typeof SelectMap;
  folderId: string;
  onChangeFolder: (string) => void;
  onSelectItem: (id: string) => void;
  onChangeChecked: (ids: string[]) => void;
}> = ({ type, folderId, onChangeFolder, onSelectItem, onChangeChecked }) => {
  const { type: listType, func } = SelectMap[type];
  const selectFunc = (item) => {
    if (type === 'export') {
      return `${item.project_uuid}:${item.uuid}`;
    }
    return item.uuid;
  };

  return (
    <ThemeProvider theme={projectTheme}>
      <ListView
        type={listType}
        load={func}
        onClickItem={(item) => {
          if (item.is_folder) {
            onChangeFolder(item.uuid);
          } else {
            onSelectItem(selectFunc(item));
          }
        }}
        folderId={folderId}
        onClickFolder={onChangeFolder}
        showDetailPane={false}
        supportFolder={true}
        disableCheck={!type.startsWith('manual')}
        disableItemClick={true}
        onChangeCheckedIds={onChangeChecked}
        disableItem={(item) => item.access_level < AccessLevel.Developer}
      />
    </ThemeProvider>
  );
};

const dialogStyles = makeStyles(() => ({
  paper: {
    maxHeight: 830
  },
  content: {
    display: 'flex',
    padding: 0,
    height: 'calc(830px - 58px)',
    position: 'relative'
  },
  contentInitial: {
    display: 'flex',
    padding: 0,
    position: 'relative'
  },
  selectButtons: {
    position: 'absolute',
    top: '15px',
    left: '15px',
    zIndex: 1,
    display: 'flex',
    gap: '12px'
  },
  relationship: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    height: '100%'
  }
}));

const selectorStyles = makeStyles(() => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 16,
    padding: 32,
    alignItems: 'center'
  },
  button: {
    width: 200
  }
}));

const TypeSelector: React.VFC<{
  onSelectType: (type: keyof typeof SelectMap) => void;
}> = ({ onSelectType }) => {
  const classes = selectorStyles();
  return (
    <div className={classes.content}>
      <Typography>
        更新したいものを1つ選んでください
        <br />
        それに関連するコンテンツを系譜上で一括登録できます
      </Typography>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('project')}
      >
        分析プロジェクト
      </Button>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('datasource')}
      >
        データソース
      </Button>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('report')}
      >
        ダッシュボード
      </Button>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('export')}
      >
        データエクスポート
      </Button>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('manual')}
      >
        系譜を無視して登録する
      </Button>
    </div>
  );
};

const ManualTypeSelector: React.VFC<{
  onSelectType: (type: keyof typeof SelectMap) => void;
}> = ({ onSelectType }) => {
  const classes = selectorStyles();
  return (
    <div className={classes.content}>
      <Typography>スケジュール登録する対象を選択してください</Typography>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('manual_project')}
      >
        分析プロジェクト
      </Button>
      <Button
        className={classes.button}
        color="common_color"
        onClick={() => onSelectType('manual_datasource')}
      >
        データソース
      </Button>
    </div>
  );
};

function selectAllItems(items: NodeItem[]): string[] {
  const ret: string[] = [];
  items.forEach((item) => {
    if (item.type === 'project') {
      const checkable = item.data.access_level >= AccessLevel.Developer;
      if (checkable) {
        ret.push(item.id);
        if (item.data.workflows) {
          item.data.workflows.forEach((workflow) => {
            ret.push(workflow.id);
          });
        }
      }
    }
    if (item.type === 'datasource') {
      const checkable = item.data.access_level >= AccessLevel.Developer;
      if (
        checkable &&
        !UnSelectableDatasourceTypes.includes(item.data.datasource_type)
      ) {
        ret.push(item.id);
      }
    }
    if (item.type === 'report') {
      ret.push(item.id);
    }
    if (item.type === 'export') {
      ret.push(item.id);
    }
  });
  return ret;
}

function isExportItem(item: NodeItem | undefined): boolean {
  if (!item) {
    return false;
  }
  if (item.id.startsWith('export_')) {
    return true;
  }
  if (item.id.startsWith('report_')) {
    return true;
  }
  if (item.id.includes('_node_')) {
    return true;
  }
  if (
    item.id.startsWith('datasource_') &&
    item.data?.datasource_type === 'nehan_internal'
  ) {
    return true;
  }
  return false;
}

// 動的な選択が行われるノード
// エクスポート：元ワークフローが更新対象に入れば、自動で選択状態になる
// ダッシュボード：元ワークフローが一つでも更新対象に入れば、自動で選択状態になる
// チェインデータソース：元ワークフローが更新対象に入れば、自動で選択状態になる
function selectExportedItems(_checked: string[], _items: NodeItem[]): string[] {
  const items: Record<string, NodeItem> = {};
  _items.forEach((i) => {
    items[i.id] = i;
  });
  const ret: string[] = _checked.filter((id) => !isExportItem(items[id]));
  _items.forEach((item) => {
    if (!item.parent_workflows) {
      return;
    }
    item.parent_workflows.forEach((id) => {
      if (_checked.includes(id)) {
        ret.push(item.id);
        return;
      }
    });
  });

  return ret;
}

function selectAllWorkflow(
  allItems: NodeItem[],
  current: string[],
  projectId: string
): string[] {
  const ret: string[] = [...current, projectId];
  allItems.forEach((item) => {
    if (item.type !== 'project') {
      return;
    }
    if (item.id === projectId) {
      if (item.data.workflows) {
        item.data.workflows.forEach((workflow) => {
          ret.push(workflow.id);
        });
      }
    }
  });

  return [...new Set(ret)];
}

function excludeAllWorkflow(
  allItems: NodeItem[],
  current: string[],
  projectId: string
): string[] {
  let workflows: string[] = [];
  allItems.forEach((item) => {
    if (item.type !== 'project') {
      return;
    }
    if (item.id === projectId) {
      if (item.data.workflows) {
        workflows = item.data.workflows.map((workflow) => workflow.id);
      }
    }
  });
  return current
    .filter((id) => !workflows.includes(id))
    .filter((id) => id !== projectId);
}

export const SelectDialog: React.FC<{
  initialStep?: 0 | 1 | 2 | 3;
  open: boolean;
  onClose: (
    type: keyof typeof SelectMap | null,
    id: string | string[] | null,
    ids: string[]
  ) => void;
  closeText: string;
  initialType?: string;
  initialId?: string;
  initialWorkflowProjectId?: string;
  initialSelected?: string[];
  initialHighlight?: string;
}> = ({
  initialStep,
  open,
  onClose,
  closeText,
  initialId,
  initialWorkflowProjectId,
  initialSelected,
  initialType,
  initialHighlight
}) => {
  const classes = dialogStyles();
  const initialize = React.useRef(false);
  const [folderId, setFolderId] = React.useState('');
  const [itemId, setItemId] = React.useState<string | null>(initialId ?? null);
  const [step, setStep] = React.useState<0 | 1 | 2 | 3>(initialStep ?? 0);
  const [type, setType] = React.useState<keyof typeof SelectMap | null>(() => {
    if (initialType == undefined) {
      return null;
    }
    if (initialType.startsWith('manual')) {
      return 'manual';
    }
    return initialType as keyof typeof SelectMap;
  });
  const [highlight, setHighlight] = React.useState(initialHighlight ?? '');
  const [workflowProjectId, setWorkflowProjectId] = React.useState(
    initialWorkflowProjectId
  );
  const [checkedItems, setCheckedItems] = React.useState<string[]>(
    initialSelected ?? []
  );
  const [selectedItem, setSelectedItem] = React.useState<NodeItem | null>(null);
  const [items, setItems] = React.useState<NodeItem[]>([]);
  const [manualCheckedItems, setManualCheckedItems] = React.useState<string[]>(
    []
  );
  const onLoad = React.useCallback(
    (_, items: NodeItem[]) => {
      setItems(items);
      if (!initialize.current) {
        let checked = checkedItems;
        setCheckedItems(selectExportedItems(checked, items));
      }
    },
    [checkedItems]
  );
  React.useEffect(() => {
    // ワークフローちょくの場合は事前にデータをロードする
    if (initialStep !== 3) {
      return;
    }
    if (type && itemId) {
      const f = async () => {
        const id = type === 'export' ? itemId.split(':')[1] : itemId;
        const project_id = type === 'export' ? itemId.split(':')[0] : undefined;
        const { data } = await loadRelationshipFunc(type)(
          id,
          project_id as any
        );
        const items = data?.nodes || [];
        setItems(items);
        // プロジェクトのアイテムを選択
        const item = items.find((item) => item.id === workflowProjectId);
        console.log('setSelectedItem');
        setSelectedItem(item);
        setCheckedItems(selectExportedItems(checkedItems, items));
      };
      f();
    }
  }, []);
  const onChangeChecked = React.useCallback(
    (id: string, checked: boolean) => {
      // SQLビルダーは選択できない
      const item = items.find((item) => item.id === id);
      if (UnSelectableTypes.includes(item?.type ?? '')) {
        return;
      }

      // 特定のデータソースは選択できない
      if (
        UnCheckableDatasourceTypes.includes(item?.data.datasource_type ?? '')
      ) {
        return;
      }

      // 変化したのが、プロジェクトの場合
      if (id.includes('-')) {
        const project = items.find((item) => item.id === id);
        const workflows = project?.data.workflows.map((w) => w.id) ?? [];
        if (checked) {
          setCheckedItems(
            selectExportedItems([...checkedItems, id, ...workflows], items)
          );
        } else {
          setCheckedItems(
            selectExportedItems(
              checkedItems
                .filter((item) => !workflows.includes(item))
                .filter((item) => item !== id),
              items
            )
          );
        }
        return;
      }

      // 変化したのが、ワークフローの場合
      if (id.includes('workflow_')) {
        const project = items.find((item) =>
          (item.data.workflows?.map((wi) => wi.id) ?? []).includes(id)
        );
        const workflows = project?.data.workflows.map((w) => w.id) ?? [];
        // 現在のアイテムから、プロジェクトとワークフローを除外
        const newCheckedItems = checkedItems.filter(
          (item) => !workflows.includes(item) && item !== id
        );
        let newCheckedWorkflows = workflows.filter((id) =>
          checkedItems.includes(id)
        );
        if (checked) {
          newCheckedWorkflows = [...checkedItems, id];
        } else {
          newCheckedWorkflows = newCheckedWorkflows.filter(
            (item) => item !== id
          );
        }

        if (newCheckedWorkflows.length > 0) {
          const arr = [
            ...newCheckedItems,
            ...newCheckedWorkflows,
            project?.id
          ].filter((item) => item != undefined) as string[];
          setCheckedItems(selectExportedItems([...new Set(arr)], items));
        } else {
          setCheckedItems(
            selectExportedItems(
              checkedItems
                .filter((item) => item !== id)
                .filter((item) => item !== project?.id),
              items
            )
          );
        }
        return;
      }

      if (checked) {
        setCheckedItems(selectExportedItems([...checkedItems, id], items));
      } else {
        setCheckedItems(
          selectExportedItems(
            checkedItems.filter((item) => item !== id),
            items
          )
        );
      }
    },
    [checkedItems, items]
  );

  React.useEffect(() => {
    if (!open) {
      setItemId(null);
    }
  }, [open]);

  if (!open) {
    return null;
  }

  return (
    <Dialog
      classes={{ paper: classes.paper }}
      maxWidth={step === 0 || type === 'manual' ? 'md' : 'xl'}
      fullWidth={!(step === 0 || type === 'manual')}
      open={open}
      title={step === 3 ? 'ワークフローの選択' : '更新対象を選択して下さい'}
      onClickBack={
        step === 1 || step === 2
          ? () => {
              if (step === 1) {
                setType(null);
                setStep(0);
                setHighlight('');
                setFolderId('');
              }
              if (step === 2) {
                if (type?.startsWith('manual')) {
                  setType('manual');
                }
                setItemId(null);
                setStep(1);
                setHighlight('');
                setFolderId('');
              }
            }
          : undefined
      }
      onClose={() => onClose(null, null, [])}
      contentProps={{
        classes: {
          root: clsx(
            step === 0 || type === 'manual'
              ? classes.contentInitial
              : classes.content
          )
        }
      }}
      hideFooter={step === 0 || step === 1}
      OKButton={{
        label: step === 3 ? '更新対象をセットする' : closeText,
        onClick: () => {
          if (step === 2) {
            if (type!.startsWith('manual')) {
              onClose(type, manualCheckedItems, []);
            } else {
              onClose(type, itemId, checkedItems);
            }
            return;
          }
          if (step === 3) {
            setStep(2);
            setHighlight('');
            return;
          }
        },
        disabled:
          step === 2
            ? (type?.startsWith('manual') && manualCheckedItems.length === 0) ||
              (!type?.startsWith('manual') && checkedItems.length === 0)
            : false
      }}
    >
      {step === 2 && !type?.startsWith('manual_') && (
        <div className={classes.selectButtons}>
          <Button
            color="common_color"
            onClick={() => setCheckedItems(selectAllItems(items))}
          >
            <CheckCircle />
            &nbsp; 全選択
          </Button>
          <Button color="cancel" onClick={() => setCheckedItems([])}>
            <RadioButtonUnchecked />
            &nbsp; 選択解除
          </Button>
        </div>
      )}
      {step === 3 && (
        <div className={classes.selectButtons}>
          <Button
            color="common_color"
            onClick={() =>
              setCheckedItems(
                selectAllWorkflow(items, checkedItems, selectedItem!.id)
              )
            }
          >
            <CheckCircle />
            &nbsp; 全選択
          </Button>
          <Button
            color="cancel"
            onClick={() =>
              setCheckedItems(
                excludeAllWorkflow(items, checkedItems, selectedItem!.id)
              )
            }
          >
            <RadioButtonUnchecked />
            &nbsp; 選択解除
          </Button>
        </div>
      )}
      {step === 0 && type == undefined && (
        <TypeSelector
          onSelectType={(type) => {
            setType(type);
            setStep(1);
            setHighlight('');
          }}
        />
      )}
      {step === 1 && type && type === 'manual' && (
        <ManualTypeSelector
          onSelectType={(type) => {
            setType(type);
            setStep(2);
            setHighlight('');
          }}
        />
      )}
      {step === 1 && type && type !== 'manual' && (
        <SelectList
          type={type}
          onSelectItem={(id) => {
            setItemId(id);
            setStep(2);
            setHighlight('');
          }}
          folderId={folderId}
          onChangeFolder={(id) => {
            setFolderId(id);
            setItemId(null);
          }}
          onChangeChecked={setManualCheckedItems}
        />
      )}
      {step === 2 && type && type.startsWith('manual_') && (
        <SelectList
          type={type}
          onSelectItem={() => {}}
          folderId={folderId}
          onChangeFolder={(id) => {
            setFolderId(id);
            setItemId(null);
          }}
          onChangeChecked={setManualCheckedItems}
        />
      )}
      {step === 2 && type && !type.startsWith('manual') && itemId && (
        <>
          <div className={classes.relationship}>
            <Relationships
              id={type === 'export' ? itemId.split(':')[1] : itemId}
              project_id={type === 'export' ? itemId.split(':')[0] : undefined}
              type={type}
              checkedItems={checkedItems}
              disableDelete={true}
              onChangeChecked={onChangeChecked}
              onClickItem={setSelectedItem}
              initial_highlight_id={highlight}
              checkableAccessLevel={AccessLevel.Developer}
              onTargetChange={(target) => {
                let id =
                  target.type === 'export'
                    ? `${target.project_id}:${target.id}`
                    : target.id;
                if (target.type !== type || id !== itemId) {
                  if (
                    window.confirm(
                      '更新対象の選択がリセットされますが切り替えますか？'
                    )
                  ) {
                    setItemId(id);
                    setType(target.type as keyof typeof SelectMap);
                    setCheckedItems([]);
                    setItems([]);
                    setHighlight('');
                    initialize.current = false;
                  }
                }
              }}
              onLoad={onLoad}
            />
          </div>
          <DetailPane
            type={selectedItem?.type}
            item={selectedItem}
            showWorkflowSelector={true}
            checkedWorkflows={checkedItems}
            onWorkflowSelect={onChangeChecked}
            onClickWorkflowMode={() => {
              setWorkflowProjectId(selectedItem?.id || '');
              setStep(3);
            }}
            onClose={() => setSelectedItem(null)}
          />
        </>
      )}
      {step === 3 && selectedItem && (
        <div className={classes.relationship}>
          <Relationships
            id={selectedItem.id}
            type="workflow"
            checkedItems={checkedItems}
            disableDelete={true}
            initial_highlight_id={highlight}
            onChangeChecked={(id, checked, _item) => {
              let newChecked = [...checkedItems];
              if (checked) {
                newChecked.push(id);
              } else {
                newChecked = newChecked.filter((i) => i !== id);
              }
              const ci = items.filter((i) => newChecked.includes(i.id));
              // このプロジェクトのワークフローのみ選択状態を変える
              // 今の選択状態から、プロジェクトのワークフローを除外する
              const project = items.find((item) => item.id === selectedItem.id);
              const workflows = project?.data.workflows.map((w) => w.id) ?? [];
              newChecked = checkedItems.filter((id) => !workflows.includes(id));
              // ワークフローだけ選別して追加
              const newWorkflows = ci
                .filter((item) => item.id.startsWith('workflow_'))
                .map((item) => item.id);
              // プロジェクトを追加
              project && newChecked.push(project.id);
              newChecked = uniq(newChecked);
              if (newWorkflows.length > 0) {
                setCheckedItems(
                  selectExportedItems([...newChecked, ...newWorkflows], items)
                );
              } else {
                // プロジェクトも外す
                setCheckedItems(
                  selectExportedItems(
                    newChecked.filter((id) => id !== selectedItem.id),
                    items
                  )
                );
              }
            }}
          />
        </div>
      )}
    </Dialog>
  );
};
