import { AccessLevel } from 'libs/accessLevel';
import { cloneNodes, setPortLabel } from 'reducers/project';
import ActionTypes from 'actions/actionTypes';
import builderGraph from 'reducers/builderGraph';
import { EdgeStatus, NodeStatus, PortStatus } from 'models/graph';
import { produce } from 'immer';
import { intersection } from 'lodash-es';
import { BuilderNodeStatusUpdate } from 'models/websocket';
import {
  findWorkflowByPortId,
  findWorkflowIndex,
  getChildrenNodes
} from 'components/diagram/utils';
import { reorderByOrderNumber } from 'libs/tab';
import {
  BuilderWorkflow,
  ExecuteStatus,
  ProjectSaveStatus,
  Variable
} from 'models/project';
import { cutArray, UndoType } from 'reducers/undo';
import { FormParams } from 'components/datasource/form/formV3';

export interface BuilderState {
  loading: boolean;
  loaded: boolean;
  saveStatus: ProjectSaveStatus;
  uuid: string;
  name: string;
  connectionType: string;
  version?: number;
  workflows: BuilderWorkflow[];
  currentWorkflowIndex: number;
  accessLevel: AccessLevel;
  error?: string;
  copyNodeIds: string[];
  copySourceWorkflowId: string | number | null;
  variables: Variable[];
  connectionUuid: string;
  params?: FormParams;
}

const initialState: BuilderState = {
  loading: false,
  loaded: false,
  saveStatus: ProjectSaveStatus.COMPLETED,
  uuid: '',
  name: '',
  connectionType: '',
  workflows: [],
  currentWorkflowIndex: 0,
  accessLevel: 0,
  error: undefined,
  copyNodeIds: [],
  copySourceWorkflowId: null,
  variables: [],
  connectionUuid: '',
  params: undefined
};

function pasteNodes(state: BuilderState): BuilderState {
  const { currentWorkflowIndex: widx, workflows, copyNodeIds: nodeIds } = state;
  const workflowIndex = workflows.findIndex((wf) => {
    return intersection(Object.keys(wf.graph.nodes), nodeIds).length > 0;
  });

  if (workflowIndex < 0) {
    return state;
  }

  const sourceWF = workflows[workflowIndex];
  const { graph } = sourceWF;
  const { nodes, edges, ports } = cloneNodes(nodeIds, graph);

  return produce(state, (draft) => {
    draft.workflows[widx].graph.nodes = {
      ...workflows[widx].graph.nodes,
      ...nodes
    };

    draft.workflows[widx].graph.edges = {
      ...workflows[widx].graph.edges,
      ...edges
    };
    draft.workflows[widx].graph.ports = {
      ...workflows[widx].graph.ports,
      ...ports
    };
    draft.copyNodeIds = [];
    draft.workflows[widx].checkedIds = Object.keys(nodes);
    draft.workflows[widx].graph.undoList.push({
      type: UndoType.CREATE_NODE_EDGE,
      params: {
        nodes: Object.keys(nodes)
      }
    });
    draft.workflows[widx].graph.undoList = cutArray(
      draft.workflows[widx].graph.undoList
    );
    draft.workflows[widx].graph.redoList = [];
  });
}

function updatePortStatus(
  workflows: BuilderWorkflow[],
  payload: { portId: string; status: PortStatus }
) {
  const { portId, status } = payload;
  return produce(workflows, (draft) => {
    const workflowIndex = findWorkflowByPortId(portId, draft);
    if (draft[workflowIndex]) {
      draft[workflowIndex].graph.ports[portId].status = status;
    }
  });
}

function updatePortsStatus(
  workflows: BuilderWorkflow[],
  payload: { portIds: string[]; status: PortStatus }
) {
  const { portIds, status } = payload;
  return produce(workflows, (draft) => {
    portIds.forEach((portId) => {
      const workflowIndex = findWorkflowByPortId(portId, draft);
      if (draft[workflowIndex]) {
        draft[workflowIndex].graph.ports[portId].status = status;
      }
    });
  });
}

function selectWorkflowNode(
  state: BuilderState,
  nodeId: string
): Partial<BuilderState> {
  const workflowIndex = findWorkflowIndex(state.workflows, nodeId);
  if (workflowIndex === -1) {
    return {};
  }

  return produce(state, (draft) => {
    draft.currentWorkflowIndex = workflowIndex;
    draft.workflows[workflowIndex].graph.selectedNodeId = nodeId;
  });
}

export default function builderProject(
  state: BuilderState = initialState,
  action
): BuilderState {
  switch (action.type) {
    case ActionTypes.SavingBuilder:
      return { ...state, saveStatus: ProjectSaveStatus.IN_PROGRESS };
    case ActionTypes.SaveBuilderSuccess:
      return { ...state, saveStatus: ProjectSaveStatus.COMPLETED };
    case ActionTypes.SaveBuilderFail:
      return { ...state, saveStatus: ProjectSaveStatus.ERROR };
    case ActionTypes.LoadBuilder: {
      if (action.error) {
        return {
          ...state,
          loading: false,
          loaded: true,
          error: action.payload
        };
      }
      const {
        data: {
          name,
          uuid,
          workflows,
          current_workflow_index,
          connection_type,
          access_level,
          variables,
          connection_uuid,
          params
        },
        modules
      } = action.payload;

      if (modules.length > 0) {
        workflows.forEach((wf) => {
          setPortLabel(wf, modules);
          wf.checkedIds = [];
          wf.graph.undoList = [];
          wf.graph.redoList = [];
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        uuid,
        name,
        workflows,
        currentWorkflowIndex: current_workflow_index,
        connectionType: connection_type,
        accessLevel: access_level,
        variables: variables || [],
        connectionUuid: connection_uuid,
        params
      };
    }

    case ActionTypes.BuilderStatusUpdate:
      return produce(state, (draft) => {
        action.payload.status.forEach((s) => {
          const idx = draft.workflows.findIndex((w) => w.uuid === s.id);
          if (idx === -1) {
            return;
          }
          draft.workflows[idx].status = s.status;
        });
      });

    case ActionTypes.BuilderDataFetchStart:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Loading
        })
      };

    case ActionTypes.BuilderDataFetchStartMulti:
      return {
        ...state,
        workflows: updatePortsStatus(state.workflows, {
          portIds: action.payload.portIds,
          status: PortStatus.Loading
        })
      };

    case ActionTypes.BuilderDataFetchSuccess:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Loaded
        })
      };
    case ActionTypes.BuilderDataFetchFailure:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Error
        })
      };

    case ActionTypes.BuilderDataFetchFailureMulti:
      return {
        ...state,
        workflows: updatePortsStatus(state.workflows, {
          portIds: action.payload.portIds,
          status: PortStatus.Error
        })
      };

    case ActionTypes.CopyBuilderNodes:
      return produce(state, (draft) => {
        draft.copyNodeIds = [...action.payload.nodeIds];
        draft.copySourceWorkflowId = action.payload.workflowId;
      });

    case ActionTypes.PasteBuilderNodes:
      return pasteNodes(state);

    case ActionTypes.UpdateBuilderNodeStatus: {
      const resp: BuilderNodeStatusUpdate = action.payload;
      return produce(state, (draft) => {
        const idx = draft.workflows.findIndex(
          (w) => w.uuid === resp.workflow_id
        );
        if (idx !== -1) {
          resp.status.forEach((s) => {
            const node = draft.workflows[idx].graph.nodes[s.node_id];
            if (node) {
              node.status = s.status;
              node.errorMessage = s.errorMessage;
              node.execution_id = s.execution_id;
              node.formValues = s.formValues;
              node.autoComment = s.autoComment;
              node.started_at = s.started_at;
              node.ended_at = s.ended_at;
            }
          });
        }
      });
    }

    case ActionTypes.SelectBuilderWorkflow:
      return { ...state, currentWorkflowIndex: action.payload.index };

    case ActionTypes.AddBuilderWorkflow: {
      const {
        data: { workflow: wf, current_workflow_index },
        modules
      } = action.payload;
      const labeledWorkflow = setPortLabel(wf, modules) as BuilderWorkflow;
      labeledWorkflow.checkedIds = [];
      labeledWorkflow.graph.undoList = [];
      labeledWorkflow.graph.redoList = [];
      return {
        ...state,
        currentWorkflowIndex: current_workflow_index,
        workflows: [...state.workflows, labeledWorkflow]
      };
    }

    case ActionTypes.DeleteBuilderWorkflow: {
      const { index, data } = action.payload;
      return {
        ...state,
        currentWorkflowIndex: data.current_workflow_index,
        workflows: [
          ...state.workflows.slice(0, index),
          ...state.workflows.slice(index + 1)
        ]
      };
    }

    case ActionTypes.RenameBuilderWorkflow: {
      const w = state.workflows[action.payload.index];
      if (w == undefined) {
        return state;
      }
      return {
        ...state,
        workflows: [
          ...state.workflows.slice(0, action.payload.index),
          { ...w, name: action.payload.name },
          ...state.workflows.slice(action.payload.index + 1)
        ]
      };
    }

    case ActionTypes.MoveBuilderWorkflow: {
      const { index: idx, targetIndex } = action.payload;
      const { workflows } = state;

      if (targetIndex < 0 || workflows.length <= targetIndex) {
        return state;
      }

      const newWorkflows = reorderByOrderNumber(workflows, idx, targetIndex);

      return {
        ...state,
        currentWorkflowIndex: targetIndex,
        workflows: newWorkflows
      };
    }

    case ActionTypes.SelectBuilderWorkflowNode:
      return { ...state, ...selectWorkflowNode(state, action.payload.nodeId) };

    case ActionTypes.StartBuilderExecute: {
      const { selectedNodeId } = action.payload;
      return produce(state, (draft) => {
        draft.workflows[state.currentWorkflowIndex].status =
          ExecuteStatus.EXECUTING;
        const { nodes, edges } =
          state.workflows[state.currentWorkflowIndex].graph;
        const nodeIds = selectedNodeId
          ? getChildrenNodes(selectedNodeId, nodes, edges, true)
          : Object.keys(nodes);
        nodeIds.unshift(selectedNodeId);
        nodeIds.forEach((nodeId) => {
          const node =
            draft.workflows[state.currentWorkflowIndex].graph.nodes[nodeId];
          if (node) {
            node.status = NodeStatus.Executing;
            node.inPorts.forEach((pid) => {
              draft.workflows[state.currentWorkflowIndex].graph.ports[
                pid
              ].status = PortStatus.Created;
            });
            node.outPorts.forEach((pid) => {
              draft.workflows[state.currentWorkflowIndex].graph.ports[
                pid
              ].status = PortStatus.Created;
            });
            Object.keys(edges).forEach((eid) => {
              if (edges[eid].to.nodeId === node.id) {
                draft.workflows[state.currentWorkflowIndex].graph.edges[
                  eid
                ].status = EdgeStatus.Created;
              }
            });
          }
        });
      });
    }

    case ActionTypes.UpdateCheckedBuilderNodes:
      return produce(state, (draft) => {
        draft.workflows[draft.currentWorkflowIndex].checkedIds =
          action.payload.nodeIds;
      });

    case ActionTypes.UpdateBuilderNodeChecked:
      return produce(state, (draft) => {
        if (action.payload.checked) {
          draft.workflows[state.currentWorkflowIndex].checkedIds.push(
            action.payload.nodeId
          );
        } else {
          draft.workflows[state.currentWorkflowIndex].checkedIds =
            draft.workflows[state.currentWorkflowIndex].checkedIds.filter(
              (id) => id !== action.payload.nodeId
            );
        }
      });

    case ActionTypes.UpdateBuilderPositions:
      return produce(state, (draft) => {
        const workflow = draft.workflows.find(
          (wf) => wf.uuid === action.payload.workflowId
        );
        if (workflow && workflow.graph) {
          workflow.graph.positions = {
            ...workflow.graph.positions,
            ...action.payload.positions
          };
        }
      });

    case ActionTypes.InitializeBuilder:
      return produce(state, (draft) => {
        draft = { ...initialState };
        return draft;
      });

    case ActionTypes.AddBuilderVariable:
      return produce(state, (draft) => {
        draft.variables.push(action.payload.variable);
        return draft;
      });

    case ActionTypes.EditBuilderVariable: {
      return produce(state, (draft) => {
        draft.variables[action.payload.index] = action.payload.variable;
        return draft;
      });
    }

    case ActionTypes.DeleteBuilderVariable: {
      return produce(state, (draft) => {
        draft.variables.splice(action.payload.index, 1);
        return draft;
      });
    }

    default: {
      if (state.workflows.length === 0) {
        return state;
      }

      if (state.workflows[state.currentWorkflowIndex] == undefined) {
        return state;
      }

      const workflow = state.workflows[state.currentWorkflowIndex];
      const newGraph = builderGraph(workflow.graph, action);
      if (workflow.graph === newGraph) {
        return state;
      }

      return {
        ...state,
        workflows: [
          ...state.workflows.slice(0, state.currentWorkflowIndex),
          { ...workflow, graph: newGraph },
          ...state.workflows.slice(state.currentWorkflowIndex + 1)
        ]
      };
    }
  }
}
