import { RangeDateParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { DateRangeValues } from 'models/report/parameter';

export class DateRangeClass implements TimeParameterClass {
  constructor(public param: RangeDateParameter) {}
  getRelativeItems = (): {
    label: string;
    value: DateRangeValues['select'];
  }[] => {
    return [
      { label: '今週', value: 'this_week' },
      { label: '先週', value: 'last_week' },
      { label: '今月', value: 'this_month' },
      { label: '先月', value: 'last_month' },
      { label: '今年', value: 'this_year' },
      { label: '去年', value: 'last_year' }
    ];
  };
  isValidStartDate = () => {
    return dayjs(this.param.values.inputValue.startDate).isValid();
  };
  isValidEndDate = () => {
    return dayjs(this.param.values.inputValue.endDate).isValid();
  };
  isValid = () => {
    return this.isValidStartDate() && this.isValidEndDate();
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      const { startDate, endDate } = this.calculateInputValue();
      // 具体的な日付
      return `${dayjs(startDate).format('YYYY年MM月DD日')} ~ ${dayjs(
        endDate
      ).format('YYYY年MM月DD日')}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  calculateInputValue = (): DateRangeValues['inputValue'] => {
    const { select, inputValue, inputPastValue, includeToday } =
      this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        const end = includeToday ? dayjs() : dayjs().subtract(1, 'day');
        return {
          startDate: dayjs()
            .subtract(Number(inputPastValue.value || 0), inputPastValue.unit)
            .format('YYYY-MM-DD'),
          endDate: end.format('YYYY-MM-DD')
        };
      }
      case 'this_week':
        return {
          startDate: dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD')
        };
      case 'last_week':
        return {
          startDate: dayjs()
            .subtract(1, 'week')
            .startOf('week')
            .add(1, 'day')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .subtract(1, 'week')
            .endOf('week')
            .add(1, 'day')
            .format('YYYY-MM-DD')
        };
      case 'this_month':
        return {
          startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('month').format('YYYY-MM-DD')
        };
      case 'last_month':
        return {
          startDate: dayjs()
            .subtract(1, 'month')
            .startOf('month')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .subtract(1, 'month')
            .endOf('month')
            .format('YYYY-MM-DD')
        };
      case 'this_year':
        return {
          startDate: dayjs().startOf('year').format('YYYY-MM-DD'),
          endDate: dayjs().endOf('year').format('YYYY-MM-DD')
        };
      case 'last_year':
        return {
          startDate: dayjs()
            .subtract(1, 'year')
            .startOf('year')
            .format('YYYY-MM-DD'),
          endDate: dayjs()
            .subtract(1, 'year')
            .endOf('year')
            .format('YYYY-MM-DD')
        };
      default:
        const ex: never = select;
        return ex;
    }
  };
}
