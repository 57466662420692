import dayjs from 'dayjs';
import {
  DateRangeValues,
  DateSingleValues,
  DatetimeRangeValues,
  DatetimeSingleValues,
  TimeRangeValues,
  TimeSingleValues
} from 'models/report/parameter';

export const dateSingleInitialValues: DateSingleValues = {
  version: 2,
  select: 'today',
  inputPastValue: {
    value: '30',
    unit: 'day'
  },
  inputValue: {
    date: dayjs().format('YYYY-MM-DD')
  }
};

export const dateRangeInitialValues: DateRangeValues = {
  version: 2,
  select: 'input_past',
  inputPastValue: {
    value: '10',
    unit: 'year'
  },
  inputValue: {
    startDate: dayjs().subtract(10, 'year').format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD')
  },
  includeToday: true
};

export const datetimeSingleInitialValues: DatetimeSingleValues = {
  version: 2,
  select: 'manual',
  inputPastValue: {
    value: '30',
    unit: 'day'
  },
  inputValue: {
    date: dayjs().format('YYYY-MM-DD'),
    time: '00:00:00'
  }
};

export const datetimeRangeInitialValues: DatetimeRangeValues = {
  version: 2,
  select: 'input_past',
  inputPastValue: {
    value: '10',
    unit: 'year'
  },
  inputValue: {
    startDate: dayjs().subtract(10, 'year').format('YYYY-MM-DD'),
    startTime: '00:00:00',
    endDate: dayjs().format('YYYY-MM-DD'),
    endTime: '23:59:59'
  },
  includeToday: true
};

export const timeSingleInitialValues: TimeSingleValues = {
  version: 2,
  select: 'manual',
  inputPastValue: {
    value: '30',
    unit: 'minute'
  },
  inputValue: {
    time: '00:00:00'
  }
};

export const timeRangeInitialValues: TimeRangeValues = {
  version: 2,
  select: 'manual',
  inputPastValue: {
    value: '30',
    unit: 'minute'
  },
  inputValue: {
    startTime: '00:00:00',
    endTime: '23:59:59'
  }
};
