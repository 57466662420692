import { configureStore } from '@reduxjs/toolkit';
import 'firebase/auth';
import { dataFetcher } from './middlewares/data_fetcher';
import { reducers } from './reducers';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const middlewares = [dataFetcher()];

// Redux Logger
// if (import.meta.env.VITE_ENV !== 'production') {
//   const loggerMiddleware = createLogger();
//   middlewares.push(loggerMiddleware);
// }

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false
    }).concat(middlewares),
  devTools: import.meta.env.VITE_ENV !== 'production'
});
export type StoreType = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<StoreType> = useSelector;
