import * as React from 'react';
import { TableCell } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { AccessLevelString } from 'libs/accessLevel';
import { Node } from 'models/graph';
import {
  BaseItem,
  BuilderItem,
  ConnectionItem,
  DatasourceItem,
  ExportItem,
  ListItem,
  ListItemType,
  NotificationDstItem,
  NotificationItem,
  OrganizationItem,
  ProjectItem,
  ReportItem,
  ScheduleItem,
  UserGroupItem,
  VariableItem
} from 'models/dependency';
import { ProjectRow } from 'ui/listView/projectRow';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DatasourceRow } from 'ui/listView/datasourceRow';
import { BuilderRow } from 'ui/listView/builderRow';
import { ReportRow } from 'ui/listView/reportRow';
import { VisualizeSelectorRow } from 'ui/listView/visualizeSelectorRow';
import { ReportSelectorRow } from 'ui/listView/reportSelectorRow';
import { VariableRow } from 'ui/listView/variableRow';
import {
  Checkbox,
  ConnectionCell,
  LinkCell,
  NodeStatusCell,
  OpenMenuType,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';
import { ListViewBaseProps } from 'ui/listViewBase';
import { SortDirection, SortDirectionType } from 'ui/listView';
import {
  SelectorDatasourceRow,
  SelectorExportRow,
  SelectorProjectRow,
  SelectorReportRow
} from 'ui/listView/selectorRow';
import { ScheduleRow } from 'ui/listView/scheduleRow';
import { NotificationRow } from 'ui/listView/notificationRow';
import { NotificationDstRow } from 'ui/listView/notificationDstRow';
import { UserGroupRow } from 'ui/listView/userGroupRow';
import { OrganizationRow } from 'ui/listView/organizationRow';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  },
  arrow: {
    position: 'absolute',
    bottom: 6
  },
  sortableColumn: {
    '&:hover': {
      color: '#000000'
    }
  }
});

const Arrow: React.FC<{ sortDirection: SortDirectionType }> = ({
  sortDirection
}) => {
  const classes = cellStyle();
  switch (sortDirection) {
    case SortDirection.asc:
      return <KeyboardArrowUp className={classes.arrow} />;
    case SortDirection.desc:
      return <KeyboardArrowDown className={classes.arrow} />;
    case SortDirection.none:
      return null;
  }
};

export const ListViewHeader: React.FC<{
  type: ListItemType;
  text: string;
  showProject?: boolean;
  onSelectAll: () => void;
  onClickColumn: (key: string) => void;
  checked: boolean;
  indeterminate: boolean;
  disableCheck?: boolean;
  selectedColumnKey: string;
  sortDirection: SortDirectionType;
}> = ({
  type,
  showProject,
  text,
  onSelectAll,
  onClickColumn,
  checked,
  indeterminate,
  disableCheck,
  selectedColumnKey,
  sortDirection
}) => {
  const classes = cellStyle();
  const sortDirectionOf = (key: string): SortDirectionType => {
    return selectedColumnKey == key ? sortDirection : false;
  };

  switch (type) {
    case 'projects':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );
    case 'builders':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell>接続先名</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );
    case 'reports':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>公開URL</TableCell>
          <TableCell>公開制限</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );
    case 'connections':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );
    case 'datasources':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );
    case 'schedules':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>実行最終ステータス</TableCell>
          <TableCell>設定ステータス</TableCell>
          <TableCell>権限</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('next_run_time')}
            onClick={() => onClickColumn('next_run_time')}
            className={classes.sortableColumn}
          >
            次回実行時間
            <Arrow sortDirection={sortDirectionOf('next_run_time')} />
          </TableCell>
          <TableCell>クイックメニュー</TableCell>
          <TableCell />
        </>
      );
    case 'exports':
      return (
        <>
          {showProject && (
            <TableCell
              sortDirection={sortDirectionOf('project_name')}
              onClick={() => onClickColumn('project_name')}
              className={classes.sortableColumn}
            >
              分析プロジェクト
              <Arrow sortDirection={sortDirectionOf('project_name')} />
            </TableCell>
          )}
          <TableCell>実行ワークフロー</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell>エクスポート先</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('started_at')}
            onClick={() => onClickColumn('started_at')}
            className={classes.sortableColumn}
          >
            実行開始
            <Arrow sortDirection={sortDirectionOf('started_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('ended_at')}
            onClick={() => onClickColumn('ended_at')}
            className={classes.sortableColumn}
          >
            実行終了
            <Arrow sortDirection={sortDirectionOf('ended_at')} />
          </TableCell>
        </>
      );
    case 'visualize_selector':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
        </>
      );

    case 'report_selector':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
        </>
      );

    case 'variables':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>データ型</TableCell>
          <TableCell>値</TableCell>
          <TableCell>説明</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );

    case 'selector_project':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
        </>
      );

    case 'selector_datasource':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );

    case 'selector_report':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
        </>
      );

    case 'selector_export':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('project_name')}
            onClick={() => onClickColumn('project_name')}
            className={classes.sortableColumn}
          >
            分析プロジェクト
            <Arrow sortDirection={sortDirectionOf('project_name')} />
          </TableCell>
          <TableCell>実行ワークフロー</TableCell>
          <TableCell>ステータス</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell>エクスポート先</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('started_at')}
            onClick={() => onClickColumn('started_at')}
            className={classes.sortableColumn}
          >
            実行開始
            <Arrow sortDirection={sortDirectionOf('started_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('ended_at')}
            onClick={() => onClickColumn('ended_at')}
            className={classes.sortableColumn}
          >
            実行終了
            <Arrow sortDirection={sortDirectionOf('ended_at')} />
          </TableCell>
        </>
      );

    case 'notifications':
      return (
        <>
          {!disableCheck && (
            <TableCell padding="checkbox" className={classes.checkCell}>
              <Checkbox
                onChange={onSelectAll}
                checked={checked}
                indeterminate={indeterminate}
              />
            </TableCell>
          )}
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );

    case 'notification_dsts':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>権限</TableCell>
          <TableCell>タイプ</TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
          <TableCell />
        </>
      );

    case 'user_groups':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell>所属人数</TableCell>
          <TableCell>ダッシュボード公開制限用IPアドレス</TableCell>
          <TableCell />
        </>
      );

    case 'organizations':
      return (
        <>
          <TableCell
            sortDirection={sortDirectionOf('id')}
            onClick={() => onClickColumn('id')}
            className={classes.sortableColumn}
          >
            ID
            <Arrow sortDirection={sortDirectionOf('id')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('name')}
            onClick={() => onClickColumn('name')}
            className={classes.sortableColumn}
          >
            {text}名
            <Arrow sortDirection={sortDirectionOf('name')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('namespace')}
            onClick={() => onClickColumn('namespace')}
            className={classes.sortableColumn}
          >
            namespace
            <Arrow sortDirection={sortDirectionOf('namespace')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('created_at')}
            onClick={() => onClickColumn('created_at')}
            className={classes.sortableColumn}
          >
            作成日時
            <Arrow sortDirection={sortDirectionOf('created_at')} />
          </TableCell>
          <TableCell
            sortDirection={sortDirectionOf('updated_at')}
            onClick={() => onClickColumn('updated_at')}
            className={classes.sortableColumn}
          >
            更新日時
            <Arrow sortDirection={sortDirectionOf('updated_at')} />
          </TableCell>
        </>
      );
    default:
      return null;
  }
};

interface BaseListViewProps {
  type: ListItemType;
  enableShare?: boolean;
  showProject?: boolean;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: ListViewBaseProps['onOpenShareDialog'];
  onOpenMenu: OpenMenuType;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  onScheduleChanged: (id: string, enabled: boolean) => void;
  checked: boolean;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  onClickLink?: (item: ListItem) => void;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}

type ListViewRowProps = BaseListViewProps & { item: ListItem };

export const ListViewRow: React.FC<ListViewRowProps> = ({
  type,
  item,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  showProject,
  onCheckChanged,
  onScheduleChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  onClickLink,
  clearForm
}) => {
  const onClickShare = React.useCallback(
    (id: string | number, editable: boolean, title: string) => {
      if (onOpenShareDialog) {
        onOpenShareDialog(id, editable, title, Boolean(item.is_folder));
      }
    },
    [item, onOpenShareDialog]
  );

  switch (type) {
    case 'projects': {
      const project = item as ProjectItem;
      return (
        <ProjectRow
          project={project}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          folderOnly={folderOnly}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }
    case 'builders': {
      const builder = item as BuilderItem;
      return (
        <BuilderRow
          item={builder}
          onClickItem={onClickItem}
          onOpenMenu={onOpenMenu}
          onOpenShareDialog={onClickShare}
          onCheckChanged={onCheckChanged}
          checked={checked}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }
    case 'connections': {
      const conn = item as ConnectionItem;
      return (
        <>
          <LinkCell href={`/connections/${conn.uuid}`} name={conn.name} />
          <TableCell>{AccessLevelString(conn.access_level)}</TableCell>
          <ConnectionCell type={conn.type} />
          <TimestampCell time={conn.created_at} />
          <TimestampCell time={conn.updated_at} />
          <ShareDialogCell
            name={conn.name}
            uuid={conn.uuid}
            accessLevel={conn.access_level}
            onOpenShareDialog={onClickShare}
            onOpenMenu={onOpenMenu}
          />
        </>
      );
    }
    case 'datasources': {
      const ds = item as DatasourceItem;
      return (
        <DatasourceRow
          ds={ds}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }
    case 'reports': {
      const report = item as ReportItem;
      return (
        <ReportRow
          item={report}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }
    case 'exports': {
      const exp = item as ExportItem;
      return (
        <>
          {showProject && (
            <LinkCell
              href={`/projects/${exp.project_uuid}`}
              name={exp.project_name}
            />
          )}
          <LinkCell
            href={`/projects/${exp.project_uuid}/nodes/${exp.uuid}`}
            name={exp.workflow_name}
          />
          <NodeStatusCell
            project_id={exp.project_uuid}
            node_id={exp.uuid}
            status={exp.status}
            error={exp.error}
          />
          <ConnectionCell type={exp.type} />
          <TableCell>{exp.destination}</TableCell>
          <TimestampCell time={exp.started_at} />
          <TimestampCell time={exp.ended_at} />
        </>
      );
    }
    case 'schedules': {
      const s = item as ScheduleItem;
      return (
        <ScheduleRow
          schedule={s}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          folderOnly={folderOnly}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
          onScheduleChanged={onScheduleChanged}
        />
      );
    }
    case 'visualize_selector': {
      const project = item as BaseItem;
      return (
        <VisualizeSelectorRow
          item={project}
          onClickItem={onClickItem}
          clearForm={clearForm}
        />
      );
    }

    case 'report_selector': {
      const report = item as ReportItem;
      return (
        <ReportSelectorRow
          item={report}
          onClickItem={onClickItem}
          clearForm={clearForm}
        />
      );
    }
    case 'variables': {
      const variable = item as VariableItem;
      return (
        <VariableRow
          item={variable}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          disableCheck={disableCheck}
          basePath={basePath}
          onClickLink={onClickLink}
          clearForm={clearForm}
        />
      );
    }
    case 'selector_project': {
      return (
        <SelectorProjectRow
          item={item as ProjectItem}
          onClickItem={onClickItem}
          clearForm={clearForm}
          checked={checked}
          disableCheck={disableCheck}
          onCheckChanged={onCheckChanged}
        />
      );
    }
    case 'selector_datasource': {
      return (
        <SelectorDatasourceRow
          item={item as BaseItem}
          onClickItem={onClickItem}
          clearForm={clearForm}
          checked={checked}
          disableCheck={disableCheck}
          onCheckChanged={onCheckChanged}
        />
      );
    }
    case 'selector_report': {
      return (
        <SelectorReportRow
          item={item as ReportItem}
          onClickItem={onClickItem}
          clearForm={clearForm}
          disableCheck={true}
          checked={false}
          onCheckChanged={onCheckChanged}
        />
      );
    }
    case 'selector_export': {
      return (
        <SelectorExportRow
          item={item as ExportItem}
          onClickItem={onClickItem}
        />
      );
    }
    case 'notifications': {
      const n = item as NotificationItem;
      return (
        <NotificationRow
          notification={n}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          folderOnly={folderOnly}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }

    case 'notification_dsts': {
      const dst = item as NotificationDstItem;
      return (
        <NotificationDstRow
          notificationDst={dst}
          onClickItem={onClickItem}
          onOpenShareDialog={onClickShare}
          onOpenMenu={onOpenMenu}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }

    case 'user_groups': {
      return (
        <UserGroupRow
          item={item as UserGroupItem}
          onClickItemLink={onClickLink}
          onOpenMenu={onOpenMenu}
        />
      );
    }

    case 'organizations': {
      const itm = item as OrganizationItem;
      return (
        <OrganizationRow
          item={itm}
          onClickItem={onClickItem}
          onOpenMenu={onOpenMenu}
          onCheckChanged={onCheckChanged}
          checked={checked}
          disableCheck={disableCheck}
          basePath={basePath}
          clearForm={clearForm}
        />
      );
    }
    default:
      return null;
  }
};

export function getDestination(n: Node): string {
  if (!n.formValues) {
    return '-';
  }

  switch (n.formValues.type) {
    case 'bigquery':
      return `${n.formValues.dataset}.${n.formValues.table}`;
    case 'redshift':
      return `${n.formValues.table}`;
    case 'treasure_data':
      return `${n.formValues.database}.${n.formValues.table}`;
    case 's3':
      return `${n.formValues.bucketName}/${n.formValues.fileName}`;
    case 'local':
      return `${n.formValues.fileName}`;
    case 'nehan_storage':
      return `${n.formValues.datasourceName}`;
    case 'mysql':
      return `${n.formValues.table}`;
    case 'msss':
      return `${n.formValues.table}`;
    default:
      return '-';
  }
}
