import * as React from 'react';

export function IconString() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 17"
      style={{ minWidth: 16 }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#67B8DA" />
        <text
          fill="#FFF"
          fontFamily="'Noto Sans JP'"
          fontSize="10"
          fontWeight="500"
        >
          <tspan x="3.6" y="12">
            文
          </tspan>
        </text>
      </g>
    </svg>
  );
}
