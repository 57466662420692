import * as React from 'react';
import {
  CheckboxForm,
  SelectForm,
  TextForm,
  userRoleList
} from 'ui/listView/filter/form';
import {
  AutocompleteForm,
  AutocompleteItemIconType,
  Option
} from 'ui/listView/filter/autocompleteForm';
import { FilterType } from 'ui/listView/filter/type';
import {
  handleChangeUserEmail,
  handleChangeUserGroups,
  handleChangeUserPasswordGenerated,
  handleChangeUserTypes
} from 'ui/listView/filter/handler';
import { FilterContext } from 'ui/listView/filter/context';
import { useAsync } from 'react-use';
import { getUserGroups } from 'libs/api';
import { UserGroup } from 'models/user_group';

export const UserFilter: React.FC<{}> = () => {
  const { state: filterState, dispatch: filterDispatch } =
    React.useContext(FilterContext);
  const groups = useAsync<() => Promise<UserGroup[]>>(async () => {
    const { data } = await getUserGroups();
    return data;
  });

  const userGroupOptions = React.useMemo(() => {
    if (!groups.value) {
      return [];
    }
    return groups.value.map((group) => ({
      label: group.name,
      value: group.name
    }));
  }, [groups]);

  const onChangeUserGroup = React.useCallback(
    (_: React.ChangeEvent<{}>, value: Option[]) => {
      handleChangeUserGroups(filterState, filterDispatch)(
        _,
        value.map((v) => v.value)
      );
    },
    [filterState, filterDispatch]
  );

  return (
    <>
      <table>
        <tbody>
          <TextForm
            title="Email"
            value={filterState[FilterType.UserEmail]}
            onChange={handleChangeUserEmail(filterState, filterDispatch)}
          />
          <SelectForm
            title="タイプ"
            labels={userRoleList}
            values={filterState[FilterType.UserTypes]}
            onChange={handleChangeUserTypes(filterState, filterDispatch)}
          />
          <CheckboxForm
            title="パスワード発行済み"
            value={filterState[FilterType.UserPasswordGenerated]}
            onChange={handleChangeUserPasswordGenerated(
              filterState,
              filterDispatch
            )}
          />
          <AutocompleteForm
            title="ユーザーグループ"
            options={userGroupOptions}
            value={filterState[FilterType.UserGroups]}
            type={AutocompleteItemIconType.UserGroup}
            onChange={onChangeUserGroup}
          />
        </tbody>
      </table>
    </>
  );
};
