import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { red } from '@material-ui/core/colors';
import ListView from 'components/ui/listView';
import {
  deleteVariable,
  getVariableList,
  getVariables,
  importVariables as importVariablesApi
} from 'libs/api';
import { Variable } from 'models/project';
import { VariableItem } from 'models/dependency';
import arrayToTree from 'array-to-tree';
import { ResourceType } from 'models/variable';
import { QueryParams } from 'ui/listViewBase';
import { projectTheme } from '../theme';

const useStyles = makeStyles((theme: Theme) => {
  return {
    content: {
      height: 500,
      minHeight: 500,
      padding: 0
    },
    error: {
      border: '1px solid',
      borderRadius: 3,
      padding: theme.spacing(1),
      margin: theme.spacing(1),
      color: red[400]
    }
  };
});

class VariablesStore {
  list: VariableItem[];
}

// 変数リストにチェックを入れる。親フォルダがチェックされてたら子もチェックする
const _setChecked = (
  store: VariablesStore,
  tree: arrayToTree.Tree<VariableItem>,
  uuids: string[],
  parentChecked = false
): void => {
  // 親がチェックされていたら or uuidが一致したら
  let checked = false;
  if (parentChecked || uuids.includes(tree.uuid)) {
    parentChecked = true;
    checked = true;
  }
  store.list = store.list.map((v) => {
    if (v.uuid === tree.uuid) {
      v.checked = checked;
    }
    return v;
  });

  if (tree.children) {
    for (const child of tree.children) {
      _setChecked(store, child, uuids, parentChecked);
    }
  }
};

const resourceTypeToLabel = (type: ResourceType) => {
  switch (type) {
    case 'project':
      return '分析プロジェクト';
    case 'datasource':
      return 'データソース';
    case 'builder':
      return 'SQLビルダー';
  }
};

export const VariablesImportDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  onImportVariables: () => any;
  localVariables: Variable[];
  importedVariables: Variable[];
  resourceType: ResourceType;
  resourceId: string;
  importVariables?: (
    resourceType: ResourceType,
    resourceId: string,
    importVariableUuids: string[]
  ) => void;
}> = ({
  open,
  onClose,
  onImportVariables,
  localVariables,
  importedVariables,
  resourceType,
  resourceId,
  importVariables
}) => {
  const classes = useStyles();
  const [folderId, setFolderId] = useState('');
  const [invalidVariables, setInvalidVariables] = useState<VariableItem[]>([]);
  const [variables, setVariables] = useState<{
    list: VariableItem[];
    trees: arrayToTree.Tree<VariableItem>[];
  }>({ list: [], trees: [] });

  useEffect(() => {
    const f = async () => {
      if (open) {
        const { data } = await getVariables();
        // 木構造に変換
        const trees = arrayToTree(data.items, {
          parentProperty: 'parent_uuid',
          customID: 'uuid'
        });
        setVariables({ list: data.items, trees });
      }
    };
    f();
  }, [open]);

  const handleImport = async () => {
    if (invalidVariables.length > 0) {
      return;
    }
    // チェックされてるvariableをインポートする
    const importVariableUuids = variables.list
      .filter((v) => v.checked)
      .map((v) => v.uuid);
    if (importVariables) {
      await importVariables(resourceType, resourceId, importVariableUuids);
    } else {
      await importVariablesApi(resourceType, resourceId, importVariableUuids);
    }
    onImportVariables();
    close();
  };

  const onChangeCheckedIds = (uuids: string[]) => {
    const list = setChecked(uuids, variables.list, variables.trees);
    setVariables({ ...variables, list });
    const localVarNames = localVariables.map((v) => v.name);
    // チェックされてる and ローカル変数と同じ名前のvariableを取得
    const sameNameVars = list.filter(
      (v) => !v.is_folder && v.checked && localVarNames.includes(v.name)
    );
    setInvalidVariables(sameNameVars);
  };

  const setChecked = (
    uuids: string[],
    variableList: VariableItem[],
    variableTrees: arrayToTree.Tree<VariableItem>[]
  ): VariableItem[] => {
    const store = new VariablesStore();
    store.list = variableList;
    variableTrees.forEach((tree) => {
      _setChecked(store, tree, uuids);
    });
    return store.list;
  };

  const onClickCancel = () => {
    close();
  };

  const onCloseDialog = () => {
    close();
  };

  const close = () => {
    reset();
    onClose();
  };

  const reset = () => {
    setInvalidVariables([]);
    setVariables({
      ...variables,
      list: variables.list.map((v) => {
        v.checked = false;
        return v;
      })
    });
  };

  const loadFunc = React.useMemo(() => {
    return async (folderId: string, queryParams?: QueryParams) => {
      const {
        data: { items, paths }
      } = await getVariableList(folderId, queryParams);

      const importedVariableUuids = importedVariables.map((v) => v.uuid);
      const {
        data: { items: variableList }
      } = await getVariables();
      const variableTrees = arrayToTree(variableList, {
        parentProperty: 'parent_uuid',
        customID: 'uuid'
      });
      // フォルダの中に未インポートの共通変数が一つもない場合はそのフォルダを除外する
      const unimportedItems = items.filter((i) => {
        // iとiの子孫にチェックを入れて、その中に未インポートの共通変数があったらok
        const list = setChecked([i.uuid], variableList, variableTrees);
        const foundUnimport = list.find(
          (i) =>
            i.checked &&
            !i.is_folder && // 共通変数である
            !importedVariableUuids.includes(i.uuid) // 未インポートである
        );
        return foundUnimport;
      });
      return { data: { items: unimportedItems, paths } };
    };
  }, [importedVariables]);

  return (
    <Dialog open={open} onClose={onCloseDialog} maxWidth="lg" fullWidth={true}>
      <DialogTitle>インポートする変数を追加してください</DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <ThemeProvider theme={projectTheme}>
          <ListView
            type="variables"
            folderId={folderId}
            load={loadFunc}
            delete={deleteVariable}
            onClickFolder={(id) => {
              setFolderId(id);
              reset();
            }}
            showDetailPane={false}
            supportFolder={true}
            onChangeCheckedIds={onChangeCheckedIds}
            onClickItemLink={(item) => {
              if (item.is_folder) {
                setFolderId(item.uuid);
              }
              reset();
            }}
            disableItemClick={true}
          />
        </ThemeProvider>
      </DialogContent>
      {invalidVariables.length > 0 && (
        <pre className={classes.error}>
          {invalidVariables.length > 0 &&
            `${invalidVariables
              .map((v) => `「${v.name}」`)
              .join('、')}は${resourceTypeToLabel(
              resourceType
            )}内変数と名前が重複しています`}
        </pre>
      )}
      <DialogActions>
        <Button onClick={onClickCancel} color="secondary">
          キャンセル
        </Button>
        <Button
          onClick={handleImport}
          color="primary"
          variant="contained"
          disabled={
            variables.list.filter((v) => v.checked).length === 0 ||
            invalidVariables.length > 0
          }
        >
          インポート
        </Button>
      </DialogActions>
    </Dialog>
  );
};
