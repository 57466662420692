import {
  Edge,
  EdgeStatus,
  Graph,
  Node,
  Nodes,
  NodeStatus,
  Port,
  Ports,
  PortStatus,
  Positions
} from 'models/graph';
import {
  INITIAL_POSITIONS,
  removeLink,
  removeNode,
  setTemporaryFormValue,
  updateNode,
  updateNodeComment,
  updateNodeCoordinates
} from 'reducers/graph';
import ActionTypes from 'actions/actionTypes';
import { produce } from 'immer';
import { concat, omit } from 'lodash-es';
import { ProcessItem } from 'reducers/processList';
import {
  ConnectPort,
  generateEdgeId,
  GenerateId,
  generateNodeId,
  GeneratePortsInfo,
  topologicalSort
} from 'components/diagram/utils';
import { ControlCoords } from 'components/diagram/controlCoords';
import { NodeBackend } from 'models/websocket';
import {
  cutArray,
  redo,
  undo,
  UndoCreateEdge,
  UndoCreateNodeEdge,
  UndoType
} from 'reducers/undo';

interface BuilderPort extends Port {
  columns?: Array<{ value: string; dType: string }>;
}

interface BuilderPorts {
  [id: string]: BuilderPort;
}

interface BuilderGraphState extends Graph {
  ports: BuilderPorts;
}

export function clearTemporaryItems(
  state: BuilderGraphState
): BuilderGraphState {
  const { selectedNodeId, previousNodeId } = state;

  // gather temporary port ids
  let tmpPortIds: string[] = Object.values(state.ports)
    .filter((port) => {
      return port.status === PortStatus.Temporary;
    })
    .map((port) => port.id);

  // gather temporary node ids
  const tmpNodeIds = Object.keys(state.nodes)
    .filter((nodeId) => {
      const { status, inPorts, outPorts } = state.nodes[nodeId];
      if (status === NodeStatus.Temporary) {
        tmpPortIds = concat(tmpPortIds, inPorts, outPorts);
        return true;
      }
      return false;
    })
    .map((id) => id);

  const tmpEdgeIds = Object.keys(state.edges)
    .filter((edgeId) => {
      const { status, from, to } = state.edges[edgeId];
      if (status === EdgeStatus.Temporary) {
        return true;
      }

      // gather edges which is connected deleted node
      return tmpNodeIds.includes(from.nodeId) || tmpNodeIds.includes(to.nodeId);
    })
    .map((id) => id);

  return produce(state, (draft) => {
    draft.nodes = omit(state.nodes, tmpNodeIds);
    draft.edges = omit(state.edges, tmpEdgeIds);
    draft.ports = omit(state.ports, tmpPortIds);
    draft.selectedNodeId =
      selectedNodeId && tmpNodeIds.includes(selectedNodeId) // selectedNodeが削除されたら、前のnodeにする
        ? previousNodeId
        : selectedNodeId;
  });
}

function createBuilderNode(
  state: BuilderGraphState,
  payload: { process: ProcessItem }
): BuilderGraphState {
  const newState = clearTemporaryItems(state);
  const nodeId = generateNodeId();
  const { selectedNodeId } = newState;
  const { outConf, inConf, name } = payload.process;
  const controlCoords = new ControlCoords(
    state.nodes as Nodes,
    state.positions
  );

  const selectedNode =
    selectedNodeId != undefined ? newState.nodes[selectedNodeId] : undefined;

  const { nodeCoord } = controlCoords.generateNodeCoord(
    selectedNodeId,
    inConf != undefined
  );

  const inPorts: Ports = GeneratePortsInfo(inConf || []);
  const outPorts: Ports = GeneratePortsInfo(outConf || []);

  const newNode: Node = {
    id: nodeId,
    name,
    inPorts: Object.keys(inPorts),
    outPorts: Object.keys(outPorts),
    processType: name || '',
    status: NodeStatus.Temporary,
    backend: NodeBackend.Python,
    x: nodeCoord.x,
    y: nodeCoord.y,
    comment: '[自動コメント]',
    autoComment: '',
    rows: 3
  };

  let tmpEdge: Edge | undefined;
  if (selectedNode != undefined && inConf != undefined) {
    // edgeの設定
    const selectedOutPorts: Ports = {};
    selectedNode.outPorts.forEach((id) => {
      selectedOutPorts[id] = newState.ports[id];
    });
    const { fromPortId, toPortId, connected } = ConnectPort(
      inPorts,
      selectedOutPorts
    );

    if (connected) {
      // 新しいedgeの作成
      tmpEdge = {
        id: generateEdgeId(),
        from: { nodeId: selectedNode.id, portId: fromPortId },
        to: { nodeId: newNode.id, portId: toPortId },
        status: EdgeStatus.Temporary
      };
    }
  }

  return produce(newState, (draft) => {
    draft.ports = { ...draft.ports, ...inPorts, ...outPorts };
    draft.nodes[newNode.id] = newNode;

    if (tmpEdge != undefined) {
      draft.edges[tmpEdge.id] = tmpEdge;
    }
    draft.selectedNodeId = nodeId;
    draft.previousNodeId = state.selectedNodeId;
    const undoData: UndoCreateNodeEdge = {
      type: UndoType.CREATE_NODE_EDGE,
      params: {
        nodes: [newNode.id]
      }
    };
    if (tmpEdge) {
      undoData.params['edges'] = [tmpEdge.id];
    }
    draft.undoList.push(undoData);
    draft.undoList = cutArray(draft.undoList);
  });
}

function deleteNode(
  state: BuilderGraphState,
  payload: {
    nodeIds: string[];
    data?: { selected_node_id: string; previous_node_id: string };
  }
) {
  const { nodeIds } = payload;
  const selectedNodeId = payload.data?.selected_node_id;
  const previousNodeId = payload.data?.previous_node_id;
  const [{ nodes, ports, edges }, deleted] = removeNode(nodeIds, state);

  return {
    nodes,
    edges,
    ports,
    selectedNodeId,
    previousNodeId,
    undoList: cutArray([
      ...state.undoList,
      { type: UndoType.REMOVE_NODE as const, params: { deleted } }
    ]),
    redoList: []
  };
}

function createLink(
  state: BuilderGraphState,
  event: Pick<Edge, 'from' | 'to'>
) {
  const { edges } = state;
  let edgeId = generateEdgeId();
  const edgeIds = Object.keys(edges);
  for (let i = 0; i < 10; i++) {
    // 重複しないIDをがんばってつくる
    if (!edgeIds.includes(edgeId)) {
      break;
    }
    edgeId = `edge_${GenerateId()}`;
  }

  if (edgeIds.includes(edgeId)) {
    console.warn('CANNOT CREATE EDGE ID'); // tslint:disable-line
    return state;
  }

  const deletedEdges: Edge[] = [];
  const newState = produce(state, (draft) => {
    const { to, from } = event;
    const deleteEdgeIds = Object.values(draft.edges)
      .filter((e) => e.to.portId === to.portId)
      .map((e) => e.id);
    deleteEdgeIds.forEach((id) => {
      deletedEdges.push(state.edges[id]);
      delete draft.edges[id];
    });

    const newEdgeStatus = [to.nodeId, from.nodeId].some(
      (id) => draft.nodes[id].status === NodeStatus.Temporary
    )
      ? EdgeStatus.Temporary
      : EdgeStatus.Created;

    // テンポラリでない場合のみ、再実行してくださいにする
    if (draft.nodes[to.nodeId].status !== NodeStatus.Temporary) {
      draft.nodes[to.nodeId].status = NodeStatus.Warning;
      draft.nodes[to.nodeId].errorMessage = '再実行してください';
    }

    draft.edges[edgeId] = {
      id: edgeId,
      ...event,
      status: newEdgeStatus
    };

    if (draft.selectedNodeId !== to.nodeId) {
      draft.previousNodeId = draft.selectedNodeId;
      draft.selectedNodeId = to.nodeId;
    }
  });

  try {
    topologicalSort(newState.nodes, newState.edges);
  } catch (e) {
    alert('ループが発生するため、エッジの作成はできません');
    console.warn('circular dependent!!', e);
    return state;
  }

  const undoItem: UndoCreateEdge = {
    type: UndoType.CREATE_EDGE,
    params: {
      edge: newState.edges[edgeId],
      deletedEdges
    }
  };

  return {
    ...newState,
    undoList: cutArray([...newState.undoList, undoItem]),
    redoList: []
  };
}

function updateBuilderView(
  state: BuilderGraphState,
  payload: {
    nodes: { [id: string]: { x: number; y: number } };
    positions: Positions;
  }
) {
  return produce(state, (draft) => {
    draft.positions = payload.positions;
    Object.keys(payload.nodes).forEach((nodeId) => {
      if (draft.nodes[nodeId]) {
        draft.nodes[nodeId].x = payload.nodes[nodeId].x;
        draft.nodes[nodeId].y = payload.nodes[nodeId].y;
      }
    });
    return draft;
  });
}

export const initialState: BuilderGraphState = {
  nodes: {},
  edges: {},
  ports: {},
  positions: INITIAL_POSITIONS,
  selectedNodeId: undefined,
  previousNodeId: undefined,
  undoList: [],
  redoList: []
};

export default function builderGraph(
  state: BuilderGraphState = initialState,
  action
): BuilderGraphState {
  switch (action.type) {
    case ActionTypes.SelectBuilderNode:
      return produce(state, (draft) => {
        if (draft.selectedNodeId !== action.payload) {
          draft.previousNodeId = draft.selectedNodeId;
        }
        draft.selectedNodeId = action.payload;
      });
    case ActionTypes.CreateBuilderNode:
      return createBuilderNode(state, action.payload);
    case ActionTypes.SetTemporaryBuilderFormValue:
      return setTemporaryFormValue(state, action.payload);
    case ActionTypes.UpdateBuilderNode:
      return updateNode(state, action.payload);
    case ActionTypes.ClearBuilderTemporaryItems:
      return clearTemporaryItems(state);
    case ActionTypes.UpdateBuilderGraphView:
      return updateBuilderView(state, action.payload);
    case ActionTypes.CreateBuilderLink:
      return createLink(state, action.payload);
    case ActionTypes.RemoveBuilderLink:
      return removeLink(state, action.payload);
    case ActionTypes.UpdateBuilderNodeCoordinates:
      return updateNodeCoordinates(state, action.payload);
    case ActionTypes.UpdateBuilderNodeComment:
      return updateNodeComment(state, action.payload);
    case ActionTypes.DeleteBuilderNode:
      return { ...state, ...deleteNode(state, action.payload) };
    case ActionTypes.UndoBuilderGraph:
      return undo(state);
    case ActionTypes.RedoBuilderGraph:
      return redo(state);

    default:
      return state;
  }
}
