import { SingleNumberParameter } from 'models/report';
import { operatorToString } from '../toString';
import { ParameterClass } from './base';

export class NumberSingleClass implements ParameterClass {
  constructor(public param: SingleNumberParameter) {}
  isValid = () => {
    return this.param.value != undefined;
  };
  toDisplayString = () => {
    return `${operatorToString(this.param.operator)} ${this.param.value}`;
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
}
