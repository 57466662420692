import * as React from 'react';

export function IconTimestamp() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      style={{ minWidth: 16 }}
    >
      <circle cx="8.5" cy="8.5" r="8.5" style={{ fill: '#75a5b1' }} />
      <path
        style={{ fill: '#fff' }}
        d="M5.5 6.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1ZM5.5 9.5a1 1 0 1 0 1 1 1 1 0 0 0-1-1Z"
      />
      <path
        style={{ fill: '#fff' }}
        d="M9.5 2.5a1 1 0 0 0-1 1h-2a1 1 0 0 0-2 0h-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h5.38a4 4 0 1 0 3.62-6.86V4.5a1 1 0 0 0-1-1h-1a1 1 0 0 0-1-1Zm0 3a1 1 0 0 0 1-1h1v2a4.07 4.07 0 0 1 .62.06 4.07 4.07 0 0 0-.62-.06 4.1 4.1 0 0 0-1.36.25 1 1 0 0 0-.64-.25 1 1 0 0 0-1 1 .94.94 0 0 0 .06.3 3.95 3.95 0 0 0-.51 4.7H3.5v-8h1a1 1 0 0 0 2 0h2a1 1 0 0 0 1 1Zm2 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3Zm-3.06-.45Z"
      />
      <path style={{ fill: '#fff' }} d="M12 8h-1v3h3v-1h-2V8z" />
    </svg>
  );
}
