import * as React from 'react';

import {
  Avatar,
  Chip,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { ColumnData, validate } from './validate';
import { getColumnIcon } from 'components/dataTable/headerCell';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import { isEmpty } from 'lodash-es';
import { SelectFieldSchema, SelectFieldValueElement } from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const styles = makeStyles(() => ({
  flex: {
    display: 'flex',
    width: 'calc(100% - 4px)' // Rippleが4pxはみ出る
  },
  autoComplete: {
    flexGrow: 1
  },
  fullwidth: {
    display: 'flex',
    width: '100%'
  }
}));

export interface SelectFieldProps {
  value: SelectFieldValueElement | SelectFieldValueElement[] | string | null;
  schema: SelectFieldSchema;
  onChangeField: ChangeFieldHandler;
  simpleValue?: boolean;
  errors?: FieldValidationError;
  clearable?: boolean;
  freeSolo?: boolean;
  onChange?: (value) => void;
  detailedHelpTooltip?: React.ReactNode;
}

export function getOptionDTypeIcon(data: any): React.ReactNode {
  if (data == undefined) {
    return null;
  }
  if (typeof data !== 'object') {
    return null;
  }
  if ('dtype' in data) {
    return getColumnIcon(data.dtype);
  }
  return null;
}

const getValue = (o) => {
  if (o == null) {
    return undefined;
  }
  if (Array.isArray(o)) {
    return o.map((i) => i.value);
  }
  return o.value;
};

const renderOption = (option) => {
  const icon = getOptionDTypeIcon(option);
  const color = option.isError ? red[400] : undefined;
  return (
    <Typography
      variant="body2"
      style={{ color, display: 'flex', alignItems: 'center' }}
      data-cy={`select-field-option-${option.dtype}`}
    >
      {icon}&nbsp;{option.label}
    </Typography>
  );
};

const renderTags = (value: any, getTagProps) => {
  return value.map((option, index) => {
    const icon = getOptionDTypeIcon(option);
    return (
      <Chip
        size="small"
        color={option.isError ? 'secondary' : 'default'}
        avatar={icon ? <Avatar>{icon}</Avatar> : null}
        label={option.label}
        {...getTagProps({ index })}
      />
    );
  });
};

const createRenderInput = (
  schema: SelectFieldSchema,
  value: any,
  errors: string[] | undefined
) => {
  const variant = schema.variant === 'outlined' ? 'outlined' : undefined;
  if (schema.multi) {
    return (params: AutocompleteRenderInputParams) => {
      const customParams = {
        ...params,
        // ここでバックグラウンドの色を入れたいけど、↓のようにやるとサジェストが効かなくなる
        //InputProps: {
        //  style: { backgroundColor: schema.backgroundColor }
        //},
        error: !isEmpty(errors),
        helperText: errors ? errors.join('') : '',
        InputProps: {
          ...params.InputProps
        },
        inputProps: {
          ...params.inputProps,
          style: {
            padding: '6px 0'
          }
        }
      };
      return (
        <TextField
          {...customParams}
          label={schema.placeholder}
          variant={variant}
        />
      );
    };
  } else {
    const icon = getOptionDTypeIcon(value);
    const color = value?.isError ? red[400] : undefined;
    return (params: AutocompleteRenderInputParams) => {
      const customParams = {
        ...params,
        InputProps: {
          ...params.InputProps,
          startAdornment: icon ? (
            <InputAdornment position="start">{icon}</InputAdornment>
          ) : null,
          style: { backgroundColor: schema.backgroundColor }
        },
        inputProps: {
          ...params.inputProps,
          style: {
            color,
            padding: '6px 0'
          }
        },
        error: !isEmpty(errors),
        helperText: errors ? errors.join('') : ''
      };
      return (
        <TextField
          {...customParams}
          label={schema.placeholder}
          variant={variant}
        />
      );
    };
  }
};

const selectField: React.FC<SelectFieldProps> = ({
  schema,
  onChangeField,
  simpleValue,
  value,
  clearable,
  onChange,
  errors,
  detailedHelpTooltip,
  ...props
}) => {
  const renderInput = createRenderInput(schema, value, errors);
  const options: Array<{ label: string; value: string }> = [];
  const columnData: ColumnData = { columns: [], dtypes: [] };
  schema.items.forEach((item) => {
    if (typeof item === 'string') {
      options.push({ label: item, value: item });
    } else {
      options.push(item);
      if ('dtype' in item) {
        columnData.columns.push(item.label);
      }
    }
  });
  columnData.dtypes = schema.allDtypes;
  simpleValue = simpleValue != undefined ? simpleValue : true;
  let valueProps = value;
  if (value != undefined && simpleValue) {
    if (schema.multi) {
      valueProps = options.filter((o) =>
        (value as unknown as string[]).includes(o.value)
      );
    } else {
      valueProps = options.find((o) => getValue(o) === value) ?? null;
    }
  }
  if (valueProps == undefined && schema.multi) {
    valueProps = [];
  }

  const classes = styles();

  return (
    <div
      className={clsx(classes.flex, { [classes.fullwidth]: schema.fullWidth })}
    >
      <Autocomplete
        openOnFocus={true}
        disableClearable={!clearable}
        multiple={schema.multi}
        filterSelectedOptions={false}
        disableCloseOnSelect={schema.multi}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionSelected={(option, value) => option.value === value.value}
        renderOption={renderOption}
        renderInput={renderInput}
        renderTags={renderTags}
        forcePopupIcon={true}
        className={classes.autoComplete}
        onChange={(_, value) => {
          if (onChange) {
            onChange(value);
          } else {
            const val =
              !schema.multi && Array.isArray(value) && value.length === 0
                ? undefined
                : simpleValue
                ? getValue(value)
                : value;
            onChangeField(
              schema.key,
              val,
              validate(val, schema.validate, columnData)
            );
          }
        }}
        value={valueProps}
        data-cy="select-field"
        {...props}
      />
      {detailedHelpTooltip}
    </div>
  );
};

export default selectField;
