import * as React from 'react';
import { debounce } from 'lodash-es';

import {
  Button,
  Theme,
  WithStyles,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions
} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import { Save as SaveIcon } from '@material-ui/icons';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { NodeColor } from 'components/graph/colors';
import { NodeStatus } from 'models/graph';
import { Variable } from 'models/project';
import { VariableFrom } from 'components/variableForm';

const styles = (theme: Theme) =>
  createStyles({
    saveBar: {
      display: 'flex',
      paddingBottom: 8,
      borderBottom: '1px solid #e8e8e8',
      alignItems: 'center'
    },
    connectionName: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.1),
      '&:hover': {
        backgroundColor: fade(theme.palette.common.white, 0.25)
      },
      marginRight: 8,
      marginLeft: 0,
      width: 'auto'
    },
    buttonWrapper: {
      margin: 8,
      position: 'relative'
    },
    error: {
      border: '1px solid',
      borderRadius: 3,
      padding: theme.spacing(1),
      color: red[400]
    }
  });

interface VariableEditorProps {
  dialog?: { open: boolean; onClose: () => void };
  addVariable: (variable: Variable) => void;
  editVariable: (variable: Variable) => void;
  saveVariables: () => void;
  handleCancel: () => void;
  initialVariable?: Variable;
  existingVariables: Variable[]; // この変数と被ってたら作成できない
  importedVariables: Variable[];
}

interface VariableEditorState {
  variable: Variable;
  isAdd: boolean; // 新規追加かどうか
  errorMessage?: string;
  initialName: string;
}

class VariableEditor extends React.Component<
  VariableEditorProps & WithStyles<typeof styles>,
  VariableEditorState
> {
  constructor(props) {
    super(props);

    if (this.props.initialVariable) {
      // 編集
      this.state = {
        variable: this.props.initialVariable,
        isAdd: false,
        initialName: this.props.initialVariable.name
      };
    } else {
      // 追加
      this.state = {
        variable: {
          name: '',
          value: '',
          dtype: '',
          desc: '',
          dateType: '',
          dateUnit: '',
          dateOperator: '',
          dateFormat: '',
          timestampFormat: '',
          timeFormat: '',
          dateCustomFormat: '',
          timestampCustomFormat: '',
          timeCustomFormat: ''
        },
        isAdd: true,
        initialName: ''
      };
    }

    this.saveVariables = debounce(this.saveVariables, 1000);
  }

  saveVariables = () => {
    this.props.saveVariables();
  };

  validateLocalName = (): string | undefined => {
    const { variable, initialName } = this.state;
    const { existingVariables, importedVariables } = this.props;

    if (variable.name === initialName) {
      // 変更前と同じ名前ならOK
      return;
    }

    const sameNameVariable = existingVariables.find(
      (v) => v.name === variable.name
    );
    if (sameNameVariable) {
      if (
        !sameNameVariable.uuid ||
        importedVariables.find((v) => v.name === variable.name)
      ) {
        // ローカル変数 or インポートした変数と同じ名前の場合
        return 'すでに存在している変数名です。';
      }
    }
  };

  handleSave: React.FormEventHandler = async (ev) => {
    ev.stopPropagation();
    const { isAdd, variable } = this.state;
    const { addVariable, editVariable, saveVariables } = this.props;
    ev.preventDefault();

    if (!variable.uuid) {
      // ローカル変数の場合クライアント側で名前チェック
      const errorMessage = this.validateLocalName();
      if (errorMessage) {
        this.setState({ errorMessage });
        return;
      }
    }

    try {
      if (isAdd) {
        await addVariable(variable);
      } else {
        await editVariable(variable);
      }

      await saveVariables();
    } catch (e) {
      if (e.isAxiosError && e.response.data) {
        this.setState({ errorMessage: e.response.data.toString() });
      }
    }

    return;
  };

  onChangeVariable = (variableParam: Partial<Variable>) => {
    this.setState({ variable: { ...this.state.variable, ...variableParam } });
  };

  render() {
    const { classes, dialog } = this.props;
    const { variable, isAdd, errorMessage } = this.state;

    let is_disabled; //= variable.name.length === 0 || variable.value.length === 0
    if (variable.dtype === 'date' && variable.dateFormat === 'custom_format') {
      is_disabled =
        variable.name.length === 0 ||
        variable.value.length === 0 ||
        variable.dateCustomFormat?.length === 0;
    } else if (
      variable.dtype === 'timestamp' &&
      variable.timestampFormat === 'custom_format'
    ) {
      is_disabled =
        variable.name.length === 0 ||
        variable.value.length === 0 ||
        variable.timestampCustomFormat?.length === 0;
    } else if (
      variable.dtype === 'time' &&
      variable.timeFormat === 'custom_format'
    ) {
      is_disabled =
        variable.name.length === 0 ||
        variable.value.length === 0 ||
        variable.timeCustomFormat?.length === 0;
    } else {
      is_disabled = variable.name.length === 0 || variable.value.length === 0;
    }

    if (dialog) {
      return (
        <Dialog open={dialog.open} onClose={dialog.onClose} fullWidth={true}>
          <DialogTitle>変数を{isAdd ? '追加' : '編集'}</DialogTitle>
          <form onSubmit={this.handleSave}>
            <DialogContent>
              <VariableFrom
                variable={variable}
                onChange={this.onChangeVariable}
              />
              {errorMessage && (
                <pre className={classes.error}>{errorMessage}</pre>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.props.handleCancel}>キャンセル</Button>
              <Button
                disabled={is_disabled}
                variant="contained"
                color="primary"
                type="submit"
              >
                {isAdd ? '追加' : '更新'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      );
    } else {
      return (
        <>
          <Typography variant="h4">
            共通変数を {isAdd ? '追加' : '編集'}
          </Typography>
          <form onSubmit={this.handleSave}>
            <div className={classes.saveBar}>
              <div style={{ flex: 1 }} />
              <div className={classes.buttonWrapper}>
                <Button
                  style={{
                    backgroundColor: NodeColor[NodeStatus.Executed].dark
                  }}
                  variant="contained"
                  color="primary"
                  disabled={is_disabled}
                  type="submit"
                >
                  <SaveIcon />
                  保存
                </Button>
              </div>
            </div>
            <VariableFrom
              variable={variable}
              onChange={this.onChangeVariable}
            />
          </form>
          {errorMessage && <pre className={classes.error}>{errorMessage}</pre>}
        </>
      );
    }
  }
}

export default withStyles(styles)(VariableEditor);
