enum ActionTypes {
  // previewDialog
  OpenPreviewDialog = 'OPEN_PREVIEW_DIALOG',
  ClosePreviewDialog = 'CLOSE_PREVIEW_DIALOG',

  // Node Actions
  CreateNode = 'CREATE_NODE',
  SelectNode = 'SELECT_NODE',
  UpdateNode = 'UPDATE_NODE',
  DeleteNode = 'DELETE_NODE',
  CreateLink = 'CREATE_LINK',
  RemoveLink = 'REMOVE_LINK',
  UpdatePositions = 'UPDATE_POSITIONS',
  UpdateNodeCoordinates = 'UPDATE_NODE_COORDINATES',
  SetTemporaryFormValue = 'SET_TEMPORARY_FORMVALUE',
  ReceiveError = 'RECEIVE_ERROR',
  UpdateNodeComment = 'UPDATE_NODE_COMMENT',
  ValidateColumnInFormValues = 'VALIDATE_COLUMN_IN_FORMVALUES',
  UpdateGraphView = 'UPDATE_GRAPH_VIEW',
  CopyNodes = 'COPY_NODES',
  PasteNodes = 'PASTE_NODES',
  ClearTemporaryItems = 'CLEAR_TEMPORARY_ITEMS',
  UpdateCheckedNodes = 'UPDATE_CHECKED_NODES', // チェック状態のnodeID一覧をもらう
  UpdateNodeChecked = 'UPDATE_NODE_CHECK', // nodeのチェック状態を更新する
  UpdateChart = 'UPDATE_CHART',
  UndoGraph = 'UNDO_GRAPH',
  RedoGraph = 'REDO_GRAPH',

  // Workflow
  NodeStatusUpdate = 'NODE_STATUS_UPDATE',
  SelectWorkflow = 'SELECT_WORKFLOW',
  AddWorkflow = 'ADD_WORKFLOW',
  RenameWorkflow = 'RENAME_WORKFLOW',
  DeleteWorkflow = 'DELETE_WORKFLOW',
  SelectWorkflowNode = 'SELECT_WORKFLOW_NODE',
  MoveWorkflow = 'MOVE_WORKFLOW',

  // Variable
  AddVariable = 'ADD_VARIABLE',
  EditVariable = 'EDIT_VARIABLE',
  DeleteVariable = 'DELETE_VARIABLE',
  AddBuilderVariable = 'ADD_BUILDER_VARIABLE',
  EditBuilderVariable = 'EDIT_BUILDER_VARIABLE',
  DeleteBuilderVariable = 'DELETE_BUILDER_VARIABLE',

  // Execution
  StartExecute = 'START_EXECUTE',

  // Datasource
  LoadingDatasources = 'LOADING_DATASOURCES',
  LoadDatasources = 'LOAD_DATASOURCES',
  UpdateOutPortsObjectType = 'UPDATE_OUT_PORTS_OBJECT_TYPE',

  // Process List
  ReceiveModuleData = 'RECEIVE_MODULE_DATA',
  ClearModuleData = 'CLEAR_MODULE_DATA',

  // Project
  LoadProject = 'LOAD_PROJECT',
  LoadingProject = 'LOADING_PROJECT',
  SavingProject = 'SAVING_PROJECT',
  SaveProjectSuccess = 'SAVE_PROJECT_SUCCESS',
  SaveProjectFail = 'SAVE_PROJECT_FAIL',
  ProjectStatusUpdate = 'PROJECT_STATUS_UPDATE',
  PreExecutionError = 'PRE_EXECUTION_ERROR',

  // BuilderProject
  LoadBuilder = 'LOAD_BUILDER',
  SavingBuilder = 'SAVING_BUILDER',
  SaveBuilderSuccess = 'SAVE_BUILDER_SUCCESS',
  SaveBuilderFail = 'SAVE_BUILDER_FAIL',
  BuilderStatusUpdate = 'BUILDER_STATUS_UPDATE',
  StartBuilderExecute = 'START_BUILDER_EXECUTE',
  InitializeBuilder = 'INITIALIZE_BUILDER',

  // BuilderWorkflow
  SelectBuilderWorkflow = 'SELECT_BUILDER_WORKFLOW',
  AddBuilderWorkflow = 'ADD_BUILDER_WORKFLOW',
  RenameBuilderWorkflow = 'RENAME_BUILDER_WORKFLOW',
  DeleteBuilderWorkflow = 'DELETE_BUILDER_WORKFLOW',
  MoveBuilderWorkflow = 'MOVE_BUILDER_WORKFLOW',
  SelectBuilderWorkflowNode = 'SELECT_BUILDER_WORKFLOW_NODE',

  // BuilderNode
  SelectBuilderNode = 'SELECT_BUILDER_NODE',
  CreateBuilderNode = 'CREATE_BUILDER_NODE',
  UpdateBuilderNode = 'UPDATE_BUILDER_NODE',
  DeleteBuilderNode = 'DELETE_BUILDER_NODE',
  UpdateBuilderNodeStatus = 'UPDATE_BUILDER_NODE_STATUS',
  ClearBuilderTemporaryItems = 'CLEAR_BUILDER_TEMPORARY_ITEMS',
  SetTemporaryBuilderFormValue = 'SET_TEMPORARY_BUILDER_FORMVALUE',
  CreateBuilderLink = 'CREATE_BUILDER_LINK',
  RemoveBuilderLink = 'REMOVE_BUILDER_LINK',
  UpdateBuilderPositions = 'UPDATE_BUILDER_POSITIONS',
  UpdateBuilderNodeComment = 'UPDATE_BUILDER_NODE_COMMENT',
  UpdateBuilderNodeCoordinates = 'UPDATE_BUILDER_NODE_COORDINATES',
  CopyBuilderNodes = 'COPY_BUILDER_NODES',
  PasteBuilderNodes = 'PASTE_BUILDER_NODES',
  UpdateCheckedBuilderNodes = 'UPDATE_CHECKED_BUILDER_NODES',
  UpdateBuilderGraphView = 'UPDATE_BUILDER_GRAPH_VIEW',
  UpdateBuilderNodeChecked = 'UPDATE_BUILDER_NODE_CHECK', // nodeのチェック状態を更新する
  UndoBuilderGraph = 'UNDO_BUILDER_GRAPH',
  RedoBuilderGraph = 'REDO_BUILDER_GRAPH',

  // WebSocket
  SocketConnecting = 'SOCKET_CONNECTING',
  SocketConnected = 'SOCKET_CONNECTED',
  SocketClosed = 'SOCKET_CLOSED',
  SocketError = 'SOCKET_ERROR',

  // Data Fetch Status
  DataFetch = 'DATA_FETCH',
  DataFetchMulti = 'DATA_FETCH_MULTI',
  DataFetchStart = 'DATA_FETCH_START',
  DataFetchSuccess = 'DATA_FETCH_SUCCESS',
  DataFetchFailure = 'DATA_FETCH_FAILURE',

  // Builder Data Fetch Status
  BuilderDataFetchStart = 'BUILDER_DATA_FETCH_START',
  BuilderDataFetchSuccess = 'BUILDER_DATA_FETCH_SUCCESS',
  BuilderDataFetchFailure = 'BUILDER_DATA_FETCH_FAILURE',
  BuilderDataFetchStartMulti = 'BUILDER_DATA_FETCH_START_MULTI',
  BuilderDataFetchFailureMulti = 'BUILDER_DATA_FETCH_FAILURE_MULTI',

  // Config
  UserConfigHelpStatus = 'USER_CONFIG_HELP_STATUS',
  UserConfigNotificationStatus = 'USER_CONFIG_NOTIFICATION_STATUS',
  UserConfigDescribeStatus = 'USER_CONFIG_DESCRIBE_STATUS',

  // Variable
  LoadVariables = 'LOAD_VARIABLES'
}

export default ActionTypes;
