import * as React from 'react';
import {
  AutocompleteForm,
  AutocompleteItemIconType,
  Option
} from 'ui/listView/filter/autocompleteForm';
import { FilterType } from 'ui/listView/filter/type';
import { handleChangeCreators } from 'ui/listView/filter/handler';
import { FilterContext } from 'ui/listView/filter/context';

export const deleteUserOption: Option = {
  label: '削除されたユーザー',
  value: '__deleted_user_email__'
};

export const CreatorRow: React.FC<{
  emails: string[];
}> = ({ emails }) => {
  const { state: filterState, dispatch: filterDispatch } =
    React.useContext(FilterContext);

  const options: Option[] = React.useMemo(() => {
    return [
      ...emails.map((email) => ({ label: email, value: email })),
      deleteUserOption
    ];
  }, [emails]);

  const onChange = React.useCallback(
    (_: React.ChangeEvent<{}>, value: Option[]) => {
      handleChangeCreators(filterState, filterDispatch)(
        _,
        value.map((v) => v.value)
      );
    },
    [filterState, filterDispatch]
  );

  return (
    <AutocompleteForm
      title="作成者"
      options={options}
      value={filterState[FilterType.Creators]}
      type={AutocompleteItemIconType.Account}
      onChange={onChange}
    />
  );
};
