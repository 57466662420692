import * as React from 'react';
import { Autocomplete } from '@material-ui/lab';
import { Avatar, Chip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AccountCircle, Group } from '@material-ui/icons';
import { getDatasourceExpression } from 'libs/datasources';
import { TextField } from 'ui/common/textfield';
import { destinationMap } from 'models/notificationDst/base';

export const AutocompleteItemIconType = {
  Account: 'account',
  Datasource: 'datasource',
  NotificationDst: 'notificationDst',
  User: 'user',
  UserGroup: 'userGroup'
} as const;
export type TypeAutocompleteItemIconType =
  (typeof AutocompleteItemIconType)[keyof typeof AutocompleteItemIconType];

const useStyles = makeStyles({
  list: {
    width: '360px'
  }
});

export interface Option {
  label: string;
  value: string;
}

export interface AutocompleteFormProps {
  title: string;
  options: Option[];
  value: string[];
  type: TypeAutocompleteItemIconType;
  onChange: (event: React.ChangeEvent<{}>, value: Option[]) => void;
}

export const AutocompleteForm: React.FC<AutocompleteFormProps> = ({
  title,
  options,
  value,
  type,
  onChange
}) => {
  const classes = useStyles();

  const autocompleteItemLabel = (
    type: TypeAutocompleteItemIconType,
    option: Option | undefined
  ): string => {
    if (!option) return '';
    switch (type) {
      case AutocompleteItemIconType.Account:
      case AutocompleteItemIconType.User:
      case AutocompleteItemIconType.UserGroup:
        return option.label;
      case AutocompleteItemIconType.Datasource:
        return getDatasourceExpression(option.value).name;
      case AutocompleteItemIconType.NotificationDst: {
        const dest = destinationMap[option.value];
        return dest ? dest.name : '';
      }
      default:
        return '';
    }
  };

  const renderOption = React.useCallback((option: Option | undefined) => {
    if (!option) return <></>;
    return (
      <Typography style={{ display: 'flex', alignItems: 'center' }}>
        <AutocompleteItemIcon type={type} option={option} />
        &nbsp;{autocompleteItemLabel(type, option)}
      </Typography>
    );
  }, []);

  const renderTags = React.useCallback((value: Option[], getTagProps) => {
    return value.map((option, index) => {
      return (
        <Chip
          key={index}
          avatar={
            <Avatar>
              <AutocompleteItemIcon type={type} option={option} />
            </Avatar>
          }
          label={autocompleteItemLabel(type, option)}
          {...getTagProps({ index })}
        />
      );
    });
  }, []);

  return (
    <tr>
      <td>{title}</td>
      <td>
        <Autocomplete
          multiple
          disableCloseOnSelect={true}
          options={options}
          getOptionLabel={(option) => option?.label || ''}
          getOptionSelected={(option, value) => option?.value === value?.value}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} />}
          renderOption={renderOption}
          renderTags={renderTags}
          value={value.map((v) => options.find((o) => o.value === v))}
          classes={{ root: classes.list }}
        />
      </td>
    </tr>
  );
};

const AutocompleteItemIcon: React.FC<{
  type: TypeAutocompleteItemIconType;
  option: Option | undefined;
}> = ({ type, option }) => {
  if (!option) return <></>;
  switch (type) {
    case AutocompleteItemIconType.User:
    case AutocompleteItemIconType.Account:
      return (
        <>
          <AccountCircle />
        </>
      );
    case AutocompleteItemIconType.Datasource: {
      const expr = getDatasourceExpression(option.value);
      return (
        <>
          {expr.logoPath && (
            <img alt={option.value} src={expr.logoPath} width="20" />
          )}
        </>
      );
    }
    case AutocompleteItemIconType.NotificationDst: {
      const dest = destinationMap[option.value];
      if (!dest) return <></>;
      return (
        <>
          {typeof dest.logo === 'string' && (
            <img
              src={dest.logo}
              style={{
                height: '20px'
              }}
            />
          )}
          {React.isValidElement(dest.logo) && (
            <div
              style={{
                height: '20px'
              }}
            >
              {dest.logo}
            </div>
          )}
        </>
      );
    }
    case AutocompleteItemIconType.UserGroup:
      return <Group />;

    default:
      return null;
  }
};

export const toOptions = (values: string[]): Option[] => {
  return values.map((value) => ({ label: value, value }));
};
