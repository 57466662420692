import * as React from 'react';

export function IconDate() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 17"
      style={{ minWidth: 16 }}
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="#787FB7" />
        <g transform="translate(3.5 2.5)">
          <rect width="9" height="9" x=".5" y="1.5" stroke="#FFF" rx="1" />
          <rect width="2" height="3" x="2" fill="#FFF" rx="1" />
          <rect width="2" height="3" x="6" fill="#FFF" rx="1" />
          <rect width="2" height="2" x="2" y="4" fill="#FFF" rx="1" />
          <rect width="2" height="2" x="2" y="7" fill="#FFF" rx="1" />
          <rect width="2" height="2" x="6" y="4" fill="#FFF" rx="1" />
          <rect width="2" height="2" x="6" y="7" fill="#FFF" rx="1" />
        </g>
      </g>
    </svg>
  );
}
