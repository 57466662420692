import { RangeNumberParameter } from 'models/report';
import { ParameterClass } from './base';

export class NumberRangeClass implements ParameterClass {
  constructor(public param: RangeNumberParameter) {}
  isValid = () => {
    return this.param.min != undefined && this.param.max != undefined;
  };
  toDisplayString = () => {
    return `${this.param.min} ~ ${this.param.max}`;
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
}
