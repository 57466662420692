import * as Comlink from 'comlink';
import { Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { getAuth } from 'firebase/auth';

import {
  DataFetchWorker,
  DataFetchWorkerResponse,
  DataFetchWorkerResponseTypes
} from '../workers/data_fetch_worker';
import DFWorker from '../workers/data_fetch_worker?worker';
import ActionTypes from 'actions/actionTypes';
import { db } from '../database';
import { firebaseApp } from '../firebase';

export type FetchDataType = 'projects' | 'builders';

export function fetchDataMulti(
  projectId: string,
  portIds: Array<{ portId: string; executionId?: string }>,
  type: FetchDataType,
  token?: string,
  onSuccess?: (projectId: string, portId: string) => any,
  onFailure?: (projectId: string, portId: string) => any
) {
  return {
    type: ActionTypes.DataFetchMulti,
    payload: {
      projectId,
      portIds,
      type,
      token,
      onSuccess,
      onFailure
    }
  };
}

export function fetchData(
  projectId: string,
  portId: string,
  type: FetchDataType,
  executionId?: string,
  token?: string,
  onSuccess?: (projectId: string, portId: string) => any,
  onFailure?: (projectId: string, portId: string) => any
) {
  return {
    type: ActionTypes.DataFetch,
    payload: {
      projectId,
      portId,
      executionId,
      token,
      type,
      onSuccess,
      onFailure
    }
  };
}

function fetchStart(projectId: string, portId: string, token?: string) {
  return {
    type: ActionTypes.DataFetchStart,
    payload: {
      projectId,
      portId,
      token
    }
  };
}

function fetchSuccess(projectId: string, portId: string, executionId: string) {
  return {
    type: ActionTypes.DataFetchSuccess,
    payload: {
      projectId,
      portId,
      executionId
    }
  };
}

function fetchFailure(projectId: string, portId: string, error: string) {
  return {
    type: ActionTypes.DataFetchFailure,
    payload: {
      projectId,
      portId,
      error
    }
  };
}

function builderFetchStart(projectId: string, portId: string, token?: string) {
  return {
    type: ActionTypes.BuilderDataFetchStart,
    payload: {
      projectId,
      portId,
      token
    }
  };
}

function builderFetchSuccess(
  projectId: string,
  portId: string,
  executionId: string
) {
  return {
    type: ActionTypes.BuilderDataFetchSuccess,
    payload: {
      projectId,
      portId,
      executionId
    }
  };
}

function builderFetchFailure(projectId: string, portId: string, error: string) {
  return {
    type: ActionTypes.BuilderDataFetchFailure,
    payload: {
      projectId,
      portId,
      error
    }
  };
}

export type FetchActionTypes =
  | ReturnType<typeof fetchData>
  | ReturnType<typeof fetchStart>
  | ReturnType<typeof fetchSuccess>
  | ReturnType<typeof fetchFailure>;

async function handlePayload(
  dispatch,
  executor,
  payload: ReturnType<typeof fetchData>['payload']
) {
  const { projectId, portId, executionId, token, onSuccess, onFailure, type } =
    payload;
  const success = type === 'projects' ? fetchSuccess : builderFetchSuccess;

  let lastModified: string | undefined;
  const summary = await db.getPortSummary(projectId, portId);
  if (summary != undefined) {
    if (summary.executionId === executionId) {
      dispatch(success(projectId, portId, executionId!));
      return;
    }
    lastModified = summary.lastModified;
  }

  // 実行中か、すでにキューイングされていたらなにもしない
  if (executor.executingOrQueued(projectId, portId)) {
    // tslint:disable-next-line
    console.warn(projectId, portId, 'is already queued or running');
    return;
  }
  const start = type === 'projects' ? fetchStart : builderFetchStart;
  dispatch(start(projectId, portId, token));
  const resp = await executor.execute(projectId, portId, lastModified, token);
  if (resp.type === DataFetchWorkerResponseTypes.Success) {
    dispatch(success(projectId, portId, resp.executionId));
    if (onSuccess) {
      onSuccess(projectId, portId);
    }
  } else if (resp.type === DataFetchWorkerResponseTypes.NotModified) {
    dispatch(success(projectId, portId, executionId!));
  } else {
    const failure = type === 'projects' ? fetchFailure : builderFetchFailure;
    dispatch(failure(projectId, portId, resp.error));
    if (onFailure) {
      onFailure(projectId, portId);
    }
  }
}

export function dataFetcher() {
  let executor: FetchExecutor;

  const mw: Middleware =
    ({ dispatch }: MiddlewareAPI) =>
    (next: Dispatch) =>
    async (
      action:
        | ReturnType<typeof fetchData>
        | ReturnType<typeof fetchDataMulti>
        | any
    ) => {
      next(action);

      if (
        action.type !== ActionTypes.DataFetch &&
        action.type !== ActionTypes.DataFetchMulti
      ) {
        return;
      }

      if (!executor) {
        executor = new FetchExecutor(() => {
          const currentUser = getAuth(firebaseApp).currentUser;
          if (currentUser == undefined) {
            const dummyToken = async () => {
              return undefined;
            };
            return dummyToken();
          }
          return currentUser.getIdToken(false);
        });
      }

      if (action.type === ActionTypes.DataFetch) {
        handlePayload(dispatch, executor, action.payload);
      } else if (action.type === ActionTypes.DataFetchMulti) {
        action.payload.portIds.forEach((p) => {
          handlePayload(dispatch, executor, {
            ...action.payload,
            portId: p.portId,
            executionId: p.executionId
          });
        });
      }
    };

  return mw;
}

class FetchExecutor {
  private maxThreads = 5;
  private queue: Array<{
    projectId: string;
    portId: string;
    lastModified?: string;
    token?: string;
    resolve?: (val: DataFetchWorkerResponse) => void;
  }> = [];
  private pool: DataFetchWorker[] = [];
  private running: Array<{ projectId: string; portId: string }> = [];

  constructor(private getAuthToken: () => Promise<string | undefined>) {}

  public execute = async (
    projectId: string,
    portId: string,
    lastModified?: string,
    token?: string
  ): Promise<DataFetchWorkerResponse> => {
    if (this.running.length >= this.maxThreads) {
      return new Promise((resolve) => {
        this.queue.push({ projectId, portId, lastModified, token, resolve });
      });
    } else {
      return this.startWorker(projectId, portId, lastModified, token);
    }
  };

  public executingOrQueued(projectId: string, portId: string): boolean {
    return (
      this.running.find(
        (r) => r.projectId === projectId && r.portId === portId
      ) != undefined ||
      this.queue.find(
        (q) => q.projectId === projectId && q.portId === portId
      ) != undefined
    );
  }

  private startWorker = async (
    projectId: string,
    portId: string,
    lastModified?: string,
    token?: string,
    resolve?: (val: DataFetchWorkerResponse) => void
  ) => {
    this.running.push({ projectId, portId });
    let worker = this.pool.shift();
    if (!worker) {
      const Fetcher = Comlink.wrap<typeof DataFetchWorker>(new DFWorker());
      worker = await new Fetcher();
    }
    let isSharedToken = true;
    let dataToken = token;
    if (dataToken == undefined) {
      dataToken = await this.getAuthToken();
      isSharedToken = false;
    }
    const resp = await worker.fetchData(
      projectId,
      portId,
      isSharedToken,
      lastModified,
      dataToken
    );
    this.releaseWorker(worker, projectId, portId);
    this.dequeue();
    if (resolve) {
      resolve(resp);
    }
    return resp;
  };

  private releaseWorker = (
    worker: DataFetchWorker,
    projectId: string,
    portId: string
  ) => {
    this.running = this.running.filter(
      (r) => r.projectId !== projectId && r.portId !== portId
    );
    this.pool.push(worker);
  };

  private dequeue = async () => {
    const args = this.queue.shift();
    if (args) {
      this.startWorker(
        args.projectId,
        args.portId,
        args.lastModified,
        args.token,
        args.resolve
      );
    }
  };
}
