import * as React from 'react';
import { Grid } from '@material-ui/core';
import MainLayout from 'components/layouts/main';

export default () => {
  return (
    <MainLayout selectedMenu="manual">
      <Grid container={true} justifyContent="center">
        <iframe
          style={{
            border: 'none',
            width: '100%',
            height: 'calc(100vh - 64px)'
          }}
          src={'https://octopus.nehan.io/support_contents/tutorial/'}
        />
      </Grid>
    </MainLayout>
  );
};
