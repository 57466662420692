import { produce } from 'immer';

import ActionTypes from '../actions/actionTypes';
import nodeReducer from './graph';
import {
  Edges,
  EdgeStatus,
  Nodes,
  NodeStatus,
  Ports,
  PortStatus
} from 'models/graph';
import {
  findWorkflowByPortId,
  findWorkflowIndex,
  generateEdgeId,
  generateNodeId,
  generatePortId,
  getChildrenNodes
} from 'components/diagram/utils';
import { cloneDeep, intersection } from 'lodash-es';
import {
  ChartStatusUpdate,
  NodeBackend,
  NodeStatusUpdate,
  PreExecutionErrorResponse
} from 'models/websocket';
import { findProcessById } from 'Utils/filterProcessList';
import { ProcessItem } from 'reducers/processList';
import { reorderByOrderNumber } from 'libs/tab';
import {
  BuilderWorkflow,
  ExecuteStatus,
  Project,
  ProjectSaveStatus,
  Workflow
} from 'models/project';
import { cutArray, UndoType } from 'reducers/undo';

export interface ProjectState
  extends Omit<Project, 'status' | 'current_workflow_index'> {
  loading: boolean;
  projectLoaded: boolean;
  currentWorkflowIndex: number;
  saveStatus: ProjectSaveStatus;
  memory: number;
  error?: string;
  copyNodeIds: string[];
  copySourceWorkflowId: string | number | null;
}

const initialState: ProjectState = {
  loading: false,
  projectLoaded: false,
  saveStatus: ProjectSaveStatus.COMPLETED,
  uuid: '',
  name: '',
  memory: 0,
  workflows: [],
  currentWorkflowIndex: 0,
  hasVisualizeNodes: {},
  hasReportVisualizeNodes: {},
  variables: [],
  accessLevel: 0,
  copyNodeIds: [],
  copySourceWorkflowId: null,
  public_flag: true,
  scheduling_locked: false
};

function selectWorkflowNode(
  state: ProjectState,
  nodeId: string
): Partial<ProjectState> {
  const workflowIndex = findWorkflowIndex(state.workflows, nodeId);
  if (workflowIndex === -1) {
    return {};
  }
  return produce(state, (draft) => {
    draft.currentWorkflowIndex = workflowIndex;
    const graph = draft.workflows[workflowIndex].graph;
    graph.selectedNodeId = nodeId;
  });
}

function updatePortStatus(
  workflows: Workflow[],
  payload: { portId: string; status: PortStatus }
): Workflow[] {
  const { portId, status } = payload;
  return produce(workflows, (draft) => {
    const workflowIndex = findWorkflowByPortId(portId, draft);
    if (draft[workflowIndex]) {
      draft[workflowIndex].graph.ports[portId].status = status;
    }
  });
}

export function setPortLabel(
  workflow: Workflow | BuilderWorkflow,
  modules: ProcessItem[]
): Workflow | BuilderWorkflow {
  if (modules.length === 0) {
    return workflow;
  }
  // portに日本語ラベルを割り当てる
  const { graph } = workflow;
  const { nodes, ports } = graph;

  const portLabelMap: { [portId: string]: string } = {};
  Object.keys(nodes).forEach((nodeId) => {
    const { name: processName, inPorts, outPorts } = nodes[nodeId];
    const processItem = findProcessById(modules, processName);
    if (processItem == undefined) {
      return;
    }

    if (inPorts.length > 0 && processItem.inConf) {
      processItem.inConf.forEach((conf, i) => {
        portLabelMap[inPorts[i]] = conf.portLabel;
      });
    }

    if (outPorts.length > 0 && processItem.outConf) {
      processItem.outConf.forEach((conf, i) => {
        portLabelMap[outPorts[i]] = conf.portLabel;
      });
    }
  });

  Object.keys(ports).forEach((portId) => {
    ports[portId]['portLabel'] = portLabelMap[portId];
  });

  workflow.graph.ports = ports;
  return workflow;
}

export function cloneNodes(
  ids: string[],
  graph: { nodes: Nodes; ports: Ports; edges: Edges }
) {
  const newEdgeIds = {};
  const newPortIds = {};
  const newNodeIds = {};

  const copyNodes: Nodes = {};
  const copyEdges: Edges = {};
  const copyPorts: Ports = {};
  const { nodes, ports, edges } = graph;
  ids.forEach((nodeId) => {
    newNodeIds[nodeId] = generateNodeId();
    nodes[nodeId].inPorts.forEach((portId) => {
      newPortIds[portId] = generatePortId();
    });
    nodes[nodeId].outPorts.forEach((portId) => {
      newPortIds[portId] = generatePortId();
    });
  });

  Object.keys(edges).forEach((edgeId) => {
    if (
      ids.indexOf(edges[edgeId].from.nodeId) !== -1 &&
      ids.indexOf(edges[edgeId].to.nodeId) !== -1
    ) {
      newEdgeIds[edgeId] = generateEdgeId();
    }
  });

  Object.keys(newNodeIds).forEach((nodeId) => {
    const copyNode = { ...nodes[nodeId] };
    const { comment, autoComment, name, processType } = copyNode;
    copyNodes[newNodeIds[nodeId]] = {
      id: newNodeIds[nodeId],
      name,
      processType,
      inPorts: copyNode.inPorts.map((portId) => {
        return newPortIds[portId];
      }),
      outPorts: copyNode.outPorts.map((portId) => {
        return newPortIds[portId];
      }),
      formValues: cloneDeep(copyNode.formValues),
      formErrors: cloneDeep(copyNode.formErrors),
      status: NodeStatus.Copied,
      backend: NodeBackend.Python,
      x: copyNode.x + 100,
      y: copyNode.y + 100,
      errorMessage: undefined,
      comment,
      autoComment,
      execution_id: undefined,
      rows: copyNode.rows
    };
  });
  Object.keys(newPortIds).forEach((portId) => {
    copyPorts[newPortIds[portId]] = {
      ...ports[portId],
      id: newPortIds[portId],
      status: PortStatus.Copied
    };
  });

  Object.keys(newEdgeIds).forEach((edgeId) => {
    const { from, to } = edges[edgeId];
    copyEdges[newEdgeIds[edgeId]] = {
      id: newEdgeIds[edgeId],
      from: {
        nodeId: newNodeIds[from.nodeId],
        portId: newPortIds[from.portId]
      },
      to: { nodeId: newNodeIds[to.nodeId], portId: newPortIds[to.portId] },
      status: EdgeStatus.Copied
    };
  });
  return {
    nodes: copyNodes,
    edges: copyEdges,
    ports: copyPorts
  };
}

function pasteNodes(state: ProjectState): ProjectState {
  const { currentWorkflowIndex: widx, workflows, copyNodeIds: nodeIds } = state;
  const workflowIndex = workflows.findIndex((wf) => {
    return intersection(Object.keys(wf.graph.nodes), nodeIds).length > 0;
  });

  if (workflowIndex < 0) {
    return state;
  }

  const sourceWF = workflows[workflowIndex];
  const { graph } = sourceWF;
  const { nodes, edges, ports } = cloneNodes(nodeIds, graph);

  return produce(state, (draft) => {
    draft.workflows[widx].graph.nodes = {
      ...workflows[widx].graph.nodes,
      ...nodes
    };
    draft.workflows[widx].graph.edges = {
      ...workflows[widx].graph.edges,
      ...edges
    };
    draft.workflows[widx].graph.ports = {
      ...workflows[widx].graph.ports,
      ...ports
    };
    draft.copyNodeIds = [];
    draft.workflows[widx].checkedIds = Object.keys(nodes);
    draft.workflows[widx].graph.undoList.push({
      type: UndoType.CREATE_NODE_EDGE,
      params: {
        nodes: Object.keys(nodes)
      }
    });
    draft.workflows[widx].graph.undoList = cutArray(
      draft.workflows[widx].graph.undoList
    );
    draft.workflows[widx].graph.redoList = [];
  });
}

export default function project(
  state: ProjectState = initialState,
  action
): ProjectState {
  switch (action.type) {
    case ActionTypes.LoadProject: {
      if (action.error) {
        return {
          ...state,
          loading: false,
          projectLoaded: false,
          error: action.payload
        };
      }

      const {
        data: {
          current_workflow_index,
          workflows,
          uuid,
          name,
          hasVisualizeNodes,
          hasReportVisualizeNodes,
          variables,
          access_level,
          public_flag,
          scheduling_locked
        },
        modules,
        nodeId
      } = action.payload;

      const workflowIndex =
        current_workflow_index && workflows[current_workflow_index]
          ? current_workflow_index
          : 0;

      if (modules.length > 0) {
        workflows.map((workflow) => {
          return setPortLabel(workflow, modules);
        });
      }
      workflows.forEach((w) => {
        w.checkedIds = [];
        w.graph.undoList = [];
        w.graph.redoList = [];
      });

      let newState: ProjectState = {
        ...state,
        loading: false,
        projectLoaded: true,
        error: undefined,
        uuid,
        name,
        workflows,
        currentWorkflowIndex: workflowIndex,
        hasVisualizeNodes,
        hasReportVisualizeNodes,
        variables: variables || [],
        accessLevel: access_level,
        public_flag,
        copyNodeIds: [],
        scheduling_locked
      };
      if (nodeId) {
        newState = { ...newState, ...selectWorkflowNode(newState, nodeId) };
      }
      return newState;
    }

    case ActionTypes.LoadingProject:
      return {
        ...state,
        projectLoaded: false,
        loading: true,
        error: undefined
      };

    case ActionTypes.SavingProject:
      return { ...state, saveStatus: ProjectSaveStatus.IN_PROGRESS };

    case ActionTypes.SaveProjectSuccess:
      return { ...state, saveStatus: ProjectSaveStatus.COMPLETED };

    case ActionTypes.SaveProjectFail:
      return { ...state, saveStatus: ProjectSaveStatus.ERROR };

    case ActionTypes.AddVariable:
      return {
        ...state,
        variables: [...state.variables, action.payload.variable]
      };

    case ActionTypes.EditVariable: {
      const newVars = [...state.variables];
      newVars[action.payload.index] = action.payload.variable;
      return {
        ...state,
        variables: newVars
      };
    }

    case ActionTypes.DeleteVariable: {
      const newVars = [...state.variables];
      newVars.splice(action.payload.index, 1);
      return {
        ...state,
        variables: newVars
      };
    }

    case ActionTypes.SelectWorkflow:
      return { ...state, currentWorkflowIndex: action.payload.index };

    case ActionTypes.AddWorkflow: {
      const { workflow, modules, hasVisualizeNodes, hasReportVisualizeNodes } =
        action.payload;
      const labeledWorkflow = setPortLabel(workflow, modules) as Workflow;
      labeledWorkflow.checkedIds = [];
      labeledWorkflow.graph.undoList = [];
      labeledWorkflow.graph.redoList = [];
      return {
        ...state,
        hasVisualizeNodes: { ...state.hasVisualizeNodes, ...hasVisualizeNodes },
        hasReportVisualizeNodes: {
          ...state.hasReportVisualizeNodes,
          ...hasReportVisualizeNodes
        },
        currentWorkflowIndex: state.workflows.length, // workflowsの最新index + 1に設定する
        workflows: [...state.workflows, labeledWorkflow]
      };
    }

    case ActionTypes.RenameWorkflow: {
      const w = state.workflows[action.payload.index];
      if (w == undefined) {
        return state;
      }

      return {
        ...state,
        workflows: [
          ...state.workflows.slice(0, action.payload.index),
          { ...w, name: action.payload.name },
          ...state.workflows.slice(action.payload.index + 1)
        ]
      };
    }

    case ActionTypes.DeleteWorkflow: {
      let workflowIndex = state.currentWorkflowIndex;
      if (workflowIndex > state.workflows.length - 2) {
        workflowIndex = state.workflows.length - 2;
      }
      return {
        ...state,
        currentWorkflowIndex: workflowIndex,
        workflows: [
          ...state.workflows.slice(0, action.payload.index),
          ...state.workflows.slice(action.payload.index + 1)
        ]
      };
    }

    case ActionTypes.MoveWorkflow: {
      const { index, targetIndex } = action.payload;
      const { workflows } = state;

      if (targetIndex < 0 || workflows.length <= targetIndex) {
        return state;
      }

      const newWorkflows = reorderByOrderNumber(workflows, index, targetIndex);

      return {
        ...state,
        currentWorkflowIndex: targetIndex,
        workflows: newWorkflows
      };
    }

    case ActionTypes.SelectWorkflowNode:
      return { ...state, ...selectWorkflowNode(state, action.payload.nodeId) };

    case ActionTypes.StartExecute: {
      const { selectedNodeId } = action.payload;
      return produce(state, (draft) => {
        draft.workflows[state.currentWorkflowIndex].status =
          ExecuteStatus.EXECUTING;
        const { nodes, edges } =
          state.workflows[state.currentWorkflowIndex].graph;
        const nodeIds = selectedNodeId
          ? getChildrenNodes(selectedNodeId, nodes, edges, true)
          : Object.keys(nodes);
        nodeIds.unshift(selectedNodeId);
        nodeIds.forEach((nodeId) => {
          const node =
            draft.workflows[state.currentWorkflowIndex].graph.nodes[nodeId];
          if (node == undefined) {
            return;
          }
          if (selectedNodeId && node.status === NodeStatus.Temporary) {
            return;
          }

          node.status = NodeStatus.Executing;
          node.inPorts.forEach((pid) => {
            draft.workflows[state.currentWorkflowIndex].graph.ports[
              pid
            ].status = PortStatus.Created;
          });
          node.outPorts.forEach((pid) => {
            draft.workflows[state.currentWorkflowIndex].graph.ports[
              pid
            ].status = PortStatus.Created;
          });
          Object.keys(edges).forEach((eid) => {
            if (edges[eid].to.nodeId === node.id) {
              draft.workflows[state.currentWorkflowIndex].graph.edges[
                eid
              ].status = EdgeStatus.Created;
            }
          });
        });
      });
    }

    case ActionTypes.DataFetchStart:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Loading
        })
      };
    case ActionTypes.DataFetchSuccess:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Loaded
        })
      };

    case ActionTypes.DataFetchFailure:
      return {
        ...state,
        workflows: updatePortStatus(state.workflows, {
          portId: action.payload.portId,
          status: PortStatus.Error
        })
      };

    case ActionTypes.ProjectStatusUpdate:
      return produce(state, (draft) => {
        action.payload.status.forEach((s) => {
          const idx = draft.workflows.findIndex((w) => w.id === s.id);
          if (idx === -1) {
            return;
          }
          draft.workflows[idx].status = s.status;
          draft.workflows[idx].runnerId = s.runnerId;
        });
      });

    case ActionTypes.NodeStatusUpdate: {
      const resp: NodeStatusUpdate = action.payload;
      return produce(state, (draft) => {
        const idx = draft.workflows.findIndex((w) => w.id === resp.workflow_id);
        if (idx !== -1) {
          resp.status.forEach((s) => {
            const node = draft.workflows[idx].graph.nodes[s.node_id];
            if (node) {
              node.status = s.status;
              node.backend = s.backend;
              node.errorMessage = s.errorMessage;
              node.execution_id = s.execution_id;
              node.formValues = s.formValues;
              node.autoComment = s.autoComment;
              node.api_uuid = s.api_uuid;
              node.started_at = s.started_at;
              node.ended_at = s.ended_at;
            }
          });
        }
      });
    }

    case ActionTypes.CopyNodes:
      return produce(state, (draft) => {
        draft.copyNodeIds = [...action.payload.nodeIds];
        draft.copySourceWorkflowId = action.payload.workflowId;
      });

    case ActionTypes.PasteNodes:
      return pasteNodes(state);

    case ActionTypes.PreExecutionError:
      return produce(state, (draft) => {
        const resp: PreExecutionErrorResponse = action.payload;
        const idx = draft.workflows.findIndex((w) => w.id === resp.workflow_id);
        if (idx !== -1) {
          Object.keys(draft.workflows[idx].graph.nodes).forEach((nid) => {
            const node = draft.workflows[idx].graph.nodes[nid];
            if (
              node.status === NodeStatus.Executing ||
              node.status === NodeStatus.Prospecting
            ) {
              node.status = NodeStatus.Error;
              node.errorMessage = resp.message;
            }
          });
        }
      });

    case ActionTypes.UpdateChart:
      return produce(state, (draft) => {
        const resp: ChartStatusUpdate = action.payload;
        if (draft.uuid === resp.project_id) {
          draft.hasVisualizeNodes = resp.chart_nodes;
          draft.hasReportVisualizeNodes = resp.report_chart_nodes;
        }
      });

    case ActionTypes.UpdateCheckedNodes:
      return produce(state, (draft) => {
        draft.workflows[state.currentWorkflowIndex].checkedIds =
          action.payload.nodeIds;
      });

    case ActionTypes.UpdateNodeChecked:
      return produce(state, (draft) => {
        if (action.payload.checked) {
          draft.workflows[state.currentWorkflowIndex].checkedIds.push(
            action.payload.nodeId
          );
        } else {
          draft.workflows[state.currentWorkflowIndex].checkedIds =
            draft.workflows[state.currentWorkflowIndex].checkedIds.filter(
              (id) => id !== action.payload.nodeId
            );
        }
      });

    case ActionTypes.UpdatePositions:
      return produce(state, (draft) => {
        const workflow = draft.workflows.find(
          (wf) => wf.id === action.payload.workflowId
        );
        if (workflow && workflow.graph) {
          workflow.graph.positions = {
            ...workflow.graph.positions,
            ...action.payload.positions
          };
        }
      });

    default: {
      if (state.workflows.length === 0) {
        return state;
      }
      // current workflow内のgraphの更新を行う
      const wf = state.workflows[state.currentWorkflowIndex];
      const newGraph = nodeReducer(wf.graph, action);
      if (wf.graph === newGraph) {
        // shallow equalで一致した場合は、nodeReducerで処理されていない
        return state;
      }

      return {
        ...state,
        workflows: [
          ...state.workflows.slice(0, state.currentWorkflowIndex),
          { ...wf, graph: newGraph },
          ...state.workflows.slice(state.currentWorkflowIndex + 1)
        ]
      };
    }
  }
}
