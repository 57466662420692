import { IconButton, Tooltip } from '@material-ui/core';

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Layers, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Calendars, DayjsValue, NullableDayjs } from './calendar';
import { MonthCalendar } from './monthCalendar';
import { useStyles } from '../styles';
import { TimeValues } from 'models/report/parameter';

interface Props<T extends DayjsValue> {
  setDate: (date: T) => void;
  date: T;
  select: TimeValues['select'];
}

export const AbusoluteCalendar = <T extends DayjsValue>({
  setDate,
  date,
  select
}: Props<T>) => {
  const classes = useStyles();
  const [calendarMode, setCalendarMode] = useState<'month' | 'year'>('month'); // month なら日選択、year なら月選択
  const [startMonth, setStartMonth] = useState(dayjs().subtract(2, 'month')); // 一番上に表示される月

  useEffect(() => {
    if (select === 'manual') {
      return;
    }
    // 「手動選択」以外のショートカットメニュークリック時、強制的に日付選択モードにする
    setCalendarMode('month');

    // 「手動選択」以外のショートカットメニュークリック時、強制的に選択範囲の最終日が存在する月が最下部になるように表示する
    const endDate: NullableDayjs = Array.isArray(date) ? date[1] : date;
    if (endDate) {
      setStartMonth(endDate.subtract(2, 'month'));
    }
  }, [select]);

  return (
    <>
      <div className={classes.abusoluteCalendarArea}>
        <Tooltip
          title="未来を表示"
          PopperProps={{ style: { zIndex: 1500000 } }}
        >
          <IconButton
            size="medium"
            style={{ padding: 0 }}
            onClick={() => {
              if (calendarMode === 'month') {
                setStartMonth(startMonth.add(3, 'month'));
              } else {
                setStartMonth(startMonth.add(1, 'year'));
              }
            }}
          >
            <KeyboardArrowDown style={{ fontSize: '40px' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="過去を表示"
          PopperProps={{ style: { zIndex: 1500000 } }}
        >
          <IconButton
            size="medium"
            style={{ padding: 0 }}
            onClick={() => {
              if (calendarMode === 'month') {
                setStartMonth(startMonth.subtract(3, 'month'));
              } else {
                setStartMonth(startMonth.subtract(1, 'year'));
              }
            }}
          >
            <KeyboardArrowUp style={{ fontSize: '40px' }} />
          </IconButton>
        </Tooltip>
        <Tooltip
          title="月一覧表示に切り替える"
          PopperProps={{ style: { zIndex: 1500000 } }}
        >
          <div>
            <IconButton
              size="medium"
              style={{ padding: 0 }}
              onClick={() => setCalendarMode('year')}
              disabled={calendarMode === 'year'}
            >
              <Layers style={{ fontSize: '40px' }} />
            </IconButton>
          </div>
        </Tooltip>
      </div>
      <div className={classes.abusoluteCalendarCalendar}>
        {calendarMode === 'month' && (
          <Calendars date={date} setDate={setDate} startMonth={startMonth} />
        )}
        {calendarMode === 'year' && (
          <MonthCalendar
            date={date}
            calendarMode={calendarMode}
            setCalendarMode={setCalendarMode}
            setStartMonth={setStartMonth}
            activeStartDate={startMonth}
          />
        )}
      </div>
    </>
  );
};
