import Dexie from 'dexie';
import {
  DataResponse,
  DataResponseBody,
  DataResponseBodyV2,
  DataResponseV1,
  DataResponseV2,
  DataSummary,
  Row
} from 'models/data';

export function migrationData(data: DataResponse): DataResponseV2 {
  let d: DataResponse;
  // tslint:disable-next-line
  if (data.version == undefined) {
    d = migrationDataToV2(data);
  } else if (data.version === 2) {
    d = data;
  } else {
    d = data;
  }
  return d;
}

function migrationDataToV2(data: DataResponseV1): DataResponseV2 {
  const resp = data.response[0];
  if (resp.data.rows == undefined) {
    resp.data.rows = [];
  }
  const newData: DataResponseBodyV2 = {
    ...resp.data,
    rows: resp.data.rows.map((r) => {
      const row: Array<string | number | null> = [];
      resp.data.columns.forEach((k) => row.push(r[k]));
      return row;
    })
  };
  return {
    version: 2,
    id: resp.id,
    name: resp.name,
    execution_id: resp.execution_id,
    data: newData
  };
}

export function isDataEmpty(data: DataResponse): boolean {
  if (data.version == undefined) {
    if (data.response.length === 0) {
      return true;
    }
    return (
      data.response[0].data.num_rows === 0 ||
      data.response[0].data.rows == undefined
    );
  } else if (data.version === 2) {
    return data.data.num_rows === 0 || data.data.rows == undefined;
  }

  // tslint:disable-next-line
  console.warn('unhandled data version');
  return true;
}

export function hasColumnData(data: DataResponse): boolean {
  if (data.version == undefined) {
    if (data.response.length === 0) {
      return true;
    }
    return (
      data.response[0].data.columns != undefined ||
      data.response[0].data.dtypes != undefined
    );
  } else if (data.version === 2) {
    return data.data.columns != undefined || data.data.dtypes != undefined;
  }

  return false;
}

export function getDataMetadata(data: DataResponse): {
  id: string;
  execution_id: string;
} {
  if (data.version == undefined) {
    if (data.response && data.response[0]) {
      return {
        id: data.response[0].id,
        execution_id: data.response[0].execution_id
      };
    }
  } else if (data.version === 2) {
    return { id: data.id, execution_id: data.execution_id };
  }

  return { id: '', execution_id: '' };
}

export default class AppDatabase extends Dexie {
  summaries: Dexie.Table<DataSummary, string>;
  rows: Dexie.Table<Row, number>;

  constructor() {
    super('NehanDatabase');

    this.version(1).stores({
      summaries: '[projectId+portId], projectId',
      rows: '++id, [projectId+portId]'
    });
    this.version(2)
      .stores({
        summaries: '[projectId+portId], projectId',
        rows: '++id, [projectId+portId]'
      })
      .upgrade((tx) => {
        tx.storeNames.map((name) => tx.table(name).clear());
      });
  }

  async deletePortData(projectId: string, portId: string) {
    await this.table('summaries').delete([projectId, portId]);
    await this.table('rows').where({ projectId, portId }).delete();
  }

  async refreshPortColumnData(
    projectId: string,
    portId: string,
    resp: Pick<DataResponseBody, 'columns' | 'dtypes'>
  ) {
    await this.deletePortData(projectId, portId);
    const summary = {
      projectId,
      portId,
      columns: resp.columns,
      dtypes: resp.dtypes,
      num_cols: resp.columns.length,
      num_rows: 0,
      display_table: true
    };

    await this.table('summaries').put(summary);
  }

  // refreshPortDataは既存のデータをすべて削除して新規に投入する
  async refreshPortData(
    projectId: string,
    portId: string,
    executionId: string,
    resp: DataResponse,
    lastModified?: string
  ) {
    if (lastModified == undefined) {
      // tslint:disable-next-line
      console.warn('last modified is undefined.');
    }
    // 既存のデータ削除
    await this.table('summaries').delete([projectId, portId]);
    await this.table('rows').where({ projectId, portId }).delete();

    // データを最新の形式にアップデートする
    resp = migrationData(resp);

    // 新しいデータを投入する
    const { rows, modelV2Info } = resp.data;
    if (rows || resp.query || modelV2Info) {
      const summary: DataSummary = {
        projectId,
        portId,
        executionId,
        row_fetched: rows ? rows.length : 0,
        columns: resp.data.columns,
        dtypes: resp.data.dtypes,
        is_down_sampling: resp.data.is_down_sampling,
        num_cols: resp.data.num_cols,
        num_rows: resp.data.num_rows,
        display_table: resp.data.display_table,
        summary: resp.data.summary,
        lastModified,
        query: resp.query,
        modelV2Info: modelV2Info
      };
      await this.table('summaries').put(summary);
      const data = rows
        ? rows.map((r) => {
            return { projectId, portId, data: r };
          })
        : [];
      await this.table('rows').bulkPut(data);
    }
  }

  getPortSummary(
    projectId: string,
    portId: string
  ): Promise<DataSummary | undefined> {
    return this.table('summaries').get({ projectId, portId });
  }

  async getProjectPortSummary(
    projectId: string
  ): Promise<{ [portId: string]: DataSummary }> {
    const data = await this.table('summaries')
      .where('projectId')
      .equals(projectId)
      .toArray();
    return data.reduce((res, d) => {
      res[d.portId] = d;
      return res;
    }, {});
  }

  async getPortData(projectId: string, portId: string): Promise<Row[]> {
    return this.table('rows').where({ projectId, portId }).sortBy('id');
  }

  async dataReceived(
    projectId: string,
    portId: string,
    executionId?: string
  ): Promise<boolean> {
    const summary = await this.getPortSummary(projectId, portId);
    if (summary == undefined) {
      return false;
    }

    if (executionId == undefined) {
      return true;
    }

    return summary.executionId === executionId;
  }
}

export const db = new AppDatabase();
