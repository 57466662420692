import { ExecuteStatus, Project } from 'models/project';
import { NodeStatus } from 'models/graph';

export enum RequestTypes {
  PING = 0,
  NODE_EXECUTE,
  GRAPH_EXECUTE,
  GET_DATA,
  READ_TEMPORARY,
  READ_DATASOURCE,
  REGISTER_DATASOURCE,
  INTERRUPT,
  BUILDER_NODE_EXECUTE,
  BUILDER_GRAPH_EXECUTE
}

export enum ResponseTypes {
  PING = 0,
  READ_TEMPORARY,
  REGISTER_DATASOURCE,
  PROJECT_STATUS_UPDATE,
  NODE_STATUS_UPDATE,
  NODE_EXPORT,
  CHART_UPDATE,
  Error,
  PRE_EXECUTION_ERROR,
  BUILDER_STATUS_UPDATE,
  BUILDER_NODE_STATUS_UPDATE,
  Notification
}

export enum NodeBackend {
  Python = 0,
  Spark
}

export interface ErrorResponse {
  type: ResponseTypes.Error;
  body: any;
}

export interface PreExecutionErrorResponse {
  type: ResponseTypes.PRE_EXECUTION_ERROR;
  project_id: string;
  workflow_id: number;
  message: string;
}

export interface ProjectStatusUpdate {
  type: ResponseTypes.PROJECT_STATUS_UPDATE;
  project_id: string;
  status: ExecuteStatus;
}

export interface NodeStatusUpdate {
  type: ResponseTypes.NODE_STATUS_UPDATE;
  project_id: string;
  workflow_id: number;
  status: Array<{
    node_id: string;
    execution_id?: string;
    status: NodeStatus;
    backend: NodeBackend;
    errorMessage: string;
    formValues: any;
    autoComment: string;
    api_uuid?: string;
    started_at?: string;
    ended_at?: string;
  }>;
}

export interface BuilderStatusUpdate {
  type: ResponseTypes.BUILDER_STATUS_UPDATE;
  project_id: string;
  status: ExecuteStatus;
}

export interface BuilderNodeStatusUpdate {
  type: ResponseTypes.BUILDER_NODE_STATUS_UPDATE;
  project_id: string;
  workflow_id: string;
  status: Array<{
    node_id: string;
    execution_id?: string;
    status: NodeStatus;
    errorMessage: string;
    formValues: any;
    autoComment: string;
    started_at?: string;
    ended_at?: string;
  }>;
}

export interface NodeExport {
  type: ResponseTypes.NODE_EXPORT;
  project_id: string;
  workflow_id: number;
  node_id: string;
}

export interface Notification {
  type: ResponseTypes.Notification;
  message: string;
}

export interface ChartStatusUpdate {
  type: ResponseTypes.CHART_UPDATE;
  project_id: string;
  chart_nodes: { [nodeId: string]: boolean };
  report_chart_nodes: Project['hasReportVisualizeNodes'];
}

export type WSResponse =
  | ProjectStatusUpdate
  | NodeStatusUpdate
  | BuilderStatusUpdate
  | BuilderNodeStatusUpdate
  | NodeExport
  | ErrorResponse
  | PreExecutionErrorResponse
  | Notification
  | ChartStatusUpdate;
