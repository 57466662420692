import {
  schemeTableau10,
  schemeCategory10,
  schemeAccent,
  schemeDark2,
  schemePaired,
  schemePastel1,
  schemePastel2,
  schemeSet1,
  schemeSet2,
  schemeSet3,
  interpolateBrBG,
  interpolatePRGn,
  interpolatePiYG,
  interpolatePuOr,
  interpolateRdBu,
  interpolateRdGy,
  interpolateRdYlBu,
  interpolateRdYlGn,
  interpolateSpectral,
  interpolateBlues,
  interpolateGreens,
  interpolateGreys,
  interpolateOranges,
  interpolatePurples,
  interpolateReds,
  interpolateTurbo,
  interpolateViridis,
  interpolateInferno,
  interpolateMagma,
  interpolatePlasma,
  interpolateCividis,
  interpolateWarm,
  interpolateCool,
  interpolateCubehelixDefault,
  interpolateBuGn,
  interpolateBuPu,
  interpolateGnBu,
  interpolateOrRd,
  interpolatePuBuGn,
  interpolatePuBu,
  interpolatePuRd,
  interpolateRdPu,
  interpolateYlGnBu,
  interpolateYlGn,
  interpolateYlOrBr,
  interpolateYlOrRd,
  interpolateRainbow,
  interpolateSinebow
} from 'd3-scale-chromatic';

function rgbToHex(rgb) {
  // Choose correct separator
  if (rgb.startsWith('#')) {
    return rgb;
  }
  const sep = rgb.indexOf(',') > -1 ? ',' : ' ';
  // Turn "rgb(r,g,b)" into [r,g,b]
  const rgbs = rgb.substr(4).split(')')[0].split(sep);

  let r = (+rgbs[0]).toString(16),
    g = (+rgbs[1]).toString(16),
    b = (+rgbs[2]).toString(16);

  if (r.length == 1) r = '0' + r;
  if (g.length == 1) g = '0' + g;
  if (b.length == 1) b = '0' + b;

  return '#' + r + g + b;
}

function toPalette(
  colorFunc: (t: number) => string,
  size: number
): ReadonlyArray<string> {
  return [...Array(size)].map((_, i) => rgbToHex(colorFunc(i / size)));
}

export function isSequentialPalette(T: Palette): T is SequentialPalette {
  return isSequentialType(T.type);
}

interface CategoricalPalette {
  type: CategoricalColorType;
}

interface SequentialPalette {
  type: SequentialColorType;
  size: number;
}

export type Palette = (CategoricalPalette | SequentialPalette) & {
  reverse?: boolean;
};

export type PaletteColorType = CategoricalColorType | SequentialColorType;
export enum CategoricalColorType {
  Category10 = 'Category10',
  Tableau10 = 'Tableau10',
  Accent = 'Accent',
  Dark2 = 'Dark2',
  Paired = 'Paired',
  Pastel1 = 'Pastel1',
  Pastel2 = 'Pastel2',
  Set1 = 'Set1',
  Set2 = 'Set2',
  Set3 = 'Set3'
}

export enum SequentialColorType {
  BrownBlueGreen = 'BrownBlueGreen',
  PurpleGreen = 'PurpleGreen',
  PinkGreen = 'PinkGreen',
  PurpleOrange = 'PurpleOrange',
  RedBlue = 'RedBlue',
  RedGrey = 'RedGrey',
  RedYellowBlue = 'RedYellowBlue',
  RedYellowGreen = 'RedYellowGreen',
  Spectral = 'Spectral',
  Blue = 'Blue',
  Green = 'Green',
  Grey = 'Grey',
  Orange = 'Orange',
  Purple = 'Purple',
  Red = 'Red',
  Turbo = 'Turbo',
  Viridis = 'Viridis',
  Inferno = 'Inferno',
  Magma = 'Magma',
  Plasma = 'Plasma',
  Cividis = 'Cividis',
  Warm = 'Warn',
  Cool = 'Cool',
  Cubehelix = 'Cubehelix',
  BlueGreen = 'BlueGreen',
  BluePurple = 'BluePurple',
  GreenBlue = 'GreenBlue',
  OrangeRed = 'OrangeRed',
  PurpleBlueGreen = 'PurpleBlueGreen',
  PurpleBlue = 'PurpleBlue',
  PurpleRed = 'PurpleRed',
  RedPurple = 'RedPurple',
  YellowGreenBlue = 'YellowGreenBlue',
  YellowGreen = 'YellowGreen',
  YellowOrangeBrown = 'YellowOrangeBrown',
  YellowOrangeRed = 'YellowOrangeRed',
  Rainbow = 'Rainbow',
  Sinebow = 'Sinebow'
}

export const categoricalColorList: CategoricalColorType[] = Object.entries(
  CategoricalColorType
).map(([_, value]) => value);

export const sequentialColorList: SequentialColorType[] = Object.entries(
  SequentialColorType
).map(([_, value]) => value);

export const paletteColorList: PaletteColorType[] = [
  ...categoricalColorList,
  ...sequentialColorList
];

export function isCategoricalType(
  T: PaletteColorType
): T is CategoricalColorType {
  return categoricalColorList.findIndex((c) => c === T) >= 0;
}

export function isSequentialType(
  T: PaletteColorType
): T is SequentialColorType {
  return sequentialColorList.findIndex((c) => c === T) >= 0;
}

export function categoricalColor(
  type: CategoricalColorType
): ReadonlyArray<string> {
  switch (type) {
    case CategoricalColorType.Category10:
      return schemeCategory10;
    case CategoricalColorType.Tableau10:
      return schemeTableau10;
    case CategoricalColorType.Accent:
      return schemeAccent;
    case CategoricalColorType.Dark2:
      return schemeDark2;
    case CategoricalColorType.Paired:
      return schemePaired;
    case CategoricalColorType.Pastel1:
      return schemePastel1;
    case CategoricalColorType.Pastel2:
      return schemePastel2;
    case CategoricalColorType.Set1:
      return schemeSet1;
    case CategoricalColorType.Set2:
      return schemeSet2;
    case CategoricalColorType.Set3:
      return schemeSet3;
  }
}

export function categoricalColorName(type: CategoricalColorType): string {
  switch (type) {
    case CategoricalColorType.Tableau10:
      return 'Tableau10';
    case CategoricalColorType.Category10:
      return 'Category10';
    case CategoricalColorType.Accent:
      return 'Accent';
    case CategoricalColorType.Dark2:
      return 'Dark2';
    case CategoricalColorType.Paired:
      return 'Paired';
    case CategoricalColorType.Pastel1:
      return 'Pastel1';
    case CategoricalColorType.Pastel2:
      return 'Pastel2';
    case CategoricalColorType.Set1:
      return 'Set1';
    case CategoricalColorType.Set2:
      return 'Set2';
    case CategoricalColorType.Set3:
      return 'Set3';
  }
}

function switchSequentialColor(
  type: SequentialColorType
): (t: number) => string {
  switch (type) {
    case SequentialColorType.BrownBlueGreen:
      return interpolateBrBG;
    case SequentialColorType.PurpleGreen:
      return interpolatePRGn;
    case SequentialColorType.PinkGreen:
      return interpolatePiYG;
    case SequentialColorType.PurpleOrange:
      return interpolatePuOr;
    case SequentialColorType.RedBlue:
      return interpolateRdBu;
    case SequentialColorType.RedGrey:
      return interpolateRdGy;
    case SequentialColorType.RedYellowBlue:
      return interpolateRdYlBu;
    case SequentialColorType.RedYellowGreen:
      return interpolateRdYlGn;
    case SequentialColorType.Spectral:
      return interpolateSpectral;
    case SequentialColorType.Blue:
      return interpolateBlues;
    case SequentialColorType.Green:
      return interpolateGreens;
    case SequentialColorType.Grey:
      return interpolateGreys;
    case SequentialColorType.Orange:
      return interpolateOranges;
    case SequentialColorType.Purple:
      return interpolatePurples;
    case SequentialColorType.Red:
      return interpolateReds;
    case SequentialColorType.Turbo:
      return interpolateTurbo;
    case SequentialColorType.Viridis:
      return interpolateViridis;
    case SequentialColorType.Inferno:
      return interpolateInferno;
    case SequentialColorType.Magma:
      return interpolateMagma;
    case SequentialColorType.Plasma:
      return interpolatePlasma;
    case SequentialColorType.Cividis:
      return interpolateCividis;
    case SequentialColorType.Warm:
      return interpolateWarm;
    case SequentialColorType.Cool:
      return interpolateCool;
    case SequentialColorType.Cubehelix:
      return interpolateCubehelixDefault;
    case SequentialColorType.BlueGreen:
      return interpolateBuGn;
    case SequentialColorType.BluePurple:
      return interpolateBuPu;
    case SequentialColorType.GreenBlue:
      return interpolateGnBu;
    case SequentialColorType.OrangeRed:
      return interpolateOrRd;
    case SequentialColorType.PurpleBlueGreen:
      return interpolatePuBuGn;
    case SequentialColorType.PurpleBlue:
      return interpolatePuBu;
    case SequentialColorType.PurpleRed:
      return interpolatePuRd;
    case SequentialColorType.RedPurple:
      return interpolateRdPu;
    case SequentialColorType.YellowGreenBlue:
      return interpolateYlGnBu;
    case SequentialColorType.YellowGreen:
      return interpolateYlGn;
    case SequentialColorType.YellowOrangeBrown:
      return interpolateYlOrBr;
    case SequentialColorType.YellowOrangeRed:
      return interpolateYlOrRd;
    case SequentialColorType.Rainbow:
      return interpolateRainbow;
    case SequentialColorType.Sinebow:
      return interpolateSinebow;
  }
}

function sequentialColorName(type: SequentialColorType): string {
  switch (type) {
    case SequentialColorType.Blue:
      return 'Blue';
    case SequentialColorType.Green:
      return 'Green';
    case SequentialColorType.Grey:
      return 'Grey';
    case SequentialColorType.Orange:
      return 'Orange';
    case SequentialColorType.Purple:
      return 'Purple';
    case SequentialColorType.Red:
      return 'Red';
    case SequentialColorType.Turbo:
      return 'Turbo';
    case SequentialColorType.Viridis:
      return 'Viridis';
    case SequentialColorType.Inferno:
      return 'Inferno';
    case SequentialColorType.Magma:
      return 'Magma';
    case SequentialColorType.Plasma:
      return 'Plasma';
    case SequentialColorType.Cividis:
      return 'Cividis';
    case SequentialColorType.Warm:
      return 'Warm';
    case SequentialColorType.Cool:
      return 'Cool';
    case SequentialColorType.Cubehelix:
      return 'Cubehelix';
    case SequentialColorType.BlueGreen:
      return 'BlueGreen';
    case SequentialColorType.BluePurple:
      return 'BluePurple';
    case SequentialColorType.GreenBlue:
      return 'GreenBlue';
    case SequentialColorType.OrangeRed:
      return 'OrangeRed';
    case SequentialColorType.PurpleBlueGreen:
      return 'PurpleBlueGreen';
    case SequentialColorType.PurpleBlue:
      return 'PurpleBlue';
    case SequentialColorType.PurpleRed:
      return 'PurpleRed';
    case SequentialColorType.RedPurple:
      return 'RedPurple';
    case SequentialColorType.YellowGreenBlue:
      return 'YellowGreenBlue';
    case SequentialColorType.YellowGreen:
      return 'YellowGreen';
    case SequentialColorType.YellowOrangeBrown:
      return 'YellowOrangeBrown';
    case SequentialColorType.YellowOrangeRed:
      return 'YellowOrangeRed';
    case SequentialColorType.Rainbow:
      return 'Rainbow';
    case SequentialColorType.Sinebow:
      return 'Sinebow';
    case SequentialColorType.BrownBlueGreen:
      return 'BrownBlueGreen';
    case SequentialColorType.PurpleGreen:
      return 'PurpleGreen';
    case SequentialColorType.PinkGreen:
      return 'PinkGreen';
    case SequentialColorType.PurpleOrange:
      return 'PurpleOrange';
    case SequentialColorType.RedBlue:
      return 'RedBlue';
    case SequentialColorType.RedGrey:
      return 'RedGrey';
    case SequentialColorType.RedYellowBlue:
      return 'RedYellowBlue';
    case SequentialColorType.RedYellowGreen:
      return 'RedYellowGreen';
    case SequentialColorType.Spectral:
      return 'Spectral';
  }
}

export function sequentialColors(name: SequentialColorType, size: number) {
  return toPalette(switchSequentialColor(name), size);
}

export function getColorName(type: PaletteColorType): string {
  if (isCategoricalType(type)) {
    return categoricalColorName(type);
  }

  if (isSequentialType(type)) {
    return sequentialColorName(type);
  }

  return '';
}

export function getColors(palette: Palette): string[] {
  let colors: string[] = [];
  if (isCategoricalType(palette.type)) {
    colors = categoricalColor(palette.type).slice();
  }

  if (isSequentialPalette(palette)) {
    colors = sequentialColors(palette.type, palette.size || 1).slice();
  }

  if (palette.reverse) {
    colors.reverse();
  }
  return colors;
}

export const radarAlpha = '40';
