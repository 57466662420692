import * as React from 'react';
import { Dtypes } from 'Utils/dataTypes';
import { IconNumber } from 'components/icons/types/number';
import { IconDate } from 'components/icons/types/date';
import { IconTimestamp } from 'components/icons/types/timestamp';
import { IconString } from 'components/icons/types/string';
import { IconUnknown } from 'components/icons/types/unknown';
import { IconTime } from 'components/icons/types/time';

export const getColumnIcon = (dataType: string | undefined) => {
  switch (dataType) {
    case Dtypes.NUMBER:
    case Dtypes.TIMEDELTA:
      return <IconNumber />;
    case Dtypes.STRING:
      return <IconString />;
    case Dtypes.DATE:
      return <IconDate />;
    case Dtypes.TIMESTAMP:
      return <IconTimestamp />;
    case Dtypes.TIME:
      return <IconTime />;
    default:
      return <IconUnknown />;
  }
};
