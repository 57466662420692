import * as React from 'react';
import {
  Dtypes //, LabelToDtype
} from 'Utils/dataTypes';
import {
  SwitchAndCondition,
  SwitchAndConditions,
  SwitchCondition,
  SwitchSelectColumn,
  SwitchOperators,
  SwitchDatetimeElement
} from 'models/form/value';
import { produce } from 'immer';
import { ColumnSelectValueSetter } from 'components/form/switchFieldComponent/columnSelectValueSetter';
import { FieldValidationError } from 'models/form/validate';
import { SelectOperator } from 'components/form/switchFieldComponent/selectOperator';
import {
  FieldConditionRuleStyles,
  OneOfArrayRuleStyles
} from 'components/form/switchFieldComponent/switchStyles';
import clsx from 'clsx';
import { SelectBetweenOperator } from 'components/form/switchFieldComponent/selectBetweenOperator';
import { isNotUseColumnCondition } from 'components/form/switchFieldComponent/utils';
import { UseColumnCondition } from 'components/form/switchFieldComponent/useColumnCondition';
import TextField from 'components/form/switchFieldComponent/textField';
import { SwitchFieldSchema } from 'models/form/schema';
import { Variable } from 'models/project';
import { SelectElement } from 'components/form/switchFieldComponent/selectElement';

export const TimeField: React.FC<{
  index: number;
  columns: string[];
  dtypes: Dtypes[];
  dtype: Dtypes | null;
  values: SwitchAndCondition;
  andConditions: SwitchAndConditions;
  conditionOnChange: (val: SwitchCondition) => void;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  errors: FieldValidationError;
  schema: SwitchFieldSchema;
  variables: Variable[];
}> = ({
  index,
  columns,
  dtypes,
  dtype,
  values,
  andConditions,
  conditionOnChange,
  returnUseColumn,
  returnValue,
  returnColname,
  errors,
  schema,
  variables
}) => {
  const classes = OneOfArrayRuleStyles();
  return (
    <>
      <div
        className={clsx(classes.columnSelect, {
          [classes.betweenColumnSelect]:
            values.operator === SwitchOperators.between
        })}
      >
        <ColumnSelectValueSetter
          columns={columns}
          dtypes={dtypes}
          value={values.searchedColnames}
          onChange={(newVal) => {
            const newState = produce(andConditions, (draft) => {
              const dtype = dtypes[columns.indexOf(newVal)];
              draft[index].searchedColnames =
                newVal == null
                  ? null
                  : {
                      label: newVal,
                      value: newVal,
                      dtype: dtype
                    };
              if (![Dtypes.DATE, Dtypes.TIMESTAMP].includes(dtype)) {
                draft[index].datetimeType = null;
              }
            });
            conditionOnChange({
              andConditions: newState,
              returnUseColumn: returnUseColumn,
              returnValue: returnValue,
              returnColname: returnColname
            });
          }}
          placeholder="対象列を選択"
          index={index}
          //errors={errors}
        />
      </div>
      {values.searchedColnames != null &&
        ['time'].includes(values.searchedColnames.dtype) && (
          <div className={classes.elementSelect}>
            <SelectElement
              value={values.datetimeType}
              dtype={dtype}
              onChange={(newVal, element) => {
                const select_element = element.filter(
                  (v) => v.value === newVal
                )[0];
                const isDisableUseColumn =
                  select_element.disableUseColumn == undefined
                    ? false
                    : select_element.disableUseColumn;
                const isDisableBetweenUseColumn =
                  select_element.disableBetweenUseColumn == undefined
                    ? false
                    : select_element.disableBetweenUseColumn;
                const newState = produce(andConditions, (draft) => {
                  draft[index].datetimeType = newVal;
                  if (isDisableUseColumn) {
                    draft[index].valueUseColumn = null;
                    draft[index].filterColnames = null;
                    draft[index].weekdayType = null;
                  }
                  if (isDisableBetweenUseColumn) {
                    draft[index].endFilterColnames = null;
                    draft[index].startFilterColnames = null;
                    draft[index].valueEndUseColumn = null;
                    draft[index].valueStartUseColumn = null;
                  }
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              index={index}
            />
          </div>
        )}
      <div
        className={clsx(classes.operatorSelect, {
          [classes.dateOperatorSelect]:
            values.operator === SwitchOperators.between
        })}
      >
        <SelectOperator
          value={values.operator == null ? null : values.operator}
          element={values.datetimeType}
          dtype={dtype}
          onChange={(newVal, operators) => {
            const select_operator = operators.filter(
              (v) => v.value === newVal
            )[0];
            const isDisableUseColumn =
              select_operator.disableUseColumn == undefined
                ? false
                : select_operator.disableUseColumn;
            const isDisableBetweenUseColumn =
              select_operator.disableBetweenUseColumn == undefined
                ? false
                : select_operator.disableBetweenUseColumn;
            const newState = produce(andConditions, (draft) => {
              draft[index].operator = newVal;
              if (isDisableUseColumn) {
                draft[index].valueUseColumn = null;
                draft[index].filterColnames = null;
                draft[index].weekdayType = null;
              }
              if (isDisableBetweenUseColumn) {
                draft[index].endFilterColnames = null;
                draft[index].startFilterColnames = null;
                draft[index].valueEndUseColumn = null;
                draft[index].valueStartUseColumn = null;
              }
            });
            conditionOnChange({
              andConditions: newState,
              returnUseColumn: returnUseColumn,
              returnValue: returnValue,
              returnColname: returnColname
            });
          }}
          placeholder="比較タイプを選択"
          index={index}
        />
      </div>
      <TimeConditionValue
        variables={variables}
        value={values}
        index={index}
        conditions={andConditions}
        schema={schema}
        conditionOnChange={conditionOnChange}
        errors={errors}
        returnUseColumn={returnUseColumn}
        returnValue={returnValue}
        returnColname={returnColname}
        columns={columns}
        dtypes={dtypes}
      />
    </>
  );
};

const TimeConditionValue: React.FC<{
  variables: Variable[];
  value: SwitchAndCondition;
  index: number;
  conditions: SwitchAndConditions;
  schema: SwitchFieldSchema;
  conditionOnChange: (val: SwitchCondition) => void;
  errors: FieldValidationError;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  columns: string[];
  dtypes: Dtypes[];
}> = ({
  variables,
  value,
  index,
  conditions,
  schema,
  conditionOnChange,
  //errors,
  returnUseColumn,
  returnValue,
  returnColname,
  columns,
  dtypes
}) => {
  const classes = FieldConditionRuleStyles();
  const searchedColnames = value.searchedColnames;
  const col = searchedColnames == null ? '' : searchedColnames.label;
  const startFilterColnames = value.startFilterColnames;
  const start_filter_colname =
    startFilterColnames == null ? '' : startFilterColnames.label;
  const endFilterColnames = value.endFilterColnames;
  const end_filter_colname =
    endFilterColnames == null ? '' : endFilterColnames.label;
  const datetimeType = value.datetimeType;
  switch (datetimeType) {
    case null:
      return null;
    case SwitchDatetimeElement.hour:
    case SwitchDatetimeElement.minute:
    case SwitchDatetimeElement.second:
      switch (value.operator) {
        case SwitchOperators.between:
          return (
            <>
              <div className={classes.betweenOperatorSelect}>
                <SelectBetweenOperator
                  value={value.betweenOperator}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].betweenOperator = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  index={index}
                />
              </div>
              <div
                className={clsx(classes.betweenSelectBox, {
                  [classes.betweenSelectBoxWithoutUseCol]:
                    isNotUseColumnCondition(value.operator)
                })}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UseColumnCondition
                    operator={value.operator}
                    check={value.valueStartUseColumn}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueStartUseColumn = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    isReturnValue={false}
                    label="既存列を設定する"
                  />
                </div>
                {value.valueStartUseColumn === true && (
                  <ColumnSelectValueSetter
                    columns={columns}
                    dtypes={dtypes}
                    value={value.startFilterColnames}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].startFilterColnames =
                          newVal == null
                            ? null
                            : {
                                label: newVal,
                                value: newVal,
                                dtype: dtypes[columns.indexOf(newVal)]
                              };
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    placeholder="列を選択"
                    index={index}
                    //errors={errors}
                    validate_dtype={['number']}
                    excludeValues={[col, end_filter_colname]}
                  />
                )}
                {value.valueStartUseColumn !== true && (
                  <TextField
                    variables={variables}
                    value={value.valueStart}
                    schema={schema}
                    onChangeField={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueStart = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    errors={[]}
                    placeholder="始点を設定"
                  />
                )}
              </div>
              <div
                className={clsx(classes.betweenSelectBox, {
                  [classes.betweenSelectBoxWithoutUseCol]:
                    isNotUseColumnCondition(value.operator)
                })}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UseColumnCondition
                    operator={value.operator}
                    check={value.valueEndUseColumn}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueEndUseColumn = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    isReturnValue={false}
                    label="既存列を設定する"
                  />
                </div>
                {value.valueEndUseColumn === true && (
                  <ColumnSelectValueSetter
                    columns={columns}
                    dtypes={dtypes}
                    value={value.endFilterColnames}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].endFilterColnames =
                          newVal == null
                            ? null
                            : {
                                label: newVal,
                                value: newVal,
                                dtype: dtypes[columns.indexOf(newVal)]
                              };
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    placeholder="列を選択"
                    index={index}
                    //errors={errors}
                    validate_dtype={['number']}
                    excludeValues={[col, start_filter_colname]}
                  />
                )}
                {value.valueEndUseColumn !== true && (
                  <TextField
                    variables={variables}
                    value={value.valueEnd}
                    schema={schema}
                    onChangeField={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueEnd = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    errors={[]}
                    placeholder="終点を設定"
                  />
                )}
              </div>
            </>
          );
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return null;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          return (
            <div
              className={clsx(classes.selectBox, {
                [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
                  value.operator
                )
              })}
            >
              <TextField
                variables={variables}
                value={value.value}
                schema={schema}
                onChangeField={(newVal) => {
                  const newState = produce(conditions, (draft) => {
                    draft[index].value = newVal;
                  });
                  conditionOnChange({
                    andConditions: newState,
                    returnUseColumn: returnUseColumn,
                    returnValue: returnValue,
                    returnColname: returnColname
                  });
                }}
                errors={[]}
                placeholder="条件値を設定"
              />
            </div>
          );
        }
        default:
          return (
            <div
              className={clsx(classes.selectBox, {
                [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
                  value.operator
                )
              })}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <UseColumnCondition
                  operator={value.operator == null ? null : value.operator}
                  check={value.valueUseColumn}
                  datetimeType={value.datetimeType}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].valueUseColumn = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  isReturnValue={false}
                  label="既存列を設定する"
                />
              </div>
              {value.valueUseColumn === true && (
                <ColumnSelectValueSetter
                  columns={columns}
                  dtypes={dtypes}
                  value={value.filterColnames}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].filterColnames =
                        newVal == null
                          ? null
                          : {
                              label: newVal,
                              value: newVal,
                              dtype: dtypes[columns.indexOf(newVal)]
                            };
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  placeholder="列を選択"
                  index={index}
                  //errors={errors}
                  validate_dtype={['number']}
                  excludeValues={[col]}
                />
              )}
              {value.valueUseColumn !== true && (
                <TextField
                  variables={variables}
                  value={value.value}
                  schema={schema}
                  onChangeField={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].value = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  errors={[]}
                  placeholder="条件値を設定"
                />
              )}
            </div>
          );
      }
    case SwitchDatetimeElement.hms:
      switch (value.operator) {
        case SwitchOperators.between:
          return (
            <>
              <div className={classes.betweenOperatorSelect}>
                <SelectBetweenOperator
                  value={value.betweenOperator}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].betweenOperator = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  index={index}
                />
              </div>
              <div
                className={clsx(classes.betweenSelectBox, {
                  [classes.betweenSelectBoxWithoutUseCol]:
                    isNotUseColumnCondition(value.operator)
                })}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UseColumnCondition
                    operator={value.operator}
                    check={value.valueStartUseColumn}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueStartUseColumn = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    isReturnValue={false}
                    label="既存列を設定する"
                  />
                </div>
                {value.valueStartUseColumn === true && (
                  <ColumnSelectValueSetter
                    columns={columns}
                    dtypes={dtypes}
                    value={value.startFilterColnames}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].startFilterColnames =
                          newVal == null
                            ? null
                            : {
                                label: newVal,
                                value: newVal,
                                dtype: dtypes[columns.indexOf(newVal)]
                              };
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    placeholder="列を選択"
                    index={index}
                    //errors={errors}
                    validate_dtype={['time']}
                    excludeValues={[col, end_filter_colname]}
                  />
                )}
                {value.valueStartUseColumn !== true && (
                  <TextField
                    variables={variables}
                    value={value.valueStart}
                    schema={schema}
                    onChangeField={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueStart = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    errors={[]}
                    placeholder="始点を設定"
                  />
                )}
              </div>
              <div
                className={clsx(classes.betweenSelectBox, {
                  [classes.betweenSelectBoxWithoutUseCol]:
                    isNotUseColumnCondition(value.operator)
                })}
              >
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <UseColumnCondition
                    operator={value.operator}
                    check={value.valueEndUseColumn}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueEndUseColumn = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    isReturnValue={false}
                    label="既存列を設定する"
                  />
                </div>
                {value.valueEndUseColumn === true && (
                  <ColumnSelectValueSetter
                    columns={columns}
                    dtypes={dtypes}
                    value={value.endFilterColnames}
                    onChange={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].endFilterColnames =
                          newVal == null
                            ? null
                            : {
                                label: newVal,
                                value: newVal,
                                dtype: dtypes[columns.indexOf(newVal)]
                              };
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    placeholder="列を選択"
                    index={index}
                    //errors={errors}
                    validate_dtype={['time']}
                    excludeValues={[col, start_filter_colname]}
                  />
                )}
                {value.valueEndUseColumn !== true && (
                  <TextField
                    variables={variables}
                    value={value.valueEnd}
                    schema={schema}
                    onChangeField={(newVal) => {
                      const newState = produce(conditions, (draft) => {
                        draft[index].valueEnd = newVal;
                      });
                      conditionOnChange({
                        andConditions: newState,
                        returnUseColumn: returnUseColumn,
                        returnValue: returnValue,
                        returnColname: returnColname
                      });
                    }}
                    errors={[]}
                    placeholder="終点を設定"
                  />
                )}
              </div>
            </>
          );
        case SwitchOperators.extract_na:
        case SwitchOperators.drop_na:
          return null;
        case SwitchOperators.in:
        case SwitchOperators.not_in: {
          return (
            <div
              className={clsx(classes.selectBox, {
                [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
                  value.operator
                )
              })}
            >
              <TextField
                variables={variables}
                value={value.value}
                schema={schema}
                onChangeField={(newVal) => {
                  const newState = produce(conditions, (draft) => {
                    draft[index].value = newVal;
                  });
                  conditionOnChange({
                    andConditions: newState,
                    returnUseColumn: returnUseColumn,
                    returnValue: returnValue,
                    returnColname: returnColname
                  });
                }}
                errors={[]}
                placeholder="条件値を設定"
              />
            </div>
          );
        }
        default:
          return (
            <div
              className={clsx(classes.selectBox, {
                [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
                  value.operator
                )
              })}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <UseColumnCondition
                  operator={value.operator == null ? null : value.operator}
                  check={value.valueUseColumn}
                  datetimeType={value.datetimeType}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].valueUseColumn = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  isReturnValue={false}
                  label="既存列を設定する"
                />
              </div>
              {value.valueUseColumn === true && (
                <ColumnSelectValueSetter
                  columns={columns}
                  dtypes={dtypes}
                  value={value.filterColnames}
                  onChange={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].filterColnames =
                        newVal == null
                          ? null
                          : {
                              label: newVal,
                              value: newVal,
                              dtype: dtypes[columns.indexOf(newVal)]
                            };
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  placeholder="列を選択"
                  index={index}
                  //errors={errors}
                  validate_dtype={['time']}
                  excludeValues={[col]}
                />
              )}
              {value.valueUseColumn !== true && (
                <TextField
                  variables={variables}
                  value={value.value}
                  schema={schema}
                  onChangeField={(newVal) => {
                    const newState = produce(conditions, (draft) => {
                      draft[index].value = newVal;
                    });
                    conditionOnChange({
                      andConditions: newState,
                      returnUseColumn: returnUseColumn,
                      returnValue: returnValue,
                      returnColname: returnColname
                    });
                  }}
                  errors={[]}
                  placeholder="条件値を設定"
                />
              )}
            </div>
          );
      }
    default:
      return null;
  }
};
