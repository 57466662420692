import * as React from 'react';

import { Dtypes, toDtypeLabel } from 'Utils/dataTypes';
import { SwitchSelectColumn } from 'models/form/value';
//import {
//  FieldValidationError // ValidationTypes
//} from 'models/form/validate';
import {
  ColumnSelectFieldSchema,
  FieldTypes,
  SelectFieldValueElement
} from 'models/form/schema';
import ColumnSelectField from 'components/form/columnSelectField';
import { validate_columns } from 'components/form/switchFieldComponent/utils';

export const ColumnSelectValueSetter: React.FC<{
  columns: string[];
  dtypes: Dtypes[];
  value: SwitchSelectColumn | null;
  onChange: (val: any) => void;
  placeholder: string;
  index: number;
  //errors: FieldValidationError;
  validate_dtype?: string[];
  excludeValues?: string[];
  isValidate?: boolean;
}> = ({
  columns,
  dtypes,
  value,
  onChange,
  placeholder,
  index,
  //errors,
  validate_dtype,
  excludeValues
  //isValidate
}) => {
  //const tmp_vali = isValidate == undefined ? false : isValidate;
  //const vali = tmp_vali
  //  ? { type: ValidationTypes.any, required: true }
  //  : undefined;
  const schema: ColumnSelectFieldSchema = {
    key: String(index),
    type: FieldTypes.column_select,
    multi: false,
    placeholder: placeholder,
    dtypes: validate_dtype,
    fullWidth: true,
    variant: 'outlined',
    backgroundColor: '#ffffff'
    //validate: vali
  };

  const onChangeColumnSelect = React.useCallback(
    (_, val: SelectFieldValueElement | null) => {
      const tmp = val == null ? null : val.label;
      onChange(tmp);
    },
    [onChange]
  );
  const newExcludeValues = excludeValues == undefined ? [] : excludeValues;
  let validate: string | undefined;
  if (value != null) {
    validate = validate_columns(value.label, value.dtype, columns, dtypes);
  }
  let errors: string[] = [];
  if (validate != null) {
    errors.push(validate);
  }
  if (
    validate_dtype != undefined &&
    value != null &&
    !validate_dtype.includes(value.dtype)
  ) {
    const label = toDtypeLabel(value.dtype);
    errors.push(label + '型の列は指定できません。');
  }
  return (
    <div>
      <ColumnSelectField
        value={value as any}
        errors={errors}
        schema={schema}
        columns={columns}
        allDtypes={[dtypes]}
        excludeValues={newExcludeValues}
        onChangeField={onChangeColumnSelect}
        detailedHelpTooltip={null}
      />
    </div>
  );
};
