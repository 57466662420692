import Cookies from 'universal-cookie';
import axios, { AxiosRequestConfig, RawAxiosRequestHeaders } from 'axios';
import { getAuth } from 'firebase/auth';
import { firebaseApp } from '../../firebase';

const axiosAuthHeader = axios.create();
axiosAuthHeader.interceptors.request.use(async (request: any) => {
  const user = getAuth(firebaseApp).currentUser;
  if (user == null) {
    return request;
  }

  try {
    const token = await user.getIdToken(false);
    request.headers = { ...request.headers, Authorization: `Bearer ${token}` };
    return request;
  } catch (e) {
    console.error(e);
    return request;
  }
});

const axiosAuthQuery = axios.create();
axiosAuthQuery.interceptors.request.use(async (request) => {
  const user = getAuth(firebaseApp).currentUser;
  if (user == null) {
    return request;
  }

  try {
    const token = await user.getIdToken(false);
    request.params = request.params ? request.params : {};
    request.params['access_token'] = token;
    return request;
  } catch (e) {
    console.error(e);
    return request;
  }
});

export const APIHost = import.meta.env.VITE_API_URL
  ? import.meta.env.VITE_API_URL
  : location.host;
const proto = import.meta.env.VITE_API_PROTO
  ? import.meta.env.VITE_API_PROTO + ':'
  : location.protocol;
export const APIUrl = `${proto}//${APIHost}/api/v1`;
export const PublicAPIUrl = `${APIUrl}/public`;

export const clientIdName = 'nehan_client_id';
// cookieも一緒に送るための設定
// axios.defaults.withCredentials = true;
// axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
const cookies = new Cookies();

function getBaseHeader(): RawAxiosRequestHeaders {
  return {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Nehan-Client-ID': cookies.get(clientIdName)
  };
}

const createApiUrl = (path: string): string => {
  if (!path.startsWith('/')) {
    path = `/${path}`;
  }
  return `${APIUrl}${path}`;
};

/* tslint:disable:variable-name */
const _get = <T = any>(path: string, config?: AxiosRequestConfig) => {
  let configBase = {
    headers: {
      ...getBaseHeader()
    }
  };
  if (config) {
    configBase = { ...configBase, ...config };
  }
  return axiosAuthHeader.get<T>(createApiUrl(path), configBase);
};

const _getWithAccessTokenV2 = <T = any>(
  path: string,
  config: AxiosRequestConfig = {}
) => {
  return axiosAuthQuery.get<T>(createApiUrl(path), {
    headers: { ...getBaseHeader() },
    ...config
  });
};

const _getWithAccessToken = (
  token: string,
  path: string,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get(`${createApiUrl(path)}?access_token=${token}`, {
    headers: { ...getBaseHeader(), ...options }
  });
};

const _getWithSharedToken = (
  token: string,
  path: string,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get(`${createApiUrl(path)}?shared_token=${token}`, {
    headers: { ...getBaseHeader(), ...options },
    withCredentials: true
  });
};

const _getWithFixedToken = <T = any>(
  token: string,
  path: string,
  optHeaders?: RawAxiosRequestHeaders
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.get<T>(createApiUrl(path), {
    headers: { ...getBaseHeader(), 'nehan-auth': token, ...options }
  });
};

const _postWithFixedToken = <T = any>(
  token: string,
  path: string,
  body: any,
  optHeaders?: { [name: string]: any }
) => {
  const options = optHeaders ? optHeaders : {};
  return axios.post<T>(createApiUrl(path), JSON.stringify(body), {
    headers: { ...getBaseHeader(), 'nehan-auth': token, ...options }
  });
};

const _post = <T = any>(
  path: string,
  body: any,
  config?: AxiosRequestConfig
) => {
  let configBase: AxiosRequestConfig = {
    headers: {
      ...getBaseHeader()
    }
  };
  if (config) {
    configBase = { ...configBase, ...config };
  }

  return axiosAuthHeader.post<T>(
    createApiUrl(path),
    JSON.stringify(body),
    configBase
  );
};

const _postRaw = <T = any>(path: string, body: any) => {
  return axiosAuthHeader.post<T>(createApiUrl(path), body, {
    headers: {
      ...getBaseHeader()
    }
  });
};

const _postWithSharedToken = (token: string, path: string, body: any) => {
  return axios.post(
    `${createApiUrl(path)}?shared_token=${token}`,
    JSON.stringify(body),
    {
      headers: getBaseHeader(),
      withCredentials: true
    }
  );
};

const _put = <T = any>(
  path: string,
  body: any,
  config?: AxiosRequestConfig
) => {
  let configBase: AxiosRequestConfig = {
    headers: {
      ...getBaseHeader()
    }
  };
  if (config) {
    configBase = { ...configBase, ...config };
  }
  return axiosAuthHeader.put<T>(
    createApiUrl(path),
    JSON.stringify(body),
    configBase
  );
};

const _delete = <T = any>(path: string, data?: object) => {
  const config = {
    headers: {
      ...getBaseHeader()
    }
  };
  if (data) {
    config['data'] = data;
  }
  return axiosAuthHeader.delete<T>(createApiUrl(path), config);
};
/* tslint:enable */

export {
  _get,
  _getWithAccessToken,
  _getWithAccessTokenV2,
  _getWithSharedToken,
  _getWithFixedToken,
  _postWithFixedToken,
  _post,
  _postRaw,
  _postWithSharedToken,
  _put,
  _delete
};
