import { ItemPosition, ReportItem } from '../../models/report';

export const GRID_WIDTH = 18;
export const GRID_ASPECT_RATIO = 4 / 3;
export const GRID_MARGIN = 8;

export const DEFAULT_CARD_SIZE = { width: 6, height: 5 };
export const DEFAULT_TEXT_CARD_SIZE = { width: 6, height: 2 };
export const DEFAULT_MIN_CARD_SIZE = { width: 3, height: 3 };
export const DEFAULT_MIN_TEXT_CARD_SIZE = { width: 3, height: 2 };

function intersects(a: ReportItem, b: ItemPosition): boolean {
  return !(
    b.x >= a.x + a.w ||
    b.x + b.w <= a.x ||
    b.y >= a.y + a.h ||
    b.y + b.h <= a.y
  );
}

// returns the first available position from left to right, top to bottom,
// based on the existing cards,  item size, and grid width
export function getPositionForNewItem(
  cards: ReportItem[],
  w: number = DEFAULT_CARD_SIZE.width,
  h: number = DEFAULT_CARD_SIZE.height,
  width: number = GRID_WIDTH
): ItemPosition {
  let y = 0;
  let x = 0;
  while (y < 1000) {
    while (x <= width - w) {
      let good = true;
      const position = { x, y, w, h };
      for (const card of cards) {
        if (intersects(card, position)) {
          good = false;
          break;
        }
      }
      if (good) {
        return position;
      }
      x++;
    }
    x = 0;
    y++;
  }
  // this should never happen but flow complains if we return undefined
  return { x, y, w, h };
}
