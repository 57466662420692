import * as React from 'react';
import {
  Dtypes //, LabelToDtype
} from 'Utils/dataTypes';
import SelectField from 'components/form/selectField';
import { SwitchOperators, SwitchDatetimeElement } from 'models/form/value';
import { SelectFieldSchema } from 'models/form/schema';
import { FieldTypes, SelectFieldValueElement } from 'models/form/schema';

export const SelectOperator: React.FC<{
  value: SwitchOperators | null;
  element: SwitchDatetimeElement | null;
  dtype: Dtypes | null;
  onChange: (
    value: SwitchOperators,
    operator: Array<{
      disableUseColumn?: boolean;
      value: SwitchOperators;
      label: string;
      disableBetweenUseColumn?: boolean;
    }>
  ) => void;
  placeholder: string;
  index: number;
}> = ({ value, element, dtype, onChange, placeholder, index }) => {
  const handleChange = React.useCallback(
    (_, val: any) => onChange(val as SwitchOperators, operator),
    [onChange]
  );

  const operator = determine_operator(dtype, element);

  //const val: SelectFieldValueElement = operator.filter((v) => {
  //  v.value === value;
  //})[0];

  const schema: SelectFieldSchema = {
    key: String(index),
    type: FieldTypes.select,
    items: operator as SelectFieldValueElement[],
    multi: false,
    allDtypes: [],
    placeholder: placeholder,
    fullWidth: true,
    variant: 'outlined'
  };
  return (
    <div>
      <SelectField
        clearable={false}
        value={value as any}
        //errors={errors}
        schema={schema}
        onChangeField={handleChange}
        detailedHelpTooltip={null}
      />
    </div>
  );
};

const determine_operator = (
  dtype: Dtypes | null,
  element: SwitchDatetimeElement | null
): Array<{
  disableUseColumn?: boolean;
  value: SwitchOperators;
  label: string;
  disableBetweenUseColumn?: boolean;
}> => {
  switch (dtype) {
    case Dtypes.NUMBER:
      return NumberOperator;
    case Dtypes.STRING:
      return StringOperator;
    case Dtypes.DATE:
      if (element === SwitchDatetimeElement.weekday) {
        return WeekdayOperator;
      }
      return DateOperator;
    case Dtypes.TIMESTAMP:
      if (element === SwitchDatetimeElement.weekday) {
        return WeekdayOperator;
      } else if (element === SwitchDatetimeElement.ymdhms) {
        return DatetimeOperatorYmdhms;
      }
      return DatetimeOperator;
    case Dtypes.TIME:
      return TimeOperator;
    default:
      return NumberOperator;
  }
};

const NumberOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.lt,
    label: 'より小さい(<)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.le,
    label: '以下(<=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.gt,
    label: 'より大きい(>)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.ge,
    label: '以上(>=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  },
  { disableUseColumn: true, value: SwitchOperators.between, label: '範囲指定' },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];

const StringOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.grep,
    label: '文字を含む(grep)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.ungrep,
    label: '文字を含まない(ungrep)',
    disableBetweenUseColumn: true
  },
  {
    disableElseUseColumn: true,
    value: SwitchOperators.starts_with,
    label: '[条件値]から始まる(starts_with)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.ends_with,
    label: '[条件値]で終わる(ends_with)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.regexp,
    label: '正規表現にマッチ',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];

const DateOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.lt,
    label: 'より小さい(<)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.le,
    label: '以下(<=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.gt,
    label: 'より大きい(>)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.ge,
    label: '以上(>=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  },
  { disableUseColumn: true, value: SwitchOperators.between, label: '範囲指定' },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];

const WeekdayOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  }
];

const DatetimeOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.lt,
    label: 'より小さい(<)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.le,
    label: '以下(<=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.gt,
    label: 'より大きい(>)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.ge,
    label: '以上(>=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  },
  { disableUseColumn: true, value: SwitchOperators.between, label: '範囲指定' },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];

const DatetimeOperatorYmdhms = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.lt,
    label: 'より小さい(<)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.le,
    label: '以下(<=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.gt,
    label: 'より大きい(>)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.ge,
    label: '以上(>=)',
    disableBetweenUseColumn: true
  },
  { disableUseColumn: true, value: SwitchOperators.between, label: '範囲指定' },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];

export const TimeOperator = [
  {
    value: SwitchOperators.eq,
    label: '等しい(=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.notEq,
    label: '等しくない(!=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.lt,
    label: 'より小さい(<)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.le,
    label: '以下(<=)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.gt,
    label: 'より大きい(>)',
    disableBetweenUseColumn: true
  },
  {
    value: SwitchOperators.ge,
    label: '以上(>=)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.in,
    label: 'いずれかに等しい(in)',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.not_in,
    label: 'いずれとも等しくない(not in)',
    disableBetweenUseColumn: true
  },
  { disableUseColumn: true, value: SwitchOperators.between, label: '範囲指定' },
  {
    disableUseColumn: true,
    value: SwitchOperators.drop_na,
    label: '欠損値を含まない',
    disableBetweenUseColumn: true
  },
  {
    disableUseColumn: true,
    value: SwitchOperators.extract_na,
    label: '欠損値を含む',
    disableBetweenUseColumn: true
  }
];
