import { RangeTimeParameter } from 'models/report';
import { relativeToString } from '../toString';
import { TimeParameterClass } from './base';
import dayjs from 'dayjs';
import { TimeRangeValues } from 'models/report/parameter';

export class TimeRangeClass implements TimeParameterClass {
  constructor(public param: RangeTimeParameter) {}
  getRelativeItems = (): {
    label: string;
    value: TimeRangeValues['select'];
  }[] => {
    return [
      { label: '過去1時間', value: 'past_1_hour' },
      { label: '過去6時間', value: 'past_6_hour' },
      { label: '過去12時間', value: 'past_12_hour' }
    ];
  };
  isValidStartTime = () => {
    return dayjs(
      `2000-01-01 ${this.param.values.inputValue.startTime}`
    ).isValid();
  };
  isValidEndTime = () => {
    return dayjs(
      `2000-01-01 ${this.param.values.inputValue.endTime}`
    ).isValid();
  };
  isValid = () => {
    return this.isValidStartTime() && this.isValidEndTime();
  };
  toDisplayString = () => {
    if (this.param.values.select === 'manual') {
      const { startTime, endTime } = this.calculateInputValue();
      // 具体的な日付
      return `${startTime} ~ ${endTime}`;
    }
    // 相対の文字列
    return relativeToString(this.param, this.getRelativeItems());
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
  calculateInputValue = (): TimeRangeValues['inputValue'] => {
    const { select, inputValue, inputPastValue } = this.param.values;
    switch (select) {
      case 'manual':
        return inputValue;

      // 相対日付が選択されている場合は、設定から具体的な日を計算する
      case 'input_past': {
        return {
          startTime: dayjs()
            .subtract(Number(inputPastValue.value || 0), inputPastValue.unit)
            .format('HH:mm:ss'),
          endTime: dayjs().format('HH:mm:ss')
        };
      }
      case 'past_1_hour':
        return {
          startTime: dayjs().subtract(1, 'hour').format('HH:mm:ss'),
          endTime: dayjs().format('HH:mm:ss')
        };
      case 'past_6_hour':
        return {
          startTime: dayjs().subtract(6, 'hour').format('HH:mm:ss'),
          endTime: dayjs().format('HH:mm:ss')
        };
      case 'past_12_hour':
        return {
          startTime: dayjs().subtract(12, 'hour').format('HH:mm:ss'),
          endTime: dayjs().format('HH:mm:ss')
        };
      default:
        const ex: never = select;
        return ex;
    }
  };
}
