import * as React from 'react';
import { ListItemText, MenuItem, Select } from '@material-ui/core';
import {
  AccessLevel,
  AccessLevelString,
  UserRole,
  UserRoleString
} from 'libs/accessLevel';
import { DatasourceStatus } from 'models/datasource';
import { DatasourceStatusText } from 'reducers/datasource';
import { Dtypes, toDtypeLabel } from 'Utils/dataTypes';
import { NodeStatus, NodeStatusText } from 'models/graph';
import { ListItemType } from 'models/dependency';
import { FilterState, FilterType } from 'ui/listView/filter/type';
import { ListQueryParams } from 'ui/listViewBase';
import {
  getQueryStringEndDate,
  getQueryStringStartDate
} from 'ui/listView/filter/customDate';
import { Checkbox } from 'ui/common/checkbox';
import { InputBase, TextField } from 'ui/common/textfield';

export const accessLevelList = {
  // [AccessLevel.Disabled]: AccessLevelString(AccessLevel.Disabled),
  [AccessLevel.Viewer]: AccessLevelString(AccessLevel.Viewer),
  [AccessLevel.Developer]: AccessLevelString(AccessLevel.Developer),
  [AccessLevel.Admin]: AccessLevelString(AccessLevel.Admin)
};

export const userRoleList = {
  // [AccessLevel.Disabled]: AccessLevelString(AccessLevel.Disabled),
  [UserRole.Developer]: UserRoleString(UserRole.Developer),
  [UserRole.Admin]: UserRoleString(UserRole.Admin)
};

export const datasourceStatusList = {
  [DatasourceStatus.Running]: DatasourceStatusText(DatasourceStatus.Running),
  [DatasourceStatus.Completed]: DatasourceStatusText(
    DatasourceStatus.Completed
  ),
  [DatasourceStatus.Failed]: DatasourceStatusText(DatasourceStatus.Failed)
};

export const dataTypeList = {
  [Dtypes.STRING]: toDtypeLabel(Dtypes.STRING),
  [Dtypes.NUMBER]: toDtypeLabel(Dtypes.NUMBER),
  [Dtypes.BOOLEAN]: toDtypeLabel(Dtypes.BOOLEAN),
  [Dtypes.DATE]: toDtypeLabel(Dtypes.DATE),
  [Dtypes.TIMESTAMP]: toDtypeLabel(Dtypes.TIMESTAMP),
  [Dtypes.TIMEDELTA]: toDtypeLabel(Dtypes.TIMEDELTA),
  [Dtypes.TIME]: toDtypeLabel(Dtypes.TIME),
  [Dtypes.ARRAY]: toDtypeLabel(Dtypes.ARRAY),
  [Dtypes.RECORD]: toDtypeLabel(Dtypes.RECORD)
};

export const variableTypeList = {
  [Dtypes.STRING]: toDtypeLabel(Dtypes.STRING),
  [Dtypes.NUMBER]: toDtypeLabel(Dtypes.NUMBER),
  [Dtypes.DATE]: toDtypeLabel(Dtypes.DATE),
  [Dtypes.TIMESTAMP]: toDtypeLabel(Dtypes.TIMESTAMP)
};

export const exportStatusList = {
  [NodeStatus.Temporary]: NodeStatusText(NodeStatus.Temporary),
  [NodeStatus.Warning]: NodeStatusText(NodeStatus.Warning),
  [NodeStatus.Executing]: NodeStatusText(NodeStatus.Executing),
  [NodeStatus.Executed]: NodeStatusText(NodeStatus.Executed),
  [NodeStatus.Error]: NodeStatusText(NodeStatus.Error)
};

export const createQueryParams = (
  type: ListItemType,
  state: FilterState
): ListQueryParams => {
  switch (type) {
    case 'builders':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        filtering: state['filtering'],
        name: state[FilterType.BuilderName],
        types: state[FilterType.DatasourceTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'connections':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        name: state[FilterType.ConnectionName],
        types: state[FilterType.DatasourceTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'datasources':
    case 'selector_datasource':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        filtering: state['filtering'],
        name: state[FilterType.DatasourceName],
        statuses: state[FilterType.DatasourceStatuses].join(),
        types: state[FilterType.DatasourceTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'exports':
    case 'selector_export':
      return {
        executionEndCondition: state[FilterType.ExecutionEndCondition],
        executionEndRangeStart: getQueryStringStartDate(
          state[FilterType.ExecutionEndRange]
        ),
        executionEndRangeEnd: getQueryStringEndDate(
          state[FilterType.ExecutionEndRange]
        ),
        executionStartCondition: state[FilterType.ExecutionStartCondition],
        executionStartRangeStart: getQueryStringStartDate(
          state[FilterType.ExecutionStartRange]
        ),
        executionStartRangeEnd: getQueryStringEndDate(
          state[FilterType.ExecutionStartRange]
        ),
        name: state[FilterType.ProjectName],
        statuses: exportStatusFormParams(
          state[FilterType.ExportStatuses]
        ).join(),
        types: state[FilterType.DatasourceTypes].join()
      };
    case 'projects':
    case 'visualize_selector':
    case 'selector_project':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        filtering: state['filtering'],
        name: state[FilterType.ProjectName],
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'report_selector':
    case 'reports':
    case 'selector_report':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        filtering: state['filtering'],
        limitedAccess: state[FilterType.LimitedAccess],
        name: state[FilterType.ReportName],
        publicUrlExists: state[FilterType.PublicURLExists],
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'variables':
      return {
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        filtering: state['filtering'],
        name: state[FilterType.VariableName],
        types: state[FilterType.DataTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'schedules':
      return {
        filtering: state['filtering'],
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        excludeFolders: state[FilterType.ExcludeFolders],
        name: state[FilterType.ScheduleName],
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange]),
        isScheduled: state[FilterType.IsScheduled]
      };
    case 'notifications':
      return {
        filtering: state['filtering'],
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        name: state[FilterType.NotificationName],
        dstTypes: state[FilterType.NotificationDstTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'notification_dsts':
      return {
        filtering: state['filtering'],
        accessLevels: state[FilterType.AccessLevels].join(),
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        creators: state[FilterType.Creators].join(),
        name: state[FilterType.NotificationDstName],
        dstTypes: state[FilterType.NotificationDstTypes].join(),
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'organizations':
      return {
        createCondition: state[FilterType.CreateCondition],
        createRangeStart: getQueryStringStartDate(
          state[FilterType.CreateRange]
        ),
        createRangeEnd: getQueryStringEndDate(state[FilterType.CreateRange]),
        name: state[FilterType.OrganizationName],
        updateCondition: state[FilterType.UpdateCondition],
        updateRangeStart: getQueryStringStartDate(
          state[FilterType.UpdateRange]
        ),
        updateRangeEnd: getQueryStringEndDate(state[FilterType.UpdateRange])
      };
    case 'users':
      return {
        filtering: state['filtering'],
        email: state[FilterType.UserEmail],
        user_types: state[FilterType.UserTypes].join(),
        password_generated: state[FilterType.UserPasswordGenerated],
        user_groups: state[FilterType.UserGroups].join()
      };
    case 'user_groups':
      return {
        filtering: state['filtering'],
        name: state[FilterType.UserGroupName],
        has_ip_address: state[FilterType.HasIPAddress]
      };
    default:
      return {};
  }
};

export const CheckboxForm: React.FC<{
  title: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ title, value, onChange }) => {
  return (
    <tr>
      <td>{title}</td>
      <td>
        <Checkbox data-cy={title} checked={value} onChange={onChange} />
      </td>
    </tr>
  );
};

export const SelectForm: React.FC<{
  title: string;
  labels: { [key in number | string]: string };
  values: string[];
  onChange: (event: React.ChangeEvent<{ value: string[] }>) => void;
}> = ({ title, labels, values, onChange }) => {
  return (
    <tr>
      <td>{title}</td>
      <td>
        <Select
          data-cy={title}
          multiple
          value={values.map((value) => labels[value])}
          onChange={onChange}
          input={<InputBase />}
          renderValue={(selected) => (selected as string[]).join(', ')}
          fullWidth={true}
          MenuProps={{
            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
            getContentAnchorEl: null
          }}
        >
          {Object.entries(labels).map(([key, label]) => (
            <MenuItem key={key} value={label}>
              <Checkbox size="small" checked={values.includes(key)} />
              <ListItemText primary={label} />
            </MenuItem>
          ))}
        </Select>
      </td>
    </tr>
  );
};

export const TextForm: React.FC<{
  title: string;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ title, value, onChange }) => {
  return (
    <tr>
      <td>{title}</td>
      <td>
        <TextField
          data-cy={title}
          type="text"
          value={value}
          onChange={onChange}
          autoFocus={true}
          fullWidth={true}
        />
      </td>
    </tr>
  );
};

export const exportStatusFormParams = (statuses: string[]) => {
  let newStatuses: string[] = [];
  statuses.forEach((status) => {
    switch (status) {
      case NodeStatus.Temporary:
        newStatuses.push(NodeStatus.Temporary, NodeStatus.Copied);
        break;
      case NodeStatus.Warning:
        newStatuses.push(NodeStatus.Warning, NodeStatus.EmptyOutput);
        break;
      case NodeStatus.Executing:
        newStatuses.push(NodeStatus.Prospecting, NodeStatus.Executing);
        break;
      case NodeStatus.Executed:
        newStatuses.push(NodeStatus.Prospected, NodeStatus.Executed);
        break;
      case NodeStatus.Error:
        newStatuses.push(NodeStatus.Error);
    }
  });
  return newStatuses;
};
