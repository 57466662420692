export interface Schedule {
  uuid?: string;
  name: string;
  relationships: ScheduleRelationship[];
  scheduling_config: SchedulingConfig;
  status?: ScheduleHistoryStatus;
  ignore_error_enabled: boolean;
  config_status: ConfigStatus;
}

export interface ScheduleRelationship {
  name: string;
  id: string;
  start_type: string;
  start_id: string;
  steps: ScheduleStep[];
  status: ConfigStatus;
  error: string;
  ignore_error: boolean;
}

type ScheduleStep = ScheduleStepDatasource | ScheduleStepProject;

interface ScheduleStepBase {
  id: string;
  relationship_id: string;
  item_id: string;
  item_status: ScheduleItemStatus;
  item_error: string;
  name: string;
  level: number;
  step_index: number;
  status: ConfigStatus;
  error: string;
}

export interface ScheduleStepDatasource extends ScheduleStepBase {
  item_type: 'datasource';
  datasource_type: string;
}

export interface ScheduleStepProject extends ScheduleStepBase {
  item_type: 'project';
  workflows: ScheduleStepWorkflow[];
}

export interface ScheduleStepWorkflow {
  id: number;
  item_id: string;
  item_type: 'workflow';
  item_status: ScheduleItemStatus;
  item_error: string;
  name: string;
  step_id: string;
  project_id: string;
  workflow_id: string;
  level: number;
  step_index: number;
  status: ConfigStatus;
  error: string;
}

export enum ScheduleItemStatus {
  Unknown = 0,
  OK,
  Running,
  Warning,
  Error
}

export enum ConfigStatus {
  OK = 0,
  Warning,
  Error
}

export interface ScheduleHistory {
  id: string;
  schedule_id: string;
  name: string;
  relationships: ScheduleRelationshipHistory[];
  outputs: ScheduleOutput[];
  started_at?: string;
  ended_at?: string;
  status: ScheduleHistoryStatus;
  error: string;
  error_node_id: string;
  error_project_id: string;
  error_datasource_id: string;
  trigger: 'immediate' | 'immediate_sub' | ExecutionTiming;
}

export enum ScheduleHistoryStatus {
  Unknown = 0,
  Pending,
  Running,
  Success,
  Failed,
  Skipped,
  Warning
}

export interface ScheduleRelationshipHistory {
  relationship: ScheduleRelationship;
  id: string;
  status: ScheduleHistoryStatus;
  steps: ScheduleStepHistory[];
  started_at?: string;
  ended_at?: string;
  order: number;
}

export interface ScheduleStepHistory {
  id: string;
  status: ScheduleHistoryStatus;
  workflows: ScheduleStepWorkflowHistory[];
  started_at?: string;
  ended_at?: string;
}

export interface ScheduleStepWorkflowHistory {
  id: number;
  status: ScheduleHistoryStatus;
  started_at?: string;
  ended_at?: string;
}

export interface ScheduleOutput {
  id: string;
  type: 'report' | 'nehan_internal' | 'export';
  name: string;
  workflow_id: string;
  project_id: string;
  node_id: string;
  export_type: string;
}

export type ExecutionTiming =
  | 'none'
  | 'hourly'
  | 'daily'
  | 'weekly'
  | 'monthly';

export type ExecutionInterval = '15m' | '30m' | '1h' | '3h' | '6h' | '12h';

export interface SchedulingConfig {
  enabled: boolean;
  timing: ExecutionTiming;
  weekdays: number;
  dates: number;
  time: string;
  interval: ExecutionInterval;
}

const triggerLabel: Record<ScheduleHistory['trigger'], string> = {
  immediate: '今すぐ実行',
  immediate_sub: '後続実行',
  none: 'なし',
  hourly: '毎時実行',
  daily: '毎日実行',
  weekly: '毎週実行',
  monthly: '毎月実行'
};

export function scheduleTriggerToLabel(t: ScheduleHistory['trigger']): string {
  return triggerLabel[t] ?? '-';
}
