import {
  APIUrl,
  _get,
  _getWithAccessToken,
  _delete,
  _getWithSharedToken,
  _post,
  _getWithAccessTokenV2
} from 'libs/api/base';
import { FormSchema } from 'models/datasource/form';

export function getModules() {
  return _get(`/modules`);
}

export function getBuilderModules(connectionType: string) {
  return _get('/builder_modules', { params: { connectionType } });
}

export function getModuleHelp(name: string) {
  return _get<string>(`/modules/${name}/help`);
}

export function getModuleHelpDetail(name: string, filename: string) {
  if (filename && filename.endsWith('.md')){
    filename = filename.slice(0, -3)
  }
  if (filename.indexOf('common/') != -1) {
    const filename_array = filename.split('/')
    filename = filename_array[filename_array.length - 1]
    name = 'common'
  }
  return _get<string>(`/modules/${name}/detail_help/${filename}`);
}

export function getDialogModuleHelpDetail(name: string, filename: string) {
  if (filename && filename.endsWith('.md')){
    filename = filename.slice(0, -3)
  }
  //if (filename.indexOf('common/') != -1) {
  //  const filename_array = filename.split('/')
  //  filename = filename_array[filename_array.length - 1]
  //  name = 'common'
  //}
  return _get<string>(`/modules/${name}/dialog_detail_help/${filename}`);
}

export function getBuilderModuleHelp(name: string) {
  return _get<string>(`/builder_modules/${name}/help`);
}

export async function getPortData(
  token: string,
  projectId: string,
  portId: string,
  lastModified?: string
) {
  return _getWithAccessToken(token, `/projects/${projectId}/port/${portId}`, {
    'If-Modified-Since': lastModified
  });
}

export async function getDatasourceCache(uuid: string | number) {
  return _getWithAccessTokenV2(`/datasources/${uuid}/cache`);
}

export async function getImageData(
  token: string,
  projectId: string,
  portId: string
) {
  return _getWithAccessToken(token, `projects/${projectId}/images/${portId}`);
}

export async function deletePortData(projectId: string, portIds: string[]) {
  return _delete(`/projects/${projectId}/ports`, { portIds });
}

export const getPortDataWithSharedToken: typeof getPortData = (
  token,
  projectId,
  portId,
  lastModified
) => {
  return _getWithSharedToken(
    token,
    `/embed/projects/${projectId}/port/${portId}`,
    { 'If-Modified-Since': lastModified }
  );
};

export const getImageDataWithSharedToken: typeof getImageData = (
  token,
  projectId,
  portId
) => {
  return _getWithSharedToken(
    token,
    `/embed/projects/${projectId}/images/${portId}`
  );
};

export function openDownloadUrl(data: {
  portId: string;
  exportId: string;
  projectId: string;
  fileName: string;
}) {
  const { projectId } = data;
  return _post(`/projects/${projectId}/download`, data);
}

export function uploadCSV(
  token: string,
  file: File,
  onProgress: XMLHttpRequestEventTarget['onprogress'],
  onLoad: XMLHttpRequestEventTarget['onload'],
  onError?: XMLHttpRequestEventTarget['onerror']
) {
  const xhr = new XMLHttpRequest();
  xhr.upload.onprogress = onProgress;
  xhr.onload = onLoad;
  if (onError != undefined) {
    xhr.onerror = onError;
    xhr.onabort = onError;
    xhr.ontimeout = onError;
  }

  xhr.open('POST', `${APIUrl}/datasources/csv`);
  xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
  xhr.setRequestHeader('Authorization', `Bearer ${token}`);

  const formData = new FormData();
  formData.append('file', file);
  xhr.send(formData);
}

export async function getDatasourceConfig(name: string) {
  return _get<FormSchema>(`/datasource_config/${name}`);
}

export function exportCode(projectId: string) {
  return _get<string>(`/export_code/${projectId}`);
}
