import * as React from 'react';
import {
  makeStyles,
  StyleRules,
  WithStyles,
  withStyles
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

const style: StyleRules = {
  fullWidth: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  content: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(224,224,224, 0.1)',
    height: '100%',
    width: '100%'
  },
  progress: {
    color: '#6798e5',
    animationDuration: '550ms'
  }
};
const SpinnerStyle = makeStyles(style);
export const Spinner: React.FC<{ size?: number; fullWidth?: boolean }> = ({
  size,
  fullWidth
}) => {
  const classes = SpinnerStyle();

  return (
    <div className={clsx(fullWidth && classes.fullWidth)}>
      <CircularProgress
        variant="indeterminate"
        disableShrink={true}
        className={classes.progress}
        thickness={4}
        size={size || 60}
      />
    </div>
  );
};

const ImageSet = ['A', 'B', 'C', 'D', 'E'];
export const LoadingImage: React.FC = () => {
  const d = new Date();
  const image =
    'loading_' +
    ImageSet[Math.floor(d.getMinutes() / 12) % ImageSet.length] +
    '.gif';
  return <img src={`/images/${image}`} alt="loading" />;
};

export const LoadingContent: React.VFC<{ text?: string }> = ({ text }) => {
  const classes = SpinnerStyle();
  return (
    <div className={classes.content}>
      <LoadingImage />
      <Typography variant="h5">{text}</Typography>
    </div>
  );
};

const SpinnerScreen: React.FC<{ text?: string } & WithStyles<typeof style>> = ({
  classes,
  text
}) => {
  return (
    <div className={classes.content}>
      <LoadingImage />
      {text && <Typography variant="h6">{text}</Typography>}
    </div>
  );
};
export default withStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh'
  }
})(SpinnerScreen);
