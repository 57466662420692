import type { ProcessItem } from '../reducers/processList';

const traverseList = (
  item: ProcessItem,
  name: string
): ProcessItem | undefined => {
  if (item.name === name) {
    return item;
  }
  if (item.children !== undefined) {
    for (const child of item.children) {
      const res = traverseList(child, name);
      if (res !== undefined) {
        return res;
      }
    }
  }
  return undefined;
};

export function findProcessById(
  processList: ProcessItem[],
  name: string
): ProcessItem | undefined {
  let processItem;
  for (const item of processList) {
    processItem = traverseList(item, name);
    if (processItem !== undefined) {
      break;
    }
  }
  return processItem;
}
