import React, { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Calendar, DateRangePicker } from 'components/react-date-range';
import ja from 'date-fns/locale/ja';

type Range<T> = [T, T];
export type NullableDayjs = dayjs.Dayjs | null;
export type DayjsValue = NullableDayjs | Range<NullableDayjs>;

type NullableDate = Date | null;
export type DateValue = NullableDate | Range<NullableDate>;

interface Props<T extends DayjsValue> {
  date: T;
  setDate: (date: T) => void;
  startMonth: dayjs.Dayjs;
}

const dateValueToDayjsValue = (dateValue: DateValue): DayjsValue => {
  if (Array.isArray(dateValue)) {
    return dateValue.map((d) => (d ? dayjs(d) : null)) as Range<NullableDayjs>;
  }
  return dateValue ? dayjs(dateValue) : null;
};

const dayjsValueToDateValue = (dateValue: DayjsValue): DateValue => {
  if (Array.isArray(dateValue)) {
    return dateValue.map((d) => (d ? d.toDate() : null)) as Range<NullableDate>;
  }
  return dateValue ? dateValue.toDate() : null;
};

export const Calendars = <T extends DayjsValue>({
  date: _date,
  setDate: _setDate,
  startMonth
}: Props<T>) => {
  const date = useMemo(() => {
    return dayjsValueToDateValue(_date);
  }, [_date]);

  const setDate = useCallback(
    (date: DateValue) => {
      _setDate(dateValueToDayjsValue(date) as T);
    },
    [_setDate]
  );
  return (
    <>
      {Array.isArray(date) ? (
        <DateRangePicker
          lang="jp"
          locale={ja}
          onChange={(item) => {
            setDate([item.selection.startDate, item.selection.endDate]);
          }}
          showSelectionPreview={true}
          moveRangeOnFirstSelection={false}
          months={3}
          monthDisplayFormat={'yyyy年MM月'}
          dateDisplayFormat={'yyyy年MM月d日'}
          staticRanges={[]}
          inputRanges={[
            {
              startDate: dayjs().toDate(),
              endDate: dayjs().toDate(),
              key: 'selection',
              color: 'rgba(0, 0, 0, 0)'
            }
          ]}
          scroll={{ enabled: false }}
          ranges={
            date[0] && date[1]
              ? [
                  {
                    startDate: date[0],
                    endDate: date[1],
                    key: 'selection'
                  }
                ]
              : [
                  {
                    key: 'selection',
                    color: 'rgba(0, 0, 0, 0)',
                    startData: 0,
                    endData: 0
                  }
                ]
          }
          direction="vertical"
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          showMonthArrow={false}
          showPreview={false}
          startMonth={startMonth.toDate()}
          minDate={dayjs('1900-01-01').toDate()}
          maxDate={dayjs().add(10, 'year').toDate()}
        />
      ) : (
        <Calendar
          direction="vertical"
          months={3}
          displayMode="date"
          showPreview={false}
          dragSelectionEnabled={false}
          lang="jp"
          scroll={{ enabled: false }}
          locale={ja}
          monthDisplayFormat={'yyyy年MM月'}
          dateDisplayFormat={'yyyy年MM月d日'}
          moveRangeOnFirstSelection={false}
          onChange={setDate}
          date={date}
          startMonth={startMonth.toDate()}
          minDate={dayjs('1900-01-01').toDate()}
          maxDate={dayjs().add(10, 'year').toDate()}
        />
      )}
    </>
  );
};
