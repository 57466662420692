import React from 'react';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { DateRange } from '@material-ui/icons';
import { ClassNameMap } from '@material-ui/styles';
import { DatePicker } from './datePicker';
import clsx from 'clsx';

export const StyledDatePicker: React.FC<
  {
    date: string; // YYYY-MM-DD
    setDate: (date: string) => void;
    isValid: boolean;
    classes: ClassNameMap<
      | 'datePickerWrapper'
      | 'datePickerBorder'
      | 'datePickerBorderError'
      | 'datePickerIcon'
      | 'datePicker'
      | 'datePickerInput'
      | 'datePickerErrorMSg'
    >;
  } & Partial<KeyboardDatePickerProps>
> = ({ setDate, date, isValid, classes, ...props }) => {
  return (
    <div className={classes.datePickerWrapper}>
      <div
        className={clsx(classes.datePickerBorder, {
          [classes.datePickerBorderError]: !isValid
        })}
      >
        <DateRange className={classes.datePickerIcon} />
        <DatePicker
          className={classes.datePicker}
          inputProps={{ className: classes.datePickerInput }}
          date={date}
          setDate={setDate}
          {...props}
        />
      </div>
      {!isValid && (
        <span className={classes.datePickerErrorMSg}>日付が不正です</span>
      )}
    </div>
  );
};
