import { Dtypes } from 'Utils/dataTypes';
import { SelectFieldValueElement } from 'models/form/schema';

export enum SelectTypes {
  all_columns = 'all_columns',
  column_names = 'column_names',
  column_types = 'column_types',
  regex = 'regex',
  starts_with = 'starts_with',
  ends_with = 'ends_with',
  includes = 'includes'
}

export type ColumnSelectRules = ColumnSelectRule[];

export type ColumnSelectRule =
  | SelectAllColumns
  | SelectColumnNames
  | SelectColumnTypes
  | SelectByRegex
  | SelectByStartsWith
  | SelectByEndsWith
  | SelectByIncludes;

interface ColumnSelectRuleBase {
  exclude: boolean;
  type: SelectTypes;
}

export interface SelectAllColumns extends ColumnSelectRuleBase {
  type: SelectTypes.all_columns;
  value: null;
}

export interface SelectColumnNames extends ColumnSelectRuleBase {
  type: SelectTypes.column_names;
  value: string[];
}

export interface SelectColumnTypes extends ColumnSelectRuleBase {
  type: SelectTypes.column_types;
  value: Dtypes[];
}

export interface SelectByRegex extends ColumnSelectRuleBase {
  type: SelectTypes.regex;
  value: string;
}

export interface SelectByStartsWith extends ColumnSelectRuleBase {
  type: SelectTypes.starts_with;
  value: string;
}

export interface SelectByEndsWith extends ColumnSelectRuleBase {
  type: SelectTypes.ends_with;
  value: string;
}

export interface SelectByIncludes extends ColumnSelectRuleBase {
  type: SelectTypes.includes;
  value: string;
}

export interface ColumnSelectV2Value {
  version: 2;
  rules: ColumnSelectRules;
}

export interface ColumnRenameRule {
  originName: string;
  afterName: string;
}

export type ColumnRenameRules = ColumnRenameRule[]

export interface ColumnRenameRulesValue {
  renames: ColumnRenameRules
}

export interface ColumnOrder {
  name: string;
  dtype: Dtypes;
}

export interface ColumnOrderValues {
  columns: ColumnOrder[];
}
//interface SwitchBase {
//  exclude: boolean;
//}

export enum SwitchOperators {
  eq = '==',
  notEq = '!=',
  lt = '<',
  le = '<=',
  gt = '>',
  ge = '>=',
  between = 'between',
  in = 'in',
  not_in= 'not_in',
  drop_na = 'drop_na',
  extract_na = 'extract_na',
  grep = 'grep',
  ungrep = 'ungrep',
  starts_with = 'starts_with',
  ends_with = 'ends_with',
  regexp = 'regexp',

}

export enum SwitchBetweenOperators {
  left_shift = '<<',
  right_shift_assign = '=<<',
  left_shift_assign = '<<=',
  both_shift_assign = '=<<='
}

export enum SwitchDatetimeElement {
  year = 'year',
  month = 'month',
  day = 'day',
  hour = 'hour',
  minute = 'minute',
  second = 'second',
  ymd = 'year-month-day',
  ym = 'year-month',
  ymdhms = 'ymdhms',
  weekday = 'weekday',
  hms = 'hms'
}

export enum SwitchWeekdayList {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday',
}

export interface SwitchAndCondition {
  searchedColnames: SwitchSelectColumn | null;
  operator: SwitchOperators | null;
  datetimeType: SwitchDatetimeElement | null;
  weekdayType: SwitchWeekdayList | SwitchWeekdayList[] |null;
  betweenOperator: SwitchBetweenOperators | null;
  valueUseColumn: boolean | null;
  filterColnames: SwitchSelectColumn | null;
  valueStart: string | null;
  valueStartUseColumn: boolean | null;
  startFilterColnames: SwitchSelectColumn | null;
  valueEnd: string | null;
  valueEndUseColumn: boolean | null;
  endFilterColnames: SwitchSelectColumn | null;
  value: string | null;
}

export type SwitchAndConditions = SwitchAndCondition[];

export interface SwitchCondition {
  andConditions: SwitchAndConditions;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
}
export interface SwitchConditions {
  conditions: SwitchCondition[];
  elseUseColumn: boolean | null;
  elseValue: string | null;
  elseColname: SwitchSelectColumn | null;
}

export interface SwitchSelectColumn {
  label: string;
  value: string;
  dtype: Dtypes | string;
}

export interface ColumnSelectFieldValueElement extends SelectFieldValueElement {
  dtype: Dtypes;
  isError?: boolean;
}
export type SelectFieldValue =
  | SelectFieldValueElement
  | SelectFieldValueElement[];

export type ColumnSelectFieldValue =
  | ColumnSelectFieldValueElement
  | ColumnSelectFieldValueElement[];

export type PrimitiveFieldValue =
  | boolean
  | string
  | string[]
  | number
  | SelectFieldValue
  | ColumnSelectFieldValue
  | ColumnSelectV2Value
  | ColumnRenameRulesValue
  | SwitchConditions
  | mlPreprocessValue
  | mlModelValues
  | ColumnOrderValues
  | null
  | undefined;

export type FieldValue =
  | PrimitiveFieldValue
  | ObjectFieldValue
  | ArrayFieldValue;

export interface FormValue {
  $?: FormValue;
  [k: string]: FieldValue;
}

export type ArrayFieldValue = FormValue[];
export type ObjectFieldValue = FormValue;

export interface mlPreprocessValue {
  num_imputer?: mlPreprocessImputer[]
  cat_imputer?: mlPreprocessImputer[]
  num_transformer?: mlPreprocessTransformer[]
  cat_transformer?: mlPreprocessTransformer[]
  outlier_remover?: mlPreprocessTransformer[]
  feature_selector?: mlPreprocessFeatureSelector
}

export interface mlPreprocessValueBase {
  strategy?: string[]
  target_cols?: ColumnSelectV2Value
  use_all_cols?: boolean
}

export interface mlPreprocessTransformer extends mlPreprocessValueBase {
  compare_with_disable?: boolean
}

export interface mlPreprocessImputer extends mlPreprocessValueBase {
  fill_value?: string
}

export interface mlPreprocessFeatureSelector {
  feature_selector_strategy?: FieldValue
  feature_selector_topn?: string
}

interface HyperParamsValueBase {
  model_type: string
}

export interface RFHyperParams extends HyperParamsValueBase {
  n_estimators: string
  criterion: string[]
  max_features: string | undefined
  random_state: string 
}

export interface XGBHyperParams extends HyperParamsValueBase {
  booster: string[]
  n_estimators: string
  learning_rate: string
  min_child_weight: string
  max_depth: string
  gamma: string
  subsample: string
  colsample_bytree: string
  reg_alpha: string
  reg_lambda: string
  random_state: string
}

export interface LGBMHyperParams extends HyperParamsValueBase {
  num_leaves: string
  max_depth: string
  learning_rate: string
  n_estimators: string
  random_state: string
}

export interface CatBoostHyperParams extends HyperParamsValueBase {
  iterations: string
  depth: string
  learning_rate: string
  random_strength: string
  bagging_temperature: string
  od_type: string[]
  random_state: string
}

export interface SVMHyperParams extends HyperParamsValueBase {
  kernel: string[]
  degree: string | undefined
  random_state: string
}

export interface LMHyperParams extends HyperParamsValueBase {
  remove_intercept: boolean
}


export interface LogitHyperParams extends HyperParamsValueBase {
  remove_intercept: boolean
}

export interface GLMHyperParams extends HyperParamsValueBase {
  family: string[]
  remove_intercept: boolean
}

export interface LMEMHyperParams extends HyperParamsValueBase {
  remove_intercept: boolean
}

export interface DTreeHyperParams extends HyperParamsValueBase {
  max_depth: string
  min_samples_leaf: string
  min_samples_split: string
  random_state: string
}

export interface mlModelValues {
  rf_hyper_params?: RFHyperParams
  xgb_hyper_params?: XGBHyperParams
  lgbm_hyper_params?: LGBMHyperParams
  catb_hyper_params?: CatBoostHyperParams
  svm_hyper_params?: SVMHyperParams
  lm_hyper_params?: LMHyperParams
  logit_hyper_params?: LogitHyperParams
  glm_hyper_params?: GLMHyperParams
  lmem_hyper_params?: LMEMHyperParams
  decisionTree_hyper_params?: DTreeHyperParams
}

export type HyperParams = 
 | RFHyperParams 
 | XGBHyperParams
 |LGBMHyperParams
 | CatBoostHyperParams
 | SVMHyperParams
 | LMHyperParams
 | LogitHyperParams
 | GLMHyperParams
 | LMEMHyperParams
 | DTreeHyperParams;