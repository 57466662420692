import * as React from 'react';
import { uniq } from 'lodash-es';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import {
  ConfigStatus,
  Schedule,
  ScheduleHistory,
  ScheduleHistoryStatus,
  ScheduleItemStatus,
  ScheduleOutput,
  ScheduleStepDatasource,
  ScheduleStepProject,
  ScheduleStepWorkflow
} from 'models/schedule';
import { AutoSizer, GridCellRenderer, MultiGrid } from 'react-virtualized';
import {
  isMovableLeft,
  isMovableRight,
  maxStepIndexPerLevel,
  Row,
  ScheduleAction,
  sortSteps
} from 'components/schedule/useSchedule';
import {
  AddCircleOutline,
  ArrowDropDown,
  ArrowRight,
  Autorenew,
  Cached,
  CheckCircle,
  Delete,
  DeleteOutlined,
  Error,
  OpenInNew,
  RadioButtonUnchecked,
  RemoveCircle,
  Warning
} from '@material-ui/icons';
import {
  Avatar,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  Divider,
  Fab,
  FormControlLabel,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  Tooltip
} from '@material-ui/core';
import { SelectDialog } from 'components/schedule/targetSelectDialog';
import {
  getManualSchedule,
  getTemporarySchedule,
  resetScheduleRelationship
} from 'libs/api/schedule';
import { createLink as dsCreateLink } from 'reducers/datasource';
import { Toolbar } from 'ui/common/toolbar';
import {
  DatasourceIcon,
  ProjectIcon,
  ReportIcon,
  WorkflowIcon
} from 'components/icons/icons';
import { NehanProjectColor, NehanReportColor } from '../../theme';
import SvgIcRelationships from 'components/icons/IcRelationships';
import SvgIcAfterExecution from 'components/icons/IcAfterExecution';
import { TitleField } from 'components/visualize/form/title';
import { Button } from 'ui/common/button';
import { orange } from '@material-ui/core/colors';
import { Spacer } from 'components/ui/common/spacer';
import {
  GoArrowDown,
  GoArrowLeft,
  GoArrowRight,
  GoArrowUp
} from 'react-icons/go';

const LEFT_COLUMN_WIDTH = 400;
const COLUMN_WIDTH = 100;
const ROW_HEIGHT = 36;
const GROUP_MARGIN = 4;

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column'
  },
  scheduleGrid: {
    flex: '1 1 auto'
  },
  footer: {
    padding: 8
  },
  relationship_button: {
    color: '#1b89a6'
  },
  header: {
    height: 64,
    display: 'flex',
    fontWeight: 600
  },
  headerLeft: {
    width: LEFT_COLUMN_WIDTH,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerRight: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLeftTop: {
    borderBottom: '2px solid gray'
  },
  leftColumn: {
    borderRight: '1px solid gray'
  },
  indexCell: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '2px solid gray',
    width: COLUMN_WIDTH,
    height: ROW_HEIGHT
  },
  row: {
    display: 'flex'
  },
  relation_row: {
    backgroundColor: '#f2f2f2'
  },
  cell: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid grey',
    height: ROW_HEIGHT,
    width: COLUMN_WIDTH
  },
  lastCell: {
    borderRight: 'none',
    backgroundColor: 'white !important'
  },
  output: {
    width: 24,
    height: 24,
    color: '#fff',
    backgroundColor: '#5099f8',
    transform: 'rotate(90deg)'
  },
  leftCell: {
    justifyContent: 'left',
    borderBottom: '1px solid silver'
  },
  leftContent: {
    display: 'flex',
    width: '100%',
    height: '100%',
    alignItems: 'center'
  },
  relation_left: {
    backgroundColor: '#f2f2f2'
  },
  datasource_left: {
    paddingLeft: 36
  },
  project_left: {
    paddingLeft: 36
  },
  workflow_left: {
    paddingLeft: 64
  },
  report_left: {
    paddingLeft: 36
  },
  nehan_internal_left: {
    paddingLeft: 36
  },
  export_left: {
    paddingLeft: 36
  },
  datasource_row: {},
  project_row: {},
  workflow_row: {},
  arrow: {
    color: '#838383',
    cursor: 'pointer'
  },
  item: {
    height: 16,
    width: 80,
    borderRadius: 8,
    backgroundColor: '#a3a3a3',
    zIndex: 2
  },
  item_move_left: {
    position: 'absolute',
    color: '#0089a7',
    zIndex: 3,
    padding: 0,
    top: 6,
    left: -14
  },
  item_move_right: {
    position: 'absolute',
    color: '#0089a7',
    zIndex: 3,
    padding: 0,
    top: 6,
    right: -14
  },
  item_execute_follow: {
    position: 'absolute',
    zIndex: 3,
    minHeight: 32,
    height: 32,
    width: 32,
    color: '#fff',
    backgroundColor: '#7fce5d',
    boxShadow: 'none',
    borderRadius: 4,
    '&:hover': {
      backgroundColor: '#549d35'
    }
  },
  header_base: {
    position: 'absolute',
    backgroundColor: '#344955',
    height: 14,
    width: 91,
    zIndex: 2
  },
  header_start: {
    right: -1,
    borderRadius: '7px 0 0 7px'
  },
  header_intermediate: {
    left: 0,
    right: 0,
    width: 100
  },
  header_end: {
    left: 0,
    width: 90,
    borderRadius: '0 7px 7px 0'
  },
  header_single: {
    width: 82,
    position: 'relative',
    borderRadius: 7
  },
  group_base: {
    position: 'absolute',
    backgroundColor: '#e9eced',
    zIndex: 1
  },
  group_top_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '8px 0 0 0'
  },
  group_top_middle: {
    left: 0,
    right: 0,
    top: GROUP_MARGIN,
    bottom: 0
  },
  group_top_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '0 8px 0 0'
  },
  group_middle_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: 0,
    bottom: 0
  },
  group_middle_middle: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  group_middle_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: 0,
    bottom: 0
  },
  group_bottom_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 0 8px'
  },
  group_bottom_middle: {
    left: 0,
    right: 0,
    top: 0,
    bottom: GROUP_MARGIN
  },
  group_bottom_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 8px 0'
  },
  group_top_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '8px 8px 0 0'
  },
  group_middle_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: 0,
    bottom: 0
  },
  group_bottom_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 8px 8px'
  },
  row_error: {
    backgroundColor: '#ff23192e'
  },
  row_warning: {
    backgroundColor: '#f09f4d2e'
  },
  row_hover: {
    backgroundColor: '#eff4f8'
  },
  row_hover_error: {
    backgroundColor: '#ff23194a'
  },
  row_hover_warning: {
    backgroundColor: '#f09f4d4a'
  },
  row_name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  add_icon: {
    position: 'absolute',
    left: 24,
    bottom: 24
  },
  checkbox: {
    position: 'absolute',
    left: 0,
    padding: 6,
    zIndex: 20
  }
});

const toolbarStyles = makeStyles({
  label: {
    fontSize: 14,
    fontWeight: 'bold',
    color: '#525252'
  },
  toolbar: {
    backgroundColor: '#fffbed'
  }
});

function outKey(output: ScheduleOutput): string {
  return `${output.type}-${output.id}-${output.project_id}-${output.node_id}`;
}

export function scheduleToRows(
  s: Schedule | ScheduleHistory,
  outputs: ScheduleOutput[]
): Row[] {
  const res: Row[] = [];
  let colIdx = 0;

  // Outputは最後のものだけを表示したい
  const outputPos: Record<string, string[]> = {};
  const outputMap: Record<string, ScheduleOutput> = {};
  const wOutputs: Record<string, ScheduleOutput[]> = {};
  outputs.forEach((out) => {
    const key = outKey(out);
    outputMap[key] = out;
    if (outputPos[key]) {
      outputPos[key].push(out.workflow_id);
    } else {
      outputPos[key] = [out.workflow_id];
    }
    if (wOutputs[out.workflow_id]) {
      wOutputs[out.workflow_id].push(out);
    } else {
      wOutputs[out.workflow_id] = [out];
    }
  });

  s.relationships.forEach((rel, relIndex) => {
    // per relation
    const relStart = colIdx;
    const relations: Row[] = [];
    let lastLevel = 0;
    let lastIndex = 0;

    [...rel.steps].sort(sortSteps).forEach((step, stepIndex) => {
      if (lastLevel !== step.level || lastIndex !== step.step_index) {
        const lastRow = relations[relations.length - 1];
        if (lastRow != undefined) {
          colIdx = lastRow.columnIndex + lastRow.columnWidth;
        }
      }

      if (step.item_type === 'datasource') {
        // add single step
        relations.push({
          ...step,
          type: 'datasource',
          name: step.name,
          item_type: step.item_type,
          item_id: step.item_id,
          item_status: step.item_status,
          item_error: step.item_error,
          relationship: rel,
          step,
          columnIndex: colIdx,
          columnWidth: 1,
          status:
            step.status != undefined
              ? step.status
              : ScheduleHistoryStatus.Unknown,
          configStatus: step.status,
          error: step.error,
          relIndex,
          relationshipLength: s.relationships.length,
          stepIndex,
          workflowIndex: -1,
          relationshipId: rel.start_id,
          relationshipType: rel.start_type,
          relationshipProjectId: '',
          relationshipWorkflowId: ''
        });
      } else if (step.item_type === 'project') {
        const groupColumnIndex = colIdx;
        const projectRow: Row = {
          type: 'project',
          item_type: step.item_type,
          item_id: step.item_id,
          item_status: step.item_status,
          item_error: step.item_error,
          id: step.id,
          name: step.name,
          step,
          relationship: rel,
          data: [],
          columnIndex: groupColumnIndex,
          columnWidth: 1,
          groupContext: 'top',
          groupColumnIndex,
          status:
            step.status != undefined
              ? step.status
              : ScheduleHistoryStatus.Unknown,
          configStatus: step.status,
          error: step.error,
          relIndex,
          relationshipLength: s.relationships.length,
          stepIndex,
          workflowIndex: -1,
          relationshipId: rel.start_id,
          relationshipType: rel.start_type,
          relationshipProjectId: '',
          relationshipWorkflowId: ''
        };

        const outputRows: Row[] = [];
        let lastWorkflowLevel = 0;
        let lastWorkflowIndex = 0;
        let workflowColIdx = colIdx;
        [...step.workflows].sort(sortSteps).forEach((w, workflowIndex) => {
          if (
            lastWorkflowLevel !== w.level ||
            lastWorkflowIndex !== w.step_index
          ) {
            const lastRow = projectRow.data![projectRow.data!.length - 1];
            if (lastRow != undefined) {
              workflowColIdx = lastRow.columnIndex + lastRow.columnWidth;
            }
          }
          const wID = w.item_id.split('_')[1];
          const woutput = wOutputs[wID];
          if (woutput != undefined) {
            woutput.forEach((output) => {
              const key = outKey(output);
              // 自分自身のIDを消す
              outputPos[key] = outputPos[key].filter((id) => id != wID);
              // 空になってたら、最後の行になる
              if (outputPos[key].length === 0) {
                outputRows.push({
                  ...output,
                  item_type: output.type,
                  item_id: output.id,
                  item_status: ScheduleItemStatus.OK,
                  item_error: '',
                  relationship: rel,
                  columnIndex: workflowColIdx,
                  columnWidth: 1,
                  isOutput: true,
                  configStatus: ConfigStatus.OK,
                  error: '',
                  relIndex,
                  relationshipLength: s.relationships.length,
                  stepIndex: -1,
                  workflowIndex: -1,
                  relationshipId: rel.start_id,
                  relationshipType: rel.start_type,
                  relationshipProjectId: '',
                  relationshipWorkflowId: ''
                });
              }
            });
          }
          projectRow.data!.push({
            ...w,
            type: 'workflow',
            item_type: w.item_type,
            item_id: w.workflow_id,
            item_status: w.item_status,
            id: w.id,
            step: w,
            relationship: rel,
            columnIndex: workflowColIdx,
            columnWidth: 1,
            groupColumnIndex,
            groupContext: 'middle',
            status:
              w.status != undefined ? w.status : ScheduleHistoryStatus.Unknown,
            configStatus: w.status,
            error: w.error,
            relIndex,
            relationshipLength: s.relationships.length,
            stepIndex,
            workflowIndex,
            relationshipId: rel.start_id,
            relationshipType: rel.start_type,
            relationshipProjectId: step.item_id,
            relationshipWorkflowId: w.item_id
          });
          lastWorkflowIndex = w.step_index;
          lastWorkflowLevel = w.level;
        });

        // プロジェクトの幅を決める
        projectRow.columnWidth = Math.max(
          0,
          ...projectRow.data!.map(
            (r) => r.columnIndex + r.columnWidth - r.groupColumnIndex!
          )
        );
        projectRow.groupWidth = projectRow.columnWidth;
        for (let i = 0; i < projectRow.data!.length; i++) {
          projectRow.data![i].groupWidth = projectRow.columnWidth;
          if (i === projectRow.data!.length - 1) {
            projectRow.data![i].groupContext = 'bottom';
          }
        }
        relations.push(projectRow);
        if (outputRows.length > 0) {
          relations.push(...outputRows);
        }
      }

      lastLevel = step.level;
      lastIndex = step.step_index;
    });

    const lastRow = relations
      .slice()
      .reverse()
      .find((row) => !row.isOutput);
    if (lastRow != undefined) {
      colIdx = lastRow.columnIndex + lastRow.columnWidth;
    }

    // リレーションヘッダ行を追加
    const relationRow: Row = {
      type: 'relation',
      item_type: rel.start_type,
      item_id: rel.start_id,
      item_status: ScheduleItemStatus.OK,
      item_error: '',
      id: rel.id,
      name: rel.name,
      relationship: rel,
      data: relations,
      columnIndex: relStart,
      columnWidth: colIdx - relStart,
      status:
        rel.status != undefined ? rel.status : ScheduleHistoryStatus.Unknown,
      configStatus: rel.status,
      error: rel.error,
      relIndex,
      relationshipLength: s.relationships.length,
      stepIndex: -1,
      workflowIndex: -1,
      relationshipId: rel.start_id,
      relationshipType: rel.start_type,
      relationshipProjectId: '',
      relationshipWorkflowId: ''
    };
    res.push(relationRow);
  });

  return res;
}

export function expandRows(rows: Row[], collapsed: number[]): Row[] {
  const res: Row[] = [];
  rows.forEach((row) => {
    res.push(row);
    if (row.data && collapsed.indexOf(row.relIndex) === -1) {
      Array.prototype.push.apply(res, expandRows(row.data, collapsed));
    }
  });
  return res;
}

export function countColumns(rows: Row[]): number {
  return Math.max(0, ...rows.map((r) => r.columnIndex + r.columnWidth));
}

const IndexHeader: React.VFC<{ count: number; style: React.CSSProperties }> = ({
  count,
  style
}) => {
  const classes = useStyles();
  return (
    <div className={clsx(classes.header)} style={style}>
      {[...Array(count)].map((_, i) => (
        <div key={i} className={classes.indexCell}>
          {i + 1}
        </div>
      ))}
      <div className={classes.indexCell} />
    </div>
  );
};

const Checkbox = withStyles({
  root: {
    alignSelf: 'start',
    color: '#b8b8b8',
    '&$checked': {
      color: '#344955'
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);

const iconStyles = makeStyles({
  icon: {
    marginRight: 8
  }
});

export const LeftIcon: React.VFC<{ row: Row }> = ({ row }) => {
  const classes = iconStyles();
  switch (row.type) {
    case 'relation':
      return null;
    case 'project':
      return (
        <ProjectIcon htmlColor={NehanProjectColor} className={classes.icon} />
      );
    case 'workflow':
      return (
        <WorkflowIcon htmlColor={NehanProjectColor} className={classes.icon} />
      );
    case 'datasource':
      return (
        <DatasourceIcon
          type={(row?.step as ScheduleStepDatasource)?.datasource_type ?? ''}
          className={classes.icon}
        />
      );
    case 'report':
      return (
        <ReportIcon htmlColor={NehanReportColor} className={classes.icon} />
      );
    case 'nehan_internal':
      return <DatasourceIcon type="nehan_internal" className={classes.icon} />;
    case 'export':
      return (
        <DatasourceIcon type={row.export_type ?? ''} className={classes.icon} />
      );
  }

  return null;
};

const StatusIcon: React.VFC<{ row: Row }> = ({ row }) => {
  switch (row.configStatus) {
    case ConfigStatus.Warning:
      return (
        <Tooltip title={row.error}>
          <Warning style={{ color: orange[500] }} />
        </Tooltip>
      );
    case ConfigStatus.Error:
      return (
        <Tooltip title={row.error}>
          <Error color="error" />
        </Tooltip>
      );
  }
  switch (row.item_status) {
    case ScheduleItemStatus.Unknown:
    case ScheduleItemStatus.OK:
    case ScheduleItemStatus.Running:
    case ScheduleItemStatus.Warning:
      return null;
    case ScheduleItemStatus.Error:
      return (
        <Tooltip title={row.item_error}>
          <Error color="error" />
        </Tooltip>
      );
  }
  return null;
};

const LeftCell: React.VFC<{
  dispatch: React.Dispatch<ScheduleAction>;
  row: Row;
  style: React.CSSProperties;
  isOpen?: boolean;
  toggleOpen: (row: Row) => void;
  hover: boolean;
  onHover: (hovered: boolean) => void;
  checked: boolean;
  indeterminate?: boolean;
  showActions: boolean;
  onClickReset: (index: number) => void;
  onCheckChanged: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  onClickRemove: (row: Row) => void;
  onClickEdit: (
    type: string,
    id: string,
    workflowProjectId: string,
    workflowId: string,
    highlight: string,
    row: Row
  ) => void;
}> = ({
  dispatch,
  row,
  style,
  isOpen,
  toggleOpen,
  hover,
  onHover,
  indeterminate,
  checked,
  showActions,
  onClickReset,
  onCheckChanged,
  onClickEdit,
  onClickRemove
}) => {
  const classes = useStyles();
  const toggle = React.useCallback(() => toggleOpen(row), [toggleOpen, row]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (ev) => {
    setAnchorEl(ev.currentTarget);
    ev.stopPropagation();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        className={clsx(classes.cell, classes.leftColumn, classes.leftCell)}
        style={{
          ...style,
          top: 0,
          transform: `translate3d(${style.left}px, ${style.top}px, 0px)`,
          willChange: 'transform',
          transition: '500ms transform'
        }}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        <div
          className={clsx(
            classes.leftContent,
            classes[`${row.type}_left`],
            hover && row.configStatus === ConfigStatus.OK && classes.row_hover
          )}
        >
          {row.type === 'relation' && isOpen && (
            <ArrowDropDown onClick={toggle} className={classes.arrow} />
          )}
          {row.type === 'relation' && !isOpen && (
            <ArrowRight onClick={toggle} className={classes.arrow} />
          )}
          {row.type !== 'relation' && !row.isOutput && (
            <Checkbox
              className={classes.checkbox}
              icon={<RadioButtonUnchecked />}
              checkedIcon={<CheckCircle />}
              indeterminateIcon={<RemoveCircle />}
              indeterminate={indeterminate}
              checked={checked}
              onChange={onCheckChanged}
            />
          )}
          <LeftIcon row={row} />
          {row.type === 'relation' ? (
            <TitleField
              color="default"
              value={row.name}
              editable={true}
              onChange={(name) => {
                dispatch({
                  type: 'change_relationship_name',
                  relationship_index: row.relIndex,
                  name
                });
              }}
            />
          ) : (
            <a
              className={classes.row_name}
              href="#"
              onClick={(ev) => {
                handleClick(ev);
                ev.preventDefault();
              }}
            >
              {row.name}
            </a>
          )}
          <Spacer />
          {row.type !== 'relation' && row.relationshipId !== '' && (
            <StatusIcon row={row} />
          )}
          {row.type === 'relation' && (
            <>
              {showActions &&
                row.relationshipLength !== 1 &&
                row.relIndex !== 0 && (
                  <Tooltip title="このステップを上に移動">
                    <IconButton
                      size="small"
                      className={classes.relationship_button}
                      onClick={() =>
                        dispatch({
                          type: 'move_relationship',
                          direction: 'up',
                          index: row.relIndex
                        })
                      }
                    >
                      <GoArrowUp size="24px" />
                    </IconButton>
                  </Tooltip>
                )}
              {showActions &&
                row.relationshipLength !== 1 &&
                row.relIndex !== row.relationshipLength - 1 && (
                  <Tooltip title="このステップを下に移動">
                    <IconButton
                      size="small"
                      className={classes.relationship_button}
                      onClick={() =>
                        dispatch({
                          type: 'move_relationship',
                          direction: 'down',
                          index: row.relIndex
                        })
                      }
                    >
                      <GoArrowDown size="24px" />
                    </IconButton>
                  </Tooltip>
                )}
              {showActions && (
                <Tooltip title="ステップ内の並び順を初期化">
                  <IconButton
                    size="small"
                    className={classes.relationship_button}
                    onClick={() => onClickReset(row.relIndex)}
                  >
                    <Cached fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {showActions && (
                <Tooltip title="ステップ内に更新対象を追加">
                  <IconButton
                    size="small"
                    className={classes.relationship_button}
                    onClick={() =>
                      onClickEdit(
                        row.relationship.start_type,
                        row.relationship.start_id,
                        '',
                        '',
                        '',
                        row
                      )
                    }
                  >
                    <AddCircleOutline fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              {showActions && (
                <Tooltip title="このステップを削除">
                  <IconButton
                    size="small"
                    className={classes.relationship_button}
                    onClick={() => onClickRemove(row)}
                  >
                    <Delete fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </div>
      </div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={() => window.open(createLink(row))}>
          <ListItemIcon style={{ minWidth: 32 }}>
            <OpenInNew fontSize="small" />
          </ListItemIcon>
          {row.name} を開く
        </MenuItem>
        {row.relationshipType !== 'manual' && (
          <MenuItem
            onClick={() => {
              onClickEdit(
                row.relationshipType,
                row.relationshipId,
                row.relationshipProjectId,
                row.relationshipWorkflowId,
                row.item_id,
                row
              );
              handleClose();
            }}
          >
            <ListItemIcon style={{ minWidth: 32 }}>
              <SvgIcRelationships height={20} width={20} />
            </ListItemIcon>
            系譜を確認する
          </MenuItem>
        )}
        {!row.isOutput && (
          <MenuItem
            onClick={() => {
              onClickRemove(row);
              handleClose();
            }}
          >
            <ListItemIcon style={{ minWidth: 32 }}>
              <Delete fontSize="small" />
            </ListItemIcon>
            削除する
          </MenuItem>
        )}
      </Menu>
    </>
  );
};

const MaskGroup: React.VFC<{ row: Row; columnIndex: number }> = ({
  row,
  columnIndex
}) => {
  const classes = useStyles();
  if (
    row.groupContext &&
    row.groupColumnIndex != undefined &&
    row.groupWidth != undefined &&
    columnIndex >= row.groupColumnIndex &&
    columnIndex < row.groupColumnIndex + row.groupWidth
  ) {
    let placement = 'left';
    if (
      columnIndex > row.groupColumnIndex &&
      columnIndex < row.groupColumnIndex + row.groupWidth - 1
    ) {
      placement = 'middle';
    } else if (row.groupColumnIndex + row.groupWidth - 1 === columnIndex) {
      placement = 'right';
    }
    if (row.groupWidth === 1) {
      placement = 'single';
    }

    return (
      <div
        className={clsx(
          classes.group_base,
          classes[`group_${row.groupContext}_${placement}`]
        )}
      />
    );
  }

  return null;
};

const RightCell: React.VFC<{
  row: Row;
  columnCount: number;
  style: React.CSSProperties;
  scheduleDispatch: React.Dispatch<ScheduleAction>;
  hover: boolean;
  onHover: (hovered: boolean) => void;
  executeEnabled: boolean;
  onClickFollow: (row: Row) => void;
  moveLeftEnabled: boolean;
  moveRightEnabled: boolean;
}> = ({
  row,
  columnCount,
  style,
  scheduleDispatch,
  hover,
  onHover,
  executeEnabled,
  onClickFollow,
  moveLeftEnabled,
  moveRightEnabled
}) => {
  const classes = useStyles();
  const items: React.ReactNode[] = [];

  // fill empty cells
  for (let i = 0; i < row.columnIndex; i++) {
    items.push(
      <div key={i} className={classes.cell}>
        &nbsp;
        <MaskGroup row={row} columnIndex={i} />
      </div>
    );
  }

  for (let i = row.columnIndex; i < row.columnIndex + row.columnWidth; i++) {
    const isHeaderCell = row.data != undefined;
    const isHeaderStart =
      isHeaderCell && row.columnWidth > 1 && i === row.columnIndex;
    const isHeaderInter =
      isHeaderCell &&
      row.columnWidth > 1 &&
      i > row.columnIndex &&
      i < row.columnIndex + row.columnWidth - 1;
    const isHeaderEnd =
      isHeaderCell &&
      row.columnWidth > 1 &&
      i === row.columnIndex + row.columnWidth - 1;

    if (row.isOutput) {
      items.push(
        <div key={i} className={classes.cell}>
          <Tooltip title="ここで更新されます">
            <Avatar className={classes.output}>
              <Autorenew fontSize="small" />
            </Avatar>
          </Tooltip>
        </div>
      );
    } else {
      items.push(
        <div key={i} className={classes.cell}>
          <div
            className={clsx(
              row.columnWidth === 1 && classes.item,
              isHeaderCell && classes.header_base,
              // single
              isHeaderCell && row.columnWidth == 1 && classes.header_single,
              // serial
              isHeaderStart && classes.header_start,
              isHeaderInter && classes.header_intermediate,
              isHeaderEnd && classes.header_end
            )}
          />
          <MaskGroup row={row} columnIndex={i} />
          {hover &&
            row.type !== 'relation' &&
            !isHeaderInter &&
            !isHeaderEnd &&
            moveLeftEnabled &&
            i !== 0 && (
              <IconButton
                size="small"
                className={classes.item_move_left}
                onClick={() => {
                  row.step &&
                    scheduleDispatch({
                      type: 'move',
                      direction: 'left',
                      step: row.step,
                      relationship: row.relationship
                    });
                }}
              >
                <GoArrowLeft size="24px" />
              </IconButton>
            )}
          {hover &&
            row.type !== 'relation' &&
            !isHeaderStart &&
            !isHeaderInter &&
            moveRightEnabled && (
              <IconButton
                size="small"
                className={classes.item_move_right}
                onClick={() => {
                  row.step &&
                    scheduleDispatch({
                      type: 'move',
                      direction: 'right',
                      step: row.step,
                      relationship: row.relationship
                    });
                }}
              >
                <GoArrowRight size="24px" />
              </IconButton>
            )}
          {hover &&
            row.type !== 'relation' &&
            !isHeaderCell &&
            executeEnabled && (
              <Tooltip title="後続をすべて実行">
                <Fab
                  size="small"
                  className={classes.item_execute_follow}
                  onClick={() => onClickFollow(row)}
                >
                  <SvgIcAfterExecution />
                </Fab>
              </Tooltip>
            )}
        </div>
      );
    }
  }

  // fill empty
  for (let i = row.columnIndex + row.columnWidth; i < columnCount; i++) {
    items.push(
      <div key={i} className={classes.cell}>
        &nbsp;
        <MaskGroup row={row} columnIndex={i} />
      </div>
    );
  }

  // 右移動のために、隙間を追加する
  items.push(
    <div key={columnCount} className={clsx(classes.cell, classes.lastCell)}>
      &nbsp;
    </div>
  );

  return (
    <div
      style={{
        ...style,
        top: 0,
        transform: `translate3d(${style.left}px, ${style.top}px, 0px)`,
        willChange: 'transform',
        transition: '300ms transform'
      }}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      <div
        className={clsx(
          classes.row,
          classes[`${row.type}_row`],
          hover && row.configStatus === ConfigStatus.OK && classes.row_hover
        )}
      >
        {items}
      </div>
    </div>
  );
};

function getSelected(s: Schedule, relIndex: number): string[] {
  const rel = s.relationships[relIndex];
  if (!rel) {
    return [];
  }
  const ret: string[] = [];
  for (let j = 0; j < rel.steps.length; j++) {
    if (rel.steps[j].item_type === 'project') {
      ret.push(rel.steps[j].item_id);
      (rel.steps[j] as ScheduleStepProject).workflows.forEach((wf) => {
        ret.push(wf.item_id);
      });
    } else {
      ret.push(`${rel.steps[j].item_type}_${rel.steps[j].item_id}`);
    }
  }
  return ret;
}

interface ScheduleEditorProps {
  schedule: Schedule;
  outputs: ScheduleOutput[];
  dispatch: React.Dispatch<ScheduleAction>;
  onChangeOutputs: (outputs: ScheduleOutput[]) => void;
  executeEnabled: boolean;
  onExecuteFollow: (row: Row) => void;
}

const selectByStatus = (rows: Row[], status: ConfigStatus): Row[] => {
  const selected: Row[] = [];
  for (let i = 0; i < rows.length; i++) {
    if (rows[i].configStatus === status && rows[i].type !== 'relation') {
      selected.push(rows[i]);
    }
  }
  return selected;
};

const findProjectByWorkflowRow = (
  rows: Row[],
  workflowRow: Row
): Row | undefined => {
  return rows.find(
    (r) =>
      r.type === 'project' &&
      r.item_id === workflowRow.project_id &&
      r.relIndex === workflowRow.relIndex
  );
};

const allWorkflowsSelected = (
  selected: Row[],
  projectRow: Row | undefined
): boolean => {
  if (!projectRow) {
    return false;
  }
  return projectRow.data?.every((r) => selected.includes(r)) ?? false;
};

const partialWorkflowsSelected = (
  selected: Row[],
  projectRow: Row
): boolean => {
  return projectRow.data?.some((r) => selected.includes(r)) ?? false;
};

const noWorkflowsSelected = (
  selected: Row[],
  projectRow: Row | undefined
): boolean => {
  if (!projectRow) {
    return false;
  }
  return projectRow.data?.every((r) => !selected.includes(r)) ?? false;
};

export const ScheduleEditor: React.FC<ScheduleEditorProps> = ({
  schedule,
  outputs,
  dispatch,
  onChangeOutputs,
  executeEnabled,
  onExecuteFollow
}) => {
  const classes = useStyles();
  const toolbarClasses = toolbarStyles();
  // 閉じているリレーションのindex
  const [collapsed, setCollapsed] = React.useState<number[]>([]);
  const [rows, stepIndexCache, workflowIndexCache] = React.useMemo(() => {
    const rows = scheduleToRows(schedule, outputs);
    const [stepCache, workflowCache] = maxStepIndexPerLevel(schedule);
    return [expandRows(rows, collapsed), stepCache, workflowCache];
  }, [schedule, outputs, collapsed]);
  const toggle = React.useCallback(
    (row) => {
      let newCollapsed;
      if (collapsed.indexOf(row.relIndex) !== -1) {
        // 開く
        newCollapsed = collapsed.filter((index) => index !== row.relIndex);
      } else {
        newCollapsed = [row.relIndex].concat(collapsed);
      }
      setCollapsed(newCollapsed);
    },
    [collapsed]
  );
  const gridRef = React.useRef<MultiGrid | null>(null);
  const [hoveredIndex, setHoveredIndex] = React.useState<number>(-1);
  const [openDialog, setOpenDialog] = React.useState(schedule.uuid === '0');
  const [checkedItems, setCheckedItems] = React.useState<Row[]>([]);
  const selectErrors = React.useCallback(() => {
    setCheckedItems(selectByStatus(rows, ConfigStatus.Error));
  }, [rows]);
  const selectWarnings = React.useCallback(() => {
    setCheckedItems(selectByStatus(rows, ConfigStatus.Warning));
  }, [rows]);

  const rowCount = rows.length;
  const columnCount = countColumns(rows);
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.recomputeGridSize();
    }
  }, [columnCount]);
  const [editContext, setEditContext] = React.useState<{
    type: string;
    id: string;
    workflowProjectId: string;
    selected: string[];
    index: number;
    highlight: string;
  } | null>(() => {
    const q = new URLSearchParams(location.search);
    const type = q.get('type');
    const id = q.get('id');
    if (type && id) {
      return {
        type: type,
        id: id,
        workflowProjectId: '',
        selected: [],
        index: -1,
        highlight: ''
      };
    }
    return null;
  });

  const cellRenderer: GridCellRenderer = React.useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      // 左上:空白
      if (columnIndex === 0 && rowIndex === 0) {
        return (
          <div
            key={key}
            style={style}
            className={clsx(classes.leftColumn, classes.headerLeftTop)}
          >
            &nbsp;
          </div>
        );
      }

      // 右上:実行順番
      if (rowIndex === 0) {
        return <IndexHeader count={columnCount} style={style} key={key} />;
      }

      // 左下:リレーション一覧
      const row = rows[rowIndex - 1];
      if (columnIndex === 0) {
        return (
          <LeftCell
            key={`left-cell-${rowIndex}`}
            dispatch={dispatch}
            style={style}
            row={row}
            toggleOpen={toggle}
            isOpen={collapsed.indexOf(row.relIndex) === -1}
            onHover={(hover) => setHoveredIndex(hover ? rowIndex - 1 : -1)}
            hover={hoveredIndex === rowIndex - 1}
            indeterminate={
              row.type === 'project' &&
              partialWorkflowsSelected(checkedItems, row) &&
              !allWorkflowsSelected(checkedItems, row)
            }
            checked={checkedItems.includes(row)}
            showActions={checkedItems.length === 0}
            onClickReset={async (index) => {
              const { data } = await resetScheduleRelationship(
                schedule.relationships[index]
              );
              dispatch({
                type: 'update_relationship',
                index: index,
                relationship: data
              });
            }}
            onCheckChanged={(ev, checked) => {
              ev.stopPropagation();
              if (checked) {
                if (row.type === 'project') {
                  // プロジェクトの場合は、そのプロジェクトとそのワークフローを全て選択する
                  setCheckedItems(
                    uniq([...checkedItems, row, ...(row.data ?? [])])
                  );
                } else if (row.type === 'workflow') {
                  // すべてのワークフローが選択されている場合、そのプロジェクトも選択する
                  const newSelected = [...checkedItems, row];
                  const projectRow = findProjectByWorkflowRow(rows, row);
                  if (allWorkflowsSelected(newSelected, projectRow)) {
                    setCheckedItems(uniq([...newSelected, projectRow]));
                  } else {
                    setCheckedItems(newSelected);
                  }
                } else {
                  setCheckedItems([...checkedItems, row]);
                }
              } else {
                if (row.type === 'project') {
                  // プロジェクトの場合は、そのプロジェクトとそのワークフローを全て選択解除する
                  setCheckedItems(
                    checkedItems.filter(
                      (r) => r !== row && !row.data?.includes(r)
                    )
                  );
                } else if (row.type === 'workflow') {
                  // すべてのワークフローが選択解除されている場合、そのプロジェクトも選択解除する
                  let newSelected = checkedItems.filter((r) => r !== row);
                  const projectRow = findProjectByWorkflowRow(rows, row);
                  // プロジェクトも選択解除する
                  newSelected = newSelected.filter((r) => r !== projectRow);
                  if (noWorkflowsSelected(newSelected, projectRow)) {
                    newSelected = newSelected.filter((r) => r !== projectRow);
                  }
                  setCheckedItems(newSelected);
                } else {
                  setCheckedItems(checkedItems.filter((r) => r !== row));
                }
              }
            }}
            onClickEdit={(
              type,
              id,
              workflowProjectId,
              workflowId,
              highlight,
              row
            ) => {
              setEditContext({
                type,
                id,
                workflowProjectId,
                highlight:
                  workflowProjectId !== ''
                    ? workflowId
                    : toNodeId(row.item_type, highlight),
                selected: getSelected(schedule, row.relIndex),
                index: row.relIndex
              });
              setOpenDialog(true);
            }}
            onClickRemove={(row) => dispatch({ type: 'delete', row })}
          />
        );
      }

      // 左右移動が有効かどうか
      let moveLeftEnabled: boolean;
      let moveRightEnabled: boolean;
      if (row.item_type === 'workflow') {
        const projectId = (row.step as ScheduleStepWorkflow).project_id;
        // @ts-ignore
        const indexCache = workflowIndexCache[projectId]?.[row.step?.level];
        moveLeftEnabled = isMovableLeft(indexCache, row);
        moveRightEnabled = isMovableRight(indexCache, row);
      } else {
        // step_indexがLevel内の最大かつ一つしかないならなら、右移動できない
        // @ts-ignore
        const indexCache = stepIndexCache[row.relIndex]?.[row.step?.level];
        moveLeftEnabled = isMovableLeft(indexCache, row);
        moveRightEnabled = isMovableRight(indexCache, row);
      }

      // 右下:実行
      return (
        <RightCell
          key={`right-cell-${rowIndex}`}
          style={style}
          row={row}
          columnCount={columnCount}
          scheduleDispatch={dispatch}
          onHover={(hover) => setHoveredIndex(hover ? rowIndex - 1 : -1)}
          hover={hoveredIndex === rowIndex - 1}
          executeEnabled={executeEnabled}
          onClickFollow={onExecuteFollow}
          moveLeftEnabled={moveLeftEnabled}
          moveRightEnabled={moveRightEnabled}
        />
      );
    },
    [
      classes,
      schedule,
      dispatch,
      collapsed,
      columnCount,
      rows,
      toggle,
      hoveredIndex,
      checkedItems
    ]
  );

  let dialogStep: 0 | 1 | 2 | 3 = 0;
  if (editContext) {
    switch (editContext.type) {
      case 'manual':
        dialogStep = 1;
        break;
      default:
        dialogStep = 2;
        break;
    }

    if (editContext.workflowProjectId != '') {
      dialogStep = 3;
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {checkedItems.length > 0 ? (
          <Toolbar className={toolbarClasses.toolbar}>
            <FormControlLabel
              classes={{ label: toolbarClasses.label }}
              control={
                <Checkbox checkedIcon={<CheckCircle />} checked={true} />
              }
              label="選択解除"
              onChange={() => setCheckedItems([])}
            />
            <Spacer />
            <FormControlLabel
              classes={{ label: toolbarClasses.label }}
              control={<Checkbox checkedIcon={<Error />} checked={true} />}
              label="エラー全選択"
              onChange={selectErrors}
            />
            <FormControlLabel
              classes={{ label: toolbarClasses.label }}
              control={<Checkbox checkedIcon={<Warning />} checked={true} />}
              label="警告全選択"
              onChange={selectWarnings}
            />
            <Divider
              orientation="vertical"
              variant="middle"
              style={{ height: 48 }}
            />
            <Tooltip title="削除">
              <IconButton
                onClick={() => {
                  dispatch({ type: 'delete_multi', rows: checkedItems });
                  setCheckedItems([]);
                }}
              >
                <DeleteOutlined />
              </IconButton>
            </Tooltip>
          </Toolbar>
        ) : (
          <>
            <div className={clsx(classes.headerLeft, classes.leftColumn)}>
              実行対象
            </div>
            <div className={classes.headerRight}>実行順番</div>
          </>
        )}
      </div>
      <div className={classes.content}>
        <div className={classes.scheduleGrid}>
          <AutoSizer>
            {({ width, height }) => (
              <MultiGrid
                ref={(ref) => (gridRef.current = ref)}
                cellRenderer={cellRenderer}
                columnWidth={({ index }) =>
                  index === 0
                    ? LEFT_COLUMN_WIDTH
                    : COLUMN_WIDTH * (columnCount + 1)
                }
                enableFixedColumnScroll={true}
                columnCount={2}
                fixedColumnCount={1}
                rowHeight={ROW_HEIGHT}
                rowCount={rowCount + 1}
                fixedRowCount={1}
                height={height}
                width={width}
                hideTopRightGridScrollbar={true}
                hideBottomLeftGridScrollbar={true}
                styleTopRightGrid={{
                  borderBottom: '2px solid grey'
                }}
              />
            )}
          </AutoSizer>
        </div>
        <div className={classes.footer}>
          <Button
            color="common_color"
            startIcon={<AddCircleOutline />}
            onClick={() => setOpenDialog(true)}
          >
            ステップを追加する
          </Button>
        </div>
      </div>
      {openDialog && (
        <SelectDialog
          open={openDialog}
          closeText="登録"
          initialStep={dialogStep}
          initialType={editContext?.type}
          initialId={editContext?.id}
          initialSelected={editContext?.selected}
          initialWorkflowProjectId={editContext?.workflowProjectId}
          initialHighlight={editContext?.highlight}
          onClose={async (type, id, ids) => {
            if (type && id) {
              let data;
              if (type.startsWith('manual') && Array.isArray(id)) {
                const resp = await getManualSchedule(
                  type,
                  id,
                  schedule,
                  editContext?.index ?? -1
                );
                data = resp.data;
              } else if (typeof id === 'string') {
                const resp = await getTemporarySchedule(
                  type,
                  id,
                  ids,
                  schedule,
                  editContext?.index ?? -1
                );
                data = resp.data;
              }
              if (data) {
                dispatch({
                  type: 'update',
                  schedule: data.schedule
                });
                onChangeOutputs(data.outputs);
              }
              setOpenDialog(false);
              setEditContext(null);
            } else {
              setOpenDialog(false);
              setEditContext(null);
            }
          }}
        />
      )}
    </div>
  );
};

function toNodeId(type: string, id: string): string {
  if (id === '') {
    return '';
  }
  switch (type) {
    case 'project':
    case 'workflow':
      return id;

    case 'nehan_internal':
      return `datasource_${id}`;

    default:
      return `${type}_${id}`;
  }
}

function createLink(row: Row): string | undefined {
  if (row.type === 'report') {
    return `/reports/${row.item_id}`;
  }
  if (row.type === 'nehan_internal') {
    return `/datasources/${row.item_id}`;
  }
  if (row.type === 'export') {
    return `/projects/${row.project_id}/nodes/${row.node_id}`;
  }
  if (row.step == undefined) {
    return undefined;
  }
  if (row.step.item_type === 'datasource') {
    return dsCreateLink(row.step.datasource_type, row.step.item_id);
  } else if (row.step.item_type === 'project') {
    return `/projects/${row.step.item_id}`;
  } else if (row.step.item_type === 'workflow') {
    return `/projects/${row.step.project_id}?select_workflow=${row.step.workflow_id}`;
  }
  return undefined;
}
