import { Parameter, TimeParameter } from 'models/report';
import { useMemo } from 'react';
import { DateParameterClassFactory, ParameterClassFactory } from './factory';
import { ParameterClass, TimeParameterClass } from './base';

export const useDateInstance = (param: TimeParameter): TimeParameterClass => {
  return useMemo(() => {
    const factory = new DateParameterClassFactory();
    const dateInstance = factory.createInstance(param);
    return dateInstance;
  }, [param]);
};

export const useParamInstance = (param: Parameter): ParameterClass => {
  return useMemo(() => {
    const factory = new ParameterClassFactory();
    const dateInstance = factory.createInstance(param);
    return dateInstance;
  }, [param]);
};
