import { SingleStringParameter } from 'models/report';
import { stringOperatorToString } from '../toString';
import { ParameterClass } from './base';
import { StringOptions } from '../useStringOptions';

export class StringSingleClass implements ParameterClass {
  constructor(
    public param: SingleStringParameter,
    public stringOptions?: StringOptions
  ) {}
  isValid = () => {
    return this.param.value != undefined;
  };
  toDisplayString = () => {
    return `${this.param.value} ${stringOperatorToString(this.param.operator)}`;
  };
  toMinimizedDisplayString = () => {
    return this.toDisplayString();
  };
}
