import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  getColorName,
  getColors,
  isSequentialType,
  paletteColorList,
  Palette as PaletteModel,
  isSequentialPalette
} from 'components/visualize/chart/color';
import {
  Button,
  Tabs,
  Tab,
  Paper,
  Switch,
  FormControlLabel,
  Popover
} from '@material-ui/core';
import { NumberInput } from 'components/report/parameter/number';
import { Dialog } from 'components/ui/common/dialog';

const useStyles = makeStyles({
  container: {
    position: 'relative'
  },
  root: {
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    borderRadius: 4
  },
  palette: {
    display: 'flex',
    flexWrap: 'wrap',
    maxHeight: 300,
    overflowY: 'scroll'
  },
  paletteView: {
    display: 'flex',
    flexGrow: 1,
    height: 400
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    margin: 8,
    height: 60
  },
  reflect: {
    display: 'flex',
    justifyContent: 'center'
  },
  square: {
    width: 32,
    height: 32
  },
  action: {
    padding: 10,
    display: 'flex',
    justifyContent: 'space-around'
  },
  swatch: {
    padding: '5px',
    background: '#fff',
    borderRadius: '1px',
    boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
    display: 'inline-block',
    cursor: 'pointer'
  }
});

const ColorSquare: React.FC<{ color: string }> = ({ color }) => {
  const classes = useStyles();
  return (
    <div
      style={{
        display: 'inline-block',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        padding: 3,
        borderRadius: 2
      }}
    >
      <div className={classes.square} style={{ backgroundColor: color }} />
    </div>
  );
};

export const Palette: React.FC<{
  palette: PaletteModel;
  onClickColor?: (color: string) => void;
}> = (props) => {
  const colors = React.useMemo(() => {
    return getColors(props.palette);
  }, [props]);

  const onClickColor = React.useCallback(
    (color: string) => (_: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (props.onClickColor) {
        props.onClickColor(color);
      }
    },
    [props.onClickColor]
  );

  const classes = useStyles();
  return (
    <div className={classes.palette}>
      {colors.map((color, i) => {
        return (
          <div
            key={i}
            style={{
              margin: 5,
              cursor: props.onClickColor ? 'pointer' : 'initial'
            }}
            onClick={onClickColor(color)}
          >
            <ColorSquare color={color} />
          </div>
        );
      })}
    </div>
  );
};

export const PaletteSelector: React.FC<{
  palette: PaletteModel;
  onChange(value: PaletteModel);
}> = ({ palette, onChange }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [state, setState] = React.useState<PaletteModel>(palette);
  const anchorRef = React.useRef<HTMLButtonElement | null>(null);

  React.useEffect(() => {
    setState(palette);
  }, [palette]);

  const setOpenPalette = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const [tabIndex, setTabIndex] = React.useState<number>(
    paletteColorList.findIndex((color) => color === palette.type)
  );

  const closePalette = React.useCallback(() => {
    setOpen(false);
    setState(palette);
    setTabIndex(paletteColorList.findIndex((color) => color === palette.type));
  }, [palette]);

  const onChangeTabIndex = React.useCallback((_, index) => {
    const newType = paletteColorList[index];
    setState((prev: PaletteModel) => {
      if (isSequentialType(newType)) {
        return {
          type: newType,
          reverse: prev.reverse,
          size: 10
        };
      }
      return { type: newType, reverse: prev.reverse };
    });
    setTabIndex(index);
  }, []);

  const onChangeSize = React.useCallback((size: string) => {
    const numberSize = Number(size);
    setState((prev) => {
      if (!isSequentialPalette(prev)) {
        return prev;
      }
      if (numberSize > 0 && numberSize <= 100) {
        return { ...prev, size: numberSize };
      }
      if (isNaN(numberSize)) {
        return { ...prev, size: 0 };
      }
      return prev;
    });
  }, []);

  const onChangePalette = React.useCallback(() => {
    const newState = { ...state };
    if (isSequentialPalette(newState) && newState.size === 0) {
      newState.size = 1;
      setState(newState);
    }
    onChange(newState);
    setOpen(false);
  }, [onChange, state]);

  const onChangeReverse = React.useCallback((_, checked) => {
    setState((prev) => ({
      ...prev,
      reverse: checked
    }));
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Button variant="outlined" onClick={setOpenPalette} ref={anchorRef}>
        {getColorName(state.type)}
      </Button>
      <Dialog
        title="カラーパレットの選択"
        open={open}
        onClose={closePalette}
        OKButton={{
          label: '決定',
          onClick: onChangePalette
        }}
      >
        <div className={classes.paletteView}>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={tabIndex}
            onChange={onChangeTabIndex}
          >
            {paletteColorList.map((color, i) => (
              <Tab key={i} label={getColorName(color)} />
            ))}
          </Tabs>
          <div style={{ width: 270, marginLeft: 'auto', marginRight: 'auto' }}>
            <div className={classes.content}>
              <FormControlLabel
                control={
                  <Switch
                    checked={state.reverse}
                    onChange={onChangeReverse}
                    name="reverse"
                    color="primary"
                  />
                }
                label="逆順"
              />
              {isSequentialPalette(state) && (
                <NumberInput
                  label={'個数'}
                  onChange={onChangeSize}
                  value={state.size || undefined}
                />
              )}
            </div>
            <Palette palette={state} />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export const PaletteColorSelector: React.FC<{
  palette: PaletteModel;
  color: string;
  onChange: (color: string) => void;
}> = ({ palette, color, onChange }) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const anchorRef = React.useRef<HTMLDivElement | null>(null);

  const setOpenPalette = React.useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  const closePalette = React.useCallback(() => {
    setOpen(false);
  }, [palette]);

  const onClickColor = React.useCallback(
    (color: string) => {
      onChange(color);
      setOpen(false);
    },
    [onChange]
  );

  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div ref={anchorRef} className={classes.swatch} onClick={setOpenPalette}>
        <div style={{ backgroundColor: color, width: 32, height: 32 }} />
      </div>
      <Popover
        open={open}
        anchorEl={anchorRef.current}
        onClose={closePalette}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Paper className={classes.root}>
          <div style={{ width: 270, marginLeft: 'auto', marginRight: 'auto' }}>
            <Palette palette={palette} onClickColor={onClickColor} />
          </div>
        </Paper>
      </Popover>
    </div>
  );
};
