import * as React from 'react';
import {
  Dtypes //, LabelToDtype
} from 'Utils/dataTypes';
import {
  SwitchAndCondition,
  SwitchAndConditions,
  SwitchCondition,
  SwitchSelectColumn,
  SwitchOperators,
  FormValue
} from 'models/form/value';
import { produce } from 'immer';
import { ColumnSelectValueSetter } from 'components/form/switchFieldComponent/columnSelectValueSetter';
//import { FieldValidationError } from 'models/form/validate';
import { SelectOperator } from 'components/form/switchFieldComponent/selectOperator';
import {
  FieldConditionRuleStyles,
  OneOfArrayRuleStyles
} from 'components/form/switchFieldComponent/switchStyles';
import clsx from 'clsx';
import { isNotUseColumnCondition } from 'components/form/switchFieldComponent/utils';
import { UseColumnCondition } from 'components/form/switchFieldComponent/useColumnCondition';
import TextField from 'components/form/switchFieldComponent/textField';
import { SwitchFieldSchema } from 'models/form/schema';
import { Variable } from 'models/project';
import { CategorySelect } from 'components/form/switchFieldComponent/categorySelect';
import { DataMap } from 'models/graph';
import { DataSummary } from 'models/data';

export const StringField: React.FC<{
  index: number;
  columns: string[];
  dtypes: Dtypes[];
  dtype: Dtypes | null;
  values: SwitchAndCondition;
  andConditions: SwitchAndConditions;
  conditionOnChange: (val: SwitchCondition) => void;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  //errors: FieldValidationError;
  schema: SwitchFieldSchema;
  variables: Variable[];
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}> = ({
  index,
  columns,
  dtypes,
  dtype,
  values,
  andConditions,
  conditionOnChange,
  returnUseColumn,
  returnValue,
  returnColname,
  //errors,
  schema,
  variables,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = OneOfArrayRuleStyles();
  return (
    <>
      <div className={classes.columnSelect}>
        <ColumnSelectValueSetter
          columns={columns}
          dtypes={dtypes}
          value={values.searchedColnames}
          onChange={(newVal) => {
            const newState = produce(andConditions, (draft) => {
              const dtype = dtypes[columns.indexOf(newVal)];
              draft[index].searchedColnames =
                newVal == null
                  ? null
                  : {
                      label: newVal,
                      value: newVal,
                      dtype: dtype
                    };
              if (![Dtypes.DATE, Dtypes.TIMESTAMP].includes(dtype)) {
                draft[index].datetimeType = null;
              }
            });
            conditionOnChange({
              andConditions: newState,
              returnUseColumn: returnUseColumn,
              returnValue: returnValue,
              returnColname: returnColname
            });
          }}
          placeholder="対象列を選択"
          index={index}
          //errors={errors}
        />
      </div>
      <div className={classes.operatorSelect}>
        <SelectOperator
          value={values.operator == null ? null : values.operator}
          element={values.datetimeType}
          dtype={dtype}
          onChange={(newVal, operators) => {
            const select_operator = operators.filter(
              (v) => v.value === newVal
            )[0];
            const isDisableUseColumn =
              select_operator.disableUseColumn == undefined
                ? false
                : select_operator.disableUseColumn;
            const isDisableBetweenUseColumn =
              select_operator.disableBetweenUseColumn == undefined
                ? false
                : select_operator.disableBetweenUseColumn;
            const newState = produce(andConditions, (draft) => {
              draft[index].operator = newVal;
              if (isDisableUseColumn) {
                draft[index].valueUseColumn = null;
                draft[index].filterColnames = null;
                draft[index].weekdayType = null;
              }
              if (isDisableBetweenUseColumn) {
                draft[index].endFilterColnames = null;
                draft[index].startFilterColnames = null;
                draft[index].valueEndUseColumn = null;
                draft[index].valueStartUseColumn = null;
              }
            });
            conditionOnChange({
              andConditions: newState,
              returnUseColumn: returnUseColumn,
              returnValue: returnValue,
              returnColname: returnColname
            });
          }}
          placeholder="比較タイプを選択"
          index={index}
        />
      </div>
      <StringConditionValue
        variables={variables}
        value={values}
        index={index}
        conditions={andConditions}
        schema={schema}
        conditionOnChange={conditionOnChange}
        //errors={errors}
        returnUseColumn={returnUseColumn}
        returnValue={returnValue}
        returnColname={returnColname}
        columns={columns}
        dtypes={dtypes}
        contextData={contextData}
        inputDataMap={inputDataMap}
        portSummaries={portSummaries}
      />
    </>
  );
};

const StringConditionValue: React.FC<{
  variables: Variable[];
  value: SwitchAndCondition;
  index: number;
  conditions: SwitchAndConditions;
  schema: SwitchFieldSchema;
  conditionOnChange: (val: SwitchCondition) => void;
  //errors: FieldValidationError;
  returnUseColumn: boolean | null;
  returnValue: string | null;
  returnColname: SwitchSelectColumn | null;
  columns: string[];
  dtypes: Dtypes[];
  contextData: FormValue | undefined;
  inputDataMap: DataMap;
  portSummaries: {
    [portId: string]: DataSummary;
  };
}> = ({
  variables,
  value,
  index,
  conditions,
  schema,
  conditionOnChange,
  //errors,
  returnUseColumn,
  returnValue,
  returnColname,
  columns,
  dtypes,
  contextData,
  inputDataMap,
  portSummaries
}) => {
  const classes = FieldConditionRuleStyles();
  const searchedColnames = value.searchedColnames;
  const col = searchedColnames == null ? '' : searchedColnames.label;
  switch (value.operator) {
    case null:
      return null;
    case SwitchOperators.extract_na:
    case SwitchOperators.drop_na:
      return null;
    case SwitchOperators.in:
    case SwitchOperators.not_in: {
      return (
        <div className={classes.selectBoxWithoutUseCol}>
          <CategorySelect
            variables={variables}
            value={value.value}
            selectColumn={value.searchedColnames}
            onChange={(newVal) => {
              const newState = produce(conditions, (draft) => {
                draft[index].value = newVal;
              });
              conditionOnChange({
                andConditions: newState,
                returnUseColumn: returnUseColumn,
                returnValue: returnValue,
                returnColname: returnColname
              });
            }}
            errors={[]}
            placeholder={'条件値を設定(複数可)'}
            index={index}
            multiple={true}
            contextData={contextData}
            inputDataMap={inputDataMap}
            portSummaries={portSummaries}
          />
        </div>
      );
    }
    case SwitchOperators.eq:
    case SwitchOperators.notEq: {
      return (
        <div
          className={clsx(classes.selectBox, {
            [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
              value.operator
            )
          })}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <UseColumnCondition
              operator={value.operator == null ? null : value.operator}
              check={value.valueUseColumn}
              datetimeType={value.datetimeType}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].valueUseColumn = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              isReturnValue={false}
              label="既存列を設定する"
            />
          </div>
          {value.valueUseColumn === true && (
            <ColumnSelectValueSetter
              columns={columns}
              dtypes={dtypes}
              value={value.filterColnames}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].filterColnames =
                    newVal == null
                      ? null
                      : {
                          label: newVal,
                          value: newVal,
                          dtype: dtypes[columns.indexOf(newVal)]
                        };
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              placeholder="列を選択"
              index={index}
              //errors={errors}
              validate_dtype={['string']}
              excludeValues={[col]}
            />
          )}
          {value.valueUseColumn !== true && (
            <CategorySelect
              variables={variables}
              value={value.value}
              selectColumn={value.searchedColnames}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].value = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              errors={[]}
              placeholder="条件値を設定"
              index={index}
              multiple={false}
              contextData={contextData}
              inputDataMap={inputDataMap}
              portSummaries={portSummaries}
            />
          )}
        </div>
      );
    }
    case SwitchOperators.grep:
    case SwitchOperators.ungrep:
    case SwitchOperators.ends_with:
    case SwitchOperators.starts_with: {
      return (
        <div
          className={clsx(classes.selectBox, {
            [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
              value.operator
            )
          })}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <UseColumnCondition
              operator={value.operator == null ? null : value.operator}
              check={value.valueUseColumn}
              datetimeType={value.datetimeType}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].valueUseColumn = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              isReturnValue={false}
              label="既存列を設定する"
            />
          </div>
          {value.valueUseColumn === true && (
            <ColumnSelectValueSetter
              columns={columns}
              dtypes={dtypes}
              value={value.filterColnames}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].filterColnames =
                    newVal == null
                      ? null
                      : {
                          label: newVal,
                          value: newVal,
                          dtype: dtypes[columns.indexOf(newVal)]
                        };
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              placeholder={'列を選択'}
              index={index}
              //errors={errors}
              validate_dtype={['string']}
              excludeValues={[col]}
            />
          )}
          {value.valueUseColumn !== true && (
            <TextField
              variables={variables}
              value={value.value}
              schema={schema}
              onChangeField={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].value = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              errors={[]}
              placeholder={'条件値を設定(複数可)'}
            />
          )}
        </div>
      );
    }
    default:
      return (
        <div
          className={clsx(classes.selectBox, {
            [classes.selectBoxWithoutUseCol]: isNotUseColumnCondition(
              value.operator
            )
          })}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <UseColumnCondition
              operator={value.operator == null ? null : value.operator}
              check={value.valueUseColumn}
              datetimeType={value.datetimeType}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].valueUseColumn = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              isReturnValue={false}
              label="既存列を設定する"
            />
          </div>
          {value.valueUseColumn === true && (
            <ColumnSelectValueSetter
              columns={columns}
              dtypes={dtypes}
              value={value.filterColnames}
              onChange={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].filterColnames =
                    newVal == null
                      ? null
                      : {
                          label: newVal,
                          value: newVal,
                          dtype: dtypes[columns.indexOf(newVal)]
                        };
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              placeholder="列を選択"
              index={index}
              //errors={errors}
              validate_dtype={['string']}
              excludeValues={[col]}
            />
          )}
          {value.valueUseColumn !== true && (
            <TextField
              variables={variables}
              value={value.value}
              schema={schema}
              onChangeField={(newVal) => {
                const newState = produce(conditions, (draft) => {
                  draft[index].value = newVal;
                });
                conditionOnChange({
                  andConditions: newState,
                  returnUseColumn: returnUseColumn,
                  returnValue: returnValue,
                  returnColname: returnColname
                });
              }}
              errors={[]}
              placeholder="条件値を設定"
            />
          )}
        </div>
      );
  }
};
