import * as React from 'react';

import SelectField from './selectField';
import { Dtypes } from 'Utils/dataTypes';
import { produce } from 'immer';
import { DataSummary } from 'models/data';
import { ColumnSelectFieldValueElement } from 'models/form/value';
import {
  ColumnSelectFieldSchema,
  FieldTypes,
  SelectFieldSchema
} from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';

interface ColumnSelectFieldProps {
  value: ColumnSelectFieldValueElement[] | ColumnSelectFieldValueElement;
  columns: DataSummary['columns'];
  allDtypes: DataSummary['dtypes'][];
  excludeValues: string[];
  schema: ColumnSelectFieldSchema;
  errors: FieldValidationError;
  onChangeField: ChangeFieldHandler;
  detailedHelpTooltip: React.ReactNode;
}

const columnSelectField: React.FunctionComponent<ColumnSelectFieldProps> = ({
  columns,
  allDtypes,
  excludeValues,
  schema,
  errors,
  detailedHelpTooltip,
  ...props
}) => {
  // 表示するカラムのDtypeは、親データ(複数ある場合は左側)のものを表示する
  // もし、dtypesに含まれていないカラムだった場合、エラー表示にする
  const columnMap: { [c: string]: Dtypes } = Object.fromEntries<Dtypes>(
    columns.map((c, i) => [c, allDtypes[0][i]])
  );
  const newValue = produce(props.value, (draft) => {
    if (draft == undefined) {
      return;
    }

    if (Array.isArray(draft)) {
      // multi select
      for (let i = 0; i < draft.length; i++) {
        if (
          columnMap[draft[i].value] != undefined &&
          draft[i].dtype !== columnMap[draft[i].value]
        ) {
          draft[i].dtype = columnMap[draft[i].value];
        }
        // 型エラー検査
        if (
          schema.dtypes != undefined &&
          !schema.dtypes.includes(draft[i].dtype)
        ) {
          draft[i].isError = true;
        }
        // カラムない検査
        if (!columns.includes(draft[i].value)) {
          draft[i].isError = true;
        }
      }
    } else {
      if (
        columnMap[draft.value] != undefined &&
        draft.dtype !== columnMap[draft.value]
      ) {
        draft.dtype = columnMap[draft.value];
      }
      // 型エラー検査
      if (schema.dtypes != undefined && !schema.dtypes.includes(draft.dtype)) {
        draft.isError = true;
      }
      // カラムない検査
      if (!columns.includes(draft.value)) {
        draft.isError = true;
      }
    }
  });

  let items = columns.map((c, i) => ({
    label: c,
    value: c,
    dtype: allDtypes[0][i]
  }));
  const filterDtypes = schema.dtypes;
  if (filterDtypes != undefined) {
    items = items.filter((v) => filterDtypes.includes(v.dtype));
  }
  items = items.filter((v) => !excludeValues.includes(v.label.toString()));
  const selectSchema: SelectFieldSchema = {
    key: schema.key,
    type: FieldTypes.select,
    title: schema.title,
    defaultValue: schema.defaultValue,
    items,
    allDtypes,
    multi: schema.multi,
    validate: schema.validate,
    placeholder: schema.placeholder,
    fullWidth: schema.fullWidth,
    variant: schema.variant,
    backgroundColor: schema.backgroundColor,
    errors
  };

  return (
    <SelectField
      clearable={true}
      schema={selectSchema}
      simpleValue={false}
      errors={errors}
      {...props}
      value={newValue}
      detailedHelpTooltip={detailedHelpTooltip}
    />
  );
};

export default columnSelectField;
