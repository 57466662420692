import * as React from 'react';
import {
  DialogActions as MuiDialogActions,
  DialogActionsProps as MuiDialogActionsProps
} from '@material-ui/core';
import { ButtonProps, Button as NehanButton } from 'ui/common/button';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  root: {
    justifyContent: 'center'
  }
});

export type DialogActionsButtonProps =
  // | JSX.Element
  ButtonProps & {
    label: string;
  };

export type DialogActionsProps = MuiDialogActionsProps & {
  secondaryOKButton?: DialogActionsButtonProps;
};

export const DialogActions: React.FC<DialogActionsProps> = ({
  children,
  secondaryOKButton,
  ...props
}) => {
  const classes = styles();

  const renderSecondaryOKButton = React.useCallback(() => {
    if (!secondaryOKButton) {
      return <></>;
    }
    if (React.isValidElement(secondaryOKButton)) {
      return { secondaryOKButton };
    }

    if (typeof secondaryOKButton === 'object' && 'label' in secondaryOKButton) {
      return (
        <NehanButton
          variant="contained"
          color="common_color"
          {...secondaryOKButton}
        >
          {secondaryOKButton.label}
        </NehanButton>
      );
    }
  }, [secondaryOKButton]);

  return (
    <MuiDialogActions className={classes.root} {...props}>
      {children}
      {renderSecondaryOKButton()}
    </MuiDialogActions>
  );
};
