import * as React from 'react';
import { User } from 'models/user';

export const Avatar = ({
  user,
  label,
  width,
  height
}: {
  user?: Pick<User, 'last_name' | 'email'> | null;
  label?: string;
  width?: number;
  height?: number;
}) => {
  const defaultLabel = user?.last_name || user?.email;
  const showLabel = label ? label : defaultLabel;
  const encodedName = encodeURIComponent(showLabel || 'nehan');
  return (
    <img
      width={width ? width : 32}
      height={height ? height : 32}
      alt={showLabel}
      src={`https://ui-avatars.com/api/?background=random&bold=true&rounded=true&format=png&name=${encodedName}`}
    />
  );
};
