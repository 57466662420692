import { _delete, _get, _post, _put } from 'libs/api/base';
import { ConnectionSchema } from 'models/connection';
import { Graph } from 'models/graph';
import { Variable } from 'models/project';
import { QueryParams } from 'ui/listViewBase';
import { healthConnection } from 'libs/api/connection';

export function getBuilders(folderId?: string, queryParams?: QueryParams) {
  return _get('/builders', { params: { folderId, ...queryParams } });
}

export function addBuilders(body: {
  folder_id: string | null;
  name: string;
  organization_id?: number;
  connection_id?: string;
  connection?: ConnectionSchema;
  form_params?: { [key: string]: string };
}) {
  return _post('/builders', body);
}

export function getBuilder(projectId: string) {
  return _get(`/builders/${projectId}`);
}

export function updateBuilder(
  projectId: string,
  body: {
    name?: string;
    currentWorkflowIndex?: number;
    connection_id?: string;
    connection?: ConnectionSchema;
    variables?: Variable[];
    form_params?: { [key: string]: string };
  }
) {
  return _put(`/builders/${projectId}`, body);
}

export function deleteBuilder(projectId: string) {
  return _delete(`/builders/${projectId}`);
}

// TODO workflowの型ちゃんと書く
export function addBuilderWorkflow(projectId: string) {
  return _post(`/builders/${projectId}/workflows`, null);
}

export function updateBuilderWorkflow(
  projectId: string,
  workflowId: string,
  body: { name?: string; graph?: Graph }
) {
  return _put(`/builders/${projectId}/workflows/${workflowId}`, body);
}

export function deleteBuilderWorkflow(projectId: string, workflowId: string) {
  return _delete(`/builders/${projectId}/workflows/${workflowId}`);
}

export function moveBuilderWorkflow(
  projectId: string,
  workflowId: string,
  targetOrderNumber: number
) {
  return _put(`/builders/${projectId}/workflows/${workflowId}/move`, {
    targetOrderNumber
  });
}

export function duplicateBuilderWorkflow(
  projectId: string,
  workflowId: string,
  target_project: string
) {
  return _post(`/builders/${projectId}/workflows/${workflowId}/duplicate`, {
    target_project
  });
}

export function deleteBuilderNode(
  projectId: string,
  workflowId: string,
  nodeIds: string[]
) {
  return _post(`/builders/${projectId}/workflows/${workflowId}/delete_nodes`, {
    node_ids: nodeIds
  });
}

export function listDatabases(projectId: string) {
  return _get(`/builders/${projectId}/databases`);
}

export function listTables(projectId: string, name: string) {
  return _post(`/builders/${projectId}/tables`, { name });
}

export function getBuilderWayPint(projectId: string, id: number) {
  return _get(`/builders/${projectId}/waypoint/${id}`);
}

export function interruptBuilder(projectId: string, workflowId: string) {
  return _post(
    `/builders/${projectId}/workflows/${workflowId}/interrupt`,
    undefined
  );
}

export function getBuilderColumns(projectId: string, portIds: string[]) {
  return _post(`/builders/${projectId}/columns`, { portIds });
}

export function getBuilderMembers(id: string) {
  return _get(`/builders/${id}/members`);
}

export function deleteBuilderMember(
  bid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/builders/${bid}/members`, { id, is_group });
}

export async function getBuilderDatasources(
  uuid: string,
  is_waypoint: boolean
) {
  return _get(`/builders/${uuid}/datasources`, { params: { is_waypoint } });
}

export function duplicateBuilder(projectId: string, folderId?: string) {
  return _post(`/builders/${projectId}/duplicates`, undefined, {
    params: { folderId }
  });
}

export async function getAdminBuilders(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get('/admin/builders', {
    params: { folderId, ...queryParams }
  });
}

export async function getBuilderRelationships(id: string) {
  return _get(`/builders/${id}/relationships`);
}

export const healthCheck = async (
  connection: ConnectionSchema
): Promise<string | null> => {
  try {
    const resp = await healthConnection(connection);
    if (resp.status === 204) {
      return null;
    }
  } catch (e) {
    return 'データベースへの接続が確認できません';
  }
  return null;
};

export async function postBuilderWorkflowExecution(
  project_id: string,
  workflow_id: string,
  body: {
    is_detect: boolean;
    node_id?: string;
    follow: boolean;
  }
) {
  return _post(
    `/builders/${project_id}/workflows/${workflow_id}/execute`,
    body
  );
}
