import { SwitchOperators } from 'models/form/value';
import { toDtypeLabel } from 'Utils/dataTypes';

export const isNotUseColumnCondition = (
  operator: SwitchOperators | null
): boolean => {
  switch (operator) {
    case SwitchOperators.eq:
    case SwitchOperators.notEq:
    case SwitchOperators.lt:
    case SwitchOperators.le:
    case SwitchOperators.gt:
    case SwitchOperators.ge:
    case SwitchOperators.between:
    case null:
      return false;
    default:
      return true;
  }
};

export const isUseColumnCondition = (
  operator: SwitchOperators | null
): boolean => {
  switch (operator) {
    case SwitchOperators.eq:
    case SwitchOperators.notEq:
    case SwitchOperators.lt:
    case SwitchOperators.le:
    case SwitchOperators.gt:
    case SwitchOperators.ge:
    case SwitchOperators.between:
    case null:
      return true;
    default:
      return false;
  }
};

export const validate_columns = (
  label: string,
  dtype: string,
  data_columns: string[],
  data_dtypes: string[],
  approve_dtypes?: string[]
): string | undefined => {
  const is_exist_column = validate_is_exist_column(label, data_columns);
  if (is_exist_column != undefined) {
    return is_exist_column;
  }
  const check_dtype = validate_column_dtype(
    label,
    dtype,
    data_columns,
    data_dtypes
  );
  if (check_dtype != undefined) {
    return check_dtype;
  }
  if (approve_dtypes != undefined) {
    const check_approve_dtypes = validate_approve_dtype(dtype, approve_dtypes);
    if (check_approve_dtypes != undefined) {
      return check_approve_dtypes;
    }
  }
  return;
};

const validate_is_exist_column = (
  label: string,
  data_columns: string[]
): string | undefined => {
  const index = data_columns.indexOf(label);
  if (index === -1) {
    return '存在しない列が選択されています。';
  }
  return;
};

const validate_column_dtype = (
  label: string,
  dtype: string,
  data_columns: string[],
  data_dtypes: string[]
): string | undefined => {
  const index = data_columns.indexOf(label);
  if (index === -1) {
    return;
  }
  const data_dtype = data_dtypes[index];
  if (data_dtype !== dtype) {
    const dtype_label = toDtypeLabel(dtype);
    return (
      'セットされた列の型が変わりました。列を選択し直して下さい(変更前:' +
      dtype_label +
      '型)'
    );
  }
  return;
};

const validate_approve_dtype = (
  dtype: string,
  approve_dtype: string[]
): string | undefined => {
  if (approve_dtype.includes(dtype)) {
    return;
  }
  const dtype_label = toDtypeLabel(dtype);
  return dtype_label + '型の列は指定できません。';
};
