import * as React from 'react';

import SelectField from './selectField';
import TextField from './textField';
import { Variable } from 'models/project';
import {
  CategorySelectFieldSchema,
  FieldTypes,
  SelectFieldSchema,
  TextFieldSchema
} from 'models/form/schema';
import { FieldValidationError } from 'models/form/validate';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';

interface CategorySelectFieldProps {
  suggetions?: string[];
  value: string;
  variables?: Variable[];
  schema: CategorySelectFieldSchema;
  errors: FieldValidationError;
  onChangeField: ChangeFieldHandler;
  detailedHelpTooltip: React.ReactNode;
}

const categorySelectField: React.FunctionComponent<
  CategorySelectFieldProps
> = ({
  suggetions,
  variables,
  schema,
  errors,
  detailedHelpTooltip,
  ...props
}) => {
  // シングルセレクトの場合、テキストフィールドとして描画する
  if (!schema.multi || suggetions == undefined) {
    const textFieldSchema: TextFieldSchema = {
      ...schema,
      type: FieldTypes.text
    };
    return (
      <TextField
        schema={textFieldSchema}
        errors={errors}
        value={props.value}
        options={suggetions}
        variables={variables}
        onChangeField={props.onChangeField}
        forcePopupIcon={true}
        detailedHelpTooltip={detailedHelpTooltip}
      />
    );
  }

  // multi: false なら "foo" => { label: "foo", value: "foo" }
  // multi: true なら "foo,bar" => [{ label: "foo", value: "foo" }, { label: "bar", value: "bar" }]
  const items = suggetions.map((v) => ({
    label: String(v),
    value: String(v)
  }));
  const varItems =
    variables != undefined
      ? variables.map((v) => ({
          label: `変数 ${v.name}`,
          value: `$${v.name}`
        }))
      : [];
  const selectItems = items.concat(varItems);

  const value =
    schema.multi && props.value != undefined
      ? props.value !== ''
        ? props.value.split(',').map(
            (v) =>
              selectItems.find((i) => i.value === v) || {
                label: v,
                value: v
              }
          )
        : []
      : { label: props.value, value: props.value };

  const selectSchema: SelectFieldSchema = {
    key: schema.key,
    type: FieldTypes.select,
    title: schema.title,
    defaultValue: schema.defaultValue,
    items: selectItems,
    allDtypes: [],
    multi: schema.multi,
    validate: schema.validate,
    placeholder: schema.placeholder,
    fullWidth: schema.fullWidth,
    variant: schema.variant,
    backgroundColor: schema.backgroundColor,
    errors
  };

  return (
    <SelectField
      freeSolo={true}
      clearable={false}
      schema={selectSchema}
      simpleValue={false}
      onChange={(val) => {
        if (Array.isArray(val) && val != undefined) {
          // multi: trueのときは、valがArrayで来る
          // freeSoloで新規作成分は、stringで来る
          const changedValue = val
            .map((v) => (typeof v === 'string' ? v : v.value))
            .join(',');
          return props.onChangeField(schema.key, changedValue, []);
        } else if (schema.multi && val != undefined) {
          return props.onChangeField(schema.key, val, []);
        } else {
          const changedValue = val != undefined ? val.value : '';
          return props.onChangeField(schema.key, changedValue, []);
        }
      }}
      errors={errors}
      {...props}
      value={value}
      detailedHelpTooltip={detailedHelpTooltip}
    />
  );
};

export default categorySelectField;
