import * as React from 'react';
import {
  FormControl,
  FormControlProps,
  InputLabel,
  Select,
  SelectProps
} from '@material-ui/core';
import { Option } from 'models/chart';
import { color } from '../../../theme';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  select: {
    color: color
  },
  label: {
    backgroundColor: '#ffffff',
    color: color
  }
});

export const ListSelect: React.FC<{
  value: string;
  label: string;
  width?: number;
  onChange: (value: string) => void;
  options: Option[];
  formProps?: Omit<FormControlProps, 'onChange' | 'value'>;
  selectProps?: Omit<SelectProps, 'onChange' | 'value'>;
}> = ({ value, width, label, onChange, options, formProps, selectProps }) => {
  const classes = useStyles();
  return (
    <FormControl margin="dense" variant="outlined" {...formProps}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <Select
        className={classes.select}
        style={{ width }}
        native={true}
        value={value}
        onChange={(event) => onChange(event.target.value as string)}
        {...selectProps}
      >
        {options.map((op) => {
          return (
            <option key={op.value} value={op.value}>
              {op.label}
            </option>
          );
        })}
      </Select>
    </FormControl>
  );
};
