import { Toolbar as MuiToolbar } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

export const Toolbar = withStyles({
  root: {
    width: '100%',
    height: 64,
    backgroundColor: '#f9f9f9'
  }
})(MuiToolbar);
