import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

export function SvgIcProject(props) {
  return (
    <SvgIcon viewBox="7 6 66 66" {...props}>
      <g>
        <path
          d="M42.66 46.539s0-1.561-1.56-4.683c0 0-4.684 3.122-4.684 6.245 0 3.122 6.244-1.562 6.244-1.562"
          fill="currentColor"
        />
        <path
          d="M45.002 32.489a.78.78 0 110-1.56.78.78 0 010 1.56M61.06 48.427a.29.29 0 01-.019-.017v.011a68.074 68.074 0 01-.821-.319 45.145 45.145 0 01-3.51-1.563s2.366.788 4.323.978c1.052.103 1.983.03 2.367-.43a.818.818 0 00.185-.53l.007-.018h-.007c.002-.383-.183-.889-.63-1.561a9.208 9.208 0 00-1.787-1.976 9.944 9.944 0 00-1.028-.752c-3.066-1.954-6.552-1.955-6.552-1.955 1.746-.873 3.978-.77 5.609-.509a22.538 22.538 0 00-2.487-4.174c-9.366-12.489-18.732-4.684-18.732-4.684s-7.312-2.434-11.168-.847c1.957-.066 4.856.902 7.506 1.79 1.346.451 2.618.877 3.446 1.043 2.628.525 3.676-.431 3.719-.472a.442.442 0 01.619.628c-.045.044-.909.88-2.875.88-.479 0-1.023-.05-1.636-.172-.882-.177-2.12-.592-3.553-1.071-2.919-.978-7.33-2.458-8.387-1.4-.018.018-.041.027-.061.04.214.569.888 1.636 3.022 2.704l10.2 5.099a9.357 9.357 0 013.909 3.645c2.366 4.046 6.021 12.146 1.503 17.794-6.244 7.805-17.172 1.561-17.172 1.561 3.122-7.806-6.244-12.488-6.244-12.488s4.682 4.682 1.56 7.805c-3.122 3.122-9.366 0-9.366 0 4.684 7.805 10.928 6.245 10.928 6.245l1.56 1.56c6.244 9.366 20.294 6.244 26.539 1.562 2.752-2.065 5.806-5.95 7.561-10.718l7.493 3.344c.445-4.534-4.234-9.367-6.021-11.033M34.523 23.44c-.562 0-1.286-.16-2.22-.594-1.454-.674-3.076-1.866-4.565-3.356-2.82-2.82-4.977-6.655-3.421-8.212l.83.831c-.638.638.378 3.507 3.42 6.55 1.396 1.394 2.898 2.504 4.23 3.121 1.32.611 2.065.555 2.32.3l.832.83c-.28.281-.728.53-1.426.53"
          fill="currentColor"
        />
        <path
          d="M36.702 21.271c-1.87 0-4.638-1.775-6.805-3.94-2.82-2.82-4.976-6.658-3.42-8.213l.83.831c-.638.637.377 3.507 3.42 6.55 3.043 3.042 5.912 4.058 6.55 3.42l.831.831c-.36.36-.843.521-1.406.521"
          fill="currentColor"
        />
        <path
          d="M30.07 7.606c-.295 0-.493.073-.603.183-.255.255-.312 1 .3 2.32.617 1.332 1.726 2.834 3.12 4.23 3.043 3.041 5.912 4.058 6.551 3.42.256-.257.312-1-.3-2.32-.618-1.333-1.726-2.835-3.12-4.23-1.396-1.395-2.898-2.503-4.23-3.122-.757-.35-1.323-.48-1.719-.48m8.793 11.504c-1.872 0-4.638-1.776-6.805-3.943-1.49-1.489-2.682-3.11-3.356-4.566-.97-2.095-.57-3.138-.065-3.644.507-.508 1.551-.907 3.645.064 1.455.674 3.077 1.866 4.567 3.356 1.491 1.49 2.682 3.112 3.357 4.567.97 2.095.57 3.138.064 3.644-.36.361-.844.522-1.407.522"
          fill="currentColor"
        />
        <g transform="translate(20.478 6)">
          <path
            d="M9.578 1.901c-.198 0-.325.04-.38.095L2.715 8.477c-.149.15-.183.82.358 1.988.604 1.302 1.692 2.775 3.063 4.147 1.371 1.371 2.843 2.46 4.145 3.062 1.17.541 1.84.506 1.99.358l6.48-6.48c.148-.15.183-.82-.358-1.99-.604-1.302-1.69-2.774-3.063-4.145-1.37-1.37-2.844-2.459-4.146-3.063-.734-.34-1.27-.453-1.607-.453m2.31 17.991c-.605 0-1.373-.168-2.346-.619-1.486-.688-3.138-1.9-4.65-3.415C1.895 12.865-.239 8.94 1.47 7.231L7.951.751c1.71-1.712 5.633.426 8.626 3.42 1.515 1.513 2.728 3.165 3.416 4.651 1.042 2.248.578 3.402.005 3.975l-6.48 6.481c-.326.325-.837.614-1.63.614"
            fill="currentColor"
          />
        </g>
        <path
          d="M19.457 26.433l.646.69a.697.697 0 00.615.212l.933-.14a.42.42 0 01.431.618l-.456.827a.698.698 0 00-.013.65l.423.845a.42.42 0 01-.456.601l-.927-.178a.7.7 0 00-.623.188l-.672.664a.42.42 0 01-.712-.248l-.117-.937a.7.7 0 00-.372-.534l-.838-.435a.42.42 0 01.015-.753l.854-.4a.703.703 0 00.394-.52l.154-.93a.42.42 0 01.721-.22M26.013 38.83l.227 1.308a.98.98 0 00.557.725l1.206.555a.591.591 0 01.03 1.06l-1.174.62a.984.984 0 00-.518.754l-.155 1.319a.591.591 0 01-1 .355l-.95-.925a.986.986 0 00-.88-.26l-1.3.26a.592.592 0 01-.647-.84l.585-1.191a.984.984 0 00-.024-.915l-.65-1.157a.59.59 0 01.599-.874l1.315.188a.985.985 0 00.862-.306l.9-.976a.592.592 0 011.017.3M48.957 18.336l.485.99a.82.82 0 00.616.448l1.09.162a.49.49 0 01.271.838l-.79.768a.819.819 0 00-.237.724l.183 1.088a.49.49 0 01-.714.515l-.975-.514a.816.816 0 00-.76-.002l-.979.51a.49.49 0 01-.71-.518l.188-1.087a.82.82 0 00-.234-.725l-.788-.771a.49.49 0 01.274-.837l1.092-.156a.815.815 0 00.616-.447l.49-.988a.492.492 0 01.882.002"
          fill="currentColor"
        />
      </g>
    </SvgIcon>
  );
}
