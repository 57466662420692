import * as React from 'react';
import {
  DialogContent,
  DialogContentProps,
  Dialog as MuiDialog,
  DialogProps as MuiDialogProps,
  DialogTitleProps as MuiDialogTitleProps
} from '@material-ui/core';
import {
  ButtonProps as NehanButtonProps,
  Button as NehanButton
} from 'ui/common/button';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogActions,
  DialogActionsButtonProps
} from './dialog/dialogActions';
import { DialogTitle, DialogTitleProps } from './dialog/dialogTitle';
import { HelpButtonProps } from 'components/diagram/ui/HelpDialog';

const dialogStyles = makeStyles({
  button: {},
  dialog: {
    maxHeight: '100%'
  }
});

export type ButtonProps =
  // | JSX.Element
  NehanButtonProps & {
    label?: string;
    [k: `data-${string}`]: string;
  };

export interface DialogProps extends MuiDialogProps {
  title?: string;
  titleProps?: MuiDialogTitleProps;
  helpButtonProps?: HelpButtonProps;
  hideHeader?: boolean;
  onClickBack?: () => void;
  backTooltipProps?: DialogTitleProps['backTooltipProps'];
  onClose: () => void;
  contentProps?: DialogContentProps;
  onSubmit?: (ev: React.FormEvent) => void; // formの送信をしたい時
  cancelButton?: ButtonProps;
  OKButton?: ButtonProps;
  secondaryOKButton?: DialogActionsButtonProps;
  hideFooter?: boolean;
  hideCancelButton?: boolean;
  hideOKButton?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  title,
  onSubmit,
  cancelButton,
  OKButton,
  secondaryOKButton,
  onClickBack,
  backTooltipProps,
  onClose,
  hideFooter,
  hideCancelButton,
  hideOKButton,
  titleProps,
  contentProps,
  hideHeader,
  helpButtonProps,
  children,
  ...props
}) => {
  const classes = dialogStyles();

  const render = () => {
    return (
      <>
        {!hideHeader && (
          <DialogTitle
            title={title}
            onClickClose={onClose}
            onClickBack={onClickBack}
            backTooltipProps={backTooltipProps}
            helpButtonProps={helpButtonProps}
            {...titleProps}
          />
        )}
        <DialogContent {...contentProps}>{children}</DialogContent>
        {!hideFooter && (
          <DialogActions secondaryOKButton={secondaryOKButton}>
            {!hideCancelButton && (
              <NehanButton
                className={classes.button}
                variant="contained"
                onClick={onClose}
                {...cancelButton}
              >
                {cancelButton?.label || 'キャンセル'}
              </NehanButton>
            )}
            {!hideOKButton && (
              <NehanButton
                className={classes.button}
                variant="contained"
                color="common_color"
                {...OKButton}
              >
                {OKButton?.label || 'OK'}
              </NehanButton>
            )}
          </DialogActions>
        )}
      </>
    );
  };
  return (
    <MuiDialog classes={{ paper: classes.dialog }} onClose={onClose} {...props}>
      {onSubmit ? <form onSubmit={onSubmit}>{render()}</form> : render()}
    </MuiDialog>
  );
};
