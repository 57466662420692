import * as Sentry from '@sentry/browser';
import {
  Edge,
  Edges,
  Graph,
  Node,
  NodeMeta,
  Nodes,
  Port,
  Ports,
  PortStatus
} from 'models/graph';
import { ProcessItem, ProcessItemConfig } from 'reducers/processList';
import { BuilderWorkflow, Workflow } from 'models/project';
import { cloneDeep, difference, flatten, uniq } from 'lodash-es';
import { TopologicalSort } from 'libs/topologicalSort';
import { ControlCoords } from 'components/diagram/controlCoords';
import { ulid } from 'ulid';

export function GenerateId() {
  return ulid();
}

export function generateNodeId() {
  return GenerateId();
}

export function generateEdgeId() {
  return GenerateId();
}

export function generatePortId() {
  return GenerateId();
}

export function GeneratePortsInfo(configs: ProcessItemConfig[]): Ports {
  const ports: Ports = {};
  configs.forEach((config) => {
    // generate PortID
    const portId = generatePortId();
    ports[portId] = {
      id: portId,
      status: PortStatus.Temporary,
      ...config
    };
  });
  return ports;
}

export function ConnectPort(
  inPorts: Ports,
  outPorts: Ports
): { fromPortId: string; toPortId: string; connected: boolean } {
  let fromPortId = '';
  let toPortId = '';
  let connected = false;
  Object.values(inPorts).forEach((inPort) => {
    Object.values(outPorts).forEach((outPort) => {
      if (inPort.objectType === 'any' && !connected && outPort.display) {
        fromPortId = outPort.id;
        toPortId = inPort.id;
        connected = true;
      }
      if (
        (outPort.objectType === inPort.objectType ||
          (outPort.objectType === 'dataframe_wo_summary' &&
            inPort.objectType === 'dataframe') ||
          (outPort.objectType === 'model_v2' &&
            inPort.objectType === 'model')) &&
        !connected &&
        outPort.display
      ) {
        fromPortId = outPort.id;
        toPortId = inPort.id;
        connected = true;
      }
    });
  });
  if (fromPortId === '' || toPortId === '') {
    Sentry.captureEvent({
      message: 'Failed to connect port',
      extra: { inPorts, outPorts, fromPortId, toPortId }
    });
    connected = false;
  }
  return { fromPortId, toPortId, connected };
}

export function searchNodeFromPort(
  nodes: Nodes,
  portID: string
): string | undefined {
  let nodeId = '';
  Object.values(nodes).forEach((node) => {
    let ports: string[] = [];
    const { inPorts, outPorts } = node;
    if (inPorts != undefined && inPorts.length > 0) {
      ports = ports.concat(inPorts);
    }

    if (outPorts != undefined && outPorts.length > 0) {
      ports = ports.concat(outPorts);
    }

    if (ports.includes(portID)) {
      nodeId = node.id;
    }
  });
  if (nodeId === '') {
    return undefined;
  }
  return nodeId;
}

// ResolveEdgeは、inputのportIdとつながっているoutのportIdを検索する
export function ResolveEdge(inputId: string, edges: Edges): string | undefined {
  const resolvedEdge = Object.values(edges).find(
    (edge) => edge.to && edge.to.portId === inputId
  );

  if (resolvedEdge != undefined) {
    return resolvedEdge.from.portId;
  }
  return undefined;
}

export function getChildrenNodes(
  nodeId: string,
  nodes: Nodes,
  edges: Edges,
  recursive = false
): string[] {
  const node = nodes[nodeId];
  let nodeIds: string[] = [];
  node.outPorts.forEach((portId) => {
    Object.values(edges).forEach((edge) => {
      if (edge.from.portId === portId) {
        nodeIds.push(edge.to.nodeId);
        if (recursive) {
          nodeIds = nodeIds.concat(
            getChildrenNodes(edge.to.nodeId, nodes, edges, true)
          );
        }
      }
    });
  });

  return nodeIds;
}

export function getRootNodeIds(nodeIds: string[], edges: Edges): string[] {
  if (nodeIds.length === 0) {
    return nodeIds;
  }

  const rootNodeIds: string[] = [];
  let loop = true;
  let childrenNodeIds = [...nodeIds];

  while (loop) {
    let nextChildNodeIds: string[] = [];
    childrenNodeIds.forEach((childrenNodeId) => {
      const parentNodeIds = Object.values(edges)
        .filter((edge) => {
          return edge.to.nodeId === childrenNodeId;
        })
        .map((edge) => {
          return edge.from.nodeId;
        });

      if (parentNodeIds.length === 0) {
        rootNodeIds.push(childrenNodeId);
        return false;
      } else {
        nextChildNodeIds = nextChildNodeIds.concat(parentNodeIds);
        return true;
      }
    });
    if (nextChildNodeIds.length === 0) {
      loop = false;
    } else {
      childrenNodeIds = nextChildNodeIds;
    }
  }
  return rootNodeIds;
}

// グラフを直列にソートする
export function sortNodes(nodes: Nodes, edges: Edges): string[] {
  // エッジの整理
  // ノードの接続関係を整理する
  // nodeOuts: { [nodeId]: outNodeIds }
  const nodeOuts: { [nodeId: string]: string[] } = Object.fromEntries(
    Object.keys(nodes).map((id) => [id, []])
  );
  let hasInputNodeIds: string[] = [];
  Object.values(edges).forEach((edge) => {
    if (nodeOuts[edge.from.nodeId] == undefined) {
      // へんなエッジが残っているパターンなので、スルー
      return;
    }
    nodeOuts[edge.from.nodeId].push(edge.to.nodeId);
    hasInputNodeIds.push(edge.to.nodeId);
  });
  hasInputNodeIds = uniq(hasInputNodeIds);
  const noInputNodeIds = difference(Object.keys(nodes), hasInputNodeIds);

  // インプットのないNodeをまとめて探す
  const startNodes = noInputNodeIds
    .map((id) => nodes[id])
    .sort((a, b) => a.x - b.x);

  // 追加済みノード
  const visited = Object.fromEntries(Object.keys(nodes).map((n) => [n, false]));

  // インプットなしノードから、つながっているノードを段階的に追加していく
  const sortedKeys: string[] = [];
  startNodes.forEach((start) => {
    sortedKeys.push(start.id);
    visited[start.id] = true;
    const queue = nodeOuts[start.id]
      .map((id) => nodes[id])
      .sort((a, b) => a.x - b.x);

    let node: Node | undefined;
    while ((node = queue.shift()) !== undefined) {
      if (visited[node.id]) {
        continue;
      }
      sortedKeys.push(node.id);
      visited[node.id] = true;
      const appendQueue = nodeOuts[node.id]
        .map((id) => nodes[id])
        .sort((a, b) => a.x - b.x);
      queue.push(...appendQueue);
    }
  });

  return sortedKeys;
}

export function topologicalSort(
  nodes: Nodes,
  edges: Edges
): { sortedKeys: string[]; nodesMeta: { [id: string]: NodeMeta } } {
  let sortedKeys: string[] = [];
  const pnodes = new Map<string, NodeMeta>();
  const nodesMeta: { [id: string]: NodeMeta } = {};
  Object.values(nodes)
    .map((node: Node) => {
      return {
        id: node.id,
        metadata: {
          height: 32 + node.rows * 11 * 1.2,
          width: ControlCoords.nodeSize.width,
          id: node.id
        }
      };
    })
    .forEach((node) => {
      nodesMeta[node.id] = node.metadata;
      pnodes.set(node.id, node.metadata);
    });

  // run topological sort
  const edgeSet = new Set<string>();
  const sortOpt = new TopologicalSort(pnodes);
  Object.values(edges).forEach((edge: Edge) => {
    // 同じノード間でに複数エッジ貼るとおかしくなるので、チェックする
    const setVal = `${edge.from.nodeId}-${edge.to.nodeId}`;
    if (!edgeSet.has(setVal)) {
      sortOpt.addEdge(edge.from.nodeId, edge.to.nodeId);
      edgeSet.add(setVal);
    }
  });
  const sorted = sortOpt.sort();
  const sortedResult: string[] = [...sorted.keys()];
  if (sortedResult.length > 0) {
    sortedKeys = sortedResult;
  }
  return { sortedKeys, nodesMeta };
}

// nodeId, edgeId, portId を振り直す
// 引数のGraphはdeepcopyする
export function regenerateIds(
  g: Graph,
  workflows: Array<Workflow | BuilderWorkflow>
): Graph {
  const graph = cloneDeep(g);
  const kinds = ['node', 'edge', 'port'];
  const ids: Map<(typeof kinds)[number], Set<string>> = new Map(
    kinds.map((k) => {
      const idSet: Set<string> = new Set(
        flatten(workflows.map((w) => Object.keys(w.graph[`${k}s`])))
      );
      return [k, idSet] as [string, Set<string>];
    })
  );

  // 既存のIDとかぶらない変換テーブルを作る
  // { "node": { oldId: newId }} 的なものができる
  const newIds: Map<(typeof kinds)[number], Map<string, string>> = new Map(
    kinds.map((k) => {
      const newIdMap: Map<string, string> = new Map(
        Object.keys(graph[`${k}s`]).map((id) => {
          const newId = generateUniqueId(k, ids.get(k)!);
          if (newId === '') {
            throw new Error('failed generate newId');
          }
          return [id, newId] as [string, string];
        })
      );

      return [k, newIdMap] as [string, Map<string, string>];
    })
  );

  // nodesにある、nodeIdとportIdを置き換える
  const newNodes = {};
  Object.values(graph.nodes).forEach((n: Node) => {
    // idを置き換える
    n.id = newIds.get('node')!.get(n.id)!;
    // portIdを置き換える
    const newOutPorts = n.outPorts.map((p) => newIds.get('port')!.get(p)!);
    n.outPorts = newOutPorts;
    const newInPorts = n.inPorts.map((p) => newIds.get('port')!.get(p)!);
    n.inPorts = newInPorts;
    // 置き換えたIDで詰める
    newNodes[n.id] = n;
  });

  // egdesを置き換える
  const newEdges = {};
  Object.values(graph.edges).forEach((e: Edge) => {
    // idを置き換える
    e.id = newIds.get('edge')!.get(e.id)!;
    // fromのnodeIdとportIdを置き換える
    e.from = {
      nodeId: newIds.get('node')!.get(e.from.nodeId)!,
      portId: newIds.get('port')!.get(e.from.portId)!
    };
    // toを置き換える
    e.to = {
      nodeId: newIds.get('node')!.get(e.to.nodeId)!,
      portId: newIds.get('port')!.get(e.to.portId)!
    };
    // 置き換えたidで詰める
    newEdges[e.id] = e;
  });

  // portsを置き換える
  const newPorts = {};
  Object.values(graph.ports).forEach((p: Port) => {
    // idを置き換える
    p.id = newIds.get('port')!.get(p.id)!;
    // 置き換えたidで詰める
    newPorts[p.id] = p;
  });

  // selectedNodeId, previousNodeIdを置き換える
  if (graph.selectedNodeId != undefined) {
    graph.selectedNodeId = newIds.get('node')!.get(graph.selectedNodeId);
  }

  if (graph.previousNodeId != undefined) {
    graph.previousNodeId = newIds.get('node')!.get(graph.previousNodeId);
  }

  return { ...graph, nodes: newNodes, edges: newEdges, ports: newPorts };
}

function generateUniqueId(
  prefix: string,
  ids: Set<string>,
  maxRetry = 10
): string {
  let count = 0;
  for (let i = 0; count < maxRetry; i++) {
    const newId = `${prefix}_${GenerateId()}`;
    if (!ids.has(newId)) {
      return newId;
    }
    count += 1;
  }
  // 失敗
  return '';
}

export function findWorkflowIndex(
  workflows: Array<Workflow | BuilderWorkflow>,
  nodeId: string
): number {
  let workflowIndex = -1;
  workflows.forEach((wf, i) => {
    if (Object.keys(wf.graph.nodes).includes(nodeId)) {
      workflowIndex = i;
    }
  });
  return workflowIndex;
}

export function findWorkflowByPortId(
  portId: string,
  workflows: Array<Workflow | BuilderWorkflow>
): number {
  return workflows.findIndex((wf) =>
    Object.keys(wf.graph.ports).includes(portId)
  );
}

export function findWorkflowByNodeId(
  nodeId: string,
  workflows: Array<Workflow | BuilderWorkflow>
): number {
  return workflows.findIndex((wf) =>
    Object.keys(wf.graph.nodes).includes(nodeId)
  );
}

export function findNode(
  nodeId: string,
  workflows: Array<Workflow | BuilderWorkflow>
): Node | undefined {
  const wIdx = findWorkflowIndex(workflows, nodeId);
  if (wIdx === -1) {
    return undefined;
  }

  return workflows[wIdx].graph.nodes[nodeId];
}

export function findPort(
  portId: string,
  workflows: Array<Workflow | BuilderWorkflow>
): Port | undefined {
  const wIdx = findWorkflowByPortId(portId, workflows);
  if (wIdx === -1) {
    return undefined;
  }

  return workflows[wIdx].graph.ports[portId];
}

export function findSourcePortFromNodeId(
  workflows: Array<Workflow | BuilderWorkflow>,
  nodeId: string
): string[] {
  const wIdx = findWorkflowIndex(workflows, nodeId);
  if (wIdx === -1) {
    return [];
  }
  const g = workflows[wIdx].graph;
  const node = g.nodes[nodeId];
  if (node == undefined) {
    return [];
  }

  return node.inPorts
    .map((p) => {
      const edge = Object.values(g.edges).find((e) => e.to.portId === p);
      if (edge) {
        return edge.from.portId;
      }
    })
    .filter((i) => i) as string[];
}

export function findNodeByPortId(
  portId: string,
  workflows: Array<Workflow | BuilderWorkflow>
): Node | undefined {
  const wIdx = findWorkflowByPortId(portId, workflows);
  if (wIdx === -1) {
    return undefined;
  }

  return Object.values(workflows[wIdx].graph.nodes).find((n) =>
    n.outPorts.includes(portId)
  );
}

// メモ化
const processIconNames: { [type: string]: string | undefined } = {};

export function getParentProcessIconName(
  processItems: ProcessItem[],
  childType: string,
  iconName?: string
): string | undefined {
  if (processIconNames[childType] != undefined) {
    return processIconNames[childType];
  }

  let parentIconName: string | undefined;
  processItems.forEach((p) => {
    if (p.children != undefined) {
      const name = getParentProcessIconName(
        p.children,
        childType,
        p.iconName || iconName
      );
      if (name != undefined) {
        parentIconName = name;
      }
    }

    if (p.type === childType) {
      parentIconName = iconName || p.iconName;
    }
  });
  if (parentIconName) {
    processIconNames[childType] = parentIconName;
  }
  return parentIconName;
}

const builderProcessIconNames: { [name: string]: string | undefined } = {};

export function getParentBuilderProcessIconName(
  processItems: ProcessItem[],
  childName: string,
  iconName?: string
): string | undefined {
  let parentIconName: string | undefined;
  if (builderProcessIconNames[childName] != undefined) {
    return builderProcessIconNames[childName];
  }
  processItems.forEach((p) => {
    if (p.children != undefined) {
      const name = getParentBuilderProcessIconName(
        p.children,
        childName,
        p.iconName || iconName
      );
      if (name != undefined) {
        parentIconName = name;
      }
    }

    if (p.name === childName) {
      parentIconName = iconName || p.iconName;
    }
  });
  if (parentIconName) {
    builderProcessIconNames[childName] = parentIconName;
  }
  return parentIconName;
}

// メモ化
const processTexts: { [type: string]: string | undefined } = {};

export function getProcessItemText(
  processItems: ProcessItem[],
  type: string
): string | undefined {
  if (processTexts[type] != undefined) {
    return processTexts[type];
  }
  let processText: string | undefined;
  processItems.forEach((p) => {
    if (p.children != undefined) {
      const text = getProcessItemText(p.children, type);
      if (text != undefined) {
        processText = text;
      }
    }

    if (p.type === type) {
      processText = p.text;
    }
  });

  if (processText) {
    processTexts[type] = processText;
  }
  return processText;
}

const builderProcessTexts: { [name: string]: string | undefined } = {};

export function getBuilderProcessItemText(
  processItems: ProcessItem[],
  name: string
): string | undefined {
  if (builderProcessTexts[name] != undefined) {
    return builderProcessTexts[name];
  }

  let processText: string | undefined;
  processItems.forEach((p) => {
    if (p.children != undefined) {
      const text = getBuilderProcessItemText(p.children, name);
      if (text != undefined) {
        processText = text;
      }
    }

    if (p.name === name) {
      processText = p.text;
    }
  });

  if (processText) {
    builderProcessTexts[name] = processText;
  }
  return processText;
}

export function getIconName(
  processItems: ProcessItem[],
  processType: string,
  formValues: any | undefined
): string | undefined {
  if (
    processType === 'dataexport_v2' &&
    formValues != undefined &&
    formValues.type === 's3' &&
    formValues._initialized &&
    (formValues.version == null || formValues.version === 1)
  ) {
    // データエクスポートのs3の旧バージョンは、旧バージョンアイコンを使う
    return 'OldVersion';
  }

  return getParentProcessIconName(processItems, processType);
}
