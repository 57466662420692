import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';
import LinkButton from 'ui/linkButton';
import { categories, UsableDatasource } from 'libs/datasources';
import { getUsableDatasources } from 'libs/api';
import { User } from '../../models/user';
import { AuthContext } from 'components/route';
import { SparkIcon } from 'components/icons/nodes/SparkIcon';

const styles = createStyles({
  root: {
    width: '100%'
  },
  listItem: {
    width: '300px',
    marginRight: '51px',
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.23)'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '83px'
  },
  title: {
    marginBottom: '10px',
    height: '29px',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#525252'
  }
});

function getSearch(key: string, _search: string): string {
  const search = new URLSearchParams(_search);
  if (key.includes('csv')) {
    // ファイルアップロードの場合は、アップロードしたファイル名が自動的にデータソース名になるロジックがあるためダイアログを出さない
    search.delete('first_display');
  }
  return `?${search.toString()}`;
}

export function createDatasourceLink(
  key: string,
  usableDatasources: UsableDatasource[]
): string {
  const ds = usableDatasources.find((d) => d.key === key);
  if (ds == undefined) {
    return '';
  }

  const search = getSearch(key, location.search);

  return ds.v2
    ? `/datasources_v2/new/${key}${search}`
    : `/datasources/new/${key}${search}`;
}

export interface NewDatasourceListProps {
  searchText: string;
}

export function isAvailable(user: User, ds: UsableDatasource): boolean {
  if (!user.isTrial) {
    return ds.available;
  }

  return ds.enabledInTrial;
}

const NewDatasourceList: React.FC<
  NewDatasourceListProps & WithStyles<typeof styles>
> = ({ classes, searchText }) => {
  const { user } = React.useContext(AuthContext);
  const [usableDatasources, setUsableDatasources] = React.useState<
    UsableDatasource[]
  >([]);
  React.useEffect(() => {
    const load = async () => {
      const { data } = await getUsableDatasources();
      setUsableDatasources(data.usableDatasources);
    };
    load();
  }, []);
  return (
    <div className={classes.root}>
      {categories
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return (
            <div key={c.category}>
              <div className={classes.title}>{c.title}</div>
              <div className={classes.list}>
                {usableDatasources
                  .filter(
                    (d) =>
                      d.category === c.category &&
                      d.name
                        .toLocaleLowerCase()
                        .indexOf(searchText.toLocaleLowerCase()) !== -1
                  )
                  .sort((a, b) => a.order - b.order)
                  .map((ds) => {
                    return (
                      <div className={classes.listItem} key={ds.key}>
                        <LinkButton
                          logo={ds.logo}
                          name={
                            <>
                              {ds.name}
                              &nbsp;
                              {user.organization.feature_spark && ds.spark && (
                                <SparkIcon />
                              )}
                            </>
                          }
                          link={createDatasourceLink(ds.key, usableDatasources)}
                          onClick={
                            ds.available
                              ? undefined
                              : () => {
                                  alert(
                                    '利用を希望される場合はお問い合わせください'
                                  );
                                }
                          }
                          available={isAvailable(user, ds)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(NewDatasourceList);
