import { applyPatches, Patch, produce } from 'immer';
import { Edge, Node, NodeStatus, Port } from 'models/graph';
import { GraphState, removeNode } from 'reducers/graph';

export enum UndoType {
  CREATE_NODE_EDGE = 'CREATE_NODE_EDGE',
  REMOVE_NODE = 'REMOVE_NODE',
  NODE_POSITION = 'NODE_POSITION',
  CREATE_EDGE = 'CREATE_EDGE',
  REMOVE_EDGE = 'REMOVE_EDGE'
}

export type UndoItemType =
  | UndoCreateNodeEdge
  | UndoRemoveNode
  | UndoNodePosition
  | UndoCreateEdge
  | UndoRemoveEdge;
export type RedoItemType =
  | RedoCreateNodeEdge
  | RedoRemoveNode
  | RedoNodePosition
  | RedoCreateEdge
  | RedoRemoveEdge;

export interface UndoCreateNodeEdge {
  type: UndoType.CREATE_NODE_EDGE;
  params: {
    nodes: string[];
  };
}

export interface RedoCreateNodeEdge {
  type: UndoType.CREATE_NODE_EDGE;
  params: {
    deleted: {
      nodes: Node[];
      edges: Edge[];
      ports: Port[];
    };
  };
}

export interface UndoRemoveNode {
  type: UndoType.REMOVE_NODE;
  params: {
    deleted: {
      nodes: Node[];
      edges: Edge[];
      ports: Port[];
    };
  };
}

export interface RedoRemoveNode {
  type: UndoType.REMOVE_NODE;
  params: {
    nodes: string[];
  };
}

export interface UndoCreateEdge {
  type: UndoType.CREATE_EDGE;
  params: {
    edge: Edge;
    deletedEdges: Edge[];
  };
}

export type RedoCreateEdge = UndoCreateEdge;

export interface UndoRemoveEdge {
  type: UndoType.REMOVE_EDGE;
  params: {
    edge: Edge;
  };
}

export type RedoRemoveEdge = UndoRemoveEdge;

export interface UndoNodePosition {
  type: UndoType.NODE_POSITION;
  params: {
    undoPatch: Patch[];
    redoPatch: Patch[];
  };
}

export type RedoNodePosition = UndoNodePosition;

export function undo(state: GraphState) {
  const { undoList, redoList } = state;
  if (undoList.length === 0) {
    return state;
  }
  const lastUndo = undoList[undoList.length - 1];
  switch (lastUndo.type) {
    case UndoType.NODE_POSITION: {
      const newState = applyPatches(state, lastUndo.params.undoPatch);
      return {
        ...newState,
        undoList: undoList.slice(0, -1),
        redoList: cutArray([...redoList, lastUndo])
      };
    }

    case UndoType.CREATE_NODE_EDGE: {
      const [{ nodes, ports, edges }, deleted] = removeNode(
        lastUndo.params.nodes,
        state
      );
      const redoCommand: RedoCreateNodeEdge = {
        type: UndoType.CREATE_NODE_EDGE,
        params: { deleted }
      };
      return {
        ...state,
        nodes,
        ports,
        edges,
        undoList: undoList.slice(0, -1),
        redoList: cutArray([...redoList, redoCommand])
      };
    }

    case UndoType.REMOVE_NODE: {
      const newState = restoreDeletedNodes(state, lastUndo.params);
      const redoItem: RedoRemoveNode = {
        type: UndoType.REMOVE_NODE,
        params: { nodes: lastUndo.params.deleted.nodes.map((n) => n.id) }
      };
      return {
        ...newState,
        undoList: undoList.slice(0, -1),
        redoList: cutArray([...state.redoList, redoItem])
      };
    }

    case UndoType.CREATE_EDGE: {
      return produce(state, (draft) => {
        delete draft.edges[lastUndo.params.edge.id];
        lastUndo.params.deletedEdges.forEach((e) => {
          draft.edges[e.id] = e;
        });
        draft.undoList = draft.undoList.slice(0, -1);
        draft.redoList.push(lastUndo);
        draft.redoList = cutArray(draft.redoList);
      });
    }

    case UndoType.REMOVE_EDGE: {
      return produce(state, (draft) => {
        draft.edges[lastUndo.params.edge.id] = lastUndo.params.edge;
        draft.undoList = draft.undoList.slice(0, -1);
        draft.redoList.push(lastUndo);
        draft.redoList = cutArray(draft.redoList);
      });
    }
  }
  return state;
}

function restoreDeletedNodes(
  state: GraphState,
  params: UndoRemoveNode['params']
) {
  const newState = produce(state, (draft) => {
    params.deleted.nodes.forEach((node) => {
      draft.nodes[node.id] = {
        ...node,
        status: NodeStatus.Copied
      };
    });
    params.deleted.ports.forEach((port) => {
      draft.ports[port.id] = port;
    });
    params.deleted.edges.forEach((edge) => {
      draft.edges[edge.id] = edge;
    });
  });

  return {
    ...newState
  };
}

export function redo(state: GraphState): GraphState {
  const { undoList, redoList } = state;
  if (redoList.length === 0) {
    return state;
  }
  const lastRedo = redoList[redoList.length - 1];
  switch (lastRedo.type) {
    case UndoType.NODE_POSITION: {
      const newState = applyPatches(state, lastRedo.params.redoPatch);
      return {
        ...newState,
        undoList: cutArray([...undoList, lastRedo as UndoNodePosition]),
        redoList: redoList.slice(0, -1)
      };
    }

    case UndoType.CREATE_NODE_EDGE: {
      const newState = restoreDeletedNodes(state, lastRedo.params);
      return {
        ...newState,
        undoList: cutArray([
          ...state.undoList,
          {
            type: UndoType.CREATE_NODE_EDGE as const,
            params: { nodes: lastRedo.params.deleted.nodes.map((n) => n.id) }
          }
        ]),
        redoList: state.redoList.slice(0, -1)
      };
    }

    case UndoType.REMOVE_NODE: {
      const [{ nodes, ports, edges }, deleted] = removeNode(
        lastRedo.params.nodes,
        state
      );
      const undoCommand: UndoRemoveNode = {
        type: UndoType.REMOVE_NODE,
        params: { deleted }
      };
      return {
        ...state,
        nodes,
        ports,
        edges,
        undoList: cutArray([...undoList, undoCommand]),
        redoList: redoList.slice(0, -1)
      };
    }

    case UndoType.CREATE_EDGE: {
      return produce(state, (draft) => {
        draft.edges[lastRedo.params.edge.id] = lastRedo.params.edge;
        lastRedo.params.deletedEdges.forEach((e) => {
          delete draft.edges[e.id];
        });
        draft.undoList.push(lastRedo);
        draft.undoList = cutArray(draft.undoList);
        draft.redoList = draft.redoList.slice(0, -1);
      });
    }

    case UndoType.REMOVE_EDGE: {
      return produce(state, (draft) => {
        delete draft.edges[lastRedo.params.edge.id];
        draft.undoList.push(lastRedo);
        draft.undoList = cutArray(draft.undoList);
        draft.redoList = draft.redoList.slice(0, -1);
      });
    }
  }
  return state;
}

const MAX_UNDO_SIZE = 50;

export function cutArray<T>(list: T[]): T[] {
  if (list.length > MAX_UNDO_SIZE) {
    return list.slice(list.length - MAX_UNDO_SIZE);
  } else {
    return list;
  }
}
