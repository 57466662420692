import * as React from 'react';
import {
  exportStatusList,
  SelectForm,
  TextForm
} from 'ui/listView/filter/form';
import { ExportItem } from 'models/dependency';
import {
  Calendar,
  CustomDate,
  getDateConditionValue
} from 'ui/listView/filter/customDate';
import {
  AutocompleteForm,
  AutocompleteItemIconType,
  toOptions,
  Option
} from 'ui/listView/filter/autocompleteForm';
import { FilterType } from 'ui/listView/filter/type';
import {
  handleChangeCustomDateCondition,
  handleChangeCustomDateRange,
  handleChangeDatasourceTypes,
  handleChangeExportStatuses,
  handleChangeProjectName,
  handleCloseCalendar
} from 'ui/listView/filter/handler';
import { FilterContext } from 'ui/listView/filter/context';

export const ExportFilter: React.FC = () => {
  const {
    state: filterState,
    dispatch: filterDispatch,
    items: oriItems
  } = React.useContext(FilterContext);
  const items = oriItems as ExportItem[];
  const calendarForExecutionEndOpen = Boolean(
    filterState[FilterType.CalendarPositionForExecutionEnd]
  );
  const calendarForExecutionStartOpen = Boolean(
    filterState[FilterType.CalendarPositionForExecutionStart]
  );

  const refForCalendar = React.useRef<HTMLTableElement | null>(null);

  const uniqueTypes = React.useMemo(() => {
    return [
      ...new Set(
        items.filter((item) => item.type !== '').map((item) => item.type)
      )
    ];
  }, [items]);

  const typeOptions = React.useMemo(() => {
    return toOptions(uniqueTypes);
  }, [uniqueTypes]);

  const onChangeType = React.useCallback(
    (_: React.ChangeEvent<{}>, value: Option[]) => {
      handleChangeDatasourceTypes(filterState, filterDispatch)(
        _,
        value.map((v) => v.value)
      );
    },
    [filterState, filterDispatch]
  );

  return (
    <>
      <table ref={refForCalendar}>
        <tbody>
          <TextForm
            title="分析プロジェクト名（含む）"
            value={filterState[FilterType.ProjectName]}
            onChange={handleChangeProjectName(filterState, filterDispatch)}
          />
          <AutocompleteForm
            title="タイプ"
            options={typeOptions}
            value={filterState[FilterType.DatasourceTypes]}
            type={AutocompleteItemIconType.Datasource}
            onChange={onChangeType}
          />
          <SelectForm
            title="ステータス"
            labels={exportStatusList}
            values={filterState[FilterType.ExportStatuses]}
            onChange={handleChangeExportStatuses(filterState, filterDispatch)}
          />
          <CustomDate
            title="実行開始時間"
            id="execution-start-select"
            value={getDateConditionValue(
              filterState[FilterType.ExecutionStartCondition],
              filterState[FilterType.ExecutionStartRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForExecutionStart,
              FilterType.ExecutionStartCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          <CustomDate
            title="実行終了時間"
            id="execution-end-select"
            value={getDateConditionValue(
              filterState[FilterType.ExecutionEndCondition],
              filterState[FilterType.ExecutionEndRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForExecutionEnd,
              FilterType.ExecutionEndCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
        </tbody>
      </table>
      <Calendar
        id="execution-start-calendar"
        title="実行開始時間"
        open={calendarForExecutionStartOpen}
        anchorEl={filterState[FilterType.CalendarPositionForExecutionStart]}
        ranges={filterState[FilterType.ExecutionStartRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.ExecutionStartRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForExecutionStart,
          filterState,
          filterDispatch
        )}
      />
      <Calendar
        id="execution-end-calendar"
        title="実行終了時間"
        open={calendarForExecutionEndOpen}
        anchorEl={filterState[FilterType.CalendarPositionForExecutionEnd]}
        ranges={filterState[FilterType.ExecutionEndRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.ExecutionEndRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForExecutionEnd,
          filterState,
          filterDispatch
        )}
      />
    </>
  );
};
