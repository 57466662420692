import * as React from 'react';
import { FilterDispatch } from 'ui/listView/filter/filterReducer';
import { FilterState } from 'ui/listView/filter/type';
import { ListItem, ListItemType } from 'models/dependency';

export const FilterContext = React.createContext<{
  state: FilterState;
  dispatch: FilterDispatch;
  type: ListItemType;
  isAdminPage?: boolean;
  items: ListItem[];
  emails: string[];
  datasourceTypes: string[];
  load: (filtering?: boolean) => void;
}>(
  {} as {
    state: FilterState;
    dispatch: FilterDispatch;
    type: ListItemType;
    isAdminPage: boolean;
    items: ListItem[];
    emails: string[];
    datasourceTypes: string[];
    load: (filtering?: boolean) => void;
  }
);
