import * as DataForge from 'data-forge';
import {
  GroupedScatterSeries,
  ScatterPoint,
  ScatterData,
  FusionChartType,
  Scatter,
  GenericsChartSettings
} from 'models/chart';
import {
  getValueName,
  sortLegend,
  getYOrderIndexies,
  pivotXValueDF
} from './common';
import { nullValueLabel } from './constant';
import { linearRegression } from 'simple-statistics';

type DisplayValueMap = { [x: number]: { [y: number]: string | undefined } };

export const addDisplayValueToDataset = (
  datasetList: GroupedScatterSeries[],
  configure: GenericsChartSettings<FusionChartType.Scatter, Scatter>,
  df: DataForge.IDataFrame,
  multiY?: boolean // y軸を複数設定しているとき
): GroupedScatterSeries[] => {
  return datasetList.map((dataset: GroupedScatterSeries) => {
    return {
      ...dataset,
      data: addDisplayValueToData(
        dataset.data,
        configure,
        df,
        multiY ? dataset.seriesname : configure.chart.y[0].column?.value // yの列名
      )
    };
  });
};

const addDisplayValueToData = (
  data: ScatterPoint[],
  configure: GenericsChartSettings<FusionChartType.Scatter, Scatter>,
  df: DataForge.IDataFrame,
  yColname: string | undefined
): ScatterPoint[] => {
  const displayValueColumn = configure.view.displayValueColumn;
  if (!displayValueColumn) {
    return data;
  }

  const xColname = configure.chart.x.value;
  if (!yColname) {
    return data;
  }

  // x, yを指定してdisplayValueを取り出せるmapに変換
  const displayValueMap: DisplayValueMap = {};
  df.select((row) => ({
    x: row[xColname],
    y: row[yColname],
    displayValue: row[displayValueColumn]
  }))
    .toArray()
    .forEach((row) => {
      if (row.x === undefined || row.y === undefined) {
        return;
      }
      if (!displayValueMap[row.x]) {
        displayValueMap[row.x] = {};
      }
      displayValueMap[row.x][row.y] = row.displayValue;
    });

  // displayValueColumnの値を追加
  return data.map((row: ScatterPoint) => {
    return {
      ...row,
      displayValue:
        row.x !== undefined && row.y !== undefined
          ? String(displayValueMap[row.x][row.y])
          : undefined
    } as ScatterPoint;
  });
};

export const scatterTransformSingle = (
  df: DataForge.IDataFrame,
  configure: GenericsChartSettings<FusionChartType.Scatter, Scatter>
): ScatterData => {
  const xColname = configure.chart.x.value;
  const yColname = configure.chart.y[0].column?.value;
  if (yColname == undefined) {
    return {
      type: configure.type,
      dataset: [{ data: [] }]
    };
  }
  const data = df
    .select((row) => ({
      [xColname]: row[xColname],
      [yColname]: row[yColname]
    }))
    .toArray()
    .map((d) => ({
      x: d[xColname],
      y: d[yColname]
    }));

  if (configure.chart.order.data?.method === 'xValue') {
    data.sort((a, b) => a.x - b.x);
  }

  const displayValueAdded = addDisplayValueToDataset([{ data }], configure, df);
  return {
    type: configure.type,
    dataset: displayValueAdded
  };
};

export const scatterTransformMultipleWithMultiY = (
  df: DataForge.IDataFrame,
  configure: GenericsChartSettings<FusionChartType.Scatter, Scatter>
) => {
  const { chart: config } = configure;
  // valueNames: yの列名 -> ここでは判例の値を意味する
  const valueNames = configure.chart.y.map((value) =>
    getValueName(config.aggregated, value)
  );

  const orders = sortLegend(configure, df, valueNames);

  // 凡例（y軸に設定した列たち）の並び
  const yOrderIndexies = getYOrderIndexies(orders, valueNames);
  const dataset = yOrderIndexies.map((index) => {
    const yValue = config.y[index];
    const seriesname = getValueName(config.aggregated, yValue);
    const column = yValue.column;
    if (column == undefined) {
      return {
        seriesname,
        data: []
      };
    }
    const xName = config.x.value;

    const data = df
      .select((row) => ({
        [xName]: row[xName],
        [column.value]: row[column.value]
      }))
      .toArray()
      .map((d) => ({
        x: d[xName],
        y: d[column.value]
      }));

    if (configure.chart.order.data?.method === 'xValue') {
      data.sort((a, b) => a.x - b.x);
    }

    return {
      seriesname,
      data
    };
  });

  const displayValueAdded = addDisplayValueToDataset(
    dataset,
    configure,
    df,
    true
  );
  return {
    type: configure.type,
    dataset: displayValueAdded
  };
};

export const scatterTransformMultipleWithLegend = (
  df: DataForge.IDataFrame | undefined,
  config: GenericsChartSettings<FusionChartType.Scatter, Scatter>
) => {
  if (df == undefined) {
    return;
  }

  const {
    chart: { x, legend, order, y, aggregated }
  } = config;
  if (legend == undefined) {
    return;
  }

  const xColname = x.value; // spread horizontal
  const legendColname = legend.value; // group column,
  const yColname = getValueName(aggregated, y[0]); // value column

  const xValues = df
    .distinct((row) => row[xColname])
    .toArray()
    .map((row) => {
      return row[xColname];
    });

  if (order.data?.method === 'xValue') {
    xValues.sort((a, b) => a - b);
  }

  // 凡例の並べ替え
  // x軸の値を列名に横展開する
  const wide = pivotXValueDF(
    df,
    xColname,
    yColname,
    legendColname,
    order,
    xValues,
    y[0],
    aggregated
  );

  // 変形
  const dataset = wide.toArray().map((row) => {
    const seriesname =
      row[legendColname] == undefined ? nullValueLabel : row[legendColname];
    return {
      seriesname: String(seriesname),
      data: xValues
        .filter((x) => row[x] != undefined)
        .map((x) => {
          if (Array.isArray(row[x])) {
            return row[x].map((y) => {
              return { x, y };
            });
          }
          return { x, y: row[x] };
        })
        .flat()
    };
  });

  const displayValueAdded = addDisplayValueToDataset(dataset, config, df);
  return {
    type: config.type,
    dataset: displayValueAdded
  };
};

const calcRegressionLine = (dataset: GroupedScatterSeries) => {
  const regressionLine = linearRegression(
    dataset.data.map((d: { x: number; y: number }) => [d.x, d.y])
  );
  return regressionLine;
};

const getRegressionEquationText = (regressionLine: {
  m: number;
  b: number;
}): string => {
  const mOperator = regressionLine.m >= 0 ? '' : '-';
  const m = Math.abs(regressionLine.m).toFixed(2);
  const bOperator = regressionLine.b >= 0 ? '+' : '-';
  const b = Math.abs(regressionLine.b).toFixed(2);
  return `y = ${mOperator} ${m}x ${bOperator} ${b}`;
};

// datasetの中でxの最小値のindexを取得する
// iが回帰式を表示する位置になる
const calcMinXIndex = (dataset: GroupedScatterSeries): number => {
  return dataset.data
    .map((row, i) => ({ row, i })) // 元のindexを保持
    .sort((a, b) => {
      return (a.row.x as number) - (b.row.x as number);
    })[0].i;
};

export const addRegressionEquation = (
  showRegressionLine: boolean | undefined,
  dataset: GroupedScatterSeries,
  color: string
) => {
  if (!showRegressionLine) {
    return undefined;
  }

  const regressionLine = calcRegressionLine(dataset);
  const text = getRegressionEquationText(regressionLine);
  const minXIndex = calcMinXIndex(dataset);
  return addAnnotations('regressionEquation', [
    {
      id: '0',
      type: 'text',
      text,
      align: 'right',
      x: `$dataset.0.set.${minXIndex}.startx - 10`,
      y: `$dataset.0.set.${minXIndex}.y`,
      color
    }
  ]);
};

export const addRegressionEquations = (
  showRegressionLine: boolean | undefined,
  datasetList: GroupedScatterSeries[],
  colors: {
    [seriesname: string]: string;
  }
) => {
  if (!showRegressionLine) {
    return undefined;
  }

  return addAnnotations(
    'regressionEquation',
    datasetList.map((dataset, i) => {
      const regressionLine = calcRegressionLine(dataset);
      const text = getRegressionEquationText(regressionLine);
      const minXIndex = calcMinXIndex(dataset);
      return {
        id: String(i),
        type: 'text',
        text,
        align: 'right',
        x: `$dataset.${i}.set.${minXIndex}.startx - 10`,
        y: `$dataset.${i}.set.${minXIndex}.y`,
        color: dataset.seriesname ? colors[dataset.seriesname] : undefined
      };
    })
  );
};

// FIXME: Annotationsクラスを作って annotations.add(id, items)とかで追加したい
// そうしないと他のannotationを追加できない
// そのためにはgenerateFusionChartData自体をクラス化する必要がある new FusionChartData()
// メンバ変数にannotationsがあってaddRegressionEquationsとかを実行するとannotationsに追加されていくイメージ
const addAnnotations = (id: string, items: any[]) => {
  return {
    annotations: {
      groups: [
        {
          id,
          items
        }
      ]
    }
  };
};
