import { _get } from 'libs/api/base';
import { RelationshipItemDetail } from 'models/relationship';

export async function getRelationshipItemDetail(params: {
  item_type: string;
  id: string;
  project_id?: string;
}) {
  return _get<RelationshipItemDetail>(`/relationships/item_detail`, { params });
}
