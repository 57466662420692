import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  ScheduleHistory as ScheduleHistoryModel,
  ScheduleHistoryStatus,
  scheduleTriggerToLabel
} from 'models/schedule';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { AutoSizer, GridCellRenderer, MultiGrid } from 'react-virtualized';
import clsx from 'clsx';
import { countColumns, expandRows, LeftIcon, scheduleToRows } from './edit';
import { Row } from 'components/schedule/useSchedule';
import {
  ArrowDropDown,
  ArrowForward,
  ArrowRight,
  Autorenew,
  Cached,
  Cancel,
  CheckCircle,
  Error,
  Feedback,
  PauseCircleFilled,
  RemoveCircle,
  Sync,
  Warning
} from '@material-ui/icons';
import { FaExpandAlt } from 'react-icons/fa';
import { useAsyncRetry, useInterval } from 'react-use';
import { cancelSchedule, getScheduleHistory } from 'libs/api/schedule';
import { blue, green, orange, red } from '@material-ui/core/colors';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { LoadingContent, Spinner } from 'components/LoadingSpinner';
import { secondsToString } from 'libs/time';
import { useDisclosure } from '../../hooks/useDisclosure';
import { Spacer } from 'ui/common/spacer';
import { useHistory } from 'react-router-dom';

dayjs.extend(utc);
dayjs.extend(timezone);

const LEFT_COLUMN_WIDTH = 400;
const COLUMN_WIDTH = 100;
const ROW_HEIGHT = 36;
const GROUP_MARGIN = 4;

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 auto'
  },
  spacer: {
    flex: '1 1 auto'
  },
  header: {
    height: ROW_HEIGHT,
    display: 'flex',
    fontWeight: 600
  },
  headerList: {
    width: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLeft: {
    width: LEFT_COLUMN_WIDTH,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid gray'
  },
  headerRight: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  leftGrid: {
    width: 300,
    height: '100%',
    borderRight: '2px solid silver'
  },
  fullGrid: {
    width: '100%',
    height: '100%',
    borderRight: '2px solid silver'
  },
  tableContainer: {
    height: '100%',
    maxHeight: '100%'
  },
  selected: {
    '&.Mui-selected, &.Mui-selected:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
});

export const ScheduleHistory: React.VFC<{
  histories: ScheduleHistoryModel[];
  fullLoaded: boolean;
  fullLoading: boolean;
  onLoadFull: () => void;
}> = ({ histories, fullLoaded, fullLoading, onLoadFull }) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = React.useState<string | undefined>(
    histories[0]?.id
  );
  const [history, setHistory] = React.useState<ScheduleHistoryModel | null>();
  const { isOpen: expanded, onOpen, onClose } = useDisclosure();
  const historyFetch = useAsyncRetry(async () => {
    if (selectedId == undefined) {
      return null;
    }
    const { data } = await getScheduleHistory(selectedId);
    setHistory(data);
    return data;
  }, [selectedId]);
  React.useEffect(() => {
    setHistory(null);
  }, [selectedId]);
  useInterval(
    historyFetch.retry,
    [ScheduleHistoryStatus.Running, ScheduleHistoryStatus.Pending].includes(
      // @ts-ignore
      historyFetch.value?.status
    )
      ? 10 * 1000
      : null
  );

  return (
    <Grid
      container={true}
      justifyContent="flex-start"
      alignItems="flex-start"
      style={{ flex: '1 1 auto', overflow: 'hidden' }}
    >
      <Grid
        item={true}
        className={clsx(expanded ? classes.fullGrid : classes.leftGrid)}
      >
        <TableContainer className={classes.tableContainer}>
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  実行開始時間
                  {!expanded && (
                    <IconButton
                      style={{ position: 'absolute', right: 8 }}
                      size="small"
                      onClick={expanded ? onClose : onOpen}
                    >
                      <FaExpandAlt fontSize="inherit" />
                    </IconButton>
                  )}
                </TableCell>
                {expanded && (
                  <>
                    <TableCell>終了時間</TableCell>
                    <TableCell>ステータス</TableCell>
                    <TableCell>実行時間</TableCell>
                    <TableCell>
                      実行タイプ
                      <IconButton
                        style={{ position: 'absolute', right: 8 }}
                        size="small"
                        onClick={expanded ? onClose : onOpen}
                      >
                        <FaExpandAlt fontSize="inherit" />
                      </IconButton>
                    </TableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {histories.length === 0 && (
                <TableRow>
                  <TableCell>履歴がありません</TableCell>
                </TableRow>
              )}
              {histories.map((h) => (
                <TableRow
                  key={h.id}
                  hover={true}
                  selected={selectedId === h.id}
                  className={clsx(selectedId === h.id && classes.selected)}
                  onClick={() => setSelectedId(h.id)}
                >
                  <HistorySummaryCell
                    key={h.id}
                    history={h}
                    showCancel={!expanded}
                  />
                  {expanded && <HistoryDetailCells history={h} />}
                </TableRow>
              ))}
              {histories.length >= 10 && !fullLoaded && (
                <TableRow hover={true}>
                  <TableCell
                    colSpan={5}
                    style={{ padding: fullLoading ? 16 : 0 }}
                  >
                    {fullLoading ? (
                      <Spinner fullWidth={true} size={32} />
                    ) : (
                      <Button
                        color="primary"
                        fullWidth={true}
                        style={{ height: 57 }}
                        onClick={onLoadFull}
                      >
                        さらに読み込む
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      {!expanded && (
        <Grid item={true} style={{ flex: '1 1 auto', height: '100%' }}>
          <div className={classes.root}>
            {historyFetch.loading && !history ? (
              <div style={{ width: '100%', height: '100%' }}>
                <LoadingContent />
              </div>
            ) : history ? (
              <>
                <div className={classes.header}>
                  <div className={classes.headerLeft}>実行対象</div>
                  <div className={classes.headerRight}>実行順番</div>
                </div>
                <div className={classes.content}>
                  <HistoryDetail history={history} />
                </div>
              </>
            ) : (
              <Typography style={{ alignSelf: 'center' }}>
                履歴を選択してください
              </Typography>
            )}
          </div>
        </Grid>
      )}
    </Grid>
  );
};

const HistorySummaryCell: React.VFC<{
  history: ScheduleHistoryModel;
  showCancel: boolean;
}> = ({ history, showCancel }) => {
  const cancel = React.useCallback(async () => {
    await cancelSchedule(history.schedule_id);
  }, [history]);
  return (
    <TableCell style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
      <HistoryStatusIcon history={history} />
      <span>
        {history.started_at &&
          dayjs(history.started_at)
            .tz(dayjs.tz.guess())
            .format('YYYY-MM-DD HH:mm:ss')}
        {!history.started_at &&
          history.status === ScheduleHistoryStatus.Pending &&
          'まもなく開始'}
      </span>
      <Spacer />
      {showCancel && history.status === ScheduleHistoryStatus.Running && (
        <Tooltip title="実行中止">
          <IconButton size="small" onClick={cancel}>
            <Cancel />
          </IconButton>
        </Tooltip>
      )}
      {showCancel && history.status === ScheduleHistoryStatus.Failed && (
        <MoveErrorIcon history={history} />
      )}
    </TableCell>
  );
};

const statusStyles = makeStyles({
  success: {
    color: green[500],
    verticalAlign: 'middle'
  },
  failed: {
    color: '#f44336',
    verticalAlign: 'middle'
  },
  running: {
    color: '#1b89a6',
    verticalAlign: 'middle'
  },
  warning: {
    color: orange[500],
    verticalAlign: 'middle'
  }
});

const HistoryStatusIcon: React.FC<{ history: ScheduleHistoryModel }> = ({
  history
}) => {
  const classes = statusStyles();
  switch (history.status) {
    case ScheduleHistoryStatus.Success:
      return <CheckCircle className={classes.success} />;
    case ScheduleHistoryStatus.Failed:
      return <Error className={classes.failed} />;
    case ScheduleHistoryStatus.Running:
    case ScheduleHistoryStatus.Pending:
      return <Cached className={classes.running} />;
    case ScheduleHistoryStatus.Warning:
      return <Warning className={classes.warning} />;
  }
  return null;
};

const HistoryStatus: React.FC<{ history: ScheduleHistoryModel }> = ({
  history
}) => {
  const classes = statusStyles();
  switch (history.status) {
    case ScheduleHistoryStatus.Success:
      return <span className={classes.success}>成功</span>;
    case ScheduleHistoryStatus.Running:
      return <span className={classes.running}>実行中</span>;
    case ScheduleHistoryStatus.Warning:
      return (
        <>
          <span className={classes.warning}>警告</span>
          <Tooltip title="一部のステップでエラーが発生">
            <Warning className={classes.warning} />
          </Tooltip>
        </>
      );
    case ScheduleHistoryStatus.Failed:
      return (
        <>
          <span className={classes.failed}>失敗</span>
          <MoveErrorIcon history={history} />
        </>
      );
  }
  return null;
};

const HistoryDetailCells: React.VFC<{
  history: ScheduleHistoryModel;
}> = ({ history }) => {
  return (
    <>
      <TableCell>
        {history.ended_at
          ? dayjs(history.ended_at)
              .tz(dayjs.tz.guess())
              .format('YYYY-MM-DD HH:mm:ss')
          : '-'}
      </TableCell>
      <TableCell>
        <HistoryStatus history={history} />
      </TableCell>
      <TableCell>
        {secondsToString(
          dayjs(history.ended_at).diff(dayjs(history.started_at), 'second')
        )}
      </TableCell>
      <TableCell>{scheduleTriggerToLabel(history.trigger)}</TableCell>
    </>
  );
};

export const MoveErrorIcon: React.FC<{
  history: Pick<
    ScheduleHistoryModel,
    'error' | 'error_project_id' | 'error_node_id'
  >;
}> = ({ history }) => {
  const router = useHistory();
  const classes = statusStyles();
  const focusNode = React.useCallback(() => {
    router.push(
      `/projects/${history.error_project_id}/nodes/${history.error_node_id}`
    );
  }, [router, history]);
  return (
    <Tooltip title={history.error}>
      {history.error_node_id === '' ? (
        <Feedback className={classes.failed} style={{ marginLeft: 2 }} />
      ) : (
        <IconButton onClick={focusNode} style={{ padding: 0 }}>
          <ArrowForward />
        </IconButton>
      )}
    </Tooltip>
  );
};

const useDetailStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 1 auto'
  },
  spacer: {
    flex: '1 1 auto'
  },
  relationship_button: {
    color: '#1b89a6'
  },
  header: {
    height: ROW_HEIGHT,
    display: 'flex',
    fontWeight: 600
  },
  headerLeft: {
    width: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerRight: {
    display: 'flex',
    flex: '1 1 auto',
    justifyContent: 'center',
    alignItems: 'center'
  },
  headerLeftTop: {
    borderBottom: '2px solid gray'
  },
  leftColumn: {
    borderRight: '1px solid gray'
  },
  indexCell: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '2px solid gray',
    width: COLUMN_WIDTH,
    height: ROW_HEIGHT
  },
  row: {
    display: 'flex'
  },
  relation_row: {
    backgroundColor: '#f2f2f2'
  },
  cell: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRight: '1px solid grey',
    height: ROW_HEIGHT,
    width: COLUMN_WIDTH
  },
  output: {
    width: 24,
    height: 24,
    color: '#fff',
    backgroundColor: '#5099f8',
    transform: 'rotate(90deg)'
  },
  leftCell: {
    justifyContent: 'left',
    borderBottom: '1px solid silver'
  },
  relation_left: {
    backgroundColor: '#f2f2f2',
    paddingLeft: 32
  },
  datasource_left: {
    paddingLeft: 36
  },
  project_left: {
    paddingLeft: 36
  },
  workflow_left: {
    paddingLeft: 64
  },
  report_left: {
    paddingLeft: 36
  },
  nehan_internal_left: {
    paddingLeft: 36
  },
  export_left: {
    paddingLeft: 36
  },
  datasource_row: {},
  project_row: {},
  workflow_row: {},
  arrow: {
    color: '#838383'
  },
  item: {
    height: 16,
    width: 80,
    borderRadius: 8,
    backgroundColor: '#a3a3a3',
    zIndex: 2,
    fontSize: 12,
    color: '#fff',
    textAlign: 'center'
  },
  item_move_left: {
    position: 'absolute',
    color: '#0089a7',
    right: -12,
    zIndex: 3
  },
  item_move_right: {
    position: 'absolute',
    color: '#0089a7',
    left: -12,
    zIndex: 3
  },
  success: {
    backgroundColor: '#80ce5f'
  },
  failed: {
    backgroundColor: red[500]
  },
  skipped: {
    backgroundColor: '#a3a3a3'
  },
  running: {
    animation: '1s fadeAnimation',
    animationIterationCount: 'infinite',
    animationTimingFunction: 'linear',
    animationDuration: '2s'
  },
  '@keyframes fadeAnimation': {
    '0%': { backgroundColor: orange[700] },
    '50%': { backgroundColor: orange[300] },
    '100%': { backgroundColor: orange[700] }
  },
  header_base: {
    position: 'absolute',
    backgroundColor: '#344955',
    height: 14,
    width: 91,
    zIndex: 2
  },
  header_start: {
    right: -1,
    borderRadius: '7px 0 0 7px'
  },
  header_intermediate: {
    left: 0,
    right: 0,
    width: 100
  },
  header_end: {
    left: 0,
    width: 90,
    borderRadius: '0 7px 7px 0'
  },
  header_single: {
    width: 82,
    position: 'relative',
    borderRadius: 7
  },
  group_base: {
    position: 'absolute',
    backgroundColor: '#e9eced',
    zIndex: 1
  },
  group_top_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '8px 0 0 0'
  },
  group_top_middle: {
    left: 0,
    right: 0,
    top: GROUP_MARGIN,
    bottom: 0
  },
  group_top_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '0 8px 0 0'
  },
  group_middle_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: 0,
    bottom: 0
  },
  group_middle_middle: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0
  },
  group_middle_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: 0,
    bottom: 0
  },
  group_bottom_left: {
    left: GROUP_MARGIN,
    right: 0,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 0 8px'
  },
  group_bottom_middle: {
    left: 0,
    right: 0,
    top: 0,
    bottom: GROUP_MARGIN
  },
  group_bottom_right: {
    left: 0,
    right: GROUP_MARGIN,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 8px 0'
  },
  group_top_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: GROUP_MARGIN,
    bottom: 0,
    borderRadius: '8px 8px 0 0'
  },
  group_middle_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: 0,
    bottom: 0
  },
  group_bottom_single: {
    left: GROUP_MARGIN,
    right: GROUP_MARGIN,
    top: 0,
    bottom: GROUP_MARGIN,
    borderRadius: '0 0 8px 8px'
  },
  row_hover: {
    backgroundColor: '#eff4f8'
  }
});

const IndexHeader: React.VFC<{ count: number; style: React.CSSProperties }> = ({
  count,
  style
}) => {
  const classes = useDetailStyles();
  return (
    <div className={clsx(classes.header)} style={style}>
      {[...Array(count)].map((_, i) => (
        <div key={i} className={classes.indexCell}>
          {i + 1}
        </div>
      ))}
    </div>
  );
};

const LeftCell: React.VFC<{
  row: Row;
  style: React.CSSProperties;
  isOpen?: boolean;
  toggleOpen: (id) => void;
  hover: boolean;
  onHover: (hovered: boolean) => void;
}> = ({ row, style, isOpen, toggleOpen, hover, onHover }) => {
  const classes = useDetailStyles();
  const toggle = React.useCallback(() => toggleOpen(row), [toggleOpen, row]);

  return (
    <>
      <div
        className={clsx(
          classes.cell,
          classes.leftColumn,
          classes.leftCell,
          classes[`${row.type}_left`],
          hover && classes.row_hover
        )}
        style={{
          ...style,
          top: 0,
          transform: `translate3d(${style.left}px, ${style.top}px, 0px)`,
          willChange: 'transform',
          transition: '500ms transform'
        }}
        onClick={toggle}
        onMouseEnter={() => onHover(true)}
        onMouseLeave={() => onHover(false)}
      >
        {row.status === ScheduleHistoryStatus.Pending && (
          <PauseCircleFilled
            style={{ color: '#a3a3a3', position: 'absolute', left: 6 }}
          />
        )}
        {row.status === ScheduleHistoryStatus.Success && (
          <CheckCircle
            style={{ color: green[500], position: 'absolute', left: 6 }}
          />
        )}
        {row.status === ScheduleHistoryStatus.Failed && (
          <Error color="error" style={{ position: 'absolute', left: 6 }} />
        )}
        {row.status === ScheduleHistoryStatus.Skipped && (
          <RemoveCircle
            style={{ color: '#a3a3a3', position: 'absolute', left: 6 }}
          />
        )}
        {row.status === ScheduleHistoryStatus.Running && (
          <Sync style={{ color: blue[500], position: 'absolute', left: 6 }} />
        )}
        {row.status === ScheduleHistoryStatus.Warning && (
          <Warning
            style={{ color: orange[500], position: 'absolute', left: 6 }}
          />
        )}
        {row.type === 'relation' && row.data && isOpen && (
          <ArrowDropDown className={classes.arrow} />
        )}
        {row.type === 'relation' && row.data && !isOpen && (
          <ArrowRight className={classes.arrow} />
        )}
        <LeftIcon row={row} />
        {row.name}
      </div>
    </>
  );
};

const MaskGroup: React.VFC<{ row: Row; columnIndex: number }> = ({
  row,
  columnIndex
}) => {
  const classes = useDetailStyles();
  if (
    row.groupContext &&
    row.groupColumnIndex != undefined &&
    row.groupWidth != undefined &&
    columnIndex >= row.groupColumnIndex &&
    columnIndex < row.groupColumnIndex + row.groupWidth
  ) {
    let placement = 'left';
    if (
      columnIndex > row.groupColumnIndex &&
      columnIndex < row.groupColumnIndex + row.groupWidth - 1
    ) {
      placement = 'middle';
    } else if (row.groupColumnIndex + row.groupWidth - 1 === columnIndex) {
      placement = 'right';
    }
    if (row.groupWidth === 1) {
      placement = 'single';
    }

    return (
      <div
        className={clsx(
          classes.group_base,
          classes[`group_${row.groupContext}_${placement}`]
        )}
      />
    );
  }

  return null;
};

const RightCell: React.VFC<{
  row: Row;
  columnCount: number;
  style: React.CSSProperties;
  hover: boolean;
  onHover: (hovered: boolean) => void;
}> = ({ row, columnCount, style, hover, onHover }) => {
  const classes = useDetailStyles();
  const items: React.ReactNode[] = [];

  // fill empty cells
  for (let i = 0; i < row.columnIndex; i++) {
    items.push(
      <div key={i} className={classes.cell}>
        &nbsp;
        <MaskGroup row={row} columnIndex={i} />
      </div>
    );
  }

  for (let i = row.columnIndex; i < row.columnIndex + row.columnWidth; i++) {
    const isHeaderCell = row.data != undefined;

    if (row.isOutput) {
      items.push(
        <div key={i} className={classes.cell}>
          <Tooltip title="ここで更新されます">
            <Avatar className={classes.output}>
              <Autorenew fontSize={'small'} />
            </Avatar>
          </Tooltip>
        </div>
      );
    } else {
      items.push(
        <div key={i} className={classes.cell}>
          <div
            className={clsx(
              row.columnWidth === 1 && classes.item,
              isHeaderCell && classes.header_base,
              // single
              isHeaderCell && row.columnWidth == 1 && classes.header_single,
              // serial
              isHeaderCell &&
                row.columnWidth > 1 &&
                i === row.columnIndex &&
                classes.header_start,
              isHeaderCell &&
                row.columnWidth > 1 &&
                i > row.columnIndex &&
                i < row.columnIndex + row.columnWidth - 1 &&
                classes.header_intermediate,
              isHeaderCell &&
                row.columnWidth > 1 &&
                i === row.columnIndex + row.columnWidth - 1 &&
                classes.header_end,
              row.status === ScheduleHistoryStatus.Success && classes.success,
              row.status === ScheduleHistoryStatus.Running && classes.running,
              row.status === ScheduleHistoryStatus.Failed && classes.failed,
              row.status === ScheduleHistoryStatus.Skipped && classes.skipped
            )}
          >
            {!isHeaderCell &&
              secondsToString(
                dayjs(row.ended_at).diff(dayjs(row.started_at), 'second')
              )}
          </div>
          <MaskGroup row={row} columnIndex={i} />
        </div>
      );
    }
  }

  // fill empty
  for (let i = row.columnIndex + row.columnWidth; i < columnCount; i++) {
    items.push(
      <div key={i} className={classes.cell}>
        &nbsp;
        <MaskGroup row={row} columnIndex={i} />
      </div>
    );
  }

  return (
    <div
      className={clsx(
        classes.row,
        classes[`${row.type}_row`],
        hover && classes.row_hover
      )}
      style={{
        ...style,
        top: 0,
        transform: `translate3d(${style.left}px, ${style.top}px, 0px)`,
        willChange: 'transform',
        transition: '300ms transform'
      }}
      onMouseEnter={() => onHover(true)}
      onMouseLeave={() => onHover(false)}
    >
      {items}
    </div>
  );
};

const HistoryDetail: React.FC<{ history: ScheduleHistoryModel }> = ({
  history
}) => {
  const classes = useDetailStyles();
  const [collapsed, setCollapsed] = React.useState<number[]>([]);
  const rows = React.useMemo(() => {
    if (history == undefined) {
      return [];
    }
    const rows = scheduleToRows(history, history.outputs);
    return expandRows(rows, collapsed);
  }, [history, collapsed]);
  const toggle = React.useCallback(
    (row) => {
      let newCollapsed;
      if (collapsed.indexOf(row.relIndex) !== -1) {
        // 開く
        newCollapsed = collapsed.filter((index) => index !== row.relIndex);
      } else {
        newCollapsed = [row.relIndex].concat(collapsed);
      }
      setCollapsed(newCollapsed);
    },
    [collapsed]
  );
  const gridRef = React.useRef<MultiGrid | null>(null);
  const [hoveredIndex, setHoveredIndex] = React.useState<number>(-1);
  const rowCount = rows.length;
  const columnCount = countColumns(rows);
  React.useEffect(() => {
    if (gridRef.current) {
      gridRef.current.recomputeGridSize();
    }
  }, [columnCount]);

  const cellRenderer: GridCellRenderer = React.useCallback(
    ({ columnIndex, key, rowIndex, style }) => {
      // 左上:空白
      if (columnIndex === 0 && rowIndex === 0) {
        return (
          <div
            key={key}
            style={style}
            className={clsx(classes.leftColumn, classes.headerLeftTop)}
          >
            &nbsp;
          </div>
        );
      }

      // 右上:実行順番
      if (rowIndex === 0) {
        return <IndexHeader count={columnCount} style={style} key={key} />;
      }

      // 左下:リレーション一覧
      const row = rows[rowIndex - 1];
      if (columnIndex === 0) {
        return (
          <LeftCell
            key={`left-cell-${rowIndex}`}
            style={style}
            row={row}
            toggleOpen={toggle}
            isOpen={collapsed.indexOf(row.relIndex) === -1}
            onHover={(hover) => setHoveredIndex(hover ? rowIndex - 1 : -1)}
            hover={hoveredIndex === rowIndex - 1}
          />
        );
      }

      // 右下:実行
      return (
        <RightCell
          key={`right-cell-${rowIndex}`}
          style={style}
          row={row}
          columnCount={columnCount}
          onHover={(hover) => setHoveredIndex(hover ? rowIndex - 1 : -1)}
          hover={hoveredIndex === rowIndex - 1}
        />
      );
    },
    [history, collapsed, columnCount, rows, toggle, hoveredIndex]
  );

  return (
    <AutoSizer>
      {({ width, height }) => (
        <MultiGrid
          ref={(ref) => (gridRef.current = ref)}
          cellRenderer={cellRenderer}
          columnWidth={({ index }) =>
            index === 0 ? LEFT_COLUMN_WIDTH : COLUMN_WIDTH * columnCount
          }
          enableFixedColumnScroll={true}
          columnCount={2}
          fixedColumnCount={1}
          rowHeight={ROW_HEIGHT}
          rowCount={rowCount + 1}
          fixedRowCount={1}
          height={height}
          width={width}
          hideTopRightGridScrollbar={true}
          hideBottomLeftGridScrollbar={true}
          styleTopRightGrid={{
            borderBottom: '2px solid grey'
          }}
        />
      )}
    </AutoSizer>
  );
};
