import * as React from 'react';
import {
  accessLevelList,
  CheckboxForm,
  SelectForm,
  TextForm
} from 'ui/listView/filter/form';
import { FilterType } from 'ui/listView/filter/type';
import {
  handleChangeAccessLevels,
  handleChangeCustomDateCondition,
  handleChangeCustomDateRange,
  handleChangeExcludeFolders,
  handleChangeIsScheduled,
  handleChangeScheduleName,
  handleCloseCalendar
} from 'ui/listView/filter/handler';
import { FilterContext } from 'ui/listView/filter/context';
import {
  Calendar,
  CustomDate,
  getDateConditionValue
} from 'ui/listView/filter/customDate';
import { CreatorRow } from './row/creatorRow';

export const ScheduleFilter: React.FC = () => {
  const {
    state: filterState,
    dispatch: filterDispatch,
    isAdminPage,
    emails
  } = React.useContext(FilterContext);
  const calendarForCreateOpen = Boolean(
    filterState[FilterType.CalendarPositionForCreation]
  );
  const calendarForUpdateOpen = Boolean(
    filterState[FilterType.CalendarPositionForUpdate]
  );

  const refForCalendar = React.useRef<HTMLTableElement | null>(null);

  return (
    <>
      <table ref={refForCalendar}>
        <tbody>
          <TextForm
            title={'スケジュール名（含む）'}
            value={filterState[FilterType.ScheduleName]}
            onChange={handleChangeScheduleName(filterState, filterDispatch)}
          />
          <CheckboxForm
            title="次回実行時間がある"
            value={filterState[FilterType.IsScheduled]}
            onChange={handleChangeIsScheduled(filterState, filterDispatch)}
          />
          <CustomDate
            title={'作成日時'}
            id={'created-select'}
            value={getDateConditionValue(
              filterState[FilterType.CreateCondition],
              filterState[FilterType.CreateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForCreation,
              FilterType.CreateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          <CustomDate
            title={'更新日時'}
            id={'created-select'}
            value={getDateConditionValue(
              filterState[FilterType.UpdateCondition],
              filterState[FilterType.UpdateRange]
            )}
            onChange={handleChangeCustomDateCondition(
              FilterType.CalendarPositionForUpdate,
              FilterType.UpdateCondition,
              refForCalendar,
              filterState,
              filterDispatch
            )}
          />
          {!isAdminPage && (
            <SelectForm
              title={'自分の権限'}
              labels={accessLevelList}
              values={filterState[FilterType.AccessLevels]}
              onChange={handleChangeAccessLevels(filterState, filterDispatch)}
            />
          )}
          <CreatorRow emails={emails} />
          <CheckboxForm
            title="フォルダを表示しない"
            value={filterState[FilterType.ExcludeFolders]}
            onChange={handleChangeExcludeFolders(filterState, filterDispatch)}
          />
        </tbody>
      </table>
      <Calendar
        id={'create-calendar'}
        title="作成日時"
        open={calendarForCreateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForCreation]}
        ranges={filterState[FilterType.CreateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.CreateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForCreation,
          filterState,
          filterDispatch
        )}
      />
      <Calendar
        id={'update-calendar'}
        title="更新日時"
        open={calendarForUpdateOpen}
        anchorEl={filterState[FilterType.CalendarPositionForUpdate]}
        ranges={filterState[FilterType.UpdateRange]}
        onChange={handleChangeCustomDateRange(
          FilterType.UpdateRange,
          filterState,
          filterDispatch
        )}
        onClose={handleCloseCalendar(
          FilterType.CalendarPositionForUpdate,
          filterState,
          filterDispatch
        )}
      />
    </>
  );
};
