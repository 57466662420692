import {
  _delete,
  _get,
  _getWithAccessTokenV2,
  _post,
  _put
} from 'libs/api/base';
import type { Datasource } from 'reducers/datasource';
import { ConnectionSchema } from 'models/connection';
import { Dtypes } from 'Utils/dataTypes';
import { SummaryInfo } from 'models/data';
import { UsableDatasource } from 'libs/datasources';
import { Path } from 'models/folder';
import { Variable } from 'models/project';
import { DatasourceStatus } from 'models/datasource';
import { QueryParams } from 'ui/listViewBase';

export interface DatasourceParams {
  name: string;
  type: string;
  params: any;
  connectionId?: number;
  connection?: any;
}

interface NehanDatasourceParams extends DatasourceParams {
  type: 'nehan_storage';
  nodeId: string;
  portId: string;
  projectId: string;
}

export interface DataResponseBody {
  columns: string[];
  display_table: boolean;
  dtypes: Dtypes[];
  is_down_sampling: boolean;
  num_cols: number;
  num_rows: number;
  summary: {
    [k: string]: SummaryInfo;
  };
  encoding: string;
}

export interface DataResponseBodyV2 extends DataResponseBody {
  rows: Array<Array<string | number | null>>;
}

export interface DatasourceList {
  test_data: Datasource[];
  user_data: Datasource[];
  nehan_storage: Datasource[];
}

export interface DatasourceListResponse {
  items: DatasourceList;
  paths: Path[];
  emails?: string[];
  types?: string[];
}

export function addDatasource(data: DatasourceParams | NehanDatasourceParams) {
  return _post('/datasources', data);
}

export function updateDatasource(
  id: number,
  data: DatasourceParams | NehanDatasourceParams
) {
  return _put(`/datasources/${id}`, data);
}

export async function getDatasources(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get<DatasourceListResponse>('/datasources', {
    params: { folderId, ...queryParams }
  });
}

export async function getUserDatasources(
  folderId?: string,
  queryParams?: QueryParams
) {
  const { data } = await getDatasources(folderId, queryParams);
  return {
    data: {
      items: data?.items?.user_data,
      paths: data?.paths,
      emails: data?.emails,
      types: data?.types
    }
  };
}

export async function getProjectDatasources(
  projectId: string,
  selectedId: number
) {
  if (selectedId <= 0) {
    return _get<DatasourceListResponse>(`/datasources`, {
      params: { projectId }
    });
  }
  return _get<DatasourceListResponse>(`/datasources`, {
    params: { projectId, selectedId }
  });
}

export async function getProjectDatasourceByFolder(
  projectId: string,
  folderId: string
) {
  return _get<DatasourceListResponse>(`/datasources`, {
    params: { projectId, folderId }
  });
}

export async function getDatasource(uuid: string | number) {
  return _get<Datasource>(`/datasources/${uuid}`);
}

export async function getDatasourceForNode(uuid: string | number) {
  return _get<Datasource>(`/datasources/${uuid}`, {
    params: {
      node_info: 1
    }
  });
}

export function deleteDatasource(uuid: string) {
  return _delete(`/datasources/${uuid}`);
}

interface readDatasourceType {
  type: string;
  params: any;
  connection?: ConnectionSchema;
  datasourceHashId?: string;
  portId?: string;
  variables?: Variable[];
  datasourceId?: number;
  isSql?: boolean;
  projectId?: string;
  workflowId?: number;
}

export async function readDatasourceV3(params: readDatasourceType) {
  return _post(`/read_datasource`, {
    ...params
  });
}

export async function getTablePreview(params: {
  type: string;
  params: any;
  connection: ConnectionSchema;
}) {
  return _post(`/get_table_data`, {
    ...params
  });
}

export async function cancelReadTemporary(
  projectId: string,
  workflowId: number
) {
  return _post(`/datasources/cancel_read_temporary`, { projectId, workflowId });
}

export async function cancelSql(datasourceId) {
  return _post(`/datasources/cancel_db_executor`, {
    datasourceId
  });
}

export async function fetchReadTemporaryData(id: string) {
  return _getWithAccessTokenV2(`/datasources/fetch_read_temporary_data/${id}`);
}

export async function deleteDatasourceMember(
  uuid: string,
  id: string,
  is_group: boolean
) {
  return _delete(`/datasources/${uuid}/members`, { id, is_group });
}

export async function getUploadSession() {
  return _post<{ session: string }>(`/datasources/upload_session`, null);
}

export async function getUploadUrl(session: string, filename: string) {
  return _post<{ url: string }>(`/datasources/upload_url`, {
    session,
    filename
  });
}

export async function newDatasource(
  folder_id: string | null,
  name: string,
  type: string,
  params: any,
  hashId: string,
  spark: boolean,
  async: boolean,
  connection?: ConnectionSchema,
  localVariables?: Variable[],
  importedVariableUuids?: string[]
) {
  return _post<Datasource>(`/datasources_new`, {
    folder_id,
    name,
    type,
    params,
    hashId,
    spark,
    async,
    connection,
    localVariables,
    importedVariableUuids
  });
}

interface addDatasourceV3Type {
  folder_id: string | null;
  name: string;
  type: string;
  params: any;
  hashId: string;
  spark: boolean;
  async: boolean;
  connection?: ConnectionSchema;
  localVariables?: Variable[];
  importedVariableUuids?: string[];
  status?: DatasourceStatus;
}

interface readAndSaveDatasourceV3TypeSave {
  name: string;
  type: string;
  // 読み込んで保存の時は, hashId,sparkはAPI内で渡されるので不要
  params?: any; // TODO: paramsも自動的に設定されるので不要になる予定
  // hashId?: string;
  // spark?: boolean;
  async: boolean;
  localVariables?: Variable[];
  importedVariableUuids?: string[];
}

export async function addDatasourceV3(params: addDatasourceV3Type) {
  return _post<Datasource>(`/datasources_v3`, { ...params });
}

interface readAndSaveDatasourceV3Type {
  read: readDatasourceType;
  save: readAndSaveDatasourceV3TypeSave;
}

export async function readAndSaveDatasourceV3(
  params: readAndSaveDatasourceV3Type
) {
  return _post<Datasource>(`/read_and_save_datasource`, { ...params });
}

export async function updateDatasourceV2(
  uuid: string,
  name: string,
  type: string,
  params: any,
  hashId: string,
  spark: boolean,
  async: boolean,
  connection?: ConnectionSchema,
  connectionId?: number
) {
  return _put(`/datasources_new/${uuid}`, {
    name,
    type,
    params,
    hashId,
    spark,
    async,
    connection,
    connectionId
  });
}

export async function updateDatasourceProps(
  uuid: string,
  props: { name?: string; params?: any; connectionId?: number }
) {
  return _put(`/datasources/${uuid}/props`, { ...props });
}

export function duplicateDatasource(uuid: string, folderId?: string | null) {
  return _post(`/datasources/${uuid}/duplicate`, undefined, {
    params: { folderId }
  });
}

export async function cancelDatasource(uuid: string) {
  return _post(`/datasources/${uuid}/cancel`, {});
}

export async function getTableResources(
  type: string,
  connection: ConnectionSchema,
  res: { [k: string]: string },
  require: string
) {
  return _post<{ [k: string]: string[] }>(`/table_resources`, {
    type,
    connection,
    res,
    require
  });
}

export async function getAdminDatasources(
  folderId?: string,
  queryParams?: QueryParams
) {
  return _get('/admin/datasources', {
    params: { folderId, ...queryParams }
  });
}

export function getUsableDatasources() {
  return _get<{ usableDatasources: UsableDatasource[] }>(
    `/organizations/usable_datasources`
  );
}

export async function updateDatasourceVariables(
  uuid: string,
  variables: Variable[]
) {
  return _put<Variable[]>(`/datasources/${uuid}/variables`, { variables });
}

export async function getDatasourcesRelationships(id: string) {
  return _get(`/datasources/${id}/relationships`);
}

export async function getNehanInternalDatasourceByNodeId(
  uuid: string,
  node_id: string
) {
  return _get<(Datasource & { parent_folder_uuid: string }) | null>(
    `/projects/${uuid}/nodes/${node_id}/nehan_internal_datasource`
  );
}

export async function getBuilderExportDatasourceByNodeId(
  uuid: string,
  node_id: string
) {
  return _get<(Datasource & { parent_folder_uuid: string }) | null>(
    `/builders/${uuid}/nodes/${node_id}/export_datasource`
  );
}
