import * as React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import {
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Tooltip
} from '@material-ui/core';
import {
  ExecutionInterval,
  ExecutionTiming,
  SchedulingConfig
} from 'models/schedule';
import { nehanColor } from '../../theme';
import { Settings } from '@material-ui/icons';
import { Dialog } from 'components/ui/common/dialog';
import clsx from 'clsx';
import { SelectInputProps } from '@material-ui/core/Select/SelectInput';
import { Switch } from 'ui/common/switch';

enum Weekday {
  Sun = 1,
  Mon = 2,
  Tue = 4,
  Wed = 8,
  Thu = 16,
  Fri = 32,
  Sat = 64
}

const WeekdayMap = {
  Sun: Weekday.Sun,
  Mon: Weekday.Mon,
  Tue: Weekday.Tue,
  Wed: Weekday.Wed,
  Thu: Weekday.Thu,
  Fri: Weekday.Fri,
  Sat: Weekday.Sat
};
const Weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const WeekdayLabels = {
  Sun: '日',
  Mon: '月',
  Tue: '火',
  Wed: '水',
  Thu: '木',
  Fri: '金',
  Sat: '土'
};

const IntervalLabel = {
  '15m': '15分ごとに実行',
  '30m': '30分ごとに実行',
  '1h': '1時間ごとに実行',
  '3h': '3時間ごとに実行',
  '6h': '6時間ごとに実行',
  '12h': '12時間ごとに実行'
};

// 立っているbitの数を数える
// https://stackoverflow.com/questions/43122082/efficiently-count-the-number-of-bits-in-an-integer-in-javascript
function bitCount(n: number): number {
  n = n - ((n >> 1) & 0x55555555);
  n = (n & 0x33333333) + ((n >> 2) & 0x33333333);
  return (((n + (n >> 4)) & 0xf0f0f0f) * 0x1010101) >> 24;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: `2px currentColor solid`,
    borderRadius: '3px',
    color: nehanColor,
    fontWeight: 600
  },
  disabled: {
    color: 'gray'
  },
  button: {
    color: nehanColor
  }
});

const Checkbox = withStyles({
  root: {
    color: nehanColor,
    '&$checked': {
      color: nehanColor
    }
  },
  checked: {}
})((props: CheckboxProps) => <MuiCheckbox color="default" {...props} />);

export function getSchedulingConfigLabel(config: SchedulingConfig): string {
  if (config.timing === 'none') {
    return '実行予定なし';
  }

  if (config.timing === 'hourly') {
    return IntervalLabel[config.interval];
  }

  if (config.timing === 'daily') {
    return `毎日${config.time}に実行`;
  }

  if (config.timing === 'weekly') {
    const count = bitCount(config.weekdays);
    if (count > 1) {
      return `毎週${count}回実行`;
    } else {
      let wday = 0;
      for (let i = 0; i < 7; i++) {
        const bit = Math.pow(2, i);
        if (config.weekdays & bit) {
          wday = i;
          break;
        }
      }
      return `毎週${WeekdayLabels[Weekdays[wday]]}曜日に実行`;
    }
  }

  if (config.timing === 'monthly') {
    const count = bitCount(config.dates);
    if (count > 1) {
      return `毎月${count}回実行`;
    } else {
      let date = 0;
      for (let i = 0; i < 31; i++) {
        const dateBit = Math.pow(2, i);
        if (config.dates & dateBit) {
          date = i + 1;
          break;
        }
      }
      return `毎月${date}日に実行`;
    }
  }

  return '実行予定なし';
}

export const SchedulingConfigButton: React.FC<{
  disabled?: boolean;
  config: SchedulingConfig;
  onChange: (config: SchedulingConfig) => void;
}> = ({ disabled, config, onChange }) => {
  const classes = useStyles();
  const [showSchedulingDialog, setShowSchedulingDialog] = React.useState(false);

  return (
    <>
      <div className={clsx(classes.root, !config.enabled && classes.disabled)}>
        <Tooltip title={disabled ? 'スケジュール実行中は操作できません' : ''}>
          <span>
            <Switch
              disabled={config.timing === 'none' || disabled}
              checked={config.enabled && config.timing !== 'none'}
              onChange={(_, checked) =>
                onChange({ ...config, enabled: checked })
              }
            />
          </span>
        </Tooltip>
        {getSchedulingConfigLabel(config)}
        <Tooltip title={disabled ? 'スケジュール実行中は操作できません' : ''}>
          <span>
            <IconButton
              className={classes.button}
              disabled={disabled}
              onClick={() => setShowSchedulingDialog(true)}
            >
              <Settings fontSize="small" />
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <SchedulingDialog
        open={showSchedulingDialog}
        initial={config}
        onCancel={() => setShowSchedulingDialog(false)}
        onClose={(newConf) => {
          onChange({ ...config, ...newConf });
          setShowSchedulingDialog(false);
        }}
      />
    </>
  );
};

const SchedulingDialog: React.VFC<{
  open: boolean;
  initial?: SchedulingConfig;
  onCancel: () => void;
  onClose: (config: Omit<SchedulingConfig, 'enabled'>) => void;
}> = ({ open, initial, onCancel, onClose }) => {
  const [timing, setTiming] = React.useState<ExecutionTiming>(
    initial?.timing || 'daily'
  );
  const [weekdays, setWeekdays] = React.useState(initial?.weekdays || 0);
  const checkWeekday = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const day = WeekdayMap[event.target.name];
    if (!day) {
      return;
    }
    if (checked) {
      setWeekdays(weekdays | day);
    } else {
      setWeekdays(weekdays & ~day);
    }
  };
  const [dates, setDates] = React.useState(initial?.dates || 0);
  const checkDate = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const date = Number(event.target.name);
    if (date < 0 || date > 31) {
      return;
    }
    const dateBit = Math.pow(2, date - 1);
    if (checked) {
      setDates(dates | dateBit);
    } else {
      setDates(dates & ~dateBit);
    }
  };
  const [execTime, setExecTime] = React.useState(initial?.time || '10:00');
  const [interval, setInterval] = React.useState(initial?.interval || '15m');
  React.useEffect(() => {
    if (open) {
      setTiming(initial?.timing || 'daily');
      setWeekdays(initial?.weekdays || 0);
      setDates(initial?.dates || 0);
      setExecTime(initial?.time || '10:00');
      setInterval(initial?.interval || '15m');
    }
  }, [initial, open]);

  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="md"
      onClose={onCancel}
      title="スケジュール設定"
      OKButton={{
        onClick: () =>
          onClose({ interval, timing, weekdays, dates, time: execTime })
      }}
    >
      <div style={{ width: '100%', display: 'flex', gap: 8 }}>
        <div
          style={{
            width: 120,
            minWidth: 120,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          実行タイミング
        </div>
        <div style={{ flex: '1 1 auto', display: 'flex' }}>
          <FormControl>
            <RadioGroup
              row={true}
              value={timing}
              onChange={(_, t: ExecutionTiming) => setTiming(t)}
            >
              <FormControlLabel
                value="none"
                control={<Radio color="primary" size="small" />}
                label="なし"
              />
              <FormControlLabel
                value="hourly"
                control={<Radio color="primary" size="small" />}
                label="毎時実行"
              />
              <FormControlLabel
                value="daily"
                control={<Radio color="primary" size="small" />}
                label="毎日実行"
              />
              <FormControlLabel
                value="weekly"
                control={<Radio color="primary" size="small" />}
                label="毎週実行"
              />
              <FormControlLabel
                value="monthly"
                control={<Radio color="primary" size="small" />}
                label="毎月実行"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      {timing === 'hourly' && (
        <div
          style={{ width: '100%', display: 'flex', gap: 8, margin: '24px 0' }}
        >
          <div
            style={{
              width: 120,
              minWidth: 120,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            実行周期
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <Select
              native={true}
              value={interval}
              onChange={(ev) =>
                setInterval(ev.target.value as ExecutionInterval)
              }
            >
              {['15m', '30m', '1h', '3h', '6h', '12h'].map((key) => {
                const label = IntervalLabel[key];
                return (
                  <option value={key} key={key}>
                    {label}
                  </option>
                );
              })}
            </Select>
          </div>
        </div>
      )}
      {timing === 'weekly' && (
        <div
          style={{ width: '100%', display: 'flex', gap: 8, margin: '24px 0' }}
        >
          <div
            style={{
              width: 120,
              minWidth: 120,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            実行曜日
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <FormGroup row={true}>
              {Weekdays.map((w) => {
                return (
                  <FormControlLabel
                    key={w}
                    control={
                      <Checkbox
                        checked={(weekdays & WeekdayMap[w]) > 0}
                        onChange={checkWeekday}
                        name={w}
                      />
                    }
                    label={WeekdayLabels[w]}
                  />
                );
              })}
            </FormGroup>
          </div>
        </div>
      )}
      {timing === 'monthly' && (
        <div
          style={{ width: '100%', display: 'flex', gap: 8, margin: '24px 0' }}
        >
          <div
            style={{
              width: 120,
              minWidth: 120,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            実行日
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <FormGroup row={true}>
              {Array(31)
                .fill(0)
                .map((_, i) => {
                  const dateBit = Math.pow(2, i);
                  return (
                    <FormControlLabel
                      style={{ width: '12%' }}
                      key={i + 1}
                      control={
                        <Checkbox
                          checked={(dates & dateBit) > 0}
                          onChange={checkDate}
                          name={(i + 1).toString()}
                        />
                      }
                      label={`${i + 1}日`}
                    />
                  );
                })}
            </FormGroup>
          </div>
        </div>
      )}
      {['daily', 'weekly', 'monthly'].includes(timing) && (
        <div
          style={{ width: '100%', display: 'flex', gap: 8, margin: '24px 0' }}
        >
          <div
            style={{
              width: 120,
              minWidth: 120,
              display: 'flex',
              alignItems: 'center'
            }}
          >
            実行時間
          </div>
          <div style={{ flex: '1 1 auto' }}>
            <TimePicker time={execTime} onChange={setExecTime} />
          </div>
        </div>
      )}
    </Dialog>
  );
};

const timePickerStyle = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'baseline',
    gap: 2
  }
});

const TimePicker: React.FC<{
  time: string;
  onChange: (newTime: string) => void;
}> = ({ time, onChange }) => {
  const classes = timePickerStyle();
  const hour = time.split(':')[0] ?? '09';
  const min = time.split(':')[1] ?? '00';
  const handleChange: SelectInputProps['onChange'] = React.useCallback(
    (ev) => {
      if (ev.target.name === 'hour') {
        const min = time.split(':')[1] ?? '00';
        onChange(ev.target.value + ':' + min);
      } else if (ev.target.name === 'min') {
        const hour = time.split(':')[0] ?? '00';
        onChange(hour + ':' + ev.target.value);
      }
    },
    [time, onChange]
  );

  return (
    <div className={classes.root}>
      <FormControl>
        <InputLabel>時</InputLabel>
        <Select
          native={true}
          value={hour}
          onChange={handleChange}
          inputProps={{
            name: 'hour'
          }}
        >
          {[...Array(24)].map((_, i) => {
            const label = ('00' + i).slice(-2);
            return (
              <option value={label} key={i}>
                {label}
              </option>
            );
          })}
        </Select>
      </FormControl>
      :
      <FormControl>
        <InputLabel>分</InputLabel>
        <Select
          native={true}
          value={min}
          onChange={handleChange}
          inputProps={{
            name: 'min'
          }}
        >
          {[...Array(60)].map((_, i) => {
            const label = ('00' + i).slice(-2);
            return (
              <option value={label} key={i}>
                {label}
              </option>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};
