import * as Comlink from 'comlink';
import { db, getDataMetadata } from '../database';
import {
  getPortData,
  getPortDataWithoutToken,
  getPortDataWithSharedToken
} from 'libs/callApi';

export enum DataFetchWorkerResponseTypes {
  Success,
  Failure,
  NotModified
}

interface DataFetchWorkerResponseSuccess {
  type: DataFetchWorkerResponseTypes.Success;
  executionId: string;
}

interface DataFetchWorkerResponseFailure {
  type: DataFetchWorkerResponseTypes.Failure;
  error: string;
}

interface DataFetchWorkerResponseNotModified {
  type: DataFetchWorkerResponseTypes.NotModified;
}

export type DataFetchWorkerResponse =
  | DataFetchWorkerResponseSuccess
  | DataFetchWorkerResponseFailure
  | DataFetchWorkerResponseNotModified;

export class DataFetchWorker {
  async fetchData(
    projectId: string,
    portId: string,
    isSharedToken: boolean,
    lastModified?: string,
    token?: string
  ): Promise<DataFetchWorkerResponse> {
    try {
      let resp;
      if (isSharedToken && token) {
        resp = await getPortDataWithSharedToken(
          token,
          projectId,
          portId,
          lastModified
        );
      } else if (token) {
        resp = await getPortData(token, projectId, portId, lastModified);
      } else {
        resp = await getPortDataWithoutToken(projectId, portId, lastModified);
      }
      const data = resp.data;
      const headers = resp.headers;
      const metadata = getDataMetadata(data);

      await db.refreshPortData(
        projectId,
        metadata.id,
        metadata.execution_id,
        data,
        headers['last-modified']
      );

      return {
        type: DataFetchWorkerResponseTypes.Success,
        executionId: metadata.execution_id
      };
    } catch (e) {
      if (e.response?.status === 304) {
        return {
          type: DataFetchWorkerResponseTypes.NotModified
        };
      }
      return {
        type: DataFetchWorkerResponseTypes.Failure,
        error: 'データのダウンロードに失敗しました。'
      };
    }
  }
}

Comlink.expose(DataFetchWorker, self);
