import * as React from 'react';

export function IconUnknown() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 17 17"
    >
      <g fill="none" fillRule="evenodd">
        <circle cx="8.5" cy="8.5" r="8.5" fill="gray" />
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M9.298 10.636v-.21c0-.355.054-.644.161-.868.107-.224.282-.471.525-.742l.56-.63c.336-.392.567-.756.693-1.092.126-.336.189-.7.189-1.092 0-.821-.245-1.477-.735-1.967S9.461 3.3 8.472 3.3c-.457 0-.861.072-1.211.217-.35.145-.64.343-.868.595-.229.252-.401.555-.518.91-.117.355-.175.742-.175 1.162v.322h1.848v-.35c0-.457.077-.828.231-1.113.154-.285.408-.427.763-.427.317 0 .555.11.714.329.159.22.238.506.238.861 0 .28-.04.544-.119.791-.08.247-.217.478-.413.693l-.532.602c-.317.355-.541.693-.672 1.015-.13.322-.196.716-.196 1.183v.546h1.736zm.098 2.772V11.56H7.464v1.848h1.932z"
        />
      </g>
    </svg>
  );
}
