import * as React from 'react';
import { withStyles, WithStyles, createStyles } from '@material-ui/core/styles';

import LinkButton from 'ui/linkButton';
import { categories, UsableDatasource } from 'libs/datasources';
import { isAvailable } from 'components/datasource/newDatasourceList';
import { getUsableDatasources } from 'libs/api';
import { AuthContext } from 'components/route';
import { onedriveUrl } from './oauth/onedrive';
import { ConnectionType } from 'models/connection';

const styles = createStyles({
  root: {
    width: '100%'
  },
  listItem: {
    width: '300px',
    marginRight: '51px',
    // '&:nth-child(3n)': {
    //   marginRight: 0
    // },
    // '&:nth-child(-n+3)': {
    //   borderTop: 'solid 1px rgba(0, 0, 0, 0.23)'
    // },
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.23)'
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '83px'
  },
  title: {
    marginBottom: '10px',
    height: '29px',
    fontSize: '20px',
    fontWeight: 500,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    color: '#525252'
  }
});

export const removeDsKeyVersion = (dsKey: string): string => {
  return dsKey.replace(/_v[\d]+/g, '');
};

const dsKeyToConnType = (dsKey: string): string => {
  return removeDsKeyVersion(dsKey);
};

export function createConnectionLink(
  key: string,
  usableDatasources: UsableDatasource[]
): string {
  const conn = usableDatasources.find((d) => d.key === key);
  if (conn == undefined) {
    return '';
  }

  const connKey = dsKeyToConnType(key);

  return `/connections/new/${connKey}`;
}

export interface NewConnectionListProps {
  searchText: string;
}

const noConnectionTypes = ['csv', 'json', 'mse'];

const linkActions: { [k in ConnectionType]?: () => void } = {
  onedrive: () => {
    window.location.href = onedriveUrl;
  }
};

const NewConnectionList: React.FC<
  NewConnectionListProps & WithStyles<typeof styles>
> = ({ classes, searchText }) => {
  const { user } = React.useContext(AuthContext);
  const [usableDatasources, setUsableDatasources] = React.useState<
    UsableDatasource[]
  >([]);
  React.useEffect(() => {
    const load = async () => {
      const { data } = await getUsableDatasources();
      setUsableDatasources(data.usableDatasources);
    };
    load();
  }, []);
  return (
    <div className={classes.root}>
      {categories
        .sort((a, b) => a.order - b.order)
        .map((c) => {
          return (
            <div key={c.category}>
              <div className={classes.title}>{c.title}</div>
              <div className={classes.list}>
                {usableDatasources
                  .filter(
                    (d) =>
                      !noConnectionTypes.includes(dsKeyToConnType(d.key)) &&
                      d.category === c.category &&
                      d.name
                        .toLocaleLowerCase()
                        .indexOf(searchText.toLocaleLowerCase()) !== -1
                  )
                  .sort((a, b) => a.order - b.order)
                  .map((ds) => {
                    return (
                      <div className={classes.listItem} key={ds.key}>
                        <LinkButton
                          data-cy={`conn-link-${ds.name}`}
                          logo={ds.logo}
                          name={ds.name}
                          link={
                            !Object.keys(linkActions).includes(ds.key)
                              ? createConnectionLink(ds.key, usableDatasources)
                              : undefined
                          }
                          onClick={() => {
                            if (!ds.available) {
                              alert(
                                '利用を希望される場合はお問い合わせください'
                              );
                              return;
                            }
                            Object.keys(linkActions).includes(ds.key) &&
                              linkActions[ds.key]?.();
                          }}
                          available={isAvailable(user, ds)}
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default withStyles(styles)(NewConnectionList);
