import React from 'react';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers';
import DateUtils from '@date-io/dayjs';
import makeStyles from '@material-ui/styles/makeStyles';

const styles = makeStyles({
  timeButton: {
    display: 'none'
  }
});

export const DatePicker: React.FC<
  {
    date: string; // YYYY-MM-DD
    setDate: (date: string) => void;
  } & Partial<KeyboardDatePickerProps>
> = ({ date, setDate, ...props }) => {
  const classes = styles();
  return (
    <MuiPickersUtilsProvider utils={DateUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="YYYY-MM-DD"
        margin="normal"
        value={date}
        onChange={(date) => {
          if (!date) return;
          setDate(date.format('YYYY-MM-DD'));
        }}
        KeyboardButtonProps={{
          className: classes.timeButton
        }}
        InputProps={{ disableUnderline: true }}
        helperText={null}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};
