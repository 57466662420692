import * as React from 'react';
import { NavigateBefore, NavigateNext } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Button, ButtonProps, SvgIconProps } from '@material-ui/core';
import clsx from 'clsx';
import { CustomTheme, NehanColors } from '../../theme';

const navButtonStyles = makeStyles((theme: CustomTheme) => ({
  nehan: {
    color: theme.palette?.nehan?.contrastText,
    backgroundColor: theme.palette?.nehan?.main,
    '&:hover': {
      backgroundColor: theme.palette?.nehan?.dark
    }
  },
  common_color: {
    color: theme.palette?.common_color?.contrastText,
    backgroundColor: theme.palette?.common_color?.main,
    '&:hover': {
      backgroundColor: theme.palette?.common_color?.dark
    }
  }
}));

const navArrowtyles = makeStyles((theme: CustomTheme) => ({
  nehan: {
    color: theme.palette?.nehan?.main,
    '&:hover': {
      color: theme.palette?.nehan?.dark
    }
  }
}));

export const NavButton: React.FC<
  Omit<ButtonProps, 'color'> & {
    color?: NehanColors;
    arrowDirection?: 'right' | 'left' | 'none';
  }
> = ({ arrowDirection = 'none', children, color, ...props }) => {
  const classes = navButtonStyles();
  return (
    <Button
      data-cy={
        typeof children === 'string' && props['data-cy'] == undefined
          ? children
          : props['data-cy']
      }
      {...props}
      className={clsx(props.className, classes[color ?? ''])}
    >
      {arrowDirection === 'right' && (
        <>
          {children}
          <NavigateNext style={{ marginRight: -5 }} />
        </>
      )}
      {arrowDirection === 'left' && (
        <>
          <NavigateBefore style={{ marginLeft: -5 }} />
          {children}
        </>
      )}
      {arrowDirection === 'none' && children}
    </Button>
  );
};

export const NavArrow: React.FC<
  Omit<SvgIconProps, 'color'> & {
    color?:
      | 'inherit'
      | 'primary'
      | 'secondary'
      | 'action'
      | 'disabled'
      | 'error'
      | 'nehan';
    arrowDirection?: 'right' | 'left';
  }
> = ({ arrowDirection = 'right', color, ...props }) => {
  const classes = navArrowtyles();
  const c = color !== 'nehan' ? color : undefined;
  const Icon = arrowDirection === 'right' ? NavigateNext : NavigateBefore;
  return (
    <Icon
      color={c}
      {...props}
      className={clsx(props.className, {
        [classes.nehan]: color === 'nehan'
      })}
    />
  );
};
