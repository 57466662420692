import * as React from 'react';

import { FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { RadioGroupProps } from '@material-ui/core/RadioGroup';

import { StyleRules, withStyles, WithStyles } from '@material-ui/core/styles';
import { validate } from './validate';
import { RadioFieldSchema } from 'models/form/schema';
import { ChangeFieldHandler } from 'components/form/schemaFieldBase';

const styles: StyleRules = {
  label: {
    color: 'rgba(0, 0, 0, 0.54)'
  }
};

interface RadioFieldProps extends RadioGroupProps {
  schema: RadioFieldSchema;
  onChangeField: ChangeFieldHandler;
}

const radioField: React.SFC<
  RadioFieldProps & WithStyles<keyof typeof styles>
> = ({ schema, onChangeField, classes, ...props }) => {
  return (
    <RadioGroup
      onChange={(_, value) =>
        onChangeField(schema.key, value, validate(value, schema.validate))
      }
      {...props}
    >
      {schema.items.map((item) => (
        <FormControlLabel
          classes={{ label: classes.label }}
          key={item.value}
          control={<Radio disableRipple={true} />}
          label={item.label}
          value={item.value}
        />
      ))}
    </RadioGroup>
  );
};

export default withStyles(styles)(radioField);
