import * as React from 'react';
import { ListItem, ReportItem } from '../../../models/dependency';
import { TableCell, Tooltip } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { AccessLevelString } from 'libs/accessLevel';
import { Error, Folder } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Checkbox,
  LinkCell,
  OpenMenuType,
  OpenShareDialogType,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';
import {
  ReportStatus,
  ReportStatusColor,
  ReportStatusText
} from 'models/report';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const ReportRow: React.FC<{
  item: ReportItem;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({
  item,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  onCheckChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  clearForm
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        clearForm(item.uuid, item.is_folder);
        onClickItem(item);
      }, [basePath, clearForm, item, onClickItem])
    : React.useCallback(() => {
        clearForm(item.uuid, item.is_folder, `${basePath}/f/${item.uuid}`);
      }, [basePath, clearForm, item]);

  if (item.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        <LinkCell
          href={`${basePath}/f/${item.uuid}`}
          name={item.name}
          icon={<Folder />}
          onClick={onClick}
        />
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TimestampCell time={item.created_at} />
        <TableCell>-</TableCell>
        <ShareDialogCell
          name={item.name}
          uuid={item.uuid}
          accessLevel={item.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, item)}
            />
          </TableCell>
        )}
        {folderOnly ? (
          <TableCell>{item.name}</TableCell>
        ) : (
          <LinkCell href={`/reports/${item.uuid}`} name={item.name} />
        )}
        <TableCell>{AccessLevelString(item.access_level)}</TableCell>
        <ReportStatusCell item={item} />
        <ReportTokenCell report={item} />
        <ReportRestrictionCell report={item} />
        <TimestampCell time={item.created_at} />
        <TimestampCell time={item.updated_at} />
        <ShareDialogCell
          name={item.name}
          uuid={item.uuid}
          accessLevel={item.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  }
};

const ReportTokenCell: React.FC<{
  report: ReportItem;
}> = ({ report }) => {
  return (
    <TableCell>
      {(report.enabled_public_token && (
        <Link to={`/reports/${report.uuid}?token=${report.public_token}`}>
          あり
        </Link>
      )) ||
        'なし'}
    </TableCell>
  );
};

const ReportRestrictionCell: React.FC<{
  report: ReportItem;
}> = ({ report }) => (
  <TableCell>
    {report.enabled_public_token &&
    (report.ip_filter !== '' ||
      report.use_password ||
      report.restrict_user_groups) ? (
      <Tooltip title={report.ip_filter || ''}>
        <div
          style={{
            color: '#0366d6'
          }}
        >
          あり
        </div>
      </Tooltip>
    ) : (
      'なし'
    )}
  </TableCell>
);

export const ReportStatusCell: React.FC<{ item: ReportItem }> = ({ item }) => {
  const style = React.useMemo(() => {
    return {
      color: ReportStatusColor(item.status),
      display: 'inline-flex',
      alignItems: 'center'
    };
  }, [item.status]);
  return (
    <TableCell>
      <span style={style}>
        {ReportStatusText(item.status)}
        {(item.status === ReportStatus.Error ||
          item.status === ReportStatus.Warning) && (
          <Tooltip
            title={`${
              item.status === ReportStatus.Error ? 'エラー' : '警告'
            }グラフが${item.error_charts}個あります`}
          >
            <Error color="error" />
          </Tooltip>
        )}
      </span>
    </TableCell>
  );
};
