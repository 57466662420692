import { View } from 'models/chart';

const addKMUnit = (num: number, decimals: number | undefined): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(decimals) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(decimals) + 'K';
  } else {
    return num.toFixed(decimals);
  }
};

// x軸が数値の場合の表示
export const formatXAxisNumber = <T>(v: T, view: View) => {
  let num = Number(v);
  // %表記する場合100倍する
  if (view.xShowPercentage) {
    num *= 100;
  }
  // 「単位表記する(KorM)」の場合、変換する
  let str =
    view.xFormatNumberScale === '1'
      ? addKMUnit(num, view.xAxisValueDecimals)
      : num.toFixed(view.xAxisValueDecimals);

  // prefix, suffix
  if (view.xNumberPrefix) {
    str = view.xNumberPrefix + str;
  }
  if (view.xNumberSuffix) {
    str = str + view.xNumberSuffix;
  }
  return str;
};
