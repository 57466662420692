import { ComponentType, lazy } from 'react';

export function retry(
  fn,
  retriesLeft = 5,
  interval = 500
): Promise<ReturnType<typeof fn>> {
  return new Promise<ReturnType<typeof fn>>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

// https://gist.github.com/raphael-leger/4d703dea6c845788ff9eb36142374bdb#file-lazywithretry-js
const REFRESH_KEY = 'page-has-been-force-refreshed';

export function RetryLazy(fn: () => Promise<{ default: ComponentType<any> }>) {
  return lazy(async () => {
    const forceRefreshed = JSON.parse(
      window.localStorage.getItem(REFRESH_KEY) || 'false'
    );

    try {
      const comp = await retry(fn);
      window.localStorage.setItem(REFRESH_KEY, 'false');
      return comp;
    } catch (error) {
      if (!forceRefreshed) {
        window.localStorage.setItem(REFRESH_KEY, 'true');
        return window.location.reload();
      }
      throw error;
    }
  });
}
