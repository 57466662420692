import * as React from 'react';
import { Label } from 'components/visualize/form/label';
import { Checkbox, TextField } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

export interface FormProps {
  label: string;
  onChange: (value?: string) => void;
  placeholder?: string;
  helpText?: string;
}

interface InputFieldProps extends Omit<FormProps, 'label'> {
  type?: string;
  value?: string | number;
}

const useStyles = makeStyles(
  createStyles({
    input: {
      paddingTop: 0
    },
    text: {
      width: '60%'
    },
    number: {
      width: 100
    },
    range: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  })
);

export const InputField: React.FC<InputFieldProps & { className: string }> = ({
  className,
  type = 'text',
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const [stateValue, setValue] = React.useState(value ?? '');

  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
      onChange(event.target.value);
    },
    [onChange]
  );

  React.useEffect(() => {
    if (stateValue !== value) {
      setValue(value ?? '');
    }
  }, [stateValue, value]);

  const classes = useStyles();
  return (
    <TextField
      className={clsx([classes.input, className])}
      label={placeholder}
      size="small"
      margin="dense"
      type={type}
      variant="outlined"
      value={stateValue}
      onChange={handleOnChange}
      inputProps={{
        'data-cy': props['data-cy'] ? `input-${props['data-cy']}` : undefined
      }}
    />
  );
};

interface CheckboxFieldProps {
  label?: string;
  value: boolean;
  onChange: (value: boolean) => void;
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({
  label,
  value,
  onChange
}) => {
  const handleOnChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
    },
    [onChange]
  );

  const CheckboxComponemt = (
    <Checkbox
      // @ts-ignore
      inputProps={{ 'data-cy': `check-${label}` }}
      checked={value}
      onChange={handleOnChange}
    />
  );

  return (
    <>
      {label == undefined ? (
        CheckboxComponemt
      ) : (
        <Label text={label}>{CheckboxComponemt}</Label>
      )}
    </>
  );
};

interface StringFieldProps extends Omit<FormProps, 'label'> {
  value?: string;
  label?: string;
}

export const StringField: React.FC<StringFieldProps> = ({
  label,
  helpText,
  ...rest
}) => {
  const classes = useStyles();

  const InputFieldComponemt = (
    <InputField
      data-cy={label}
      className={classes.text}
      type={'text'}
      {...rest}
    />
  );

  return (
    <>
      {label == undefined ? (
        InputFieldComponemt
      ) : (
        <Label text={label} helpText={helpText}>
          {InputFieldComponemt}
        </Label>
      )}
    </>
  );
};

interface NumberFieldProps extends Omit<FormProps, 'label'> {
  label?: string;
  value?: number;
}
export const NumberField: React.FC<NumberFieldProps> = ({
  label,
  onChange,
  ...rest
}) => {
  const classes = useStyles();
  const setNumber = React.useCallback(
    (e) => {
      const re = /^[0-9\b]+$/;
      if (e === '' || re.test(e)) {
        onChange(e);
      }
    },
    [onChange]
  );

  const InputFieldComponemt = (
    <InputField
      data-cy={label}
      className={clsx(label && classes.text)}
      onChange={setNumber}
      {...rest}
    />
  );

  return (
    <>
      {label == undefined ? (
        InputFieldComponemt
      ) : (
        <Label text={label}>{InputFieldComponemt}</Label>
      )}
    </>
  );
};

interface RangeFieldProps extends Omit<FormProps, 'onChange'> {
  onChangeMinValue: (value?: string) => void;
  onChangeMaxValue: (value?: string) => void;
  min?: number | string;
  max?: number | string;
  helpText?: string;
}

export const RangeField: React.FC<RangeFieldProps> = ({
  min,
  onChangeMinValue,
  max,
  onChangeMaxValue,
  label,
  helpText,
  ...rest
}) => {
  const classes = useStyles();
  const setMin = React.useCallback(
    (e) => {
      const re = /^(-?[0-9]*)\.?([0-9]+)?$/;
      if (e === '' || re.test(e)) {
        onChangeMinValue(e);
      }
    },
    [onChangeMinValue]
  );
  const setMax = React.useCallback(
    (e) => {
      const re = /^(-?[0-9]*)\.?([0-9]+)?$/;
      if (e === '' || re.test(e)) {
        onChangeMaxValue(e);
      }
    },
    [onChangeMaxValue]
  );
  return (
    <Label text={label} helpText={helpText}>
      <div className={clsx(classes.text, classes.range)}>
        <InputField
          data-cy={`${label}-range-min-最小値`}
          placeholder={'最小値'}
          className={classes.number}
          value={min}
          onChange={setMin}
          {...rest}
        />
        ~
        <InputField
          data-cy={`${label}-range-max-最大値`}
          placeholder={'最大値'}
          className={classes.number}
          value={max}
          onChange={setMax}
          {...rest}
        />
      </div>
    </Label>
  );
};
