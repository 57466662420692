import * as React from 'react';
import { TableCell } from '@material-ui/core';
import { Folder } from '@material-ui/icons';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { ListItem, ScheduleItem } from 'models/dependency';
import { AccessLevelString } from 'libs/accessLevel';
import {
  Checkbox,
  LinkCell,
  OpenMenuType,
  OpenShareDialogType,
  ScheduleConfigCell,
  ScheduleConfigStatusCell,
  ScheduleStatusCell,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';

const cellStyle = makeStyles({
  checkCell: {
    borderBottom: 0
  }
});

export const ScheduleRow: React.FC<{
  schedule: ScheduleItem;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  checked: boolean;
  onCheckChanged: (checked: boolean, item: ListItem) => void;
  onScheduleChanged: (id: string, enabled: boolean) => void;
  folderOnly?: boolean;
  disableCheck?: boolean;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({
  schedule,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  onCheckChanged,
  onScheduleChanged,
  checked,
  folderOnly,
  disableCheck,
  basePath,
  clearForm
}) => {
  const classes = cellStyle();
  const onClick = onClickItem
    ? React.useCallback(() => {
        clearForm(schedule.uuid, schedule.is_folder);
        onClickItem(schedule);
      }, [clearForm, onClickItem, schedule])
    : React.useCallback(() => {
        clearForm(
          schedule.uuid,
          schedule.is_folder,
          `${basePath}/f/${schedule.uuid}`
        );
      }, [basePath, clearForm, schedule]);

  if (schedule.is_folder) {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, schedule)}
            />
          </TableCell>
        )}
        <LinkCell
          href={`${basePath}/f/${schedule.uuid}`}
          name={schedule.name}
          icon={<Folder />}
          onClick={onClick}
        />
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <TableCell>{AccessLevelString(schedule.access_level)}</TableCell>
        <TimestampCell time={schedule.created_at} />
        <TimestampCell time={schedule.updated_at} />
        <TableCell>-</TableCell>
        <TableCell>-</TableCell>
        <ShareDialogCell
          name={schedule.name}
          uuid={schedule.uuid}
          accessLevel={schedule.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  } else {
    return (
      <>
        {!disableCheck && (
          <TableCell padding="checkbox" className={classes.checkCell}>
            <Checkbox
              checked={checked}
              onChange={(_, checked) => onCheckChanged(checked, schedule)}
            />
          </TableCell>
        )}
        {folderOnly ? (
          <TableCell>{schedule.name}</TableCell>
        ) : (
          <LinkCell
            href={`/schedules/${schedule.uuid}`}
            name={schedule.name}
            onClick={onClickItem ? onClick : undefined}
          />
        )}
        <ScheduleStatusCell item={schedule} />
        <ScheduleConfigStatusCell item={schedule} />
        <TableCell>{AccessLevelString(schedule.access_level)}</TableCell>
        <TimestampCell time={schedule.created_at} />
        <TimestampCell time={schedule.updated_at} />
        {schedule.scheduling_config.enabled ? (
          <TimestampCell time={schedule.next_run_time} />
        ) : (
          <TableCell>-</TableCell>
        )}
        <ScheduleConfigCell item={schedule} onChange={onScheduleChanged} />
        <ShareDialogCell
          name={schedule.name}
          uuid={schedule.uuid}
          accessLevel={schedule.access_level}
          onOpenShareDialog={onOpenShareDialog}
          onOpenMenu={onOpenMenu}
        />
      </>
    );
  }
};
