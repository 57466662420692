import * as React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { getDialogModuleHelpDetail } from 'libs/api';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';

const styles = makeStyles((theme: Theme) => ({
  help: {
    fontSize: theme.typography.pxToRem(12),
    backgroundColor: 'rgba(255, 255, 255, 0)',
    padding: 5,
    '& table': {
      borderCollapse: 'collapse',
      borderSpacing: '2px',
      display: 'table'
    },
    '& thead': {
      display: 'table-header-group',
      verticalAlign: 'middle',
      borderColor: 'inherit',
      backgroundColor: '#DDD'
    },
    '& tbody': {
      display: 'table-header-group'
    },
    '& tr': {
      borderTopWidth: 1,
      borderTopStyle: 'solid',
      borderTopColor: '#c6cbd1',
      display: 'table-row',
      verticalAlign: 'inherit',
      borderColor: 'inherit',
      width: 40
    },
    '& td, th': {
      paddingTop: 6,
      paddingRight: 13,
      paddingBottom: 6,
      paddingLeft: 13,
      borderWidth: 1,
      borderStyle: 'solid'
    }
  },
  popper: {
    opacity: 1,
    zIndex: theme.zIndex.modal + 1, // column_select_v2のdialogに被さないようにする
    pointerEvents: 'auto'
  },
  htmlTooltip: {
    backgroundColor: 'rgba(255, 255, 255, 1)',
    color: 'rgba(0, 0, 0, 1)',
    maxWidth: 936,
    maxHeight: 'calc(100vh - 14px)',
    overflowY: 'auto',
    boxShadow: theme.shadows[5]
  }
}));

export const DialogDetailedHelpTooltip: React.FC<{
  moduleName: string;
  filename: string;
}> = ({ moduleName, filename }) => {
  const classes = styles();

  const [content, setContent] = React.useState('');
  const [open, setOpen] = React.useState(false);

  const handleTooltipOpen = React.useCallback(async () => {
    const { data } = await getDialogModuleHelpDetail(moduleName, filename);
    setContent(data);
    setOpen((open) => !open);
  }, [moduleName, filename]);

  const detail_help = () => {
    return (
      <>
        {content !== '' && (
          <div
            className={classes.help}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}
      </>
    );
  };

  return (
    <Tooltip
      open={open}
      title={detail_help() || ''}
      placement="right"
      classes={{
        popper: classes.popper,
        tooltip: classes.htmlTooltip
      }}
      TransitionProps={{
        timeout: 0
      }}
      PopperProps={{
        popperOptions: {
          modifiers: {
            computeStyle: {
              gpuAcceleration: false
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'viewport'
            }
          }
        }
      }}
      disableFocusListener
      disableTouchListener
      disableHoverListener
    >
      <IconButton onClick={handleTooltipOpen}>
        <HelpIcon />
      </IconButton>
    </Tooltip>
  );
};
