import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Chip,
  DialogContentText,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import MainLayout from 'components/layouts/main';
import { getUser, updateUser } from 'libs/api';
import { useAsyncRetry } from 'react-use';
import { User } from 'models/user';
import { Avatar } from 'ui/common/avatar';
import { TextField } from 'ui/common/textfield';
import * as z from 'zod';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { UserRoleString } from 'libs/accessLevel';
import { Group } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { StoreType } from '../../configureStore';
import { Checkbox } from 'ui/common/checkbox';
import { setHelpStatus, setNotificationStatus } from 'actions/userConfig';
import { MdLogout, MdPassword } from 'react-icons/md';
import { Button } from 'ui/common/button';
import { getAuth, updatePassword } from 'firebase/auth';
import { firebaseApp } from '../../firebase';
import { useDisclosure } from '../../hooks/useDisclosure';
import { Dialog } from 'components/ui/common/dialog';

const useStyles = makeStyles({
  content: {
    width: '95%',
    paddingTop: 16
  },
  paper: {
    marginTop: 16,
    marginBottom: 32,
    maxWidth: 500,
    padding: 24,
    display: 'flex',
    flexDirection: 'column',
    gap: '12px'
  },
  textfield: {
    '& .MuiInputBase-input': {
      width: 0,
      minWidth: '30px'
    }
  }
});

const schema = z.object({
  last_name: z.string().min(1, { message: '姓を入力してください。' }),
  first_name: z.string(),
  help_switch: z.boolean(),
  notification_switch: z.boolean()
});

type FieldValues = z.infer<typeof schema>;

export default ({}) => {
  const classes = useStyles();
  const help_switch = useSelector((state: StoreType) => state.userConfig.help);
  const notification_switch = useSelector(
    (state: StoreType) => state.userConfig.notification
  );
  const {
    control,
    getValues,
    formState: { isDirty, errors },
    reset
  } = useForm<FieldValues>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    defaultValues: {
      help_switch,
      notification_switch
    }
  });
  const [openPassword, setOpenPassword] = useState(false);
  const userApi = useAsyncRetry(async () => {
    const { data } = await getUser();
    setUser(data);
    reset({
      first_name: data.first_name,
      last_name: data.last_name || data.email
    });
    return data;
  });
  const [user, setUser] = useState<User | null>(null);
  const dispatch = useDispatch();

  return (
    <MainLayout selectedMenu="user">
      <div
        style={{
          position: 'absolute',
          top: 72,
          right: 8,
          display: 'flex',
          gap: 8,
          alignItems: 'center'
        }}
      >
        <Tooltip title="パスワード変更">
          <IconButton onClick={() => setOpenPassword(true)}>
            <MdPassword />
          </IconButton>
        </Tooltip>
        <Tooltip title="ログアウト">
          <IconButton onClick={() => getAuth(firebaseApp).signOut()}>
            <MdLogout />
          </IconButton>
        </Tooltip>
        <Button
          color="common"
          disabled={!isDirty || Object.keys(errors).length > 0}
          onClick={async () => {
            await updateUser(getValues());
            dispatch(setNotificationStatus(getValues().notification_switch));
            dispatch(setHelpStatus(getValues().help_switch));
            userApi.retry();
          }}
        >
          保存
        </Button>
      </div>
      <Grid container={true} justifyContent="center">
        <div className={classes.content}>
          <Typography variant="h5">ユーザー情報</Typography>
          <Paper className={classes.paper}>
            <Avatar user={user} />
            <Controller
              name="last_name"
              control={control}
              render={({ field }) => (
                <TextField
                  label="姓(必須)"
                  required={true}
                  InputLabelProps={{ shrink: true }}
                  helperText={errors['last_name']?.message}
                  error={Boolean(errors['last_name'])}
                  {...field}
                />
              )}
            />
            <Controller
              name="first_name"
              control={control}
              render={({ field }) => (
                <TextField
                  label="名"
                  InputLabelProps={{ shrink: true }}
                  {...field}
                />
              )}
            />
            <TextField
              label="メールアドレス"
              disabled={true}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              value={user?.email}
            />
            <TextField
              label="ユーザータイプ"
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: true }}
              disabled={true}
              value={UserRoleString(user?.role ?? 0)}
            />
            <TextField
              label="所属ユーザーグループ"
              disabled={true}
              InputProps={{
                readOnly: true,
                style: { flexWrap: 'wrap' },
                className: classes.textfield,
                startAdornment: (
                  <>
                    {user?.groups?.map((u, i) => (
                      <Chip
                        key={i}
                        icon={<Group />}
                        label={u}
                        disabled={true}
                      />
                    ))}
                  </>
                )
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Paper>
          <Typography variant="h5">アカウント設定</Typography>
          <Paper className={classes.paper}>
            <FormControlLabel
              label="分析プロジェクトにクイックサンプルを表示する"
              control={
                <Controller
                  name="help_switch"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} />
                  )}
                />
              }
            />
            <FormControlLabel
              label="実行完了時にブラウザ通知する"
              control={
                <Controller
                  name="notification_switch"
                  control={control}
                  render={({ field: { value, ...field } }) => (
                    <Checkbox checked={value} {...field} />
                  )}
                />
              }
            />
          </Paper>
        </div>
      </Grid>
      <PasswordChangeDialog
        open={openPassword}
        onClose={() => setOpenPassword(false)}
      />
    </MainLayout>
  );
};

export function PasswordChangeDialog({
  open,
  onClose
}: {
  open: boolean;
  onClose: () => void;
}) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [changing, setChanging] = useState(false);
  const [error, setError] = useState('');
  const { isOpen, onOpen, onClose: onCloseResult } = useDisclosure();
  const passNotMatch = password !== passwordConfirm;

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        title="パスワードの変更"
        cancelButton={{ disabled: changing }}
        OKButton={{
          label: ' パスワードを変更する',
          onClick: async () => {
            setChanging(true);
            try {
              setError('');
              const user = getAuth(firebaseApp).currentUser!;
              await updatePassword(user, password);
            } catch (ex) {
              setError(ex.code);
            }
            onOpen();
            setChanging(false);
          },
          disabled: changing || password === '' || passNotMatch
        }}
      >
        <TextField
          value={password}
          onChange={(ev) => setPassword(ev.target.value)}
          autoFocus={true}
          margin="dense"
          id="new-password"
          label="新しいパスワード"
          type="password"
          fullWidth={true}
          autoComplete="new-password"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          value={passwordConfirm}
          onChange={(ev) => setPasswordConfirm(ev.target.value)}
          margin="dense"
          id="password-confirm"
          label="新しいパスワード(確認)"
          type="password"
          fullWidth={true}
          autoComplete="new-password"
          error={passNotMatch}
          helperText={passNotMatch && 'パスワードが一致しません'}
          InputLabelProps={{ shrink: true }}
        />
      </Dialog>
      <Dialog
        open={isOpen}
        onClose={() => {
          onClose();
          onCloseResult();
        }}
        title="パスワードの変更"
        hideCancelButton
        OKButton={{
          onClick: () => {
            onClose();
            onCloseResult();
          },
          autoFocus: true
        }}
      >
        <DialogContentText>
          {error === ''
            ? 'パスワードが変更されました'
            : error === 'auth/requires-recent-login'
              ? 'パスワードの変更に失敗しました。この操作を行うには再度ログインしてください。'
              : 'パスワードの変更に失敗しました。時間をおいて再度お試しください。'}
        </DialogContentText>
      </Dialog>
    </>
  );
}
