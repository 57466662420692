import * as React from 'react';
import { TableCell } from '@material-ui/core';
import { ListItem, NotificationDstItem } from 'models/dependency';
import { AccessLevelString } from 'libs/accessLevel';
import {
  LinkCell,
  NotificationDstCell,
  OpenMenuType,
  OpenShareDialogType,
  ShareDialogCell,
  TimestampCell
} from 'ui/listViewTableCell';

export const NotificationDstRow: React.FC<{
  notificationDst: NotificationDstItem;
  onClickItem?: (item: ListItem) => void;
  onOpenShareDialog?: OpenShareDialogType;
  onOpenMenu: OpenMenuType;
  basePath: string;
  clearForm: (folderId: string, isFolder: boolean, path?: string) => void;
}> = ({
  notificationDst,
  onClickItem,
  onOpenShareDialog,
  onOpenMenu,
  basePath,
  clearForm
}) => {
  const onClick = onClickItem
    ? React.useCallback(() => {
        clearForm(notificationDst.uuid, notificationDst.is_folder);
        onClickItem(notificationDst);
      }, [clearForm, onClickItem, notificationDst])
    : React.useCallback(() => {
        clearForm(
          notificationDst.uuid,
          notificationDst.is_folder,
          `${basePath}/f/${notificationDst.uuid}`
        );
      }, [basePath, clearForm, notificationDst]);

  return (
    <>
      <LinkCell
        href={`/notification_dsts/${notificationDst.uuid}`}
        name={notificationDst.name}
        onClick={onClickItem ? onClick : undefined}
      />
      <TableCell>{AccessLevelString(notificationDst.access_level)}</TableCell>
      <NotificationDstCell type={notificationDst.type} />
      <TimestampCell time={notificationDst.created_at} />
      <TimestampCell time={notificationDst.updated_at} />
      <ShareDialogCell
        name={notificationDst.name}
        uuid={notificationDst.uuid}
        accessLevel={notificationDst.access_level}
        onOpenShareDialog={onOpenShareDialog}
        onOpenMenu={onOpenMenu}
      />
    </>
  );
};
