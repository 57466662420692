import * as React from 'react';

import 'css/Grid.css';

import { db } from '../../database';
import { PortStatus } from 'models/graph';
import {
  DataTable,
  convertDatabaseRowToChartRow,
  convertSummaryColumnToTableColumn
} from 'ui/dataTable';
import { TableColumn, Row as ChartRow } from '../../models/chart';
import { ColumnOptionsV2Names } from 'components/form/columnSelectV2Field';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DataSummary } from 'models/data';
import { ErrorOutlineOutlined } from '@material-ui/icons';

interface DataTableProps {
  projectId?: string;
  portId?: string;
  dataStatus?: PortStatus;
  displayColumns?: string[];
  isDataPreview?: boolean;
  colnameMap?: ColumnOptionsV2Names; // 多分データソースの列型変更の時専用。変更後の列名を適用させたいので
  // tableData?: {
  //   columns: TableColumn[],
  //   data: ChartRow[]
  // }
  showSummary?: boolean;
  isOrder?: boolean;
}

const summaryStyle = makeStyles({
  root: {
    color: '#344955',
    lineHeight: '1.2em',
    paddingTop: '20px',
    paddingBottom: '20px',
    paddingLeft: '10px',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    fontSize: 15,
    fontWeight: 500
  },
  warn: {
    color: '#f3a95c'
  }
});

export const Summary: React.FC<{ summary: DataSummary | undefined }> = ({
  summary
}) => {
  const classes = summaryStyle();
  if (summary == undefined) {
    return null;
  }
  if (summary.num_rows > 5000) {
    return (
      <div className={classes.root}>
        <span>
          {`行数 : ${summary.num_rows.toLocaleString()}  列数 : ${summary.num_cols.toLocaleString()}`}
        </span>
        <span style={{ marginRight: '20px' }} />
        <ErrorOutlineOutlined className={classes.warn} />
        <span className={classes.warn}>先頭5000行のみ表示しています</span>
      </div>
    );
  }
  return (
    <div className={classes.root}>
      <span>
        {`行数 : ${summary.num_rows.toLocaleString()}  列数 : ${summary.num_cols.toLocaleString()}`}
      </span>
    </div>
  );
};

const Table: React.FC<DataTableProps> = ({
  projectId,
  portId,
  dataStatus,
  displayColumns,
  isDataPreview,
  colnameMap,
  showSummary,
  isOrder
}) => {
  const [data, setData] = React.useState<ChartRow[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [columns, setColumns] = React.useState<TableColumn[]>([]);
  const [summary, setSummary] = React.useState<DataSummary | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (projectId == undefined || portId == undefined) {
      return;
    }

    setLoading(true);

    let unmounted = false;
    const f = async () => {
      const summary = await db.getPortSummary(projectId, portId);
      if (summary == undefined) {
        if (!unmounted) {
          setLoading(false);
        }
        return;
      }
      const data = await db.getPortData(projectId, portId);
      if (!unmounted) {
        if (summary != undefined) {
          let tmpColumns = convertSummaryColumnToTableColumn(summary);
          let tmpData = convertDatabaseRowToChartRow(data, summary);
          if (colnameMap != undefined) {
            const columnValues = tmpColumns.map((c) => c.value);
            tmpData = tmpData.map((row) => {
              let newRow = {};
              Object.keys(row).forEach((key) => {
                if (columnValues.includes(key)) {
                  newRow[colnameMap[key]] = row[key];
                  return;
                }
                newRow[key] = row[key];
              });
              return newRow;
            });
            tmpColumns = tmpColumns.map((c) => {
              return {
                ...c,
                label: colnameMap[c.value],
                value: colnameMap[c.value]
              };
            });
          }
          if (isOrder) {
            // displayColumnsが指定されている場合は、その順番に並べ替える
            // tmpColumnsにdisplayColumnsの列名が存在しない場合は、末尾に追加する
            tmpColumns = tmpColumns.sort((a, b) => {
              if (displayColumns == undefined) {
                return 0;
              }
              const aIdx = displayColumns.indexOf(a.value);
              const bIdx = displayColumns.indexOf(b.value);
              if (aIdx == -1) {
                return 1;
              }
              if (bIdx == -1) {
                return -1;
              }
              return aIdx - bIdx;
            });
          }
          setColumns(tmpColumns);
          setData(tmpData);
          if (showSummary) {
            setSummary(summary);
          }
        }
        setLoading(false);
      }
    };
    f();

    return () => {
      unmounted = true;
    };
  }, [projectId, portId, dataStatus]);

  React.useEffect(() => {
    if (isOrder) {
      const tmpColumns = columns.sort((a, b) => {
        if (displayColumns == undefined) {
          // displayColumnsが指定されていない場合は、何もしない
          return 0;
        }
        const aIdx = displayColumns.indexOf(a.value);
        const bIdx = displayColumns.indexOf(b.value);
        if (aIdx == -1) {
          return 1;
        }
        if (bIdx == -1) {
          return -1;
        }
        return aIdx - bIdx;
      });
      setColumns(tmpColumns);
      // dataの列順をtmpColumnsの順に変更する
      const tmpData = data.map((row) => {
        let newRow = {};
        tmpColumns.forEach((c) => {
          newRow[c.value] = row[c.value];
        });
        return newRow;
      });
      setData(tmpData);
    }
  }, [displayColumns]);

  React.useEffect(() => {}, [data]);
  if (showSummary) {
    return (
      <>
        <div style={{ height: '55px' }}>
          <Summary summary={summary} />
        </div>
        <div style={{ height: 'calc(100% - 60px)' }}>
          <DataTable
            height={'100%'}
            data={data}
            columns={columns}
            layout={'fitData'}
            loading={loading}
            displayColumns={displayColumns}
            dataStatus={dataStatus}
            isDataPreview={isDataPreview}
          />
        </div>
      </>
    );
  }
  return (
    <DataTable
      height={'100%'}
      data={data}
      columns={columns}
      layout={'fitData'}
      loading={loading}
      displayColumns={displayColumns}
      dataStatus={dataStatus}
      isDataPreview={isDataPreview}
    />
  );
};

export default Table;
