import * as React from 'react';
import { SvgIcon } from '@material-ui/core';

function SvgIcAfterExecution(props) {
  return (
    <SvgIcon viewBox="0 0 30 30" {...props}>
      <g
        stroke="currentcolor"
        strokeWidth="2"
        transform="translate(-1582 -558) translate(1582 558)"
      >
        <rect width="16" height="6" x="7" y="4" rx="3" fill="none" />
        <rect width="16" height="6" x="7" y="20" rx="3" fill="none" />
        <path d="M15 10L15 17" />
        <path strokeLinecap="round" d="M12 14L15.006 17 18 14.013" />
      </g>
    </SvgIcon>
  );
}

export default SvgIcAfterExecution;
